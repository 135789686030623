import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Store } from '../../stores';
import { RotatorViewTemplate } from './rotatorViewTemplate/RotatorViewTemplate';
import { IASlider } from '../../../slider/Slider';
import { IAToggle } from '../../../toggle/Toggle';
import { IPlayBoxStyle, IPlayLayoutType, IPlayViewTemplate } from '../../../../interfaces/IPlay';
import { Helper } from '../../../../Helper';
import { LocalizationService } from '../../../../services/LocalizationService';

export interface IProps {
  store?: Store;
  layoutType: IPlayLayoutType;
  boxStyle: IPlayBoxStyle;
  showListOption?: boolean;
  showWeekOption?: boolean;
  backgroundColor: string;
  textColor: string;
  listBackgroundColor?: string;
  listTextColor?: string;
  listBoxColor?: string;
  timeBatchBackgroundColor?: string;
  timeBatchTextColor?: string;
  timeBatchBorderColor?: string;
  image?: string;
  video?: string;
  galleryImages?: string[];
  contentPosition?: "left" | "right" | "random";
  contentVerticalPosition?: "top" | "bottom";
  keepOriginalImage?: boolean;
  boxStyleRoundCorner?: boolean;
  contentWidth?: number;
  boxDisabled?: boolean;
  updateBoxStyle: (boxStyle: IPlayBoxStyle) => void;
  updateLayout: (layout: IPlayLayoutType) => void;
  updateContentPosition: (contentPosition: "left" | "right" | "random") => void;
  updateContentVerticalPosition?: (contentVerticalPosition: "top" | "bottom") => void;
  updateKeepOriginalImage: (keepOriginalImage: boolean) => void;
  updateBoxStyleRoundCorner?: (boxStyleRoundCorner: boolean) => void;
  updateContentWidth?: (contentWidth: number) => void;
}

export interface IState {
  boxStyleCategory: IPlayViewTemplate;
  backgroundTransparency: "transparent" | "solid" | "gradient" | "hidden";
}

@inject('store')
@observer
export class RotatorViewSelector extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    let boxStyleCategory: "box" | "half" | "full" | "onlyMedia" | "postcard";
    if (props.boxStyle === "box" || props.boxStyle === "boxTransparent") {
      boxStyleCategory = "box";
    } else if (props.boxStyle === "fullSolid" || props.boxStyle === "fullTransparent") {
      boxStyleCategory = "full";
    } else if (props.boxStyle === "onlyMedia") {
      boxStyleCategory = "onlyMedia";
    } else if (props.boxStyle === "postcard") {
      boxStyleCategory = "postcard";
    } else {
      boxStyleCategory = "half";
    }
    let backgroundTransparency: "transparent" | "solid" | "gradient" = "solid";
    if (props.boxStyle === "boxTransparent" || props.boxStyle === "fullTransparent") {
      backgroundTransparency = "transparent";
    } else if (props.boxStyle === "fullSolid" || props.boxStyle === "solid") {
      backgroundTransparency = "solid";
    } else {
      backgroundTransparency = "gradient";
    }
    this.state = {
      boxStyleCategory: boxStyleCategory,
      backgroundTransparency: backgroundTransparency
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }


  private updateBoxStyle(boxStyleCategory: "box" | "half" | "full" | "onlyMedia" | "postcard", backgroundTransparency: "transparent" | "solid" | "gradient" | "hidden") {
    let boxStyle;
    if (boxStyleCategory === "box" && backgroundTransparency === "transparent") {
      boxStyle = "boxTransparent";
    }
    if (boxStyleCategory === "box" && backgroundTransparency === "hidden") {
      boxStyle = "boxHidden";
    }
    if (boxStyleCategory === "box" && backgroundTransparency === "solid") {
      boxStyle = "box";
    }
    if (boxStyleCategory === "full" && backgroundTransparency === "transparent") {
      boxStyle = "fullTransparent";
    }
    if (boxStyleCategory === "full" && backgroundTransparency === "solid") {
      boxStyle = "fullSolid";
    }
    if (boxStyleCategory === "half" && backgroundTransparency === "transparent") {
      boxStyle = "transparent";
    }
    if (boxStyleCategory === "half" && backgroundTransparency === "hidden") {
      boxStyle = "hidden";
    }
    if (boxStyleCategory === "half" && backgroundTransparency === "solid") {
      boxStyle = "solid";
    }
    if (boxStyleCategory === "half" && backgroundTransparency === "gradient") {
      boxStyle = "gradient";
    }
    if (boxStyleCategory === "onlyMedia") {
      boxStyle = "onlyMedia";
    }
    if (boxStyleCategory === "postcard") {
      boxStyle = "postcard";
    }
    this.props.updateLayout("slide");
    this.props.updateBoxStyle(boxStyle);
    this.setState({ boxStyleCategory, backgroundTransparency });
  }

  public render(): JSX.Element {
    return (
      <div>
        <div style={{ fontSize: "12px", width: "100%", float: "left", marginTop: 20, marginBottom: 10, color: this.props.store.darkMode ? Helper.darkModeLabelColor : 'rgb(51, 51, 51)' }}>{this.localizationService.strings.PlayAdmin_Templates?.toUpperCase()}</div>
        <div style={{
          clear: "both",
          width: 330,
          float: "left"
        }}>
          <RotatorViewTemplate
            template={"full"}
            transparency={"solid"}
            selected={this.state.boxStyleCategory === "full" && this.props.layoutType === "slide"}
            backgroundColor={this.props.backgroundColor}
            textColor={this.props.textColor}
            image={this.props.image}
            video={this.props.video}
            onSelect={() => {
              this.props.updateContentPosition("left");
              if (this.props.updateContentVerticalPosition) {
                this.props.updateContentVerticalPosition("top");
              }
              this.props.updateKeepOriginalImage(false);
              this.updateBoxStyle("full", "solid");
            }}
          />
          <RotatorViewTemplate
            disabled={!this.props.image && !this.props.video && this.props.galleryImages?.length == 0}
            template={"half"}
            transparency={"solid"}
            contentPosition={"left"}
            selected={this.state.boxStyleCategory === "half" && this.props.layoutType === "slide"}
            backgroundColor={this.props.backgroundColor}
            textColor={this.props.textColor}
            image={this.props.image}
            video={this.props.video}
            onSelect={() => {
              this.props.updateContentPosition("left");
              if (this.props.updateContentVerticalPosition) {
                this.props.updateContentVerticalPosition("top");
              };
              this.props.updateKeepOriginalImage(false);
              this.updateBoxStyle("half", "solid");
            }}
          />
          {!this.props.boxDisabled &&
            <RotatorViewTemplate
              disabled={!this.props.image && !this.props.video && this.props.galleryImages?.length == 0}
              template={"box"}
              transparency={"solid"}
              contentPosition={"left"}
              contentVerticalPosition={"top"}
              selected={this.state.boxStyleCategory === "box" && this.props.layoutType === "slide"}
              backgroundColor={this.props.backgroundColor}
              textColor={this.props.textColor}
              image={this.props.image}
              video={this.props.video}
              onSelect={() => {
                this.props.updateContentPosition("left");
                if (this.props.updateContentVerticalPosition) {
                  this.props.updateContentVerticalPosition("top");
                };
                this.props.updateKeepOriginalImage(false);
                this.updateBoxStyle("box", "solid");
              }}
            />
          }
          <RotatorViewTemplate
            disabled={!this.props.image && !this.props.video && this.props.galleryImages?.length == 0}
            template={"onlyMedia"}
            selected={this.state.boxStyleCategory === "onlyMedia" && this.props.layoutType === "slide"}
            backgroundColor={this.props.backgroundColor}
            textColor={this.props.textColor}
            image={this.props.image}
            video={this.props.video}
            contentPosition={this.props.contentPosition === "right" ? "right" : "left"}
            transparency={this.state.backgroundTransparency}
            onSelect={() => {
              this.props.updateContentPosition("left");
              if (this.props.updateContentVerticalPosition) {
                this.props.updateContentVerticalPosition("top");
              };
              this.props.updateKeepOriginalImage(false);
              this.updateBoxStyle("onlyMedia", this.state.backgroundTransparency === "gradient" ? "transparent" : this.state.backgroundTransparency);
            }}
          />
          {this.props.showListOption &&
            <RotatorViewTemplate
              template={"list"}
              selected={this.props.layoutType === "list"}
              backgroundColor={this.props.backgroundColor}
              textColor={this.props.textColor}
              listBackgroundColor={this.props.listBackgroundColor}
              listTextColor={this.props.listTextColor}
              listBoxColor={this.props.listBoxColor}
              timeBatchBackgroundColor={this.props.store.widgetToEdit?.eventViewer.timeBatchBackgroundColor}
              timeBatchTextColor={this.props.store.widgetToEdit?.eventViewer.timeBatchTextColor}
              timeBatchBorderColor={this.props.store.widgetToEdit?.eventViewer.timeBatchBorderColor}
              image={this.props.image}
              video={this.props.video}
              transparency={this.state.backgroundTransparency}
              onSelect={() => {
                this.props.updateLayout("list");
              }}
            />
          }
          {this.props.showWeekOption &&
            <RotatorViewTemplate
              template={"week"}
              selected={this.props.layoutType === "week"}
              backgroundColor={this.props.backgroundColor}
              textColor={this.props.textColor}
              listBackgroundColor={this.props.listBackgroundColor}
              listTextColor={this.props.listTextColor}
              listBoxColor={this.props.listBoxColor}
              timeBatchBackgroundColor={this.props.store.widgetToEdit?.eventViewer.timeBatchBackgroundColor}
              timeBatchTextColor={this.props.store.widgetToEdit?.eventViewer.timeBatchTextColor}
              timeBatchBorderColor={this.props.store.widgetToEdit?.eventViewer.timeBatchBorderColor}
              image={this.props.image}
              video={this.props.video}
              transparency={this.state.backgroundTransparency}
              onSelect={() => {
                this.props.updateLayout("week");
              }}
            />
          }
          {/* <RotatorViewTemplate
                      disabled={!this.props.image && !this.props.video && this.props.galleryImages?.length == 0}
                      template={"postcard"}
                      selected={this.state.boxStyleCategory === "postcard"}
                      backgroundColor={this.props.backgroundColor}
                      textColor={this.props.textColor}
                      image={this.props.image}
                      video={this.props.video}
                      onSelect={() => {
                        this.props.updateContentPosition("left");
                        if (this.props.updateContentVerticalPosition) {
                this.props.updateContentVerticalPosition("top");
              };
                        this.props.updateKeepOriginalImage(false);
                        this.updateBoxStyle("postcard", this.state.backgroundTransparency === "gradient" ? "transparent" : this.state.backgroundTransparency);
                      }}
                    /> */}
        </div>
        {(this.state.boxStyleCategory === "full" || this.state.boxStyleCategory === "half" || this.state.boxStyleCategory === "box" || this.state.boxStyleCategory === "onlyMedia") && this.props.layoutType === "slide" &&
          <>
            <div style={{ fontSize: "12px", width: "100%", float: "left", marginTop: 20, marginBottom: 10, color: this.props.store.darkMode ? Helper.darkModeLabelColor : 'rgb(51, 51, 51)' }}>{this.localizationService.strings.PlayAdmin_ArticleLayoutVariants.toUpperCase()}</div>
            <div style={{
              clear: "both",
              width: 330,
              float: "left"
            }}>
              {this.state.boxStyleCategory === "full" && this.props.layoutType === "slide" &&
                <>
                  <RotatorViewTemplate
                    template={"full"}
                    transparency={"solid"}
                    selected={this.state.boxStyleCategory === "full" && this.state.backgroundTransparency === "solid" && this.props.layoutType === "slide"}
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    onSelect={() => {
                      this.props.updateContentPosition("left");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("top");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("full", "solid");
                    }}
                  />
                  <RotatorViewTemplate
                    disabled={!this.props.image && !this.props.video && this.props.galleryImages?.length == 0}
                    template={"full"}
                    transparency="transparent"
                    selected={this.state.boxStyleCategory === "full" && this.state.backgroundTransparency === "transparent" && this.props.layoutType === "slide"}
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    onSelect={() => {
                      this.props.updateContentPosition("left");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("top");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("full", "transparent");
                    }}
                  />
                </>
              }
              {this.state.boxStyleCategory === "half" && this.props.layoutType === "slide" &&
                <>
                  <RotatorViewTemplate
                    template={"half"}
                    transparency={"solid"}
                    contentPosition={"left"}
                    selected={this.state.boxStyleCategory === "half" && this.state.backgroundTransparency === "solid" && this.props.contentPosition === "left" && !this.props.keepOriginalImage && this.props.layoutType === "slide"}
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    onSelect={() => {
                      this.props.updateContentPosition("left");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("top");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("half", "solid");
                    }}
                  />
                  <RotatorViewTemplate
                    template={"half"}
                    transparency={"solid"}
                    contentPosition={"right"}
                    selected={this.state.boxStyleCategory === "half" && this.state.backgroundTransparency === "solid" && this.props.contentPosition === "right" && !this.props.keepOriginalImage && this.props.layoutType === "slide"}
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    onSelect={() => {
                      this.props.updateContentPosition("right");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("top");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("half", "solid");
                    }}
                  />
                  {!(!this.props.image && !this.props.video) &&
                    <RotatorViewTemplate
                      template={"half"}
                      transparency={"solid"}
                      contentPosition={"left"}
                      keepOriginalImage
                      selected={this.state.boxStyleCategory === "half" && this.state.backgroundTransparency === "solid" && this.props.contentPosition === "left" && this.props.keepOriginalImage && this.props.layoutType === "slide"}
                      backgroundColor={this.props.backgroundColor}
                      textColor={this.props.textColor}
                      image={this.props.image}
                      video={this.props.video}
                      onSelect={() => {
                        this.props.updateContentPosition("left");
                        if (this.props.updateContentVerticalPosition) {
                          this.props.updateContentVerticalPosition("top");
                        };
                        this.props.updateKeepOriginalImage(true);
                        this.updateBoxStyle("half", "solid");
                      }}
                    />
                  }
                  {!(!this.props.image && !this.props.video) &&
                    <RotatorViewTemplate
                      template={"half"}
                      transparency={"solid"}
                      contentPosition={"right"}
                      keepOriginalImage
                      selected={this.state.boxStyleCategory === "half" && this.state.backgroundTransparency === "solid" && this.props.contentPosition === "right" && this.props.keepOriginalImage && this.props.layoutType === "slide"}
                      backgroundColor={this.props.backgroundColor}
                      textColor={this.props.textColor}
                      image={this.props.image}
                      video={this.props.video}
                      onSelect={() => {
                        this.props.updateContentPosition("right");
                        if (this.props.updateContentVerticalPosition) {
                          this.props.updateContentVerticalPosition("top");
                        };
                        this.props.updateKeepOriginalImage(true);
                        this.updateBoxStyle("half", "solid");
                      }}
                    />
                  }
                  <RotatorViewTemplate
                    template={"half"}
                    transparency={"transparent"}
                    contentPosition={"left"}
                    selected={this.state.boxStyleCategory === "half" && this.state.backgroundTransparency === "transparent" && this.props.contentPosition === "left" && this.props.layoutType === "slide"}
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    onSelect={() => {
                      this.props.updateContentPosition("left");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("top");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("half", "transparent");
                    }}
                  />
                  <RotatorViewTemplate
                    template={"half"}
                    transparency={"transparent"}
                    contentPosition={"right"}
                    selected={this.state.boxStyleCategory === "half" && this.state.backgroundTransparency === "transparent" && this.props.contentPosition === "right" && this.props.layoutType === "slide"}
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    onSelect={() => {
                      this.props.updateContentPosition("right");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("top");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("half", "transparent");
                    }}
                  />
                  <RotatorViewTemplate
                    template={"half"}
                    transparency={"gradient"}
                    contentPosition={"left"}
                    selected={this.state.boxStyleCategory === "half" && this.state.backgroundTransparency === "gradient" && this.props.contentPosition === "left" && this.props.layoutType === "slide"}
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    onSelect={() => {
                      this.props.updateContentPosition("left");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("top");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("half", "gradient");
                    }}
                  />
                  <RotatorViewTemplate
                    template={"half"}
                    transparency={"gradient"}
                    contentPosition={"right"}
                    selected={this.state.boxStyleCategory === "half" && this.state.backgroundTransparency === "gradient" && this.props.contentPosition === "right" && this.props.layoutType === "slide"}
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    onSelect={() => {
                      this.props.updateContentPosition("right");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("top");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("half", "gradient");
                    }}
                  />
                  <RotatorViewTemplate
                    template={"half"}
                    transparency={"hidden"}
                    contentPosition={"left"}
                    selected={this.state.boxStyleCategory === "half" && this.state.backgroundTransparency === "hidden" && this.props.contentPosition === "left" && this.props.layoutType === "slide"}
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    onSelect={() => {
                      this.props.updateContentPosition("left");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("top");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("half", "hidden");
                    }}
                  />
                  <RotatorViewTemplate
                    template={"half"}
                    transparency={"hidden"}
                    contentPosition={"right"}
                    selected={this.state.boxStyleCategory === "half" && this.state.backgroundTransparency === "hidden" && this.props.contentPosition === "right" && this.props.layoutType === "slide"}
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    onSelect={() => {
                      this.props.updateContentPosition("right");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("top");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("half", "hidden");
                    }}
                  />
                </>
              }
              {this.state.boxStyleCategory === "box" && this.props.layoutType === "slide" &&
                <>
                  <RotatorViewTemplate
                    template={"box"}
                    transparency={"solid"}
                    contentPosition={"left"}
                    contentVerticalPosition={"top"}
                    selected={
                      this.state.boxStyleCategory === "box" &&
                      this.state.backgroundTransparency === "solid" &&
                      this.props.contentPosition === "left" &&
                      this.props.contentVerticalPosition === "top" &&
                      this.props.layoutType === "slide"
                    }
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    onSelect={() => {
                      this.props.updateContentPosition("left");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("top");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("box", "solid");
                    }}
                  />
                  <RotatorViewTemplate
                    template={"box"}
                    transparency={"solid"}
                    contentPosition={"right"}
                    contentVerticalPosition={"top"}
                    selected={
                      this.state.boxStyleCategory === "box" &&
                      this.state.backgroundTransparency === "solid" &&
                      this.props.contentPosition === "right" &&
                      this.props.contentVerticalPosition === "top" &&
                      this.props.layoutType === "slide"
                    }
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    onSelect={() => {
                      this.props.updateContentPosition("right");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("top");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("box", "solid");
                    }}
                  />
                  <RotatorViewTemplate
                    template={"box"}
                    transparency={"solid"}
                    contentPosition={"left"}
                    contentVerticalPosition={"bottom"}
                    selected={
                      this.state.boxStyleCategory === "box" &&
                      this.state.backgroundTransparency === "solid" &&
                      this.props.contentPosition === "left" &&
                      this.props.contentVerticalPosition === "bottom" &&
                      this.props.layoutType === "slide"
                    }
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    onSelect={() => {
                      this.props.updateContentPosition("left");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("bottom");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("box", "solid");
                    }}
                  />
                  <RotatorViewTemplate
                    template={"box"}
                    transparency={"solid"}
                    contentPosition={"right"}
                    contentVerticalPosition={"bottom"}
                    selected={
                      this.state.boxStyleCategory === "box" &&
                      this.state.backgroundTransparency === "solid" &&
                      this.props.contentPosition === "right" &&
                      this.props.contentVerticalPosition === "bottom" &&
                      this.props.layoutType === "slide"
                    }
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    onSelect={() => {
                      this.props.updateContentPosition("right");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("bottom");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("box", "solid");
                    }}
                  />
                  <RotatorViewTemplate
                    template={"box"}
                    transparency={"transparent"}
                    contentPosition={"left"}
                    contentVerticalPosition={"top"}
                    selected={
                      this.state.boxStyleCategory === "box" &&
                      this.state.backgroundTransparency === "transparent" &&
                      this.props.contentPosition === "left" &&
                      this.props.contentVerticalPosition === "top" &&
                      this.props.layoutType === "slide"
                    }
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    onSelect={() => {
                      this.props.updateContentPosition("left");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("top");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("box", "transparent");
                    }}
                  />
                  <RotatorViewTemplate
                    template={"box"}
                    transparency={"transparent"}
                    contentPosition={"right"}
                    contentVerticalPosition={"top"}
                    selected={
                      this.state.boxStyleCategory === "box" &&
                      this.state.backgroundTransparency === "transparent" &&
                      this.props.contentPosition === "right" &&
                      this.props.contentVerticalPosition === "top" &&
                      this.props.layoutType === "slide"
                    }
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    onSelect={() => {
                      this.props.updateContentPosition("right");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("top");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("box", "transparent");
                    }}
                  />
                  <RotatorViewTemplate
                    template={"box"}
                    transparency={"transparent"}
                    contentPosition={"left"}
                    contentVerticalPosition={"bottom"}
                    selected={
                      this.state.boxStyleCategory === "box" &&
                      this.state.backgroundTransparency === "transparent" &&
                      this.props.contentPosition === "left" &&
                      this.props.contentVerticalPosition === "bottom" &&
                      this.props.layoutType === "slide"
                    }
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    onSelect={() => {
                      this.props.updateContentPosition("left");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("bottom");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("box", "transparent");
                    }}
                  />
                  <RotatorViewTemplate
                    template={"box"}
                    transparency={"transparent"}
                    contentPosition={"right"}
                    contentVerticalPosition={"bottom"}
                    selected={
                      this.state.boxStyleCategory === "box" &&
                      this.state.backgroundTransparency === "transparent" &&
                      this.props.contentPosition === "right" &&
                      this.props.contentVerticalPosition === "bottom" &&
                      this.props.layoutType === "slide"
                    }
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    onSelect={() => {
                      this.props.updateContentPosition("right");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("bottom");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("box", "transparent");
                    }}
                  />

                  <RotatorViewTemplate
                    template={"box"}
                    transparency={"hidden"}
                    contentPosition={"left"}
                    contentVerticalPosition={"top"}
                    selected={
                      this.state.boxStyleCategory === "box" &&
                      this.state.backgroundTransparency === "hidden" &&
                      this.props.contentPosition === "left" &&
                      this.props.contentVerticalPosition === "top" &&
                      this.props.layoutType === "slide"
                    }
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    onSelect={() => {
                      this.props.updateContentPosition("left");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("top");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("box", "hidden");
                    }}
                  />
                  <RotatorViewTemplate
                    template={"box"}
                    transparency={"hidden"}
                    contentPosition={"right"}
                    contentVerticalPosition={"top"}
                    selected={
                      this.state.boxStyleCategory === "box" &&
                      this.state.backgroundTransparency === "hidden" &&
                      this.props.contentPosition === "right" &&
                      this.props.contentVerticalPosition === "top" &&
                      this.props.layoutType === "slide"
                    }
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    onSelect={() => {
                      this.props.updateContentPosition("right");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("top");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("box", "hidden");
                    }}
                  />
                  <RotatorViewTemplate
                    template={"box"}
                    transparency={"hidden"}
                    contentPosition={"left"}
                    contentVerticalPosition={"bottom"}
                    selected={
                      this.state.boxStyleCategory === "box" &&
                      this.state.backgroundTransparency === "hidden" &&
                      this.props.contentPosition === "left" &&
                      this.props.contentVerticalPosition === "bottom" &&
                      this.props.layoutType === "slide"
                    }
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    onSelect={() => {
                      this.props.updateContentPosition("left");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("bottom");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("box", "hidden");
                    }}
                  />
                  <RotatorViewTemplate
                    template={"box"}
                    transparency={"hidden"}
                    contentPosition={"right"}
                    contentVerticalPosition={"bottom"}
                    selected={
                      this.state.boxStyleCategory === "box" &&
                      this.state.backgroundTransparency === "hidden" &&
                      this.props.contentPosition === "right" &&
                      this.props.contentVerticalPosition === "bottom" &&
                      this.props.layoutType === "slide"
                    }
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    onSelect={() => {
                      this.props.updateContentPosition("right");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("bottom");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("box", "hidden");
                    }}
                  />
                </>
              }
              {this.state.boxStyleCategory === "onlyMedia" && this.props.layoutType === "slide" &&
                <>
                  <RotatorViewTemplate
                    template={"onlyMedia"}
                    selected={this.state.boxStyleCategory === "onlyMedia" && !this.props.keepOriginalImage && this.props.layoutType === "slide"}
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    contentPosition={this.props.contentPosition === "right" ? "right" : "left"}
                    transparency={this.state.backgroundTransparency}
                    onSelect={() => {
                      this.props.updateContentPosition("left");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("top");
                      };
                      this.props.updateKeepOriginalImage(false);
                      this.updateBoxStyle("onlyMedia", this.state.backgroundTransparency === "gradient" ? "transparent" : this.state.backgroundTransparency);
                    }}
                  />
                  <RotatorViewTemplate
                    template={"onlyMedia"}
                    disabled={!this.props.image && !this.props.video}
                    selected={this.state.boxStyleCategory === "onlyMedia" && this.props.keepOriginalImage && this.props.layoutType === "slide"}
                    keepOriginalImage
                    backgroundColor={this.props.backgroundColor}
                    textColor={this.props.textColor}
                    image={this.props.image}
                    video={this.props.video}
                    contentPosition={this.props.contentPosition === "right" ? "right" : "left"}
                    transparency={this.state.backgroundTransparency}
                    onSelect={() => {
                      this.props.updateContentPosition("left");
                      if (this.props.updateContentVerticalPosition) {
                        this.props.updateContentVerticalPosition("top");
                      };
                      this.props.updateKeepOriginalImage(true);
                      this.updateBoxStyle("onlyMedia", this.state.backgroundTransparency === "gradient" ? "transparent" : this.state.backgroundTransparency);
                    }}
                  />
                </>
              }
            </div>
          </>
        }

        {/* Width slider (only for box view) */
          (this.state.boxStyleCategory === "box") &&
          <IASlider
            label={this.localizationService.strings.PlayAdmin_ArticleEditorBoxWidth?.toUpperCase()}
            min={30}
            max={100}
            value={`${this.props.contentWidth}`}
            onChange={(value) => {
              if (this.props.updateContentWidth) {
                this.props.updateContentWidth(value);
              }
              this.forceUpdate();
            }}
            color={this.props.store.highlightColor}
            styles={{ width: "100%" }}
          />
        }
        {this.state.boxStyleCategory === "box" &&
          <>
            <IAToggle
              style={{
                marginTop: 20,
                width: "100%",
                clear: 'both',
                float: "left"
              }}
              label={this.localizationService.strings.PlayAdmin_ArticleEditorBoxRoundCorners?.toUpperCase()}
              checked={!!this.props.boxStyleRoundCorner}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              labelStyle={{
                fontSize: 12
              }}
              onChange={() => {
                if (this.props.updateBoxStyleRoundCorner) {
                  this.props.updateBoxStyleRoundCorner(!!!this.props.boxStyleRoundCorner)
                }
                this.forceUpdate();
              }}
            />
          </>
        }
      </div>
    );
  }


}