import * as React from 'react';
import * as styles from './ChristmasCountdown.css';
import "../../../../../styles/BasicIA.css";
import { IEnvironment } from '../../../../../interfaces/IEnvironment';
import { Environment } from '../../../../../services/Environment';
import QRCodeService from '../../../../../services/QRCodeService';
import { LocalizationService } from '../../../../../services/LocalizationService';
import PlayService from '../../../../../services/PlayService';
import { IWidgetSwitchStatus } from '../../../../../interfaces/IPlay';
import { Helper } from '../../../../../Helper';

export type TCountDirection = "up" | "down";

export interface IChristmasSettings {
  candleNumberColor: string;
  candleColor: "white" | "red" | "green" | "blue";
  showQRCode: boolean;
}

export interface IChristmasCountdownProps {
  countDirection: TCountDirection;
  title?: string;
  image?: string;
  backgroundColor?: string;
  titleColor?: string;
  sizeUnit?: number;
  christmasSettings?: IChristmasSettings;
  isVertical?: boolean;
  environment: IEnvironment;
  tenantId: string;
  profileId: string;
  widgetId?: string;
  playerId?: string;
  isActive: boolean;
}

export interface IChristmasCountdownState {
  qrCode?: string;
}

export class IAChristmasCountdown extends React.Component<IChristmasCountdownProps, IChristmasCountdownState> {

  private switchInterval: ReturnType<typeof setInterval> | undefined;
  private localizationService: LocalizationService = new LocalizationService();

  constructor(props: IChristmasCountdownProps) {
    super(props);
    this.state = {
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  componentDidMount(): void {
    this.makeQRCode();
    this.switchInterval = setInterval(() => {
      this.hasBeenLit();
    }, 2000);
  }

  private makeQRCode(): void {
    if (this.props.christmasSettings?.showQRCode) {
      try {
        let candleSwitchUrl = undefined;
        if (this.props.tenantId && this.props.profileId) {
          if (!this.props.widgetId) {
            // when there is no widgetId, that means the user is creating a new widget. Display a QR code to https://intraactive.net/products/replay/
            candleSwitchUrl = "https://intraactive.net/products/replay/";
          } else {
            candleSwitchUrl = `${Environment.getEnvironmentForPlayFrontend(this.props.environment)}?type=christmascountdown&widget=${this.props.widgetId}&player=${this.props.playerId}&switchstatus=${this.isCandleLit() ? "false" : "true"}`;
          }
          QRCodeService.generateQRCode(candleSwitchUrl).then((qrCode: string) => {
            if (qrCode) {
              this.setState({ qrCode });
            }
          })
        }
      } catch (error) {
        console.log(`%c${error}`, 'background: red; color: #ffffff')
      }
    }
  }

  private hasBeenLit(): void {
    if (this.props.isActive) {
      PlayService.isWidgetSwitched(this.props.environment, this.props.widgetId, this.props.playerId).then((widgetSwitchStatus: IWidgetSwitchStatus) => {
        if (widgetSwitchStatus) {
          if (widgetSwitchStatus.isActive) {
            Helper.setLocalStorage("IA_candleIsLit", widgetSwitchStatus.modified);
          } else {
            localStorage.removeItem("IA_candleIsLit");
          }
          this.makeQRCode();
          this.forceUpdate();
        }
      });
    }
  }

  private isToday(savedDate): boolean {
    const today = new Date()
    return savedDate.getDate() == today.getDate() &&
      savedDate.getMonth() == today.getMonth() &&
      savedDate.getFullYear() == today.getFullYear()
  }

  private isCandleLit(): boolean {
    let isCandleLit = false;
    if (this.props.christmasSettings?.showQRCode) {
      if (localStorage.getItem("IA_candleIsLit") && this.isToday(new Date(localStorage.getItem("IA_candleIsLit")))) {
        isCandleLit = true;
      }
    } else {
      isCandleLit = true;
    }
    return isCandleLit;
  }

  private getDaysToChristmas(): number {
    const today = new Date();
    var cmas = new Date(today.getFullYear(), 11, 25);
    if (today.getMonth() == 11 && today.getDate() > 25) {
      cmas.setFullYear(cmas.getFullYear() + 1);
    }
    var one_day = 1000 * 60 * 60 * 24;
    return Math.ceil((cmas.getTime() - today.getTime()) / (one_day)) - 1;
  }

  private getTodaysCount(): number {
    let todaysCount = 1
    const today = new Date();
    const dayInMonth = today.getDate();
    if (today.getMonth() == 11 && today.getDate() < 25) {
      todaysCount = dayInMonth;
    }
    return todaysCount;
  }

  public render(): JSX.Element {
    return (
      <>
        <div
          className={styles.IA_countdownBackground}
          style={{
            backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : "black",
            backgroundImage: this.props.image ? `url("${this.props.image}")` : "none"
          }}
        />
        <div className={styles.IA_content}>
          {this.props.title &&
            <div
              className={styles.headline}
              style={{
                color: this.props.titleColor ? this.props.titleColor : "white",
                fontSize: this.props.sizeUnit * 13,
                lineHeight: `${this.props.sizeUnit * 13}px`,
                padding: `0 ${this.props.sizeUnit * 1}px`
              }}
            >
              {this.props.title}
            </div>
          }
          {this.props.christmasSettings &&
            <div
              className={styles.candle}
              style={{
                backgroundImage: `url("https://intraactivestorage.blob.core.windows.net/cdn/play/candle-${this.props.christmasSettings?.candleColor ? this.props.christmasSettings?.candleColor : "white"}.png")`,
                left: `calc(50% -  ${this.props.sizeUnit * 7.5}px)`,
                bottom: this.props.isVertical ? this.props.sizeUnit * 4 : 0,
                width: this.props.sizeUnit * 15,
                height: this.props.sizeUnit * 24,
              }}
            >
              <div
                className={styles.candleNumbers}
                style={{
                  color: this.props.christmasSettings?.candleNumberColor,
                  fontSize: this.props.sizeUnit * 5.5,
                  top: this.props.sizeUnit * 6
                }}
              >
                <div
                  className={styles.candleNumber}
                  style={{
                    height: this.props.isVertical ? this.props.sizeUnit * 7 : this.props.sizeUnit * 6,
                  }}
                >
                  {this.props.countDirection === "up" ? (this.getTodaysCount() === 24 ? "🎄" : this.getTodaysCount()) : this.getDaysToChristmas()}
                </div>
                {!(this.props.countDirection === "up" && this.getTodaysCount() === 24) &&
                  <div
                    className={styles.candleNumber}
                    style={{
                      height: this.props.sizeUnit * 2,
                    }}
                  >
                    {this.props.countDirection === "up" ? (this.getTodaysCount() + 1 == 24 ? "🎄" : this.getTodaysCount() + 1) : this.getDaysToChristmas() - 1}
                  </div>
                }
              </div>
              <div
                className={styles.candleOverlay}
                style={{
                  backgroundImage: `url("https://intraactivestorage.blob.core.windows.net/cdn/play/candle-overlay-${this.props.christmasSettings?.candleColor ? this.props.christmasSettings?.candleColor : "white"}.png")`,
                }}
              />
              {this.isCandleLit() &&
                <div
                  className={styles.blinkingGlow}
                  style={{
                    width: this.props.isVertical ? this.props.sizeUnit * 18 : this.props.sizeUnit * 13,
                    height: this.props.isVertical ? this.props.sizeUnit * 20 : this.props.sizeUnit * 15,
                  }}
                />
              }
              <div
                className={styles.thread}
                style={{
                  width: this.props.isVertical ? this.props.sizeUnit * 0.5 : this.props.sizeUnit * 0.5,
                  height: this.props.isVertical ? this.props.sizeUnit * 3 : this.props.sizeUnit * 3,
                  top: -this.props.sizeUnit * 1.5,
                  background: this.isCandleLit() ? "linear-gradient(#d6994a, #4b232c, #121212, black, #e8bb31 90%)" : "linear-gradient(rgb(75 35 44), rgb(75, 35, 44), rgb(18, 18, 18), black, rgb(76 63 23) 90%)"
                }}
              />
              {this.isCandleLit() &&
                <div
                  className={styles.glow}
                  style={{
                    top: this.props.isVertical ? -this.props.sizeUnit * 4.5 : -this.props.sizeUnit * 6,
                    width: this.props.isVertical ? this.props.sizeUnit * 2.2 : this.props.sizeUnit * 1.8,
                    height: this.props.isVertical ? this.props.sizeUnit * 5 : this.props.sizeUnit * 6,
                  }}
                />
              }
              {this.isCandleLit() &&
                <div
                  className={styles.flame}
                  style={{
                    width: this.props.isVertical ? this.props.sizeUnit * 2.5 : this.props.sizeUnit * 2,
                    height: this.props.sizeUnit * 2,
                  }}
                />
              }
            </div>
          }
          {this.state.qrCode && this.props.christmasSettings?.showQRCode &&
            <div
              style={{
                position: "absolute",
                right: this.props.sizeUnit * 3,
                bottom: this.props.sizeUnit * 2,
                zIndex: 1000,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: this.props.sizeUnit * 13,
                borderRadius: this.props.sizeUnit * 1.5,
                backgroundColor: "#ffffff",
              }}
            >
              <div
                style={{
                  backgroundColor: "#ffffff",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#333333",
                  borderRadius: `${this.props.sizeUnit * 1.5}px ${this.props.sizeUnit * 1.5}px 0px 0px`,
                }}
              >
                <div
                  style={{
                    marginTop: 0,
                    fontWeight: "bold",
                    fontSize: this.props.sizeUnit * 1.3,
                    marginBottom: this.props.sizeUnit,
                    textAlign: "center",
                    paddingTop: this.props.sizeUnit,
                    boxSizing: "border-box",
                    paddingLeft: this.props.sizeUnit,
                    paddingRight: this.props.sizeUnit,
                  }}
                >
                  {this.isCandleLit() ? this.localizationService.strings.PlayAdmin_BlowOutCandle?.toUpperCase() : this.localizationService.strings.PlayAdmin_LitCandle?.toUpperCase()}
                </div>
                <img
                  src={this.state.qrCode}
                  style={{
                    width: this.props.sizeUnit * 11,
                    height: this.props.sizeUnit * 11,
                    paddingLeft: this.props.sizeUnit,
                    paddingRight: this.props.sizeUnit,
                    paddingBottom: this.props.sizeUnit,
                    backgroundColor: "#ffffff"
                  }}
                />
              </div>
              <div
                style={{
                  paddingTop: this.props.sizeUnit,
                  paddingBottom: this.props.sizeUnit,
                  backgroundColor: "#333333",
                  color: "#ffffff",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: `0px 0px ${this.props.sizeUnit * 1.5}px ${this.props.sizeUnit * 1.5}px`,
                  width: this.props.sizeUnit * 13,
                }}
              >
                <div
                  style={{
                    marginBottom: 0,
                    fontSize: this.props.sizeUnit * 1.2,
                    marginTop: 0,
                    fontWeight: "lighter"
                  }}
                >
                  <b>REPLAY</b> INTERACT
                </div>
              </div>
            </div>
          }
        </div>
      </>
    );
  }

  public componentWillUnmount(): void {
    clearInterval(this.switchInterval);
  }
}