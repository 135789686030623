import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as styles from './RotatorViewTemplate.css';
import { Store } from '../../../stores';
import { IPlayViewTemplate } from '../../../../../interfaces/IPlay';
import { Video } from '../../../../player/slide/video/Video';
import { PlayHelper } from '../../../PlayHelper';

export interface IProps {
  store?: Store;
  backgroundColor?: string;
  textColor?: string;
  listBackgroundColor?: string;
  listTextColor?: string;
  listBoxColor?: string;
  timeBatchBackgroundColor?: string;
  timeBatchTextColor?: string;
  timeBatchBorderColor?: string;
  template: IPlayViewTemplate;
  image?: string;
  video?: string;
  contentPosition?: "left" | "right";
  contentVerticalPosition?: "top" | "bottom";
  transparency?: "solid" | "transparent" | "gradient" | "hidden";
  keepOriginalImage?: boolean;
  selected: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
  onSelect: () => void;
}

export interface IState {
}

@inject('store')
@observer
export class RotatorViewTemplate extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }

  public render(): JSX.Element {
    let template;
    switch (this.props.template) {
      case "full":
        template = (
          <div className={styles.IA_template}>
            {/* Media */}
            <div style={{
              width: 148,
              height: 87,
              borderRadius: "inherit",
              position: "absolute"
            }}>
              <img
                src={this.props.image ? this.props.image : "https://intraactivestorage.blob.core.windows.net/cdn/play/no-image.webp"}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  borderRadius: "inherit",
                }}
              />
              {this.props.video &&
                <Video
                  type={"video"}
                  videoUrl={PlayHelper.getVideoFromIntranetApiIfVideoIsFromSharepoint(this.props.store.environment, this.props.video)}
                  width={148}
                  height={87}
                  marginLeft={0}
                  marginTop={0}
                  environment={this.props.store.environment}
                  profileId={this.props.store.profile.id}
                  tenantId={this.props.store.tenantId}
                  autoPlay={false}
                  style={{
                    borderRadius: 5,
                    overflow: "hidden"
                  }}
                  willRestart={() => { }}
                />
              }
            </div>
            {/* Background box */}
            <div
              style={{
                position: "absolute",
                backgroundColor: this.props.transparency === "hidden" ? "transparent" : this.props.backgroundColor,
                opacity: this.props.transparency === "transparent" ? 0.75 : 1,
                width: 148,
                height: 87,
                borderRadius: "inherit"
              }}
            />
            {/* Content */}
            <div style={{
              width: 148,
              height: 87,
              float: this.props.contentPosition,
            }}>
              <div
                className={styles.IA_headline}
                style={{
                  backgroundColor: this.props.textColor,
                  marginTop: 20
                }}
              />
              <div
                className={styles.IA_headline}
                style={{
                  backgroundColor: this.props.textColor,
                  width: "50%",
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.textColor,
                  marginTop: 10
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.textColor,
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.textColor,
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.textColor,
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.textColor,
                  width: "50%",
                }}
              />
            </div>
          </div>
        )
        break;
      case "half":
        template = (
          <div className={styles.IA_template}>
            {/* Media */}
            <div style={{
              width: this.props.transparency === "gradient" || this.props.transparency === "transparent" || this.props.transparency === "hidden" ? 148 : 74,
              height: 87,
              float: this.props.contentPosition === "right" ? "left" : "right",
              borderRadius: "inherit",
              position: this.props.transparency === "gradient" || this.props.transparency === "transparent" || this.props.transparency === "hidden" ? "absolute" : "relative"
            }}>
              {!this.props.video &&
                <img
                  src={this.props.image ? this.props.image : "https://intraactivestorage.blob.core.windows.net/cdn/play/no-image.webp"}
                  style={{
                    width: this.props.keepOriginalImage ? "calc(100% - 6px)" : "100%",
                    height: this.props.keepOriginalImage ? "calc(100% - 6px)" : "100%",
                    marginTop: this.props.keepOriginalImage ? "6px" : "0px",
                    marginLeft: this.props.keepOriginalImage && this.props.contentPosition === "right" ? "6px" : "0px",
                    objectFit: this.props.keepOriginalImage ? "contain" : "cover",
                    position: "absolute",
                    borderRadius: "inherit",
                    backgroundColor: this.props.backgroundColor
                  }}
                />
              }
              {this.props.video &&
                <Video
                  type={"video"}
                  videoUrl={PlayHelper.getVideoFromIntranetApiIfVideoIsFromSharepoint(this.props.store.environment, this.props.video)}
                  width={this.props.keepOriginalImage ? "calc(100% - 6px)" : 155}
                  height={this.props.keepOriginalImage ? "calc(100% - 6px)" : 87}
                  marginLeft={this.props.transparency === "gradient" || this.props.transparency === "transparent" || this.props.transparency === "hidden" ? 0 : (this.props.keepOriginalImage ? (this.props.contentPosition === "right" ? 6 : 0) : -35)}
                  marginTop={this.props.keepOriginalImage ? 6 : 0}
                  environment={this.props.store.environment}
                  profileId={this.props.store.profile.id}
                  tenantId={this.props.store.tenantId}
                  autoPlay={false}
                  style={{
                    overflow: "hidden"
                  }}
                  willRestart={() => { }}
                />
              }
            </div>
            {/* Background box */}
            <div
              style={{
                position: "absolute",
                background: this.props.transparency === "gradient" ? (this.props.contentPosition === "left" ? `linear-gradient(-90deg,${this.props.backgroundColor}00 0%, ${this.props.backgroundColor}ba 20%, ${this.props.backgroundColor}` : `linear-gradient(90deg,${this.props.backgroundColor}00 0%, ${this.props.backgroundColor}ba 20%, ${this.props.backgroundColor}`) : "unset",
                backgroundColor: this.props.transparency !== "gradient" ? this.props.backgroundColor : "unset",
                opacity: this.props.transparency === "transparent" ? 0.75 : 1,
                display: this.props.transparency === "hidden" ? "none" : "block",
                width: this.props.transparency === "gradient" ? 100 : 74,
                marginLeft: this.props.contentPosition === "left" ? 0 : (this.props.transparency === "gradient" ? 55 : 74),
                height: 87,
                borderRadius: "inherit"
              }}
            />
            {/* Content */}
            <div style={{
              width: 74,
              height: 87,
              float: this.props.contentPosition,
            }}>
              <div
                className={styles.IA_headline}
                style={{
                  backgroundColor: this.props.textColor,
                  marginTop: 20
                }}
              />
              <div
                className={styles.IA_headline}
                style={{
                  backgroundColor: this.props.textColor,
                  width: "50%",
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.textColor,
                  marginTop: 10
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.textColor,
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.textColor,
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.textColor,
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.textColor,
                  width: "50%",
                }}
              />
            </div>
          </div>
        )
        break;
      case "box":
        template = (
          <div className={styles.IA_template}>
            {/* Media */}
            <div style={{
              width: 148,
              height: 87,
              float: this.props.contentPosition === "right" ? "left" : "right",
              position: this.props.transparency === "gradient" || this.props.transparency === "transparent" || this.props.transparency === "hidden" ? "absolute" : "relative"
            }}>
              {!this.props.video &&
                <img
                  src={this.props.image ? this.props.image : "https://intraactivestorage.blob.core.windows.net/cdn/play/no-image.webp"}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "inherit",
                  }}
                />
              }
              {this.props.video &&
                <Video
                  type={"video"}
                  videoUrl={PlayHelper.getVideoFromIntranetApiIfVideoIsFromSharepoint(this.props.store.environment, this.props.video)}
                  width={155}
                  height={87}
                  marginLeft={0}
                  marginTop={0}
                  environment={this.props.store.environment}
                  profileId={this.props.store.profile.id}
                  tenantId={this.props.store.tenantId}
                  autoPlay={false}
                  style={{
                    borderRadius: 5,
                    overflow: "hidden"
                  }}
                  willRestart={() => { }}
                />
              }
            </div>
            {/* Content */}
            <div style={{
              width: 70,
              height: 35,
              backgroundColor: this.props.transparency === "hidden" ? "transparent" : (this.props.transparency !== "gradient" ? (this.props.transparency === "transparent" ? `${this.props.backgroundColor}94` : this.props.backgroundColor) : "unset"),
              marginLeft: this.props.contentPosition === "left" ? 10 : 75,
              marginTop: this.props.contentVerticalPosition === "top" ? 10 : 43,
              position: "absolute"
            }}>
              <div
                className={styles.IA_headline}
                style={{
                  backgroundColor: this.props.textColor,
                  marginTop: 7
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.textColor,
                  marginTop: 7
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.textColor,
                  width: "50%",
                }}
              />
            </div>
          </div>
        )
        break;
      case "onlyMedia":
        template = (
          <div className={styles.IA_template}>
            {/* Media */}
            <div style={{
              width: 148,
              height: 87,
              position: "relative"
            }}>
              {!this.props.video &&
                <img
                  src={this.props.image ? this.props.image : "https://intraactivestorage.blob.core.windows.net/cdn/play/no-image.webp"}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: this.props.keepOriginalImage ? "contain" : "cover",
                    borderRadius: "inherit",
                  }}
                />
              }
              {this.props.video &&
                <Video
                  type={"video"}
                  videoUrl={PlayHelper.getVideoFromIntranetApiIfVideoIsFromSharepoint(this.props.store.environment, this.props.video)}
                  width={155}
                  height={87}
                  marginLeft={0}
                  marginTop={0}
                  environment={this.props.store.environment}
                  profileId={this.props.store.profile.id}
                  tenantId={this.props.store.tenantId}
                  autoPlay={false}
                  style={{
                    borderRadius: 5,
                    overflow: "hidden"
                  }}
                  willRestart={() => { }}
                />
              }
            </div>
          </div>
        )
        break;
      case "postcard":
        template = (
          <div className={styles.IA_template}>
            {/* Background box */}
            <div
              style={{
                position: "absolute",
                backgroundColor: this.props.backgroundColor,
                width: 148,
                height: 87,
                borderRadius: "inherit"
              }}
            />
            {/* White box */}
            <div style={{
              width: 143,
              marginLeft: 6,
              marginTop: 6,
              height: 75.5,
              borderRadius: 6,
              position: "absolute",
              backgroundColor: "#ffffff"
            }}>
              {/* Media */}
              <div style={{
                width: 82.5,
                height: 67,
                float: "left",
                marginTop: 4,
                marginLeft: 4
              }}>
                {!this.props.video &&
                  <img
                    src={this.props.image ? this.props.image : "https://intraactivestorage.blob.core.windows.net/cdn/play/no-image.webp"}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                }
                {this.props.video &&
                  <Video
                    type={"video"}
                    videoUrl={PlayHelper.getVideoFromIntranetApiIfVideoIsFromSharepoint(this.props.store.environment, this.props.video)}
                    width={82.5}
                    height={67}
                    marginLeft={0}
                    marginTop={0}
                    environment={this.props.store.environment}
                    profileId={this.props.store.profile.id}
                    tenantId={this.props.store.tenantId}
                    autoPlay={false}
                    willRestart={() => { }}
                  />
                }
              </div>
              {/* Content */}
              <div style={{
                width: 56,
                marginTop: 5,
                height: 67,
                float: "right"
              }}>
                <div
                  className={styles.IA_headline}
                  style={{
                    backgroundColor: "#333333",
                    marginTop: 10
                  }}
                />
                <div
                  className={styles.IA_headline}
                  style={{
                    backgroundColor: "#333333",
                    width: "50%",
                  }}
                />
                <div
                  className={styles.IA_paragraphLine}
                  style={{
                    backgroundColor: "#333333",
                    marginTop: 10
                  }}
                />
                <div
                  className={styles.IA_paragraphLine}
                  style={{
                    backgroundColor: "#333333",
                  }}
                />
                <div
                  className={styles.IA_paragraphLine}
                  style={{
                    backgroundColor: "#333333",
                  }}
                />
                <div
                  className={styles.IA_paragraphLine}
                  style={{
                    backgroundColor: "#333333",
                    width: "50%",
                  }}
                /></div>
            </div>
          </div>
        )
        break;
      case "list":
        template = (
          <div className={styles.IA_template}>
            {/* Media */}
            <div style={{
              width: 148,
              height: 87,
              borderRadius: "inherit",
              position: "absolute"
            }}>
              <img
                src={this.props.image ? this.props.image : "https://intraactivestorage.blob.core.windows.net/cdn/play/no-image.webp"}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  borderRadius: "inherit",
                }}
              />
              {this.props.video &&
                <Video
                  type={"video"}
                  videoUrl={PlayHelper.getVideoFromIntranetApiIfVideoIsFromSharepoint(this.props.store.environment, this.props.video)}
                  width={148}
                  height={87}
                  marginLeft={0}
                  marginTop={0}
                  environment={this.props.store.environment}
                  profileId={this.props.store.profile.id}
                  tenantId={this.props.store.tenantId}
                  autoPlay={false}
                  style={{
                    borderRadius: 5,
                    overflow: "hidden"
                  }}
                  willRestart={() => { }}
                />
              }
            </div>
            {/* Background box */}
            <div
              style={{
                position: "absolute",
                backgroundColor: this.props.transparency === "hidden" ? "transparent" : this.props.listBackgroundColor,
                opacity: this.props.transparency === "transparent" ? 0.75 : 1,
                width: 148,
                height: 87,
                borderRadius: "inherit"
              }}
            />
            {/* List element */}
            <div style={{
              marginTop: 5,
              paddingBottom: 5,
              width: 80,
              marginLeft: 30,
              float: "left",
              backgroundColor: this.props.listBoxColor,
              position: "relative"
            }}>
              <div style={{
                marginTop: 5,
                marginLeft: 5,
                width: 10,
                height: 10,
                float: "left",
                backgroundColor: this.props.timeBatchBackgroundColor,
                position: "relative",
                border: `1px solid ${this.props.timeBatchBorderColor}`,
                boxSizing: "border-box"
              }}>
                <div
                  className={styles.IA_paragraphLine}
                  style={{
                    backgroundColor: this.props.timeBatchTextColor,
                    width: 6,
                    marginLeft: 1,
                    marginTop: 2
                  }}
                />
                <div
                  className={styles.IA_paragraphLine}
                  style={{
                    backgroundColor: this.props.timeBatchTextColor,
                    width: 6,
                    marginLeft: 1,
                    marginTop: 1
                  }}
                />
              </div>
              <div
                className={styles.IA_headline}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 5,
                  width: 50
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  width: 50
                }}
              />
            </div>
            {/* List element */}
            <div style={{
              marginTop: 5,
              paddingBottom: 5,
              width: 80,
              marginLeft: 30,
              float: "left",
              backgroundColor: this.props.listBoxColor,
              position: "relative"
            }}>
              <div style={{
                marginTop: 5,
                marginLeft: 5,
                width: 10,
                height: 10,
                float: "left",
                backgroundColor: this.props.timeBatchBackgroundColor,
                position: "relative",
                border: `1px solid ${this.props.timeBatchBorderColor}`,
                boxSizing: "border-box"
              }}>
                <div
                  className={styles.IA_paragraphLine}
                  style={{
                    backgroundColor: this.props.timeBatchTextColor,
                    width: 6,
                    marginLeft: 1,
                    marginTop: 2
                  }}
                />
                <div
                  className={styles.IA_paragraphLine}
                  style={{
                    backgroundColor: this.props.timeBatchTextColor,
                    width: 6,
                    marginLeft: 1,
                    marginTop: 1
                  }}
                />
              </div>
              <div
                className={styles.IA_headline}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 5,
                  width: 50
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  width: 50
                }}
              />
            </div>
            {/* List element */}
            <div style={{
              marginTop: 5,
              paddingBottom: 5,
              width: 80,
              marginLeft: 30,
              float: "left",
              backgroundColor: this.props.listBoxColor,
              position: "relative"
            }}>
              <div style={{
                marginTop: 5,
                marginLeft: 5,
                width: 10,
                height: 10,
                float: "left",
                backgroundColor: this.props.timeBatchBackgroundColor,
                position: "relative",
                border: `1px solid ${this.props.timeBatchBorderColor}`,
                boxSizing: "border-box"
              }}>
                <div
                  className={styles.IA_paragraphLine}
                  style={{
                    backgroundColor: this.props.timeBatchTextColor,
                    width: 6,
                    marginLeft: 1,
                    marginTop: 2
                  }}
                />
                <div
                  className={styles.IA_paragraphLine}
                  style={{
                    backgroundColor: this.props.timeBatchTextColor,
                    width: 6,
                    marginLeft: 1,
                    marginTop: 1
                  }}
                />
              </div>
              <div
                className={styles.IA_headline}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 5,
                  width: 50
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  width: 50
                }}
              />
            </div>
          </div>
        )
        break;
      case "week":
        template = (
          <div className={styles.IA_template}>
            {/* Media */}
            <div style={{
              width: 148,
              height: 87,
              borderRadius: "inherit",
              position: "absolute"
            }}>
              <img
                src={this.props.image ? this.props.image : "https://intraactivestorage.blob.core.windows.net/cdn/play/no-image.webp"}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  borderRadius: "inherit",
                }}
              />
              {this.props.video &&
                <Video
                  type={"video"}
                  videoUrl={PlayHelper.getVideoFromIntranetApiIfVideoIsFromSharepoint(this.props.store.environment, this.props.video)}
                  width={148}
                  height={87}
                  marginLeft={0}
                  marginTop={0}
                  environment={this.props.store.environment}
                  profileId={this.props.store.profile.id}
                  tenantId={this.props.store.tenantId}
                  autoPlay={false}
                  style={{
                    borderRadius: 5,
                    overflow: "hidden"
                  }}
                  willRestart={() => { }}
                />
              }
            </div>
            {/* Background box */}
            <div
              style={{
                position: "absolute",
                backgroundColor: this.props.transparency === "hidden" ? "transparent" : this.props.listBackgroundColor,
                opacity: this.props.transparency === "transparent" ? 0.75 : 1,
                width: 148,
                height: 87,
                borderRadius: "inherit"
              }}
            />
            {/* List element */}
            <div style={{
              marginTop: 5,
              width: 25,
              height: 76,
              marginLeft: 7,
              float: "left",
              backgroundColor: new Date().getDay() === 1 ? this.props.listBoxColor :  "transparent",
              position: "relative"
            }}>
              <div
                className={styles.IA_headline}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 5,
                  marginLeft: 2,
                  width: 16
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 5,
                  marginLeft: 2,
                  width: 16
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 3,
                  marginLeft: 2,
                  width: 16
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 3,
                  marginLeft: 2,
                  width: 16
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 3,
                  marginLeft: 2,
                  width: 10
                }}
              />
            </div>
            {/* List element */}
            <div style={{
              marginTop: 5,
              width: 25,
              height: 76,
              marginLeft: 2,
              float: "left",
              backgroundColor: new Date().getDay() === 2 ? this.props.listBoxColor :  "transparent",
              position: "relative"
            }}>
              <div
                className={styles.IA_headline}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 5,
                  marginLeft: 2,
                  width: 16
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 5,
                  marginLeft: 2,
                  width: 16
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 3,
                  marginLeft: 2,
                  width: 16
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 3,
                  marginLeft: 2,
                  width: 16
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 3,
                  marginLeft: 2,
                  width: 10
                }}
              />
            </div>
            {/* List element */}
            <div style={{
              marginTop: 5,
              width: 25,
              height: 76,
              marginLeft: 2,
              float: "left",
              backgroundColor: new Date().getDay() === 3 ? this.props.listBoxColor :  "transparent",
              position: "relative"
            }}>
              <div
                className={styles.IA_headline}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 5,
                  marginLeft: 2,
                  width: 16
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 5,
                  marginLeft: 2,
                  width: 16
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 3,
                  marginLeft: 2,
                  width: 16
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 3,
                  marginLeft: 2,
                  width: 16
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 3,
                  marginLeft: 2,
                  width: 10
                }}
              />
            </div>
            {/* List element */}
            <div style={{
              marginTop: 5,
              width: 25,
              height: 76,
              marginLeft: 2,
              float: "left",
              backgroundColor: new Date().getDay() === 4 ? this.props.listBoxColor :  "transparent",
              position: "relative"
            }}>
              <div
                className={styles.IA_headline}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 5,
                  marginLeft: 2,
                  width: 16
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 5,
                  marginLeft: 2,
                  width: 16
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 3,
                  marginLeft: 2,
                  width: 16
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 3,
                  marginLeft: 2,
                  width: 16
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 3,
                  marginLeft: 2,
                  width: 10
                }}
              />
            </div>
            {/* List element */}
            <div style={{
              marginTop: 5,
              width: 25,
              height: 76,
              marginLeft: 2,
              float: "left",
              backgroundColor: new Date().getDay() === 5 ? this.props.listBoxColor :  "transparent",
              position: "relative"
            }}>
              <div
                className={styles.IA_headline}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 5,
                  marginLeft: 2,
                  width: 16
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 5,
                  marginLeft: 2,
                  width: 16
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 3,
                  marginLeft: 2,
                  width: 16
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 3,
                  marginLeft: 2,
                  width: 16
                }}
              />
              <div
                className={styles.IA_paragraphLine}
                style={{
                  backgroundColor: this.props.listTextColor,
                  marginTop: 3,
                  marginLeft: 2,
                  width: 10
                }}
              />
            </div>
          </div>
        )
        break;
      default:
        break;
    }
    return (
      <div
        className={styles.IA_rotatorViewTemplate}
        style={{
          backgroundColor: this.props.backgroundColor,
          ...this.props.style,
          opacity: this.props.disabled ? 0.3 : 1,
          cursor: this.props.disabled ? "default" : "pointer",
        }}
        onClick={this.props.disabled ? undefined : () => this.props.onSelect()}
      >
        {template}
        <div style={{
          backgroundColor: this.props.selected ? "#fc8a00" : "#ffffff80",
          width: 16,
          height: 16,
          borderRadius: 16,
          position: 'relative',
          marginRight: 10,
          marginTop: -79,
          border: "1px solid #ffffff",
          zIndex: 2000000,
          float: "right"
        }}>

        </div>
      </div>
    );
  }


}