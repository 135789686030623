import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import { IADropDown } from '../../../../../dropDown/DropDown';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IPlayProfile, IPowerBIWorkspace } from '../../../../../../interfaces/IPlay';
import PlayService from '../../../../../../services/PlayService';
import { IASpinner } from '../../../../../spinner/Spinner';
import * as styles from '../IntegrationsEditPanel.css';
import { Helper } from '../../../../../../Helper';
import { IACheckbox } from '../../../../../checkbox/Checkbox';
import { IAButton } from '../../../../../button/Button';

export interface IProps {
  store?: Store;
  profiles: IPlayProfile[];
  isEditingExistingIntegration: boolean;
  onProfileChange: (profile) => void;
  onPowerBiWorkspaceChosen: (workspace: IPowerBIWorkspace) => void;
}

export interface IState {
  availableFeeds: IPowerBIWorkspace[];
  isLoading: boolean;
  errorOccurred: boolean;
  isPowerbiUpdating?: boolean;
}

@inject('store')
@observer
export class PowerBIIntegrationComponent extends React.Component<IProps, IState> {
  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      availableFeeds: [],
      isLoading: true,
      errorOccurred: false
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentDidMount(): void {
    PlayService.getPowerBIWorkspaces(this.props.store.environment, this.props.store.token, this.props.store.tenantId)
      .then(availableFeeds => { 
        if(availableFeeds == undefined){
          this.setState({errorOccurred: true});
        }else{
          this.setState({ availableFeeds}); 
        }
        } ,
      () => { this.setState({errorOccurred: true})})
      .finally(() => this.setState({ isLoading: false }));
  }

  private getPowerBIWorkspaces(){
    this.setState({ isPowerbiUpdating: true });
    PlayService.getPowerBIWorkspaces(this.props.store.environment, this.props.store.token, this.props.store.tenantId)
      .then(availableFeeds => { 
        if(availableFeeds == undefined){
          this.setState({errorOccurred: true});
        }else{
          this.setState({ availableFeeds}); 
        }
        } ,
      () => { this.setState({errorOccurred: true})})
      .finally(() => this.setState({ isPowerbiUpdating: false }));
  }
  public render(): JSX.Element {
    return (
      <>
        {this.state.isLoading ?
          <IASpinner color={this.props.store.highlightColor}/>
        :
          <>
            <IADropDown
              label={this.localizationService.strings.PlayAdmin_SelectAProfileToAccess?.toUpperCase()}
              selectedOption={this.props.store.integrationToEdit?.profileId}
              options={[{
                key: undefined,
                value: this.localizationService.strings.PlayAdmin_SelectAProfileToAccess,
                disabled: true
              },
              ...this.props.profiles.map(profile => { return { key: profile.id, value: profile.title } })
              ]}
              disabled={this.props.isEditingExistingIntegration}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              onChange={(profile) => {
                this.props.onProfileChange(profile)
              }}
              style={{
                float: "left",
                width: 340
              }}
              borderRadius={5}
            />
          <IAButton
              borderRadius={5}
              label=""
              onClick={ () => {
               this.getPowerBIWorkspaces();
              }}
              style={{
                  position: "absolute",
                  right: 30,
                  backgroundColor: "transparent"
              }}
              showSpinner={this.state.isPowerbiUpdating}
              height={40}
              iconMarginTop={8}
              iconUrl='https://intraactivestorage.blob.core.windows.net/cdn/icons/Arrow Clockwise/SVG/ic_fluent_arrow_clockwise_48_regular.svg'
              buttonColor="#f4f4f4"
              disbaled={!this.props.store.integrationToEdit?.profileId}
            />   
        {(this.state.errorOccurred || this.state.availableFeeds?.length === 0) && 
              <div style={{ clear: "both", float: "left", marginTop: 15, fontStyle: "italic", width: "100%" }}>{this.localizationService.strings.PlayAdmin_PowerBI_Workspases_Message1} <a href="https://docs.intraactive.net/intraactive-replay/integrations/#section-header-two-2ve33" target='_blank'>{this.localizationService.strings.PlayAdmin_PowerBI_Workspases_Message2}</a> {this.localizationService.strings.PlayAdmin_PowerBI_Workspases_Message3}</div>
            }
           {this.state.errorOccurred && <div style={{ clear: "both", float: "left", marginTop: 15, fontStyle: "italic", width: "100%" }}>{this.localizationService.strings.PlayAdmin_PowerBI_ValidationError}</div>}
            {this.props.store.integrationToEdit?.profileId && !this.state.errorOccurred && this.state.availableFeeds && this.state.availableFeeds.length > 0 &&
              <div className={styles.IA_feeds} style={{ marginTop: 30 }}>
                <label>{this.localizationService.strings.PlayAdmin_PowerBI_SelectWorkspaces?.toLocaleUpperCase()}</label>
                {this.state.availableFeeds?.map((workspace: IPowerBIWorkspace) => {
                  const id = Helper.getRandomStringKey();
                  return (
                    <div
                      id={id}
                      className={styles.IA_feed_element}
                      onMouseOver={() => {
                        document.getElementById(id).style.backgroundColor = this.props.store.darkMode ? "#393939" : "#e6e6e6";
                      }}
                      onMouseOut={() => {
                        document.getElementById(id).style.backgroundColor = "";
                      }}
                    >
                      <IACheckbox
                        key={workspace.id}
                        label={workspace.name}
                        highlightColor={this.props.store.highlightColor}
                        isChecked={this.props.store.integrationToEdit?.powerBiWorkspaces?.findIndex(w => w.id == workspace.id) !== -1}
                        onChange={() => this.props.onPowerBiWorkspaceChosen(workspace)}
                        styles={{
                          padding: 10
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            }
          </>
        }
      </>
    );
  }
}