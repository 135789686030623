exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_newComment_3btQD5JBvRtFrJQ1KEMj3w {\n  width: calc(100% - 10px);\n  height: auto;\n  position: relative;\n  float: left;\n  color: #333333;\n  margin-top: 5px;\n  margin-bottom: 20px;\n  margin-left: 10px;\n}\n\n\n.IA_postBtn_FTsJJkcRdhmtnN-t-9fPz, .IA_cancelBtn_cxrumNGfOKEP8iGethqhd {\n  margin-top: 5px;\n  float: right;\n}\n\n.IA_cancelBtn_cxrumNGfOKEP8iGethqhd {\n  margin-right: 5px;\n}\n\n.IA_commentProfileImageWrapper_1O8lVSQ9Rn3YMdtm71gSD6 {\n  height: 30px;\n  width: 30px;\n  float: left;\n  border-radius: 30px;\n  margin-right: 10px;\n  margin-top: 11px;\n  font-size: 15px;\n  text-align: center;\n  line-height: 29px;\n  text-transform: uppercase;\n  font-weight: 200;\n}\n\n.IA_commentProfileImagePicture_2jwz1VqAJMCEDzVwe0TA9V {\n  height: 32px;\n  width: 32px;\n  border-radius: 30px;\n  background-position: center;\n  background-size: cover;\n  margin-top: -32px;\n  left: -1px;\n  position: relative;\n  float: left;\n}\n\n.IA_commentProfileImageText_2Exf0MW34dXhiU2bKWUD2I {\n  height: 30px;\n  width: 30px;\n  border-radius: 30px;\n  left: 0px;\n  top: 0px;\n  position: relative;\n  float: left;\n}\n\n.IA_commentReplyMobilePopup_1nKPb_XirXFXUsOPPcNGrv {\n  display: flex;\n  flex-direction: column;\n}\n\n.IA_commentReplyMobilePopup_1nKPb_XirXFXUsOPPcNGrv [class=\"fr-element fr-view\"] {\n  max-height: 125px;\n}", ""]);

// Exports
exports.locals = {
	"IA_newComment": "IA_newComment_3btQD5JBvRtFrJQ1KEMj3w",
	"IA_postBtn": "IA_postBtn_FTsJJkcRdhmtnN-t-9fPz",
	"IA_cancelBtn": "IA_cancelBtn_cxrumNGfOKEP8iGethqhd",
	"IA_commentProfileImageWrapper": "IA_commentProfileImageWrapper_1O8lVSQ9Rn3YMdtm71gSD6",
	"IA_commentProfileImagePicture": "IA_commentProfileImagePicture_2jwz1VqAJMCEDzVwe0TA9V",
	"IA_commentProfileImageText": "IA_commentProfileImageText_2Exf0MW34dXhiU2bKWUD2I",
	"IA_commentReplyMobilePopup": "IA_commentReplyMobilePopup_1nKPb_XirXFXUsOPPcNGrv"
};