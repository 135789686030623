import * as React from 'react';
import IaFacebookLogin from './IaFacebookLogin';
import { LocalizationService } from '../../services/LocalizationService';
import PlayService from '../../services/PlayService';
import { IEnvironment } from '../../interfaces/IEnvironment';
import { IFacebookPageIntegration } from '../../interfaces/IPlay';
import { IAIcon } from '../icon/Icon';

export interface MngFacebookPermissionsProps {
  tenantId: string;
  profileId: string;
  environment: string;
}

export interface MngFacebookPermissionsState {
  pages: IFacebookPageIntegration[],
  translationsReady: boolean
}

export class MngFacebookPermissions extends React.Component<MngFacebookPermissionsProps, MngFacebookPermissionsState> {
  private localizationService: LocalizationService = new LocalizationService();

  constructor(props: MngFacebookPermissionsProps) {
    super(props);
    this.setState({translationsReady: false});
    setTimeout(() => {
    this.localizationService = new LocalizationService();
    this.setState({translationsReady: true});
    }, 500);
  }

  public updateProfileWithFacebookToken(userId: string, userToken: string) {

    if (!!userId && !!userToken && !!this.props.tenantId && !!this.props.profileId && !!this.props.environment) {
      PlayService.connectFacebookPages(this.props.environment as IEnvironment, this.props.tenantId, userId, userToken).then((data) => {
        this.setState({ pages: data });
      }, (error) => {
        console.log(error);
      });
    }

  }

  public render(): JSX.Element {

    return (
      <div style={{ width: "100vw", height: "100vh", display: this?.state?.translationsReady ? "flex" : "none", justifyContent: "center", alignItems: "center", backgroundColor: "black" }}>
        <div style={{ backgroundColor: "white", padding: 20, borderRadius: 15 }}>
          <div style={{fontSize: 22, fontWeight:"bold"}}>{this.localizationService.strings.PlayAdmin_Facebook_Page_Permissions}</div>

          {
            (!this.state?.pages || this.state?.pages.length == 0) &&
            <>
              <p style={{ fontWeight: "bold" }}>{this.localizationService.strings.PlayAdmin_Facebook_Permissions_Message1}</p> 
              <p>{this.localizationService.strings.PlayAdmin_Facebook_Permissions_Message2}</p>
              <IaFacebookLogin
                disabled={false}
                buttonText={this.localizationService?.strings?.PlayAdmin_Facebook_Login}
                onChange={(userToken: string, userId: string) => this.updateProfileWithFacebookToken(userId, userToken)}
              />
            </>

          }

          {this.state?.pages?.length > 0 && <>
            <p>{this.localizationService.strings.PlayAdmin_Facebook_Permissions_Message3}:</p>
            {this.state.pages?.map((page) => 
              <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                <IAIcon url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Checkmark%20Circle/SVG/ic_fluent_checkmark_circle_24_filled.svg" color='green' size={18} style={{marginTop:6, marginRight:5}}/>
                <span style={{fontWeight:"bold"}}>{page.pageName}</span>
              </div>)}

            <p>{this.localizationService.strings.PlayAdmin_Facebook_Permissions_Message4}</p>
          </>}
        </div>
      </div>


    );
  }


}
