exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Droid+Sans+Mono);", ""]);

// Module
exports.push([module.id, ".IA_counterBackground_126X0HT1QX0PkqaWn-QxeZ {\n  background-color: #4b234a;\n  background-size: cover;\n  background-position: center;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.IA_counterBackgroundOverlay_2RKrLfwbzdqESQUknorC-1 {\n  background-color: #4b234a;\n  background-size: cover;\n  background-position: center;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.IA_content_mG7c9Dg5EZE0G5X79MJfk {\n  width: 100%;\n  position: absolute;\n  color: #ffffff;\n  font-family: \"Segoe UI\", \"Segoe UI Web (West European)\", \"Segoe UI\", -apple-system, BlinkMacSystemFont, Roboto, \"Helvetica Neue\", sans-serif;\n}\n\n.IA_headline_2s4LKwpTjra7IgHmqK_gRE {\n  width: 100%;\n  text-align: center;\n  box-sizing: border-box;\n}\n\n.IA_description_1LJtQsIlzaDqfaVoRqOcgv {\n  width: 100%;\n  text-align: center;\n  box-sizing: border-box;\n}\n\n.IA_counter_MLRjTOFwUAit1n289X3EP {\n  display: flex;\n  flex-wrap: nowrap;\n  text-align: center;\n}\n\n.IA_days_TgjC_UHqPYj-LgEG2jUmL {\n  text-align: center;\n  flex-direction: row;\n}\n\n.IA_hours_sN9ATkTANHvzCgotE7TyV {\n  text-align: center;\n  flex-direction: row;\n}\n\n.IA_minuts_LxRMd7v_UGzavBHDo7hhK {\n  text-align: center;\n  flex-direction: row;\n}\n\n.IA_seconds_2fU1fe4jJkdkbrXv205hwt {\n  text-align: center;\n  flex-direction: row;\n}\n\n.IA_number_12gxikSx9JFWcRPhRvJb0o {\n  width: 100%;\n  font-family: \"Droid Sans Mono\", monospace;\n}\n\n.IA_numberInfo_QXcPw28g73BGBW5SCH9UY {\n  width: 100%;\n  font-weight: lighter;\n}", ""]);

// Exports
exports.locals = {
	"IA_counterBackground": "IA_counterBackground_126X0HT1QX0PkqaWn-QxeZ",
	"IA_counterBackgroundOverlay": "IA_counterBackgroundOverlay_2RKrLfwbzdqESQUknorC-1",
	"IA_content": "IA_content_mG7c9Dg5EZE0G5X79MJfk",
	"IA_headline": "IA_headline_2s4LKwpTjra7IgHmqK_gRE",
	"IA_description": "IA_description_1LJtQsIlzaDqfaVoRqOcgv",
	"IA_counter": "IA_counter_MLRjTOFwUAit1n289X3EP",
	"IA_days": "IA_days_TgjC_UHqPYj-LgEG2jUmL",
	"IA_hours": "IA_hours_sN9ATkTANHvzCgotE7TyV",
	"IA_minuts": "IA_minuts_LxRMd7v_UGzavBHDo7hhK",
	"IA_seconds": "IA_seconds_2fU1fe4jJkdkbrXv205hwt",
	"IA_number": "IA_number_12gxikSx9JFWcRPhRvJb0o",
	"IA_numberInfo": "IA_numberInfo_QXcPw28g73BGBW5SCH9UY"
};