import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { LocalizationService } from '../../../../../../../services/LocalizationService';
import { IATextField } from '../../../../../../textField/TextField';
import { IPlayTimeEditSearchObject, IPlayTimeEditType } from '../../../../../../../interfaces/IPlay';
import PlayService from '../../../../../../../services/PlayService';
// import { Popup } from '../../../../../reusableComponents/popup/Popup';
import { IADropDown } from '../../../../../../dropDown/DropDown';
import { IEnvironment } from '../../../../../../../interfaces/IEnvironment';
import { IASpinner } from '../../../../../../spinner/Spinner';
import { IAButton } from '../../../../../../button/Button';
import { Helper } from '../../../../../../../Helper';
import { TimeEditFilter } from './TimeEditFilter';
import { IAOptionSelector } from '../../../../../../optionSelector/OptionSelector';
import { IAPanel } from '../../../../../../panels';

export interface IProps {
  environment: IEnvironment;
  token: string;
  tenantId: string;
  profileId: string;
  types: IPlayTimeEditType[];
  darkMode: boolean;
  highlightColor: string;
  widgetFilterObjects: IPlayTimeEditSearchObject[];
  isOpen: boolean;
  onClosePopup: () => void;
  onApplyFilters: (objects: IPlayTimeEditSearchObject[]) => void;
}

export interface IState {
  selectedFilterType: "object" | "field";
  selectedTimeEditType: IPlayTimeEditType;
  filterObjectSearchQuery: string;
  isLoadingFilterObjects: boolean;
  isLoadingTypeFields: boolean;
  filterObjects: IPlayTimeEditSearchObject[];
  selectedFilterObjects: IPlayTimeEditSearchObject[];
  typeFields: string[];
  selectedTypeField: string;
  typeFieldSearchQuery: string;
}

@inject('store')
@observer
export class TimeEditFilterSelectorPanel extends React.Component<IProps, IState> {
  private queryDelay: NodeJS.Timeout;
  private readonly localizationService: LocalizationService = new LocalizationService();
  private readonly FILTERS_MAX_AMOUNT = 20;

  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedFilterType: "object",
      selectedTimeEditType: undefined,
      filterObjectSearchQuery: "",
      isLoadingFilterObjects: false,
      isLoadingTypeFields: false,
      filterObjects: [],
      selectedFilterObjects: [],
      typeFields: [],
      selectedTypeField: "",
      typeFieldSearchQuery: ""
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen) {
      this.setState({ selectedFilterObjects: this.props.widgetFilterObjects ? JSON.parse(JSON.stringify(this.props.widgetFilterObjects)) : [] });
    }
    if (prevProps.isOpen !== this.props.isOpen && !this.props.isOpen) {
      this.setState({
        selectedFilterType: "object",
        selectedTimeEditType: undefined,
        filterObjectSearchQuery: "",
        isLoadingFilterObjects: false,
        isLoadingTypeFields: false,
        filterObjects: [],
        selectedFilterObjects: [],
        typeFields: [],
        selectedTypeField: "",
        typeFieldSearchQuery: ""
      });
    }
  }

  private getTypeFields(){
    this.setState({isLoadingTypeFields: true}, () => {
      PlayService.getTimeEditTypeFields(this.props.environment, this.props.token, this.props.tenantId, this.props.profileId, this.state.selectedTimeEditType?.extId)
        .then(response => {
          this.setState({typeFields: response.fields});
        })
        .finally(() => {
          this.setState({isLoadingTypeFields: false});
        });
    });
  }

  private getFilterObjects(){
    this.setState({isLoadingFilterObjects: true}, () => {
      PlayService.getTimeEditFilterObjects(this.props.environment, this.props.token, this.props.tenantId, this.props.profileId, this.state.selectedTimeEditType.extId, this.state.filterObjectSearchQuery)
        .then(response => {
          this.setState({filterObjects: response.objects});
        })
        .finally(() => {
          this.setState({isLoadingFilterObjects: false});
        });
    });
  }

  private addFilterObjectToFilterList(filterObjects: IPlayTimeEditSearchObject[]) {
    const selected = this.state.selectedFilterObjects;
    filterObjects.forEach(filterObject => {
      if (!selected.find(o => JSON.stringify(o).toLocaleLowerCase() === JSON.stringify(filterObject).toLocaleLowerCase()) && this.state.selectedFilterObjects.length < this.FILTERS_MAX_AMOUNT) {
        filterObject.type = this.state.selectedTimeEditType.extId;
        selected.push(filterObject);
      }
    });
    this.setState({selectedFilterObjects: selected});
  }

  render(): React.ReactNode {
    return (
      <IAPanel
        close={() => this.props.onClosePopup()}
        darkMode={this.props.darkMode}
        title={this.localizationService.strings.PlayAdmin_TimeEditAddFilters?.toLocaleUpperCase()}
        open={this.props.isOpen}
        showNavigationBar
        width={600}
        transition="slide"
        footer={
          <div
            style={{
              display: "flex"
            }}
          >
            <IAButton
              label={this.localizationService.strings.PlayAdmin_TimeEditApplyFilters?.toLocaleUpperCase()}
              buttonColor={this.props.highlightColor}
              onClick={() => this.props.onApplyFilters(this.state.selectedFilterObjects)}
              borderRadius={5}
              style={{
                marginTop: 10,
                width: "fit-content",
                marginLeft: "auto"
              }}
            />
          </div>
        }
      >
        <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: 40,
              paddingRight: 40
            }}
          >
              <div style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: this.props.darkMode ? "rgba(255,255,255,0.1)" : "rgb(244, 244, 244)",
                borderRadius: 15,
                padding: 15
              }}>
                <p
                  style={{
                    marginBottom: 10,
                    color: this.props.darkMode ? Helper.darkModeLabelColor : undefined
                  }}
                >
                  {`${this.localizationService.strings.PlayAdmin_TimeEditSelectedFilters} (${this.state.selectedFilterObjects.length}/${this.FILTERS_MAX_AMOUNT})`}
                </p>
                {this.state.selectedFilterObjects.length === 0
                ?
                  <p style={{marginTop: 0}}><i>No filters selected yet</i></p>
                :
                  <div
                    style={{
                      width: "100%",
                      overflowY: "auto",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "flex-start",
                      height: "fit-content",
                      maxHeight: "100%",
                      justifyContent: "flex-start",
                      rowGap: 5,
                      columnGap: 5
                    }}
                  >
                    {this.state.selectedFilterObjects.map((filterObject, i) => filterObject.field?.[0]?.value 
                    ?
                      <TimeEditFilter
                        highlightColor={this.props.highlightColor}
                        widgetFilterObject={filterObject}
                        onClick={() => {
                          const selected = this.state.selectedFilterObjects;
                          selected.splice(i, 1);
                          this.setState({selectedFilterObjects: selected});
                        }}
                      />
                    : 
                      <></>
                    )}
                  </div>
                }
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <IAOptionSelector
                  highlightColor={this.props.highlightColor}
                  darkMode={this.props.darkMode}
                  options={[
                    {
                      key: "object",
                      text: this.localizationService.strings.PlayAdmin_TimeEditFilterObject,
                      iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Cube/SVG/ic_fluent_cube_32_regular.svg"
                    },
                    {
                      key: "field",
                      text: this.localizationService.strings.PlayAdmin_TimeEditFieldSearch,
                      iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Search Square/SVG/ic_fluent_search_square_24_regular.svg"
                    }
                  ]}
                  selected={this.state.selectedFilterType}
                  onChange={(f: "object" | "field") => this.setState({
                    selectedFilterType: f,
                    filterObjects: [],
                    filterObjectSearchQuery: "",
                    selectedTimeEditType: undefined,
                    typeFields: [],
                    selectedTypeField: "",
                    typeFieldSearchQuery: ""
                  })}
                  borderRadius={5}
                  style={{
                    width: "calc(100% + 10px)",
                    overflow: "hidden"
                  }}
                />

                {this.state.selectedFilterType &&
                  <>
                    <p
                      style={{
                        marginTop: 4
                      }}
                    >
                      {this.state.selectedFilterType === "object"
                        ? this.localizationService.strings.PlayAdmin_TimeEditSearchObjectTooltip
                        : this.localizationService.strings.PlayAdmin_TimeEditFieldSearchTooltip
                      }
                    </p>
                    <IADropDown
                      required
                      highlightColor={this.props.highlightColor}
                      options={[{key: "", value: "", disabled: true}].concat(this.props.types.map(type => {
                        return {
                          key: type.extId,
                          value: type.name,
                          disabled: false
                        }
                      }))}
                      selectedOption={this.state.selectedTimeEditType?.extId ?? ""}
                      onChange={selectedType => {
                        this.setState({selectedTimeEditType: this.props.types.find(t => t.extId === selectedType), filterObjectSearchQuery: "", filterObjects: []});
                        if (this.state.selectedFilterType === "field") {
                          this.getTypeFields();
                        }
                      }}
                      borderRadius={5}
                      label={this.localizationService.strings.PlayAdmin_TimeEditSelectFilterType}
                      darkMode={this.props.darkMode}
                      style={{
                        width: "100%",
                        marginTop: 0
                      }}
                      labelStyle={{
                        fontSize: 13
                      }}
                    />

                    {this.state.selectedFilterType === "object" &&
                      <>
                        <IATextField
                          required
                          highlightColor={this.props.highlightColor}
                          text={this.state.filterObjectSearchQuery}
                          onChange={text => {
                            this.setState({filterObjectSearchQuery: text}, () => {
                              clearTimeout(this.queryDelay);
                              this.queryDelay = setTimeout(() => {
                                this.getFilterObjects();
                              }, 500);
                            });
                          }}
                          label={this.localizationService.strings.PlayAdmin_TimeEditSearchForFilter}
                          borderRadius={5}
                          darkMode={this.props.darkMode}
                          disabled={!this.state.selectedTimeEditType}
                          labelStyle={{
                            fontSize: 13
                          }}
                        />
                        {this.state.isLoadingFilterObjects
                        ?
                          <IASpinner 
                            color={this.props.highlightColor}
                            style={{
                              marginTop: 50,
                              marginBottom: 50
                            }}
                          />
                        :
                          <>
                            {this.state.filterObjects.length > 0 &&
                              <>
                                <IAButton
                                  label={this.localizationService.strings.PlayAdmin_TimeEditAddAll}
                                  style={{
                                    width: "fit-content",
                                    color: this.props.darkMode ? Helper.darkModeLabelColor : "black"
                                  }}
                                  height={40}
                                  onClick={() => this.addFilterObjectToFilterList(this.state.filterObjects)}
                                />
                                <div
                                  style={{
                                    height: "100%",
                                    overflowY: "auto",
                                    width: "100%"
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      flexWrap: "wrap",
                                      alignItems: "flex-start",
                                      rowGap: 5,
                                      columnGap: 5
                                    }}
                                  >
                                    {this.state.filterObjects.map(filterObject =>
                                      <>
                                        {filterObject.field?.length > 0
                                        ?
                                          <TimeEditFilter
                                            highlightColor={this.props.highlightColor}
                                            widgetFilterObject={filterObject}
                                            style={{
                                              opacity: this.state.selectedFilterObjects.find(o => o.extId === filterObject.extId) ? 0.65 : undefined
                                            }}
                                            onClick={() => this.addFilterObjectToFilterList([filterObject])}
                                          />
                                        :
                                          <></>
                                        }
                                      </>
                                    )}
                                  </div>
                                </div>
                              </>
                            }
                          </>
                        }
                      </>
                    }

                    {this.state.selectedFilterType === "field" &&
                      <>
                        <IADropDown
                          required
                          highlightColor={this.props.highlightColor}
                          options={[{key: "", value: "", disabled: true}].concat(this.state.typeFields.map(field => {
                            return {
                              key: field,
                              value: field,
                              disabled: false
                            }
                          }))}
                          selectedOption={this.state.selectedTypeField ?? ""}
                          disabled={!this.state.selectedTimeEditType}
                          onChange={selectedTypeField => {
                            this.setState({selectedTypeField});
                          }}
                          borderRadius={5}
                          label={this.localizationService.strings.PlayAdmin_TimeEditSelectField}
                          darkMode={this.props.darkMode}
                          style={{
                            width: "100%",
                          }}
                          labelStyle={{
                            fontSize: 13
                          }}
                        />
                        <IATextField
                          required
                          highlightColor={this.props.highlightColor}
                          text={this.state.typeFieldSearchQuery}
                          onChange={text => {
                            this.setState({typeFieldSearchQuery: text});
                          }}
                          label={this.localizationService.strings.PlayAdmin_TimeEditAddFieldSearchQuery}
                          borderRadius={5}
                          darkMode={this.props.darkMode}
                          disabled={!this.state.selectedTypeField}
                          labelStyle={{
                            fontSize: 13
                          }}
                        />
                        <IAButton
                          label={this.localizationService.strings.PlayAdmin_TimeEditAddFieldSearchFilter}
                          buttonColor={this.props.highlightColor}
                          onClick={() => this.addFilterObjectToFilterList([{
                            extId: null,
                            type: this.state.selectedTimeEditType.extId,
                            field: [{
                              extId: this.state.selectedTypeField,
                              value: this.state.typeFieldSearchQuery
                            }]
                          }])}
                          disbaled={!this.state.typeFieldSearchQuery || !this.state.selectedTypeField || !this.state.selectedTimeEditType}
                          borderRadius={5}
                          style={{
                            marginTop: 20,
                            width: "fit-content",
                            marginLeft: "auto"
                          }}
                        />
                      </>
                    }
                  </>
                }
              </div>
          </div>
      </IAPanel>
    );
  }
}