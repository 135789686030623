exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "\n.IA_Checkbox_1A5Hi1GBmJiBnlZFGiFaF2 {\n  display: -webkit-inline-box;\n}\n\n.IA_Checkbox_1A5Hi1GBmJiBnlZFGiFaF2 input {\n  position: absolute;\n  opacity: 0;\n  cursor: pointer;\n  height: 0;\n  width: 0;\n  background-color: #ffffff !important;\n}\n\n.IA_CheckboxInput_3oyaE4WqlWAPmt2KWqeAJ2{\n  margin-right: 7px;\n}\n\n.container_3c6V60X6B1hYkAxcDATrlQ {\n  display: block;\n  position: relative;\n  padding-left: 25px;\n  cursor: pointer;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n\n.checkmark_TSfsEavHa89ZJIv_sRpC4 {\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 18px;\n  width: 18px;\n  border-radius: 2px;\n  background-color: #cccccc;\n}\n\n.IA_dot_1R5TSur1CXNL9ld6V2s40Y {\n  border-radius: 2px;\n  width: 9px;\n  height: 9px;\n  margin: 5px;\n  box-sizing: border-box;\n}\n", ""]);

// Exports
exports.locals = {
	"IA_Checkbox": "IA_Checkbox_1A5Hi1GBmJiBnlZFGiFaF2",
	"IA_CheckboxInput": "IA_CheckboxInput_3oyaE4WqlWAPmt2KWqeAJ2",
	"container": "container_3c6V60X6B1hYkAxcDATrlQ",
	"checkmark": "checkmark_TSfsEavHa89ZJIv_sRpC4",
	"IA_dot": "IA_dot_1R5TSur1CXNL9ld6V2s40Y"
};