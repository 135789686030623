exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".likesAndComments_3AjqIVrKl23gDFBRtNCz44 {\n  position: relative;\n  float: right;\n  font-size: 14px;\n  line-height: 20px;\n}\n\n.likesAndComments_3AjqIVrKl23gDFBRtNCz44 .likes_2Eqw87KCMWRAojms2kTKvc {\n  height: 20px;\n  float: right;\n  position: relative;\n}\n\n.likesAndComments_3AjqIVrKl23gDFBRtNCz44 .spinner_1c8e5_FIT_BiFCkBQpPzdq {\n  height: 14px;\n  width: 12px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_1CuuH9-l9DO-NRX6b5A182 700ms linear infinite;\n  -moz-animation: spin_1CuuH9-l9DO-NRX6b5A182 700ms linear infinite;\n  animation: spin_1CuuH9-l9DO-NRX6b5A182 700ms linear infinite;\n}\n\n.likesAndComments_3AjqIVrKl23gDFBRtNCz44 .likesText_25YLg-tIudeBQnrfpdZ2DE {\n  float: left;\n  margin-left: 4px;\n  margin-top: -1px;\n}\n\n.likesAndComments_3AjqIVrKl23gDFBRtNCz44 .likesTextVertical_MG_Oiffrx8KLRJc5C78JB {\n  float: left;\n  clear: both;\n  margin-top: -7px;\n  text-align: center;\n  width: 100%;\n  box-sizing: border-box;\n  padding-left: 1px;\n}\n", ""]);

// Exports
exports.locals = {
	"likesAndComments": "likesAndComments_3AjqIVrKl23gDFBRtNCz44",
	"likes": "likes_2Eqw87KCMWRAojms2kTKvc",
	"spinner": "spinner_1c8e5_FIT_BiFCkBQpPzdq",
	"spin": "spin_1CuuH9-l9DO-NRX6b5A182",
	"likesText": "likesText_25YLg-tIudeBQnrfpdZ2DE",
	"likesTextVertical": "likesTextVertical_MG_Oiffrx8KLRJc5C78JB"
};