exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_authors_1U2rMwP2gfgvZhGBQYuMIs {\n  width: 100%;\n  box-sizing: border-box;\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.IA_authorWrapper_3mw46Yo8wtsFsov2mjFCmk {\n  margin-right: 10px;\n  margin-bottom: 10px;\n}\n\n.IA_authorBackground_3HVk1Yweiua3pcsWFZ61m2 {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  background-color: #f6f6f6;\n  border-radius: 34px;\n  padding-right: 10px;\n}\n\n.IA_authorsImageBackground_3pLkkDrBarknFv9NCMUP37 {\n  height: 34px;\n  width: 34px;\n  /* border-radius: 32px; */\n  position: relative;\n  /* overflow: hidden; */\n  text-align: center;\n}\n\n.IA_authorsImageText_jsxgjB16FjjWsjm5d10Wq {\n  height: 100%;\n  width: 100%;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 28px;\n  border-radius: 100%;\n  box-sizing: border-box;\n  border: 2px solid #f6f6f6;\n}\n\n.IA_authorsImage_2G83cJSUHeLjOhhLGUvnnW {\n  height: 100%;\n  width: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  background-position: center;\n  background-size: cover;\n  border-radius: 100%;\n  box-sizing: border-box;\n  border: 2px solid #f6f6f6;\n}\n\n.IA_authorsDisplayName_2wSM17EI3rwCK2EusfgSMb {\n  margin-left: 10px;\n  margin-bottom: 2px;\n  font-weight: 600;\n  font-size: 13px;\n}", ""]);

// Exports
exports.locals = {
	"IA_authors": "IA_authors_1U2rMwP2gfgvZhGBQYuMIs",
	"IA_authorWrapper": "IA_authorWrapper_3mw46Yo8wtsFsov2mjFCmk",
	"IA_authorBackground": "IA_authorBackground_3HVk1Yweiua3pcsWFZ61m2",
	"IA_authorsImageBackground": "IA_authorsImageBackground_3pLkkDrBarknFv9NCMUP37",
	"IA_authorsImageText": "IA_authorsImageText_jsxgjB16FjjWsjm5d10Wq",
	"IA_authorsImage": "IA_authorsImage_2G83cJSUHeLjOhhLGUvnnW",
	"IA_authorsDisplayName": "IA_authorsDisplayName_2wSM17EI3rwCK2EusfgSMb"
};