import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IAIcon } from '../../../../icon/Icon';
import { IATextField } from '../../../../textField/TextField';
import { Store } from '../../../stores';
import { MediaFileSelectorPanel } from './mediaFileSelectorPanel/MediaFileSelectorPanel';
import { MediaFileUploadPanel } from './mediaFileUploadPanel/MediaFileUploadPanel';
import { StockPhotosPanel } from './stockPhotosPanel/StockPhotosPanel';
// import * as styles from './MediaFileSelector.css';

export interface IProps {
  url: string;
  type: "image" | "video" | "pdf" | "font";
  label: string;
  placeholder?: string;
  enableMultiple?: boolean;
  disabled?: boolean;
  onChange: (url: string) => void;
  store?: Store;
}

export interface IState {
  showMediaFileSelectorPanel: boolean;
  showMediaFileUploadPanel: boolean;
  showStockPhotosPanel: boolean;
}

@inject('store')
@observer
export class MediaFileSelector extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      showMediaFileSelectorPanel: false,
      showMediaFileUploadPanel: false,
      showStockPhotosPanel: false
    }
  }

  public render(): JSX.Element {
    return (
      <div style={{
        float: "left",
        marginTop: 10,
        opacity: this.props.disabled ? 0.5 : 1
      }}>
        <IATextField
          label={this.props.label}
          placeholder={this.props.placeholder}
          text={this.props.url}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          disabled={this.props.disabled}
          labelStyle={{
            fontSize: "12px",
            width: 320
          }}
          style={{
            width: this.props.type === "image" ? "calc(100% - 120px)" : "calc(100% - 80px)",
            float: "left"
          }}
          onChange={(url: string) => {
            if (!this.props.disabled) {
              this.props.onChange(url);
            }
          }}
        />
        <IAIcon
          title="Upload"
          size={25}
          color={this.props.store.highlightColor}
          style={{
            float: "left",
            marginTop: 43,
            marginLeft: 10
          }}
          onClick={() => {
            if (!this.props.disabled) {
              this.props.store.mediaFileToEdit = {
                isNew: true
              }
              this.setState({ showMediaFileUploadPanel: true });
            }
          }}
        />
        <IAIcon
          title="Folder"
          size={25}
          color={this.props.store.highlightColor}
          style={{
            float: "left",
            marginTop: 43,
            marginLeft: 10
          }}
          onClick={() => {
            if (!this.props.disabled) {
              this.setState({ showMediaFileSelectorPanel: true });
            }
          }}
        />
        {this.props.type === "image" &&
          <IAIcon
            title="Search Photo"
            size={25}
            color={this.props.store.highlightColor}
            style={{
              float: "left",
              marginTop: 43,
              marginLeft: 8
            }}
            onClick={() => {
              if (!this.props.disabled) {
                this.setState({ showStockPhotosPanel: true });
              }
            }}
          />
        }
        <MediaFileSelectorPanel
          open={this.state.showMediaFileSelectorPanel}
          type={this.props.type}
          onSelect={(url: string) => {
            this.props.onChange(url);
            if (!this.props.enableMultiple) {
              this.setState({ showMediaFileSelectorPanel: false });
            }
          }}
          onClose={() => {
            this.setState({ showMediaFileSelectorPanel: false });
          }}
        />
        <MediaFileUploadPanel
          open={this.state.showMediaFileUploadPanel}
          selectedUrl={(url: string) => {
            this.props.onChange(url);
            this.setState({ showMediaFileUploadPanel: false });
            this.props.store.mediaFileToEdit = undefined;
          }}
          close={() => {
            this.setState({ showMediaFileUploadPanel: false });
            this.props.store.mediaFileToEdit = undefined;
          }}
          enableMultiple={this.props.enableMultiple}
          fileType={this.props.type}
        />
        <StockPhotosPanel
          open={this.state.showStockPhotosPanel}
          close={() => this.setState({ showStockPhotosPanel: false })}
          onSelect={(image: string, lastModified?: string) => {
            this.props.onChange(image);
            console.log(`image: ${image} / lastModified: ${lastModified}`);
            this.setState({ showStockPhotosPanel: false });
          }}
          highlightColor={this.props.store.highlightColor}
          isMobile={this.props.store.isMobile}
          environment={this.props.store.environment}
          darkMode={this.props.store.darkMode}
          tenant={this.props.store.tenantId}
          token={this.props.store.token}
          marginTop={0}
        />
      </div>
    )
  }
}
