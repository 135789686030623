import * as React from 'react';
import * as styles from '../Weather.css';

export interface IProps {
  widgetId: string;
  temperatures: number[];
  sizeUnit: number;
  marginMultiplier: number;
  width: number;
  color: string;
  isSmall?: boolean;
}

export interface IState {
}

export class TemperatureGraph extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
    };
  }

  public componentDidMount(): void {
    if (this.props.temperatures) {
      this.drawLine(this.getPoints());
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (this.props.temperatures && prevProps.temperatures !== this.props.temperatures
      || this.props.widgetId !== prevProps.widgetId) {
      this.drawLine(this.getPoints());
    }
  }

  private getPoints() {
    const points = [];
    const container = document.getElementById(`graphContainer_${this.props.widgetId}`) as HTMLDivElement;
    const marginSize = this.props.sizeUnit;
    const drawHeight = (container.clientHeight * .65) - (this.props.sizeUnit * 2); // adds padding above and below graph
    const containerMargin = this.props.sizeUnit * this.props.marginMultiplier;
    const xStepSize = (container.clientWidth - (containerMargin * 2)) / 4;
    const lowestLow = this.getLowestLow(this.props.temperatures);
    const highestHigh = this.getHighestHigh(this.props.temperatures);
    const tempDifference = highestHigh - lowestLow;
    const drawScale = drawHeight / tempDifference;
    let steps = 0;
    this.props.temperatures.forEach((temperatur, i) => {
      const lowNormalized = temperatur - lowestLow;
      if (i === 0) {
        // First forecast - start of line
        const x1 = steps = steps + 1.5;
        const y1 = (drawHeight - lowNormalized * drawScale) + marginSize;
        points.push([x1, y1]);
      } else if (0 < i && i < 4) {
        // All forecasts between first and last
        const x1 = steps++ * xStepSize + containerMargin;
        const y1 = (drawHeight - lowNormalized * drawScale) + marginSize;
        points.push([x1, y1]);
      } else if (i === 4) {
        // Last forecast
        const x1 = steps++ * xStepSize + containerMargin + containerMargin;
        const y1 = (drawHeight - lowNormalized * drawScale) + marginSize;
        points.push([x1, y1]);
      }
    });
    return points;
  }

  private drawLine(points): any {
    const svgPath = (points, command) => {
      // build the d attributes by looping over the points
      const d = points.reduce((acc, point, i, a) => i === 0
        ? `M ${point[0]},${point[1]}`
        : `${acc} ${command(point, i, a)}`
        , '')
      return d;
    }

    const line = (pointA, pointB) => {
      const lengthX = pointB[0] - pointA[0]
      const lengthY = pointB[1] - pointA[1]
      return {
        length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
        angle: Math.atan2(lengthY, lengthX)
      }
    }

    const controlPoint = (current, previous, next, reverse?) => {
      // When 'current' is the first or last point of the array
      // 'previous' or 'next' don't exist.
      // Replace with 'current'
      const p = previous || current
      const n = next || current
      // The smoothing ratio
      const smoothing = 0.2
      // Properties of the opposed-line
      const o = line(p, n)
      // If is end-control-point, add PI to the angle to go backward
      const angle = o.angle + (reverse ? Math.PI : 0)
      const length = o.length * smoothing
      // The control point position is relative to the current point
      const x = current[0] + Math.cos(angle) * length
      const y = current[1] + Math.sin(angle) * length
      return [x, y]
    }

    const bezierCommand = (point, i, a) => {
      // start control point
      const [cpsX, cpsY] = controlPoint(a[i - 1], a[i - 2], point)
      // end control point
      const [cpeX, cpeY] = controlPoint(point, a[i - 1], a[i + 1], true)
      return `C ${cpsX},${cpsY} ${cpeX},${cpeY} ${point[0]},${point[1]}`
    }

    //const lineCommand = point => `L ${point[0]} ${point[1]}`
    const graph = svgPath(points, bezierCommand)
    const path = document.getElementById(`tempGraphSVGPath_${this.props.widgetId}`);
    if (path) {
      path.setAttribute("d", graph);
    }
  }

  private getHighestHigh(temperaturs: number[]): number {
    let higestHigh = Number.MIN_SAFE_INTEGER;
    temperaturs.forEach(i => {
      if (i > higestHigh) {
        higestHigh = i;
      }
    });
    return higestHigh;
  }

  private getLowestLow(temperaturs: number[]): number {
    let lowestLow = Number.MAX_SAFE_INTEGER;
    temperaturs.forEach(i => {
      if (i < lowestLow) {
        lowestLow = i;
      }
    });
    return lowestLow;
  }

  public render(): JSX.Element {
    return (
      <div
        id={`graphContainer_${this.props.widgetId}`}
        className={styles.IA_graphContainer}
        style={{
          top: "55%",
          height: "25%"
        }}
      >
        <svg
          id="tempGraphSVG"
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            overflow: "visible"
          }}
        >
          <path
            id={`tempGraphSVGPath_${this.props.widgetId}`}
            fill="none"
            stroke={this.props.color}
            stroke-linejoin="round"
            strokeWidth={this.props.sizeUnit / 4}
          />
        </svg>
      </div>
    );
  }
}