import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as styles from './CompanyInformation.css';
import { Store } from '../../../stores';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IAButton } from '../../../../button/Button';
import { CompanyInformationForm } from './CompanyInformationForm';
import { Popup } from '../../../reusableComponents/popup/Popup';

export interface IProps {
  display: boolean;
  store?: Store;
}

export interface IState {
  loading: boolean;
  showNoCompanyInformationPopup: boolean;
  showAddMoreScreensPopup: boolean;
}

@inject('store')
@observer
export class CompanyInformation extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      showNoCompanyInformationPopup: false,
      showAddMoreScreensPopup: false
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public render(): JSX.Element {
    return this.props.display ? (
      <div className={styles.IA_companyInformation}>
        <CompanyInformationForm
          onSaved={() => { }}
        />
        <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
        <h2>{this.localizationService.strings.PlayAdmin_BillingOption}</h2>
        <div className={styles.IA_billingInfo}>{this.localizationService.strings.PlayAdmin_BillingOptionInfo}</div>
        <IAButton
          label={this.localizationService.strings.PlayAdmin_AddACreditCard?.toUpperCase()}
          buttonColor={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onClick={() => {
            if (this.props.store.subscription?.hostedPageLinks?.paymentInfo) {
              window.open(`${this.props.store.subscription?.hostedPageLinks?.paymentInfo}`)
            } else {
              if (this.props.store.companyInformation?.tenantId) {
                this.setState({ showAddMoreScreensPopup: true });
              } else {
                this.setState({ showNoCompanyInformationPopup: true });
              }
            }
          }}
          borderRadius={5}
          showSpinner={this.state.loading}
          style={{
            float: "left",
            position: "relative",
            marginTop: 20
          }}
        />
        {this.state.showNoCompanyInformationPopup &&
          <Popup
            headline={this.localizationService.strings.PlayAdmin_CompanyInformationPopup}
            close={() => this.setState({ showNoCompanyInformationPopup: false })}
            darkMode={this.props.store.darkMode}
            style={{
              height: "80vh",
              top: "10vh"
            }}
            content={
              <CompanyInformationForm
                showButtonAtTheBottom
                onSaved={() => {
                  this.setState({ showNoCompanyInformationPopup: false });
                }}
              />
            }
          />
        }
        {this.state.showAddMoreScreensPopup &&
          <Popup
            headline={this.localizationService.strings.PlayAdmin_YouNeedToSelectAmountOfScreensHeadline}
            close={() => this.setState({ showAddMoreScreensPopup: false })}
            darkMode={this.props.store.darkMode}
            style={{
              height: 200
            }}
            content={
              <div>{this.localizationService.strings.PlayAdmin_YouNeedToSelectAmountOfScreensContent}</div>
            }
          />
        }
      </div>
    )
      :
      (
        <></>
      );
  }


}