import { Helper } from "../../../Helper";
import { IEnvironment } from "../../../interfaces/IEnvironment";
import AuthenticationService from "../../../services/AuthenticationService";

export class ComposerHelper {
  public static getCustomWidth(componentWidth): number {
    if (componentWidth <= screen.width - 80) {
      return componentWidth;
    } else {
      return screen.width - 80;
    }
  }

  public static uploadFileDirectlyToSharepoint(environment: IEnvironment, file: File, tenant: string, token: string, onProgressCallback: (progressPercentage: number) => void, site?: string, listName?: string): Promise<string> {
    return new Promise((resolve, reject) => {  
      let fileReader = new FileReader();
      fileReader.onload = async () => {
        const spToken = await AuthenticationService.getSharepointToken(environment, tenant, token);
        if (!spToken) {
          console.error(`Error fetching SharePoint token`);
          reject();
          return;
        }
        const arrayBuffer = fileReader.result as ArrayBuffer;

        const guid = Helper.generateGUID();
        const siteName = site ?? "IntraActive-Messages";
        const serverRelativeUrl = `/sites/${siteName}/${listName ?? `Lists/PublicRead_MessageAssets`}`;
        let fileName: string;
        if (file.name) {
          fileName = this.getFileNameWithRandomKey(file.name);
        } else {
          // file is copy pasted - generate random name
          fileName = Helper.getRandomStringKey() + "." + file.type.replace("image/", "");
        }

        const fileServerRelativePath = await this.createFile(tenant, siteName, serverRelativeUrl, fileName, spToken);
        if (fileServerRelativePath) {
          const isUploadSuccess = await this.recursivelyUploadFile(tenant, siteName, serverRelativeUrl, fileName, guid, spToken, arrayBuffer, 0, onProgressCallback);
          if (isUploadSuccess) {
            onProgressCallback(100);
            console.log(`Succesfully uploaded file "${fileName}"`);
            resolve(`https://${tenant}.sharepoint.com${fileServerRelativePath as string}`);
            return;
          }
        }
        console.error(`Uploading file "${fileName}" failed`);
        reject();
      };
      fileReader.readAsArrayBuffer(file);
    });
  }

  private static createFile(tenant: string, site: string, serverRelativeUrl: string, fileName: string, spToken: string): Promise<boolean | string> {
    return new Promise((resolve, reject) => {
      const url = `https://${tenant}.sharepoint.com/sites/${site}/_api/web/getfolderbyserverrelativeurl('${serverRelativeUrl}')/files/add(overwrite=true, url='${fileName}')`;

      fetch(url, {
        method: "POST",
        credentials: "same-origin",
        mode: "cors",
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'authorization': `Bearer ${spToken}`,
        }),
        body: ""
      })
      .then(function (response) {
        if (!response.ok) {
          reject(response.status);
        }
        return response.json();
      }).then((response: { ServerRelativeUrl: string }) => {
        resolve(response.ServerRelativeUrl);
      }).catch(() => {
        resolve(false);
      });
    });
  }

  private static async recursivelyUploadFile(tenant: string, site: string, serverRelativeUrl: string, fileName: string, guid: string, spToken: string, arrayBuffer: ArrayBuffer, bytesUploaded: number, onProgressCallback: (progressPercentage: number) => void): Promise<boolean> {
    const chunkSize = 10000000;
    onProgressCallback((bytesUploaded / arrayBuffer.byteLength) * 100);

    if (bytesUploaded === 0) {
      // start upload
      // check if the whole file is smaller than the chunksize
      const chunkSizeToUpload = chunkSize >= arrayBuffer.byteLength ? arrayBuffer.byteLength : chunkSize;
      const result = await this.uploadChunk(tenant, site, serverRelativeUrl, fileName, guid, spToken, arrayBuffer.slice(0, chunkSizeToUpload), "start", 0);
      if (!result) return false;
      return await this.recursivelyUploadFile(tenant, site, serverRelativeUrl, fileName, guid, spToken, arrayBuffer, chunkSizeToUpload, onProgressCallback);
    }

    if (bytesUploaded + chunkSize >= arrayBuffer.byteLength) {
      // finish upload
      return await this.uploadChunk(tenant, site, serverRelativeUrl, fileName, guid, spToken, arrayBuffer.slice(bytesUploaded), "finish", bytesUploaded);
    }

    // continue upload
    const chunkEnd = bytesUploaded + chunkSize;
    const result = await this.uploadChunk(tenant, site, serverRelativeUrl, fileName, guid, spToken, arrayBuffer.slice(bytesUploaded, chunkEnd), "continue", bytesUploaded);
    if (!result) return false;
    return await this.recursivelyUploadFile(tenant, site, serverRelativeUrl, fileName, guid, spToken, arrayBuffer, chunkEnd, onProgressCallback);      
  }

  private static uploadChunk(tenant: string, site: string, serverRelativeUrl: string, fileName: string, guid: string, spToken: string, chunk: ArrayBuffer, method: "start" | "continue" | "finish", fileOffset: number): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const uploadUrl = `https://${tenant}.sharepoint.com/sites/${site}/_api/web/getfilebyserverrelativeurl('${serverRelativeUrl}/${fileName}')/${method}upload(uploadId=guid'${guid}'${fileOffset !== 0 ? `, fileOffset=${fileOffset}` : ""})`;
      fetch(uploadUrl, {
        method: "POST",
        credentials: "same-origin",
        mode: "cors",
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'authorization': `Bearer ${spToken}`
        }),
        body: chunk
      })
      .then(function (response) {
        if (!response.ok) {
          reject(response.status);
        }
        return response.json();
      }).then(() => {
        resolve(true);
      }).catch(() => {
        resolve(false);
      });
    });
  }

  private static getFileNameWithRandomKey(fileName: string): string {
    fileName = fileName.replace("&", " "); // since sharepoint stores values decoded, we need to manually remove ampersand symbol
    const dotIndex = fileName.lastIndexOf(".");
    const key = Helper.getRandomStringKey();
    if (dotIndex == -1) return encodeURIComponent(fileName) + key;
    else return encodeURIComponent(fileName.substring(0, dotIndex)) + key + fileName.substring(dotIndex);
  }
}