import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import { IADropDown } from '../../../../../dropDown/DropDown';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IPlayProfile } from '../../../../../../interfaces/IPlay';
import { IATextField } from '../../../../../textField/TextField';
import PlayService from '../../../../../../services/PlayService';
import { IASpinner } from '../../../../../spinner/Spinner';

export interface IProps {
  store?: Store;
  profiles: IPlayProfile[];
  isEditingExistingIntegration: boolean;
  onProfileChange: (profile) => void;
  onChange: (isConnected: boolean) => void;
}

export interface IState {
  clientId: string;
  clientSecret: string;
  isLoadingValidation: boolean;
  showValidationError: boolean;
}

@inject('store')
@observer
export class RelesysIntegrationComponent extends React.Component<IProps, IState> {
  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      clientId: "",
      clientSecret: "",
      isLoadingValidation: false,
      showValidationError: false
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  private onInputChange(){
    const hasClientIdAndSecret = !!this.props.store.integrationToEdit.relesysClientSecret && !!this.props.store.integrationToEdit.relesysClientId;
    if (hasClientIdAndSecret) {
      this.setState({ isLoadingValidation: true, showValidationError: false });
      PlayService.validateRelesysIntegration(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, this.props.store.integrationToEdit.relesysClientId, this.props.store.integrationToEdit.relesysClientSecret).then(value => {
        this.props.onChange(value.validated);
        if (!value.validated) {
          this.setState({ showValidationError: true });
        }
      }).catch(() => {
        this.props.onChange(false);
        this.setState({ showValidationError: true });
      }).finally(() => {
        this.setState({ isLoadingValidation: false });
      });
    } else {
      this.props.onChange(false);
      this.setState({showValidationError: false});
    }
  }

  public render(): JSX.Element {
    return (
      <>
        <IADropDown
          label={this.localizationService.strings.PlayAdmin_SelectAProfileToAccess?.toUpperCase()}
          selectedOption={this.props.store.integrationToEdit?.profileId}
          options={[{
            key: undefined,
            value: this.localizationService.strings.PlayAdmin_SelectAProfileToAccess,
            disabled: true
          },
          ...this.props.profiles.map(profile => { return { key: profile.id, value: profile.title } })
          ]}
          disabled={this.props.isEditingExistingIntegration}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          onChange={(profile) => {
            this.props.onProfileChange(profile)
          }}
          style={{
            float: "left",
            width: 340
          }}
          borderRadius={5}
        />
        {this.props.store.integrationToEdit?.profileId &&  
          <>
            <IATextField
              label="Client ID"
              text={this.props.store.integrationToEdit?.relesysClientId}
              onChange={clientId => {
                this.props.store.integrationToEdit.relesysClientId = clientId;
                this.onInputChange();
              }}
              highlightColor={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              borderRadius={5}
              onlyPaste
            />
            <IATextField
              label="Client secret"
              text={this.props.store.integrationToEdit?.relesysClientSecret}
              onChange={clientSecret => {
                this.props.store.integrationToEdit.relesysClientSecret = clientSecret;
                this.onInputChange();
              }}
              highlightColor={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              borderRadius={5}
              onlyPaste
            />
            <div style={{ clear: "both", float: "left", marginTop: 15, fontStyle: "italic", width: "100%" }}>
              {this.state.isLoadingValidation && 
                <>
                  <div style={{width: "100%", textAlign: "center"}}>{this.localizationService.strings.PlayAdmin_RelesysValidating}</div>
                  <IASpinner style={{marginTop: 30, marginBottom: 10}} color={this.props.store.highlightColor}/>
                </>
              }
              {this.state.showValidationError && <>{this.localizationService.strings.PlayAdmin_RelesysValidationError}</>}
            </div>
          </>
        }
      </>
    );
  }
}