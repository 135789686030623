import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from '../WidgetEditPanel.css';
import { IATextField } from '../../../../../textField/TextField';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IANumberField } from '../../../../../numberField/NumberField';
import { IADropDown } from '../../../../../dropDown/DropDown';
import { IAToggle } from '../../../../../toggle/Toggle';
import { Helper } from '../../../../../../Helper';
import { IAButton } from '../../../../../button/Button';
import { IAColorTheme } from '../../../../../colorTheme/ColorTheme';
import { IAOptionSelector } from '../../../../../optionSelector/OptionSelector';

export interface IProps {
  store?: Store;
  forceUpdate: () => void;
}

export interface IState {
}

@inject('store')
@observer
export class InstagramWidgetSettings extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();


  constructor(props: IProps) {
    super(props);
    this.state = {
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }


  render(): React.ReactNode {
    const colorThemes = [];
    if (this.props.store.widgetToEdit?.instagram?.colorThemes?.length > 0) {
      this.props.store.widgetToEdit.instagram.colorThemes.forEach((colorTheme, index) => {
        colorThemes.push(
          <IAColorTheme
            key={"colorTheme_" + index}
            backgroundColor={colorTheme.backgroundColor}
            textColor={colorTheme.textColor}
            highlightColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            swatches={this.props.store.profile?.swatches}
            inTeams
            remove={() => {
              this.props.store.widgetToEdit.instagram.colorThemes.splice(index, 1);
              this.props.forceUpdate();
            }}
            allowAddingToSwatches={false}
            setBackgroundColor={(backgroundColor) => {
              colorTheme.backgroundColor = backgroundColor;
              this.props.forceUpdate();
            }}
            setTextColor={(textColor) => {
              colorTheme.textColor = textColor;
              this.props.forceUpdate();
            }}
          />
        );
      });
    }
    return (
      <>
      <IATextField
                  label={this.localizationService.strings.PlayAdmin_Title?.toUpperCase()}
                  required
                  placeholder={this.localizationService.strings.PlayAdmin_Title}
                  text={this.props.store.widgetToEdit.title}
                  darkMode={this.props.store.darkMode}
                  highlightColor={this.props.store.highlightColor}
                  borderRadius={5}
                  labelStyle={{
                    fontSize: "12px"
                  }}
                  onChange={(title: string) => {
                    this.props.store.widgetToEdit.title = title;
                    this.props.forceUpdate();
                  }}
                />
                <IAToggle
                  style={{
                    marginTop: 10,
                    fontSize: 12
                  }}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_ShowWidgetTitle?.toUpperCase()}
                  checked={this.props.store.widgetToEdit.showTitle}
                  color={this.props.store.highlightColor}
                  onChange={() => {
                    this.props.store.widgetToEdit.showTitle = this.props.store.widgetToEdit.showTitle != undefined ? !this.props.store.widgetToEdit.showTitle : true;
                    this.props.forceUpdate();
                  }}
                />
        <IANumberField
          label={this.localizationService.strings.PlayAdmin_MinimumDuration?.toUpperCase()}
          number={this.props.store.widgetToEdit.instagram.duration}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          onChange={(duration: number) => {
            this.props.store.widgetToEdit.instagram.duration = duration;
            this.props.forceUpdate();
          }}
          styles={{
            width: "100%",
            clear: "both"
          }}
        />
        {/* <IATextField
          label={this.localizationService.strings.PlayAdmin_RssUrl?.toUpperCase()}
          placeholder={this.localizationService.strings.PlayAdmin_RssUrl}
          text={this.props.store.widgetToEdit.instagram ? this.props.store.widgetToEdit.instagram.url : undefined}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          onChange={(url: string) => {
            this.props.store.widgetToEdit.instagram.url = url;
            this.props.forceUpdate();
          }}
        /> */}
        <IANumberField
          label={this.localizationService.strings.PlayAdmin_RssMaxItems?.toUpperCase()}
          number={this.props.store.widgetToEdit.instagram?.maxItems ?? undefined}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          onChange={(maxItems: number) => {
            this.props.store.widgetToEdit.instagram.maxItems = maxItems;
            this.props.forceUpdate();
          }}
          styles={{
            width: "100%",
            clear: "both"
          }}
        />
        <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
        <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : '#333333' }}>{this.localizationService.strings.PlayAdmin_Layout}</h3>
        <IAOptionSelector
          label={this.localizationService.strings.PlayAdmin_LayoutType?.toUpperCase()}
          options={[
            {
              icon: "Slides",
              key: "slide",
              text: this.localizationService.strings.PlayAdmin_LayoutTypeSlides
            }
          ]}
          selected={this.props.store.widgetToEdit.instagram.layoutType ? this.props.store.widgetToEdit.instagram.layoutType : "slide"}
          highlightColor={this.props.store.highlightColor}
          compact
          iconSize={30}
          darkMode={this.props.store.darkMode}
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          onChange={(layoutType: any) => {
            this.props.store.widgetToEdit.instagram.layoutType = layoutType;
            this.props.forceUpdate();
          }}
          style={{ clear: "none", marginTop: 20 }}
        />
        {this.props.store.widgetToEdit.eventViewer?.layoutType === "week" &&
          <div className={styles.IA_disclaimer}>
            {this.localizationService.strings.PlayAdmin_CanteenMenuDisclaimer}
          </div>
        }
        {(this.props.store.widgetToEdit.instagram.layoutType == undefined ||this.props.store.widgetToEdit.instagram.layoutType === "slide") &&
          <>
            <IAOptionSelector
              label={this.localizationService.strings.PlayAdmin_TransitionType?.toUpperCase()}
              options={[
                {
                  icon: "Transition Crossfade",
                  key: "crossfade",
                  text: this.localizationService.strings.PlayAdmin_Crossfade
                },
                {
                  icon: "Transition Slide",
                  key: "slide",
                  text: this.localizationService.strings.PlayAdmin_Slide
                },
              ]}
              selected={this.props.store.widgetToEdit.instagram.transition}
              highlightColor={this.props.store.highlightColor}
              compact
              labelStyle={{
                fontSize: "12px"
              }}
              iconSize={30}
              darkMode={this.props.store.darkMode}
              borderRadius={5}
              onChange={(transitionType: any) => {
                this.props.store.widgetToEdit.instagram.transition = transitionType;
                this.props.forceUpdate();
              }}
              style={{ clear: "none", marginTop: 20 }}
            />
          </>
        }
        <IADropDown
          label={this.localizationService.strings.PlayAdmin_ContentBackground?.toUpperCase()}
          selectedOption={this.props.store.widgetToEdit.instagram.boxStyle}
          options={[
            {
              key: "solid",
              value: this.localizationService.strings.PlayAdmin_SolidBackground
            },
            {
              key: "transparent",
              value: this.localizationService.strings.PlayAdmin_TransparentBackground
            },
            {
              key: "gradient",
              value: this.localizationService.strings.PlayAdmin_GradientBackground
            }
          ]}
          borderRadius={5}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          onChange={(boxStyle) => {
            this.props.store.widgetToEdit.instagram.boxStyle = boxStyle;
            this.props.forceUpdate();
          }}
          style={{
            clear: "none",
            float: "left",
            width: "100%"
          }}
        />
        <IADropDown
          label={this.localizationService.strings.PlayAdmin_TextPosition?.toUpperCase()}
          selectedOption={this.props.store.widgetToEdit.instagram.contentPosition}
          options={[
            {
              key: "left",
              value: this.localizationService.strings.PlayAdmin_TextPositionLeft,
            },
            {
              key: "right",
              value: this.localizationService.strings.PlayAdmin_TextPositionRight,
            },
            {
              key: "random",
              value: this.localizationService.strings.PlayAdmin_TextPositionRandom,
            }
          ]}
          borderRadius={5}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          onChange={(contentPosition: any) => {
            this.props.store.widgetToEdit.instagram.contentPosition = contentPosition;
            this.props.forceUpdate();
          }}
          style={{
            clear: "none",
            float: "left",
            width: "100%",
            marginBottom: 5
          }}
        />
        {/* <IAToggle
          style={{
            marginTop: 10
          }}
          labelStyle={{
            fontSize: "12px"
          }}
          label={this.localizationService.strings.PlayAdmin_MessagesViewerShowTitle?.toUpperCase()}
          checked={this.props.store.widgetToEdit.instagram.showTitle != undefined ? this.props.store.widgetToEdit.instagram.showTitle : true}
          color={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onChange={() => {
            this.props.store.widgetToEdit.instagram.showTitle = this.props.store.widgetToEdit.instagram.showTitle != undefined ? !this.props.store.widgetToEdit.instagram.showTitle : false;
            this.props.forceUpdate();
          }}
        /> */}
        <IAToggle
          style={{
            marginTop: 10
          }}
          labelStyle={{
            fontSize: "12px"
          }}
          label={this.localizationService.strings.PlayAdmin_MessagesViewerShowText?.toUpperCase()}
          checked={this.props.store.widgetToEdit.instagram.showText != undefined ? this.props.store.widgetToEdit.instagram.showText : true}
          color={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onChange={() => {
            this.props.store.widgetToEdit.instagram.showText = this.props.store.widgetToEdit.instagram.showText != undefined ? !this.props.store.widgetToEdit.instagram.showText : false;
            this.props.forceUpdate();
          }}
        />
        <IAToggle
          style={{
            marginTop: 10
          }}
          labelStyle={{
            fontSize: "12px"
          }}
          label={this.localizationService.strings.PlayAdmin_ShowProgressBar?.toUpperCase()}
          checked={this.props.store.widgetToEdit.showProgressBar != undefined ? this.props.store.widgetToEdit.showProgressBar : true}
          color={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onChange={() => {
            this.props.store.widgetToEdit.showProgressBar = this.props.store.widgetToEdit.showProgressBar != undefined ? !this.props.store.widgetToEdit.showProgressBar : false;
            this.props.forceUpdate();
          }}
        />
        <IAToggle
          style={{
            marginTop: 10
          }}
          labelStyle={{
            fontSize: "12px"
          }}
          label={this.localizationService.strings.PlayAdmin_MessagesViewerShowDate?.toUpperCase()}
          checked={this.props.store.widgetToEdit.instagram.showDate != undefined ? this.props.store.widgetToEdit.instagram.showDate : true}
          color={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onChange={() => {
            this.props.store.widgetToEdit.instagram.showDate = this.props.store.widgetToEdit.instagram.showDate != undefined ? !this.props.store.widgetToEdit.instagram.showDate : false;
            this.props.forceUpdate();
          }}
        />
        <IAToggle
          style={{
            marginTop: 10
          }}
          labelStyle={{
            fontSize: "12px"
          }}
          label={this.localizationService.strings.PlayAdmin_MessagesViewerShowQR?.toUpperCase()}
          checked={this.props.store.widgetToEdit.instagram.showQRLink != undefined ? this.props.store.widgetToEdit.instagram.showQRLink : true}
          color={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onChange={() => {
            this.props.store.widgetToEdit.instagram.showQRLink = this.props.store.widgetToEdit.instagram.showQRLink != undefined ? !this.props.store.widgetToEdit.instagram.showQRLink : false;
            this.props.forceUpdate();
          }}
        />
        <IATextField
          placeholder={this.localizationService.strings.PlayAdmin_LinkText}
          text={this.props.store.widgetToEdit.instagram.linkText}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          label={this.localizationService.strings.PlayAdmin_QRText?.toUpperCase()}
          style={{
            marginTop: 10
          }}
          labelStyle={{
            fontSize: "12px"
          }}
          inputFieldStyle={{
            marginBottom: 10
          }}
          onChange={(text: string) => {
            this.props.store.widgetToEdit.instagram.linkText = text;
            this.props.forceUpdate();
          }}
        />
        <IAToggle
          style={{
            marginTop: 10
          }}
          labelStyle={{
            fontSize: "12px"
          }}
          label={this.localizationService.strings.PlayAdmin_MessagesViewerShowImage?.toUpperCase()}
          checked={this.props.store.widgetToEdit.instagram.showImage != undefined ? this.props.store.widgetToEdit.instagram.showImage : true}
          color={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onChange={() => {
            this.props.store.widgetToEdit.instagram.showImage = this.props.store.widgetToEdit.instagram.showImage != undefined ? !this.props.store.widgetToEdit.instagram.showImage : false;
            this.props.forceUpdate();
          }}
        />
        <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
        <label
          style={{
            color: this.props.store.darkMode ? Helper.darkModeLabelColor : '#333333',
            marginTop: 20
          }}
        >
          {this.localizationService.strings.PlayAdmin_ColorThemes?.toUpperCase()}
        </label>
        {colorThemes}
        <IAButton
          label={this.localizationService.strings.PlayAdmin_AddColorTheme?.toUpperCase()}
          buttonColor={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onClick={() => {
            if (!this.props.store.widgetToEdit.instagram?.colorThemes) {
              this.props.store.widgetToEdit.instagram.colorThemes = [];
            }
            this.props.store.widgetToEdit.instagram.colorThemes.push(
              {
                textColor: "#333333",
                backgroundColor: "#ffffff"
              }
            );
            this.props.forceUpdate();
          }}
          showSpinner={this.props.store.workingOnIt}
          borderRadius={5}
          style={{
            float: "left",
            position: "relative",
            clear: "both",
            marginBottom: 20,
            marginTop: 10
          }}
        />
      </>);
  }


}