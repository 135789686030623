import * as React from 'react';
import * as styles from './Countdown.css';
import { IEnvironment } from '../../../../interfaces/IEnvironment';
import { IAChristmasCountdown } from './christmasCountdown/ChristmasCountdown';
import { FlipCounter } from './flipCounter/FlipCounter';
import { TCountDirection, TLayout } from '../../../../interfaces/IPlay';
import { LocalizationService } from '../../../../services/LocalizationService';
import { PlayHelper } from '../../../playAdmin/PlayHelper';
import { Helper } from '../../../../Helper';

export interface IChristmasSettings {
  candleNumberColor: string;
  candleColor: "white" | "red" | "green" | "blue";
  showQRCode: boolean;
}

export interface ICountdownProps {
  countDirection: TCountDirection;
  layout?: TLayout;
  title?: string;
  date?: Date; //
  description?: string; // New
  overlayColor?: string; // New
  overlayOpacity?: number; // New
  showDays?: boolean; // New
  showHours?: boolean; // New
  showMinuts?: boolean; // New
  showSeconds?: boolean; // New
  color?: string; // New
  flipBackgroundColor?: string; // New
  flipColor?: string; // New
  image?: string;
  backgroundColor?: string;
  titleColor?: string;
  sizeUnit?: number;
  christmasSettings?: IChristmasSettings;
  isVertical?: boolean;
  environment: IEnvironment;
  tenantId: string;
  profileId: string;
  widgetId?: string;
  playerId?: string;
  isActive: boolean;
  language?: string;
  areaHeight?: number;
  countdownEndedText?: string;
  headlineFont?: string;
  contentFont?: string;
}

export interface ICountdownState {
  qrCode?: string;
}

export class IACountdown extends React.Component<ICountdownProps, ICountdownState> {

  private localizationService: LocalizationService;
  private id: string;

  constructor(props: ICountdownProps) {
    super(props);
    this.state = {
    }
    this.id = Helper.getRandomStringKey();
  }

  componentDidMount(): void {
    setInterval(() => {
      this.forceUpdate();
    }, 1000);
    this.localizationService = new LocalizationService(PlayHelper.getLocalizerLanguageFromPlayerLanguage(this.props.language));
  }

  private getCounterType(number: string): JSX.Element {
    switch (this.props.layout) {
      case "standard":
        return (
          <div className={styles.IA_number}>
            {number}
          </div>
        );
      case "flip":
        return (
          <FlipCounter
            sizeUnit={this.props.sizeUnit}
            number={number}
            color={this.props.flipColor}
            backgroundColor={this.props.flipBackgroundColor}
          />
        );
      default:
        return <></>
    }
  }

  public render(): JSX.Element {
    let contentTopPosition: number;
    let daysToShow;
    let hoursToShow;
    let minutsToShow;
    let secondsToShow;
    console.log("areaHeight: " + this.props.areaHeight);
    if (!this.props.christmasSettings) {
      const contentWrapper = document.getElementById(`IA_CountdownContent_${this.id}`);
      if (contentWrapper) {
        const contentWrapperHeight = contentWrapper.clientHeight;
        contentTopPosition = (this.props.areaHeight / 2) - (contentWrapperHeight / 2);
      } else {
        setTimeout(() => {
          this.forceUpdate();
        }, 0);
      }
      if (this.props.date) {
        const now: any = new Date();
        let timeInMilliSeconds;
        if (this.props.countDirection === "up") {
          timeInMilliSeconds = now.getTime() - this.props.date.getTime();
        } else {
          timeInMilliSeconds = this.props.date.getTime() - now.getTime();
        }
        if (timeInMilliSeconds < 0) {
          timeInMilliSeconds = 0;
        }
        daysToShow = Math.floor(timeInMilliSeconds / 86400000);
        hoursToShow = Math.floor((timeInMilliSeconds - (86400000 * daysToShow)) / 3600000);
        minutsToShow = Math.floor((timeInMilliSeconds - (86400000 * daysToShow) - (3600000 * hoursToShow)) / 60000);
        secondsToShow = Math.floor((timeInMilliSeconds - (86400000 * daysToShow) - (3600000 * hoursToShow) - (60000 * minutsToShow)) / 1000);
      }
    }
    const timeIsUp = !(this.props.countDirection === "up" || (this.props.countDirection === "down" && new Date(this.props.date) > new Date()));
    return this.props.christmasSettings ? (
      <IAChristmasCountdown
        countDirection={this.props.countDirection}
        title={this.props.title}
        image={this.props.image}
        backgroundColor={this.props.backgroundColor}
        titleColor={this.props.titleColor}
        sizeUnit={this.props.sizeUnit}
        christmasSettings={this.props.christmasSettings}
        isVertical={this.props.isVertical}
        environment={this.props.environment}
        tenantId={this.props.tenantId}
        profileId={this.props.profileId}
        widgetId={this.props.widgetId}
        playerId={this.props.playerId}
        isActive={this.props.isActive}
      />
    ) : (
      <>
        <div
          className={styles.IA_counterBackground}
          style={{
            backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : "black",
            backgroundImage: this.props.image ? `url("${this.props.image}")` : "none"
          }}
        />
        {this.props.image &&
          <div
            className={styles.IA_counterBackgroundOverlay}
            style={{
              backgroundColor: this.props.overlayColor ? this.props.overlayColor : "black",
              opacity: this.props.overlayOpacity ? this.props.overlayOpacity : 0.5
            }}
          />
        }
        <div
          id={`IA_CountdownContent_${this.id}`}
          className={styles.IA_content}
          style={{
            top: contentTopPosition,
            transform: this.props.isVertical ? "scale(0.7)" : "scale(1)",
            transformOrigin: "center center"
          }}
        >
          {this.props.title &&
            <div
              className={styles.IA_headline}
              style={{
                color: this.props.titleColor ? this.props.titleColor : "white",
                fontSize: this.props.sizeUnit * 7,
                lineHeight: `${this.props.sizeUnit * 5}px`,
                padding: `0 ${this.props.sizeUnit * 2}px`,
                marginBottom: this.props.sizeUnit * 8,
                fontFamily: this.props.headlineFont
              }}
            >
              {this.props.title}
            </div>
          }
          <div
            className={styles.IA_counter}
            style={{
              color: this.props.titleColor ? this.props.titleColor : "white",
              fontSize: this.props.sizeUnit * 15,
              columnGap: this.props.sizeUnit * 7,
              marginBottom: this.props.sizeUnit * 8,
              justifyContent: "center",
              width: timeIsUp ? "80%" : "60%",
              marginLeft: timeIsUp ? "10%" : "20%"
            }}
          >
            {timeIsUp ?
              <div style={{
                WebkitLineClamp: 3,
                display: "-webkit-box",
                textOverflow: "ellipsis",
                boxOrient: "vertical",
                overflow: "hidden",
                maxHeight: this.props.sizeUnit * 52,
                WebkitBoxOrient: "vertical",
                fontFamily: this.props.headlineFont
              }}>
                {this.props.countdownEndedText ? this.props.countdownEndedText : this.localizationService?.strings?.PlayAdmin_CounterTimeIsUp}
              </div>
              :
              <>
                {this.props.showDays &&
                  <div
                    className={styles.IA_days}
                    style={{
                      // width: this.props.showSeconds ? "20%" : "unset"
                    }}
                  >
                    {this.getCounterType(daysToShow < 10 ? `0${daysToShow}` : `${daysToShow}`)}
                    <div
                      className={styles.IA_numberInfo}
                      style={{
                        fontSize: this.props.sizeUnit * 3,
                        paddingLeft: this.props.sizeUnit,
                        paddingRight: this.props.sizeUnit,
                        fontFamily: this.props.contentFont
                      }}
                    >
                      {this.localizationService?.strings?.PlayAdmin_CounterDays?.toUpperCase()}
                    </div>
                  </div>
                }
                {this.props.showHours &&
                  <div className={styles.IA_hours}>
                    {this.getCounterType(hoursToShow < 10 ? `0${hoursToShow}` : `${hoursToShow}`)}
                    <div
                      className={styles.IA_numberInfo}
                      style={{
                        fontSize: this.props.sizeUnit * 3,
                        paddingLeft: this.props.sizeUnit,
                        paddingRight: this.props.sizeUnit,
                        fontFamily: this.props.contentFont
                      }}
                    >
                      {this.localizationService?.strings?.PlayAdmin_CounterHours?.toUpperCase()}
                    </div>
                  </div>
                }
                {this.props.showMinuts &&
                  <div className={styles.IA_minuts}>
                    {this.getCounterType(minutsToShow < 10 ? `0${minutsToShow}` : `${minutsToShow}`)}
                    <div
                      className={styles.IA_numberInfo}
                      style={{
                        fontSize: this.props.sizeUnit * 3,
                        paddingLeft: this.props.sizeUnit,
                        paddingRight: this.props.sizeUnit,
                        fontFamily: this.props.contentFont
                      }}
                    >
                      {this.localizationService?.strings?.PlayAdmin_CounterMinutes?.toUpperCase()}
                    </div>
                  </div>
                }
                {this.props.showSeconds &&
                  <div className={styles.IA_seconds}>
                    {this.getCounterType(secondsToShow < 10 ? `0${secondsToShow}` : `${secondsToShow}`)}
                    <div
                      className={styles.IA_numberInfo}
                      style={{
                        fontSize: this.props.sizeUnit * 3,
                        paddingLeft: this.props.sizeUnit,
                        paddingRight: this.props.sizeUnit,
                        fontFamily: this.props.contentFont
                      }}
                    >
                      {this.localizationService?.strings?.PlayAdmin_CounterSeconds?.toUpperCase()}
                    </div>
                  </div>
                }
              </>
            }
          </div>
          {this.props.description &&
            <div
              className={styles.IA_description}
              style={{
                color: this.props.titleColor ? this.props.titleColor : "white",
                fontSize: this.props.sizeUnit * 4,
                lineHeight: `${this.props.sizeUnit * 3}px`,
                padding: `0 ${this.props.sizeUnit * 2}px`,
                fontFamily: this.props.contentFont
              }}
            >
              {this.props.description}
            </div>
          }
        </div >
      </>
    );
  }
}