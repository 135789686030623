import * as React from 'react';
import { Store } from '../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './Design.css';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IAColorPicker } from '../../../../colorPicker/ColorPicker';
import { Footer } from '../../../../player/footer/Footer';
import { IAToggle } from '../../../../toggle/Toggle';
import { IAButton } from '../../../../button/Button';
import PlayService from '../../../../../services/PlayService';
import { MediaFileSelector } from '../../mediaFiles/mediaFileSelector/MediaFileSelector';
import { IADropDown } from '../../../../dropDown/DropDown';
import { IMediaFile } from '../../../../../interfaces/IPlay';
import { IAMessageBar } from '../../../../messageBar/MessageBar';
import { Helper } from '../../../../../Helper';

export interface IProps {
  showSkeleton: boolean;
  store?: Store;
}

export interface IState {
  width: number;
}

@inject('store')
@observer
export class Design extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      width: undefined,
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentDidMount(): void {
    const progressBarElement = document.getElementById("IA_Profile");
    if (progressBarElement) {
      this.setState({ width: progressBarElement.clientWidth });
    }
    this.getMediaFiles();
  }

  private getMediaFiles() {
    if (this.props.store.profile?.id) {
      PlayService.getMediaFiles(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, "font").then(mediaFiles => {
        this.props.store.mediaFiles = mediaFiles.value;
        this.props.store.mediaFilesCount = mediaFiles.count;
        this.forceUpdate();
      }).catch(() => {
        console.log("Media files failed to load");
      });
    } else {
      setTimeout(() => {
        this.getMediaFiles();
      }, 500);
    }
  }

  private setFonts(): void {
    if (this.props.store.profile.fonts) {
      if (this.props.store.profile.fonts?.headline) {
        const font: { name: string, url: string } = JSON.parse(this.props.store.profile.fonts?.headline);
        if (font.url) {
          const fontFace: FontFace = new FontFace(font.name, `url(${font.url})`);
          this.props.store.loadFont(fontFace, "headline");
        }
      }
      if (this.props.store.profile.fonts?.body) {
        const font: { name: string, url: string } = JSON.parse(this.props.store.profile.fonts?.body);
        if (font.url) {
          const fontFace: FontFace = new FontFace(font.name, `url(${font.url})`);
          this.props.store.loadFont(fontFace, "body");
        }
      }
    }
  }

  public render(): JSX.Element {
    const swatches = [];
    if (this.props.store.profile?.swatches?.length > 0) {
      this.props.store.profile.swatches.forEach((swatch: string, index) => {
        swatches.push(
          <div key={"swatch_" + swatch}>
            <IAColorPicker
              color={swatch}
              defaultColor="#00ffff"
              showClearButton={false}
              darkMode={this.props.store.darkMode}
              onChange={(color: string) => {
                this.props.store.profile.swatches[index] = color;
              }}
              removeColor={() => {
                this.props.store.profile.swatches.splice(index, 1);
              }}
              styles={{
                width: 70,
              }}
            />
          </div>
        );
      });
    }
    const fontOptions = [
      {
        key: JSON.stringify({
          name: '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
          url: undefined
        }),
        value: "Segoe UI (Standard)"
      },
      // {
      //   key: JSON.stringify({
      //     name: '"Comic Sans MS", "Comic Sans", cursive',
      //     url: undefined
      //   }),
      //   value: "Comic Sans"
      // },
      {
        key: JSON.stringify({
          name: "Roboto Bold",
          url: "https://intraactivestorage.blob.core.windows.net/cdn/fonts/Roboto-Bold.ttf"
        }),
        value: "Roboto Bold"
      },
      {
        key: JSON.stringify({
          name: "Roboto Light",
          url: "https://intraactivestorage.blob.core.windows.net/cdn/fonts/Roboto-Light.ttf"
        }),
        value: "Roboto Light"
      },
      {
        key: JSON.stringify({
          name: "Roboto Medium",
          url: "https://intraactivestorage.blob.core.windows.net/cdn/fonts/Roboto-Medium.ttf"
        }),
        value: "Roboto Medium"
      },
      {
        key: JSON.stringify({
          name: "Roboto Regular",
          url: "https://intraactivestorage.blob.core.windows.net/cdn/fonts/Roboto-Regular.ttf"
        }),
        value: "Roboto Regular"
      },
      {
        key: JSON.stringify({
          name: "Tahoma",
          url: "https://intraactivestorage.blob.core.windows.net/cdn/fonts/tahoma.ttf"
        }),
        value: "Tahoma"
      },
      {
        key: JSON.stringify({
          name: "Trebuchet MS",
          url: undefined
        }),
        value: "Trebuchet MS"
      },
      {
        key: JSON.stringify({
          name: "Verdana",
          url: undefined
        }),
        value: "Verdana"
      }
    ];
    this.props.store.mediaFiles?.forEach((customFont: IMediaFile) => {
      if (customFont.mimeType?.includes("font")) {
        fontOptions.push({
          key: JSON.stringify({
            name: customFont.name,
            url: customFont.url
          }),
          value: customFont.name
        });
      }
    }); if (fontOptions.length > 2) {
      console.log(this.props.store.profile?.fonts?.body);
      console.log(fontOptions[2].key)
    }
    return (
      <div
        className={styles.IA_design}
        id="IA_Profile"
      >
        <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : '#333333' }}>{this.localizationService.strings.PlayAdmin_ProfileColors}</h3>
        <IAButton
          label={this.localizationService.strings.PlayAdmin_AddSwatch?.toUpperCase()}
          buttonColor={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onClick={() => {
            if (!this.props.store.profile?.swatches) {
              this.props.store.profile.swatches = [];
            }
            this.props.store.profile?.swatches.push(this.props.store.highlightColor);
          }}
          borderRadius={5}
          style={{
            clear: "both"
          }}
        />
        <div className={styles.IA_swatches}>
          {swatches}
        </div>
        {/* Font family */}
        <div
          className={styles.IA_divider}
          style={{
            backgroundColor: this.props.store.darkMode ? "#727272" : "#eeeeee"
          }}
        />
        <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : '#333333' }}>{this.localizationService.strings.PlayAdmin_FontFamily}</h3>
        <div 
        className={styles.IA_fontPreview}
        style={{
          backgroundColor: this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"
        }}
        >
          <div className={styles.IA_fontPreviewLabel}>PREVIEW</div>
          <div
            className={styles.IA_fontPreviewHeadline}
            style={{
              fontFamily: this.props.store.profile.fonts?.headline ? JSON.parse(this.props.store.profile.fonts.headline).name : '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'
            }}
          >
            The quick brown fox jumps over the lazy dog
          </div>
          <div
            className={styles.IA_fontPreviewContent}
            style={{
              fontFamily: this.props.store.profile.fonts?.body ? JSON.parse(this.props.store.profile.fonts.body).name : '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
          </div>
        </div>
        <IADropDown
          label={this.localizationService.strings.PlayAdmin_Headline?.toUpperCase()}
          labelStyle={{
            fontSize: 12,
            float: "left"
          }}
          darkMode={this.props.store.darkMode}
          toolTip={this.localizationService.strings.PlayAdmin_HeadlineToolTip}
          selectedOption={this.props.store.profile?.fonts?.headline}
          options={fontOptions}
          borderRadius={5}
          highlightColor={this.props.store.highlightColor}
          onChange={(font) => {
            console.log(font);
            if (this.props.store.profile.fonts == undefined) {
              this.props.store.profile.fonts = {};
            }
            this.props.store.profile.fonts.headline = font;
            this.setFonts();
            this.forceUpdate();
          }}
          style={{
            clear: "none",
            float: "left",
            marginRight: 20,
            width: "100%"
          }}
        />
        <IADropDown
          label={this.localizationService.strings.PlayAdmin_Content.toUpperCase()}
          labelStyle={{
            fontSize: 12,
            float: "left"
          }}
          darkMode={this.props.store.darkMode}
          toolTip={this.localizationService.strings.PlayAdmin_ContentToolTip}
          selectedOption={this.props.store.profile?.fonts?.body}
          options={fontOptions}
          borderRadius={5}
          highlightColor={this.props.store.highlightColor}
          onChange={(font) => {
            console.log(font);
            if (this.props.store.profile.fonts == undefined) {
              this.props.store.profile.fonts = {};
            }
            this.props.store.profile.fonts.body = font;
            this.setFonts();
            this.forceUpdate();
          }}
          style={{
            clear: "none",
            float: "left",
            marginRight: 20,
            width: "100%"
          }}
        />
        <IAMessageBar
          type="information"
          onDismiss={() => { }}
          icon="Info"
          content={this.localizationService.strings.PlayAdmin_AddCustomFontMessage}
          showIcon={false}
          showCloseButton={false}
          style={{
            marginTop: 20
          }}
        />
        <IAButton
          buttonColor={this.props.store.highlightColor}
          textColor='#ffffff'
          darkMode={this.props.store.darkMode}
          label={this.localizationService.strings.PlayAdmin_OpenMediaLibrary?.toUpperCase()}
          onClick={() => {
            this.props.store.selectedMenuItem = "mediaFiles";
            this.props.store.showSettingsPanel = false;
          }}
          borderRadius={5}
          style={{
            float: "left",
            marginTop: 10,
            clear: "both"
          }}
        />
        {/* Footer */}
        <div
          className={styles.IA_divider}
          style={{
            backgroundColor: this.props.store.darkMode ? "#727272" : "#eeeeee"
          }}
        />
        <>
          <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : '#333333' }}>{this.localizationService.strings.PlayAdmin_Footer}</h3>
          {this.state.width && this.props.store.profile?.footer &&
            <div style={{
              float: "left",
              clear: "both",
              position: "relative",
              width: "100%",
              height: (this.state.width / 16 * 9) / 12,
              marginTop: 10,
              marginBottom: 20
            }}>
              <Footer
                footer={this.props.store.profile?.footer}
                sizeUnit={(this.state.width / 16 * 9) / 100}
                city={this.props.store.city}
                currentWeather={this.props.store.weatherData?.current}
                editMode={true}
              />
            </div>
          }
          <IAColorPicker
            label={this.localizationService.strings.PlayAdmin_FooterBackgroundColor}
            color={this.props.store.profile?.footer?.backgroundColor}
            swatches={this.props.store.profile?.swatches}
            toolTip={<div>{this.localizationService.strings.PlayAdmin_EditSwatches}</div>}
            inTeams={this.props.store.isTeams}
            allowAddingToSwatches
            darkMode={this.props.store.darkMode}
            onChange={(backgroundColor: string) => {
              this.props.store.profile.footer.backgroundColor = backgroundColor;
              this.props.store.profileHasChanged = true;
              this.forceUpdate();
            }}
            styles={{
              width: "auto",
              marginRight: 40,
              float: "left",
              clear: "both"
            }}
            labelStyle={{
              textTransform: "uppercase",
              fontSize: 12
            }}
            showClearButton={false}
            borderRadius={5}
            addSwatch={(color: string) => this.props.store.addSwatch(color)}
          />
          <IAColorPicker
            label={this.localizationService.strings.PlayAdmin_FooterColor}
            color={this.props.store.profile?.footer?.color}
            swatches={this.props.store.profile?.swatches}
            toolTip={<div>{this.localizationService.strings.PlayAdmin_EditSwatches}</div>}
            inTeams={this.props.store.isTeams}
            allowAddingToSwatches
            darkMode={this.props.store.darkMode}
            onChange={(color: string) => {
              this.props.store.profile.footer.color = color;
              this.props.store.profileHasChanged = true;
              this.forceUpdate();
            }}
            styles={{
              width: "auto"
            }}
            labelStyle={{
              textTransform: "uppercase",
              fontSize: 12
            }}
            showClearButton={false}
            borderRadius={5}
            addSwatch={(color: string) => this.props.store.addSwatch(color)}
          />
          <IAToggle
            label={this.localizationService.strings.PlayAdmin_FooterShowLogo}
            labelStyle={{
              textTransform: "uppercase",
              fontSize: 12
            }}
            checked={this.props.store.profile?.footer?.showLogo}
            darkMode={this.props.store.darkMode}
            color={this.props.store.highlightColor}
            style={{
              marginTop: 20,
              width: "100%"
            }}
            onChange={() => {
              this.props.store.profile.footer.showLogo = !this.props.store.profile.footer.showLogo;
              this.props.store.profileHasChanged = true;
            }}
          />
          {this.props.store.profile?.footer?.showLogo &&
            <MediaFileSelector
              url={this.props.store.profile?.footer?.logoUrl}
              label={this.localizationService.strings.PlayAdmin_FooterLogo?.toUpperCase()}
              type={"image"}
              onChange={(logoUrl: string) => {
                // for some reason we have to encode "(" and ")" manually  
                this.props.store.profile.footer.logoUrl = logoUrl ? logoUrl.replace(/\(/g, '%28').replace(/\)/g, '%29') : logoUrl;
                this.props.store.profileHasChanged = true;
                this.forceUpdate();
              }}
            />
          }
          <IAToggle
            label={this.localizationService.strings.PlayAdmin_FooterShowWeather}
            checked={this.props.store.profile?.footer?.showWeather}
            color={this.props.store.highlightColor}
            labelStyle={{
              textTransform: "uppercase",
              fontSize: 12
            }}
            darkMode={this.props.store.darkMode}
            style={{
              marginTop: 20,
              width: "100%"
            }}
            onChange={() => {
              this.props.store.profile.footer.showWeather = !this.props.store.profile.footer.showWeather;
              this.props.store.profileHasChanged = true;
            }}
          />
          <IAToggle
            label={this.localizationService.strings.PlayAdmin_FooterShowClock}
            checked={this.props.store.profile?.footer?.showClock}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            labelStyle={{
              textTransform: "uppercase",
              fontSize: 12
            }}
            style={{
              marginTop: 20,
              width: "100%"
            }}
            onChange={() => {
              this.props.store.profile.footer.showClock = !this.props.store.profile.footer.showClock;
              this.props.store.profileHasChanged = true;
            }}
          />
        </>
      </div>
    );
  }

  componentWillUnmount(): void {
    if (this.props.store.profile?.id) {
      PlayService.getPlayProfile(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id).then((profile) => {
        if (profile !== 401 && profile !== 404) {
          this.props.store.profile = profile;
        }
      });
    }
  }


}