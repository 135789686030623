exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_gallery_s2b3hSIud0PqMdJ91eAZn {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n}\n\n.IA_image_21g1ROp76UAVsg6TmjCx04 {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background-size: cover;\n  background-position: center;\n}\n\n.IA_imageWithSlidingTransition_1CUx3sn9MLseeV9y_1BbxB {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  float: left;\n  background-size: cover;\n  background-position: center;\n}\n\n.IA_thumbnails_2hme1s_4k0NIbV5gHf6-pR {\n  width: 100%;\n  position: absolute;\n  bottom: 0;\n  background-color: rgb(51,51,51);\n}\n\n.IA_thumbnailsContainer_Nmkz157qz5QvaMxL73k55 {\n  position: relative;\n  height: 100%;\n  width: max-content;\n  transition: margin-left 500ms ease-in-out;\n}\n\n.IA_thumbnail_sQIYFgQgZAMYR6LlQy4rg {\n  width: 100px;\n  height: 100px;\n  float: left;\n  background-size: cover;\n  background-position: center;\n  transition: opacity 500ms ease-in-out;\n}", ""]);

// Exports
exports.locals = {
	"IA_gallery": "IA_gallery_s2b3hSIud0PqMdJ91eAZn",
	"IA_image": "IA_image_21g1ROp76UAVsg6TmjCx04",
	"IA_imageWithSlidingTransition": "IA_imageWithSlidingTransition_1CUx3sn9MLseeV9y_1BbxB",
	"IA_thumbnails": "IA_thumbnails_2hme1s_4k0NIbV5gHf6-pR",
	"IA_thumbnailsContainer": "IA_thumbnailsContainer_Nmkz157qz5QvaMxL73k55",
	"IA_thumbnail": "IA_thumbnail_sQIYFgQgZAMYR6LlQy4rg"
};