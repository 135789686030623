import * as React from 'react';
import { IColorTheme } from '../../../interfaces/IColorTheme';
import { IEnvironment } from '../../../interfaces/IEnvironment';
import { IPlayFooter, IPlayRssTickerWidget, IPlaySlideExtended, IPlayWeatherData, IPlayWidgetAreaType, IPlayWidgetExtended } from '../../../interfaces/IPlay';
import { LocalizationService } from '../../../services/LocalizationService';
import { IAIcon } from '../../icon/Icon';
import { PlayHelper } from '../../playAdmin/PlayHelper';
import * as styles from './Slide.css';
import { Widget } from './Widget';

export interface IProps {
  index: number;
  slide: IPlaySlideExtended;
  isActive: boolean;
  height: number;
  width: number;
  footer: IPlayFooter;
  left: number;
  city: string;
  environment: IEnvironment;
  tenantId: string;
  profileId: string;
  playerId: string;
  highlightColor?: string;
  editMode?: boolean;
  editPreviewMode?: boolean;
  showActivityLabel?: boolean;
  editWidgetIndex?: (widgetIndex: number) => void;
  previewColorTheme?: IColorTheme;
  rssTicker?: IPlayRssTickerWidget;
  language?: string;
  standalone?: boolean;
  forecast?: IPlayWeatherData;
  isHardcodedWeatherData?: boolean;
  headlineFont: string;
  contentFont: string;
  nextSlide: () => void;
}

export interface IState {
  activeSlide: boolean;
  widgetsStatus: boolean[];
}

export class Slide extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService;

  constructor(props: IProps) {
    super(props);
    this.state = {
      activeSlide: false,
      widgetsStatus: undefined
    };
    this.localizationService = new LocalizationService(PlayHelper.getLocalizerLanguageFromPlayerLanguage(this.props.language));
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.isActive) {
      if (this.state.activeSlide != true) {
        const widgetsStatus: boolean[] = [];
        props.slide.widgets.forEach(() => {
          widgetsStatus.push(false);
        })
        this.setState({ activeSlide: true, widgetsStatus }, () => {
          setTimeout(() => {
            this.forceUpdate();
          }, 0);
        });
      }
    } else {
      if (this.state.activeSlide != false) {
        setTimeout(() => {
          this.setState({ activeSlide: false });
        }, 500);
      }
    }
  }

  private editInterface(widgetId: string, widgetIndex: number): JSX.Element {
    return this.props.editMode ? (
      <div
        id={`widgetIndex_${widgetIndex}`}
        className={styles.IA_editInterface}
      >
        <div className={styles.IA_editInterfaceBackground} />
        <div className={styles.IA_editInterfaceBox}>
          <IAIcon
            title={widgetId ? "Edit" : "Add"}
            size={18}
            color={this.props.highlightColor ? this.props.highlightColor : "#fc8a00"}
            style={{
              marginTop: 3,
              marginLeft: 3,
              float: "left"
            }}
            onClick={() => {
              this.props.editWidgetIndex(widgetIndex);
            }}
          />
        </div>
      </div>
    ) : (undefined);
  }

  private aspectRatioInfo(widgetArea: "full" | "column" | "small" | "fullPortrait" | "columnPortraitUpper" | "columnPortraitLower", showFooter: boolean, showRssTicker: boolean): JSX.Element {
    try {
      let fraction: string;
      let footerAndRssHeightPercent = 1;
      const widgetsInColumn = widgetArea === "small" ? 2 : 1;
      footerAndRssHeightPercent -= showFooter ? 0.08 / widgetsInColumn : 0;
      footerAndRssHeightPercent -= showRssTicker ? 0.06 / widgetsInColumn : 0;

      switch (widgetArea) {
        case "full":
          fraction = `16:${(9 * footerAndRssHeightPercent).toFixed(2).replace(/\.?0*$/g, '')}`;
          break;
        case "column":
          fraction = `8:${(9 * footerAndRssHeightPercent).toFixed(2).replace(/\.?0*$/g, '')}`;
          break;
        case "small":
          fraction = `16:${(9 * footerAndRssHeightPercent).toFixed(2).replace(/\.?0*$/g, '')}`;
          break;
        case "fullPortrait":
          fraction = `9:${(16 * footerAndRssHeightPercent).toFixed(2).replace(/\.?0*$/g, '')}`;
          break;
        case "columnPortraitUpper":
          fraction = `41:${(50 * footerAndRssHeightPercent).toFixed(2).replace(/\.?0*$/g, '')}`;
          break;
        case "columnPortraitLower":
          fraction = `16:9`;
          break;
        default:
          break;
      }

      return this.props.editPreviewMode ? (
        <div className={styles.IA_editInterface}>
          <div
            style={{
              position: "absolute",
              top: 0,
              backgroundColor: "rgba(0,0,0,0.5)",
              color: "white",
              padding: "2px 5px",
              borderBottomRightRadius: 5
            }}
          >
            {fraction}
          </div>
        </div>
      ) : (undefined);
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private widgetContentWillRestart(index: number) {
    try {
      const widgetsStatus: boolean[] = this.state.widgetsStatus;
      widgetsStatus[index] = true;
      this.setState({ widgetsStatus }, () => {
        let allWidgetsAreDone = true;
        this.state.widgetsStatus.forEach((status) => {
          if (!status) {
            allWidgetsAreDone = false;
          }
        });
        if (allWidgetsAreDone) {
          this.props.nextSlide();
        }
      });
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  public render(): JSX.Element {
    const widgets = [];
    const widgetIds = [];
    let layout;
    let height = this.props.height;
    try {
      if (this.props.slide?.widgets?.length > 0) {
        this.props.slide.widgets.forEach((widget: IPlayWidgetExtended, index) => {
          widgetIds.push(widget.id);
          let areaType: IPlayWidgetAreaType = "full";
          if (this.props.slide.layout === "twoColumns") {
            areaType = "vertical";
          }
          if (this.props.slide.layout === "rightSideTwoRows") {
            if (index === 0) {
              areaType = "vertical";
            } else {
              areaType = "small";
            }
          }
          if (this.props.slide.layout === "leftAndRightSideTwoRows") {
            areaType = "small";
          }
          if (this.props.slide.layout === "portraitOneRow") {
            areaType = "portraitFull";
          }
          if (this.props.slide.layout === "portraitTwoRows") {
            if (index === 0) {
              areaType = "portraitHalf";
            } else {
              areaType = "small";
            }
          }
          widgets.push(
            <Widget
              key={`widget_${widget.id}`}
              widget={widget}
              isOnActiveSlide={this.state.activeSlide}
              playerHeight={this.props.height}
              playerWidth={this.props.width}
              environment={this.props.environment}
              tenantId={this.props.tenantId}
              profileId={this.props.profileId}
              playerId={this.props.playerId}
              index={index}
              headlineFont={this.props.headlineFont}
              contentFont={this.props.contentFont}
              editPreviewMode={this.props.editPreviewMode}
              showActivityLabel={this.props.showActivityLabel}
              highlightColor={this.props.highlightColor}
              previewColorTheme={this.props.previewColorTheme}
              areaType={areaType}
              language={this.props.language}
              forecast={this.props.forecast}
              isHardcodedWeatherData={this.props.isHardcodedWeatherData}
              widgetContentWillRestart={() => {
                if (this.props.isActive && !this.props.standalone) {
                  this.widgetContentWillRestart(index);
                }
              }}
            />
          );
        });
      }
      if (this.props.slide.showFooter) {
        height = height - ((this.props.height > this.props.width ? this.props.width / 100 : this.props.height / 100) * 8);
      }
      const showRssTicker = this.props.slide?.showRssTicker && (this.props.rssTicker || this.props.editMode || (this.props.editPreviewMode && this.props.rssTicker));
      if (showRssTicker) {
        height = height - ((this.props.height > this.props.width ? this.props.width / 100 : this.props.height / 100) * 6);
      }
      switch (this.props.slide.layout) {
        case "oneColumn":
          layout = (
            <div
              className={styles.IA_oneColumn}
              style={{
                height: height,
                border: this.props.editMode ? "1px solid #ffffff" : "none"
              }}
            >
              {this.editInterface(widgetIds[0], 0)}
              {this.aspectRatioInfo("full", this.props.slide.showFooter, !!showRssTicker)}
              {widgets[0]}
            </div>
          );
          break;
        case "twoColumns":
          layout = (
            <div
              className={styles.IA_twoColumns}
              style={{
                height: height,
              }}
            >
              <div
                className={styles.IA_columnOne}
                style={{
                  border: this.props.editMode ? "1px solid #ffffff" : "none"
                }}
              >
                {this.editInterface(widgetIds[0], 0)}
                {this.aspectRatioInfo("column", this.props.slide.showFooter, !!showRssTicker)}
                {widgets[0]}
              </div>
              <div
                className={styles.IA_columnTwo}
                style={{
                  border: this.props.editMode ? "1px solid #ffffff" : "none"
                }}
              >
                {this.editInterface(widgetIds[1], 1)}
                {this.aspectRatioInfo("column", this.props.slide.showFooter, !!showRssTicker)}
                {widgets[1]}
              </div>
            </div>
          );
          break;
        case "rightSideTwoRows":
          layout = (
            <div
              className={styles.IA_twoColumns}
              style={{
                height: height,
              }}
            >
              <div
                className={styles.IA_columnOne}
                style={{
                  border: this.props.editMode ? "1px solid #ffffff" : "none"
                }}
              >
                {this.editInterface(widgetIds[0], 0)}
                {this.aspectRatioInfo("column", this.props.slide.showFooter, !!showRssTicker)}
                {widgets[0]}
              </div>
              <div className={styles.IA_columnTwo}>
                <div
                  className={styles.IA_rowOne}
                  style={{
                    border: this.props.editMode ? "1px solid #ffffff" : "none"
                  }}
                >
                  {this.editInterface(widgetIds[1], 1)}
                  {this.aspectRatioInfo("small", this.props.slide.showFooter, !!showRssTicker)}
                  {widgets[1]}
                </div>
                <div
                  className={styles.IA_rowTwo}
                  style={{
                    border: this.props.editMode ? "1px solid #ffffff" : "none"
                  }}
                >
                  {this.editInterface(widgetIds[2], 2)}
                  {this.aspectRatioInfo("small", this.props.slide.showFooter, !!showRssTicker)}
                  {widgets[2]}
                </div>
              </div>
            </div>
          );
          break;
        case "leftAndRightSideTwoRows":
          layout = (
            <div
              className={styles.IA_twoColumns}
              style={{
                height: height,
              }}
            >
              <div className={styles.IA_columnOne}>
                <div
                  className={styles.IA_rowOne}
                  style={{
                    border: this.props.editMode ? "1px solid #ffffff" : "none"
                  }}
                >
                  {this.editInterface(widgetIds[0], 0)}
                  {this.aspectRatioInfo("small", this.props.slide.showFooter, !!showRssTicker)}
                  {widgets[0]}
                </div>
                <div
                  className={styles.IA_rowTwo}
                  style={{
                    border: this.props.editMode ? "1px solid #ffffff" : "none"
                  }}
                >
                  {this.editInterface(widgetIds[1], 1)}
                  {this.aspectRatioInfo("small", this.props.slide.showFooter, !!showRssTicker)}
                  {widgets[1]}
                </div>
              </div>
              <div
                className={styles.IA_columnTwo}
                style={{
                  border: this.props.editMode ? "1px solid #ffffff" : "none"
                }}
              >
                <div
                  className={styles.IA_rowOne}
                  style={{
                    border: this.props.editMode ? "1px solid #ffffff" : "none"
                  }}
                >
                  {this.editInterface(widgetIds[2], 2)}
                  {this.aspectRatioInfo("small", this.props.slide.showFooter, !!showRssTicker)}
                  {widgets[2]}
                </div>
                <div
                  className={styles.IA_rowTwo}
                  style={{
                    border: this.props.editMode ? "1px solid #ffffff" : "none"
                  }}
                >
                  {this.editInterface(widgetIds[3], 3)}
                  {this.aspectRatioInfo("small", this.props.slide.showFooter, !!showRssTicker)}
                  {widgets[3]}
                </div>
              </div>
            </div>
          );
          break;
        case "portraitOneRow":
          layout = (
            <div
              className={styles.IA_oneColumn}
              style={{
                height: height,
                border: this.props.editMode ? "1px solid #ffffff" : "none"
              }}
            >
              {this.editInterface(widgetIds[0], 0)}
              {this.aspectRatioInfo("fullPortrait", this.props.slide.showFooter, !!showRssTicker)}
              {widgets[0]}
            </div>
          );
          break;
        case "portraitTwoRows":
          const sixteenNineHeight = this.props.width * (9 / 16);
          layout = (
            <div
              className={styles.IA_oneColumn}
              style={{
                height: height,
                border: this.props.editMode ? "1px solid #ffffff" : "none"
              }}
            >
              <div
                className={styles.IA_rowOne}
                style={{
                  height: height - sixteenNineHeight,
                  border: this.props.editMode ? "1px solid #ffffff" : "none"
                }}
              >
                {this.editInterface(widgetIds[0], 0)}
                {this.aspectRatioInfo("columnPortraitUpper", this.props.slide.showFooter, !!showRssTicker)}
                {widgets[0]}
              </div>
              <div
                className={styles.IA_rowTwo}
                style={{
                  height: sixteenNineHeight,
                  border: this.props.editMode ? "1px solid #ffffff" : "none"
                }}
              >
                {this.editInterface(widgetIds[1], 1)}
                {this.aspectRatioInfo("columnPortraitLower", this.props.slide.showFooter, !!showRssTicker)}
                {widgets[1]}
              </div>
            </div>
          );
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
    return this.props.isActive ? (
      <div
        className={styles.IA_slide}
        style={{
          width: this.props.width,
          height: this.props.height,
          left: this.props.left,
          top: 0
        }}
      >
        {layout}
        {!PlayHelper.hasSlideActiveContent(this.props.slide) && this.props.showActivityLabel &&
          <div
            className={styles.IA_labelBackground}
            style={{
              backgroundColor: "#00000045"
            }}
          >
            <div
              className={styles.IA_label}
              style={{
                backgroundColor: "#ffffff",
                color: "#333333",
                height: height / 20,
                width: height / 2,
                left: -height / 6,
                top: height / 14,
                lineHeight: `${height / 20}px`,
                fontSize: `${height / 40}px`,
              }}
            >
              {this.localizationService.strings.Player_NotActive?.toUpperCase()}
            </div>
          </div>
        }
      </div>
    ) : (<></>);
  }
}