import * as React from 'react';
import * as styles from './Group.css';
import { IAIcon } from '../icon/Icon';


export interface IGroupProps {
  label?: string;
  borderColor?: string;
  style?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  contentStyle?: any;
  collapsed?: boolean;
  darkMode?: boolean;
}

export interface IGroupState {
  collapsed: boolean;
}

export class IAGroup extends React.Component<IGroupProps, IGroupState> {

  constructor(props: IGroupProps) {
    super(props);
    this.state = {
      collapsed: this.props.collapsed != undefined ? this.props.collapsed : false
    }
  }

  public componentWillReceiveProps(props: IGroupProps): void {
    if (props.collapsed !== this.props.collapsed) {
      this.setState({ collapsed: props.collapsed });
    }
  }

  public componentDidMount(): void {
    setTimeout(() => {
      this.setState({ collapsed: this.props.collapsed != undefined ? this.props.collapsed : false });
    }, 100);
  }

  public render(): JSX.Element {
    return (
      <div
        className={styles.IA_group}
        style={{
          ...this.props.style
        }}
      >
        {this.props.label &&
          <div
            className={styles.IA_title}
            style={{
              background: this.props.darkMode ? "#323232" : "#f8f8f8",
              borderColor: this.props.darkMode ? "#414141" : "#eeeeee",
              ...this.props.titleStyle
            }}
            onClick={() => {
              this.setState({ collapsed: !this.state.collapsed });
            }}
          >
            {this.props.label}
            <IAIcon
              url={this.state.collapsed ? "https://intraactivestorage.blob.core.windows.net/cdn/icons/Chevron Up/SVG/ic_fluent_chevron_up_48_regular.svg" : "https://intraactivestorage.blob.core.windows.net/cdn/icons/Chevron Down/SVG/ic_fluent_chevron_down_48_regular.svg"}
              size={18}
              color={this.props.darkMode ? "#ffffff" : "#333333"}
              style={{
                marginRight: 15,
                marginTop: 5
              }}
            />
          </div>
        }
        <div
          className={styles.IA_groupArea}
          style={{
            padding: this.state.collapsed ? 0 : "20px 40px 20px 40px",
            height: this.state.collapsed ? 0 : "auto",
            overflowY: this.state.collapsed ? "auto" : "none",
            overflowX: "hidden",
            ...this.props.contentStyle
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}