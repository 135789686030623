import * as React from 'react';
import { IColorTheme } from '../../../interfaces/IColorTheme';
import { IComposerImage } from '../../../interfaces/IComposerImage';
import { IComposerComponent } from '../../../interfaces/IComposerTool';
import { IPlayArticle, IPlayEvent, IPlayMessage, IPlayNewsItem, IPlayWidgetAreaType, IPlaySharepointNewsItem, IPlayWidgetExtended, IPlayBoxStyle, IPlayWeatherData, IPlayWorkplaceFeedItem, IFacebookFeedItem } from '../../../interfaces/IPlay';
import { Environment } from '../../../services/Environment';
import { Gallery } from './gallery/Gallery';
import { Image } from './image/Image';
import { List } from './list/List';
import { Rotator } from './rotator/Rotator';
import { Rss } from './rss/Rss';
import { Video } from './video/Video';
import { CanteenMenu } from './canteenMenu/CanteenMenu';
import { IEnvironment } from '../../../interfaces/IEnvironment';
import * as styles from './Slide.css';
import { Poll } from './poll/Poll';
import { Weather } from './weather/Weather';
import { Instagram } from './instagram/Instagram';
import * as showdown from 'showdown';
import { IACountdown } from './countdown/Countdown';
import { PowerBI } from './powerbi/PowerBI';
import { TimeEdit } from './timeEdit/TimeEdit';

export interface IProps {
  widget: IPlayWidgetExtended;
  isOnActiveSlide: boolean;
  environment: IEnvironment;
  tenantId: string;
  profileId: string;
  playerId: string;
  index: number;
  playerHeight?: number;
  playerWidth?: number;
  editPreviewMode?: boolean;
  showActivityLabel?: boolean;
  highlightColor: string;
  previewColorTheme?: IColorTheme;
  areaType?: IPlayWidgetAreaType;
  language?: string;
  forecast?: IPlayWeatherData;
  isHardcodedWeatherData?: boolean;
  headlineFont: string;
  contentFont: string;
  widgetContentWillRestart: () => void;
}

export interface IState {
}

export class Widget extends React.Component<IProps, IState> {
  public componentDidMount(): void {
    if (
      this.props.widget.type !== "articleViewer" &&
      this.props.widget.type !== "messageViewer" &&
      this.props.widget.type !== "sharepointNewsViewer" &&
      this.props.widget.type !== 'workplaceFeedViewer' &&
      this.props.widget.type !== 'facebookPageViewer' &&
      this.props.widget.type !== "video" &&
      this.props.widget.type !== "youtube" &&
      this.props.widget.type !== "vimeo" &&
      this.props.widget.type !== "gallery" &&
      this.props.widget.type !== "rss" &&
      this.props.widget.type !== "relesys"
    ) {
      try {
        setTimeout(() => {
          this.props.widgetContentWillRestart();
        }, this.props.widget.duration && this.props.widget.duration !== 0 ? (this.props.widget.duration * 1000) : (30 * 1000));
      } catch (error) {
        console.log(`%c${error}`, 'background: red; color: #ffffff')
      }
    }
  }

  public render(): JSX.Element {
    let areaWidth;
    let areaHeight;
    let content: React.ReactElement;
    let newsItems: IPlayNewsItem[] = [];
    let sizeUnit;
    try {
      if (document.getElementById(`widget_${this.props.widget?.id}_${this.props.index}`)) {
        const element = document.getElementById(`widget_${this.props.widget?.id}_${this.props.index}`);
        if (element) {
          areaWidth = element.clientWidth;
          areaHeight = element.clientHeight;
          sizeUnit = (areaHeight < areaWidth ? areaHeight : areaWidth) / 100;
        }
      }
      if (areaWidth == undefined) {
        setTimeout(() => {
          this.forceUpdate();
        }, 100);
      }
      switch (this.props.widget?.type) {
        case "countdown":
          if (this.props.isOnActiveSlide || this.props.editPreviewMode) {
            content = <IACountdown
              countDirection="up"
              title={this.props.widget?.countdown?.title}
              image={this.props.widget?.countdown?.imageUrl} // "https://images.pexels.com/photos/241820/pexels-photo-241820.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
              backgroundColor={this.props.widget?.countdown?.colorTheme?.backgroundColor} //"#e9363c"
              titleColor={this.props.widget?.countdown?.colorTheme?.textColor} // "white"
              sizeUnit={sizeUnit}
              christmasSettings={this.props.widget?.countdown?.christmasSettings ? this.props.widget?.countdown?.christmasSettings : {
                candleNumberColor: "#e9363c",
                candleColor: "white",
                showQRCode: true
              }}
              isVertical={areaWidth < areaHeight}
              environment={this.props.environment}
              tenantId={this.props.tenantId}
              profileId={this.props.profileId}
              widgetId={this.props.widget?.id}
              playerId={this.props.playerId}
              isActive={this.props.isOnActiveSlide}
              headlineFont={this.props.headlineFont}
              contentFont={this.props.contentFont}
            />;
          }
          break;
        case "counter":
          if (this.props.isOnActiveSlide || this.props.editPreviewMode) {
            content = <IACountdown
              countDirection={this.props.widget?.counter?.countDirection}
              title={this.props.widget?.counter?.title}
              description={this.props.widget?.counter?.description}
              image={this.props.widget?.counter?.imageUrl}
              backgroundColor={this.props.widget?.counter?.backgroundColor}
              titleColor={this.props.widget?.counter?.color}
              color={this.props.widget?.counter?.color}
              flipBackgroundColor={this.props.widget?.counter?.colorTheme?.backgroundColor}
              flipColor={this.props.widget?.counter?.colorTheme?.textColor}
              overlayColor={this.props.widget?.counter?.overlayColor}
              overlayOpacity={this.props.widget?.counter?.overlayOpacity}
              showDays={this.props.widget?.counter?.showDays}
              showHours={this.props.widget?.counter?.showHours}
              showMinuts={this.props.widget?.counter?.showMinuts}
              showSeconds={this.props.widget?.counter?.showSeconds}
              date={this.props.widget?.counter?.date ? new Date(this.props.widget?.counter?.date) : new Date()}
              sizeUnit={sizeUnit}
              isVertical={areaWidth < (areaHeight + (areaHeight / 20))}
              areaHeight={areaHeight}
              environment={this.props.environment}
              tenantId={this.props.tenantId}
              profileId={this.props.profileId}
              widgetId={this.props.widget?.id}
              playerId={this.props.playerId}
              isActive={this.props.isOnActiveSlide}
              layout={this.props.widget?.counter?.layout}
              language={this.props.language}
              countdownEndedText={this.props.widget?.counter?.countdownEndedText}
              headlineFont={this.props.headlineFont}
              contentFont={this.props.contentFont}
            />;
          }
          break;
        case "image":
          if (!this.props.widget.image) break;
          content = <Image
            imageUrl={this.props.widget?.image?.imageUrl}
            environment={this.props.environment}
            tenantId={this.props.tenantId}
            profileId={this.props.profileId}
            keepOriginal={this.props.widget.image?.keepOriginal}
            backgroundColor={"black"}
            isActive={true}
          />;
          break;
        case "powerbi":
          if (!this.props.widget.powerbi) break;
          content = <PowerBI
            reportId={this.props.widget.powerbi.reportId}
            embedUrl={this.props.widget.powerbi.reportEmbedUrl}
            accessToken={this.props.widget.powerbi.accessToken}
            pageName={this.props.widget.powerbi.pageName}
          />
        case "gallery":
          if (!this.props.widget.gallery) break;
          if (this.props.isOnActiveSlide) {
            content = <Gallery
              title={this.props.widget.title}
              gallery={this.props.widget?.gallery}
              playerHeight={this.props.playerHeight}
              playerWidth={this.props.playerWidth}
              backgroundColor='black'
              areaWidth={areaWidth}
              duration={this.props.widget?.gallery?.duration}
              transition={this.props.widget?.gallery?.transition}
              environment={this.props.environment}
              tenantId={this.props.tenantId}
              profileId={this.props.profileId}
              keepOriginal={this.props.widget?.gallery?.keepOriginal}
              widgetId={this.props.widget?.id}
              language={this.props.language}
              willRestart={() => this.props.widgetContentWillRestart()}
            />
          }
          break;
        case "rss":
          if (!this.props.widget.rss) break;
          if (this.props.isOnActiveSlide) {
            content = <Rss
              widget={this.props.widget}
              width={areaWidth}
              height={areaHeight}
              playerHeight={this.props.playerHeight}
              playerWidth={this.props.playerWidth}
              environment={this.props.environment}
              tenantId={this.props.tenantId}
              profileId={this.props.profileId}
              editPreviewMode={this.props.editPreviewMode}
              areaType={this.props.areaType}
              language={this.props.language}
              previewColorTheme={this.props.previewColorTheme}
              headlineFont={this.props.headlineFont}
              contentFont={this.props.contentFont}
              willRestart={() => this.props.widgetContentWillRestart()}
            />;
          }
          break;
        case "instagram":
          if (!this.props.widget.instagram) break;
          if (this.props.isOnActiveSlide) {
            content = <Instagram
              widget={this.props.widget}
              width={areaWidth}
              height={areaHeight}
              playerHeight={this.props.playerHeight}
              playerWidth={this.props.playerWidth}
              environment={this.props.environment}
              tenantId={this.props.tenantId}
              profileId={this.props.profileId}
              editPreviewMode={this.props.editPreviewMode}
              areaType={this.props.areaType}
              language={this.props.language}
              previewColorTheme={this.props.previewColorTheme}
              headlineFont={this.props.headlineFont}
              contentFont={this.props.contentFont}
              willRestart={() => this.props.widgetContentWillRestart()}
            />;
          }
          break;
        case "video":
        case "vimeo":
        case "video23":
        case "youtube":
          if (!this.props.widget.video) break;
          let marginLeft = 0;
          let marginTop = 0;
          if (this.props.isOnActiveSlide) {
            if (this.props.widget?.video?.fitToView) {
              const newAreaWidth = (areaHeight / 9) * 16;
              if (newAreaWidth < areaWidth) {
                const newAreaHeight = areaWidth / 16 * 9
                marginTop = (areaHeight - newAreaHeight) / 2;
                areaHeight = newAreaHeight;
              } else {
                marginLeft = (areaWidth - newAreaWidth) / 2;
                areaWidth = newAreaWidth;
              }
            }
            content = <Video
              type={this.props.widget.type}
              videoUrl={this.props.widget?.video?.videoUrl}
              width={areaWidth}
              height={areaHeight}
              marginLeft={marginLeft}
              marginTop={marginTop}
              playerHeight={this.props.playerHeight}
              playerWidth={this.props.playerWidth}
              showTitle={this.props.widget?.video.showTitle}
              title={this.props.widget?.title}
              environment={this.props.environment}
              profileId={this.props.profileId}
              tenantId={this.props.tenantId}
              willRestart={() => this.props.widgetContentWillRestart()}
            />;
          }
          break;
        case "embed":
          if (!this.props.widget.embed) break;
          if (this.props.widget?.embed?.url && this.props.widget?.embed.url !== "") {
            content = <iframe
              src={this.props.isOnActiveSlide ? this.props.widget?.embed?.url : ""}
              width="100%"
              height="100%"
              style={{ backgroundColor: "white" }}
            />
          }
          break;
        case "pdf":
          if (!this.props.widget.pdf) break;
          if (this.props.widget?.pdf?.url) {
            content = <object data={this.props.widget.pdf.url} type="application/pdf" width="100%" height="100%" >
              <iframe src={`${this.props.widget?.pdf.url}#toolbar=0&navpanes=0&scrollbar=0&view=fit`} width="100%" height="100%" />
            </object>
          }
          break;
        case "articleViewer":
          if (!this.props.widget.articleViewer) break;
          if (this.props.widget?.articleViewer?.articles) {
            this.props.widget?.articleViewer.articles.forEach((article: IPlayArticle) => {
              let text = "";
              if (article?.textContent?.length > 0) {
                article?.textContent?.forEach((t) => {
                  if (t !== undefined && t !== "") {
                    text += t;
                  }
                });
              }
              const newsItem: IPlayNewsItem = {
                id: article.id,
                title: article.showTitle ? (article.title ? article.title : "") : undefined,
                content: text,
                fontSize: article.fontSize ?? "small",
                date: article.showDate && article.startDate,
                author: article.createdBy?.displayName,
                image: article.imageUrl,
                video: article.video,
                gallery: article.galleryImages,
                link: article.link,
                linkText: article.linkText,
                colorTheme: article.colorTheme,
                boxStyle: article.boxStyle,
                contentPosition: article.contentPosition,
                contentVerticalPosition: article.contentVerticalPosition,
                boxStyleWidth: article.contentWidth,
                boxStyleRoundCorner: article.boxStyleRoundCorner,
                keepOriginalImage: article.keepOriginalImage,
                showTableBorder: article.showTableBorder
              };
              newsItems.push(newsItem);
            });
          }
          content = (
            <Rotator
              enableScroll={true}
              widgetType="articleViewer"
              newsItems={newsItems}
              widgetId={this.props.widget?.id}
              linkPortalURL={Environment.getEnvironmentForLinkPortal(this.props.environment)}
              width={areaWidth}
              height={areaHeight}
              playerHeight={this.props.playerHeight}
              playerWidth={this.props.playerWidth}
              environment={this.props.environment}
              tenantId={this.props.tenantId}
              profileId={this.props.profileId}
              transition={this.props.widget.articleViewer.transition}
              duration={this.props.widget.articleViewer.duration}
              editPreviewMode={this.props.editPreviewMode}
              areaType={this.props.areaType}
              showQRLink={true}
              showTitle={true}
              showText={true}
              showProgressBar={this.props.widget?.showProgressBar != undefined ? this.props.widget?.showProgressBar : true}
              showAuthor={false} // always hide author for articles
              showDate={true}
              showImage={true}
              showVideo={true}
              showGallery={true}
              language={this.props.language}
              widgetTitleIsShowing={this.props.widget?.showTitle}
              headerTitle={this.props.widget?.title}
              articleEditMode={this.props.widget.articleViewer.articleEditMode}
              noContentImage={this.props.widget.articleViewer.noContentImageUrl}
              headlineFont={this.props.headlineFont}
              contentFont={this.props.contentFont}
              willRestart={() => this.props.widgetContentWillRestart()}
            />
          );
          break;
        case "messageViewer":
          if (!this.props.widget.messageViewer) break;
          const ampuntToShow = this.props.widget.messageViewer?.amountToShow != undefined ? this.props.widget.messageViewer?.amountToShow : 5;
          this.props.widget.messageViewer.messages?.forEach((message: IPlayMessage, index: number) => {
            let gallery;
            let text = "";
            if (message?.configuration?.composerComponents?.length > 0) {
              message?.configuration?.composerComponents.forEach((component: IComposerComponent) => {
                switch (component.type) {
                  case "text":
                    if (component.text !== undefined && component.text !== "") {
                      text += component.text;
                    }
                    break;
                  case "gallery":
                    if (component.gallery?.images?.length > 0) {
                      gallery = [];
                      component.gallery.images.forEach((image: IComposerImage) => {
                        gallery.push(
                          image.url
                        );
                      });
                    }
                    break;
                }
              });
            }

            // The content showed is prioritized like this:
            // Feed text > teaser > message content
            let content = text;
            if (message.teaser && message.teaser !== "") content = message.teaser;
            if (message.configuration?.replay?.content && message.configuration?.replay?.content !== "") content = message.configuration?.replay?.content;

            const newsItem: IPlayNewsItem = {
              id: message.id,
              title: message.title,
              content: content,
              date: message.startTime,
              author: message.contacts && message.contacts.length > 0 ? message.contacts[0].name : message.author?.name,
              image: message.configuration?.replay?.boxStyle === "onlyVideo" ? undefined : message.featuredImage,
              video: message.configuration?.replay?.boxStyle === "onlyImage" ? undefined : message.featuredVideo,
              gallery: message.configuration?.replay?.boxStyle === "onlyImage" || message.configuration?.replay?.boxStyle === "onlyVideo" ? undefined : gallery,
              link: message.link,
              linkText: this.props.widget.messageViewer?.linkText,
              keepOriginalImage: this.props.widget.messageViewer.keepOriginalImage,
              boxStyle: message.configuration?.replay?.boxStyle as IPlayBoxStyle,
              contentPosition: this.props.widget.messageViewer.contentPosition
            };
            if (index < ampuntToShow) {
              newsItems.push(newsItem);
            }
          });
          content = (
            <Rotator
              enableScroll={this.props.widget.messageViewer.enableScroll}
              widgetType="messageViewer"
              newsItems={newsItems}
              widgetId={this.props.widget?.id}
              linkPortalURL={Environment.getEnvironmentForLinkPortal(this.props.environment)}
              width={areaWidth}
              height={areaHeight}
              playerHeight={this.props.playerHeight}
              playerWidth={this.props.playerWidth}
              environment={this.props.environment}
              tenantId={this.props.tenantId}
              headlineFont={this.props.headlineFont}
              contentFont={this.props.contentFont}
              profileId={this.props.profileId}
              transition={this.props.widget.messageViewer.transition}
              duration={this.props.widget.messageViewer.duration}
              boxStyle={this.props.widget.messageViewer.boxStyle}
              editPreviewMode={this.props.editPreviewMode}
              colorThemes={this.props.previewColorTheme ? [this.props.previewColorTheme] : this.props.widget.messageViewer.colorThemes}
              areaType={this.props.areaType}
              showQRLink={this.props.widget.messageViewer.showQRLink != undefined ? this.props.widget.messageViewer.showQRLink : true}
              showTitle={this.props.widget.messageViewer.showTitle != undefined ? this.props.widget.messageViewer.showTitle : true}
              showText={this.props.widget.messageViewer.showText != undefined ? this.props.widget.messageViewer.showText : true}
              showAuthor={this.props.widget.messageViewer.showAuthor != undefined ? this.props.widget.messageViewer.showAuthor : true}
              showDate={this.props.widget.messageViewer.showTime != undefined ? this.props.widget.messageViewer.showTime : true}
              showProgressBar={this.props.widget?.showProgressBar != undefined ? this.props.widget?.showProgressBar : true}
              showImage={this.props.widget.messageViewer.showImage != undefined ? this.props.widget.messageViewer.showImage : true}
              showVideo={this.props.widget.messageViewer.showVideo != undefined ? this.props.widget.messageViewer.showVideo : true}
              showGallery={this.props.widget.messageViewer.showGallery != undefined ? this.props.widget.messageViewer.showGallery : true}
              isMessages={true}
              widgetTitleIsShowing={this.props.widget?.showTitle}
              headerTitle={this.props.widget.messageViewer?.feedTitle ? this.props.widget.messageViewer?.feedTitle : this.props.widget?.title}
              language={this.props.language}
              noContentImage={this.props.widget.messageViewer.noContentImageUrl}
              willRestart={() => this.props.widgetContentWillRestart()}
            />
          );
          break;
        case "eventViewer":
          if (!this.props.widget.eventViewer) break;
          this.props.widget.eventViewer.events?.forEach((event: IPlayEvent) => {
            let content = event.configuration?.replay?.content && event.configuration?.replay?.content !== "" ? event.configuration?.replay?.content : event.content;
            // if (this.props.widget.eventViewer.layoutType === "week" && content) {
            //   content = Helper.stripHtml(content);
            // }
            const newsItem: IPlayNewsItem = {
              id: event.id,
              title: event.title,
              content: content,
              startDate: event.startTime,
              endDate: event.endTime,
              isAllDayEvent: event.allDayEvent,
              location: event.location,
              author: event.author?.name,
              image: event.featuredImage,
              keepOriginalImage: this.props.widget.eventViewer.keepOriginalImage,
              boxStyle: event.configuration?.replay?.boxStyle as IPlayBoxStyle,
              contentPosition: this.props.widget.eventViewer.contentPosition ? this.props.widget.eventViewer.contentPosition : "left"
            };
            newsItems.push(newsItem);
          });
          switch (this.props.widget.eventViewer.layoutType) {
            case "list":
              content = (
                <List
                  widgetId={this.props.widget?.id}
                  index={this.props.index}
                  newsItems={newsItems}
                  playerHeight={this.props.playerHeight}
                  playerWidth={this.props.playerWidth}
                  sizeUnit={sizeUnit}
                  headlineColor={this.props.widget.eventViewer.headlineColor}
                  backgroundColor={this.props.widget.eventViewer.backgroundColor}
                  boxColor={this.props.widget.eventViewer.boxColor}
                  borderColor={this.props.widget.eventViewer.borderColor}
                  textColor={this.props.widget.eventViewer.textColor}
                  timeBatchBackgroundColor={this.props.widget.eventViewer.timeBatchBackgroundColor}
                  timeBatchTextColor={this.props.widget.eventViewer.timeBatchTextColor}
                  timeBatchBorderColor={this.props.widget.eventViewer.timeBatchBorderColor}
                  showTitle={this.props.widget.eventViewer.showTitle != undefined ? this.props.widget.eventViewer.showTitle : true}
                  showText={this.props.widget.eventViewer.showText != undefined ? this.props.widget.eventViewer.showText : true}
                  showTime={this.props.widget.eventViewer.showTime != undefined ? this.props.widget.eventViewer.showTime : true}
                  showLocation={this.props.widget.eventViewer.showLocation != undefined ? this.props.widget.eventViewer.showLocation : true}
                  showTimeBox={this.props.widget.eventViewer.showTimeBox != undefined ? this.props.widget.eventViewer.showTimeBox : true}
                  areaType={this.props.areaType}
                  textLines={this.props.widget.eventViewer.textLines}
                  headline={this.props.widget.eventViewer.showHeadline && this.props.widget.title}
                  noContentImage={this.props.widget.eventViewer.noContentImageUrl}
                  language={this.props.language}
                  headlineFont={this.props.headlineFont}
                  contentFont={this.props.contentFont}
                />
              );
              break;
            case "week":
              content = (
                <CanteenMenu
                  widgetId={this.props.widget?.id}
                  index={this.props.index}
                  newsItems={newsItems}
                  sizeUnit={sizeUnit}
                  headlineColor={this.props.widget.eventViewer.headlineColor}
                  backgroundColor={this.props.widget.eventViewer.backgroundColor}
                  boxColor={this.props.widget.eventViewer.boxColor}
                  borderColor={"lightgray"}
                  textColor={this.props.widget.eventViewer.textColor}
                  timeBatchBackgroundColor={this.props.widget.eventViewer.timeBatchBackgroundColor}
                  timeBatchTextColor={this.props.widget.eventViewer.timeBatchTextColor}
                  timeBatchBorderColor={this.props.widget.eventViewer.timeBatchBorderColor}
                  showTitle={this.props.widget.eventViewer.showTitle != undefined ? this.props.widget.eventViewer.showTitle : true}
                  showText={this.props.widget.eventViewer.showText != undefined ? this.props.widget.eventViewer.showText : true}
                  showTime={this.props.widget.eventViewer.showTime != undefined ? this.props.widget.eventViewer.showTime : true}
                  showLocation={this.props.widget.eventViewer.showLocation != undefined ? this.props.widget.eventViewer.showLocation : true}
                  showTimeBox={this.props.widget.eventViewer.showTimeBox != undefined ? this.props.widget.eventViewer.showTimeBox : true}
                  areaType={this.props.areaType}
                  textLines={this.props.widget.eventViewer.textLines}
                  headline={this.props.widget.eventViewer.showHeadline && this.props.widget.title}
                  backgroundImage={this.props.widget.eventViewer.backgroundImage}
                  language={this.props.language}
                />
              );
              break;
            default:
              content = (
                <Rotator
                  enableScroll={this.props.widget.eventViewer.enableScroll}
                  widgetType="eventViewer"
                  newsItems={newsItems}
                  widgetId={this.props.widget?.id}
                  headlineFont={this.props.headlineFont}
                  contentFont={this.props.contentFont}
                  linkPortalURL={Environment.getEnvironmentForLinkPortal(this.props.environment)}
                  width={areaWidth}
                  height={areaHeight}
                  playerHeight={this.props.playerHeight}
                  playerWidth={this.props.playerWidth}
                  environment={this.props.environment}
                  tenantId={this.props.tenantId}
                  profileId={this.props.profileId}
                  transition={this.props.widget.eventViewer.transition}
                  duration={this.props.widget.eventViewer.duration}
                  boxStyle={this.props.widget.eventViewer.boxStyle}
                  editPreviewMode={this.props.editPreviewMode}
                  colorThemes={this.props.previewColorTheme ? [this.props.previewColorTheme] : this.props.widget.eventViewer.colorThemes}
                  areaType={this.props.areaType}
                  showTitle={this.props.widget.eventViewer.showTitle != undefined ? this.props.widget.eventViewer.showTitle : true}
                  showText={this.props.widget.eventViewer.showText != undefined ? this.props.widget.eventViewer.showText : true}
                  showAuthor={this.props.widget.eventViewer.showAuthor != undefined ? this.props.widget.eventViewer.showAuthor : true}
                  showImage={true}
                  showTime={this.props.widget.eventViewer.showTime != undefined ? this.props.widget.eventViewer.showTime : true}
                  showProgressBar={this.props.widget?.showProgressBar != undefined ? this.props.widget?.showProgressBar : true}
                  showLocation={this.props.widget.eventViewer.showLocation != undefined ? this.props.widget.eventViewer.showLocation : true}
                  showTimeBox={this.props.widget.eventViewer.showTimeBox != undefined ? this.props.widget.eventViewer.showTimeBox : true}
                  noContentImage={this.props.widget.eventViewer.noContentImageUrl}
                  language={this.props.language}
                  widgetTitleIsShowing={this.props.widget?.showTitle}
                  headerTitle={this.props.widget?.title}
                  willRestart={() => this.props.widgetContentWillRestart()}
                />
              );
              break;
          }
          break;
        case "sharepointNewsViewer":
          if (!this.props.widget.sharepointNewsViewer) break;
          this.props.widget.sharepointNewsViewer.news?.forEach((sharepointNewsItem: IPlaySharepointNewsItem) => {
            const newsItem: IPlayNewsItem = {
              title: sharepointNewsItem.title,
              content: sharepointNewsItem.description,
              date: sharepointNewsItem.modified,
              image: sharepointNewsItem.featuredImage ?? sharepointNewsItem.bannerImageUrl?.url,
              contentPosition: this.props.widget.sharepointNewsViewer.contentPosition,
              keepOriginalImage: this.props.widget.sharepointNewsViewer.keepOriginalImage
            };
            newsItems.push(newsItem);
          });
          content =
            <Rotator
              enableScroll={this.props.widget.sharepointNewsViewer.enableScroll}
              widgetType="sharepointNewsViewer"
              newsItems={newsItems}
              widgetId={this.props.widget?.id}
              linkPortalURL={Environment.getEnvironmentForLinkPortal(this.props.environment)}
              width={areaWidth}
              height={areaHeight}
              playerHeight={this.props.playerHeight}
              playerWidth={this.props.playerWidth}
              environment={this.props.environment}
              tenantId={this.props.tenantId}
              profileId={this.props.profileId}
              transition={this.props.widget.sharepointNewsViewer.transition}
              duration={this.props.widget.sharepointNewsViewer.duration}
              boxStyle={this.props.widget.sharepointNewsViewer.boxStyle}
              editPreviewMode={this.props.editPreviewMode}
              headlineFont={this.props.headlineFont}
              contentFont={this.props.contentFont}
              colorThemes={this.props.previewColorTheme ? [this.props.previewColorTheme] : this.props.widget.sharepointNewsViewer.colorThemes}
              areaType={this.props.areaType}
              showTitle={this.props.widget.sharepointNewsViewer.showTitle != undefined ? this.props.widget.sharepointNewsViewer.showTitle : true}
              showText={this.props.widget.sharepointNewsViewer.showText != undefined ? this.props.widget.sharepointNewsViewer.showText : true}
              showDate={this.props.widget.sharepointNewsViewer.showTime != undefined ? this.props.widget.sharepointNewsViewer.showTime : true}
              showImage={this.props.widget.sharepointNewsViewer.showImage != undefined ? this.props.widget.sharepointNewsViewer.showImage : true}
              showProgressBar={this.props.widget?.showProgressBar != undefined ? this.props.widget?.showProgressBar : true}
              noContentImage={this.props.widget.sharepointNewsViewer.noContentImageUrl}
              language={this.props.language}
              widgetTitleIsShowing={this.props.widget?.showTitle}
              headerTitle={this.props.widget?.title}
              willRestart={() => this.props.widgetContentWillRestart()}
            />;
          break;
        case "workplaceFeedViewer":
          const converter = new showdown.Converter();
          if (!this.props.widget.workplaceFeedViewer) break;
          this.props.widget.workplaceFeedViewer.feed?.forEach((workplaceFeedItem: IPlayWorkplaceFeedItem, index) => {
            if (index < this.props.widget.workplaceFeedViewer.amountToShow) {
              const content = workplaceFeedItem.message?.split("\n\n");
              let title = content?.length > 0 ? content[0] : undefined;
              title = title?.replace("# ", "")
              title = title?.replace(/\*\*/g, '');
              let description = workplaceFeedItem.message?.replace(content[0], "");
              if (description) {
                description = converter.makeHtml(description);
                description = description.replace(/\*\*/g, '');
              }
              const newsItem: IPlayNewsItem = {
                title: title,
                content: description,
                date: workplaceFeedItem.created_time,
                image: workplaceFeedItem.video === null ? workplaceFeedItem.picture : undefined,
                video: workplaceFeedItem.video,
                author: workplaceFeedItem.from?.name,
                contentPosition: this.props.widget.workplaceFeedViewer.contentPosition ?? "left",
                keepOriginalImage: this.props.widget.workplaceFeedViewer.keepOriginalImage,
                link: workplaceFeedItem.permalink_url,
                tag: workplaceFeedItem.groupName,
                linkText: this.props.widget.workplaceFeedViewer?.linkText,
              };
              newsItems.push(newsItem);
            }
          });
          content =
            <Rotator
              enableScroll={this.props.widget.workplaceFeedViewer.enableScroll}
              widgetType="workplaceFeedViewer"
              newsItems={newsItems}
              widgetId={this.props.widget?.id}
              headlineFont={this.props.headlineFont}
              contentFont={this.props.contentFont}
              linkPortalURL={Environment.getEnvironmentForLinkPortal(this.props.environment)}
              width={areaWidth}
              height={areaHeight}
              playerHeight={this.props.playerHeight}
              playerWidth={this.props.playerWidth}
              environment={this.props.environment}
              tenantId={this.props.tenantId}
              profileId={this.props.profileId}
              transition={this.props.widget.workplaceFeedViewer.transition}
              duration={this.props.widget.workplaceFeedViewer.duration}
              boxStyle={this.props.widget.workplaceFeedViewer.boxStyle}
              editPreviewMode={this.props.editPreviewMode}
              colorThemes={this.props.previewColorTheme ? [this.props.previewColorTheme] : this.props.widget.workplaceFeedViewer.colorThemes}
              areaType={this.props.areaType}
              showVideo
              showTag
              showQRLink={this.props.widget.workplaceFeedViewer.showQRLink}
              showAuthor={this.props.widget.workplaceFeedViewer.showAuthor != undefined ? this.props.widget.workplaceFeedViewer.showAuthor : true}
              showTitle={this.props.widget.workplaceFeedViewer.showTitle != undefined ? this.props.widget.workplaceFeedViewer.showTitle : true}
              showText={this.props.widget.workplaceFeedViewer.showText != undefined ? this.props.widget.workplaceFeedViewer.showText : true}
              showDate={this.props.widget.workplaceFeedViewer.showTime != undefined ? this.props.widget.workplaceFeedViewer.showTime : true}
              showImage={this.props.widget.workplaceFeedViewer.showImage != undefined ? this.props.widget.workplaceFeedViewer.showImage : true}
              showProgressBar={this.props.widget?.workplaceFeedViewer != undefined ? this.props.widget?.showProgressBar : true}
              noContentImage={this.props.widget.workplaceFeedViewer.noContentImageUrl}
              language={this.props.language}
              widgetTitleIsShowing={this.props.widget?.showTitle}
              headerTitle={this.props.widget?.headerTitle}
              headerSubtitle={this.props.widget?.headerSubtitle}
              headerImage={this.props.widget?.headerImage}
              willRestart={() => {
                this.props.widgetContentWillRestart();
              }}
            />;
          break;
        case "facebookPageViewer":
          const converterFacebook = new showdown.Converter();
          if (!this.props.widget.facebookPageViewer) break;
          this.props.widget.facebookPageViewer.feed?.forEach((facebookFeedItem: IFacebookFeedItem, index) => {
            if (index < this.props.widget.facebookPageViewer.amountToShow) {
              const content = facebookFeedItem.message?.split("\n\n");
              let title = content?.length > 0 ? content[0] : undefined;
              title = title?.replace("# ", "")
              title = title?.replace(/\*\*/g, '');
              let description = facebookFeedItem.message?.replace(content[0], "");
              if (description) {
                description = converterFacebook.makeHtml(description);
                description = description.replace(/\*\*/g, '');
              }

              const newsItem: IPlayNewsItem = {
                title: title,
                content: description,
                date: facebookFeedItem.created_time,
                image: !facebookFeedItem.video ? facebookFeedItem.picture : undefined,
                video: facebookFeedItem.video,
                author: facebookFeedItem.from?.name,
                contentPosition: this.props.widget.facebookPageViewer.contentPosition ?? "left",
                keepOriginalImage: this.props.widget.facebookPageViewer.keepOriginalImage,
                link: facebookFeedItem.permalink_url,
                // tag: facebookFeedItem.groupName,
                linkText: this.props.widget.facebookPageViewer?.linkText,
              };
              newsItems.push(newsItem);
            }
          });
          content =
            <Rotator
              enableScroll={this.props.widget.facebookPageViewer.enableScroll}
              widgetType="facebookPageViewer"
              newsItems={newsItems}
              widgetId={this.props.widget?.id}
              linkPortalURL={Environment.getEnvironmentForLinkPortal(this.props.environment)}
              width={areaWidth}
              height={areaHeight}
              headlineFont={this.props.headlineFont}
              contentFont={this.props.contentFont}
              playerHeight={this.props.playerHeight}
              playerWidth={this.props.playerWidth}
              environment={this.props.environment}
              tenantId={this.props.tenantId}
              profileId={this.props.profileId}
              transition={this.props.widget.facebookPageViewer.transition}
              duration={this.props.widget.facebookPageViewer.duration}
              boxStyle={this.props.widget.facebookPageViewer.boxStyle}
              editPreviewMode={this.props.editPreviewMode}
              colorThemes={this.props.previewColorTheme ? [this.props.previewColorTheme] : this.props.widget.facebookPageViewer.colorThemes}
              areaType={this.props.areaType}
              showVideo
              showTag
              showQRLink={this.props.widget.facebookPageViewer.showQRLink}
              showAuthor={this.props.widget.facebookPageViewer.showAuthor != undefined ? this.props.widget.facebookPageViewer.showAuthor : true}
              showTitle={this.props.widget.facebookPageViewer.showTitle != undefined ? this.props.widget.facebookPageViewer.showTitle : true}
              showText={this.props.widget.facebookPageViewer.showText != undefined ? this.props.widget.facebookPageViewer.showText : true}
              showDate={this.props.widget.facebookPageViewer.showTime != undefined ? this.props.widget.facebookPageViewer.showTime : true}
              showImage={this.props.widget.facebookPageViewer.showImage != undefined ? this.props.widget.facebookPageViewer.showImage : true}
              showProgressBar={this.props.widget?.facebookPageViewer != undefined ? this.props.widget?.showProgressBar : true}
              noContentImage={this.props.widget.facebookPageViewer.noContentImageUrl}
              language={this.props.language}
              widgetTitleIsShowing={this.props.widget?.showTitle}
              headerTitle={this.props.widget?.headerTitle}
              headerSubtitle={this.props.widget?.headerSubtitle}
              headerImage={this.props.widget?.headerImage}
              willRestart={() => {
                this.props.widgetContentWillRestart();
              }}
            />;
          break;
        case "poll":
          if (!this.props.widget.poll) break;
          content = <Poll
            widgetId={this.props.widget.id}
            environment={this.props.environment}
            tenantId={this.props.tenantId}
            profileId={this.props.profileId}
            title={this.props.widget.poll.title}
            replyOptions={this.props.widget.poll.replyOptions}
            sizeUnit={sizeUnit}
            isActive={true}
            width={areaWidth}
            height={areaHeight}
            duration={this.props.widget.duration && this.props.widget.duration !== 0 ? this.props.widget.duration : 20}
            willRestart={() => this.props.widgetContentWillRestart()}
          />;
          break;
        case "weather":
          if (!this.props.widget.weather) break;
          content = <Weather
            widgetId={`${this.props.widget?.id}_${this.props.index}`}
            forecast={this.props.forecast}
            width={areaWidth}
            height={areaHeight}
            language={this.props.language}
            areaType={this.props.areaType}
            colorTheme={this.props.widget.weather.colorTheme}
            isHardcodedWeatherData={this.props.isHardcodedWeatherData}
            displayMode={this.props.widget.weather?.weatherType ?? "week"}
            headlineFont={this.props.headlineFont}
            contentFont={this.props.contentFont}
          />;
          break;
        case "relesys":
          if (!this.props.widget.relesys) break;
          this.props.widget.relesys.relesysContent?.forEach((content) => {
            const contentLocale = content.locales?.length > 0 && content.locales[0];
            const newsItem: IPlayNewsItem = {
              id: content.id,
              title: contentLocale.title,
              content: contentLocale.bodyText,
              date: content.creationDateTime,
              image: content.medias?.length > 0 && content.medias[0].base64Image,
              gallery: content.medias?.length > 0 && content.medias[0].galleryImages,
              contentPosition: this.props.widget?.relesys?.contentPosition,
              contentVerticalPosition: this.props.widget?.relesys?.contentVerticalPosition,
              keepOriginalImage: this.props.widget?.relesys?.keepOriginalImage,
              boxStyleRoundCorner: this.props.widget?.relesys?.boxStyleRoundCorner,
              boxStyleWidth: this.props.widget?.relesys?.contentWidth && this.props.widget.relesys.contentWidth > 30 ? this.props.widget.relesys.contentWidth : 30
            };
            newsItems.push(newsItem);
          });
          content = <Rotator
            enableScroll={this.props.widget.relesys.enableScroll}
            widgetType="relesys"
            newsItems={newsItems}
            widgetId={this.props.widget?.id}
            width={areaWidth}
            height={areaHeight}
            headlineFont={this.props.headlineFont}
            contentFont={this.props.contentFont}
            playerHeight={this.props.playerHeight}
            playerWidth={this.props.playerWidth}
            environment={this.props.environment}
            tenantId={this.props.tenantId}
            profileId={this.props.profileId}
            duration={this.props.widget.relesys.duration}
            transition={"slide"}
            editPreviewMode={this.props.editPreviewMode}
            colorThemes={this.props.previewColorTheme ? [this.props.previewColorTheme] : this.props.widget.relesys.colorThemes}
            areaType={this.props.areaType}
            showTitle={this.props.widget.relesys.showTitle}
            showText={this.props.widget.relesys.showText}
            showDate={this.props.widget.relesys.showTime}
            showImage={true}
            imagesIsBase64
            showProgressBar={this.props.widget.relesys.showProgressBar}
            language={this.props.language}
            widgetTitleIsShowing={this.props.widget?.showTitle}
            headerTitle={this.props.widget?.headerTitle}
            headerSubtitle={this.props.widget?.headerSubtitle}
            headerImage={this.props.widget?.headerImage}
            boxStyle={this.props.widget?.relesys?.boxStyle}
            showGallery={this.props.widget?.relesys?.showGallery}
            willRestart={() => {
              this.props.widgetContentWillRestart();
            }}
          />;
          break;
        case "timeEdit":
          if (!this.props.widget.timeEdit) break;
          content = <TimeEdit
            widget={this.props.widget}
            areaWidth={areaWidth}
            areaHeight={areaHeight}
            headlineFont={this.props.headlineFont}
            contentFont={this.props.contentFont}
            playerHeight={this.props.playerHeight}
            playerWidth={this.props.playerWidth}
            environment={this.props.environment}
            tenantId={this.props.tenantId}
            profileId={this.props.profileId}
            editPreviewMode={this.props.editPreviewMode}
            previewColorTheme={this.props.previewColorTheme}
            areaType={this.props.areaType}
            language={this.props.language}
            widgetContentWillRestart={() => {
              this.props.widgetContentWillRestart();
            }}
          />
          break;
        default:
          content = <></>;
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
    return <div
      key={`widget_${this.props.widget?.id}_${this.props.index}`}
      id={`widget_${this.props.widget?.id}_${this.props.index}`}
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        zIndex: 1,
        position: "relative"
      }}
    >
      {content}

      {(this.props.widget.gallery?.showTitle || this.props.widget.video?.showTitle || this.props.widget.showTitle) && this.props.widget.title &&
        <div
          className={styles.IA_title}
          style={{
            padding: `${sizeUnit}px ${sizeUnit * 3}px ${sizeUnit}px ${sizeUnit * 2}px`,
            left: 0,
            bottom: this.props.widget?.gallery?.showThumbnails && this.props.widget?.gallery?.images?.length > 1 ? areaHeight / 10 : 0,
            fontSize: sizeUnit * 1.5,
            borderRadius: `0px ${sizeUnit * 1.5}px 0px 0px`
          }}
        >
          {this.props.widget.title?.toUpperCase()}
        </div>
      }
    </div>;
  }
}
