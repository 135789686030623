import * as React from 'react';
import { IPlayWeather } from '../../../../../interfaces/IPlay';
import * as styles from '../Weather.css';
import { IColorTheme } from '../../../../../interfaces/IColorTheme';
import { ColorTranslator } from 'colortranslator';
import { DaySection } from './DaySection';
import { TemperatureGraph } from './TemperatureGraph';

export interface IProps {
  widgetId: string;
  forecast: IPlayWeather[];
  width: number;
  height: number;
  sizeUnit: number;
  colorTheme: IColorTheme;
  isSmall?: boolean;
  getDaySections: () => DaySection[];
}

export interface IState {
  daySections: DaySection[];
  highestPrecipation: number;
}

export class DayHorizontalWeather extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      daySections: undefined,
      highestPrecipation: undefined
    };
  }

  componentDidMount(): void {
    let highestPrecipation = 0;
    // We split the day into sections (morning, noon, evening and night)
    const daySections = this.props.getDaySections();
    daySections.forEach(day => {
      if (day.weather.precipitationAmount > highestPrecipation) {
        highestPrecipation = day.weather.precipitationAmount;
      }
    })
    this.setState({
      daySections,
      highestPrecipation
    });
  }

  public render(): JSX.Element {
    const sizeMultiplier = this.props.isSmall ? 1 : 2;
    const colortranslator = new ColorTranslator(this.props.colorTheme.textColor.toLocaleLowerCase());
    const borderColor = `rgba(${colortranslator.R}, ${colortranslator.G}, ${colortranslator.B}, .1)`;
    const sectionTemperatures = [];
    if (this.state.daySections?.length >= 5) {
      this.state.daySections.slice(0, 5).map((section) => {
        sectionTemperatures.push(section.weather.airTemperature);
      });
    }
    const columnWidth = (this.props.width - (this.props.sizeUnit * 10)) / 4;
    return (
      <div
        // className={styles.IA_weatherWidgetContainer}
        style={{
          backgroundColor: this.props.colorTheme.backgroundColor,
          display: "flex",
          flexDirection: "row",
          height: "100%",
          width: "100%"
        }}
      >
        {this.state.daySections?.length >= 4 && this.state.daySections.slice(0, 4).map((section, i) => {
          const highestPrecipation = Math.max(this.state.highestPrecipation, 10);
          const daySectionHours = [
            { hour: section.startTime, date: section.startDate },
            { hour: section.startTime + 1, date: section.startDate },
            { hour: section.startTime + 2, date: section.startDate },
            { hour: section.startTime + 3, date: section.startDate },
            { hour: section.startTime + 4, date: section.startDate },
            { hour: section.startTime + 5, date: section.startDate }
          ];
          return <div
            style={{
              marginTop: this.props.sizeUnit * 5,
              marginLeft: i === 0 ? this.props.sizeUnit * 5 : undefined,
              marginRight: i === 3 ? this.props.sizeUnit * 5 : undefined,
              height: `calc(100% - ${this.props.sizeUnit * 5}px)`,
              display: "flex",
              flexDirection: "column",
              width: columnWidth
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "75%",
                borderLeft: i !== 0 ? `1px solid ${borderColor}` : undefined
              }}
            >
              <div
                style={{
                  fontSize: this.props.sizeUnit * 3,
                  marginBottom: this.props.sizeUnit * 4,
                  marginTop: this.props.sizeUnit * 4,
                  color: this.props.colorTheme.textColor
                }}
              >
                {section?.title?.toUpperCase()}
              </div>
              <div
                className={styles.IA_iconAndPrecipationContainer}
              >
                <img
                  src={section.weather.iconUrl}
                  className={styles.IA_weatherIcon}
                />
                <div
                  className={styles.IA_precipation}
                  style={{
                    fontSize: this.props.sizeUnit * sizeMultiplier,
                    minHeight: this.props.sizeUnit * sizeMultiplier * 1.5,
                    marginTop: this.props.sizeUnit
                  }}
                >
                  {section.weather.precipitationAmount !== 0 ? `${Math.ceil(section.weather.precipitationAmount)} mm` : " "}
                </div>
              </div>
              <div
                style={{
                  marginTop: this.props.sizeUnit,
                  fontSize: this.props.sizeUnit * 5.5,
                  color: this.props.colorTheme.textColor,
                }}
              >
                {`${section.weather.airTemperature.toFixed(0)}°`}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "17%",
              }}
            >
              {daySectionHours.map((section, j) => {
                let precipitation = this.props.forecast.find(weather => {
                  const date = new Date(weather.time);
                  return date.getHours() === section.hour && date.getDate() === section.date;
                })?.precipitationAmount ?? 0;
                const sectionHour = section?.hour;
                const sectionHourPlusOne = section?.hour + 1;
                return (
                  <div
                    style={{
                      flex: 1,
                      borderColor: borderColor,
                      borderRightWidth: i === 3 && j === 5 ? 1 : 0,
                      borderLeftWidth: 1,
                      borderTopWidth: 0,
                      borderBottomWidth: 0,
                      borderStyle: "solid",
                      textAlign: "center",
                      padding: `0 ${this.props.sizeUnit / 2}px`
                    }}
                  >
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "end"
                      }}
                    >
                      <div
                        style={{
                          height: `${precipitation / highestPrecipation * 100}%`,
                          backgroundColor: "#0062bf"
                        }}
                      />
                    </div>
                    <div
                      style={{
                        fontSize: this.props.sizeUnit * 2,
                        marginTop: this.props.sizeUnit,
                        color: this.props.colorTheme.textColor,
                        display: j % 2 === 0 ? "block" : "none",
                        position: "relative",
                        left: -(columnWidth / 6) / 2
                      }}
                    >
                      {sectionHour === 0 ? 24 : (sectionHour?.toString()?.length === 1 ? `0${sectionHour?.toString()}` : sectionHour?.toString())}
                    </div>
                    {daySectionHours.length - 1 === j && i === 3 &&
                      <div
                        style={{
                          fontSize: this.props.sizeUnit * 2,
                          marginTop: this.props.sizeUnit,
                          color: this.props.colorTheme.textColor,
                          display: j % 2 !== 0 ? "block" : "none",
                          position: "relative",
                          right: -(columnWidth / 6) / 2
                        }}
                      >
                        {sectionHourPlusOne === 0 ? 24 : (sectionHourPlusOne?.toString()?.length === 1 ? `0${sectionHourPlusOne?.toString()}` : sectionHourPlusOne?.toString())}
                      </div>
                    }
                  </div>
                )
              })}
            </div>
          </div>
        })}
        <TemperatureGraph
          widgetId={this.props.widgetId}
          temperatures={sectionTemperatures}
          sizeUnit={this.props.sizeUnit}
          isSmall={this.props.isSmall}
          marginMultiplier={5}
          width={this.props.width}
          color={this.props.colorTheme.textColor}
        />
      </div>
    );
  }
}