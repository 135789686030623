import * as React from 'react';
import * as styles from "./Likes.css";
import { IUser } from '../../interfaces/IUser';
import { ILike } from '../../interfaces/ILike';
import SocialService from '../../services/SocialService';
import { IAIcon } from '../icon/Icon';
import { ENTER_KEY, SPACEBAR_KEY } from '../../global/Constants';

export interface ILikesProps {
  color: string;
  itemId: string;
  user: IUser;
  tenant: string;
  component: string;
  instance: string;
  token: string;
  source: string;
  environment?: "Development" | "Test" | "Production";
  likes?: ILike[];
  likesUpdated?: (likes: ILike[]) => void;
  style?: React.CSSProperties;
  dataAutomationId?: string;
  displayVertical?: boolean;
  showDimmed?: boolean;
}

export interface ILikesState {
  showSpinner: boolean;
  likes: ILike[];
}
export class IALikes extends React.Component<ILikesProps, ILikesState> {

  constructor(props: ILikesProps) {
    super(props);
    this.state = {
      showSpinner: false,
      likes: props.likes != undefined ? props.likes : []
    };
  }

  public componentDidMount(): void {
    if (this.props.likes == undefined) {
      this.setState({ showSpinner: true }, () => this.forceUpdate());
      SocialService.getLikes(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.itemId).then((likes: ILike[]) => {
        this.setState({ showSpinner: false, likes }, () => this.forceUpdate());
        this.props.likesUpdated(likes);
      });
    }
  }

  public componentWillReceiveProps(props: ILikesProps): void {
    if (props.itemId != this.props.itemId || props.likes != this.props.likes) {
      if (props.likes == undefined) {
        this.setState({ showSpinner: true }, () => this.forceUpdate());
        SocialService.getLikes(props.environment, props.tenant, props.component, props.instance, props.token, props.itemId).then((likes: ILike[]) => {
          this.setState({ showSpinner: false, likes }, () => this.forceUpdate());
          props.likesUpdated(likes);
        });
      } else {
        this.setState({ likes: props.likes }, () => this.forceUpdate());
      }
    }
  }

  private onClick(e: React.SyntheticEvent<HTMLDivElement>, hasLiked: boolean) {
    if (this.props.showDimmed) {
      return;
    }
    e.stopPropagation();
    if (this.state.likes != undefined && this.props.user != undefined && !this.state.showSpinner) {
      this.setState({ showSpinner: true });
      if (hasLiked) {
        let likeId: string;
        this.state.likes.forEach((like: ILike) => {
          if (this.props.user && (like.user.userPrincipalName == this.props.user.userPrincipalName)) {
            likeId = like.id;
          }
        });
        SocialService.unlike(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.itemId, likeId).then((likes: ILike[]) => {
          this.setState({ likes, showSpinner: false }, () => {
            if (this.props.likesUpdated) {
              this.props.likesUpdated(likes);
            }
          });
        });
      } else {
        SocialService.like(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.user, this.props.itemId, this.props.source).then((likes: ILike[]) => {
          this.setState({ likes, showSpinner: false }, () => {
            if (this.props.likesUpdated) {
              this.props.likesUpdated(likes);
            }
          });
        });
      }
    }
  }

  public render(): JSX.Element {
    let hasLiked = false;
    if (this.state.likes && this.state.likes.length > 0) {
      this.state.likes.forEach((like: ILike) => {
        if (this.props.user && (like.user.userPrincipalName.toLowerCase() == this.props.user.userPrincipalName.toLowerCase())) {
          hasLiked = true;
          return;
        }
      });
    }
    return (
      <div
        className={styles.likesAndComments}
        style={{
          height: this.props.displayVertical ? 37 : 20,
          width: this.props.displayVertical ? 40 : undefined,
          marginTop: this.props.displayVertical ? 0 : 8,
          opacity: this.props.showDimmed ? 0.5 : undefined,
          ...this.props.style
        }}
      >
        <div
          className={[styles.likes, "IA_OnFocus"].join(" ")}
          tabIndex={0}
          style={{
            opacity: this.props.user != undefined ? 1 : 0.5,
            width: this.props.displayVertical ? 40 : undefined,
            height: this.props.displayVertical ? 37 : 20,
            marginRight: this.props.displayVertical ? 0 : 5
          }}
          data-automation-id={this.props.dataAutomationId}
          onClick={(event) => this.onClick(event, hasLiked)}
          onKeyDown={(event) => {
            if (event.key === ENTER_KEY || event.key === SPACEBAR_KEY) {
              this.onClick(event, hasLiked);
            }
          }}
        >
          <div
            style={{
              float: "left",
              marginLeft: this.props.displayVertical ? 11 : 0
            }}
          >
            {hasLiked ?
              <IAIcon
                url={"https://intraactivestorage.blob.core.windows.net/cdn/icons/Thumb%20Like/SVG/ic_fluent_thumb_like_48_filled.svg"}
                color={this.props.color}
                size={18}
                style={{
                  opacity: this.state.showSpinner ? 0.5 : 1
                }}
              />
              :
              <IAIcon
                url={"https://intraactivestorage.blob.core.windows.net/cdn/icons/Thumb%20Like/SVG/ic_fluent_thumb_like_48_regular.svg"}
                color={this.props.color}
                size={18}
                style={{
                  opacity: this.state.showSpinner ? 0.5 : 1
                }}
              />
            }
          </div>
          <div
            className={this.props.displayVertical ? styles.likesTextVertical : styles.likesText}
            style={{
              color: this.props.color,
              opacity: this.state.showSpinner ? 0.5 : 1,
            }}
          >
            {this.state.likes != undefined ? this.state.likes.length : "-"}
          </div>
        </div>
      </div >
    );
  }
}