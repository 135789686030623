import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IAToggle } from '../../../../../toggle/Toggle';
import { IATextField } from '../../../../../textField/TextField';
import { IAGroup } from '../../../../../group/Group';
import { IANumberField } from '../../../../../numberField/NumberField';
import { Helper } from '../../../../../../Helper';
import { IAColorTheme } from '../../../../../colorTheme/ColorTheme';
import { MediaFileSelector } from '../../../mediaFiles/mediaFileSelector/MediaFileSelector';
import { IAColorPicker } from '../../../../../colorPicker/ColorPicker';
import { IAOptionSelector } from '../../../../../optionSelector/OptionSelector';

export interface IProps {
  store?: Store;
  forceUpdate: () => void;
}

@inject('store')
@observer
export class CountdownWidgetSettings extends React.Component<IProps> {

  private readonly localizationService = new LocalizationService()

  render(): React.ReactNode {
    return (
      <>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_WidgetSettings?.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <IATextField
            label={this.localizationService.strings.PlayAdmin_Title?.toUpperCase()}
            required
            placeholder={this.localizationService.strings.PlayAdmin_Title}
            text={this.props.store.widgetToEdit?.title}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(title: string) => {
              this.props.store.widgetToEdit.title = title;
              this.props.forceUpdate();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20,
              fontSize: 12
            }}
            darkMode={this.props.store.darkMode}
            label={this.localizationService.strings.PlayAdmin_ShowWidgetTitle?.toUpperCase()}
            checked={this.props.store.widgetToEdit.showTitle}
            color={this.props.store.highlightColor}
            onChange={() => {
              this.props.store.widgetToEdit.showTitle = this.props.store.widgetToEdit.showTitle != undefined ? !this.props.store.widgetToEdit.showTitle : true;
              this.props.forceUpdate();
            }}
          />
          <IANumberField
            label={this.localizationService.strings.PlayAdmin_Duration?.toUpperCase()}
            number={this.props.store.widgetToEdit?.duration && this.props.store.widgetToEdit?.duration !== 0 ? this.props.store.widgetToEdit?.duration : 30}
            onChange={(duration: number) => {
              this.props.store.widgetToEdit.duration = duration;
              this.props.forceUpdate();
            }}
            min={0}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            styles={{
              width: "100%",
              clear: "both"
            }}
            labelStyle={{
              fontSize: "12px"
            }}
          />
          <IATextField
            label={this.localizationService.strings.PlayAdmin_CountdownTitle?.toUpperCase()}
            placeholder={this.localizationService.strings.PlayAdmin_Title}
            text={this.props.store.widgetToEdit?.countdown?.title}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(title: string) => {
              this.props.store.widgetToEdit.countdown.title = title;
              this.props.forceUpdate();
            }}
          />
          <label
            style={{
              color: this.props.store.darkMode ? Helper.darkModeLabelColor : "#333333",
              marginTop: 20
            }}
          >
            {this.localizationService.strings.PlayAdmin_ColorThemes?.toUpperCase()}
          </label>
          <IAColorTheme
            backgroundColor={this.props.store.widgetToEdit?.countdown?.colorTheme?.backgroundColor}
            textColor={this.props.store.widgetToEdit?.countdown?.colorTheme?.textColor}
            highlightColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            swatches={this.props.store.profile?.swatches}
            inTeams
            remove={undefined}
            allowAddingToSwatches={false}
            setBackgroundColor={(backgroundColor) => {
              this.props.store.widgetToEdit.countdown.colorTheme.backgroundColor = backgroundColor;
              this.props.forceUpdate();
            }}
            setTextColor={(textColor) => {
              this.props.store.widgetToEdit.countdown.colorTheme.textColor = textColor;
              this.props.forceUpdate();
            }}
          />
           <IAOptionSelector
            label={this.localizationService.strings.PlayAdmin_CandleColor?.toUpperCase()}
            options={[
              {
                image: "https://intraactivestorage.blob.core.windows.net/cdn/play/candle-white.png",
                key: "white",
              },{
                image: "https://intraactivestorage.blob.core.windows.net/cdn/play/candle-red.png",
                key: "red",
              },{
                image: "https://intraactivestorage.blob.core.windows.net/cdn/play/candle-green.png",
                key: "green",
              },{
                image: "https://intraactivestorage.blob.core.windows.net/cdn/play/candle-blue.png",
                key: "blue",
              },
            ]}
            selected={this.props.store.widgetToEdit?.countdown?.christmasSettings?.candleColor}
            highlightColor={this.props.store.highlightColor}
            compact
            iconSize={30}
            darkMode={this.props.store.darkMode}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(candleColor: any) => {
              this.props.store.widgetToEdit.countdown.christmasSettings.candleColor = candleColor;
              this.props.forceUpdate();
              this.props.forceUpdate();
            }}
            style={{ clear: "none", marginTop: 20 }}
          />
          <IAColorPicker
            label={this.localizationService.strings.PlayAdmin_CandleNumberColor?.toUpperCase()}
            color={this.props.store.widgetToEdit?.countdown?.christmasSettings?.candleNumberColor}
            inTeams={this.props.store.isTeams}
            swatches={this.props.store.profile?.swatches}
            allowAddingToSwatches
            onChange={(candleNumberColor: string) => {
              this.props.store.widgetToEdit.countdown.christmasSettings.candleNumberColor = candleNumberColor;
              this.props.forceUpdate();
            }}
            showClearButton={false}
            darkMode={this.props.store.darkMode}
            addSwatch={(color: string) => this.props.store.addSwatch(color)}
          />
          <MediaFileSelector
            label={this.localizationService.strings.PlayAdmin_BackgroundImage?.toUpperCase()}
            url={this.props.store.widgetToEdit?.countdown?.imageUrl}
            type={"image"}
            onChange={(imageUrl: string) => {
              this.props.store.widgetToEdit.countdown.imageUrl = imageUrl;
              this.props.forceUpdate();
              this.props.forceUpdate();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20,
              fontSize: 12
            }}
            darkMode={this.props.store.darkMode}
            label={this.localizationService.strings.PlayAdmin_LetUserLitCandle?.toUpperCase()}
            checked={this.props.store.widgetToEdit?.countdown?.christmasSettings?.showQRCode}
            color={this.props.store.highlightColor}
            onChange={() => {
              this.props.store.widgetToEdit.countdown.christmasSettings.showQRCode = this.props.store.widgetToEdit?.countdown?.christmasSettings?.showQRCode != undefined ? !this.props.store.widgetToEdit?.countdown?.christmasSettings?.showQRCode : false;
              this.props.forceUpdate();
            }}
          />
        </IAGroup>
      </>
    );
  }


}