import * as React from 'react';
import * as styles from './Popup.css';
import { IAIcon } from '../icon/Icon';
import { IFontSlots } from '../../interfaces/IFontSlots';

export interface IIAPopupProps {
  headline?: string;
  headlineColor?: string;
  headlineSize?: number;
  headlineWidth?: string;
  backgroundImage?: string;
  content: JSX.Element;
  closeButtonColor?: string;
  closeButtonBackground?: string;
  contentOverflow?: "visible" | "hidden" | "clip" | "scroll" | "auto";
  styles?: React.CSSProperties;
  zIndex?: number;
  fontSlots?: IFontSlots;
  close?: () => void;
}

export class IAPopup extends React.Component<IIAPopupProps, {}> {

  constructor(props: IIAPopupProps) {
    super(props);
  }

  public render(): JSX.Element {
    const style = this.props.styles ?? {};
    return (
      <div
        className={styles.IA_popupBackground}
        style={{
          zIndex: this.props.zIndex
        }}
        onClick={() => {
          if (this.props.close) {
            this.props.close();
          }
        }}
      >
        <div
          className={styles.IA_popupContentBox}
          onClick={(e) => e.stopPropagation()}
          style={{
            ...style,
            backgroundImage: this.props.backgroundImage ? `url(${this.props.backgroundImage})` : "none",
            overflowY: this.props.contentOverflow,
          }}
        >
          {this.props.close != undefined &&
            <IAIcon
              title={"Close"}
              size={24}
              color={this.props.closeButtonColor ? this.props.closeButtonColor : "#000000"}
              style={{
                float: "right",
                backgroundColor: this.props.closeButtonBackground,
                borderRadius: 24,
                width: 24,
                height: 24,
                lineHeight: "24px",
                padding: this.props.closeButtonBackground ? 2 : 0
              }}
              onClick={() => this.props.close()}
            />
          }
          {this.props.headline &&
            <h1 style={{
              color: this.props.headlineColor ? this.props.headlineColor : "#333333",
              fontSize: this.props.headlineSize ? this.props.headlineSize : 19,
              width: this.props.headlineWidth ? this.props.headlineWidth : "100%",
              fontFamily: this.props.fontSlots?.heading?.fontFamily
            }}>{this.props.headline}</h1>
          }
          {this.props.content}
        </div>
      </div>
    );
  }
}