import { IEnvironment } from "../interfaces/IEnvironment";

export class Environment {
  public static getApplicationInsightsKey(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "19412317-cf3b-4dea-a7e7-7961ebf47937";
      case "Test":
        return "c27d5d74-dc8e-4a3d-8a7f-3217aba8ef89";
      case "Development":
        return "a115fb3a-8a29-4418-89bc-8e774b0197cb";
    }
  }

  public static getEnvironmentForPeopleFinderAPI(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://intraactive-peoplefinder-functions.azurewebsites.net";
      case "Test":
        return "https://intraactive-peoplefinder-functions-test.azurewebsites.net";
      case "Development":
        return "https://intraactive-peoplefinder-functions-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentForMobileApp(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "http://mobile-spa.azurewebsites.net";
      case "Test":
        return "http://mobile-spa-test.azurewebsites.net";
      case "Development":
        return "http://mobile-spa-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentForMainAPI(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://intraactive-api.azurewebsites.net";
      case "Test":
        return "https://main.api-test.intraactive.net";
      case "Development":
        return "https://intraactive-api-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentForMessagesAPI(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://messages.api.intraactive.net";
      case "Test":
        return "https://messages.api-test.intraactive.net";
      case "Development":
        return "https://intraactive-messages-functions-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentForEventsAPI(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://intraactive-events-functions.azurewebsites.net/";
      case "Test":
        return "https://intraactive-events-functions-test.azurewebsites.net/";
      case "Development":
        return "https://intraactive-events-functions-dev.azurewebsites.net/";
    }
  }

  public static getEnvironmentForCategoriessAPI(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://categories.api.intraactive.net";
      case "Test":
        return "https://categories.api-test.intraactive.net";
      case "Development":
        return "https://intraactive-categories-functions-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentForThemeAPI(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://theme.api.intraactive.net";
      case "Test":
        return "https://theme.api-test.intraactive.net";
      case "Development":
        return "https://intraactive-theme-functions-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentForOrgAssetsAPI(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://orgassets.api.intraactive.net";
      case "Test":
        return "https://orgassets.api-test.intraactive.net";
      case "Development":
        return "https://intraactive-orgassets-functions-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentForImageAPI(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://intraactive-image-functions.azurewebsites.net";
      case "Test":
        return "https://intraactive-image-functions-test.azurewebsites.net";
      case "Development":
        return "https://intraactive-image-functions-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentForSocialAPI(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://social.api.intraactive.net";
      case "Test":
        return "https://social.api-test.intraactive.net";
      case "Development":
        return "https://intraactive-social-functions-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentForTranslationAPI(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://translate.api.intraactive.net";
      case "Test":
        return "https://translate.api-test.intraactive.net";
      case "Development":
        return "https://intraactive-translate-functions-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentForTranslationFunctionKey(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "FvMm7ay4I66yzJuZ1UuX/PG7i1avC5HzCv5tLTgbYNpGTnUPip/4og==";
      case "Test":
        return "";
      case "Development":
        return "";
    }
  }

  public static getEnvironmentForTeamsAPI(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://teams.api.intraactive.net";
      case "Test":
        return "https://teams.api-test.intraactive.net";
      case "Development":
        return "https://intraactive-teams-functions-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentForTargetingAPI(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://targeting.api.intraactive.net";
      case "Test":
        return "https://targeting.api-test.intraactive.net";
      case "Development":
        return "https://intraactive-targeting-functions-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentForSettingsAPI(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://settings.api.intraactive.net";
      case "Test":
        return "https://settings.api-test.intraactive.net";
      case "Development":
        return "https://intraactive-settings-functions-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentForContentCreatorAPI(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://contentcreator.api.intraactive.net";
      case "Test":
        return "https://contentcreator.api-test.intraactive.net";
      case "Development":
        return "https://intraactive-contentcreator-functions-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentForAnalyticsAPI(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://intraactive-analytics-functions.azurewebsites.net";
      case "Test":
        return "https://intraactive-analytics-functions-test.azurewebsites.net";
      case "Development":
        return "https://intraactive-analytics-functions-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentForPermissionsAPI(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://intraactive-permissions-functions.azurewebsites.net";
      case "Test":
        return "https://intraactive-permissions-functions-test.azurewebsites.net";
      case "Development":
        return "https://intraactive-permissions-functions-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentContentOrganizerAPI(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://intraactive-contentorganizer-functions.azurewebsites.net";
      case "Test":
        return "https://intraactive-contentorganizer-functions-test.azurewebsites.net";
      case "Development":
        return "https://intraactive-contentorganizer-functions-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentForAzureImages(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://intraactivestorage.blob.core.windows.net";
      case "Test":
        return "https://intraactivestoragetest.blob.core.windows.net";
      case "Development":
        return "https://intraactivestoragedev.blob.core.windows.net";
    }
  }

  public static getEnvironmentForBotApi(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://intraactive-bot-functions.azurewebsites.net";
      case "Test":
        return "https://intraactive-bot-functions-test.azurewebsites.net";
      case "Development":
        return "https://intraactive-bot-functions-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentForLinkPortal(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://link.intraactive.net";
      case "Test":
        return "https://link-test.intraactive.net";
      case "Development":
        return "https://purple-mushroom-0d37c6803.azurestaticapps.net";
    }
  }

  public static getEnvironmentForPlayApi(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://intraactive-play-functions.azurewebsites.net";
      case "Test":
        return "https://intraactive-play-functions-test.azurewebsites.net";
      case "Development":
        return "https://intraactive-play-functions-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentForPlayImageFiles(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://files.intraactiveplay.net";
      case "Test":
        return "https://files.intraactiveplay-test.net";
      case "Development":
        return "https://files.intraactiveplay-dev.net";
    }
  }

  public static getEnvironmentForFeedApi(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://intraactive-feed-functions.azurewebsites.net";
      case "Test":
        return "https://intraactive-feed-functions-test.azurewebsites.net";
      case "Development":
        return "https://intraactive-feed-functions-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentForNewBotApi(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://api.pling.intraactive.net";
      case "Test":
        return "https://api.pling-test.intraactive.net";
      case "Development":
        return "https://api.pling-dev.intraactive.net";
    }
  }

  public static getEnvironmentForPlayFrontend(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://app.intraactiveplay.net/";
      case "Test":
        return "https://test.intraactiveplay.net/";
      case "Development":
        return "https://dev.intraactiveplay.net/";
    }
  }

  public static getEnvironmentForMsalScope(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://intraactiveworkplace.onmicrosoft.com/25d85c69-d640-427c-af92-8671deeb5f1b/user_impersonation";
      case "Test": 
        // TODO: add scope for test
        break;
      case "Development":
        return "https://intraactivedev.onmicrosoft.com/e3da631d-7da1-4309-906c-5ce60ce9dafc/user_impersonation";
    }
  }

  public static getEnvironmentForMsalClientId(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "a52605ed-bd27-4e8a-9dae-528df434cdf3";
      case "Test": 
        // TODO: add clientId for test
        break;
      case "Development":
        return "a8919b19-ce99-44a8-9311-e393159020f7";
    }
  }

  public static getEnvironmentForAuthenticationApi(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://intraactive-authentication-functions.azurewebsites.net";
      case "Test":
        return "https://intraactive-authentication-functions-test.azurewebsites.net";
      case "Development":
        return "https://intraactive-authentication-functions-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentForNotificationApi(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://intraactive-notifications.azurewebsites.net";
      case "Test":
        return "https://intraactive-notifications-test.azurewebsites.net";
      case "Development":
        return "https://intraactive-notifications-dev.azurewebsites.net";
    }
  }

  public static getEnvironmentContentOrganizerAIAPI(environment: IEnvironment): string {
    switch (environment) {
      case "Production":
        return "https://intraactive-contentorganizerai-functions.azurewebsites.net";
      case "Test":
        return "https://intraactive-contentorganizerai-functions-test.azurewebsites.net";
      case "Development":
        return "https://intraactive-contentorganizerai-functions-dev.azurewebsites.net";
    }
  }
}
