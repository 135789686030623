import * as React from 'react';
import { Store } from '../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './AccountSettings.css';
import { Helper } from '../../../Helper';
import { License } from './license/License';
import { LocalizationService } from '../../../services/LocalizationService';
import { Users } from './users/Users';
import { Profiles } from './profiles/Profiles';
import { Integrations } from './integrations/Integrations';
import { IAMenu } from '../../menu/Menu';
import { IAPanel } from '../../panels/Panel';

export interface IProps {
  show: boolean;
  store?: Store;
}

export interface IState {
  selectedMenuItem?: string;
}

@inject('store')
@observer
export class AccountSettings extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.show && !this.props.show) {
      this.setState({ selectedMenuItem: "license" });
    }
  }

  public render(): JSX.Element {
    const menuItems = [];
    menuItems.push({
      key: "license",
      label: this.localizationService.strings.PlayAdmin_License,
      icon: "Shopping Cart"
    });
    menuItems.push({
      key: "users",
      label: this.localizationService.strings.PlayAdmin_Users,
      icon: "People"
    });
    menuItems.push({
      key: "profiles",
      label: this.localizationService.strings.PlayAdmin_Profiles,
      icon: "Home Add"
    });
    menuItems.push({
      key: "integrations",
      label: this.localizationService.strings.PlayAdmin_Integrations,
      icon: "Plug Disconnected"
    });

    return (
      <IAPanel
        open={this.props.show}
        darkMode={this.props.store.darkMode}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        width={this.props.store.windowWidth}
        dataAutomationIdPrefix="article-edit-panel"
        panelId={`IAAccountSettingsPanel`}
        isInTeams={this.props.store.isTeams}
        close={() => {
          this.props.store.isShowingAccountSettings = false
        }}
      >
        <div className={styles.IA_accountSettingsWrapper}>
          <IAMenu
            showAccountSettingsHeader
            items={menuItems}
            showMobileMenu={false}
            isMobile={this.props.store.isMobile}
            isTeams={this.props.store.isTeams}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            bottomItems={[{
              key: "close",
              label: this.localizationService.strings.PlayAdmin_BackToPlay,
              iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Chevron%20Left/SVG/ic_fluent_chevron_left_48_regular.svg"
            }]}
            selectedItem={this.state.selectedMenuItem}
            showCollapseButton={false}
            profile={this.props.store.profile}
            collapsed={this.props.store.menuCollapsed}
            onSelect={(selectedMenuItem: string) => {
              if (selectedMenuItem === "close") {
                this.props.store.isShowingAccountSettings = false;
              } else {
                this.setState({ selectedMenuItem });
              }
            }}
            onCollapseToggle={() => {
              this.props.store.menuCollapsed = !this.props.store.menuCollapsed;
              if (this.props.store.menuCollapsed) {
                Helper.setLocalStorage("intraactive-play-menu-collapsed", "collapsed");
              } else {
                localStorage.removeItem("intraactive-play-menu-collapsed");
              }
            }}
            resetProfile={() => this.props.store.resetProfile()}
            setProfile={() => { }}
            style={{
              height: this.props.store.isMobile ? (this.props.store.showMobileMenu ? "calc(100% - 51px)" : 0) : "calc(100% - 1px)"
            }}
          />
          <div
            className={styles.IA_accountSettings}
            style={{
              width: this.props.store.isMobile ? "100%" : (this.props.store.menuCollapsed ? "calc(100% - 50px)" : "calc(100% - 300px)"),
              transition: "all 300ms ease-in-out",
              backgroundColor: this.props.store.darkMode ? Helper.darkModeBackgroundColor : "#ffffff",
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
          >
            <License
              display={this.state.selectedMenuItem === "license"}
            />
            <Profiles
              display={this.state.selectedMenuItem === "profiles"}
            />
            <Users
              display={this.state.selectedMenuItem === "users"}
            />
            <Integrations
              display={this.state.selectedMenuItem === "integrations"}
            />
          </div>
        </div>
      </IAPanel >
    );
  }


}
