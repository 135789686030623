exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_topnav_3bqwBzevQ3z7IhxH2ueLSv {\n  color: #333333;\n  width: 100%;\n  border-bottom: solid 1px #eaeaea;\n  /* SP Theme: \"DisabledLines\" */\n  height: 44px;\n  overflow: unset;\n  padding: 0 10px;\n  box-sizing: border-box;\n  position: sticky;\n  top: 0;\n  background-color: #ffffff;\n  overflow: hidden;\n  z-index: 1;\n}\n\n.IA_topnav_outer_FGrq2YGYXauYnCWBQ4M03 {\n  color: #333333;\n  width: 100%;\n  border-bottom: none;\n  /* SP Theme: \"DisabledLines\" */\n  height: 44px;\n  overflow: unset;\n  padding: 0 10px;\n  box-sizing: border-box;\n  position: sticky;\n  top: 0;\n  background-color: #eee;\n}\n\n.IA_instanceName_2guRDNYRyzdHdt0e3nYVKh{\n  float: left;\n  font-size: 12px;\n}\n\n.IA_item_13RvrFYWKqnalzD-H3ltlm {\n  float: right;\n  font-size: 11px;\n  height: 44px;\n  box-sizing: border-box;\n  margin-left: 14px;\n  padding-top: 7px;\n  user-select: none;\n}\n\n.IA_navigatorTitle_PGY1xqOVTJgG-uxju3V5z {\n  float: left;\n  margin-left: 30px;\n  line-height: 47px;\n  color: #777;\n  font-size: 11px;\n}", ""]);

// Exports
exports.locals = {
	"IA_topnav": "IA_topnav_3bqwBzevQ3z7IhxH2ueLSv",
	"IA_topnav_outer": "IA_topnav_outer_FGrq2YGYXauYnCWBQ4M03",
	"IA_instanceName": "IA_instanceName_2guRDNYRyzdHdt0e3nYVKh",
	"IA_item": "IA_item_13RvrFYWKqnalzD-H3ltlm",
	"IA_navigatorTitle": "IA_navigatorTitle_PGY1xqOVTJgG-uxju3V5z"
};