import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as styles from './Integrations.css';
import { Store } from '../../stores';
import { IAHeader } from '../../../header/Header';
import { LocalizationService } from '../../../../services/LocalizationService';
import { IAButton } from '../../../button/Button';
import { Popup } from '../../reusableComponents/popup/Popup';
import { IFacebookPageIntegration, IListElement, IPlayProfile, IPlayTimeEditIntegration, IPowerBIWorkspace, PlayIntegrationType } from '../../../../interfaces/IPlay';
import { Helper } from '../../../../Helper';
import { IntegrationsEditPanel } from './integrationsEditPanel/IntegrationsEditPanel';
import PlayService from '../../../../services/PlayService';
import { IASpinner } from '../../../spinner/Spinner';
import { IFeed, IIntegrationsPerProfile } from '../../../../interfaces/IFeed';
import { Group } from '../../reusableComponents/group/Group';
import { IAListView } from '../../../listView/ListView';
import { IASkeleton } from '../../../skeleton/Skeleton';

export interface IProps {
  display: boolean;
  store?: Store;
}

export interface IState {
  loading: boolean;
  loadingProfileIntegrations: {[profileId: string]: boolean};
  profiles?: IPlayProfile[];
  availableMessagesFeeds: IFeed[];
  availableEventsFeeds: IFeed[];
  availableSharePointNewsSites: IFeed[];
  integrationToDelete?: any;
  integrationsPerProfile: IIntegrationsPerProfile;
  isEditingExistingIntegration: boolean;
  areEventsConnected: boolean;
  areMessagesConnected: boolean;
  isConsentGrantedForSharePointNewsApp: boolean;
  isConsentGrantedForSharePointNewsSiteManagementApp: boolean;
  availablePowerBiWorkspaces: IPowerBIWorkspace[];
  availableFacebookPages: IFacebookPageIntegration[];
}

@inject('store')
@observer
export class Integrations extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      loadingProfileIntegrations: {},
      availableMessagesFeeds: undefined,
      availableEventsFeeds: undefined,
      availableSharePointNewsSites: undefined,
      integrationsPerProfile: {},
      isEditingExistingIntegration: undefined,
      areEventsConnected: undefined,
      areMessagesConnected: undefined,
      isConsentGrantedForSharePointNewsApp: undefined,
      isConsentGrantedForSharePointNewsSiteManagementApp: undefined,
      availablePowerBiWorkspaces: undefined,
      availableFacebookPages: undefined
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.display && !this.props.display) {
      if (!this.state.profiles) {
        this.verifyIntranetConnections();
        this.getIntegrations();
        this.getProfiles();
      }
    }
  }
  private async verifyIntranetConnections() {
    const areMessagesConnected = await PlayService.verifyIntranetConnection(this.props.store.environment, this.props.store.token, this.props.store.tenantId, 'messages');
    const areEventsConnected = await PlayService.verifyIntranetConnection(this.props.store.environment, this.props.store.token, this.props.store.tenantId, 'events');
    const isConsentGrantedForSharePointNewsApp = (await PlayService.verifyAppRegistrationConsent(this.props.store.environment, this.props.store.token, this.props.store.tenantId, "SharePointNews"))?.result;
    const isConsentGrantedForSharePointNewsSiteManagementApp = (await PlayService.verifyAppRegistrationConsent(this.props.store.environment, this.props.store.token, this.props.store.tenantId, "SharePointNewsSiteManagement"))?.result;

    await this.setState({ areEventsConnected, areMessagesConnected, isConsentGrantedForSharePointNewsApp, isConsentGrantedForSharePointNewsSiteManagementApp });
  }

  private async getIntegrations() {
    const availableMessagesFeeds = await PlayService.getIntegrations(this.props.store.environment, this.props.store.token, this.props.store.tenantId, 'messages');
    const availableEventsFeeds = await PlayService.getIntegrations(this.props.store.environment, this.props.store.token, this.props.store.tenantId, 'events');
    const availableSharePointNewsSites = await PlayService.getSharePointSitesSelectedAsFeeds(this.props.store.environment, this.props.store.token, this.props.store.tenantId);
    try{
      const availablePowerBiWorkspaces = await PlayService.getPowerBIWorkspaces(this.props.store.environment, this.props.store.token, this.props.store.tenantId);
      this.setState({ availablePowerBiWorkspaces });
    }catch(error){
    }

    try{
      const facebookPages = await PlayService.getFacebookPages(this.props.store.environment, this.props.store.token, this.props.store.tenantId);
      this.setState({ availableFacebookPages: facebookPages });
    }catch(error){
    }
    
    this.setState({ availableEventsFeeds, availableMessagesFeeds, availableSharePointNewsSites});
  }

  private async getProfiles() {
    this.setState({ loading: true });
    const profilesResult = await PlayService.getPlayProfiles(this.props.store.environment, this.props.store.token, this.props.store.tenantId);
    if (profilesResult === 401) {
      this.props.store.showAuthenticationErrorPopup = true;
    }
    else {
      const profiles = Helper.sortAlphabeticalByTitle(profilesResult);
      await this.setState({ profiles, loading: false })
    }
  }
  private async getIntegration(profile: IPlayProfile) {
    let loadingProfileIntegrations: {[profileId: string]: boolean} = JSON.parse(JSON.stringify(this.state.loadingProfileIntegrations));
    loadingProfileIntegrations[profile.id] = true;
    this.setState({ loadingProfileIntegrations });
    let integrationsPerProfile: IIntegrationsPerProfile;
    await Promise.all([
      PlayService.getProfileIntegrations(this.props.store.environment, this.props.store.token, this.props.store.tenantId, profile.id, 'messages'),
      PlayService.getProfileIntegrations(this.props.store.environment, this.props.store.token, this.props.store.tenantId, profile.id, 'events'),
      PlayService.getSharePointProfileNewsSites(this.props.store.environment, this.props.store.token, this.props.store.tenantId, profile.id),
      PlayService.getWorkplaceAPIKey(this.props.store.environment, this.props.store.token, this.props.store.tenantId, profile.id),
      PlayService.getFacebookPageIntegrations(this.props.store.environment, this.props.store.token, this.props.store.tenantId, profile.id),
      PlayService.getPowerBiIntegrations(this.props.store.environment, this.props.store.token, this.props.store.tenantId, profile.id),
      PlayService.getRelesysIntegration(this.props.store.environment, this.props.store.token, this.props.store.tenantId, profile.id),
      PlayService.getTimeEditIntegration(this.props.store.environment, this.props.store.token, this.props.store.tenantId, profile.id)
    ]).then((values) => {
      integrationsPerProfile = JSON.parse(JSON.stringify(this.state.integrationsPerProfile));
      integrationsPerProfile[profile.id] = {
        messagesFeeds: values[0],
        eventsFeeds: values[1],
        sharePointSites: values[2],
        workplaceAPIKey: values[3],
        facebookPageIntegrations: values[4],
        powerBiIntegrations: values[5],
        relesysIntegration: values[6],
        timeEditIntegration: values[7]
      };
    });
    loadingProfileIntegrations = JSON.parse(JSON.stringify(this.state.loadingProfileIntegrations));
    loadingProfileIntegrations[profile.id] = false;
    this.setState({ integrationsPerProfile, loadingProfileIntegrations });
  }

  private onDelete(integrationId: string): void {
    this.props.store.workingOnIt = true;
    console.log(integrationId);
    // PlayService.deleteIntegration(this.props.store.environment, this.props.store.token, this.props.store.tenantId, integrationId).then(() => {
    // 
    // });
  }

  public render(): JSX.Element {

    // const groups = this.state.profiles?.filter(profile => this.state.integrationsPerProfile[profile.id]?.eventsFeeds?.length || this.state.integrationsPerProfile[profile.id]?.messagesFeeds?.length).map(profile => {
    const groups = this.state.profiles?.map(profile => {
      let listElements: IListElement[] = []
      if (this.state.integrationsPerProfile) {
        let listElement: IListElement;
        if (this.state.integrationsPerProfile[profile.id]?.messagesFeeds?.length) {
          listElement = {
            id: "IntraActive-Messages",
            columns: [
              {
                header: this.localizationService.strings?.PlayAdmin_Type?.toUpperCase(),
                type: "tag",
                text: "IntraActive-Messages",
                // icon: feed.application === 'messages' ? 'Widgets Message 2' : 'Widgets Events 2',
                backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
              },
              {
                header: this.localizationService.strings?.PlayAdmin_Feeds?.toUpperCase(),
                type: "text",
                text: this.state.integrationsPerProfile[profile.id]?.messagesFeeds.map(feed => feed.title).join(', '),
                color: this.props.store.darkMode ? "#ffffff" : "#333333"
              },
            ],
            buttons: [
              {
                action: "edit",
                icon: "Edit",
                color: this.props.store.highlightColor
              }
            ]
          }
          listElements.push(listElement);
        }

        if (this.state.integrationsPerProfile[profile.id]?.eventsFeeds?.length) {
          listElement = {
            id: "IntraActive-Events",
            columns: [
              {
                header: this.localizationService.strings.PlayAdmin_Type?.toUpperCase(),
                type: "tag",
                text: "IntraActive-Events",
                // icon: feed.application === 'messages' ? 'Widgets Message 2' : 'Widgets Events 2',
                backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
              },
              {
                header: 'Feeds'?.toUpperCase(),
                type: "text",
                text: this.state.integrationsPerProfile[profile.id]?.eventsFeeds.map(feed => feed.title).join(', '),
                color: this.props.store.darkMode ? "#ffffff" : "#333333"
              },
            ],
            buttons: [
              {
                action: "edit",
                icon: "Edit",
                color: this.props.store.highlightColor
              }
            ]
          };
          listElements.push(listElement);
        }

        if (this.state.integrationsPerProfile[profile.id]?.sharePointSites?.length) {
          listElement = {
            id: "SharePointNews",
            columns: [
              {
                header: this.localizationService.strings.PlayAdmin_Type?.toUpperCase(),
                type: "tag",
                text: "SharePoint News",
                // icon: feed.application === 'messages' ? 'Widgets Message 2' : 'Widgets Events 2',
                backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
              },
              {
                header: 'Sites'?.toUpperCase(),
                type: "text",
                text: this.state.integrationsPerProfile[profile.id]?.sharePointSites.map(feed => feed.title).join(', '),
                color: this.props.store.darkMode ? "#ffffff" : "#333333"
              },
            ],
            buttons: [
              {
                action: "edit",
                icon: "Edit",
                color: this.props.store.highlightColor
              }
            ]
          };
          listElements.push(listElement);
        }
        if (this.state.integrationsPerProfile[profile.id]?.workplaceAPIKey) {
          listElement = {
            id: "WorkplaceFeed",
            columns: [
              {
                header: this.localizationService.strings.PlayAdmin_Type?.toUpperCase(),
                type: "tag",
                text: this.localizationService.strings.PlayAdmin_Workplace,
                // icon: feed.application === 'messages' ? 'Widgets Message 2' : 'Widgets Events 2',
                backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
              },
              {
                header: 'Sites'?.toUpperCase(),
                type: "text",
                text: this.state.integrationsPerProfile[profile.id]?.workplaceAPIKey,
                color: this.props.store.darkMode ? "#ffffff" : "#333333"
              },
            ],
            buttons: [
              {
                action: "edit",
                icon: "Edit",
                color: this.props.store.highlightColor
              }
            ]
          };
          listElements.push(listElement);
        }
        if (this.state.integrationsPerProfile[profile.id]?.facebookPageIntegrations?.length > 0) {
          listElement = {
            id: "FacebookFeed",
            columns: [
              {
                header: this.localizationService.strings.PlayAdmin_Type?.toUpperCase(),
                type: "tag",
                text: "Facebook",
                backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
              },
              {
                header: 'Sites'?.toUpperCase(),
                type: "text",
                text: this.state.integrationsPerProfile[profile.id]?.facebookPageIntegrations?.map(page => page.pageName).join(', '),
                color: this.props.store.darkMode ? "#ffffff" : "#333333"
              },
            ],
            buttons: [
              {
                action: "edit",
                icon: "Edit",
                color: this.props.store.highlightColor
              }
            ]
          };
          listElements.push(listElement);
        }
        if (this.state.integrationsPerProfile[profile.id]?.powerBiIntegrations?.length > 0) {
          listElement = {
            id: "powerbi",
            columns: [
              {
                header: this.localizationService.strings.PlayAdmin_Type?.toUpperCase(),
                type: "tag",
                text: "Power BI",
                backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
              },
              {
                header: 'Sites'?.toUpperCase(),
                type: "text",
                text: this.state.integrationsPerProfile[profile.id]?.powerBiIntegrations?.map(workspace => workspace.name).join(', '),
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
              },
            ],
            buttons: [
              {
                action: "edit",
                icon: "Edit",
                color: this.props.store.highlightColor
              }
            ]
          };
          listElements.push(listElement);
        }
        if (this.state.integrationsPerProfile[profile.id]?.relesysIntegration?.clientId) {
          listElement = {
            id: "relesys",
            columns: [
              {
                header: this.localizationService.strings.PlayAdmin_Type?.toUpperCase(),
                type: "tag",
                text: "Relesys",
                backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
              },
              {
                header: "Client ID".toUpperCase(),
                type: "text",
                text: this.state.integrationsPerProfile[profile.id].relesysIntegration.clientId,
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
              },
            ],
            buttons: [
              {
                action: "edit",
                icon: "Edit",
                color: this.props.store.highlightColor
              }
            ]
          };
          listElements.push(listElement);
        }
        if (this.state.integrationsPerProfile[profile.id]?.timeEditIntegration?.username) {
          listElement = {
            id: "timeEdit",
            columns: [
              {
                header: this.localizationService.strings.PlayAdmin_Type?.toUpperCase(),
                type: "tag",
                text: "TimeEdit",
                backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
              },
              {
                header: "Username".toUpperCase(),
                type: "text",
                text: this.state.integrationsPerProfile[profile.id].timeEditIntegration.username,
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
              },
            ],
            buttons: [
              {
                action: "edit",
                icon: "Edit",
                color: this.props.store.highlightColor
              }
            ]
          };
          listElements.push(listElement);
        }
      }

      return <Group
        key={profile.id}
        label={profile.title}
        darkMode={this.props.store.darkMode}
        count={this.state.integrationsPerProfile[profile.id] ? listElements.length : undefined}
        collapsed
        onExpand={() => {
          console.log(this.state.integrationsPerProfile);
          if (!this.state.integrationsPerProfile[profile.id]) {
            this.getIntegration(profile);
          }
        }}
      >
        {this.state.loadingProfileIntegrations[profile.id] &&
          <>
            <IASkeleton
              skeleton={{
                height: 50,
                width: "auto",
                darkMode: this.props.store.darkMode,
                borderRadius: 5,
                marginBottom: 2,
                marginTop: 24
              }}
            />
            <IASkeleton
              skeleton={{
                height: 50,
                width: "auto",
                darkMode: this.props.store.darkMode,
                borderRadius: 5
              }}
            />
          </>
        }
        <IAListView
          id="ListViewOfIntegrations"
          listElements={listElements}
          backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
          hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
          buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
          darkMode={this.props.store.darkMode}
          defaultAction={"edit"}
          selectedAction={(action: string, integrationType: string) => {
            switch (action) {
              case "edit":
                let type: PlayIntegrationType;
                let feeds: IFeed[] = [];
                let accessToken;
                let powerbiIntegrations: IPowerBIWorkspace[] = [];
                let relesys = {
                  clientId: undefined,
                  clientSecret: undefined
                }
                let timeEdit: IPlayTimeEditIntegration;

                if (integrationType === "IntraActive-Messages") {
                  type = 'messages';
                  feeds = this.state.integrationsPerProfile[profile.id].messagesFeeds
                } else if (integrationType === "IntraActive-Events") {
                  type = 'events';
                  feeds = this.state.integrationsPerProfile[profile.id].eventsFeeds
                } else if (integrationType === "SharePointNews") {
                  type = 'sharepoint-news';
                  feeds = this.state.integrationsPerProfile[profile.id].sharePointSites
                } else if (integrationType === "WorkplaceFeed") {
                  type = 'workplace-feed';
                  accessToken = this.state.integrationsPerProfile[profile.id].workplaceAPIKey
                } else if (integrationType === "FacebookFeed") {
                  type = 'facebook-feed';
                } else if (integrationType === "powerbi") {
                  type = 'powerbi';
                  powerbiIntegrations = this.state.integrationsPerProfile[profile.id].powerBiIntegrations
                } else if (integrationType === "relesys") {
                  type = 'relesys';
                  relesys = this.state.integrationsPerProfile[profile.id].relesysIntegration;
                } else if (integrationType === "timeEdit") {
                  type = 'timeEdit';
                  timeEdit = this.state.integrationsPerProfile[profile.id].timeEditIntegration;
                }

                this.props.store.integrationToEdit = {
                  type: type,
                  profileId: profile.id,
                  feeds: feeds?.map(feed => feed.id),
                  accessToken: accessToken,
                  powerBiWorkspaces: powerbiIntegrations,
                  relesysClientId: relesys.clientId,
                  relesysClientSecret: relesys.clientSecret,
                  timeEditIntegration: timeEdit
                };

                this.setState({ isEditingExistingIntegration: true });
                break;
              default:
                break;
            }
          }}
        />
      </Group>
    })

    return this.props.display ? (
      <div className={styles.IA_integrations}>
        <IntegrationsEditPanel
          show={this.props.store.integrationToEdit != undefined}
          profiles={this.state.profiles}
          integrationsPerProfile={this.state.integrationsPerProfile}
          areMessagesConnected={this.state.areMessagesConnected}
          areEventsConnected={this.state.areEventsConnected}
          isEditingExistingIntegration={this.state.isEditingExistingIntegration}
          isConsentGrantedForSharePointNewsApp={this.state.isConsentGrantedForSharePointNewsApp}
          isConsentGrantedForSharePointNewsSiteManagementApp={this.state.isConsentGrantedForSharePointNewsSiteManagementApp}
          getIntegrationsForProfile={profile => new Promise(async (resolve) => {
            await this.getIntegration(profile);
            resolve();
          })}
          loadingProfileIntegrations={this.state.loadingProfileIntegrations}
          onClose={() => {
            this.props.store.integrationToEdit = undefined;
            this.setState({ isEditingExistingIntegration: undefined })
          }}
          onSave={async () => {
            this.setState({ loading: true });

            switch (this.props.store.integrationToEdit.type) {
              case 'messages':
                await PlayService.connectIntranetApplicationFunction(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.integrationToEdit.profileId, this.props.store.integrationToEdit.type, this.props.store.integrationToEdit.feeds);
                break;
              case 'events':
                await PlayService.connectIntranetApplicationFunction(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.integrationToEdit.profileId, this.props.store.integrationToEdit.type, this.props.store.integrationToEdit.feeds);
                break;
              case 'sharepoint-news':
                await PlayService.updateSharePointSitesForProfile(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.integrationToEdit.profileId, this.props.store.integrationToEdit.feeds, this.props.store.sharepointRootSite);
                break;
              case 'workplace-feed':
                await PlayService.connectWorkplaceFeedFunction(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.integrationToEdit.profileId, this.props.store.integrationToEdit.accessToken);
                break;
              case 'facebook-feed':
                await PlayService.registerFacebookPagesForProfile(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.integrationToEdit.profileId, this.props.store.integrationToEdit.facebookIntegrations);
                break;
              case 'powerbi':
                await PlayService.updatePowerBiIntegrations(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.integrationToEdit.profileId, this.props.store.integrationToEdit.powerBiWorkspaces);
                break;
              case "relesys":
                await PlayService.registerRelesysIntegrations(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.integrationToEdit.profileId, this.props.store.integrationToEdit.relesysClientId, this.props.store.integrationToEdit.relesysClientSecret);
                break;
              case "timeEdit":
                await PlayService.registerTimeEditIntegrations(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.integrationToEdit.profileId, this.props.store.integrationToEdit.timeEditIntegration);
                break;
              default:
                break;
            }

            PlayService.getPlayProfile(this.props.store.environment, this.props.store.token, this.props.store.tenantId, localStorage.getItem("IA_profileId")).then((profile: IPlayProfile) => {
              if (profile) {
                this.props.store.profile = profile;
              }
            });

            await this.getIntegration({id: this.props.store.integrationToEdit.profileId});
            this.props.store.integrationToEdit = undefined;
            this.setState({ loading: false });
          }}
          onConnecting={async () => {
            await this.verifyIntranetConnections();
            await this.getIntegrations();
          }}
        />
        <IAHeader
          id="play-integrations-header"
          title={this.localizationService.strings.PlayAdmin_Integrations}
          description={this.localizationService.strings.PlayAdmin_LicenseDescription}
          expandButtonColor={this.props.store.highlightColor}
          navigationButtonColor="#666666"
          navigationButtonIconColor="#ffffff"
          isMobile={this.props.store.isMobile}
          darkMode={this.props.store.darkMode}
          slides={[
            {
              backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-bg-black.png",
              headline: this.localizationService.strings.PlayAdmin_Integrations,
              text: this.localizationService.strings.PlayAdmin_IntegrationsDescription,
              image: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-illustration-integration.png"
            }
          ]}
          actionButton={{
            label: this.localizationService.strings?.PlayAdmin_Add?.toUpperCase(),
            onClick: () => {
              this.props.store.integrationToEdit = {
                feeds: [],
                profileId: undefined,
                type: undefined
              };
              this.setState({ isEditingExistingIntegration: undefined });
            },
            buttonColor: this.props.store.highlightColor,
            borderRadius: 5,
            textColor: "#ffffff",
            arialable: this.localizationService.strings.PlayAdmin_Add,
            icon: "Add",
            disbaled: this.state.loading
          }}
        />
        {this.state.integrationToDelete &&
          <Popup
            headline={this.localizationService.strings.PlayAdmin_DeleteProfile}
            close={() => this.setState({ integrationToDelete: undefined })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_Yes?.toUpperCase()}
                  onClick={() => {
                    this.onDelete(this.state.integrationToDelete.id);
                  }}
                  borderRadius={5}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_No?.toUpperCase()}
                  onClick={() => this.setState({ integrationToDelete: undefined })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
        {this.state.loading ?
          <IASpinner
            color={this.props.store.highlightColor}
            style={{
              float: "left",
              marginLeft: "calc(50% - 20px)",
              clear: "both"
            }}
          />
          :
          <>
            {groups}
          </>
        }
      </div>
    )
      :
      (
        <></>
      );
  }


}