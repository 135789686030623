import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IAToggle } from '../../../../../toggle/Toggle';
import { IATextField } from '../../../../../textField/TextField';
import { IAGroup } from '../../../../../group/Group';
import { IANumberField } from '../../../../../numberField/NumberField';
import { MediaFileSelector } from '../../../mediaFiles/mediaFileSelector/MediaFileSelector';
import { IAColorPicker } from '../../../../../colorPicker/ColorPicker';
import { IASlider } from '../../../../../slider/Slider';
import { IAColorTheme } from '../../../../../colorTheme/ColorTheme';
import { Helper } from '../../../../../../Helper';
import { IADateAndTimePicker } from '../../../../../dateAndTimePicker/DateAndTimePicker';
import { IAOptionSelector } from '../../../../../optionSelector/OptionSelector';

export interface IProps {
  store?: Store;
  forceUpdate: () => void;
}

@inject('store')
@observer
export class CounterWidgetSettings extends React.Component<IProps> {

  private readonly localizationService = new LocalizationService()

  render(): React.ReactNode {
    return (
      <>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_WidgetSettings?.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <IATextField
            label={this.localizationService.strings.PlayAdmin_Title?.toUpperCase()}
            required
            placeholder={this.localizationService.strings.PlayAdmin_Title}
            text={this.props.store.widgetToEdit?.title}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(title: string) => {
              this.props.store.widgetToEdit.title = title;
              this.props.forceUpdate();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20,
              fontSize: 12
            }}
            darkMode={this.props.store.darkMode}
            label={this.localizationService.strings.PlayAdmin_ShowWidgetTitle?.toUpperCase()}
            checked={this.props.store.widgetToEdit.showTitle}
            color={this.props.store.highlightColor}
            onChange={() => {
              this.props.store.widgetToEdit.showTitle = this.props.store.widgetToEdit.showTitle != undefined ? !this.props.store.widgetToEdit.showTitle : true;
              this.props.forceUpdate();
            }}
          />
          <IANumberField
            label={this.localizationService.strings.PlayAdmin_Duration?.toUpperCase()}
            number={this.props.store.widgetToEdit?.duration && this.props.store.widgetToEdit?.duration !== 0 ? this.props.store.widgetToEdit?.duration : 30}
            onChange={(duration: number) => {
              this.props.store.widgetToEdit.duration = duration;
              this.props.forceUpdate();
            }}
            min={0}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            styles={{
              width: "100%",
              clear: "both"
            }}
            labelStyle={{
              fontSize: "12px"
            }}
          />
          <IATextField
            label={this.localizationService.strings.PlayAdmin_CountdownTitle?.toUpperCase()}
            placeholder={this.localizationService.strings.PlayAdmin_Title}
            text={this.props.store.widgetToEdit?.counter?.title}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(title: string) => {
              this.props.store.widgetToEdit.counter.title = title;
              this.props.forceUpdate();
            }}
          />
          <IATextField
            label={this.localizationService.strings.PlayAdmin_CounterSubtitle?.toUpperCase()}
            placeholder={this.localizationService.strings.PlayAdmin_Title}
            text={this.props.store.widgetToEdit?.counter?.description}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(description: string) => {
              this.props.store.widgetToEdit.counter.description = description;
              this.props.forceUpdate();
            }}
          />
          <IADateAndTimePicker
            selectedDate={new Date(this.props.store.widgetToEdit?.counter?.date)}
            showTimeSelector={true}
            label={this.localizationService.strings.PlayAdmin_CounterToFromDate?.toUpperCase()}
            highlightColor={this.props.store.highlightColor}
            isClearable={false}
            onChange={(date: Date) => {
              this.props.store.widgetToEdit.counter.date = date;
              this.props.forceUpdate();
            }}
            style={{
              width: "100%",
              marginTop: 20,
              float: "left",
              fontSize: "12px"
            }}
          />
          <IAOptionSelector
            label={this.localizationService.strings.PlayAdmin_CounterDountDirection?.toUpperCase()}
            options={[
              {
                iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Timer/SVG/ic_fluent_timer_48_regular.svg",
                key: "up",
                text: this.localizationService.strings.PlayAdmin_CounterDountDirectionUp
              },
              {
                icon: "https://intraactivestorage.blob.core.windows.net/cdn/extra%20icons/ic_hourglass.svg",
                key: "down",
                text: this.localizationService.strings.PlayAdmin_CounterDountDirectionDown
              },
            ]}
            selected={this.props.store.widgetToEdit?.counter.countDirection}
            highlightColor={this.props.store.highlightColor}
            compact
            labelStyle={{
              fontSize: "12px"
            }}
            iconSize={30}
            darkMode={this.props.store.darkMode}
            borderRadius={5}
            onChange={(countDirection: any) => {
              this.props.store.widgetToEdit.counter.countDirection = countDirection;
              this.props.forceUpdate();
            }}
            style={{ clear: "none", marginTop: 20 }}
          />
          {this.props.store.widgetToEdit?.counter.countDirection === "down" &&
            <IATextField
              label={this.localizationService.strings.PlayAdmin_CounterTimeIsUpLabel?.toUpperCase()}
              placeholder={this.localizationService.strings.PlayAdmin_CounterTimeIsUp}
              text={this.props.store.widgetToEdit?.counter?.countdownEndedText}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              borderRadius={5}
              labelStyle={{
                fontSize: "12px"
              }}
              onChange={(countdownEndedText: string) => {
                this.props.store.widgetToEdit.counter.countdownEndedText = countdownEndedText;
                this.props.forceUpdate();
              }}
            />
          }
        </IAGroup>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_ArticleLayoutType?.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <IAOptionSelector
            label={this.localizationService.strings.PlayAdmin_CounterCountLayout?.toUpperCase()}
            options={[
              {
                iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Split Horizontal/SVG/ic_fluent_split_horizontal_48_regular.svg",
                key: "flip",
                text: this.localizationService.strings.PlayAdmin_CounterLayoutClassic
              },
              {
                icon: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Shifts Day/SVG/ic_fluent_shifts_day_24_regular.svg",
                key: "standard",
                text: this.localizationService.strings.PlayAdmin_CounterLayoutSimple
              },
            ]}
            selected={this.props.store.widgetToEdit?.counter.layout}
            highlightColor={this.props.store.highlightColor}
            compact
            labelStyle={{
              fontSize: "12px"
            }}
            iconSize={30}
            darkMode={this.props.store.darkMode}
            borderRadius={5}
            onChange={(layout: any) => {
              this.props.store.widgetToEdit.counter.layout = layout;
              this.props.forceUpdate();
            }}
            style={{ clear: "none", marginTop: 20 }}
          />
          <IAToggle
            style={{
              marginTop: 20,
              fontSize: 12
            }}
            darkMode={this.props.store.darkMode}
            label={this.localizationService.strings.PlayAdmin_CounterShowOnlyDays?.toUpperCase()}
            checked={this.props.store.widgetToEdit.counter.showDays && !this.props.store.widgetToEdit.counter.showHours && !this.props.store.widgetToEdit.counter.showMinuts && !this.props.store.widgetToEdit.counter.showSeconds}
            color={this.props.store.highlightColor}
            onChange={() => {
              if (!this.props.store.widgetToEdit.counter.showHours && !this.props.store.widgetToEdit.counter.showMinuts && !this.props.store.widgetToEdit.counter.showSeconds) {
                this.props.store.widgetToEdit.counter.showHours = true;
                this.props.store.widgetToEdit.counter.showMinuts = true;
                this.props.store.widgetToEdit.counter.showSeconds = true;
              } else {
                this.props.store.widgetToEdit.counter.showHours = false;
                this.props.store.widgetToEdit.counter.showMinuts = false;
                this.props.store.widgetToEdit.counter.showSeconds = false;
              }
              this.props.forceUpdate();
            }}
          />
          <IAColorPicker
            label={this.localizationService.strings.PlayAdmin_CounterBackgroundColor?.toUpperCase()}
            color={this.props.store.widgetToEdit.counter.backgroundColor}
            onChange={(backgroundColor: string) => {
              this.props.store.widgetToEdit.counter.backgroundColor = backgroundColor;
              this.props.forceUpdate();
            }}
            showClearButton={false}
            darkMode={this.props.store.darkMode}
            styles={{ marginTop: 20 }}
          />
          {this.props.store.widgetToEdit.counter.layout === "standard" &&
            <IAColorPicker
              label={this.localizationService.strings.PlayAdmin_CounterTextColor?.toUpperCase()}
              color={this.props.store.widgetToEdit.counter.color}
              onChange={(color: string) => {
                this.props.store.widgetToEdit.counter.color = color;
                this.props.forceUpdate();
              }}
              darkMode={this.props.store.darkMode}
              showClearButton={false}
              styles={{ marginTop: 20 }}
            />
          }
          {this.props.store.widgetToEdit.counter.layout === "flip" &&
            <>
              <label
                style={{
                  color: this.props.store.darkMode ? Helper.darkModeLabelColor : "#333333",
                  marginTop: 20
                }}
              >
                {this.localizationService.strings.PlayAdmin_CounterFlipNumberColors?.toUpperCase()}
              </label>
              <IAColorTheme
                backgroundColor={this.props.store.widgetToEdit?.counter?.colorTheme?.backgroundColor}
                textColor={this.props.store.widgetToEdit?.counter?.colorTheme?.textColor}
                highlightColor={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                swatches={this.props.store.profile?.swatches}
                inTeams
                remove={undefined}
                allowAddingToSwatches={false}
                setBackgroundColor={(backgroundColor) => {
                  this.props.store.widgetToEdit.counter.colorTheme.backgroundColor = backgroundColor;
                  this.props.forceUpdate();
                }}
                setTextColor={(textColor) => {
                  this.props.store.widgetToEdit.counter.colorTheme.textColor = textColor;
                  this.props.forceUpdate();
                }}
              />
            </>
          }
          <MediaFileSelector
            label={this.localizationService.strings.PlayAdmin_BackgroundImage?.toUpperCase()}
            url={this.props.store.widgetToEdit?.counter?.imageUrl}
            type={"image"}
            onChange={(imageUrl: string) => {
              this.props.store.widgetToEdit.counter.imageUrl = imageUrl;
              this.props.forceUpdate();
              this.props.forceUpdate();
            }}
          />
          <IAColorPicker
            label={this.localizationService.strings.PlayAdmin_CounterBackgroundOverlayColor?.toUpperCase()}
            color={this.props.store.widgetToEdit.counter.overlayColor}
            onChange={(overlayColor: string) => {
              this.props.store.widgetToEdit.counter.overlayColor = overlayColor;
              this.props.forceUpdate();
            }}
            disabled={!this.props.store.widgetToEdit.counter.imageUrl}
            showClearButton={false}
            darkMode={this.props.store.darkMode}
            styles={{ marginTop: 20 }}
          />
          <IASlider
            label={this.localizationService.strings.PlayAdmin_CounterBackgroundOverlayOpacity?.toUpperCase()}
            min={0}
            max={10}
            disabled={!this.props.store.widgetToEdit.counter.imageUrl}
            value={`${this.props.store.widgetToEdit.counter.overlayOpacity * 10}`}
            onChange={(overlayOpacity) => {
              this.props.store.widgetToEdit.counter.overlayOpacity = overlayOpacity / 10;
              this.props.forceUpdate();
            }}
            color={this.props.store.highlightColor}
            styles={{ width: "100%" }}
          />
        </IAGroup>
      </>
    );
  }


}