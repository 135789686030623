import * as React from 'react';
import * as styles from './EasterEgg.css';

export interface IProps {
  id: string;
  duration: number;
  sizeUnit: number;
}

export interface IState {
  leftPosition: number
}

export class EasterEgg extends React.Component<IProps, IState> {

  private eggIndex: number;

  public constructor(props: IProps) {
    super(props);
    this.state = {
      leftPosition: -200
    };
    this.eggIndex = Math.floor(Math.random() * 13 + 1);
  }

  public componentDidMount(): void {
    setTimeout(() => {
      this.setState({ leftPosition: window.innerWidth + 200 });
    }, 500);
  }

  public render(): JSX.Element {
    return (
      <div
        id={this.props.id}
        style={{
          position: "absolute",
          transition: `all ${this.props.duration}s linear`,
          left: this.state.leftPosition,
          width: this.props.sizeUnit * 10,
          height: this.props.sizeUnit * 14,
        }}>
        <div
          className={styles.egg}
          style={{
            backgroundImage: `url("https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/easter-confetti/egg_${this.eggIndex}.png")`,
            animationDuration: `${this.props.duration / 6}s`,
            width: this.props.sizeUnit * 10,
            height: this.props.sizeUnit * 14,

          }}
        />
      </div>
    )
  }

}