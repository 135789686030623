import { FetchProxy } from "intraactive-sdk-helper";
import { IEnvironment } from "../interfaces/IEnvironment";
import { Environment } from "./Environment";
import { sdkContainerInstance } from "../global/Container";

export type TokenWithExpiration = { token: string, validTo: string };

export default class AuthenticationService {
  public static getSharepointToken(environment: IEnvironment, tenantName: string, token: string = null): Promise<string> {
    const headers = token 
      ? {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "authtoken": token,
      }
      : {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      };

    return new Promise<string>((resolve, reject) => {
      new FetchProxy(`${Environment.getEnvironmentForAuthenticationApi(environment)}/api/${tenantName}/exchange-token/sharepoint`, {
        method: "GET",
        mode: "cors",
        headers: new Headers(headers),
      })
      .withContainer(sdkContainerInstance)
      .fetch()
      .then(function (response) {
        if (!response.ok) {
          reject(response.status);
        }
        return response.json();
      }).then((response: { token: string }) => {
        resolve(response.token);
      }).catch(() => {
        resolve(undefined);
      });
    });
  }

  public static getSharepointTokenWithExpiration(environment: IEnvironment, tenantName: string, token: string = null): Promise<TokenWithExpiration> {
    const headers = token 
      ? {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "authtoken": token,
      }
      : {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      };

    return new Promise<TokenWithExpiration>((resolve, reject) => {
      new FetchProxy(`${Environment.getEnvironmentForAuthenticationApi(environment)}/api/${tenantName}/exchange-token/sharepoint`, {
        method: "GET",
        mode: "cors",
        headers: new Headers(headers),
      })
      .withContainer(sdkContainerInstance)
      .fetch()
      .then(function (response) {
        if (!response.ok) {
          reject(response.status);
        }
        return response.json();
      }).then((response: TokenWithExpiration) => {
        resolve(response);
      }).catch(() => {
        resolve(undefined);
      });
    });
  }
}