import * as React from 'react';

export interface IProps {
  animation: string;
  delay: string;
}

export interface IState {
}

export class Ghost extends React.Component<IProps, IState> {

  public render(): JSX.Element {
    return (
      <svg
        viewBox="0 0 212 140"
        version="1.1"
        id="ghost"
        style={{
          position: "absolute",
          width: '100%',
          height: '100%',
          WebkitFilter: 'url("#disfilter")',
          filter: 'url("#disfilter") blur(3px)',
          transformOrigin: '60% 70%',
          transform: 'translateZ(130px) translateY(150px) translateX(700px) scale(.55) rotate(0deg)',
          animation: `${this.props.animation} 90s infinite linear`,
          animationDelay: this.props.delay,
        }}
      >
        <defs>
          <filter id="disfilter">
            <feTurbulence type="fractalNoise" baseFrequency="0.05 0.05" numOctaves="2" result="warp" >
              <animate attributeType="XML" attributeName="baseFrequency" from="0.03 0.03" to="0.05 0.05"
                dur="2s" repeatCount="indefinite" />
            </feTurbulence>
            <feDisplacementMap xChannelSelector="R" yChannelSelector="G" scale="20" in="SourceGraphic" in2="warp" />
          </filter>
        </defs>
        <g
          id=""
          transform="translate(1.15,-88.71)">
          <g
            id="ghost1">
            <path
              style={{
                opacity: 0.3,
                fill: "#ffffff",
                fillRule: "evenodd",
                stroke: "none",
                strokeWidth: "0.19091424px",
                strokeLinecap: "butt",
                strokeLinejoin: "miter",
                strokeOpacity: 1
              }}
              d="m 100.79161,132.69971 c -1.333632,-2.27245 -1.884306,-4.93766 -3.007029,-7.31692 -1.566889,-5.09862 -2.457398,-10.18646 -2.571554,-15.48165 -0.363995,-7.24126 2.769229,-15.092861 9.299183,-17.065643 4.18881,-1.121088 11.59069,1.569454 14.28843,9.371783 2.94433,6.2779 3.44947,13.34521 4.56873,19.40732 0.44669,2.43287 0.55536,4.86948 0.85666,7.32839 0,0 -2.09928,-4.16754 -3.25026,-3.9011 -1.15099,0.26644 -0.0904,8.20137 -0.0904,8.20137 0,0 -4.38506,-8.57875 -5.56606,-7.81757 -1.181,0.76119 1.51308,7.73639 1.51308,7.73639 0,0 -3.19598,-4.50606 -4.13691,-3.68064 -0.94093,0.8254 -0.23147,5.7131 -0.23147,5.7131 0,0 -3.62169,-5.89399 -4.88809,-5.03102 -1.26639,0.86298 0.0892,6.30332 0.42496,7.70133 0,0 -2.80584,-4.133 -3.50905,-3.33685 -0.7032,0.79615 -0.17612,3.33416 -0.17612,3.33416 0,0 -3.28524,-4.0866 -3.5241,-5.16245 z"
              id="path8656" />
            <ellipse
              transform="matrix(1,0,0.03111267,0.99951588,0,0)"
              ry="4.2458224"
              rx="2.0475318"
              cy="105.81969"
              cx="98.693253"
              id="ellipse9337"
              style={{
                opacity: 1,
                fill: "#ff0000",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
                strokeWidth: 0.28869617,
                strokeLinecap: "round",
                strokeMiterlimit: 4,
                strokeDasharray: "none",
                strokeDashoffset: 0,
                strokeOpacity: 1
              }} />
            <ellipse
              style={{
                opacity: 1,
                fill: "#000000",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
                strokeWidth: 0.28869617,
                strokeLinecap: "round",
                strokeMiterlimit: 4,
                strokeDasharray: "none",
                strokeDashoffset: 0,
                strokeOpacity: 1
              }}
              id="path8677"
              cx="98.147614"
              cy="106.34912"
              rx="2.0475318"
              ry="4.2458224"
              transform="matrix(1,0,0.03111267,0.99951588,0,0)" />
            <ellipse
              style={{
                opacity: 1,
                fill: "#ff0000",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
                strokeWidth: 0.29199275,
                strokeLinecap: "round",
                strokeMiterlimit: 4,
                strokeDasharray: "none",
                strokeDashoffset: 0,
                strokeOpacity: 1
              }}
              id="ellipse9339"
              cx="89.086861"
              cy="105.88109"
              rx="2.0475318"
              ry="4.3433409"
              transform="matrix(1,0,0.21289874,0.97707427,0,0)" />
            <ellipse
              transform="matrix(1,0,0.21289874,0.97707427,0,0)"
              ry="4.3433409"
              rx="2.0475318"
              cy="106.42267"
              cx="88.44239"
              id="ellipse8679"
              style={{
                opacity: 1,
                fill: "#000000",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
                strokeWidth: 0.29199275,
                strokeLinecap: "round",
                strokeMiterlimit: 4,
                strokeDasharray: "none",
                strokeDashoffset: 0,
                strokeOpacity: 1
              }} />
            <path
              id="path9341"
              d="m 101.25903,114.85223 c 0.36338,-1.60573 2.49577,-2.43815 3.6576,-1.25544 1.00834,0.9066 2.68609,1.06005 3.62958,-0.0347 0.40159,-0.68924 1.23894,-0.47351 1.84663,-0.29923 0.70214,0.28725 1.38874,-0.083 1.79021,-0.69883 0.47633,-0.5191 0.54476,-1.40401 1.18517,-1.75184 0.60181,-0.007 1.20411,0.42134 1.73156,-0.19576 0.48193,-0.3651 1.54588,-1.3397 1.7864,-0.22891 0.0583,0.56536 0.50473,1.00842 0.49076,1.60877 0.10402,1.4453 -0.84599,2.86052 -2.1188,3.33264 -0.75469,0.85366 -0.21777,2.07061 -0.37144,3.09023 -0.0472,1.0268 -1.21074,0.64857 -1.84907,0.59394 -0.907,-0.1249 -1.87046,-0.4983 -2.76822,-0.16272 -0.71464,0.3982 -0.93744,1.57448 -1.85755,1.57939 -0.6336,-0.18067 -1.41644,-0.73959 -1.86516,0.14845 -0.4277,0.62774 -1.04535,1.39233 -1.86835,1.08397 -1.14803,-0.2781 -1.50802,-1.61081 -2.0164,-2.57095 -0.21494,-0.79652 -1.23393,-0.61631 -1.48674,-1.40842 -0.35267,-0.78092 0.29135,-1.52801 0.12602,-2.32612 -0.007,-0.1687 -0.0213,-0.3371 -0.0422,-0.50448 z"
              style={{
                fill: "#ff0000",
                fillRule: "evenodd",
                stroke: "#000000",
                strokeWidth: "0.19091424px",
                strokeLinecap: "butt",
                strokeLinejoin: "miter",
                strokeOpacity: 1
              }} />
            <path
              style={{
                fill: "#000000",
                fillRule: "evenodd",
                stroke: "#000000",
                strokeWidth: "0.19091424px",
                strokeLinecap: "butt",
                strokeLinejoin: "miter",
                strokeOpacity: 1
              }}
              d="m 100.72986,115.3814 c 0.36338,-1.60573 2.49577,-2.43815 3.6576,-1.25544 1.00834,0.9066 2.68609,1.06005 3.62958,-0.0347 0.40159,-0.68924 1.23894,-0.47351 1.84663,-0.29923 0.70214,0.28725 1.38874,-0.083 1.79021,-0.69883 0.47633,-0.5191 0.54476,-1.40401 1.18517,-1.75184 0.60181,-0.007 1.20411,0.42134 1.73156,-0.19576 0.48193,-0.3651 1.54588,-1.3397 1.7864,-0.22891 0.0583,0.56536 0.50473,1.00842 0.49076,1.60877 0.10402,1.4453 -0.84599,2.86052 -2.1188,3.33264 -0.75469,0.85366 -0.21777,2.07061 -0.37144,3.09023 -0.0472,1.0268 -1.21074,0.64857 -1.84907,0.59394 -0.907,-0.1249 -1.87046,-0.4983 -2.76822,-0.16272 -0.71464,0.3982 -0.93744,1.57448 -1.85755,1.57939 -0.6336,-0.18067 -1.41644,-0.73959 -1.86516,0.14845 -0.4277,0.62774 -1.04535,1.39233 -1.86835,1.08397 -1.14803,-0.2781 -1.50802,-1.61081 -2.0164,-2.57095 -0.21494,-0.79652 -1.23393,-0.61631 -1.48674,-1.40842 -0.35267,-0.78092 0.29135,-1.52801 0.12602,-2.32612 -0.007,-0.1687 -0.0213,-0.3371 -0.0422,-0.50448 z"
              id="path8681"
            />
          </g>
        </g>
      </svg>
    );
  }

}