exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_mediaFiles_3kG1tzxBVrK2mnCNYBlPGW {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_3njG_8u0OxJUDzIC2egWj {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_3w4so0iELmjfRgyuBl6Oc0 {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_2m3US_HsT41guxDU-IjlR4 {\n  text-align: center;\n}\n\n.IA_tagButton_naBFDNtlJ5tK2dXTPwLFb {\n  padding: 5px 7px;\n  margin: 5px;\n  border-radius: 5px;\n  cursor: pointer;\n  user-select: none;\n  display: inline-block;\n  font-size: 13px;\n}\n\n.IA_tagButtonWithDelete_3miZpeRlsrSF0ORTHCbGxI {\n  margin: 5px;\n  border-radius: 5px;\n  cursor: pointer;\n  user-select: none;\n  display: inline-block;\n  font-size: 13px;\n  overflow: hidden;\n}\n\n.IA_tagButtonFlexContainer_1F4679rRml_45hEyfgtuuI {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n\n.IA_tagButtonTagName_11JDA-wdt0VInzdIIw7HJL {\n  padding: 3px 7px 5px;\n}\n\n.IA_tagButtonIcon_HaMdNYCZvSj2vGa7P9BYR {\n  padding: 3px 3px 5px;\n}\n\n.IA_tagButtonIcon_HaMdNYCZvSj2vGa7P9BYR:hover{\n  background-color: rgba(0,0,0,0.2);\n}", ""]);

// Exports
exports.locals = {
	"IA_mediaFiles": "IA_mediaFiles_3kG1tzxBVrK2mnCNYBlPGW",
	"IA_filters": "IA_filters_3njG_8u0OxJUDzIC2egWj",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_3w4so0iELmjfRgyuBl6Oc0",
	"IA_paginationPageCount": "IA_paginationPageCount_2m3US_HsT41guxDU-IjlR4",
	"IA_tagButton": "IA_tagButton_naBFDNtlJ5tK2dXTPwLFb",
	"IA_tagButtonWithDelete": "IA_tagButtonWithDelete_3miZpeRlsrSF0ORTHCbGxI",
	"IA_tagButtonFlexContainer": "IA_tagButtonFlexContainer_1F4679rRml_45hEyfgtuuI",
	"IA_tagButtonTagName": "IA_tagButtonTagName_11JDA-wdt0VInzdIIw7HJL",
	"IA_tagButtonIcon": "IA_tagButtonIcon_HaMdNYCZvSj2vGa7P9BYR"
};