exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_profile_2zXPFGjfmX_5_k6vi5m658 {\n  width: 100%;\n  margin-top: 20px;\n}\n\n.IA_divider_RO3KIcWDCGgRWXsyHm40G, .IA_dividerDarkMode_3YoDIMDIJuypb9KoDCfgSH {\n  height: 1px;\n  width: 100%;\n  background-color: #eeeeee;\n  margin-top: 40px;\n  margin-bottom: 20px;\n  float: left;\n}\n\n.IA_dividerDarkMode_3YoDIMDIJuypb9KoDCfgSH {\n  background-color: #a8a8a8;\n}\n\n.IA_widgetType_1UgJ0HRzQFyfkMjBBO5fbH {\n  margin-bottom: 15px;\n  width: 75px;\n  cursor: pointer;\n}\n\n.IA_widgetTypeLabel_27ddd-QuAhaKBoFGBVQOyB {\n  font-size: 12px;\n  float: left;\n  clear: both;\n  width: 90%;\n  margin-top: 2px;\n}\n\n.IA_swatches_3VwZXaZIsaF0Xu92wM2w6r {\n  width: 100%;\n  clear: both;\n  position: relative;\n  float: left;\n}", ""]);

// Exports
exports.locals = {
	"IA_profile": "IA_profile_2zXPFGjfmX_5_k6vi5m658",
	"IA_divider": "IA_divider_RO3KIcWDCGgRWXsyHm40G",
	"IA_dividerDarkMode": "IA_dividerDarkMode_3YoDIMDIJuypb9KoDCfgSH",
	"IA_widgetType": "IA_widgetType_1UgJ0HRzQFyfkMjBBO5fbH",
	"IA_widgetTypeLabel": "IA_widgetTypeLabel_27ddd-QuAhaKBoFGBVQOyB",
	"IA_swatches": "IA_swatches_3VwZXaZIsaF0Xu92wM2w6r"
};