exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_canteenMenuItem_3nS99iBacqtzDVFv8282yi {\n  width: 100%;\n  height: 100%;\n  position: relative;\n  overflow: hidden;\n}\n\n.IA_title_1_9Zf7V2S78ODBTgcDj2oE {\n  float: left;\n  clear: both;\n  position: relative;\n  font-weight: bold;\n}\n\n.IA_contentWrapper_3dsKcoxlWPoP2k-5MRQLu_ {\n  float: left;\n  overflow: hidden;\n  width: 100%;\n  position: relative;\n}\n\n.IA_text_1SuLtfcwWuiXuygTdB6FDG {\n  float: left;\n  clear: both;\n}\n\n.IA_text_1SuLtfcwWuiXuygTdB6FDG p,\n.IA_text_1SuLtfcwWuiXuygTdB6FDG ul {\n  margin: 0;\n}\n\n.IA_progressBarBackground_3vBcygxir27Yfji9o4s_TH {\n  position: relative;\n  float: left;\n  clear: both;\n  position: relative;\n}\n\n.IA_startDate_3tgucyHluLAKS-ZV5omXGI {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 10px; \n  font-weight: lighter;\n}\n\n.IA_byline_2KD-tAK0affo01nDhAlem {\n  float: left;\n  clear: both;\n  position: relative;\n}", ""]);

// Exports
exports.locals = {
	"IA_canteenMenuItem": "IA_canteenMenuItem_3nS99iBacqtzDVFv8282yi",
	"IA_title": "IA_title_1_9Zf7V2S78ODBTgcDj2oE",
	"IA_contentWrapper": "IA_contentWrapper_3dsKcoxlWPoP2k-5MRQLu_",
	"IA_text": "IA_text_1SuLtfcwWuiXuygTdB6FDG",
	"IA_progressBarBackground": "IA_progressBarBackground_3vBcygxir27Yfji9o4s_TH",
	"IA_startDate": "IA_startDate_3tgucyHluLAKS-ZV5omXGI",
	"IA_byline": "IA_byline_2KD-tAK0affo01nDhAlem"
};