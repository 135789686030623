import * as React from 'react';
import { Helper } from '../../Helper';
import * as styles from './PersonaImage.css';

export interface IPersonaImageProps {
  name: string;
  profileImage?: string;
  backgroundColor?: string;
  size?: number;
  fontSize?: number;
  showSkeleton?: boolean;
  marginRight?: number;
}

export class IAPersonaImage extends React.Component<IPersonaImageProps> {
  public render() {
    const firstNameChar = this.props.name?.split(' ')[0].charAt(0);
    const lastNameChar = this.props.name?.split(' ')[this.props.name.split(' ').length - 1].charAt(0);
    return (
      <div
        className={this.props.showSkeleton ? styles.IA_personaSmallProfileImageWrapperSkeleton : styles.IA_personaSmallProfileImageWrapper}
        style={{
          color: this.props.backgroundColor != undefined ? Helper.getTextColorBasedOnBackgroundColorDarkness(this.props.backgroundColor, '#ffffff', '#333333') : '#ffffff',
          backgroundColor: this.props.backgroundColor != undefined ? this.props.backgroundColor : '#333',
          width: this.props.size,
          height: this.props.size,
          marginRight: this.props.marginRight
        }}
      >
        <div
          className={this.props.showSkeleton ? styles.IA_personaSmallProfileImageSkeleton : styles.IA_personaSmallProfileImageText}
          style={{ width: this.props.size, height: this.props.size, lineHeight: `${this.props.size - 2}px`, fontSize: this.props.fontSize }}
        >
          {!this.props.showSkeleton && firstNameChar + lastNameChar}
        </div>
        <div
          className={styles.IA_personaSmallProfileImage}
          style={{
            backgroundImage: `url('${this.props.profileImage}')`,
            width: this.props.size + 2,
            height: this.props.size + 2,
          }}
        />
      </div>
    );
  }
}
