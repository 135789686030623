import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import { IADropDown } from '../../../../../dropDown/DropDown';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IPlayProfile } from '../../../../../../interfaces/IPlay';
import { IATextField } from '../../../../../textField/TextField';

export interface IProps {
  store?: Store;
  profiles: IPlayProfile[];
  isEditingExistingIntegration: boolean;
  onProfileChange: (profile) => void;
  onAccessTokenChange: (accessToken) => void;
}

@inject('store')
@observer
export class WorkPlaceIntegrationComponent extends React.Component<IProps> {
  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public render(): JSX.Element {
    return (
      <>
        <IADropDown
          label={this.localizationService.strings.PlayAdmin_SelectAProfileToAccess?.toUpperCase()}
          selectedOption={this.props.store.integrationToEdit?.profileId}
          options={[{
            key: undefined,
            value: this.localizationService.strings.PlayAdmin_SelectAProfileToAccess,
            disabled: true
          },
          ...this.props.profiles.map(profile => { return { key: profile.id, value: profile.title } })
          ]}
          disabled={this.props.isEditingExistingIntegration}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          onChange={(profile) => {
            this.props.onProfileChange(profile)
          }}
          style={{
            float: "left",
            width: 340
          }}
          borderRadius={5}
        />
        <IATextField
          label={this.localizationService.strings.PlayAdmin_AddWorkplaceAPIKey?.toUpperCase()}
          text={this.props.store.integrationToEdit.accessToken}
          disabled={!this.props.store.integrationToEdit.profileId}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          onChange={(accessToken: string) => this.props.onAccessTokenChange(accessToken)}
        />
      </>
    );
  }
}