import * as React from 'react';
import * as styles from './Spider.css';

export interface IProps {
  sizeUnit: number;
}

export interface IState {
}

export class Spider extends React.Component<IProps, IState> {

  public render(): JSX.Element {
    return (
      <div className={styles.IA_spider}>
        <div
          className={styles.IA_spiderWeb}
          style={{
            width: this.props.sizeUnit / 10,
            height: this.props.sizeUnit * 20
          }}
        />
        <div
          className={styles.IA_spiderBody}
          style={{
            width: this.props.sizeUnit * 12,
            height: this.props.sizeUnit * 12,
            left: -this.props.sizeUnit * 5.6,
            top: -this.props.sizeUnit * 2,
          }}
        >
          <div
            className={styles.IA_spiderLeftEye}
            style={{
              width: this.props.sizeUnit * 2,
              height: this.props.sizeUnit * 2,
              top: this.props.sizeUnit * 3,
              left: this.props.sizeUnit * 4,
            }}
          />
          <div
            className={styles.IA_spiderRightEye}
            style={{
              width: this.props.sizeUnit * 2,
              height: this.props.sizeUnit * 2,
              top: this.props.sizeUnit * 3,
              right: this.props.sizeUnit * 4,
            }}
          />
        </div>
      </div>
    );
  }

}