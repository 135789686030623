import * as React from 'react';
import * as styles from './Slider.css';

export interface ISliderProps {
  label: string;
  value: string;
  min: number;
  max: number
  styles?: React.CSSProperties;
  onChange: (value: number) => void;
  color?: string;
  disabled?: boolean;
}


export class IASlider extends React.Component<ISliderProps> {

  public render(): JSX.Element {
    return (
      <div
        className={styles.IA_slider}
        style={{
          //@ts-ignore
          accentColor: this.props.color ?? "#0E5DC6",
          opacity: this.props.disabled ? 0.5 : 1,
          ...this.props.styles
        }}>
        <label>{this.props.label}</label>
        <input
          disabled={this.props.disabled}
          type="range"
          min={this.props.min}
          max={this.props.max}
          value={this.props.value}
          onChange={(event: any) => this.props.onChange(event.target.value)}
        />
      </div>
    );
  }
}