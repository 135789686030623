import { observable } from "mobx";
import { Helper } from "../../../Helper";
import { IEnvironment } from "../../../interfaces/IEnvironment";

export class Store {

  @observable public token: string;
  @observable public tenant: string;
  @observable public environment: IEnvironment;
  @observable public isMobile: boolean;
  @observable public highlightColor: string;
  @observable public style: React.CSSProperties;
  @observable public placeholder: string;
  @observable public boxStyle: React.CSSProperties = Helper.defaultBoxStyle;
  @observable public simple: boolean;
  @observable public minHeight: number;
  @observable public useImageAPI: boolean;

  // Tool options
  @observable public fontSizeSupport?: boolean;
  @observable public fontStyleSupport?: boolean;
  @observable public listsSupport?: boolean;
  @observable public textAlignmentSupport?: boolean;
  @observable public textColorSupport?: boolean;
  @observable public clearStylingSupport?: boolean;
  @observable public emojiSupport?: boolean;
  @observable public linkSupport?: boolean;
  @observable public imageSupport?: boolean;
  @observable public videoSupport?: boolean;
  @observable public tableSupport?: boolean;
  @observable public boxSupport?: boolean;
  @observable public htmlSupport?: boolean;
  @observable public quoteSupport?: boolean;
  @observable public fontFamilySupport?: boolean;
  @observable public paragraphStyleSupport?: boolean;
  @observable public indentSupport?: boolean;
  @observable public fontStyleAdditionalSupport?: boolean;
  @observable public lineHeightSupport?: boolean;
  @observable public mentionsSupport?: boolean;
  @observable public tableSettingsSupport?: boolean;
  @observable public pastePlain?: boolean;

  // Editor
  @observable public content?: string;
  @observable public editorInstanceId: string = Helper.getRandomStringKey();
  @observable public cursorPositionLeft?: number;
  @observable public cursorPositionTop?: number;

  // Image tool
  @observable public uploadImagesToSharepoint: boolean;

  // Box tool
  @observable public showBoxTool: boolean;

  // Table border
  @observable public showTableSettings: boolean;
  @observable public showTableBorder: boolean = true;

  // Font color and background color (froala)
  @observable public showFontColorTool: boolean;
  @observable public showFontBackgroundColorTool: boolean;

  // Mentions tool
  @observable public showMentionsTool: boolean;
  @observable public mentionsPosition: "above" | "below";
  @observable public mentionsPopupLeft: number;
  @observable public mentionsPopupTop: number;
  @observable public mentionsPopupBottom: number;
  @observable public mentionsRange: Range;
  @observable public mentionsPopupMaxHeight = 400;
  @observable public mentionsAttributeName = "data-upn";
}