import * as React from 'react';
import { IColorTheme } from '../../../../interfaces/IColorTheme';
import { IEnvironment } from '../../../../interfaces/IEnvironment';
import { IPlayNewsItem, IPlayWidgetAreaType, IPlayWidgetExtended } from '../../../../interfaces/IPlay';
import { Environment } from '../../../../services/Environment';
import { Rotator } from '../rotator/Rotator';

export interface IProps {
  widget: IPlayWidgetExtended;
  width: number;
  height: number;
  playerWidth: number;
  playerHeight: number;
  environment: IEnvironment;
  tenantId: string;
  profileId: string;
  editPreviewMode: boolean;
  areaType: IPlayWidgetAreaType;
  language?: string;
  previewColorTheme?: IColorTheme;
  headlineFont: string;
  contentFont: string;
  willRestart: () => void;
}

export interface IState {
  marginLeft: number;
  progressBar: number;
  articles: IPlayNewsItem[];
  feedUrl: string;
  feedHasError: string;
  isFetchingFeed: boolean;
  contentPosition: "left" | "right" | "random";
}

export class Instagram extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      marginLeft: 0,
      progressBar: 0,
      articles: [],
      feedUrl: "",
      feedHasError: undefined,
      isFetchingFeed: false,
      contentPosition: props.widget?.instagram?.contentPosition ?? "left"
    };
  }

  public componentDidMount(): void {
    this.getFeed();
  }

  public componentWillReceiveProps(): void {
    if (this.props.widget?.instagram?.contentPosition !== this.state.contentPosition) {
      this.setState({ contentPosition: this.props.widget?.instagram?.contentPosition }, () => this.getFeed());
    }
  }

  private getFeed(): void {
    try {
      const articles: IPlayNewsItem[] = [{
        id: "Jonas",
        // type: this.props.widget.instagram.type,
        author: "Fellowmind Danmark",
        // title: "Jonas",
        content: "I dag har vi besøg af fellows fra Sverige, Tyskland, Finland og Holland til en cross-regional workshop. Dagen bruges på i fællesskab at definere og forfine den digitale employee experience vi kan tilbyde vores kunder 🇳🇱🇩🇪🇸🇪🇩🇰🇫🇮 #connectedcompany #modernwork",
        date: new Date().toLocaleString(),
        image: "https://intraactiveplaydev.blob.core.windows.net/media-files/c7392b95-d07b-4653-87a7-6c709f527c37/dc99e172-f21c-4561-aa07-14fd5cb52818/353390407_803356724727551_2028074455672635199_n.jpg?blurHash=UVGbVLnnr?-U~qRRRkxZkUofoz%2V?g3bbt6",
        link: "",
        linkText: this.props.widget?.instagram?.linkText,
        contentPosition: this.state.contentPosition
      }];
      this.setState({ articles });
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  public render(): JSX.Element {
    console.log(this.state.articles);
    const colorThemes = this.props.previewColorTheme ? [this.props.previewColorTheme] : (this.props.widget.instagram?.colorThemes?.length > 0 ? this.props.widget.instagram?.colorThemes : [])
    if (colorThemes.length === 0) {
      colorThemes.push(
        {
          textColor: "#333333",
          backgroundColor: "#ffffff"
        }
      );
    }
    return this.state.articles && this.state.articles.length > 0 ? (
      <Rotator
        widgetType="instagram"
        newsItems={this.state.articles}
        widgetId={this.props.widget?.id}
        linkPortalURL={Environment.getEnvironmentForLinkPortal(this.props.environment)}
        width={this.props.width}
        height={this.props.height}
        playerHeight={this.props.playerHeight}
        playerWidth={this.props.playerWidth}
        environment={this.props.environment}
        tenantId={this.props.tenantId}
        profileId={this.props.profileId}
        transition={this.props.widget.instagram?.transition}
        duration={this.props.widget.instagram?.duration}
        boxStyle={this.props.widget.instagram.boxStyle ? this.props.widget.instagram.boxStyle : "solid"}
        editPreviewMode={this.props.editPreviewMode}
        areaType={this.props.areaType}
        colorThemes={colorThemes}
        headlineFont={this.props.headlineFont}
        contentFont={this.props.contentFont}
        showQRLink={this.props.widget.instagram?.showQRLink != undefined ? this.props.widget.instagram?.showQRLink : true}
        showTitle={this.props.widget.instagram?.showTitle != undefined ? this.props.widget.instagram?.showTitle : true}
        showText={this.props.widget.instagram?.showText != undefined ? this.props.widget.instagram?.showText : true}
        showProgressBar={this.props.widget?.showProgressBar != undefined ? this.props.widget?.showProgressBar : true}
        showAuthor={true}
        showDate={this.props.widget.instagram?.showDate != undefined ? this.props.widget.instagram?.showDate : true}
        showImage={this.props.widget.instagram?.showImage != undefined ? this.props.widget.instagram?.showImage : true}
        showVideo={false}
        showGallery={false}
        language={this.props.language}
        widgetTitleIsShowing={this.props.widget?.showTitle}
        excludeImagesFromImageAPI={true}
        willRestart={() => this.props.willRestart()}
      />
    ) : <div></div>;
  }
}