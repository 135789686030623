exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_cardCover_1z4CeKH-sGhVVLJpm0EMqL {\n  background-color: #eeeeee;\n  width: 100%;\n  height: 144px;\n  background-position: center;\n  background-size: cover;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n}\n\n.IA_cardCover_1z4CeKH-sGhVVLJpm0EMqL iframe {\n  border: none;\n}\n\n/* Overlay image */\n\n.IA_coverOverlay_yBdNVit1wOBM4J_5DIi_I {\n  width: 74px;\n  height: 74px;\n  background: rgba(255, 255, 255, 0.8);\n  position: absolute;\n  color: #333333;\n}\n\n.IA_startDate_-7-dJT9fWLeZ4jBf2cI5r {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 10px; \n  font-weight: lighter;\n  font-size: 14px;\n}\n\n.IA_startDateendDateDivider_249c8-1DRlFTC7EDR5aMzU {\n  float: left;\n  width: 60%;\n  height: 1px;\n  background-color: #999999;\n  margin-left: 20%;\n  margin-top: 10px;\n}\n\n.IA_endDate_N-jxwdjy3p5dWTj-RfK_p {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 6px; \n  font-weight: lighter;\n  font-size: 14px;\n}\n\n.IA_coverOverlayToday_1wHso-oPNh8g45masmJdEe {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 27px; \n  font-weight: bold;\n  font-size: 14px;\n}\n\n.IA_coverOverlayMonth_2st2xfxUT3frNU4zjbjQj1 {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 15px; \n  font-weight: normal;\n  font-size: 14px;\n}\n\n.IA_coverOverlayDay_14Raxyqpc5mhsgvMfPjX_f {\n  float: left;\n  width: 100%;\n  text-align: center;\n  font-size: 22px;\n}\n", ""]);

// Exports
exports.locals = {
	"IA_cardCover": "IA_cardCover_1z4CeKH-sGhVVLJpm0EMqL",
	"IA_coverOverlay": "IA_coverOverlay_yBdNVit1wOBM4J_5DIi_I",
	"IA_startDate": "IA_startDate_-7-dJT9fWLeZ4jBf2cI5r",
	"IA_startDateendDateDivider": "IA_startDateendDateDivider_249c8-1DRlFTC7EDR5aMzU",
	"IA_endDate": "IA_endDate_N-jxwdjy3p5dWTj-RfK_p",
	"IA_coverOverlayToday": "IA_coverOverlayToday_1wHso-oPNh8g45masmJdEe",
	"IA_coverOverlayMonth": "IA_coverOverlayMonth_2st2xfxUT3frNU4zjbjQj1",
	"IA_coverOverlayDay": "IA_coverOverlayDay_14Raxyqpc5mhsgvMfPjX_f"
};