import * as React from 'react';
import * as styles from './SharePointSiteManager.css';
import { Store } from '../../../stores';
import { inject, observer } from 'mobx-react';
import { IATextField } from '../../../../textField/TextField';
import { IASpinner } from '../../../../spinner/Spinner';
import { IFeed } from '../../../../../interfaces/IFeed';
import { SharePointSite } from './sharePointSite/SharePointSite';
import PlayService from '../../../../../services/PlayService';
import { AppRegistrationConsentHelper } from '../appRegistrationConsentHelper/AppRegistrationConsentHelper';
import { LocalizationService } from '../../../../../services/LocalizationService';

interface IProps {
  store?: Store;
  onComplete: (id: string) => void;
}

interface IState {
  isLoading: boolean;
  isUsable: boolean;
  isSearching: boolean;
  isAdding: boolean;
  searchTerm?: string;
  results: IFeed[];
}

@inject('store')
@observer
export class SharePointSiteManager extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();
  private debounceInputTimerId: ReturnType<typeof setTimeout>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: false,
      isUsable: false,
      isSearching: false,
      isAdding: false,
      results: [],
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  async componentWillMount(): Promise<void> {

    return;
  }

  componentWillUnmount() {
    return;
  }

  private onSearch(searchTerm: string): void {
    const currentSearchText = searchTerm;
    this.setState({ results: [], isSearching: true, searchTerm }, () => {
      clearTimeout(this.debounceInputTimerId);
      this.debounceInputTimerId = setTimeout(async () => {
        if (this.state.searchTerm.length && this.state.searchTerm == currentSearchText) {
          this.setState({ results: [], isSearching: true })
          PlayService.searchSharePointSites(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.state.searchTerm).then((results) => {
            this.setState({ results, isSearching: false });
          })
        } else {
          this.setState({ isSearching: false });
        }
      }, 500);
    })
  }

  public render(): JSX.Element {
    return (
      <div style={{ float: "left", marginTop: 20, width: "100%" }}>
        {this.state.isUsable ?
          <>
            <IATextField
              label={this.localizationService.strings.PlayAdmin_SharePointNewsSiteManagementAddSite?.toUpperCase()}
              text={this.state.searchTerm}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              borderRadius={5}
              style={{ marginTop: 0 }}
              labelStyle={{
                textTransform: "uppercase",
                fontSize: 14
              }}
              placeholder={this.localizationService.strings.PlayAdmin_SharePointNewsSiteManagementSearch}
              onChange={(input: string) => {
                this.onSearch(input)
              }}
            />
            <div className={styles.IA_sharePointManagementSearchResult}>
              {this.state.isSearching && 
                <IASpinner
                  color={this.props.store.highlightColor}
                  style={{ marginTop: 30, marginBottom: 30 }}
                />
              }
              {!this.state.isSearching && this.state.searchTerm && this.state.results?.length > 0 &&
                <>
                  {this.state.results.map(site => (
                    <SharePointSite
                      key={site.id}
                      highlightColor={this.props.store.highlightColor}
                      siteId={site.id}
                      siteName={site.title}
                      disabled={this.state.isAdding}
                      onAdd={async (id: string) => {
                        this.setState({ isAdding: true });
                        await PlayService.createSharePointSitePermission(this.props.store.environment, this.props.store.token, this.props.store.tenantId, id);
                        this.props.onComplete(id);
                        this.setState({
                          results: undefined,
                          isSearching: false,
                          isAdding: false,
                          searchTerm: undefined
                        });
                      }}
                    />
                  ))}
                </>
              }
            </div>
          </>
          :
          <>
            <AppRegistrationConsentHelper
              label={this.localizationService.strings.PlayAdmin_SharePointNewsSiteManagementConsentRequiredTitle}
              content={this.localizationService.strings.PlayAdmin_SharePointNewsSiteManagementConsentRequiredDescription}
              appRegistrations={[
                {
                  name: "SharePointNewsSiteManagement",
                  heading: this.localizationService.strings.PlayAdmin_AppRegistrationSharePointNewsSiteManagementTitle,
                  description: this.localizationService.strings.PlayAdmin_AppRegistrationSharePointNewsSiteManagementDescription
                }
              ]}
              onConsent={() => {
                return;
              }}
              onSuccess={() => {
                this.setState({ isUsable: true });
              }}
            />
          </>
        }
      </div>
    );
  }
}