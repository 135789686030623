import * as React from 'react';
import * as styles from "./Comment.css";
import { Helper } from './../../../Helper';
import Utils from '../Utils';
import { IAItem } from '../../../interfaces/IAItem';
import { IUser } from '../../../interfaces/IUser';
import { ILike } from '../../../interfaces/ILike';
import { IReply } from '../../../interfaces/IReply';
import SocialService from '../../../services/SocialService';
import { Environment } from '../../../services/Environment';
import DateTimeService from '../../../services/DateTimeService';
import { LoggingService } from '../../../services/LoggingService';
import { LocalizationService } from '../../../services/LocalizationService';
import { IAReader } from '../../reader/Reader';
import { MSPersonCard } from '../../msPersonCard/MSPersonCard';
import { IAButton } from '../../button/Button';
import { IEnvironment } from '../../../interfaces/IEnvironment';
import { IComment } from '../../../interfaces/IComment';
import { IADeleteCommentPopup } from './DeleteCommentPopup';
import { IANewComment } from '../newComment/NewComment';
import { IANewCommentMobilePopup } from '../newComment/NewCommentMobilePopup';

export interface ICommentProps {
  itemId: string;
  item?: IAItem;
  id: string;
  user: IUser;
  userRole?: "Administrator" | "Editor" | "Reader";
  tenant: string;
  component: string;
  instance: string;
  token: string;
  environment?: IEnvironment;
  source: "Home" | "Mobile" | "SharePoint";
  author: IUser;
  content: string;
  created: Date;
  myComment: boolean;
  highlightColor: string;
  likesEnabled: boolean;
  likes?: ILike[];
  reply?: IReply;
  padding: number;
  isMobile: boolean;
  allComments?: IComment[];
  isDeleted?: boolean;
  isEdited?: boolean;
  imageUploadUrl?: string;
  childLevel?: number;
  showAllComments?: boolean;
  commentOpenedFromLink?: string;
  delete?: (commentId: string) => Promise<void>;
  replyComment?: (content: string, mentions: IUser[], reply: IReply) => Promise<void>;
  likesUpdated: (likes: ILike[], commentId: string) => void;
  lastRerender?: Date;
  triggerRerenderOnAllComments?: () => void;
  onOpenIAMessage?: (instance: string, messageId: string) => void;
  onContentImageClicked?: (imageUrl) => void;
  updateComment?: (content: string, commentId: string) => Promise<void>;
}

export interface ICommentState {
  showLikeButtonLoader: boolean;
  likes: ILike[];
  content: string;
  showDeletePopup: boolean;
  isDeleting: boolean;
  deleteCommentError: boolean;
  childComments: IComment[];
  showReplyInput: boolean;
  isAddingComment: boolean;
  showChildComments: boolean;
  isCopyingLink: boolean;
  editMode: boolean;
}

export class IAComment extends React.Component<ICommentProps, ICommentState> {
  private readonly localizationService = new LocalizationService();
  private readonly dateTimeService: DateTimeService = new DateTimeService();
  private readonly constructorName = "IAComment";
  private commentRef: HTMLDivElement;
  private hierarchyIndicator: HTMLSpanElement;

  constructor(props: ICommentProps) {
    super(props);
    this.state = {
      showLikeButtonLoader: false,
      likes: this.props.likes,
      content: undefined,
      showDeletePopup: false,
      isDeleting: false,
      deleteCommentError: false,
      childComments: undefined,
      showReplyInput: false,
      isAddingComment: false,
      showChildComments: false,
      isCopyingLink: false,
      editMode: false
    };
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentDidMount(): void {
    this.setState({ content: this.useImageAPI(this.props.content), childComments: this.getChildComments(), showChildComments: this.props.showAllComments });
    if (this.props.showAllComments && this.props.triggerRerenderOnAllComments) {
      this.props.triggerRerenderOnAllComments();
    }

    if (this.props.commentOpenedFromLink === this.props.id) {
      setTimeout(() => {
        this.commentRef.scrollIntoView({
          block: "start",
          behavior: "smooth"
        });
      }, 100);
    }
  }

  public componentDidUpdate(prevProps: Readonly<ICommentProps>): void {
    if (prevProps.allComments !== this.props.allComments) {
      this.setState({ childComments: this.getChildComments() });
    }
    if (this.props.likes !== this.state.likes) {
      this.setState({ likes: this.props.likes });
    }
    if (this.props.content !== this.state.content && !this.state.editMode) {
      this.setState({ content: this.props.content });
    }
    if (prevProps.showAllComments !== this.props.showAllComments) {
      this.setState({ showChildComments: this.props.showAllComments });
    }

    // on chrome we need to force an update when user opens replies on another comment
    // this prevents a bug where comments where displayed on top of eachother
    if (prevProps.lastRerender !== this.props.lastRerender) {
      this.forceUpdate();
    }
  }

  private useImageAPI(contentToConvert): string {
    let content = contentToConvert;
    var contentDiv: any = document.createElement('div');
    contentDiv.innerHTML = content;
    let images = contentDiv.getElementsByTagName("img");
    for (let i = 0; i < images.length; i++) {
      let src = images[i].src;
      if (src.indexOf("sharepoint") != -1) {
        let imageAPI = "";
        if (this.props.environment == "Production") {
          imageAPI = "https://intraactive-image-functions.azurewebsites.net";
        } else if (this.props.environment == "Test") {
          imageAPI = "https://intraactive-image-functions-test.azurewebsites.net";
        } else if (this.props.environment == "Development") {
          imageAPI = "https://intraactive-image-functions-dev.azurewebsites.net";
        }
        let proxyUrl = Helper.getImageUrl(imageAPI, this.props.tenant, this.props.token, src, undefined, "Full");
        contentDiv.getElementsByTagName("img")[i].src = proxyUrl;
      }
    }
    return (contentDiv.innerHTML);
  }

  private likeButtonOnClick(hasLiked): void {
    if (!this.state.showLikeButtonLoader) {
      this.setState({ showLikeButtonLoader: true });
      if (hasLiked) {
        LoggingService.trackEvent(this.constructorName, {
          ActionType: "UnlikeFromLikesAndComments",
          Component: this.props.component,
          ContentType: this.props.item != undefined ? this.props.item.type : "-",
          ContentId: this.props.item != undefined ? this.props.item.id : "-",
          Tenant: this.props.tenant,
          Instance: this.props.instance,
          UserId: this.props.token,
          Source: this.props.source,
          Environment: this.props.environment
        });
        let likeId: string;
        this.state.likes.forEach((like: ILike) => {
          if (this.props.user && (like.user.userPrincipalName.toLowerCase() == this.props.user.userPrincipalName.toLowerCase())) {
            likeId = like.id;
          }
        });
        SocialService.unlike(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.itemId, likeId, this.props.id).then((likes: ILike[]) => {
          this.setState({ likes, showLikeButtonLoader: false });
          this.props.likesUpdated(likes, this.props.id);
        });
      } else {
        LoggingService.trackEvent(this.constructorName, {
          ActionType: "LikeFromLikesAndComments",
          Component: this.props.component,
          ContentType: this.props.item != undefined ? this.props.item.type : "-",
          ContentId: this.props.item != undefined ? this.props.item.id : "-",
          Tenant: this.props.tenant,
          Instance: this.props.instance,
          UserId: this.props.token,
          Source: this.props.source,
          Environment: this.props.environment
        });
        SocialService.like(this.props.environment, this.props.tenant, this.props.component, this.props.instance, this.props.token, this.props.user, this.props.itemId, this.props.source, this.props.id).then((likes: ILike[]) => {
          this.setState({ likes, showLikeButtonLoader: false });
          this.props.likesUpdated(likes, this.props.id);
        });
      }
    }
  }

  private addNewComment(comment: string, mentions: IUser[], reply?: IReply): Promise<void> {
    return new Promise(async (resolve) => {
      this.setState({ isAddingComment: true }, () => {
        this.props.replyComment(comment, mentions, reply).then(() => {
          this.setState({ showChildComments: true });
          this.toggleReplyInput();
        }).finally(() => {
          this.setState({ isAddingComment: false })
          resolve();
        });
      })
    });
  }

  private toggleReplyInput(): void {
    this.setState({ showReplyInput: !this.state.showReplyInput }, () => {
      if (this.props.triggerRerenderOnAllComments) {
        this.props.triggerRerenderOnAllComments();
        // Unfortunatly we need a timeout here because of a timing issue when rerendering the heirarchy indicator
        setTimeout(() => {
          this.props.triggerRerenderOnAllComments();
        }, 0);
      }
    });
  }

  private getChildComments(): IComment[] {
    const childComments: IComment[] = [];
    this.props.allComments?.forEach(comment => {
      if (comment.reply?.commentId === this.props.id) {
        childComments.push(comment);
      }
    });
    return childComments.reverse();
  }

  private renderAuthor(): JSX.Element[] {
    let author: JSX.Element[] = [];
    author.push(
      <span key={`displayName_${this.props.id}`}>
        {this.props.myComment ? this.localizationService.strings.LikesAndComments_Comment_You : this.props.author.displayName}
      </span>
    );
    if (this.props.reply != undefined && this.props.reply.user != undefined && this.props.childLevel >= 2) {
      author.push(
        <span
          key={`author_${this.props.reply.user.displayName}`}
          style={{ fontWeight: "normal" }}
        >
          {` ${this.localizationService.strings.LikesAndComments_InReplyTo}`} <span style={{ fontWeight: "bold" }}>{this.props.reply.user.displayName}</span>
        </span>
      );
    }
    author.push(
      <span
        key={`authorDate_${this.props.created}`}
        style={{ color: "gray", fontWeight: "normal", fontSize: "12px", lineHeight: "18px", marginLeft: this.props.isMobile ? 0 : 5 }}
      >
        {this.dateTimeService.ConvertToDDMMYYYY(this.props.created) == this.dateTimeService.ConvertToDDMMYYYY(new Date()) ? this.localizationService.strings.DateTimeService_Today + " " + this.dateTimeService.ConvertToHHMM(this.props.created) : this.dateTimeService.ConvertToDDMMYYYYHHMM(this.props.created)}
      </span>
    );
    if (this.props.isEdited) {
      author.push(
        <span
          key={`authorDate_${this.props.created}`}
          style={{ color: "gray", fontWeight: "normal", fontSize: "12px", lineHeight: "18px", marginLeft: this.props.isMobile ? 0 : 10 }}
        >
          {this.localizationService.strings.LikesAndComments_EditedComment}
        </span>
      );
    }
    return author;
  }

  public render(): JSX.Element {
    const isPublishingNews = this.props.item != undefined && this.props.item.type == "News" ? true : false;
    let firstNameChar = this.props.author.displayName != undefined ? this.props.author.displayName.split(" ")[0].charAt(0) : "";
    let lastNameChar = this.props.author.displayName != undefined ? this.props.author.displayName.split(" ")[this.props.author.displayName.split(" ").length - 1].charAt(0) : "";
    let likes: JSX.Element[];
    // likes
    let hasLiked = false;
    let peopleWhoLiked = [];
    if (this.props.likesEnabled && this.state.likes != undefined && this.state.likes.length > 0) {
      this.state.likes.forEach((like: ILike) => {
        if (this.props.user && (like.user.userPrincipalName.toLowerCase() == this.props.user.userPrincipalName.toLowerCase())) {
          hasLiked = true;
        }
        peopleWhoLiked.push(
          <div className={styles.IA_personWhoLiked}>
            {like.user.displayName}
          </div>
        );
      });
      likes = Utils.getLikesWithExamples(this.state.likes, this.props.user, this.props.highlightColor, this.props.isMobile, this.props.environment, this.props.tenant);
    }
    let profileImage = "";
    if (isPublishingNews) {
      profileImage = this.props.author.image;
    } else {
      profileImage = Helper.getProfileImageUrlFromImageAPI(Environment.getEnvironmentForImageAPI(this.props.environment), this.props.tenant, this.props.token, this.props.author.userPrincipalName);
    }
    const userHasDeletePermissions = this.props.myComment || this.props.userRole === "Administrator";
    const childLevel = this.props.childLevel ?? 0;

    // style the hierarchy indicater if it is shown
    if (childLevel > 0 && this.hierarchyIndicator) {
      const parentId = this.props.allComments.find(comment => comment.id === this.props.id)?.reply?.commentId;
      const parent = document.getElementById(parentId);
      if (parent && this.commentRef) {
        const parentRect = parent.getBoundingClientRect();
        const thisCommentRect = this.hierarchyIndicator.getBoundingClientRect();
        const height = thisCommentRect.bottom - parentRect.bottom + parentRect.height - 16;
        this.hierarchyIndicator.style.height = `${height}px`;
        this.hierarchyIndicator.style.top = `-${height - 24}px`;
      }
    }

    let paddingLeft = childLevel < 2 ? childLevel * 30 : undefined;
    if (childLevel >= 2) {
      paddingLeft = 60;
    }
    return (
      <>
        {this.state.showDeletePopup &&
          <IADeleteCommentPopup
            commentContent={this.state.content}
            component={this.props.component}
            environment={this.props.environment}
            highlightColor={this.props.highlightColor}
            instance={this.props.instance}
            item={this.props.item}
            source={this.props.source}
            tenant={this.props.tenant}
            token={this.props.token}
            isDeleting={this.state.isDeleting}
            deleteCommentError={this.state.deleteCommentError}
            delete={() => {
              this.setState({ isDeleting: true }, () => {
                this.props.delete(this.props.id).then(() => {
                  this.setState({ showDeletePopup: false });
                }).catch(() => {
                  this.setState({ deleteCommentError: true });
                }).finally(() => {
                  this.setState({ isDeleting: false });
                });
              });
            }}
            closePopup={() => this.setState({ showDeletePopup: false })}
          />
        }
        {this.state.editMode ?
          (this.props.isMobile ?
            <IANewCommentMobilePopup
              tenant={this.props.tenant}
              token={this.props.token}
              environment={this.props.environment}
              highlightColor={this.props.highlightColor}
              user={this.props.user}
              content={this.state.content}
              reply={{
                commentId: this.props.id,
                user: this.props.author
              }}
              isAddingComment={this.state.isAddingComment}
              comment={(comment) => this.props.updateComment(comment, this.props.id).then(() => this.setState({ editMode: false }))}
              closePopup={() => this.setState({ showReplyInput: false, editMode: false })}
            />
            :
            <IANewComment
              addComment
              cancelReply={() => this.toggleReplyInput()}
              comment={(comment) => this.props.updateComment(comment, this.props.id).then(() => this.setState({ editMode: false }))}
              environment={this.props.environment}
              highlightColor={this.props.highlightColor}
              isMobile={this.props.isMobile}
              content={this.state.content}
              reply={{
                commentId: this.props.id,
                user: this.props.author
              }}
              tenant={this.props.tenant}
              token={this.props.token}
              user={this.props.user}
              marginBottom={10}
              isAddingComment={this.state.isAddingComment}
              cancelUpdate={() => this.setState({ editMode: false })}
            />
          )
          :
          <div
            ref={ref => this.commentRef = ref}
            id={this.props.id}
            className={[styles.IA_comment, userHasDeletePermissions && !this.props.isDeleted ? styles.IA_commentUserCanDelete : ""].join(" ")}
            style={{
              ["--highlight-color" as any]: this.props.highlightColor,
              paddingLeft: paddingLeft
            }}
          >
            {!this.props.isDeleted ?
              <div className={styles.IA_commentProfileImagePersonCardWrapper}>
                <MSPersonCard
                  userLoginName={this.props.author.userPrincipalName}
                  userProfileImage={profileImage}
                  highlightColor={this.props.highlightColor}
                >
                  <div
                    className={styles.IA_commentProfileImageWrapper}
                    style={{
                      backgroundColor: this.props.highlightColor != undefined ? this.props.highlightColor : "#333333",
                      color: this.props.highlightColor != undefined ? Helper.getTextColorBasedOnBackgroundColorDarkness(this.props.highlightColor, "#ffffff", "#333333") : "#ffffff"
                    }}
                  >
                    <div className={styles.IA_commentProfileImageText}>
                      {firstNameChar + lastNameChar}
                    </div>
                    <div
                      className={styles.IA_commentProfileImagePicture}
                      style={{
                        backgroundImage: `url('${profileImage}')`
                      }}
                    />
                  </div>
                </MSPersonCard>
              </div>
              :
              <div className={styles.IA_commentProfileImageDeleted} />
            }
            <div
              className={[styles.IA_commentWrapper, this.props.myComment && !this.props.isDeleted ? styles.IA_commentMyComment : ""].join(" ")}
              style={{
                backgroundColor: this.props.commentOpenedFromLink === this.props.id ? Helper.getColorWithOpacity(this.props.highlightColor, .1, "#e0e0e0") : undefined
              }}
            >
              {childLevel > 0 &&
                <span
                  ref={(ref) => this.hierarchyIndicator = ref}
                  className={styles.IA_commentIsReply}
                />
              }
              {this.props.isDeleted ? <div style={{ fontSize: 13, marginBottom: 5 }}><i>{this.localizationService.strings.LikesAndComments_CommentHasBeenDeleted}</i></div> :
                <>
                  <div className={styles.IA_commentAuthor} style={this.props.isMobile ? { display: 'flex', flexDirection: "column" } : {}}>{this.renderAuthor()}</div>
                  {userHasDeletePermissions &&
                    <div
                      style={{ opacity: this.props.isMobile ? 1 : undefined }}
                      className={styles.IA_commentDeleteBtn}
                    >
                      <IAButton
                        iconUrl="https://intraactivestorage.blob.core.windows.net/cdn/icons/Delete/SVG/ic_fluent_delete_48_regular.svg"
                        onClick={() => this.setState({ showDeletePopup: true })}
                      />
                    </div>
                  }
                  <div
                    style={{
                      opacity: this.props.isMobile ? 1 : undefined,
                      right: userHasDeletePermissions ? 40 : undefined
                    }}
                    className={styles.IA_commentCopyLinkBtn}
                  >
                    <IAButton
                      iconUrl={"https://intraactivestorage.blob.core.windows.net/cdn/icons/Copy/SVG/ic_fluent_copy_24_regular.svg"}
                      showSpinner={this.state.isCopyingLink}
                      onClick={() => {
                        this.setState({ isCopyingLink: true }, () => {
                          navigator.clipboard.writeText(`${Environment.getEnvironmentForLinkPortal(this.props.environment)}/${this.props.tenant}/${this.props.instance}/${this.props.itemId}?commentId=${this.props.id}`)
                            .finally(() => this.setState({ isCopyingLink: false }));
                        });
                      }}
                      textColor="#333333"
                    />
                  </div>
                  {this.props.myComment &&
                    <div
                      style={{
                        opacity: this.props.isMobile ? 1 : undefined,
                        right: 70
                      }}
                      className={styles.IA_commentCopyLinkBtn}
                    >
                      <IAButton
                        iconUrl={"https://intraactivestorage.blob.core.windows.net/cdn/icons/Edit/SVG/ic_fluent_edit_48_regular.svg"}
                        onClick={() => {
                          this.setState({ editMode: true, showChildComments: false });
                        }}
                        textColor="#333333"
                      />
                    </div>
                  }
                  <div
                    className={styles.IA_commentText}
                    id={`comment-${this.props.id}`}
                  />
                  <IAReader
                    content={this.state.content}
                    environment={this.props.environment}
                    tenant={this.props.tenant}
                    token={this.props.token}
                    highlightColor={this.props.highlightColor}
                    isMobile={this.props.isMobile}
                    onOpenIAMessage={(instance, messageId) => this.props.onOpenIAMessage && this.props.onOpenIAMessage(instance, messageId)}
                    onImageClicked={(imageUrl) => this.props.onContentImageClicked && this.props.onContentImageClicked(imageUrl)}
                  />
                  {/* Like button */}
                  {this.props.likesEnabled && !isPublishingNews &&
                    <IAButton
                      iconUrl={hasLiked ? "https://intraactivestorage.blob.core.windows.net/cdn/icons/Thumb%20Like/SVG/ic_fluent_thumb_like_48_filled.svg" : "https://intraactivestorage.blob.core.windows.net/cdn/icons/Thumb%20Like/SVG/ic_fluent_thumb_like_48_regular.svg"}
                      label={hasLiked ? this.localizationService.strings.LikesAndComments_Comment_Unlike : this.localizationService.strings.LikesAndComments_Comment_Like}
                      iconColor={this.props.highlightColor}
                      textColor={this.props.highlightColor}
                      iconMarginTop={6}
                      disbaled={this.state.showLikeButtonLoader}
                      style={{
                        marginRight: 25,
                      }}
                      onClick={() => this.likeButtonOnClick(hasLiked)}
                    />
                  }
                  {/* Reply button */}
                  {!isPublishingNews &&
                    <IAButton
                      iconUrl="https://intraactivestorage.blob.core.windows.net/cdn/icons/Arrow Reply Down/SVG/ic_fluent_arrow_reply_down_24_regular.svg"
                      label={this.localizationService.strings.LikesAndComments_Comment_Reply}
                      iconColor={this.props.highlightColor}
                      textColor={this.props.highlightColor}
                      iconMarginTop={8}
                      onClick={() => this.toggleReplyInput()}
                    />
                  }
                  {/* Show likes */}
                  {!isPublishingNews &&
                    <div
                      className={styles.IA_likes}
                    >
                      {likes}
                    </div>
                  }
                  {this.state.showReplyInput &&
                    <>
                      {this.props.isMobile ?
                        <IANewCommentMobilePopup
                          tenant={this.props.tenant}
                          token={this.props.token}
                          environment={this.props.environment}
                          highlightColor={this.props.highlightColor}
                          user={this.props.user}
                          reply={{
                            commentId: this.props.id,
                            user: this.props.author
                          }}
                          isAddingComment={this.state.isAddingComment}
                          comment={(comment, mentions, reply) => this.addNewComment(comment, mentions, reply)}
                          closePopup={() => this.setState({ showReplyInput: false })}
                        />
                        :
                        <IANewComment
                          addComment
                          cancelReply={() => this.toggleReplyInput()}
                          comment={(comment, mentions, reply) => this.addNewComment(comment, mentions, reply)}
                          environment={this.props.environment}
                          highlightColor={this.props.highlightColor}
                          isMobile={this.props.isMobile}
                          reply={{
                            commentId: this.props.id,
                            user: this.props.author
                          }}
                          tenant={this.props.tenant}
                          token={this.props.token}
                          user={this.props.user}
                          marginBottom={10}
                          isAddingComment={this.state.isAddingComment}
                        />
                      }
                    </>
                  }
                </>
              }
              {/* Show/hide replies */}
              {this.state.childComments && this.state.childComments.length > 0 &&
                <IAButton
                  label={(this.state.showChildComments ? this.localizationService.strings.LikesAndComments_Hide : this.localizationService.strings.LikesAndComments_ShowPreviousComments_1) + " " + this.state.childComments.length + " " + (this.state.childComments.length <= 1 ? this.localizationService.strings.LikesAndComments_Reply : this.localizationService.strings.LikesAndComments_Replies)}
                  textColor={this.props.highlightColor}
                  iconColor={this.props.highlightColor}
                  iconSize={16}
                  iconMarginTop={8}
                  iconUrl={this.state.showChildComments ?
                    "https://intraactivestorage.blob.core.windows.net/cdn/icons/Chevron Up/SVG/ic_fluent_chevron_up_48_regular.svg" :
                    "https://intraactivestorage.blob.core.windows.net/cdn/icons/Chevron Down/SVG/ic_fluent_chevron_down_48_regular.svg"}
                  onClick={() => {
                    this.setState({ showChildComments: !this.state.showChildComments }, () => {
                      if (this.props.triggerRerenderOnAllComments) {
                        this.props.triggerRerenderOnAllComments();
                      }
                    });
                  }}
                />
              }
            </div>
          </div>
        }
        {this.state.showChildComments &&
          <>
            {this.state.childComments.map(comment => {
              let myComment: boolean;
              if (isPublishingNews) {
                myComment = this.props.user && comment.user?.displayName == this.props.user?.displayName;
              } else {
                myComment = this.props.user && comment.user?.userPrincipalName?.toLowerCase() == this.props.user?.userPrincipalName?.toLowerCase();
              }
              return <IAComment
                key={comment.id}
                itemId={this.props.itemId}
                item={this.props.item}
                id={comment.id}
                tenant={this.props.tenant}
                component={this.props.component}
                instance={this.props.instance}
                token={this.props.token}
                environment={this.props.environment}
                source={this.props.source}
                user={this.props.user}
                userRole={this.props.userRole}
                author={comment.user}
                content={comment.content}
                likes={this.props.likesEnabled ? comment.likes : undefined}
                created={comment.created}
                myComment={myComment}
                reply={comment.reply}
                highlightColor={this.props.highlightColor}
                likesEnabled={this.props.likesEnabled}
                padding={this.props.padding}
                isMobile={this.props.isMobile}
                delete={(commentId: string) => this.props.delete(commentId)}
                replyComment={(content: string, mentions: IUser[], reply: IReply) => this.props.replyComment(content, mentions, reply)}
                likesUpdated={(likes, commentId) => this.props.likesUpdated(likes, commentId)}
                allComments={this.props.allComments}
                isDeleted={comment.isDeleted}
                isEdited={comment.isEdited}
                childLevel={childLevel + 1}
                showAllComments={this.props.showAllComments}
                commentOpenedFromLink={this.props.commentOpenedFromLink}
                lastRerender={this.props.lastRerender}
                updateComment={(content: string, commentId: string) => this.props.updateComment(content, commentId)}
                triggerRerenderOnAllComments={() => {
                  if (this.props.triggerRerenderOnAllComments) {
                    this.props.triggerRerenderOnAllComments();
                  }
                }}
              />
            })}
          </>
        }
      </>
    );
  }
}