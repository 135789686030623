import { Helper } from "../../../Helper";
import * as React from 'react';
import ReactPlayer from 'react-player';
import { Environment } from '../../../services/Environment';
import * as styles from "./MessageVideo.css";
import { IEnvironment } from "../../../interfaces/IEnvironment";

export interface IMessageVideoProps {
  id?: string;
  width: string | number;
  height: string | number;
  videoUrl: string;
  environment: IEnvironment;
  tenant: string;
  token: string;
  onPlayVideo?: () => void;
  onPause?: () => void;
  isPlaying?: boolean;
  title?: string;
  forceStop?: boolean;
  style?: React.CSSProperties;
  fixSmallYTThumbnail?: boolean;
  thumbnailUrl?: string;
  onUnsupportedVideo?: () => void;
}

export interface IMessageVideoState {
  videoUrl: string;
  videoType: VideoType;
  isPlaying: boolean;
  randomId: string;
  hasStarted: boolean;
}

export class IAMessageVideo extends React.Component<IMessageVideoProps, IMessageVideoState> {
  constructor(props: IMessageVideoProps) {
    super(props);
    this.state = {
      videoUrl: undefined,
      videoType: undefined,
      isPlaying: false,
      randomId: undefined,
      hasStarted: false
    }
  }

  componentDidMount(): void {
    this.setState({ randomId: Helper.getRandomStringKey() });
    this.setUrlAndType();
  }

  componentDidUpdate(prevProps: Readonly<IMessageVideoProps>): void {
    if ((this.props.isPlaying !== prevProps.isPlaying && !this.props.isPlaying) || (this.props.forceStop !== prevProps.forceStop && this.props.forceStop)) {
      this.setState({ isPlaying: false });
      this.resetIframe();
    }
    if (this.props.isPlaying !== prevProps.isPlaying && this.props.isPlaying) {
      this.setState({ isPlaying: true });
    }
    if (this.props.videoUrl !== prevProps.videoUrl) {
      this.setUrlAndType();
    }
  }

  private onPlay(): void {
    if (this.props.onPlayVideo) {
      this.props.onPlayVideo();
    }
    this.setState({ isPlaying: true, hasStarted: true });
  }

  private onPause(): void {
    if (this.props.onPause) {
      this.props.onPause();
    }
    this.setState({ isPlaying: false });
  }

  private resetIframe(): void {
    var videoIframe = document.getElementById(`IA_Iframe_${this.props.id}_${this.state.randomId}`) as HTMLIFrameElement;
    if (videoIframe !== null) {
      const query = this.state.videoUrl.indexOf("?") !== 1 ? "&" : "?";
      videoIframe.src = this.state.videoUrl + `${query}_=${new Date().getTime()}`;
    }
    this.onPause();
  }

  private setUrlAndType(): void {
    const videoType = this.getVideoType();
    this.setState({ videoType: videoType }, () => this.setState({ videoUrl: this.getUrl() }))
  }

  private getVideoType(): VideoType {
    if (!this.props.videoUrl) {
      return "notValid";
    }
    const url = this.props.videoUrl.toLocaleLowerCase();
    if (url.indexOf("microsoftstream") === -1 && url.indexOf("sharepoint") === -1) {
      if (Helper.isYouTubeUrl(url)) {
        return "youtube";
      } else if (Helper.isVimeoVideo(url)) {
        return "vimeo";
      } else {
        return "iframe";
      }
    } else if (url.includes("stream")) {
      return "stream";
    } else if (Helper.isMp4Video(url)) {
      return "mp4";
    } else if (url.indexOf(".webm") !== -1 || url.indexOf(".mov") !== -1) {
      return "webm/mov";
    } else {
      if (!!this.props.onUnsupportedVideo) this.props.onUnsupportedVideo();
      return "notValid";
    }
  }

  private getUrl(): string {
    if (!this.props.videoUrl) {
      return;
    }
    let videoUrl = this.props.videoUrl?.replace("autoPlay=1", "autoPlay=0"); // remove autoplay
    if (this.state.videoType === "stream") {
      videoUrl = Helper.hideStreamInfo(videoUrl);
    }
    if (this.state.videoType === "mp4" || this.state.videoType === "webm/mov") {
      videoUrl = Helper.getVideoUrl(Environment.getEnvironmentForImageAPI(this.props.environment), this.props.tenant, this.props.token, videoUrl) + "#t=0.1";
    }
    return videoUrl;
  }

  public render(): JSX.Element {
    if (this.state.videoType === "notValid") {
      return <></>;
    } else {
      const style = this.props.style ?? {};
      return (
        <div
          id={`IA_MessageVideo_${this.props.id}`}
          style={{
            ...style,
            height: this.props.height,
            width: this.props.width
          }}
          className={styles.IA_VideoMainDiv}
        >
          {(this.state.videoType === "mp4" || this.state.videoType === "youtube" || this.state.videoType === "webm/mov") &&
            <ReactPlayer
              id={`IA_MessageVideo_${this.props.id}_${this.state.randomId}`}
              width={this.props.width}
              height={this.props.height}
              url={this.state.videoUrl}
              playing={(this.props.fixSmallYTThumbnail && this.state.videoType === "youtube" && !this.state.hasStarted) || (this.state.isPlaying && !this.props.forceStop)}
              onPlay={() => this.onPlay()}
              onPause={() => this.onPause()}
              onClickPreview={() => this.setState({ isPlaying: true })}
              controls={true}
              config={{
                // file: {
                //   attributes: { poster: this.props.imageUrl }
                // },
                youtube: {
                  playerVars: { modestbranding: 1 } // remove youtube logo on playerpanel
                }
              }}
              light={(this.props.fixSmallYTThumbnail && this.state.videoType === "youtube") || this.props.thumbnailUrl}
            />
          }
          {(this.state.videoType === "stream" || this.state.videoType === "iframe" || this.state.videoType === "vimeo") &&
            <iframe id={`IA_Iframe_${this.props.id}_${this.state.randomId}`}
              width={this.props.width}
              height={this.props.height}
              allowFullScreen
              src={this.state.videoUrl}
              style={{
                float: this.props.style?.float ?? "none"
              }}
            />
          }
        </div>
      );
    }
  }
}

export type VideoType = "youtube" | "vimeo" | "iframe" | "mp4" | "webm/mov" | "stream" | "notValid";