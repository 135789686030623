import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import { IListElement, IPlayArticle } from '../../../../../../interfaces/IPlay';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import PlayService from '../../../../../../services/PlayService';
import { IAButton } from '../../../../../button/Button';
import { ArticleEditPanel } from '../../../articles/articleEditPanel/ArticleEditPanel';
import { Helper } from '../../../../../../Helper';
import { PlayHelper } from '../../../../PlayHelper';
import { IAOptionSelector } from '../../../../../optionSelector/OptionSelector';
import { Group } from '../../../../reusableComponents/group/Group';
import { MediaFileSelector } from '../../../mediaFiles/mediaFileSelector/MediaFileSelector';
import { IANumberField } from '../../../../../numberField/NumberField';
import { IAListView } from '../../../../../listView/ListView';
import { IAToggle } from '../../../../../toggle/Toggle';
import { IATextField } from '../../../../../textField/TextField';
import { IAGroup } from '../../../../../group/Group';

export interface IProps {
  store?: Store;
  forceUpdate: () => void;
}

export interface IState {
  allArticles?: IPlayArticle[];
  availableArticles?: { key: string, value: string, disabled?: boolean }[];
  lastChosenArticle?: string;
  expiredArticles?: IPlayArticle[];
}

@inject('store')
@observer
export class ArticleWidgetSettings extends React.Component<IProps, IState> {
  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      allArticles: [],
      availableArticles: [],
      lastChosenArticle: ""
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  componentDidMount() {
    this.getAvailableArticles();
  }

  private getAvailableArticles() {
    const articlesToExclude = [];
    if (this.props.store.widgetToEdit?.articleViewer?.articles?.length > 0) {
      this.props.store.widgetToEdit.articleViewer.articles.forEach((article: IPlayArticle) => {
        articlesToExclude.push(article.id);
      });
    }
    this.props.store.loadingPreviewContent = true;
    PlayService.getArticles(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, { excludeIds: articlesToExclude, showOnly: ["active", "scheduled"] }).then((articles) => {
      this.setState({ allArticles: articles.value });
      this.props.store.loadingPreviewContent = false;
    }).catch(e => {
      this.setState({ allArticles: [] });
      this.props.store.workingOnIt = false;
      this.props.store.generalErrorPopup = {
        technicalDetails: e
      };
    });
  }

  private getExpiredArticles(): void {
    PlayService.getArticlesForWidget(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, this.props.store.widgetToEdit.id, `&showOnly=expired`).then((result: any) => {
      this.setState({ expiredArticles: result.value });
    }).catch(e => {
      this.props.store.workingOnIt = false;
      this.props.store.generalErrorPopup = {
        technicalDetails: e
      };
    });
  }

  render(): React.ReactNode {
    const activeArticleList: IListElement[] = [];
    const scheduledArticleList: IListElement[] = [];
    this.props.store.widgetToEdit?.articleViewer?.articles.forEach((article, index) => {
      if (new Date(article.startDate) < new Date()) {
        activeArticleList.push({
          id: article.id,
          object: article,
          columns: [
            {
              header: this.localizationService.strings.PlayAdmin_Title?.toUpperCase(),
              type: "text",
              text: article.title,
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            },
          ],
          buttons: [
            {
              action: "edit",
              icon: "Edit",
              color: this.props.store.highlightColor
            }, {
              action: "delete",
              icon: "Close",
              color: this.props.store.highlightColor
            }, {
              action: index === 0 ? undefined : "moveUp",
              iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Chevron%20Up/SVG/ic_fluent_chevron_up_48_regular.svg",
              color: this.props.store.highlightColor,
              opacity: index === 0 ? .5 : 1
            }, {
              action: index === this.props.store.widgetToEdit?.articleViewer?.articles.length - 1 ? undefined : "moveDown",
              iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Chevron%20Down/SVG/ic_fluent_chevron_down_48_regular.svg",
              color: this.props.store.highlightColor,
              opacity: index === this.props.store.widgetToEdit?.articleViewer?.articles.length - 1 ? .5 : 1
            }
          ]
        });
      } else {
        scheduledArticleList.push({
          id: article.id,
          object: article,
          columns: [
            {
              header: this.localizationService.strings.PlayAdmin_Title?.toUpperCase(),
              type: "text",
              text: article.title,
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            },
          ],
          buttons: [
            {
              action: "edit",
              icon: "Edit",
              color: this.props.store.highlightColor
            }, {
              action: "delete",
              icon: "Close",
              color: this.props.store.highlightColor
            }
          ]
        });
      }

    });

    const expiredArticles = [];
    if (this.state.expiredArticles?.length > 0) {
      this.state.expiredArticles.forEach((article: IPlayArticle) => {
        expiredArticles.push(
          {
            id: article.id,
            object: article,
            columns: [
              // {
              //   header: "",
              //   type: "icon",
              //   icon: "Attention",
              //   color: this.props.store.darkMode ? "#ffffff" : "#333333",
              //   iconSize: 20,
              //   marginTop: 16
              // },
              {
                header: "",
                type: "text",
                text: article.title,
                color: this.props.store.darkMode ? "#ffffff" : "#333333"
              }
            ],
            buttons: [
              {
                action: "edit",
                icon: "Edit",
                color: this.props.store.highlightColor
              },
              {
                action: "delete",
                icon: "Close",
                color: this.props.store.highlightColor
              }
            ]
          }
        );
      });
    }

    const availableArticles = [];
    if (this.state.allArticles?.length > 0) {
      this.state.allArticles.forEach((article: IPlayArticle) => {
        // Old articles can not be added to new article viewers
        if (!article.boxStyle && (this.props.store.widgetToEdit.id === "new" || new Date(this.props.store.widgetToEdit.created) > PlayHelper.NEW_ARTICLES_LAUNCH_DATE)) {
          return;
        }

        // New articles can not be added to old article viewers
        if (article.boxStyle && new Date(this.props.store.widgetToEdit.created) < PlayHelper.NEW_ARTICLES_LAUNCH_DATE) {
          return;
        }

        availableArticles.push(
          {
            id: article.id,
            object: article,
            columns: [
              {
                header: "",
                type: "text",
                text: article.title,
                color: this.props.store.darkMode ? "#ffffff" : "#333333"
              }
            ],
            buttons: [
              {
                action: "edit",
                icon: "Edit",
                color: this.props.store.highlightColor
              }, {
                action: "add",
                icon: "Add",
                color: this.props.store.highlightColor
              }
            ]
          }
        );
      });
    }

    return (
      <>
        <ArticleEditPanel
          id={"fromWidget"}
          articleId={undefined}
          isOpen={!!this.props.store.articleToEdit}
          close={() => {
            this.props.store.articleToEdit = undefined;
          }}
          onSave={() => {
            this.props.store.workingOnIt = true;
            const articleModel = {
              ...this.props.store.articleToEdit,
              startDate: new Date(this.props.store.articleToEdit.startDate).toJSON(),
              endDate: this.props.store.articleToEdit.endDate ? new Date(this.props.store.articleToEdit.endDate).toJSON() : null
            }
            if (this.props.store.articleToEdit.id && this.props.store.articleToEdit.id !== "new") {
              PlayService.updateArticle(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, articleModel).then(() => {
                this.props.store.workingOnIt = false;
                this.props.store.widgetToEdit.articleViewer.articles.push(this.props.store.articleToEdit);
                // If you update an expired articles to be active
                if (this.props.store.widgetToEdit.articleViewer.omittedArticles.indexOf(this.props.store.articleToEdit.id) !== -1) {
                  this.props.store.widgetToEdit.articleViewer.omittedArticles = this.props.store.widgetToEdit.articleViewer.omittedArticles.filter(articleId => {
                    return articleId != this.props.store.articleToEdit.id;
                  });
                  const expiredArticles = this.state.expiredArticles.filter(article => {
                    return article.id != this.props.store.articleToEdit.id;
                  });
                  this.setState({ expiredArticles });
                  this.props.store.widgetToEdit.articleViewer.articles.push(this.props.store.articleToEdit)
                }
                // TODO: If you update an active articles to be expired
                this.props.store.articleToEdit = undefined;
                this.getAvailableArticles();
              }).catch(e => {
                this.props.store.workingOnIt = false;
                this.props.store.generalErrorPopup = {
                  technicalDetails: e
                };
              });
            } else {
              PlayService.createArticle(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, articleModel).then((id) => {
                this.props.store.workingOnIt = false;
                this.props.store.articleToEdit.id = id;
                this.props.store.widgetToEdit.articleViewer.articles.push(this.props.store.articleToEdit);
                this.props.store.articleToEdit = undefined;
                this.getAvailableArticles();
              }).catch(e => {
                this.props.store.workingOnIt = false;
                this.props.store.generalErrorPopup = {
                  technicalDetails: e
                };
              });
            }
          }}
        />
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_WidgetSettings?.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <IATextField
            label={this.localizationService.strings.PlayAdmin_Title?.toUpperCase()}
            required
            placeholder={this.localizationService.strings.PlayAdmin_Title}
            text={this.props.store.widgetToEdit.title}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(title: string) => {
              this.props.store.widgetToEdit.title = title;
              this.props.forceUpdate();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20,
              fontSize: 12
            }}
            darkMode={this.props.store.darkMode}
            label={this.localizationService.strings.PlayAdmin_ShowWidgetTitle?.toUpperCase()}
            checked={this.props.store.widgetToEdit.showTitle}
            color={this.props.store.highlightColor}
            onChange={() => {
              this.props.store.widgetToEdit.showTitle = this.props.store.widgetToEdit.showTitle != undefined ? !this.props.store.widgetToEdit.showTitle : true;
              this.props.forceUpdate();
            }}
          />
          <IANumberField
            label={this.localizationService.strings.PlayAdmin_MinimumDuration?.toUpperCase()}
            number={this.props.store.widgetToEdit.articleViewer.duration}
            onChange={(duration: number) => {
              this.props.store.widgetToEdit.articleViewer.duration = duration;
              this.props.forceUpdate();
            }}
            min={5}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            styles={{
              width: "100%",
              clear: "both"
            }}
            labelStyle={{
              fontSize: "12px"
            }}
          />
          <MediaFileSelector
            label={this.localizationService.strings.PlayAdmin_NoContentImageUrl?.toUpperCase()}
            url={this.props.store.widgetToEdit.articleViewer.noContentImageUrl ? this.props.store.widgetToEdit.articleViewer.noContentImageUrl : this.localizationService.strings.Player_NoContentUrl}
            type={"image"}
            onChange={(noContentImageUrl: string) => {
              this.props.store.widgetToEdit.articleViewer.noContentImageUrl = noContentImageUrl;
              this.props.forceUpdate();
              this.props.forceUpdate();
            }}
          />
        </IAGroup>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_ArticleLayoutType.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <IAOptionSelector
            label={this.localizationService.strings.PlayAdmin_TransitionType?.toUpperCase()}
            options={[
              {
                icon: "Transition Crossfade",
                key: "crossfade",
                text: this.localizationService.strings.PlayAdmin_Crossfade
              },
              {
                icon: "Transition Slide",
                key: "slide",
                text: this.localizationService.strings.PlayAdmin_Slide
              },
            ]}
            selected={this.props.store.widgetToEdit.articleViewer.transition}
            highlightColor={this.props.store.highlightColor}
            compact
            iconSize={30}
            darkMode={this.props.store.darkMode}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(transitionType: any) => {
              this.props.store.widgetToEdit.articleViewer.transition = transitionType;
              this.props.forceUpdate();
              this.props.forceUpdate();
            }}
            style={{ clear: "none", marginTop: 20 }}
          />
          <IAToggle
            style={{
              marginTop: 20
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            label={this.localizationService.strings.PlayAdmin_ShowProgressBar?.toUpperCase()}
            checked={this.props.store.widgetToEdit.showProgressBar != undefined ? this.props.store.widgetToEdit.showProgressBar : true}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onChange={() => {
              this.props.store.widgetToEdit.showProgressBar = this.props.store.widgetToEdit.showProgressBar != undefined ? !this.props.store.widgetToEdit.showProgressBar : false;
              this.props.forceUpdate();
            }}
          />
        </IAGroup>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_Articles?.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <Group
            key={"activeArticles"}
            count={activeArticleList?.length}
            label={this.localizationService.strings.PlayAdmin_ActiveArticles}
            darkMode={this.props.store.darkMode}
            textAlign={"left"}
            labelColor={this.props.store.darkMode ? "#a8a8a8" : "gray"}
          >
            {activeArticleList?.length > 0 &&
              <IAListView
                id="ListViewOfArticles1"
                listElements={activeArticleList}
                backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
                hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
                buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
                darkMode={this.props.store.darkMode}
                hideHeaders={true}
                defaultAction={undefined}
                selectedAction={(action: string, id: string, article: any) => {
                  const index = this.props.store.widgetToEdit.articleViewer.articles.findIndex(article => article.id === id);
                  switch (action) {
                    case "moveUp":
                      this.props.store.widgetToEdit.articleViewer.articles.splice(index - 1, 0, this.props.store.widgetToEdit.articleViewer.articles.splice(index, 1)[0]);
                      this.props.forceUpdate();
                      break;
                    case "moveDown":
                      this.props.store.widgetToEdit.articleViewer.articles.splice(index + 1, 0, this.props.store.widgetToEdit.articleViewer.articles.splice(index, 1)[0]);
                      this.props.forceUpdate();
                      break;
                    case "delete":
                      this.props.store.widgetToEdit.articleViewer.articles = this.props.store.widgetToEdit.articleViewer.articles.filter(article => {
                        return article.id != id;
                      });
                      this.props.forceUpdate();
                      this.getAvailableArticles();
                      break;
                    case "edit":
                      this.props.store.articleToEdit = article;
                      break;
                    default:
                      break;
                  }
                  setTimeout(() => {
                    this.props.forceUpdate();
                  }, 100);
                }}
              />
            }
          </Group>
          <Group
            key={"scheduledArticles"}
            count={scheduledArticleList?.length}
            label={this.localizationService.strings.PlayAdmin_ScheduledArticles}
            darkMode={this.props.store.darkMode}
            textAlign={"left"}
            labelColor={this.props.store.darkMode ? "#a8a8a8" : "gray"}
          >
            {scheduledArticleList?.length > 0 &&
              <IAListView
                id="ListViewOfArticles1"
                listElements={scheduledArticleList}
                backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
                hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
                buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
                darkMode={this.props.store.darkMode}
                hideHeaders={true}
                defaultAction={undefined}
                selectedAction={(action: string, id: string, article: any) => {
                  const index = this.props.store.widgetToEdit.articleViewer.articles.findIndex(article => article.id === id);
                  switch (action) {
                    case "moveUp":
                      this.props.store.widgetToEdit.articleViewer.articles.splice(index - 1, 0, this.props.store.widgetToEdit.articleViewer.articles.splice(index, 1)[0]);
                      this.props.forceUpdate();
                      break;
                    case "moveDown":
                      this.props.store.widgetToEdit.articleViewer.articles.splice(index + 1, 0, this.props.store.widgetToEdit.articleViewer.articles.splice(index, 1)[0]);
                      this.props.forceUpdate();
                      break;
                    case "delete":
                      this.props.store.widgetToEdit.articleViewer.articles = this.props.store.widgetToEdit.articleViewer.articles.filter(article => {
                        return article.id != id;
                      });
                      this.props.forceUpdate();
                      this.getAvailableArticles();
                      break;
                    case "edit":
                      this.props.store.articleToEdit = article;
                      break;
                    default:
                      break;
                  }
                  setTimeout(() => {
                    this.props.forceUpdate();
                  }, 100);
                }}
              />
            }
          </Group>
          {this.props.store.widgetToEdit?.articleViewer?.omittedArticles?.length > 0 &&
            <Group
              key={"expiredArticles"}
              label={this.localizationService.strings.PlayAdmin_ExpiredArticles}
              count={this.props.store.widgetToEdit?.articleViewer?.omittedArticles?.length != undefined ? this.props.store.widgetToEdit?.articleViewer?.omittedArticles?.length : 0}
              darkMode={this.props.store.darkMode}
              textAlign={"left"}
              labelColor={this.props.store.darkMode ? "#a8a8a8" : "gray"}
              collapsed
              onExpand={() => {
                this.getExpiredArticles();
              }}
            >
              {expiredArticles?.length > 0 &&
                <IAListView
                  id="ListViewOfArticles2"
                  listElements={expiredArticles}
                  backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
                  hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
                  buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
                  darkMode={this.props.store.darkMode}
                  defaultAction={undefined}
                  selectedAction={(action: string, id: string, article: any) => {
                    switch (action) {
                      case "delete":
                        this.props.store.widgetToEdit.articleViewer.omittedArticles = this.props.store.widgetToEdit.articleViewer.omittedArticles.filter(articleId => {
                          return articleId != id;
                        });
                        const expiredArticles = this.state.expiredArticles.filter(article => {
                          return article.id != id;
                        });
                        this.setState({ expiredArticles });
                        this.props.forceUpdate();
                        break;
                      case "edit":
                        this.props.store.articleToEdit = article;
                        break;
                      default:
                        break;
                    }
                    setTimeout(() => {
                      this.props.forceUpdate();
                    }, 10);
                  }}
                />
              }
              <IAButton
                label={this.localizationService.strings.PlayAdmin_RemoveAllExpiredArticles?.toUpperCase()}
                buttonColor={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                onClick={() => {
                  this.props.store.widgetToEdit.articleViewer.omittedArticles = [];
                  this.setState({ expiredArticles: [] });
                  this.props.forceUpdate();
                }}
                showSpinner={this.props.store.workingOnIt}
                borderRadius={5}
                style={{
                  float: "left",
                  position: "relative",
                  clear: "both",
                  marginBottom: 10,
                  marginTop: 20
                }}
              />
            </Group>
          }
          <Group
            key={"availableArticles"}
            label={this.localizationService.strings.PlayAdmin_AvailableArticles}
            count={availableArticles?.length}
            darkMode={this.props.store.darkMode}
            labelColor={this.props.store.darkMode ? "#a8a8a8" : "gray"}
            textAlign={"left"}
          >
            {availableArticles?.length > 0 &&
              <IAListView
                id="ListViewOfArticles3"
                listElements={availableArticles}
                backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
                hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
                buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
                darkMode={this.props.store.darkMode}
                defaultAction={"add"}
                selectedAction={(action: string, id: string, article: any) => {
                  switch (action) {
                    case "add":
                      console.log(id);
                      if (this.props.store.widgetToEdit?.articleViewer?.articles == undefined) {
                        this.props.store.widgetToEdit.articleViewer.articles = [];
                      }
                      let articleHasNotBeenAddedAlready = true;
                      if (this.props.store.widgetToEdit.articleViewer.articles.length > 0) {
                        this.props.store.widgetToEdit.articleViewer.articles.forEach((article: IPlayArticle) => {
                          if (article.id === id) {
                            articleHasNotBeenAddedAlready = false;
                          }
                        })
                      }
                      if (articleHasNotBeenAddedAlready) {
                        this.props.store.widgetToEdit.articleViewer.articles.push(article);
                      }
                      this.getAvailableArticles();
                      this.props.forceUpdate();
                      break;
                    case "edit":
                      this.props.store.articleToEdit = article;
                      break;
                    default:
                      break;
                  }
                  setTimeout(() => {
                    this.props.forceUpdate();
                  }, 10);
                }}
              />
            }
          </Group>
          <IAButton
            label={this.localizationService.strings.PlayAdmin_Add?.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            borderRadius={5}
            darkMode={this.props.store.darkMode}
            onClick={() => {
              this.props.store.articleToEdit = PlayHelper.getEmptyArticle();
            }}
            showSpinner={this.props.store.workingOnIt}
            style={{
              float: "left",
              position: "relative",
              clear: "both"
            }}
          />
        </IAGroup>
      </>);
  }
}