import * as React from 'react';
import { PlayStore } from './stores';
import { inject, observer } from 'mobx-react';
import * as styles from './Player.css';
import '../../styles/BasicIA.css';
import PlayService from '../../services/PlayService';
import { Slide } from './slide/Slide';
import { IPlayer, IPlayerExtended, IPlayRssTickerWidget, IPlaySlideExtended } from '../../interfaces/IPlay';
import { LocalizationService } from '../../services/LocalizationService';
import { PlayHelper } from '../playAdmin/PlayHelper';
import { RssTicker } from './rssTicker/RssTicker';
import { Footer } from './footer/Footer';
import Snowfall from 'react-snowfall';
import { Halloween } from './halloween/Halloween';
import { Helper } from '../../Helper';
import { Easter } from './easter/Easter';

export interface IProps {
  store?: PlayStore;
  preview?: boolean;
  player?: IPlayerExtended;
}

export interface IState {
  waitingForLocationConsent: boolean;
  newPlayerProgressBarWidth: number;
  timeLeftToConnect: number;
  pendingPlayerHasBeenAdded: boolean;
}

@inject('store')
@observer
export class Player extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService;
  private refreshTimer: any;
  private progressBarTimer: any;

  constructor(props: IProps) {
    super(props);
    this.state = {
      waitingForLocationConsent: true,
      newPlayerProgressBarWidth: 0,
      timeLeftToConnect: 300,
      pendingPlayerHasBeenAdded: false
    };
    const tenantId = localStorage.getItem("tenantId");
    if (tenantId) {
      this.props.store.tenantId = tenantId;
    }

    const player = localStorage.getItem("IA_player");
    const language = (JSON.parse(player) as IPlayerExtended)?.language
    if (player && language) {
      this.localizationService = new LocalizationService(PlayHelper.getLocalizerLanguageFromPlayerLanguage(language));
    } else {
      this.localizationService = new LocalizationService();
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  private async loadFont(fontFace: FontFace, type: "headline" | "body"): Promise<void> {
    try {
      await fontFace.load();
      const fonts: any = document.fonts;
      fonts.add(fontFace);
    } catch (e) {
      console.log(e);
      switch (type) {
        case "headline":
          this.props.player.playProfile.fonts.headline = JSON.stringify({
            name: '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
            url: undefined
          });
          break;
        case "body":
          this.props.player.playProfile.fonts.body = JSON.stringify({
            name: '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
            url: undefined
          });
          break;
      }
    }
  }

  private startTimer(): void {
    try {
      if (this.props.store.player == undefined) {
        this.setState({ timeLeftToConnect: this.state.timeLeftToConnect - 1 }, () => {
          setTimeout(() => {
            if (this.state.timeLeftToConnect > 0) {
              this.startTimer();
            } else {
              location.reload();
            }
          }, 1000);
        });
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private addPendingPlayer(): void {
    try {
      if (!this.state.pendingPlayerHasBeenAdded) {
        if (navigator.geolocation && !localStorage.getItem("locationData")) {
          console.log("Asking for geolocation permission...");
          navigator.geolocation.getCurrentPosition((position) => {
            console.log("Permission to use geolocation data granted");
            const lat = position.coords.latitude.toString();
            const lon = position.coords.longitude.toString();
            Helper.setLocalStorage("locationData", JSON.stringify({
              lat,
              lon
            }));
            console.log({ lat, lon });
            PlayService.getCityName(this.props.store.environment, lat, lon).then((cityName) => {
              Helper.setLocalStorage("locationData", JSON.stringify({
                lat,
                lon,
                cityName
              }));
              console.log({ lat, lon, cityName });
            })
          }, (error) => {
            console.log("Unable to get location data, permission denied: " + error);
          });
        } else {
          console.log("Unable to get location data, geolocation not supported");
        }

        this.setState({ pendingPlayerHasBeenAdded: true });
        PlayService.addPendingPlayer(this.props.store.environment).then((player: IPlayer) => {
          if (!this.props.store.pendingPlayer) {
            this.setState({ newPlayerProgressBarWidth: window.innerWidth, timeLeftToConnect: 300 });
            this.startTimer();
            this.props.store.pendingPlayer = player;
            setTimeout(() => {
              this.isConnected();
            }, 5000);
          }
        });
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private isConnected(): void {
    try {
      PlayService.getPendingPlayer(this.props.store.environment, this.props.store.pendingPlayer.displayCode).then((pendingPlayer: IPlayer) => {
        if (pendingPlayer?.profileId && pendingPlayer?.tenantId) {
          this.props.store.pendingPlayer = pendingPlayer;
          this.props.store.tenantId = pendingPlayer.tenantId;
          Helper.setLocalStorage("tenantId", pendingPlayer.tenantId);
          this.createPlayer();
        } else {
          if (pendingPlayer) {
            setTimeout(() => {
              this.isConnected();
            }, 5000);
          } else {
            location.reload();
          }
        }
      });
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private shouldInfoscreenRefresh(restart?: boolean): void {
    try {
      if (restart) {
        this.refresh();
      } else {
        if (localStorage.getItem("refreshDate") !== null) {
          if ("" + new Date().getDate() !== localStorage.getItem("refreshDate")) {
            this.refresh();
          }
        } else {
          Helper.setLocalStorage("refreshDate", "" + new Date().getDate());
        }
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private refresh(): void {
    Helper.setLocalStorage("refreshDate", "" + new Date().getDate());
    location.reload();
  }

  private setFonts(): void {
    if (this.props.store.player?.playProfile?.fonts) {
      if (this.props.store.player?.playProfile?.fonts?.headline) {
        const font: { name: string, url: string } = JSON.parse(this.props.store.player.playProfile.fonts.headline);
        if (font.url) {
          const fontFace: FontFace = new FontFace(font.name, `url(${font.url})`);
          this.loadFont(fontFace, "headline");
        }
        if (this.props.store.player?.playProfile?.fonts?.body) {
          const font: { name: string, url: string } = JSON.parse(this.props.store.player.playProfile.fonts.body);
          if (font.url) {
            const fontFace: FontFace = new FontFace(font.name, `url(${font.url})`);
            this.loadFont(fontFace, "body");
          }
        }
      }
    }
  }

  private sendPlayerDetailsIfInsideKiosk(tries = 0): Promise<void> {
    return new Promise((resolve) => {
      if (PlayHelper.isReplayKiosk()) {
        PlayHelper.sendMessageToWebView("playerDetails", [
          { argKey: "tenantId", argValue: this.props.store.tenantId },
          { argKey: "profileId", argValue: this.props.store.player.playProfile?.id },
          { argKey: "playerId", argValue: this.props.store.player.id },
          { argKey: "screenSize", argValue: `${window.outerWidth}x${window.outerHeight}` }
        ]);
        resolve();
      } else if (tries < 5) {
        // sometimes PlayHelper.isReplayKiosk() is undefined on startup - racing condition
        setTimeout(async () => {
          return await this.sendPlayerDetailsIfInsideKiosk(++tries);
        }, 1000);
      } else {
        // after 5 tries we conclude that we are not inside the kiosk and just resolve
        resolve();
      }
    });
  }

  public componentDidMount(): void {
    try {
      if (this.props.preview) {
        this.props.store.player = this.props.player;
        this.props.store.loading = false;
        this.startPlaylist();
      } else {
        if (localStorage.getItem("IA_player") && localStorage.getItem("IA_player") !== "undefined") {
          this.props.store.player = JSON.parse(localStorage.getItem("IA_player"));
          this.sendPlayerDetailsIfInsideKiosk();
          if (this.props.store.player?.playProfile) {
            if (this.props.store.player.latitude && this.props.store.player.longitude) {
              PlayService.getWeatherForecast(this.props.store.environment, this.props.store.player.latitude, this.props.store.player.longitude).then((weatherData) => {
                this.props.store.weatherData = weatherData;
              });
            }
            if (this.props.store.player.playlist) {
              this.props.store.loading = false;
            } else {
              this.props.store.loading = true;
              setTimeout(() => {
                this.props.store.loading = false;
              }, 1000);
            }
            // Fetching updates for the player
            PlayService.getPlayer(this.props.store.environment, this.props.store.tenantId, this.props.store.player?.playProfile?.id, this.props.store.player.id).then((player: IPlayerExtended) => {
              if (player) {
                PlayService.successfulRequest(this.props.store.environment, this.props.store.tenantId, this.props.store.player.playProfile?.id, this.props.store.player.id, {
                  restarting: false
                });
              }
              this.props.store.connected = player && player.playlist ? true : false;
              if (player && player.playlist) {
                this.props.store.player = player;
                this.setFonts();
                PlayHelper.savePlayerInLocalStorage(this.props.store.player);
              }
              if (this.props.store.player.playlist) {
                this.startPlaylist();
              } else {
                setTimeout(() => {
                  this.getPlayer();
                }, 5000);
              }
            });
            this.state = {
              waitingForLocationConsent: false,
              newPlayerProgressBarWidth: 0,
              timeLeftToConnect: 300,
              pendingPlayerHasBeenAdded: true
            };
          } else {
            this.getPlayer();
          }
        } else {
          this.addPendingPlayer();
        }
        // Resize
        window.addEventListener('resize', () => this.resize());
        setTimeout(() => {
          this.resize();
        }, 0);
        try {
          var resizeObserver = new ResizeObserver(() => {
            this.resize();
          });
          resizeObserver.observe(document.getElementById(`IA_Content`));
        } catch {
          this.resize();
        }
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.player !== this.props.player) {
      this.props.store.player = this.props.player;
    }
  }

  private resize(): void {
    try {
      const player = document.getElementById("IA_Player");
      if (player && this.props.store.player) {
        this.props.store.player.width = player.clientWidth;
        this.props.store.player.height = player.clientHeight;
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private createPlayer(): void {
    try {
      const locationData: { lat: string, lon: string, cityName: string } = JSON.parse(localStorage.getItem("locationData"));
      PlayService.createPlayer(this.props.store.environment, this.props.store.pendingPlayer.tenantId, this.props.store.pendingPlayer?.profileId, {
        profileId: this.props.store.pendingPlayer?.profileId,
        width: screen.width,
        height: screen.height,
        latitude: locationData?.lat,
        longitude: locationData?.lon,
        city: locationData?.cityName,
        displayCode: this.props.store.pendingPlayer?.displayCode,
        nonce: this.props.store.pendingPlayer?.nonce,
      }).then((playerId: string) => {
        const newPlayer: IPlayerExtended = {
          id: playerId,
          width: screen.width,
          height: screen.height,
          latitude: locationData?.lat,
          longitude: locationData?.lon,
          city: locationData?.cityName,
          displayCode: this.props.store.pendingPlayer?.displayCode,
          playProfile: { id: this.props.store.pendingPlayer?.profileId }
        }
        this.createPlayerCallback(newPlayer);
      });
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private createPlayerCallback(player: IPlayerExtended): void {
    try {
      this.props.store.player = player;
      this.props.store.pendingPlayer = undefined;
      PlayHelper.savePlayerInLocalStorage(player);
      this.setState({ waitingForLocationConsent: false });
      setTimeout(() => {
        this.getPlayer();
      }, 5000);
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private getFPS(): Promise<number> {
    try {
      return new Promise<number>((resolve) => {
        try {
          const frame = performance.now();
          requestAnimationFrame(function calculateFPS() {
            const timeSinceLastFrame = (performance.now() - frame) / 1000;
            const fps = 1 / timeSinceLastFrame;
            resolve(Math.round(fps));
          });
        } catch (e) {
          resolve(-1);
        }
      });
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private getPlayer(): void {
    try {
      let memory: {
        jsHeapSizeLimit: number;
        usedJSHeapSize: number;
      };
      // @ts-ignore
      if (window.performance && window.performance.memory) {
        // @ts-ignore
        memory = window.performance.memory;
      }
      this.getFPS().then(fps => {
        PlayService.getPlayer(this.props.store.environment, this.props.store.tenantId, this.props.store.player?.playProfile?.id, this.props.store.player.id).then((player: IPlayerExtended) => {
          this.shouldInfoscreenRefresh();
          if (player) {
            this.sendPlayerDetailsIfInsideKiosk();
            const playerShouldRestart = player?.restartAt != null && new Date().getTime() >= new Date(player?.restartAt).getTime();
            PlayService.successfulRequest(this.props.store.environment, this.props.store.tenantId, this.props.store.player?.playProfile?.id, this.props.store.player.id, {
              restarting: playerShouldRestart,
              jsHeapSizeLimit: memory?.jsHeapSizeLimit ?? null,
              usedJSHeapSize: memory?.usedJSHeapSize ?? null,
              fps: fps
            }).then(() => {
              if (playerShouldRestart) {
                console.log("playerShouldRestart: " + playerShouldRestart);
              }
              this.shouldInfoscreenRefresh(playerShouldRestart);
            });
          }
          this.props.store.connected = player && player.playlist ? true : false;
          // Only update the player if it has changes
          if (PlayHelper.playerHasChanges(this.props.store.player, player)) {
            this.props.store.player = player;
            PlayHelper.savePlayerInLocalStorage(player);
            this.resize();
            this.forceUpdate();
            location.reload();
          } else {
            // If playlist is not added to the player, it should retry in 5 seconds
            if (!this.props.store.player.playlist) {
              setTimeout(() => {
                this.getPlayer();
              }, 5000);
            }

            // power bi integrations has short lived tokens (1 hour) so we need to add the new token
            // from the call manually when there is no changes otherwise
            this.props.store.player?.playlist?.slides?.forEach((slide, i) => {
              slide?.widgets?.forEach((widget, j) => {
                if (widget?.type === "powerbi" && widget.powerbi) {
                  console.log("updating powerbi access token for widget", widget.title, player.playlist.slides[i].widgets[j].powerbi.accessToken);
                  widget.powerbi.accessToken = player.playlist.slides[i].widgets[j].powerbi.accessToken;
                }
              });
            });
          }
          // get and update weather
          if (this.props.store.player?.latitude && this.props.store.player?.longitude) {
            PlayService.getWeatherForecast(this.props.store.environment, this.props.store.player?.latitude, this.props.store.player?.longitude).then((weatherData) => {
              this.props.store.weatherData = weatherData;
            });
          }
        });
      });
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private startPlaylist(): void {
    try {
      if (this.props.store.player) {
        this.props.store.loading = false;
        this.resize();
        this.forceUpdate();
        if (!this.props.preview) {
          this.refreshPlayer();
        }
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private refreshPlayer(): void {
    try {
      clearTimeout(this.refreshTimer);
      this.refreshTimer = setTimeout(() => {
        this.getPlayer();
        this.refreshPlayer();
      }, 60000);
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private getNextSlide(slides: IPlaySlideExtended[]): void {
    try {
      if (this.props.store.currentSlideIndex < slides.length - 1) {
        this.props.store.currentSlideIndex++;
      } else {
        this.props.store.currentSlideIndex = 0;
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  public render(): JSX.Element {
    let sizeUnit = screen.height / 100;
    let profileRssTicker: IPlayRssTickerWidget;
    const slidesToShow = PlayHelper.getSlidesWithActiveContent(this.props.store.player?.playlist?.slides);
    const slidesComponents = [];
    const playlistNavigationDots: JSX.Element[] = [];
    try {
      if (screen.height > screen.width) {
        sizeUnit = screen.width / 100;
      }
      if (this.props.store.player?.playlist?.rssTickerId) {
        const profileTickers = this.props.store.player?.playProfile?.rssTickers ?? [];
        profileRssTicker = PlayHelper.getTickerbyId(profileTickers, this.props.store.player?.playlist?.rssTickerId);
      }
      if (slidesToShow.length > 0) {
        slidesToShow.forEach((slide: any, index) => {
          if (slide.props) {
            slide = slide.props.slide;
          }
          slidesComponents.push(
            <Slide
              key={`slide_${index}`}
              index={index}
              slide={slide}
              isActive={this.props.store.currentSlideIndex === index}
              height={this.props.store.player.height}
              width={this.props.store.player.width}
              left={this.props.store.player.playlist && this.props.store.player.playlist.transitionType === "slide" ? this.props.store.player.width * (index) : 0}
              footer={slide.showFooter && this.props.store.player.playProfile?.footer}
              city={this.props.store.player.city}
              environment={this.props.store.environment}
              tenantId={this.props.store.tenantId}
              profileId={this.props.store.player?.playProfile?.id}
              playerId={this.props.store.player?.id}
              rssTicker={profileRssTicker}
              language={this.props.store.player.language ?? "en"}
              standalone={this.props.store.player.playlist?.slides?.length === 1}
              forecast={this.props.store.weatherData}
              nextSlide={() => this.getNextSlide(slidesToShow)}
              isHardcodedWeatherData={this.props.store.previewIsHardcodedWeatherData}
              headlineFont={this.props.store.player?.playProfile?.fonts?.headline ? JSON.parse(this.props.store.player?.playProfile?.fonts?.headline).name : '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'}
              contentFont={this.props.store.player?.playProfile?.fonts?.body ? JSON.parse(this.props.store.player?.playProfile?.fonts?.body).name : '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'}
            />
          );
        });
      }
      // Playlist Navigation
      if (slidesToShow.length > 0) {
        for (let index = 0; index < slidesToShow.length; index++) {
          playlistNavigationDots.push(
            <div
              key={`playlistNavigationDot_${index}`}
              className={styles.IA_playlistNavigationDot}
              style={{
                width: this.props.store.player.width / 140,
                height: this.props.store.player.width / 140,
                marginRight: this.props.store.player.width / 180,
                borderRadius: this.props.store.player.width / 140,
                opacity: index === this.props.store.currentSlideIndex ? 0.7 : 0.3
              }}
              onClick={() => {
                this.props.store.currentSlideIndex = index;
              }}
            />
          );
        }
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
    const showFooter = slidesToShow[this.props.store.currentSlideIndex] && slidesToShow[this.props.store.currentSlideIndex].showFooter;
    const isRssActive = slidesToShow[this.props.store.currentSlideIndex] && slidesToShow[this.props.store.currentSlideIndex].showRssTicker && !!profileRssTicker;
    return (
      <div
        id="IA_Player"
        style={{
          height: "100%",
          backgroundColor: "black"
        }}
      >
        {this.props.store.player?.playlist?.showSnow &&
          <div
            id="Snow"
            style={{
              display: "block",
              position: 'absolute',
              top: 0,
              width: "100%",
              height: "100% ",
              zIndex: 4000000,
              pointerEvents: "none",
              background: undefined
            }}>
            <Snowfall
              color="white"
            />
          </div>
        }
        {this.props.store.player?.playlist?.showHalloween && (this.props.store.player?.height > this.props.store.player?.width ? this.props.store.player?.width / 100 : this.props.store.player?.height / 100) &&
          <Halloween
            sizeUnit={(this.props.store.player?.height > this.props.store.player?.width ? this.props.store.player?.width / 100 : this.props.store.player?.height / 100)}
          />
        }
        {this.props.store.player?.playlist?.showEaster && (this.props.store.player?.height > this.props.store.player?.width ? this.props.store.player?.width / 100 : this.props.store.player?.height / 100) &&
          <Easter
            sizeUnit={(this.props.store.player?.height > this.props.store.player?.width ? this.props.store.player?.width / 100 : this.props.store.player?.height / 100)}
          />
        }
        <div
          id="IA_newPlayer"
          className={styles.IA_newPlayer}
          style={{
            opacity: (this.props.store.player?.displayCode || this.props.store.pendingPlayer?.displayCode) && !this.props.store.player?.playlist ? 1 : 0
          }}
        >
          <div className={styles.IA_configurationView}>
            <div
              className={styles.IA_guide}
              style={{
                fontSize: sizeUnit * 2,
                lineHeight: window.innerWidth > window.innerHeight ? "3.5vh" : "3.5vw"
              }}
            >
              {this.props.store.player?.displayCode ? this.localizationService.strings.PlayAdmin_PlayerConnected?.toUpperCase() : this.localizationService.strings.PlayAdmin_ConnectNewPlayer?.toUpperCase()}
            </div>
            <div
              className={styles.IA_code}
              style={{
                fontSize: sizeUnit * 15
              }}
            >
              {this.props.store.player?.displayCode ? this.props.store.player?.displayCode : this.props.store.pendingPlayer?.displayCode}
            </div>
            {!this.props.store.player?.displayCode &&
              <>
                <div
                  className={styles.IA_timerInfo}
                  style={{
                    fontSize: sizeUnit * 2
                  }}
                >
                  {this.localizationService.strings.Player_TimeToNewCode?.replace("timeLeftToConnect", this.state.timeLeftToConnect.toString())?.toUpperCase()}
                </div>
                <div className={styles.IA_timer}>
                  <div
                    className={styles.IA_progressBar}
                    style={{
                      width: this.state.newPlayerProgressBarWidth
                    }}
                  />
                </div>
              </>
            }
          </div>
        </div>
        {slidesToShow.length > 0 && this.props.store.player?.width &&
          <>
            {profileRssTicker &&
              <RssTicker
                rssTicker={{ rssTicker: profileRssTicker }}
                sizeUnit={(this.props.store.player?.height > this.props.store.player?.width ? this.props.store.player?.width / 100 : this.props.store.player?.height / 100)}
                playerWidth={this.props.store.player.width}
                bottom={showFooter ? (this.props.store.player?.height > this.props.store.player?.width ? ((this.props.store.player?.width / 100 * 8)) : ((this.props.store.player?.height / 100 * 8))) : 0}
                editMode={false}
                isActive={isRssActive}
                headlineFont={this.props.store.player?.playProfile?.fonts?.headline ? JSON.parse(this.props.store.player?.playProfile?.fonts?.headline).name : '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'}
                contentFont={this.props.store.player?.playProfile?.fonts?.body ? JSON.parse(this.props.store.player?.playProfile?.fonts?.body).name : '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'}
                language={this.props.store.player?.language}
              />
            }
            {showFooter &&
              <Footer
                footer={this.props.store.player?.playProfile?.footer}
                sizeUnit={(this.props.store.player?.height > this.props.store.player?.width ? this.props.store.player?.width : this.props.store.player?.height) / 100}
                city={this.props.store.player?.city}
                currentWeather={this.props.store.weatherData?.current}
                editMode={this.props.preview}
                language={this.props.store.player?.language}
              />
            }
            <div
              style={{
                // transition: "opacity 500ms ease-in-out",
                opacity: this.props.store.loading ? 0 : 1
              }}
            >
              <div
                className={styles.IA_slidesContainer}
                style={{
                  width: this.props.store.player.width * slidesToShow.length,
                  height: this.props.store.player.height,
                  left: this.props.store.player.playlist.transitionType === "slide" ? this.props.store.currentSlideIndex * -this.props.store.player.width : 0,
                }}
              >
                {slidesComponents}
              </div>
              {this.props.store.player.width && slidesToShow.length > 1 &&
                <div
                  className={styles.IA_playlistNavigation}
                  style={{
                    top: slidesToShow[this.props.store.currentSlideIndex]?.rssTicker && slidesToShow[this.props.store.currentSlideIndex]?.rssTicker?.rssTicker?.position === "top" ? (this.props.store.player.width / 100) + (this.props.store.player.height * 0.06) : this.props.store.player.width / 140,
                    left: this.props.store.player.width / 140,
                  }}
                >
                  {playlistNavigationDots}
                </div>
              }
              {this.props.store.player.width && !this.props.preview &&
                <div
                  className={this.props.store.connected ? "pulsating-circle" : "error-circle"}
                  style={{
                    width: this.props.store.player.width / 140,
                    height: this.props.store.player.width / 140,
                    top: slidesToShow[this.props.store.currentSlideIndex]?.rssTicker && slidesToShow[this.props.store.currentSlideIndex]?.rssTicker?.rssTicker?.position === "top" ? (this.props.store.player.width / 80) + (this.props.store.player.height * 0.06) : this.props.store.player.width / 80,
                    right: this.props.store.player.width / 140,
                    opacity: 0.7,
                    zIndex: 10
                  }}
                />
              }
            </div>
          </>
        }
        {slidesToShow.length < 1 && this.props.store.player?.width &&
          <>
            <div className={styles.IA_noContent}
              style={{
                backgroundImage: `url(${this.localizationService.strings.Player_NoContentUrl})`,
                width: "100%",
                height: "100%",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <div
              className={this.props.store.connected ? "pulsating-circle" : "error-circle"}
              style={{
                width: this.props.store.player.width / 140,
                height: this.props.store.player.width / 140,
                top: this.props.store.player.width / 80,
                right: this.props.store.player.width / 140,
                opacity: 0.7,
                zIndex: 1
              }}
            />
          </>
        }
      </div>
    );
  }

  public componentWillUnmount(): void {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }
    if (this.progressBarTimer) {
      clearTimeout(this.progressBarTimer);
    }
    window.removeEventListener('resize', () => this.resize());
  }


}