import * as React from 'react';
import { LocalizationService } from '../../../../services/LocalizationService';
import { PlayHelper } from '../../../playAdmin/PlayHelper';
import { IPlayColorTheme, IPlayNewsItem, IPlayWidgetAreaType, IPlayWidgetExtended } from '../../../../interfaces/IPlay';
import { Rotator } from '../rotator/Rotator';
import { IEnvironment } from '../../../../interfaces/IEnvironment';
import * as styles from './TimeEdit.css';

export interface IProps {
  language: string;
  widget: IPlayWidgetExtended;
  areaWidth: number;
  areaHeight: number;
  headlineFont: string;
  contentFont: string;
  playerHeight: number;
  playerWidth: number;
  environment: IEnvironment;
  tenantId: string;
  profileId: string;
  editPreviewMode: boolean;
  previewColorTheme: IPlayColorTheme;
  areaType: IPlayWidgetAreaType;
  widgetContentWillRestart: () => void;
}

export interface IState {
}

export class TimeEdit extends React.Component<IProps, IState> {
  private localizationService: LocalizationService;

  constructor(props: IProps) {
    super(props);
    this.localizationService = new LocalizationService(PlayHelper.getLocalizerLanguageFromPlayerLanguage(this.props.language));
  }

  public render(): JSX.Element {
    const title = new Intl.DateTimeFormat(this.props.language, {dateStyle: "full"}).format(new Date());
    const sizeUnit = PlayHelper.getSizeUnit(this.props.areaHeight, this.props.areaWidth);
    let newsItem: IPlayNewsItem = {
      boxStyle: this.props.widget?.timeEdit?.boxStyle,
      content: "",
      title: this.props.widget?.timeEdit?.showTodaysDate ? title[0].toLocaleUpperCase() + title.slice(1) : undefined,
      showTableBorder: false,
      removeContentMarginTop: true,
      headerFontWeight: "bold",
      image: this.props.widget?.timeEdit?.backgroundImage
    }
    if (this.props.widget.timeEdit.reservations?.length > 0) {
      const table = document.createElement("table");
      table.style.tableLayout = "fixed";
      table.className = styles.IA_timeEditTable;
      const tBody = document.createElement("tbody");
      table.appendChild(tBody);

      const titleRow = document.createElement("tr");
      const firstCell = document.createElement("td");
      firstCell.style.width = `${sizeUnit * 25}px`;
      const locationTitleCell = document.createElement("td");
      locationTitleCell.style.width = `${sizeUnit * 25}px`;
      locationTitleCell.innerText = this.localizationService.strings.Player_TimeEditLocation ?? "";
      titleRow.appendChild(firstCell);
      titleRow.appendChild(document.createElement("td"));
      titleRow.appendChild(locationTitleCell);
      tBody.appendChild(titleRow);
      this.props.widget.timeEdit.reservations?.forEach((reservation) => {
        const reservationTitle = reservation.objects.find(o => o.type === "course")?.fields?.find(f => f.extId === "course.name")?.value;
        if (reservationTitle) {
          const row = document.createElement("tr");

          // time
          const startDate = PlayHelper.convertTimeEditTimeToDate(reservation.begin);
          const endDate = PlayHelper.convertTimeEditTimeToDate(reservation.end);
          const startTime = `${startDate.getHours().toString().padStart(2, "0")}:${startDate.getMinutes().toString().padStart(2, "0")}`
          const endTime = `${endDate.getHours().toString().padStart(2, "0")}:${endDate.getMinutes().toString().padStart(2, "0")}`
          const timeCell = document.createElement("td");
          timeCell.innerText = `${startTime} - ${endTime}`
          row.appendChild(timeCell);
          
          // title
          const titleCell = document.createElement("td");
          titleCell.innerText = reservationTitle;
          row.appendChild(titleCell);
          
          // location
          const location = reservation.objects.find(o => o.type === "location")?.fields?.[0].value;
          if (location) {
            const locationCell = document.createElement("td");
            locationCell.innerText = location;
            row.appendChild(locationCell);
          }

          tBody.appendChild(row);
        }
      });
      newsItem.content = table.outerHTML;
    } else if (this.props.widget.timeEdit.searchObjects?.length === 0){
      newsItem.content = this.localizationService.strings.PlayAdmin_TimeEditNoFiltersAdded;
      newsItem.title = undefined;
    } else {
      newsItem.image = this.props.widget.timeEdit.noContentImageUrl !== "" ? this.props.widget.timeEdit.noContentImageUrl : this.localizationService.strings.Player_NoContentUrl;
      newsItem.boxStyle = "onlyImage";
    }
    return <Rotator
      enableScroll={true}
      widgetType="timeEdit"
      newsItems={[newsItem]}
      widgetId={this.props.widget?.id}
      width={this.props.areaWidth}
      height={this.props.areaHeight}
      headlineFont={this.props.headlineFont}
      contentFont={this.props.contentFont}
      playerHeight={this.props.playerHeight}
      playerWidth={this.props.playerWidth}
      environment={this.props.environment}
      tenantId={this.props.tenantId}
      profileId={this.props.profileId}
      duration={this.props.widget.timeEdit.duration}
      transition={"slide"}
      editPreviewMode={this.props.editPreviewMode}
      colorThemes={this.props.previewColorTheme ? [this.props.previewColorTheme] : this.props.widget.timeEdit.colorThemes}
      areaType={this.props.areaType}
      showTitle={true}
      showText={true}
      showDate={false}
      showImage={true}
      showProgressBar={false}
      language={this.props.language}
      widgetTitleIsShowing={this.props.widget?.showTitle}
      headerTitle={this.props.widget?.headerTitle}
      headerSubtitle={""}
      headerImage={""}
      boxStyle={"fullSolid"}
      willRestart={() => {
        this.props.widgetContentWillRestart();
      }}
    />;

    return <></>;
  }
}