import * as React from 'react';
import { Store } from '../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './GettingStarted.css';
import { LocalizationService } from '../../../../services/LocalizationService';
import { IAHeader } from '../../../header/Header';
import { IACard } from '../../../card/Card';
import { IACardViewService } from '../../../messagesCardView/CardView.service';
import { Helper } from '../../../../Helper';
import { PlayHelper } from '../../PlayHelper';
import { IPlayWidgetType } from '../../../../interfaces/IPlay';
import { Popup } from '../../reusableComponents/popup/Popup';

export interface IProps {
  store?: Store;
}

export interface IState {
  videoToShow?: any;
}

@inject('store')
@observer
export class GettingStarted extends React.Component<IProps, IState> {

  private cardViewService: IACardViewService = new IACardViewService();
  private readonly localizationService: LocalizationService = new LocalizationService();
  private gettingStaredVideos;

  constructor(props: IProps) {
    super(props);
    this.state = {
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
    this.gettingStaredVideos = [
      {
        videoUrl: this.localizationService.strings.PlayAdmin_GettingStartedPlayersVideoUrl,
        posterUrl: this.localizationService.strings.PlayAdmin_GettingStartedPlayersPosterUrl,
        title: this.localizationService.strings.PlayAdmin_GettingStartedPlayersTitle,
        duration: "0:56"
      },
      {
        videoUrl: this.localizationService.strings.PlayAdmin_GettingStartedPlaylistsVideoUrl,
        posterUrl: this.localizationService.strings.PlayAdmin_GettingStartedPlaylistsPosterUrl,
        title: this.localizationService.strings.PlayAdmin_GettingStartedPlaylistsTitle,
        duration: "0:56" 
      }, {
        videoUrl: this.localizationService.strings.PlayAdmin_GettingStartedSlidesVideoUrl,
        posterUrl: this.localizationService.strings.PlayAdmin_GettingStartedSlidesPosterUrl,
        title: this.localizationService.strings.PlayAdmin_GettingStartedSlidesTitle,
        duration: "1:34"
      }
    ];
  }

  public render(): JSX.Element {
    const cards = [];
    // Resizing cards
    const cardMarginRight = 20;
    const [numberOfCardsPerRow, maxCardWidthAvailable] = this.cardViewService.getNumberOfCardsPerRowAndMaxCardWidthAvailable(this.props.store.contentWidth, cardMarginRight, 250, 5);
    let currentRowNumber = 1;
    this.gettingStaredVideos.forEach((gettingStaredVideo: any, index) => {
      cards.push(
        <IACard
          key={`player_${index}`}
          id={`GettingStaredVideo_${index}`}
          title={gettingStaredVideo.title}
          darkMode={this.props.store.darkMode}
          cover={{
            imageURL: gettingStaredVideo.posterUrl
          }}
          width={window.innerWidth < 667 ? window.innerWidth - 56 : maxCardWidthAvailable}
          isMobile={this.props.store.isMobile}
          styles={{
            marginRight: currentRowNumber == numberOfCardsPerRow ? 0 : cardMarginRight,
            marginBottom: 20,
            width: window.innerWidth < 667 ? "100%" : maxCardWidthAvailable,
            color: this.props.store.darkMode ? "#ffffff" : "#333333"
          }}
          design={{
            highlightColor: this.props.store.highlightColor,
            showDropShadow: true,
            borderRadius: 5,
            backgroundColor: this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5",
            hoverBackgroundColor: this.props.store.darkMode ? "#393939" : "#e6e6e6"
          }}
          labels={[
            {
              text: PlayHelper.getTitleForWidgetType("video", this.localizationService),
              image: `https://intraactivestorage.blob.core.windows.net/cdn/play/widgets/${PlayHelper.getIconForWidgetType("video" as IPlayWidgetType)}.png`,
              backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
              textColor: this.props.store.darkMode ? "#ffffff" : "#333333",
              borderRadius: 5
            },{
              icon: "Clock",
              text: gettingStaredVideo.duration,
              backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
              textColor: this.props.store.darkMode ? "#ffffff" : "#333333",
              borderRadius: 5
            }
          ]}
          onClickOpen={() => {
            this.setState({ videoToShow: gettingStaredVideo });
          }}
          footerElements={[
          ]}
        />
      );
      if (currentRowNumber == numberOfCardsPerRow) {
        currentRowNumber = 1;
      } else {
        currentRowNumber++;
      }
    });
    return (
      <div className={styles.IA_gettingStated}>
        <IAHeader
          id="play-gettingStarted-header"
          title={this.localizationService.strings.PlayAdmin_GettingStarted}
          expandButtonColor={this.props.store.highlightColor}
          navigationButtonColor="#91bbcd"
          navigationButtonIconColor="#ffffff"
          isMobile={this.props.store.isMobile}
          darkMode={this.props.store.darkMode}
          slides={[
            {
              backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-bg-gray.jpg",
              headline: this.localizationService.strings.PlayAdmin_GettingStarted,
              text: this.localizationService.strings.PlayAdmin_GettingStartedHeaderText,
              image: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-illustration-settings.png"
            }
          ]}
        />
        <div>
          {cards}
        </div>
        {this.state.videoToShow &&
          <Popup
            headline={this.state.videoToShow.title}
            close={() => this.setState({ videoToShow: undefined })}
            darkMode={this.props.store.darkMode}
            style={{
              width: "80%",
              top: "10%",
              height: "80%"
            }}
            content={
              <div
                style={{
                  height: "calc(100% - 100px)"
                }}
              >
                <video
                  controls
                  width="100%"
                  height="100%"
                  poster={this.state.videoToShow.posterUrl}
                  style={{
                    backgroundColor: this.props.store.highlightColor
                  }}
                >
                  <source src={this.state.videoToShow.videoUrl} type="video/mp4" />
                </video>
              </div>
            }
          />
        }
      </div>
    );
  }

  
}