import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as styles from './ConnectingNewPlayerPopup.css';
import { Store } from '../../../stores';
import { IAIcon } from '../../../../icon/Icon';
import PlayService from '../../../../../services/PlayService';
import { IPlayer } from '../../../../../interfaces/IPlay';
import { IAButton } from '../../../../button/Button';
import { IASpinner } from '../../../../spinner/Spinner';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { ConnectingNewPlayerInput } from './ConnectingNewPlayerInput';

export interface IProps {
  store?: Store;
  close: () => void;
  connectNow: (playerId: string) => void;
}

export interface IState {
  displayCode: string;
  player: IPlayer;
  loading: boolean;
}

@inject('store')
@observer
export class ConnectingNewPlayerPopup extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      displayCode: "XXXX",
      player: undefined,
      loading: false
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentDidMount(): void {
    document.getElementById("input1").focus();
  }

  private getPlayers(): void {
    PlayService.getPlayers(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id).then(players => {
      this.props.store.players = players.value;
      this.props.store.playersCount = players.count;
      let newPlayer;
      this.props.store.players.forEach((player: IPlayer) => {
        if (player.displayCode === parseInt(this.state.displayCode)) {
          newPlayer = player;
        }
      });
      if (newPlayer) {
        this.setState({ player: newPlayer });
      } else {
        if (this.props.store.isShowingConnectNewPlayerPopUp) {
          setTimeout(() => {
            this.getPlayers();
          }, 1000);
        }
      }
    });
  }

  private connectPlayer(): void {
    setTimeout(() => {
      if (this.state.displayCode.indexOf("X") == -1) {
        this.setState({ loading: true });
        PlayService.updatePendingPlayer(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, parseInt(this.state.displayCode)).then((player: IPlayer) => {
          if (player) {
            this.getPlayers();
          } else {
            this.setState({ loading: false }, () => document.getElementById("input1").focus());
          }
        });
      }
    }, 0);
  }

  public render(): JSX.Element {
    return (
      <>
        {this.state.player ?
          <div
            className={styles.IA_connectingNewPlayerPopup}
          >
            <div
              className={styles.IA_background}
              style={{
                backgroundColor: this.props.store.darkMode ? "#00000080" : "#ffffff80"
              }}
              onClick={() => {
                if (this.props.close) {
                  this.props.close();
                }
              }}
            />
            <div className={styles.IA_contentBox}>
              {this.props.close != undefined &&
                <IAIcon
                  title={"Close"}
                  size={18}
                  color={"#ffffff"}
                  style={{
                    float: "right",
                    width: 24,
                    height: 24,
                    lineHeight: "18px",
                    padding: 2,
                    boxSizing: "border-box"
                  }}
                  onClick={() => this.props.close()}
                />
              }
              <div className={styles.IA_illustration} />
              <div className={styles.IA_text}>
                {this.localizationService.strings.PlayAdmin_ConnectingPlayerText2}
              </div>
              <div className={styles.IA_buttons}>
                <div className={styles.IA_button}>
                  <IAButton
                    label={this.localizationService.strings.PlayAdmin_SetupNow?.toUpperCase()}
                    borderColor={"#ffffff"}
                    buttonColor="transparent"
                    textColor={"#ffffff"}
                    darkMode={this.props.store.darkMode}
                    onClick={() => {
                      this.props.connectNow(this.state.player?.id);
                    }}
                    borderRadius={5}
                    style={{
                      float: "right",
                      position: "relative",
                      marginTop: 7,
                      marginRight: 10,
                      marginLeft: this.props.store.isMobile ? "3%" : undefined
                    }}
                  />
                </div>
                <div className={styles.IA_button}>
                  <IAButton
                    label={this.localizationService.strings.PlayAdmin_DoItLater?.toUpperCase()}
                    borderColor={"#ffffff"}
                    buttonColor="transparent"
                    textColor={"#ffffff"}
                    darkMode={this.props.store.darkMode}
                    onClick={() => this.props.close()}
                    borderRadius={5}
                    style={{
                      float: "left",
                      position: "relative",
                      marginTop: 7,
                      marginLeft: 10
                    }}
                  />
                </div>
              </div>
              <div
                className={styles.IA_text}
                style={{
                  marginTop: 20
                }}
              >
                2 / 2
              </div>
            </div>
          </div>
          :
          <div
            className={styles.IA_connectingNewPlayerPopup}
          >
            <div
              className={styles.IA_background}
              style={{
                backgroundColor: this.props.store.darkMode ? "#00000080" : "#ffffff80"
              }}
              onClick={() => {
                if (this.props.close) {
                  this.props.close();
                }
              }}
            />
            <div className={styles.IA_contentBox}>
              {this.props.close != undefined &&
                <IAIcon
                  title={"Close"}
                  size={18}
                  color={"#ffffff"}
                  style={{
                    float: "right",
                    borderRadius: 5,
                    width: 18,
                    height: 18,
                    lineHeight: "18px",
                    padding: 2
                  }}
                  onClick={() => this.props.close()}
                />
              }
              <div className={styles.IA_illustration} />
              <div className={styles.IA_text}>
                {this.localizationService.strings.PlayAdmin_ConnectingPlayerText1}
              </div>
              {this.state.loading ?
                <div className={styles.IA_displayCode}>
                  <IASpinner
                    color={"#ffffff"}
                    style={{
                      marginLeft: this.props.store.isMobile ? "calc(50% - 40px)" : "calc(50% - 25px)"
                    }}
                  />
                </div>
                :
                <div className={styles.IA_displayCode}>
                  <ConnectingNewPlayerInput
                    id="input1"
                    nextId="input2"
                    onChange={value => {
                      this.setState({ displayCode: this.state.displayCode.substring(0, 0) + value + this.state.displayCode.substring(0 + 1) });
                    }}
                  />
                  <ConnectingNewPlayerInput
                    id="input2"
                    prevId="input1"
                    nextId="input3"
                    onChange={value => {
                      this.setState({ displayCode: this.state.displayCode.substring(0, 1) + value + this.state.displayCode.substring(1 + 1) });
                    }}
                  />
                  <ConnectingNewPlayerInput
                    id="input3"
                    prevId="input2"
                    nextId="input4"
                    onChange={value => {
                      this.setState({ displayCode: this.state.displayCode.substring(0, 2) + value + this.state.displayCode.substring(2 + 1) });
                    }}
                  />
                  <ConnectingNewPlayerInput
                    id="input4"
                    prevId="input3"
                    onChange={value => {
                      this.setState({ displayCode: this.state.displayCode.substring(3, 0) + value + this.state.displayCode.substring(3 + 1) });
                      document.getElementById("input4").blur();
                      this.connectPlayer();
                    }}
                  />
                </div>
              }
              <div
                className={styles.IA_text}
                style={{
                  marginTop: 20
                }}
              >
                1 / 2
              </div>
            </div>
          </div>
        }
      </>
    );
  }

  
}