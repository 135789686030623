import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as styles from './CompanyInformation.css';
import { Store } from '../../../stores';
import { IATextField } from '../../../../textField/TextField';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IAButton } from '../../../../button/Button';
import PlayService from '../../../../../services/PlayService';
import { Helper } from '../../../../../Helper';
import { IADropDown } from '../../../../dropDown/DropDown';

export interface IProps {
  showButtonAtTheBottom?: boolean;
  store?: Store;
  onSaved: () => void;
}

export interface IState {
  loading: boolean;
}

@inject('store')
@observer
export class CompanyInformationForm extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  private getLicense(): void {
    PlayService.getSubscriptions(this.props.store.environment, this.props.store.token, this.props.store.tenantId).then((subscriptions) => {
      if (subscriptions?.length > 0) {
        PlayService.getSubscription(this.props.store.environment, this.props.store.token, this.props.store.tenantId, subscriptions[0].subscriptionId).then((subscription: any) => {
          if (subscription && subscription.addOns?.length > 0) {
            this.props.store.subscription = subscription;
            this.props.store.licensedNumberOfPlayers = subscription.addOns[0].quantity + 1;
            this.props.store.hasDiscount = subscription.discounts?.length > 0
          }
          this.setState({ loading: false });
          this.props.onSaved();
        });
      } else {
        this.setState({ loading: false });
          this.props.onSaved();
      }
    });
  }

  private save(): void {
    this.setState({ loading: true });
    if (this.formIsValid()) {
      if (this.props.store.companyInformation.tenantId) {
        PlayService.updateCustomer(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.companyInformation).then(() => {
          this.getLicense();
        });
      } else {
        this.props.store.companyInformation.tenantId = this.props.store.tenantId;
        PlayService.createCustomer(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.companyInformation).then(() => {
          this.getLicense();
        });
      }
    } else {
      this.setState({ loading: false });
    }
  }

  private formIsValid(): boolean {
    if (this.props.store.companyInformation &&
      this.props.store.companyInformation.company &&
      this.props.store.companyInformation.address &&
      this.props.store.companyInformation.city &&
      this.props.store.companyInformation.postalCode &&
      this.props.store.companyInformation.vat &&
      this.props.store.companyInformation.firstName &&
      this.props.store.companyInformation.lastName &&
      this.props.store.companyInformation.email
    ) {
      if (!this.props.store.companyInformation.country) {
        this.props.store.companyInformation.country = "DK";
      }
      return true;
    } else {
      return false;
    }
  }

  public render(): JSX.Element {
    return (
      <div className={styles.IA_companyInformation}>
        {!this.props.showButtonAtTheBottom &&
          <IAButton
            label={this.localizationService.strings.PlayAdmin_Update?.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onClick={() => this.save()}
            borderRadius={5}
            disbaled={!this.formIsValid()}
            showSpinner={this.state.loading}
            style={{
              float: "right",
              position: "relative",
              marginTop: 20
            }}
          />
        }
        <IATextField
          label={this.localizationService.strings.PlayAdmin_CompanyName}
          text={this.props.store.companyInformation?.company}
          required
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            textTransform: "uppercase",
            fontSize: 12
          }}
          onChange={(company: string) => {
            if (this.props.store.companyInformation == undefined) {
              this.props.store.companyInformation = {};
            }
            this.props.store.companyInformation.company = company;
            this.forceUpdate();
          }}
        />
        <IATextField
          label={this.localizationService.strings.PlayAdmin_Address}
          text={this.props.store.companyInformation?.address}
          required
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            textTransform: "uppercase",
            fontSize: 12
          }}
          onChange={(address: string) => {
            if (this.props.store.companyInformation == undefined) {
              this.props.store.companyInformation = {};
            }
            this.props.store.companyInformation.address = address;
            this.forceUpdate();
          }}
        />
        <IATextField
          label={this.localizationService.strings.PlayAdmin_City}
          text={this.props.store.companyInformation?.city}
          required
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            textTransform: "uppercase",
            fontSize: 12
          }}
          onChange={(city: string) => {
            if (this.props.store.companyInformation == undefined) {
              this.props.store.companyInformation = {};
            }
            this.props.store.companyInformation.city = city;
            this.forceUpdate();
          }}
        />
        <IATextField
          label={this.localizationService.strings.PlayAdmin_PostalCode}
          text={this.props.store.companyInformation?.postalCode}
          required
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            textTransform: "uppercase",
            fontSize: 12
          }}
          onChange={(postalCode: string) => {
            if (this.props.store.companyInformation == undefined) {
              this.props.store.companyInformation = {};
            }
            this.props.store.companyInformation.postalCode = postalCode;
            this.forceUpdate();
          }}
        />
        <IADropDown
          label={this.localizationService.strings.PlayAdmin_Country?.toUpperCase()}
          required
          selectedOption={this.props.store.companyInformation?.country ? this.props.store.companyInformation?.country : "DK"}
          options={Helper.getCountryCodes()}
          borderRadius={5}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          style={{
            marginTop: 20,
            float: "left",
            width: "100%"
          }}
          onChange={(country: string) => {
            if (this.props.store.companyInformation == undefined) {
              this.props.store.companyInformation = {};
            }
            this.props.store.companyInformation.country = country;
            this.forceUpdate();
          }}
        />
        <IATextField
          label={this.localizationService.strings.PlayAdmin_VatNumber}
          text={this.props.store.companyInformation?.vat}
          required
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            textTransform: "uppercase",
            fontSize: 12
          }}
          onChange={(vat: string) => {
            if (this.props.store.companyInformation == undefined) {
              this.props.store.companyInformation = {};
            }
            this.props.store.companyInformation.vat = vat;
            this.forceUpdate();
          }}
        />

        <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
        <h2>{this.localizationService.strings.PlayAdmin_ContactPerson}</h2>
        <IATextField
          label={this.localizationService.strings.PlayAdmin_FirstName}
          text={this.props.store.companyInformation?.firstName}
          required
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            textTransform: "uppercase",
            fontSize: 12
          }}
          onChange={(firstName: string) => {
            if (this.props.store.companyInformation == undefined) {
              this.props.store.companyInformation = {};
            }
            this.props.store.companyInformation.firstName = firstName;
            this.forceUpdate();
          }}
        />
        <IATextField
          label={this.localizationService.strings.PlayAdmin_LastName}
          text={this.props.store.companyInformation?.lastName}
          required
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            textTransform: "uppercase",
            fontSize: 12
          }}
          onChange={(lastName: string) => {
            if (this.props.store.companyInformation == undefined) {
              this.props.store.companyInformation = {};
            }
            this.props.store.companyInformation.lastName = lastName;
            this.forceUpdate();
          }}
        />
        <IATextField
          label={this.localizationService.strings.PlayAdmin_Email}
          text={this.props.store.companyInformation?.email}
          required
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            textTransform: "uppercase",
            fontSize: 12
          }}
          onChange={(email: string) => {
            if (this.props.store.companyInformation == undefined) {
              this.props.store.companyInformation = {};
            }
            this.props.store.companyInformation.email = email;
            this.forceUpdate();
          }}
        />
        {this.props.showButtonAtTheBottom &&
          <IAButton
            label={this.localizationService.strings.PlayAdmin_Update?.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onClick={() => this.save()}
            borderRadius={5}
            disbaled={!this.formIsValid()}
            showSpinner={this.state.loading}
            style={{
              float: "left",
              position: "relative",
              marginTop: 20
            }}
          />
        }
      </div>
    );
  }


}