import * as React from 'react';
import { Store } from '../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './Slides.css';
import { IACard } from '../../../card/Card';
import { IACardViewService } from '../../../messagesCardView/CardView.service';
import { LocalizationService } from '../../../../services/LocalizationService';
import { Helper } from '../../../../Helper';
import { IListElement, IListElementColumn, IPlaySlide } from '../../../../interfaces/IPlay';
import { IASpinner } from '../../../spinner/Spinner';
import { PlayHelper } from '../../PlayHelper';
import { IAIconDropDown } from '../../../iconDropDown/IconDropDown';
import { IIconDropDown } from '../../../../interfaces/IIconDropDown';
import { IAButton } from '../../../button/Button';
import { IAListView } from '../../../listView/ListView';
import { IATextField } from '../../../textField/TextField';
import Moment from 'react-moment';
import { ILabel } from '../../../../interfaces/ILabel';

export interface IProps {
  store?: Store;
  isLoading: boolean;
  getSlides: (searchQuery: string) => void;
  nextPage: () => void;
  elementActions?: {
    action: (id?: string) => void,
    actionName: string,
    icon: string,
    color: string
  }[];
}

export interface IState {
  selectedUserSorting?: IIconDropDown;
  searchQuery: string;
}

@inject('store')
@observer
export class SlidesContent extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();
  private cardViewService: IACardViewService = new IACardViewService();
  private queryDelay: NodeJS.Timeout;

  constructor(props: IProps) {
    super(props);
    this.state = {
      searchQuery: ""
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public render(): JSX.Element {
    const cards: JSX.Element[] = [];
    const listElements: IListElement[] = [];
    const showCards = localStorage.getItem("IA_slidesViewType") && JSON.parse(localStorage.getItem("IA_slidesViewType")).value === "card" && !this.props.store.isMobile;
    // Resizing cards
    const [numberOfCardsPerRow, maxCardWidthAvailable] = this.cardViewService.getNumberOfCardsPerRowAndMaxCardWidthAvailable(this.props.store.contentWidth, 20, 250, 5);
    let currentRowNumber = 1;
    if (this.props.store.slides && this.props.store.slides.length > 0) {
      this.props.store.slides.forEach((slide: IPlaySlide) => {
        let labels: ILabel[] = [];
        let statusColor;
        let statusIconUrl;
        let statusText;
        let backgroundColor;
        let timespand;
        if (slide.endDate) {
          timespand = (
            <span style={{ paddingLeft: showCards ? 5 : 0 }}>
              <Moment
                format="L LT"
                locale={navigator.language}
              >
                {slide.startDate}
              </Moment>
              <span> - </span>
              <Moment
                format="L LT"
                locale={navigator.language}
              >
                {slide.endDate}
              </Moment>
            </span>
          );
        } else {
          timespand = (
            <span style={{ paddingLeft: showCards ? 5 : 0 }}>
              <Moment
                format="L LT"
                locale={navigator.language}
              >
                {slide.startDate}
              </Moment>
            </span>
          );
        }
        if (slide.startDate) {
          switch (Helper.getState(slide.startDate, slide.endDate)) {
            case "active":
              statusColor = "#6ea63f";
              statusIconUrl = "https://intraactivestorage.blob.core.windows.net/cdn/icons/Checkmark%20Circle/SVG/ic_fluent_checkmark_circle_48_filled.svg";
              backgroundColor = "transparent";
              statusText = this.localizationService.strings.ContentCreator_Active;
              break;
            case "scheduled":
              statusColor = this.props.store.highlightColor;
              statusIconUrl = "https://intraactivestorage.blob.core.windows.net/cdn/icons/Clock/SVG/ic_fluent_clock_48_filled.svg";
              backgroundColor = "transparent";
              statusText = this.localizationService.strings.ContentCreator_Scheduled;
              break;
            case "expired":
              statusColor = "gray";
              statusIconUrl = "https://intraactivestorage.blob.core.windows.net/cdn/icons/Clock/SVG/ic_fluent_clock_48_filled.svg";
              backgroundColor = "transparent";
              statusText = this.localizationService.strings.ContentCreator_Expired;
              break;
          }
          labels.push(
            {
              tooltip: statusText,
              iconUrl: statusIconUrl,
              iconColor: statusColor,
              backgroundColor: backgroundColor,
              textColor: this.props.store.darkMode ? "#ffffff" : "#777777",
              borderRadius: 5,
              text: timespand,
              textSize: 11,
            }
          );
        }
        const columns: IListElementColumn[] = this.props.store.isMobile ?
          [
            {
              header: this.localizationService.strings.PlayAdmin_Title?.toUpperCase(),
              type: "text",
              text: slide.title,
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            },
            {
              header: this.localizationService.strings.PlayAdmin_PlayerStatus?.toUpperCase(),
              type: "icon",
              iconUrl: statusIconUrl,
              tooltip: statusText,
              color: statusColor,
              iconSize: 18,
              marginTop: 16
            },
            {
              header: this.localizationService.strings.PlayAdmin_SlideLayout?.toUpperCase(),
              type: "icon",
              icon: PlayHelper.getIconForSlideLayout(slide.layout),
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }
          ]
          :
          [
            {
              header: this.localizationService.strings.PlayAdmin_Title?.toUpperCase(),
              type: "text",
              text: slide.title,
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            },
            {
              header: this.localizationService.strings.PlayAdmin_PlayerStatus?.toUpperCase(),
              type: "icon",
              iconUrl: statusIconUrl,
              tooltip: statusText,
              color: statusColor,
              iconSize: 18,
              marginTop: 16
            },
            {
              header: this.localizationService.strings.PlayAdmin_Scheduled.toUpperCase(),
              type: "text",
              text: timespand,
              color: this.props.store.darkMode ? Helper.darkModeLabelColor : "#777777"
            },
            {
              header: this.localizationService.strings.PlayAdmin_SlideLayout?.toUpperCase(),
              type: "icon",
              icon: PlayHelper.getIconForSlideLayout(slide.layout),
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            },
            {
              header: this.localizationService.strings.PlayAdmin_Modified?.toUpperCase(),
              type: "date",
              date: slide.modified,
              color: this.props.store.darkMode ? Helper.darkModeLabelColor : "#777777"
            },
            {
              header: this.localizationService.strings.PlayAdmin_ModifiedBy?.toUpperCase(),
              type: "text",
              text: slide.modifiedBy?.displayName,
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }
          ];
        listElements.push(
          {
            id: slide.id,
            columns: columns,
            buttons: this.props.elementActions?.map(element => {
              return {
                action: element.actionName,
                color: element.color,
                icon: element.icon
              };
            })
          }
        );
        cards.push(
          <IACard
            key={`player_${slide.id}`}
            id={slide.id}
            showSkeleton={false}
            title={slide.title}
            author={slide.modifiedBy?.displayName}
            darkMode={this.props.store.darkMode}
            cover={{
              imageURL: this.props.store.darkMode ? "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/default-dark.jpg" : "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/default.jpg"
            }}
            labels={labels}
            width={window.innerWidth < 667 ? window.innerWidth - 56 : maxCardWidthAvailable}
            isMobile={this.props.store.isMobile}
            styles={{
              marginRight: currentRowNumber == numberOfCardsPerRow ? 0 : 20,
              marginBottom: 20,
              width: window.innerWidth < 667 ? "100%" : maxCardWidthAvailable,
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
            design={{
              highlightColor: this.props.store.highlightColor,
              showDropShadow: true,
              borderRadius: 5,
              backgroundColor: this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5",
              hoverBackgroundColor: this.props.store.darkMode ? "#393939" : "#e6e6e6"
            }}
            onClickOpen={() => {
              this.props.elementActions?.map(element => {
                if (element.actionName === "edit") {
                  element.action(slide.id);
                }
              })
            }}
            alwaysShowFooter
            footerElements={this.props.elementActions?.map(element => {
              return {
                color: element.color,
                icon: element.icon,
                onClick: () => element.action(slide.id)
              };
            })}
          />
        );

        if (currentRowNumber == numberOfCardsPerRow) {
          currentRowNumber = 1;
        } else {
          currentRowNumber++;
        }
      })
    }
    return (
      <>
        {!this.props.store.isMobile &&
          <div className={styles.IA_filters}>
            {/* <IAIconDropDown
              options={PlayHelper.getSortingOptions(this.localizationService)}
              selectedOption={localStorage.getItem("IA_slidesSortingOption") ? JSON.parse(localStorage.getItem("IA_slidesSortingOption")) : PlayHelper.getSortingOptions(this.localizationService)[0]}
              // label={this.localizationService.strings.PlayAdmin_Sorting}
              darkMode={this.props.store.darkMode}
              onChange={(widgetsViewType: IIconDropDown) => {
                Helper.setLocalStorage("IA_slidesSortingOption", JSON.stringify(widgetsViewType));
                this.forceUpdate();
              }}
              disabled
              style={{
                minWidth: 320,
                float: "left",
                marginRight: 20,
                marginBottom: 20,
                opacity: 0.5
              }}
            />
            <IAIconDropDown
              options={PlayHelper.getUserSortingOptions(this.localizationService)}
              selectedOption={this.state.selectedUserSorting}
              darkMode={this.props.store.darkMode}
              // label={this.localizationService.strings.PlayAdmin_UserSorting}
              placeholder={this.localizationService.strings.PlayAdmin_UserSortingPlaceholder}
              clearable
              disabled
              onChange={(selectedUserSorting: IIconDropDown) => this.setState({ selectedUserSorting }, () => this.props.getSlides())}
              style={{
                minWidth: 170,
                float: "left",
                marginRight: 20,
                marginBottom: 20,
                opacity: 0.5
              }}
            /> */}
            <IATextField
              style={{
                width: 330,
                marginRight: 20,
                marginTop: 0
              }}
              inputFieldStyle={{
                marginTop: 0,
                height: 38,
                borderColor: "#cccccc",
                fontSize: 15
              }}
              text={this.state.searchQuery}
              placeholder={this.localizationService.strings.PlayAdmin_SearchSlides}
              borderRadius={5}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              onChange={(search: string) => {
                this.setState({
                  searchQuery: search
                })
                clearTimeout(this.queryDelay);
                this.queryDelay = setTimeout(() => {
                  this.props.getSlides(this.state.searchQuery);
                }, 500);
              }}
            />
            <IAIconDropDown
              options={PlayHelper.getViewTypes()}
              selectedOption={localStorage.getItem("IA_slidesViewType") ? JSON.parse(localStorage.getItem("IA_slidesViewType")) : PlayHelper.getViewTypes()[0]}
              // label={this.localizationService.strings.PlayAdmin_ViewType}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              onChange={(widgetsViewType: IIconDropDown) => {
                Helper.setLocalStorage("IA_slidesViewType", JSON.stringify(widgetsViewType));
                this.forceUpdate();
              }}
              style={{
                minWidth: 70,
                marginBottom: 20,
                marginLeft: "auto"
              }}
            />
          </div>
        }
        <>
          {showCards ?
            cards
            :
            <IAListView
              id="ListViewOfSlides"
              listElements={listElements}
              backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
              hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
              buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
              darkMode={this.props.store.darkMode}
              defaultAction={"edit"}
              selectedAction={(action: string, id: string) => {
                this.props.elementActions?.forEach((element) => {
                  if (element.actionName === action) {
                    element.action(id);
                  }
                });
              }}
            />
          }
          {!!this.props.nextPage && !this.props.isLoading &&
            <div className={styles.IA_paginationButtonContainer}>
              <IAButton
                onClick={() => this.props.nextPage()}
                darkMode={this.props.store.darkMode}
                buttonColor={this.props.store.highlightColor}
                textColor="#ffffff"
                borderRadius={5}
                label={this.localizationService.strings.PlayAdmin_LoadMore?.toUpperCase()}
              />
            </div>
          }
          {this.props.isLoading &&
            <IASpinner
              color={this.props.store.highlightColor}
              style={{
                float: "left",
                margin: "20px 0 0 calc(50% - 20px)"
              }}
            />
          }
        </>
      </>
    );
  }


}