exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_design_3_G-mdz7cQaQ4V8krsi7ji {\n  width: 100%;\n}\n\n.IA_divider_ZOB5D7C0wc2pKoXClpZKL, .IA_dividerDarkMode_3zT_GyBdZlmn09u2_cdoXK {\n  height: 1px;\n  width: 100%;\n  background-color: #eeeeee;\n  margin-top: 40px;\n  margin-bottom: 20px;\n  float: left;\n}\n\n.IA_dividerDarkMode_3zT_GyBdZlmn09u2_cdoXK {\n  background-color: #a8a8a8;\n}\n\n.IA_widgetType_3V6QRRSO-Inix1QVB1D_8m {\n  margin-bottom: 15px;\n  width: 75px;\n  cursor: pointer;\n}\n\n.IA_widgetTypeLabel_VDcjlWH2bD1VVfa9DzefV {\n  font-size: 12px;\n  float: left;\n  clear: both;\n  width: 90%;\n  margin-top: 2px;\n}\n\n.IA_swatches_2k2QDMSkOj2yAlhoQ1AQFB {\n  width: 100%;\n  clear: both;\n  position: relative;\n  float: left;\n}\n\n.IA_fontPreview_3UY4fkDy25sf8jFCX_xyl4 {\n  width: 100%;\n  padding: 40px;\n  float: left;\n  box-sizing: border-box;\n  border-radius: 20px;\n  margin-bottom: 20px;\n}\n\n.IA_fontPreviewLabel_2HliwtTSfbFYKdSCYD4E2 {\n  font-size: 12px;\n  width: 100%;\n  margin-top: -20px;\n  margin-bottom: 20px;\n  opacity: 0.6;\n}\n\n.IA_fontPreviewHeadline_1djguBzSK4lzYZJ_OZOJ6F {\n  font-size: 30px;\n  width: 100%;\n}\n\n.IA_fontPreviewContent_3CLoQVyB6YCN8zBJ6CyUWe {\n  font-size: 18px;\n  line-height: 26px;\n  width: 100%;\n  margin-top: 20px;\n}", ""]);

// Exports
exports.locals = {
	"IA_design": "IA_design_3_G-mdz7cQaQ4V8krsi7ji",
	"IA_divider": "IA_divider_ZOB5D7C0wc2pKoXClpZKL",
	"IA_dividerDarkMode": "IA_dividerDarkMode_3zT_GyBdZlmn09u2_cdoXK",
	"IA_widgetType": "IA_widgetType_3V6QRRSO-Inix1QVB1D_8m",
	"IA_widgetTypeLabel": "IA_widgetTypeLabel_VDcjlWH2bD1VVfa9DzefV",
	"IA_swatches": "IA_swatches_2k2QDMSkOj2yAlhoQ1AQFB",
	"IA_fontPreview": "IA_fontPreview_3UY4fkDy25sf8jFCX_xyl4",
	"IA_fontPreviewLabel": "IA_fontPreviewLabel_2HliwtTSfbFYKdSCYD4E2",
	"IA_fontPreviewHeadline": "IA_fontPreviewHeadline_1djguBzSK4lzYZJ_OZOJ6F",
	"IA_fontPreviewContent": "IA_fontPreviewContent_3CLoQVyB6YCN8zBJ6CyUWe"
};