import * as React from 'react';
import { Store } from './stores';
import { inject, observer } from 'mobx-react';
import * as styles from './PlayAdmin.css';
import { Dashboard } from './dashboard/Dashboard';
import { LocalizationService } from '../../services/LocalizationService';
import PlayService from '../../services/PlayService';
import { IPlayProfile, IPlayUser } from '../../interfaces/IPlay';
import { Helper } from '../../Helper';
import { IASpinner } from '../spinner/Spinner';
import { IAButton } from '../button/Button';
import { AccountSettings } from './accountSettings/AccountSettings';
import { SettingsPanel } from './dashboard/settingsPanel/SettingsPanel';
import { ErrorPopup } from './dashboard/errorPopup/ErrorPopup';
import { Popup } from './reusableComponents/popup/Popup';

export interface IProps {
  tenantId: string;
  token: string;
  store?: Store;
  authenticate?: () => void;
}

export interface IState {
  isShowingMissingPermissionsAlert: boolean;
  availableProfilesItems: { key: string, value: string, disabled?: boolean }[];
  isLoadingProfiles: boolean;
}

@inject('store')
@observer
export class PlayAdmin extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      isShowingMissingPermissionsAlert: false,
      availableProfilesItems: undefined,
      isLoadingProfiles: false
    }
    if (this.props.store.isMobile) {
      Helper.setLocalStorage("intraactive-play-menu-item", "players");
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentDidMount() {
    // Use geolocation build in API to get coordinates (require user consent)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const lat = position.coords.latitude.toString();
        const lon = position.coords.longitude.toString();
        PlayService.getWeatherForecast(this.props.store.environment, lat, lon).then((weatherData) => {
          this.props.store.weatherData = weatherData;
        });
        PlayService.getCityName(this.props.store.environment, lat, lon).then((cityName) => {
          this.props.store.city = cityName;
        });
      }, () => {
        this.props.store.setHardcodedWeather();
      });
    } else {
      this.props.store.setHardcodedWeather();
    }
    if (this.props.tenantId && this.props.token && this.props.store.consentIsGiven) {
      this.getProfiles();
      this.checkIfAdminUser();
    }
  }

  public componentWillReceiveProps(nextProps: Readonly<IProps>): void {
    if (nextProps.tenantId && nextProps.token && !nextProps.store.isLoading && nextProps.store.consentIsGiven) {
      this.getProfiles();
      this.checkIfAdminUser();
    }
  }

  public checkIfAdminUser(): void {
    if (this.props.store.token === "DEV_TOKEN") {
      this.props.store.isAdmin = true;
    }
    PlayService.getAdminUsers(this.props.store.environment, this.props.store.token, this.props.store.tenantId).then((adminUsers: IPlayUser[]) => {
      this.props.store.adminUsers = adminUsers;
      PlayService.getCurrentUser(this.props.store.environment, this.props.store.token, this.props.store.tenantId).then((currentUser: any) => {
        this.props.store.currentUser = currentUser;
        if (adminUsers?.length > 0) {
          adminUsers.forEach((adminUser: IPlayUser) => {
            if (adminUser.userId === currentUser.id) {
              this.props.store.isAdmin = true;
            }
          });
        }
      });
    });
  }

  private addResizeObserver(): void {
    window.addEventListener('resize', () => this.resize());
    setTimeout(() => {
      this.resize();
    }, 0);
    try {
      var resizeObserver = new ResizeObserver(() => {
        this.resize();
      });
      resizeObserver.observe(document.getElementById(`IA_Content`));
    } catch {
      this.resize();
    }
  }

  private resize(): void {
    const contentPadding = 40 * 2;
    this.props.store.contentWidth = document.getElementById(`IA_Content`) != undefined ? document.getElementById(`IA_Content`).clientWidth - contentPadding : 0;
    this.props.store.windowWidth = window.innerWidth;
    this.forceUpdate();
  }

  private setFonts(): void {
    if (this.props.store.profile.fonts) {
      if (this.props.store.profile.fonts?.headline) {
        const font: { name: string, url: string } = JSON.parse(this.props.store.profile.fonts?.headline);
        if (font.url) {
          const fontFace: FontFace = new FontFace(font.name, `url(${font.url})`);
          this.props.store.loadFont(fontFace, "headline");
        }
      }
      if (this.props.store.profile.fonts?.body) {
        const font: { name: string, url: string } = JSON.parse(this.props.store.profile.fonts?.body);
        if (font.url) {
          const fontFace: FontFace = new FontFace(font.name, `url(${font.url})`);
          this.props.store.loadFont(fontFace, "body");
        }
      }
    }
  }

  private getProfiles(): void {
    if (this.state.isLoadingProfiles) {
      return;
    }
    this.props.store.isLoading = true;
    this.setState({ isLoadingProfiles: true });
    if (localStorage.getItem("IA_profileId")) {
      PlayService.getPlayProfile(this.props.store.environment, this.props.store.token, this.props.store.tenantId, localStorage.getItem("IA_profileId")).then((profile) => {
        if (profile === 401) {
          this.props.store.isLoading = false;
          this.setState({ isLoadingProfiles: false });
          this.props.store.showAuthenticationErrorPopup = true;
          localStorage.removeItem("IA_profileId");
        } else if (profile === 404) {
          localStorage.removeItem("IA_profileId");
          this.setState({ isLoadingProfiles: false }, () => this.getProfiles());
        } else {
          if (profile) {
            Helper.setLocalStorage("IA_profileId", profile?.id);
            this.props.store.profile = profile;
            this.addResizeObserver();
            this.props.store.isLoading = false;
            this.setState({ isLoadingProfiles: false });
            this.setFonts();
          } else {
            this.forceUpdate();
            localStorage.removeItem("IA_profileId");
            this.getProfiles();
          }
          PlayService.getPlayProfiles(this.props.store.environment, this.props.store.token, this.props.store.tenantId).then((profiles: IPlayProfile[] | 401) => {
            if (profiles === 401) {
              this.props.store.showAuthenticationErrorPopup = true;
            } else {
              this.props.store.profiles = profiles.filter(profile => profile.hasAccessPermissions);
              this.forceUpdate();
            }
          });
        }
      });
    } else {
      PlayService.getPlayProfiles(this.props.store.environment, this.props.store.token, this.props.store.tenantId).then((profiles: IPlayProfile[] | 401) => {
        if (profiles === 401) {
          this.props.store.isLoading = false;
          this.setState({ isLoadingProfiles: false });
          this.props.store.showAuthenticationErrorPopup = true;
        } else {
          if (profiles?.length > 0) {
            const availableProfiles = profiles.filter(profile => profile.hasAccessPermissions);
            if (availableProfiles.length > 0) {
              Helper.setLocalStorage("IA_profileId", availableProfiles[0].id);
              this.props.store.profile = availableProfiles[0];
              this.props.store.profiles = availableProfiles;
              this.setFonts();
              this.addResizeObserver();
            } else {
              const availableProfilesItems: { key: string, value: string, disabled?: boolean }[] = [{ key: "", value: "" }];
              availableProfilesItems.push({
                key: "x",
                value: this.localizationService.strings.PlayAdmin_SelectAProfileToAccess,
                disabled: true
              });
              profiles.forEach((profile: IPlayProfile) => {
                availableProfilesItems.push({
                  key: profile.id,
                  value: profile.title
                });
              });
              this.setState({ isShowingMissingPermissionsAlert: true, availableProfilesItems });
            }
          } else {
            this.createNewProfile();
          }
          this.props.store.isLoading = false;
          this.setState({ isLoadingProfiles: false });
        }
      });
    }
  }

  private createNewProfile(): void {
    const newProfile: IPlayProfile = this.props.store.getDefaultProfile();
    this.props.store.creatingNewProfile = 1;
    this.props.store.isLoading = true;
    PlayService.createPlayProfile(this.props.store.environment, this.props.store.token, this.props.store.tenantId, newProfile, this.props.store.sharepointRootSite).then((id: string) => {
      newProfile.id = id;
      Helper.setLocalStorage("IA_profileId", id);
      this.props.store.profile = newProfile;
      this.props.store.profiles = undefined;
      this.props.store.creatingNewProfile = 10;
      this.props.store.addDemoContent();
    }).catch(() => location.reload());
  }

  public render(): JSX.Element {
    return (
      <div
        id="IA_PlayAdmin"
        className={styles.IA_playAdmin}
        style={{
          backgroundColor: this.props.store.darkMode ? "#141414" : "#ffffff",
          color: this.props.store.darkMode ? Helper.darkModeLabelColor : "#333333"
        }}
      >
        <Dashboard
          getProfiles={() => this.getProfiles()}
          showSkeleton={(this.props.store.isLoading || this.state.isShowingMissingPermissionsAlert || this.props.store.displayConsentButton || this.props.store.displayConsentButton || !(this.props.store.token && this.props.store.tenantId && this.props.store.consentIsGiven) || !this.props.store.profile)}
        />
        <div
          className={styles.IA_loadingScreen}
          style={{
            display: (this.props.store.isLoading || this.state.isShowingMissingPermissionsAlert || this.props.store.displayConsentButton || this.props.store.displayConsentButton || !(this.props.store.token && this.props.store.tenantId && this.props.store.consentIsGiven) || this.props.store.creatingNewProfile) ? "block" : "none"
          }}
        >
          <div
            className={styles.IA_loadingScreenContent}
            style={{
              backgroundColor: this.props.store.darkMode ? "#404040a6" : "#ffffffa6"
            }}
          >
            {this.props.store.isLoading ?
              <IASpinner
                color={this.props.store.highlightColor}
                style={{
                  float: "left",
                  marginLeft: "calc(50% - 20px)",
                  marginTop: "46vh"
                }}
              />
              :
              <>
                {this.props.store.creatingNewProfile &&
                  <>
                    <div
                      style={{
                        width: 400,
                        height: 3,
                        backgroundColor: "#000000",
                        float: "left",
                        marginLeft: "calc(50% - 200px)",
                        marginTop: "45vh"
                      }}
                    >
                      <div
                        style={{
                          width: this.props.store.creatingNewProfile / 100 * 400,
                          height: 3,
                          backgroundColor: this.props.store.highlightColor,
                          transition: "width 500ms linear"
                        }}
                      >

                      </div>
                    </div>
                    <div
                      style={{
                        float: "left",
                        textAlign: "center",
                        width: "100%",
                        marginTop: 20,
                      }}
                    >
                      {this.localizationService.strings.PlayAdmin_CreatingNewProfile?.toUpperCase()}
                    </div>
                  </>
                }
              </>
            }
          </div>
        </div>
        {this.props.store.displayConsentButton &&
          <>
            <div
              className={styles.IA_contentBox}
              style={{
                top: "calc(50% - 280px)"
              }}
            >
              <div
                className={styles.IA_illustration}
                style={{
                  backgroundImage: 'url("https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/consent-banner.png")'
                }}
              />
              <div
                className={styles.IA_text}
                style={{
                  marginTop: 10
                }}
              >
                <div>{this.localizationService.strings.PlayAdmin_ConsentText1}</div>
              </div>
              <div
                className={styles.IA_text}
                style={{
                  marginTop: 10
                }}
              >
                <div>{this.localizationService.strings.PlayAdmin_ConsentText2}</div>
              </div>
              <div className={styles.IA_buttons}>
                <IAButton
                  label={this.localizationService.strings.PlayAdmin_Consent?.toUpperCase()}
                  buttonColor="#ffffff"
                  darkMode={this.props.store.darkMode}
                  textColor={this.props.store.highlightColor}
                  onClick={() => this.props.authenticate()}
                  borderRadius={5}
                  height={40}
                  style={{
                    float: "left",
                    position: "relative",
                    marginTop: 4,
                    marginLeft: "calc(50% - 50px)",
                    height: 40
                  }}
                />
              </div>
            </div>
          </>
        }
        {!(this.props.store.token && this.props.store.tenantId && this.props.store.consentIsGiven) && !this.props.store.displayConsentButton && !this.props.store.isLoading &&
          <>
            <div
              className={styles.IA_contentBox}
              style={{
                top: "calc(50% - 200px)"
              }}
            >
              <div
                className={styles.IA_illustration}
                style={{
                  backgroundImage: 'url("https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/error-banner.png")'
                }}
              />
              <div
                className={styles.IA_text}
                style={{
                  marginTop: 10
                }}
              >
                <div>{this.localizationService.strings.PlayAdmin_SomethingWentWrong}</div>
              </div>
              <div className={styles.IA_buttons}>
                <IAButton
                  label={this.localizationService.strings?.PlayAdmin_Reload?.toUpperCase()}
                  buttonColor="#ffffff"
                  darkMode={this.props.store.darkMode}
                  textColor={this.props.store.highlightColor}
                  onClick={() => window.location.reload()}
                  borderRadius={5}
                  height={40}
                  style={{
                    float: "left",
                    position: "relative",
                    marginTop: 4,
                    marginLeft: "calc(50% - 50px)",
                    height: 40
                  }}
                />
              </div>
            </div>
          </>
        }
        {this.state.isShowingMissingPermissionsAlert &&
          <div className={styles.IA_contentBox}>
            <div
              className={styles.IA_illustration}
              style={{
                backgroundImage: 'url("https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/no-permissions-banner.png")'
              }}
            />
            <div className={styles.IA_text}>
              {this.localizationService.strings.PlayAdmin_MissingPermissionsText}
            </div>
            <div className={styles.IA_buttons}>
              <IAButton
                label={this.localizationService.strings?.PlayAdmin_OpenATeamsChatWithAdmin?.toUpperCase()}
                borderColor={"#ffffff"}
                buttonColor="transparent"
                textColor={"#ffffff"}
                darkMode={this.props.store.darkMode}
                onClick={() => {
                  PlayService.getAdminUsers(this.props.store.environment, this.props.store.token, this.props.store.tenantId).then((users: IPlayUser[]) => {
                    if (users?.length > 0) {
                      window.open(`MSTeams:/l/chat/0/0?users=${users[users.length - 1].userPrincipalName}`);
                    }
                  });
                }}
                borderRadius={5}
                style={{
                  float: "left",
                  position: "relative",
                  marginTop: 10,
                  marginLeft: "calc(50% - 80px)"
                }}
              />
            </div>
          </div>
        }
        <AccountSettings
          show={this.props.store.isShowingAccountSettings}
        />
        <SettingsPanel />
        {
          this.props.store.showAuthenticationErrorPopup &&
          <Popup
            darkMode={this.props.store.darkMode}
            headline={this.localizationService.strings.PlayAdmin_AuthenticationError}
            content={
              <IAButton
                label={this.localizationService.strings?.PlayAdmin_Reload?.toUpperCase()}
                buttonColor={this.props.store.highlightColor}
                showSpinner={false}
                borderRadius={5}
                onClick={() => {
                  location.reload();
                }}
                style={{
                  marginTop: 20,
                  float: "left"
                }}
              />
            }
          />
        }
        {this.props.store.generalErrorPopup &&
          <ErrorPopup
            technicalDetails={this.props.store.generalErrorPopup.technicalDetails}
            onClose={() => this.props.store.generalErrorPopup = undefined}
            highlightColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
          />
        }
      </div >
    );
  }


}