import * as React from 'react';
import * as styles from './Skeleton.css';
import { ISkeletonElement } from '../../interfaces/ISkeletonElement';

export interface ISkeletonProps {
  skeleton: ISkeletonElement;
}

export class IASkeleton extends React.Component<ISkeletonProps> {
  public render() {
    return (
      <div
        className={[styles.IA_Skeleton, this.props.skeleton.darkMode ? styles.IA_SkeletonDark : styles.IA_SkeletonLight].join(" ")}
        style={{
          height: this.props.skeleton.height,
          width: this.props.skeleton.width,
          borderRadius: this.props.skeleton.borderRadius,
          marginTop: this.props.skeleton.marginTop,
          marginBottom: this.props.skeleton.marginBottom,
          marginLeft: this.props.skeleton.marginLeft,
          marginRight: this.props.skeleton.marginRight
        }}
      />
    );
  }
}