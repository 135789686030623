import * as React from 'react';
import { IAIcon } from '../../../icon/Icon';
import * as styles from './Group.css';

export interface IGroupProps {
  label: string;
  count?: number;
  darkMode?: boolean;
  textAlign?: "left" | "center" | "right";
  labelColor?: string;
  collapsed?: boolean;
  onExpand?: () => void;
}

export interface IGroupState {
  collapsed: boolean;
}

export class Group extends React.Component<IGroupProps, IGroupState> {

  constructor(props: IGroupProps) {
    super(props);
    this.state = {
      collapsed: props.collapsed != undefined ? props.collapsed : false
    }
  }

  private onClick() {
    this.setState({ collapsed: !this.state.collapsed });
    if (this.props.onExpand) {
      this.props.onExpand();
    }
  }

  public render(): JSX.Element {
    return (
      <div className={styles.IA_group}>
        <div
          className={styles.IA_title}
          style={{
            textAlign: this.props.textAlign ? this.props.textAlign : "center",
            color: this.props.labelColor
          }}
          onClick={() => this.onClick()}
        >
          {this.props.count != undefined ? `${this.props.label?.toUpperCase()} (${this.props.count})` : `${this.props.label?.toUpperCase()}`}
        </div>
        <IAIcon
          title={"Arrow"}
          size={18}
          color={this.props.darkMode ? "#ffffff" : "#333333"}
          style={{
            float: "left",
            transform: this.state.collapsed ? "rotate(-90deg)" : "rotate(90deg)",
            height: 18,
            width: 18,
            marginLeft: 10
          }}
          onClick={() => this.onClick()}
        />
        <div
          className={styles.IA_groupArea}
          style={{
            padding: this.state.collapsed ? 0 : "0px 0px 0px 0px",
            height: this.state.collapsed ? 0 : "auto",
            overflow: this.state.collapsed ? "auto" : "none"
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }

  
}