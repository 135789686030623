import * as React from 'react';
import { IPlayNewsItem, IPlayWidgetAreaType } from '../../../../interfaces/IPlay';
import { ListItem } from './listItem/ListItem';
import * as styles from './List.css';
import { Helper } from '../../../../Helper';
import { LocalizationService } from '../../../../services/LocalizationService';
import { PlayHelper } from '../../../playAdmin/PlayHelper';

export interface IProps {
  widgetId: string;
  index?: number;
  playerWidth: number;
  playerHeight: number;
  newsItems: IPlayNewsItem[];
  width?: number | string;
  sizeUnit?: number;
  headlineColor?: string;
  backgroundColor?: string;
  boxColor?: string;
  borderColor?: string;
  textColor?: string;
  timeBatchBackgroundColor?: string;
  timeBatchTextColor?: string;
  timeBatchBorderColor?: string;
  showTitle?: boolean;
  showText?: boolean;
  showDate?: boolean;
  showTime?: boolean;
  showLocation?: boolean;
  showTimeBox?: boolean;
  areaType?: IPlayWidgetAreaType;
  textLines?: number;
  headline?: string;
  noContentImage?: string;
  language?: string;
  headlineFont: string;
  contentFont: string;
}

export interface IState {
  isLoading: boolean;
}

export class List extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService;

  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: true,
    };
    this.localizationService = new LocalizationService(PlayHelper.getLocalizerLanguageFromPlayerLanguage(this.props.language));
  }

  private getTeaser(content: string,): string {
    try {
      let text = content;
      text = Helper.stripHtml(text);
      text = text ? text + "..." : "";
      return text;
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  public render(): JSX.Element {
    const listDiv = document.getElementById(`IA_List_${this.props.widgetId}_${this.props.index}`);
    let listHeight;
    let numberOfFullyVisibleElemets;
    const listItems = [];
    let noContentImage = this.props.noContentImage;
    try {
      if (listDiv) {
        numberOfFullyVisibleElemets = this.props.headline ? -1 : 0;
        listHeight = listDiv.clientHeight;
        let listContentHeight = 0;
        listDiv.childNodes.forEach((childNode: any) => {
          console.log(childNode.clientHeight);
          listContentHeight += childNode.clientHeight + (this.props.sizeUnit * 3);
          if (listHeight > listContentHeight) {
            numberOfFullyVisibleElemets++;
          }
        })
      }
      this.props.newsItems.forEach((newsItem: IPlayNewsItem, index) => {
        listItems.push(
          <ListItem
            key={`newsItem_${index}`}
            sizeUnit={this.props.sizeUnit}
            textColor={this.props.textColor}
            borderColor={this.props.borderColor}
            boxColor={this.props.boxColor}
            timeBatchBackgroundColor={this.props.timeBatchBackgroundColor}
            timeBatchTextColor={this.props.timeBatchTextColor}
            timeBatchBorderColor={this.props.timeBatchBorderColor}
            title={this.props.showTitle && newsItem.title}
            teaser={this.props.showText && this.getTeaser(newsItem.content)}
            startDate={newsItem.startDate}
            endDate={newsItem.endDate}
            isAllDayEvent={newsItem.isAllDayEvent}
            location={this.props.showLocation && newsItem.location}
            showTime={this.props.showTime}
            showTimeBox={this.props.showTimeBox}
            textLines={this.props.textLines}
            hidden={index >= numberOfFullyVisibleElemets}
            language={this.props.language}
            headlineFont={this.props.headlineFont}
            contentFont={this.props.contentFont}
          />
        );
      });
      if (!noContentImage) {
        noContentImage = "https://intraactivestorage.blob.core.windows.net/cdn/play/no-content_en.jpg";
        if (this.localizationService.getLanguageCode() === "da-DK") {
          noContentImage = "https://intraactivestorage.blob.core.windows.net/cdn/play/no-content_da.jpg";
        }
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
    return (
      <div
        id={`IA_List_${this.props.widgetId}_${this.props.index}`}
        className={styles.IA_list}
        style={{
          backgroundColor: `${this.props.backgroundColor}`,
          padding: this.props.areaType === "full" ? `${this.props.sizeUnit * 5}px 20%` : this.props.sizeUnit * 5,
          backgroundImage: listItems.length == 0 ? `url(${noContentImage})` : undefined
        }}
      >
        {this.props.headline && listItems.length > 0 &&
          <div
            className={styles.IA_headline}
            style={{
              fontSize: this.props.sizeUnit * 4,
              marginBottom: this.props.sizeUnit * 2.5,
              color: this.props.headlineColor,
              fontFamily: this.props.headlineFont
            }}
          >
            {this.props.headline}
          </div>
        }
        {listItems}
      </div >
    );
  }


}