exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_listElementHeader_j6M9EaDv0B8vHVgcjucmi {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  overflow: hidden;\n  font-size: 12px;\n  padding-left: 15px;\n  box-sizing: border-box;\n}\n\n.IA_previewColumn_3Hw9QVQX30-RbQlFQASOoz {\n  flex: 0.2;\n}\n\n.IA_iconColumn_30m-3M6HhqLtzJX-7kPRRq {\n  flex: 0.4;\n  padding-right: 10px;\n  box-sizing: border-box;\n}\n\n.IA_dateColumn_2gjBPI705T8f5ZQYAKGb7T {\n  flex: 0.5;\n}\n\n.IA_column_9EEw9wWfQZh0Mqku6L8Kc {\n  flex: 1;\n}\n\n.IA_buttons_iOLogzt4WPLdpA7hWRWNv {\n  padding-right: 10px;\n}", ""]);

// Exports
exports.locals = {
	"IA_listElementHeader": "IA_listElementHeader_j6M9EaDv0B8vHVgcjucmi",
	"IA_previewColumn": "IA_previewColumn_3Hw9QVQX30-RbQlFQASOoz",
	"IA_iconColumn": "IA_iconColumn_30m-3M6HhqLtzJX-7kPRRq",
	"IA_dateColumn": "IA_dateColumn_2gjBPI705T8f5ZQYAKGb7T",
	"IA_column": "IA_column_9EEw9wWfQZh0Mqku6L8Kc",
	"IA_buttons": "IA_buttons_iOLogzt4WPLdpA7hWRWNv"
};