import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from '../IntegrationsEditPanel.css';
import { IFeed } from '../../../../../../interfaces/IFeed';
import { IACheckbox } from '../../../../../checkbox/Checkbox';
import { IAIcon } from '../../../../../icon/Icon';

export interface IProps {
  store?: Store;
  id: string;
  feed: IFeed;
  isDeletingSharepointNewsItem: boolean;
}

@inject('store')
@observer
export class IntegrationFeedCheckbox extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }

  public render(): JSX.Element {
    return (
      <div
        id={this.props.id}
        className={styles.IA_feed_element}
        onMouseOver={() => {
          document.getElementById(this.props.id).style.backgroundColor = this.props.store.darkMode ? "#393939" : "#e6e6e6";
        }}
        onMouseOut={() => {
          document.getElementById(this.props.id).style.backgroundColor = "";
        }}
      >
        <IACheckbox
          key={this.props.feed.id}
          label={this.props.feed.title}
          highlightColor={this.props.store.highlightColor}
          isChecked={this.props.store.integrationToEdit?.feeds.indexOf(this.props.feed.id) !== -1}
          onChange={() => {
            const index = this.props.store.integrationToEdit?.feeds.indexOf(this.props.feed.id);
            if (index === -1) {
              this.props.store.integrationToEdit?.feeds.push(this.props.feed.id);
            } else {
              this.props.store.integrationToEdit?.feeds.splice(index, 1);
            }
          }}
          styles={{
            padding: 10
          }}
        />
        {(this.props.store.integrationToEdit?.type === 'messages' || this.props.store.integrationToEdit?.type === 'events') &&
          <>
            <IAIcon
              title='Edit'
              size={20}
              style={{ float: "left", padding: "10px 7px 0 0", marginLeft: "auto" }}
              color={this.props.store.highlightColor}
              onClick={() => {
                this.setState({ shouldReloadOnFocus: true });
                window.open(`https://${this.props.store.sharepointRootSite}/sites/IntraActive-Admin?showFeedWidgetForComponent=${this.props.store.integrationToEdit.type === 'messages' ? 'IntraActive-Messages' : 'IntraActive-Events'}&feedId=${this.props.feed.id}&mode=edit`, '_blank')
              }}
            />
            <IAIcon
              title='Trash'
              size={20}
              style={{ float: "left", padding: "10px 7px 0 0" }}
              color={this.props.store.highlightColor}
              onClick={() => {
                this.setState({ shouldReloadOnFocus: true });
                window.open(`https://${this.props.store.sharepointRootSite}/sites/IntraActive-Admin?showFeedWidgetForComponent=${this.props.store.integrationToEdit.type === 'messages' ? 'IntraActive-Messages' : 'IntraActive-Events'}&feedId=${this.props.feed.id}&mode=delete`, '_blank')
              }}
            />
          </>
        }
        {this.props.store.integrationToEdit?.type == 'sharepoint-news' &&
          <>
            <IAIcon
              title='Trash'
              size={20}
              style={{ float: "left", opacity: this.props.isDeletingSharepointNewsItem ? 0.5 : 1, padding: "10px 7px 0 0", marginLeft: "auto" }}
              color={this.props.store.highlightColor}
              onClick={() => {
                // Bail early if something's already being deleted
                if (this.props.isDeletingSharepointNewsItem) {
                  return;
                }
                this.setState({ showConfirmDeleteSharepointNewsItem: true, feedToDelete: this.props.feed });
              }}
            />
          </>
        }
      </div>
    );
  }
}