exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_SkeletonLight_M-ZNSbebfiHfHh4g_8O9j {\n  background: linear-gradient(to right, #e1e1e1 8%, #e9e9e9 38%, #e1e1e1 54%);\n}\n\n.IA_SkeletonDark_9D41P7HV8GWCFffnnln7R {\n  background: linear-gradient(to right, #2d2d2d 8%, #393939 38%, #2d2d2d 54%);\n}\n\n.IA_Skeleton_1gYsM7iG-OH008PhWX1KEb {\n  background-color: rgba(0, 0, 0, .15);\n  animation-duration: 1.5s;\n  animation-fill-mode: forwards;\n  animation-iteration-count: infinite;\n  animation-timing-function: linear;\n  background-size: 500px 500px;\n  animation-name: skeletonAnimation_3q1yh6lZPL0-TKUPc0tLag;\n}\n\n@keyframes skeletonAnimation_3q1yh6lZPL0-TKUPc0tLag {\n  0% {\n    background-position: -500px 0;\n  }\n\n  100% {\n    background-position: 500px 0;\n  }\n}", ""]);

// Exports
exports.locals = {
	"IA_SkeletonLight": "IA_SkeletonLight_M-ZNSbebfiHfHh4g_8O9j",
	"IA_SkeletonDark": "IA_SkeletonDark_9D41P7HV8GWCFffnnln7R",
	"IA_Skeleton": "IA_Skeleton_1gYsM7iG-OH008PhWX1KEb",
	"skeletonAnimation": "skeletonAnimation_3q1yh6lZPL0-TKUPc0tLag"
};