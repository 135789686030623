import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import { IATextField } from '../../../../../textField/TextField';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IAToggle } from '../../../../../toggle/Toggle';
import { IANumberField } from '../../../../../numberField/NumberField';
import { IAGroup } from '../../../../../group/Group';

export interface IProps {
  store?: Store;
  forceUpdate: () => void;
}

@inject('store')
@observer
export class EmbedWidgetSettings extends React.Component<IProps> {

  private readonly localizationService = new LocalizationService()

  render(): React.ReactNode {
    return (
      <IAGroup
        label={this.localizationService.strings.PlayAdmin_WidgetSettings?.toUpperCase()}
        darkMode={this.props.store.darkMode}
      >
        <IATextField
          label={this.localizationService.strings.PlayAdmin_Title?.toUpperCase()}
          required
          placeholder={this.localizationService.strings.PlayAdmin_Title}
          text={this.props.store.widgetToEdit.title}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          onChange={(title: string) => {
            this.props.store.widgetToEdit.title = title;
            this.props.forceUpdate();
          }}
        />
        <IAToggle
          style={{
            marginTop: 20,
            fontSize: 12
          }}
          darkMode={this.props.store.darkMode}
          label={this.localizationService.strings.PlayAdmin_ShowWidgetTitle?.toUpperCase()}
          checked={this.props.store.widgetToEdit.showTitle}
          color={this.props.store.highlightColor}
          onChange={() => {
            this.props.store.widgetToEdit.showTitle = this.props.store.widgetToEdit.showTitle != undefined ? !this.props.store.widgetToEdit.showTitle : true;
            this.props.forceUpdate();
          }}
        />
        <IATextField
          label={this.localizationService.strings.PlayAdmin_EmbedUrl?.toUpperCase()}
          placeholder={this.localizationService.strings.PlayAdmin_EmbedUrl}
          text={this.props.store.widgetToEdit.embed.url}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          onChange={(embedUrl: string) => {
            this.props.store.widgetToEdit.embed.url = embedUrl;
            this.props.forceUpdate();
          }}
          labelStyle={{
            fontSize: "12px"
          }}
          style={{
            marginTop: 20
          }}
        />
        <IANumberField
          label={this.localizationService.strings.PlayAdmin_Duration?.toUpperCase()}
          number={this.props.store.widgetToEdit?.duration && this.props.store.widgetToEdit?.duration !== 0 ? this.props.store.widgetToEdit?.duration : 30}
          onChange={(duration: number) => {
            this.props.store.widgetToEdit.duration = duration;
            this.props.forceUpdate();
          }}
          min={0}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          styles={{
            width: "100%",
            clear: "both",
            marginTop: 20
          }}
          labelStyle={{
            fontSize: "12px"
          }}
        />
      </IAGroup>
    );
  }
}