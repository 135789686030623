import * as React from 'react';
import ReactPlayer from 'react-player';
import { Helper } from '../../../../Helper';
import { IEnvironment } from '../../../../interfaces/IEnvironment';
import { PlayHelper } from '../../../playAdmin/PlayHelper';
import { Image } from '../image/Image';
import * as styles from './Video.css';
import { IndexDbFileService } from '../../../../services/IndexDbFileService';

export interface IProps {
  type: "video" | "youtube" | "vimeo" | "video23";
  videoUrl: string;
  width: number | string;
  height: number | string;
  position?: "absolute" | "relative";
  marginLeft?: number | string;
  marginTop?: number | string;
  backgroundColor?: string;
  showTitle?: boolean;
  title?: string;
  playerHeight?: number;
  playerWidth?: number;
  environment: IEnvironment;
  tenantId: string;
  profileId: string;
  willRestart: () => void;
  autoPlay?: boolean;
  style?: React.CSSProperties;
  onDuration?: (duration: number) => void;
}

export interface IState {
  isLoading: boolean;
  isKiosk: boolean;
  isPlaying: boolean;
  videoUrl: string;
}

export class Video extends React.Component<IProps, IState> {
  private indexDbFileService: IndexDbFileService = new IndexDbFileService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: true,
      isKiosk: PlayHelper.isReplayKiosk(),
      isPlaying: props.autoPlay == undefined ? true : props.autoPlay ,
      videoUrl: null,
    };

    this.indexDbFileService.getCachedFileUrl(this.props.videoUrl).then((newUrl) => {
      this.setState({ isLoading: false, videoUrl: newUrl });
    })
  }

  public render(): JSX.Element {

    const style: React.CSSProperties = {
      position: this.props.position ?? "absolute",
      marginLeft: this.props.marginLeft ?? 0,
      marginTop: this.props.marginTop ?? 0,
      backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : "transparent",
      ...this.props.style
    }
    return (<>
      {/* {this.props.type === "video23" ?
        <div
          style={{
            width: this.props.width,
            height: this.props.height,
            ...style
          }}
        >
          <iframe
            src={this.state.isKiosk ? this.props.videoUrl.replace("autoMute=1", "") : this.props.videoUrl}
            width={this.props.width}
            height={this.props.height}
            frameBorder="0"
            scrolling="no"
            allow="autoplay"
          />
        </div>
        : */}
      {!this.state.isLoading &&
        <ReactPlayer
          className="react-player"
          url={this.state.videoUrl}
          width={this.props.width}
          height={this.props.height}
          playing={this.state.isPlaying}
          muted={!this.state.isKiosk}
          style={style}
          onStart={() => this.setState({ isLoading: false })}
          onError={() => {
            console.log("Video failed");
            this.props.willRestart()
          }}
          onEnded={() => {
            this.props.willRestart()
            this.setState({ isPlaying: false }, () => {
              setTimeout(() => {
                this.setState({ isPlaying: true });
              }, 100);
            })
          }}
          onDuration={this.props.onDuration ? (duration: number) => this.props.onDuration(duration) : undefined}
        />
      }
      {/* } */}
      {this.state.isLoading && this.props.videoUrl &&
        <>
          {this.props.type === "youtube"
            ?
            <div>
              <Image
                imageUrl={Helper.getYouTubeThumbnailFromURL(this.props.videoUrl, "maxresdefault")}
                isActive={true}
                width={this.props.width}
                height={this.props.height}
                environment={this.props.environment}
                profileId={this.props.profileId}
                tenantId={this.props.tenantId}
                backgroundColor={this.props.backgroundColor}
              />
            </div>
            :
            <div
              style={{
                width: this.props.width,
                height: this.props.height,
                ...style
              }}
              className={styles.IA_videoSkeleton}
            />
          }
        </>
      }
    </>);
  }


}