import * as React from 'react';
import * as styles from './FlipCounter.css';
import '../../../../../styles/BasicIA.css';

export interface FlipCounterProps {
  sizeUnit: number;
  number: string;
  color: string;
  backgroundColor: string;
}

export interface FlipCounterState {
  newNum: string;
  oldNum: string;
  change: boolean;
}

export class FlipCounter extends React.Component<FlipCounterProps, FlipCounterState> {

  private timerID: NodeJS.Timeout;

  constructor(props) {
    super(props);
    this.state = {
      newNum: props.number,
      oldNum: undefined,
      change: true
    }
  }

  public componentWillReceiveProps(props: FlipCounterProps): void {
    if (props.number !== this.props.number) {
      this.tick(props.number);
    }
  }

  public componentWillUnmount() {
    clearInterval(this.timerID);
  }

  private tick(newNum: string) {
    if (this.state.newNum !== newNum) {
      this.setState({
        newNum,
        oldNum: this.props.number,
        change: !this.state.change
      });
    }
  }


  private colorShade(col, amt): string {
    col = col.replace(/^#/, '')
    if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

    let [r, g, b] = col.match(/.{2}/g);
    ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])

    r = Math.max(Math.min(255, r), 0).toString(16)
    g = Math.max(Math.min(255, g), 0).toString(16)
    b = Math.max(Math.min(255, b), 0).toString(16)

    const rr = (r.length < 2 ? '0' : '') + r
    const gg = (g.length < 2 ? '0' : '') + g
    const bb = (b.length < 2 ? '0' : '') + b

    return `#${rr}${gg}${bb}`
  }

  public render(): JSX.Element {
    const { newNum, oldNum, change } = this.state;
    const animation1 = change ? 'fold' : 'unfold';
    const animation2 = !change ? 'fold' : 'unfold';
    const number1 = change ? oldNum : newNum;
    const number2 = !change ? oldNum : newNum;
    const borderRadius = this.props.sizeUnit * 2;
    return (
      <div
        className={styles.flipCounter}
        style={{
          fontSize: this.props.sizeUnit * 3,
          width: "100%",
          paddingLeft: this.props.sizeUnit,
          paddingRight: this.props.sizeUnit,
          height: this.props.sizeUnit * 18,
          backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : "#ffffff",
          color: this.props.color ? this.props.color : "#333333",
          borderRadius: borderRadius,
        }}
      >
        <div
          className={styles.upperCard}
          style={{
            borderTopLeftRadius: borderRadius,
            borderTopRightRadius: borderRadius,
          }}
        >
          <span>{newNum}</span>
        </div>
        <div
          className={styles.lowerCard}
          style={{
            borderBottomLeftRadius: borderRadius,
            borderBottomRightRadius: borderRadius,
          }}
        >
          <span>{oldNum}</span>
        </div>
        <div
          className={`flipCard first ${animation1}`}
          style={{
            width: "100%",
            height: this.props.sizeUnit * 9,
            fontSize: this.props.sizeUnit * 15,
            backgroundColor: !change ? this.props.backgroundColor : this.colorShade(this.props.backgroundColor, -10),
            boxShadow: `inset 0px 1px ${this.colorShade(this.props.backgroundColor, -20)}`,
            borderTopLeftRadius: change ? borderRadius : 0,
            borderTopRightRadius: change ? borderRadius : 0,
            borderBottomLeftRadius: !change ? borderRadius : 0,
            borderBottomRightRadius: !change ? borderRadius : 0,
            
          }}
        >
          <span>{number1}</span>
        </div>
        <div className={`flipCard second ${animation2}`}
          style={{
            width: "100%",
            height: this.props.sizeUnit * 9,
            fontSize: this.props.sizeUnit * 15,
            backgroundColor: change ? this.props.backgroundColor : this.colorShade(this.props.backgroundColor, -10),
            boxShadow: `inset 0px 1px ${this.colorShade(this.props.backgroundColor, -20)}`,
            borderTopLeftRadius: !change ? borderRadius : 0,
            borderTopRightRadius: !change ? borderRadius : 0,
            borderBottomLeftRadius: change ? borderRadius : 0,
            borderBottomRightRadius: change ? borderRadius : 0,
          }}
        >
          <span>{number2}</span>
        </div>
      </div >
    );
  }
}