import * as React from 'react';
import { Store } from '../../../stores';
import { inject, observer } from 'mobx-react';
import { IATextField } from '../../../../textField/TextField';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IAColorPicker } from '../../../../colorPicker/ColorPicker';
import { IADropDown } from '../../../../dropDown/DropDown';
import { MediaFileSelector } from '../../mediaFiles/mediaFileSelector/MediaFileSelector';

export interface IProps {
  store?: Store;
  rssTickerIndexToEdit: number
  forceUpdate: () => void;
}

export interface IState {
  rssLabelType: "fromfeed" | "customtext" | "image";
}

@inject('store')
@observer
export class ProfileRssTickerSettings extends React.Component<IProps, IState> {
  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    if (this.props.store.profile && this.props.store.profile && this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit]) {
      let rssLabelType: "fromfeed" | "customtext" | "image" = "fromfeed";
      if (this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit]?.labelText != undefined) {
        rssLabelType = "customtext";
      }
      if (this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit]?.labelLogo != undefined) {
        rssLabelType = "image";
      }
      this.state = {
        rssLabelType: rssLabelType
      }
    };
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  render(): React.ReactNode {
    return (
      <>
        <IATextField
          label={this.localizationService.strings.PlayAdmin_Title?.toUpperCase()}
          placeholder={this.localizationService.strings.PlayAdmin_Title}
          text={this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit] ? this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit].title : undefined}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          onChange={(title: string) => {
            this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit].title = title;
            this.props.forceUpdate();
          }}
        />
        <IATextField
          label={this.localizationService.strings.PlayAdmin_RssUrl?.toUpperCase()}
          placeholder={this.localizationService.strings.PlayAdmin_RssUrl}
          text={this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit] ? this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit].url : undefined}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          style={{
            marginBottom: 15
          }}
          onChange={(url: string) => {
            this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit].url = url;
            this.props.forceUpdate();
          }}
        />
        {/* <IADropDown
          label={this.localizationService.strings.PlayAdmin_RssPosition?.toUpperCase()}
          selectedOption={this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit].position}
          darkMode={this.props.store.darkMode}
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          options={[
            {
              key: "bottom",
              value: this.localizationService.strings.PlayAdmin_RssPositionBottom
            },
            {
              key: "top",
              value: this.localizationService.strings.PlayAdmin_RssPositionTop
            },
          ]}
          onChange={(position: any) => {
            this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit].position = position;
            this.props.forceUpdate();
          }}
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: "100%",
            float: "left"
          }}
        /> */}
        <IAColorPicker
          label={this.localizationService.strings.PlayAdmin_BackgroundColor?.toUpperCase()}
          color={this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit] ? this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit].backgroundColor : undefined}
          darkMode={this.props.store.darkMode}
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          onChange={(backgroundColor: string) => {
            this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit].backgroundColor = backgroundColor;
            this.props.forceUpdate();
          }}
          showClearButton={false}
          styles={{
            marginTop: 0,
            marginBottom: 15
          }}
        />
        <IAColorPicker
          label={this.localizationService.strings.PlayAdmin_Color?.toUpperCase()}
          color={this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit] ? this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit].color : undefined}
          darkMode={this.props.store.darkMode}
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          onChange={(color: string) => {
            this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit].color = color;
            this.props.forceUpdate();
          }}
          showClearButton={false}
          styles={{
            marginTop: 0,
            marginBottom: 15
          }}
        />
        <IADropDown
          label={this.localizationService.strings.PlayAdmin_RssLabelType?.toUpperCase()}
          selectedOption={this.state.rssLabelType}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          options={[
            {
              key: "fromfeed",
              value: this.localizationService.strings.PlayAdmin_RssLabelTypeFromFeed
            },
            {
              key: "customtext",
              value: this.localizationService.strings.PlayAdmin_RssLabelTypeCustomText
            },
            {
              key: "image",
              value: this.localizationService.strings.PlayAdmin_RssLabelTypeImage
            }
          ]}
          onChange={(rssLabelType: any) => {
            this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit].labelText = rssLabelType === "customtext" ? "" : undefined;
            this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit].labelLogo = rssLabelType === "image" ? "none" : undefined;
            this.setState({rssLabelType});
          }}
          style={{
            marginTop: 20,
            width: "100%"
          }}
        />
        {this.state.rssLabelType === "customtext" &&
          <IATextField
            label={this.localizationService.strings.PlayAdmin_RssLabelText?.toUpperCase()}
            placeholder={this.localizationService.strings.PlayAdmin_RssLabelText}
            text={this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit] ? this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit].labelText : undefined}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(labelText: string) => {
              this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit].labelText = labelText;
              this.props.forceUpdate();
            }}
          />
        }
        {this.state.rssLabelType === "image" &&
          <MediaFileSelector
            url={this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit].labelLogo}
            label={this.localizationService.strings.PlayAdmin_RssLabelLogo?.toUpperCase()}
            placeholder={this.localizationService.strings.PlayAdmin_ImageUrl}
            type={"image"}
            onChange={(labelLogo: string) => {
              this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit].labelLogo = labelLogo;
              this.props.forceUpdate();
            }}
          />
        }
        <IAColorPicker
          label={this.localizationService.strings.PlayAdmin_RssLabelBackgroundColor?.toUpperCase()}
          color={this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit] ? this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit].labelBackgroundColor : undefined}
          darkMode={this.props.store.darkMode}
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          onChange={(labelBackgroundColor: string) => {
            this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit].labelBackgroundColor = labelBackgroundColor;
            this.props.forceUpdate();
          }}
          showClearButton={false}
        />
        {this.state.rssLabelType !== "image" &&
          <IAColorPicker
            label={this.localizationService.strings.PlayAdmin_RssLabelColor?.toUpperCase()}
            color={this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit] ? this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit].labelColor : undefined}
            darkMode={this.props.store.darkMode}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(labelColor: string) => {
              this.props.store.profile.rssTickers[this.props.rssTickerIndexToEdit].labelColor = labelColor;
              this.props.forceUpdate();
            }}
            showClearButton={false}
          />
        }
      </>);
  }
}