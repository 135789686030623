import * as React from 'react';
import { Store } from '../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './RssTickers.css';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IAButton } from '../../../../button/Button';
import { ProfileRssTickerSettings } from './ProfileRssTickerSettings';
import { PlayHelper } from '../../../PlayHelper';
import { IAIcon } from '../../../../icon/Icon';

export interface IProps {
  showSkeleton: boolean;
  store?: Store;
}

export interface IState {
  width: number;
  rssTickerIndexToEdit: number;
}

@inject('store')
@observer
export class RssTickers extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      width: undefined,
      rssTickerIndexToEdit: undefined
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentDidMount(): void {
    const progressBarElement = document.getElementById("IA_Profile");
    if (progressBarElement) {
      this.setState({ width: progressBarElement.clientWidth });
    }
  }

  public render(): JSX.Element {
    const rssTickers = [];
    this.props.store.profile?.rssTickers?.forEach((ticker, i) => {
      rssTickers.push(
        <div
          key={i}
          className={styles.IA_widgetType}
          style={{
            opacity: this.state.rssTickerIndexToEdit === undefined || this.state.rssTickerIndexToEdit === i ? 1 : 0.5
          }}
          onClick={() => {
            if (this.state.rssTickerIndexToEdit === i) {
              this.setState({ rssTickerIndexToEdit: undefined });
            } else {
              this.setState({ rssTickerIndexToEdit: i });
            }
          }}
        >
          {ticker.labelLogo && ticker.labelLogo !== "none" ?
            <div
              style={{
                height: 65,
                width: 65,
                backgroundColor: ticker.labelBackgroundColor,
                borderRadius: 10,
                overflow: "hidden"
              }}
            >
              <img
                style={{
                  objectFit: "contain",
                  width: "100%"
                }}
                src={ticker.labelLogo}
              />
            </div>
            :
            <IAIcon
              url="https://intraactivestorage.blob.core.windows.net/cdn/icons/RSS/SVG/ic_fluent_rss_24_regular.svg"
              size={41}
              color={ticker.labelColor}
              style={{
                float: "left",
                backgroundColor: ticker.labelBackgroundColor,
                padding: "10px 12px",
                borderRadius: 10
              }}
            />
          }
          <div
            className={styles.IA_widgetTypeLabel}
            style={{
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
          >
            {ticker.title}
          </div>
        </div>
      );
    });

    return (
      <div
        className={styles.IA_profile}
        id="IA_Profile"
      >
        <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : '#333333' }}>RSS Tickers</h3>
        <IAButton
          label={this.localizationService.strings.PlayAdmin_ProfileAddTicker?.toUpperCase()}
          buttonColor={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onClick={() => {
            const newTicker = PlayHelper.getEmptyRssTicker();
            if (!this.props.store.profile.rssTickers) {
              this.props.store.profile.rssTickers = [newTicker];
            } else {
              this.props.store.profile.rssTickers.push(newTicker);
            }
            this.setState({ rssTickerIndexToEdit: this.props.store.profile.rssTickers.length - 1 });
          }}
          borderRadius={5}
          style={{
            clear: "both"
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            clear: "both",
            float: "left",
            marginTop: 20
          }}
        >
          {rssTickers}
        </div>
        {this.state.rssTickerIndexToEdit !== undefined &&
          <div
            style={{
              clear: "both",
              float: "left"
            }}
          >
            <ProfileRssTickerSettings
              forceUpdate={() => this.forceUpdate()}
              rssTickerIndexToEdit={this.state.rssTickerIndexToEdit}
            />
            <IAButton
              icon='Trash'
              iconColor={this.props.store.highlightColor}
              label={this.localizationService.strings.PlayAdmin_ProfileDeleteTicker?.toUpperCase()}
              textColor={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onClick={() => {
                this.props.store.profile.rssTickers.splice(this.state.rssTickerIndexToEdit, 1);
                this.setState({ rssTickerIndexToEdit: undefined });
              }}
              borderRadius={5}
              style={{
                marginTop: 15,
                clear: "both"
              }}
            />
          </div>
        }
      </div>
    );
  }

}