exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".likesAndComments_P9UGZg1uJ4dPF3a7JsQ2D {\n  position: relative;\n  float: right;\n  font-size: 14px;\n  line-height: 20px;\n  margin-top: 8px;\n}\n\n.likesAndComments_P9UGZg1uJ4dPF3a7JsQ2D .likes_1pRuOR2MWQa7Iw4QyKjcXm {\n  height: 20px;\n  float: right;\n  position: relative;\n  cursor: pointer;\n}\n\n.likesAndComments_P9UGZg1uJ4dPF3a7JsQ2D .spinner_zfeEfWPN8om5dlsIpZ_bl {\n  height: 14px;\n  width: 12px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_27tgiH4-m5s_SyHbaNJ8nD 700ms linear infinite;\n  -moz-animation: spin_27tgiH4-m5s_SyHbaNJ8nD 700ms linear infinite;\n  animation: spin_27tgiH4-m5s_SyHbaNJ8nD 700ms linear infinite;\n}\n\n.likesAndComments_P9UGZg1uJ4dPF3a7JsQ2D .likesText_2UWWHcVLca8nCJybbJe6CE {\n  float: left;\n  margin-left: 4px;\n  margin-top: -1px;\n}\n\n.likesAndComments_P9UGZg1uJ4dPF3a7JsQ2D .likesTextVertical_1OfOA0JbbORTfUDcce-UdS {\n  float: left;\n  clear: both;\n  margin-top: -7px;\n  text-align: center;\n  width: 100%;\n  box-sizing: border-box;\n  padding-left: 1px;\n}\n\n@-moz-keyframes spin_27tgiH4-m5s_SyHbaNJ8nD {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_27tgiH4-m5s_SyHbaNJ8nD {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_27tgiH4-m5s_SyHbaNJ8nD {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"likesAndComments": "likesAndComments_P9UGZg1uJ4dPF3a7JsQ2D",
	"likes": "likes_1pRuOR2MWQa7Iw4QyKjcXm",
	"spinner": "spinner_zfeEfWPN8om5dlsIpZ_bl",
	"spin": "spin_27tgiH4-m5s_SyHbaNJ8nD",
	"likesText": "likesText_2UWWHcVLca8nCJybbJe6CE",
	"likesTextVertical": "likesTextVertical_1OfOA0JbbORTfUDcce-UdS"
};