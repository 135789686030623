exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_personaSmall_1U0MKN9q3CpYxU3Pvfxp6V {\n  font-size: 12px;\n  float: left;\n  margin-top: 3px;\n  display: block;\n  width: 100%;\n}\n\n.IA_personaSmall_1U0MKN9q3CpYxU3Pvfxp6V .IA_personaSmallProfileImageWrapper_J2frxMXK1svMy8CT0Ts0n {\n  height: 30px;\n  width: 30px;\n  float: left;\n  border-radius: 30px;\n  margin-right: 7px;\n  margin-top: 1px;\n  font-size: 15px;\n  text-align: center;\n  line-height: 29px;\n  text-transform: uppercase;\n  font-weight: 200;\n}\n\n.IA_personaSmall_1U0MKN9q3CpYxU3Pvfxp6V .IA_personaSmallProfileImage_1WhigJPfskUTKq0Ud4B1yY {\n  height: 32px;\n  width: 32px;\n  border-radius: 30px;\n  background-position: center;\n  margin-top: -32px;\n  left: -1px;\n  position: relative;\n  float: left;\n  background-size: cover;\n}\n\n.IA_personaSmall_1U0MKN9q3CpYxU3Pvfxp6V .IA_personaSmallProfileImageText_3cuoIvtpgkudoOrs-ZDGEd {\n  height: 30px;\n  width: 30px;\n  border-radius: 30px;\n  left: 0px;\n  top: 0px;\n  position: relative;\n  float: left;\n}\n\n.IA_personaSmall_1U0MKN9q3CpYxU3Pvfxp6V .IA_personaSmallName_jHIdG420Pz9C8q_MxrA9r {\n  float: none;\n  width: calc(100% - 40px);\n  height: 16px;\n  margin-top: -2px;\n  line-clamp: 1;\n  -webkit-line-clamp: 1;\n  box-orient: vertical;\n  -webkit-box-orient: vertical;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  font-weight: 600;\n  white-space: nowrap;\n}\n\n.IA_personaSmall_1U0MKN9q3CpYxU3Pvfxp6V .IA_personaSmallMetadata_2PKSCOIMKiA__b36znQ-Gc {\n  float: none;\n  width: calc(100% - 40px);\n  margin-top: -2px;\n  line-clamp: 1;\n  -webkit-line-clamp: 1;\n  box-orient: vertical;\n  -webkit-box-orient: vertical;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  color: #858585;\n  margin-top: 2px;\n}\n\n.IA_personaSmall_1U0MKN9q3CpYxU3Pvfxp6V .IA_personaSmallModifiedDate_2xbXR3w5QEtQqht7Sn4Sf1 {\n  float: none;\n  height: 12px;\n  width: 100%;\n  margin-top: 1px;\n}", ""]);

// Exports
exports.locals = {
	"IA_personaSmall": "IA_personaSmall_1U0MKN9q3CpYxU3Pvfxp6V",
	"IA_personaSmallProfileImageWrapper": "IA_personaSmallProfileImageWrapper_J2frxMXK1svMy8CT0Ts0n",
	"IA_personaSmallProfileImage": "IA_personaSmallProfileImage_1WhigJPfskUTKq0Ud4B1yY",
	"IA_personaSmallProfileImageText": "IA_personaSmallProfileImageText_3cuoIvtpgkudoOrs-ZDGEd",
	"IA_personaSmallName": "IA_personaSmallName_jHIdG420Pz9C8q_MxrA9r",
	"IA_personaSmallMetadata": "IA_personaSmallMetadata_2PKSCOIMKiA__b36znQ-Gc",
	"IA_personaSmallModifiedDate": "IA_personaSmallModifiedDate_2xbXR3w5QEtQqht7Sn4Sf1"
};