import * as React from 'react';
import Moment from 'react-moment';
import { Helper } from '../../../Helper';
import { IPlayWidgetExtended } from '../../../interfaces/IPlay';
import { IRss, IRssItem } from '../../../interfaces/IRss';
import { LocalizationService } from '../../../services/LocalizationService';
import RssService from '../../../services/RssService';
import { PlayHelper } from '../../playAdmin/PlayHelper';
import * as styles from './RssTicker.css';

export interface IProps {
  rssTicker: IPlayWidgetExtended;
  sizeUnit: number;
  playerWidth: number;
  bottom: string | number;
  isActive: boolean;
  editMode?: boolean;
  language?: string;
  headlineFont: string;
  contentFont: string;
}

export interface IState {
  leftPosition?: number;
  startPosition?: number;
  labelWidth?: number;
  items?: IRssItem[];
  animationFrame?: number;
  loadedTicker?: IPlayWidgetExtended;
  locale?: string;
}

export class RssTicker extends React.Component<IProps, IState> {
  private readonly localizationService;;
  private readonly polygonWidthRatio = 25;
  private animationFrameID: number;
  private _isMounted: boolean;
  private _isRunning: boolean;

  constructor(props: IProps) {
    super(props);
    this.state = {
      leftPosition: 0,
      startPosition: 0,
      labelWidth: 0
    }
    this.localizationService = new LocalizationService(PlayHelper.getLocalizerLanguageFromPlayerLanguage(this.props.language));
  }

  public componentWillReceiveProps(props: IProps): void {
    if ((props.rssTicker && props.rssTicker !== this.props.rssTicker) || this.state.loadedTicker !== props.rssTicker) {
      this.resize();
      if ((props.rssTicker.rssTicker?.url && props.rssTicker.rssTicker.url !== this.props.rssTicker.rssTicker.url) || this.state.loadedTicker?.rssTicker?.url !== props.rssTicker?.rssTicker?.url) {
        this.getRssFeed(props.rssTicker.rssTicker.url, true);
        this.resize();
        setTimeout(() => {
          this.resize();
        }, 1000);
      }
    }
    if (this.props.rssTicker && document.getElementById(`IA_RssTickerLabel_${this.props.rssTicker?.rssTicker?.url}`)) {
      const labelWidth = document.getElementById(`IA_RssTickerLabel_${this.props.rssTicker?.rssTicker?.url}`).clientWidth;
      const startPosition = this.state.startPosition ?? labelWidth + (this.props.sizeUnit * 3.3);
      const leftPosition = this.state.leftPosition ?? startPosition;
      this.setState({
        startPosition: startPosition,
        leftPosition: this.state.leftPosition ?? leftPosition,
        labelWidth: labelWidth
      });
    }
    if (!props.isActive && props.isActive !== this.props.isActive) {
      console.log("Pausing ticker " + this.animationFrameID);
      this.stopTicker();
      const leftPosition = document.getElementById(`IA_RssTickerContainer_${this.props.rssTicker?.rssTicker?.url}`)?.style?.left;
      if (leftPosition) {
        this.setState({ leftPosition: parseInt(leftPosition, 10) });
      }
    }
    if (props.isActive && props.isActive !== this.props.isActive) {
      console.log("Unpausing ticker");
      this.startTicker(true);
    }
    if (props.rssTicker?.rssTicker?.url && this.props.rssTicker?.rssTicker?.url && props.rssTicker?.rssTicker.url !== this.props.rssTicker.rssTicker.url) {
      console.log("Stopping RSS ticker");
      this.stopTicker();
      this.getRssFeed(props.rssTicker?.rssTicker.url);
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {
    if (prevProps.rssTicker && document.getElementById(`IA_RssTickerLabel_${this.props.rssTicker?.rssTicker?.url}`)) {
      const labelWidth = document.getElementById(`IA_RssTickerLabel_${this.props.rssTicker?.rssTicker?.url}`).clientWidth;
      if (prevState.labelWidth !== labelWidth) {
        this.setState({ leftPosition: labelWidth + ((this.props.sizeUnit * 100) / this.polygonWidthRatio), labelWidth: labelWidth });
      }
    }
  }

  public componentDidMount(): void {
    this._isMounted = true;
    if (this.props.rssTicker?.rssTicker?.url) {
      this.getRssFeed(this.props.rssTicker?.rssTicker?.url, true);
    } else if (this.props.editMode) {
      this.setState({
        items: [{
          link: "",
          title: this.localizationService.strings.PlayAdmin_RSSTickerSetupText
        }],
        leftPosition: 50,
        labelWidth: 0
      }, () => {
        this.startTicker();
      });
    } else {

    }
    // Resize
    window.addEventListener('resize', () => this.resize());
    try {
      var resizeObserver = new ResizeObserver(() => {
        this.resize();
      });
      resizeObserver.observe(document.getElementById(`IA_Content`));
    } catch {
      this.resize();
    }
    setTimeout(() => {
      this.resize();
    }, 1000);
  }

  private resize(): void {
    if (this.props.rssTicker && document.getElementById(`IA_RssTickerLabel_${this.props.rssTicker?.rssTicker?.url}`)) {
      this.setState({ labelWidth: document.getElementById(`IA_RssTickerLabel_${this.props.rssTicker?.rssTicker?.url}`).clientWidth });
    }
  }

  private getRssFeed(url: string, isInitializing?: boolean): Promise<void> {
    return new Promise(async (resolve) => {
      if (this.props.rssTicker && localStorage.getItem(`IA_rss_${url}`)) {
        const rss: IRss = JSON.parse(localStorage.getItem(`IA_rss_${url}`));
        this.setState({
          items: rss.item,
          loadedTicker: { rssTicker: { url: url } },
          locale: rss.language
        });
      }
      if (this.props.rssTicker) {
        RssService.getDirectFeed(url, this.props.rssTicker?.rssTicker?.maxItems).then((rss: IRss) => {
          if (rss && rss.item) {
            Helper.setLocalStorage(`IA_rss_${url}`, JSON.stringify(rss));
            this.setState({
              items: rss.item,
              loadedTicker: this.props.rssTicker,
              locale: rss.language
            });
          } else {
            this.setState({ items: [] });
          }
          if (isInitializing) {
            setTimeout(() => {
              // start animation after slide transition
              this.startTicker();
            }, 750);
          }
          resolve();
        }).catch(() => {
          if (isInitializing) {
            this.startTicker();
          }
          resolve();
        });
      }
    });
  }

  private startTicker(forceStart?: boolean) {
    if (!this.props.isActive && !forceStart && !this._isRunning) {
      console.log("Do not start ticker - ticker not active on this slide");
      return;
    }

    this._isRunning = true;

    const ticker = document.getElementById(`IA_RssTickerContainer_${this.props.rssTicker?.rssTicker?.url}`);
    if (!ticker || !this.state.items || this.state.items.length === 0) {
      setTimeout(() => {
        if (this.props.rssTicker?.rssTicker?.url) {
          // try to fetch rss content again after 1 second
          this.getRssFeed(this.props.rssTicker?.rssTicker?.url, true);
        }
      }, 1000);
      return;
    }
    console.log("Starting RSS ticker");
    const startPosition = this.state.startPosition;
    let leftPosition = this.state.leftPosition;

    let lastFrameCalled = performance.now();
    let fps = 0;
    const containerWidth = ticker.getBoundingClientRect().width;

    this.animationFrameID = requestAnimationFrame(() => this.animateTicker(lastFrameCalled, fps, startPosition, leftPosition, ticker, containerWidth));
  }

  private animateTicker(lastFrameCalled: number, fps: number, startPosition: number, leftPosition: number, ticker: HTMLElement, containerWidth: number) {
    // calculate fps
    const timeSinceLastFrame = (performance.now() - lastFrameCalled) / 1000;
    lastFrameCalled = performance.now();
    fps = 1 / timeSinceLastFrame;

    if ((containerWidth <= 0 || !this._isMounted) && this.state.items) {
      // no content, component probably has unmounted
      console.log("Stopping ticker, not mounted");
      return;
    }
    if ((leftPosition * -1) > containerWidth) {
      // when leftPosition (inversed, since has negative value) is greater than containerWidth,
      // this means that all elements has passed the screen so we restart the ticker
      console.log("Restarting RSS ticker");
      leftPosition = startPosition;
      ticker.style.left = leftPosition + "px";
      ticker.style.paddingLeft = "100%"; // should restart off screen
      if (!this.props.editMode) {
        this.getRssFeed(this.props.rssTicker?.rssTicker?.url).then(() => {
          containerWidth = ticker.getBoundingClientRect().width;
          this.animationFrameID = requestAnimationFrame(() => this.animateTicker(lastFrameCalled, fps, startPosition, leftPosition, ticker, containerWidth));
        });
      } else {
        this.animationFrameID = requestAnimationFrame(() => this.animateTicker(lastFrameCalled, fps, startPosition, leftPosition, ticker, containerWidth));
      }
      return;
    } else {
      const timeToCross = 25;
      // use fps to set the new position to avoid slow animations on weaker devices
      const step = window.innerWidth / timeToCross / fps;
      leftPosition = leftPosition - step;
      ticker.style.left = leftPosition + "px";
    }
    this.animationFrameID = requestAnimationFrame(() => this.animateTicker(lastFrameCalled, fps, startPosition, leftPosition, ticker, containerWidth));
  }

  private stopTicker() {
    this._isRunning = false;
    cancelAnimationFrame(this.animationFrameID);
  }

  public render(): JSX.Element {
    const rssFeed: JSX.Element[] = [];
    // let itemCount = 0;
    if (this.state.items) {
      this.state.items.forEach((item: IRssItem, index: number) => {
        // itemCount++;
        if (!this.props.editMode) {
          rssFeed.push(
            <div
              className={styles.IA_rssTickerItem}
              style={{
                marginLeft: `${this.props.sizeUnit * 1.25}px`,
                color: this.props.rssTicker?.rssTicker?.color,
                opacity: 0.3
              }}
              key={Helper.getRandomStringKey()}
            >
              <Moment fromNow locale={this.state.locale ?? (PlayHelper.getMomentJSLanguageCode(this.props.language))}>{item?.pubDate ?? Date.now()}</Moment>:
            </div>
          );
        }
        rssFeed.push(
          <div
            className={styles.IA_rssTickerItem}
            style={{
              marginLeft: `${this.props.sizeUnit * 1.7}px`,
              color: this.props.rssTicker?.rssTicker?.color,
            }}
            key={Helper.getRandomStringKey()}
          >
            {item.title}
          </div>
        );
        if (this.state.items.length - 1 !== index) {
          rssFeed.push(
            <div
              className={styles.IA_rssTickerItem}
              style={{
                marginLeft: `${this.props.sizeUnit * 1.7}px`,
                color: this.props.rssTicker?.rssTicker?.color,
                opacity: 0.3
              }}
              key={Helper.getRandomStringKey()}
            >
              |
            </div>
          );
        }
      });
    }

    // define css animation speed
    // const timeToCross = window.innerWidth / 250; // time for each item to cross the screen
    // const totalAnimationTime = itemCount * timeToCross;
    return this.props.rssTicker ? (
      <div
        id={`IA_RssTicker_${this.props.rssTicker?.rssTicker?.url}`}
        className={styles.IA_rssTicker}
        style={{
          backgroundColor: this.props.rssTicker?.rssTicker?.backgroundColor ?? "white",
          lineHeight: `${this.props.sizeUnit * 6}px`,
          fontSize: PlayHelper.getFontSize("standard", this.props.sizeUnit * 100),
          visibility: this.state.leftPosition ? "visible" : "hidden",
          border: this.props.editMode ? "1px solid #ffffff" : "none",
          height: this.props.sizeUnit * 6,
          bottom: this.props.bottom
        }}
      >
        {/* {(this.props.rssTicker?.rssTicker?.labelText || (localStorage.getItem(`IA_rss_${this.props.rssTicker?.rssTicker?.url}`) && JSON.parse(localStorage.getItem(`IA_rss_${this.props.rssTicker?.rssTicker?.url}`)).title)) &&
          <div
            className={styles.IA_rssTickerLabel}
            style={{
              backgroundColor: this.props.rssTicker?.rssTicker?.labelBackgroundColor,
              clipPath: "polygon(0 0, 100% 0%, 0% 100%, 0% 100%)",
              left: !isNaN(this.state.labelWidth) ? this.state.labelWidth : 0,
              width: this.props.playerHeight / 35
            }}
          />
        } */}
        <div
          id={`IA_RssTickerContainer_${this.props.rssTicker?.rssTicker?.url}`}
          className={styles.IA_rssTickerContainer}
          style={{
            left: !isNaN(this.state.leftPosition) ? this.state.leftPosition : 0,
            // animationDuration: `${totalAnimationTime}s`,
            // MozAnimationDuration: `${totalAnimationTime}s`,
            // WebkitAnimationDuration: `${totalAnimationTime}s`
          }}
        >
          {rssFeed}
        </div>
        {localStorage.getItem(`IA_rss_${this.props.rssTicker?.rssTicker?.url}`) &&
          <div
            id={`IA_RssTickerLabel_${this.props.rssTicker?.rssTicker?.url}`}
            className={styles.IA_rssTickerLabel}
            style={{
              backgroundColor: this.props.rssTicker?.rssTicker?.labelBackgroundColor ?? "black",
              color: this.props.rssTicker?.rssTicker?.labelColor ?? "white",
              lineHeight: `${this.props.sizeUnit * 6}px`,
              fontSize: PlayHelper.getFontSize("standard", this.props.sizeUnit * 100),
              paddingLeft: this.props.sizeUnit * 1.7,
              paddingRight: (this.props.sizeUnit * 100) / this.polygonWidthRatio,
              clipPath: `polygon(0 0, 100% 0%, ${this.state.labelWidth - ((this.props.sizeUnit * 100) / (this.polygonWidthRatio + 2))}px 100%, 0% 100%)`
            }}
          >
            {this.props.rssTicker?.rssTicker?.labelLogo &&
              <img
                src={this.props.rssTicker?.rssTicker?.labelLogo}
                height={(this.props.sizeUnit * 100) * 0.03}
                style={{
                  marginTop: (this.props.sizeUnit * 100) * 0.015,
                  height: `calc(100% - ${(this.props.sizeUnit * 100) * 0.03}px)`,
                }}
                onLoad={() => this.resize()}
              />
            }
            {this.props.rssTicker?.rssTicker?.labelLogo ? "" : (this.props.rssTicker?.rssTicker?.labelText ?? JSON.parse(localStorage.getItem(`IA_rss_${this.props.rssTicker?.rssTicker?.url}`)).title)}
          </div>
        }
      </div>
    )
      :
      (<div></div>);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', () => this.resize());
    console.log("Stopping RSS ticker: " + this.animationFrameID);
    this._isMounted = false;
    this.stopTicker();
  }


}