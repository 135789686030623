import * as React from 'react';
import * as styles from "./Comment.css";
import { LoggingService } from '../../../services/LoggingService';
import { IAReader } from '../../reader/Reader';
import { IAButton } from '../../button/Button';
import { IAPopup } from '../../popup/Popup';
import { IEnvironment } from '../../../interfaces/IEnvironment';
import { IAItem } from '../../../interfaces/IAItem';
import { LocalizationService } from '../../../services/LocalizationService';

export interface IADeleteCommentPopupProps {
  instance: string;
  token: string;
  environment: IEnvironment;
  item: IAItem;
  tenant: string;
  highlightColor: string;
  commentContent: string;
  source: string;
  component: string;
  isDeleting: boolean;
  deleteCommentError: boolean;
  delete: () => void;
  closePopup: () => void;
}

export class IADeleteCommentPopup extends React.Component<IADeleteCommentPopupProps> {

  private readonly localizationService = new LocalizationService();

  public componentDidMount(): void {
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public render(): JSX.Element {
    return (
      <IAPopup
        headline={this.localizationService.strings.LikesAndComments_DeleteCommentPopupText}
        close={() => this.props.closePopup()}
        content={<div style={{
          display: "flex",
          flexDirection: "column"
        }}>
          <div style={{
            marginBottom: 20,
            backgroundColor: "#f6f6f6",
            padding: "10px 15px 0 15px",
            borderRadius: 5,
            maxHeight: 250,
            overflowY: "auto"
          }}>
            <div
              className={styles.IA_commentText}
            />
            <IAReader
              content={this.props.commentContent}
              environment={this.props.environment}
              tenant={this.props.tenant}
              token={this.props.token}
              highlightColor={this.props.highlightColor}
            />
          </div>
          <div style={{
            display: "flex",
            flexDirection: "row"
          }}>
            <IAButton
              label={this.localizationService.strings.LikesAndComments_Delete}
              onClick={() => {
                LoggingService.trackEvent("IADeleteCommentPopup", {
                  ActionType: "DeleteCommentFromLikesAndComments",
                  Component: this.props.component,
                  ContentType: this.props.item != undefined ? this.props.item.type : "-",
                  ContentId: this.props.item != undefined ? this.props.item.id : "-",
                  Tenant: this.props.tenant,
                  Instance: this.props.instance,
                  UserId: this.props.token,
                  Source: this.props.source,
                  Environment: this.props.environment
                });
                this.props.delete();
              }}
              iconUrl="https://intraactivestorage.blob.core.windows.net/cdn/icons/Delete/SVG/ic_fluent_delete_48_regular.svg"
              buttonColor={this.props.highlightColor}
              style={{
                marginRight: 10
              }}
              borderRadius={5}
              showSpinner={this.props.isDeleting}
            />
            <IAButton
              label={this.localizationService.strings.LikesAndComments_Cancel}
              onClick={() => this.props.closePopup()}
              buttonColor="grey"
              borderRadius={5}
              disbaled={this.props.isDeleting}
            />
          </div>
          {this.props.deleteCommentError &&
            <div style={{ marginTop: 10 }}><i>{this.localizationService.strings.LikesAndComments_ErrorDeletingComment}</i></div>
          }
        </div>
        }
      />
    );
  }
}