exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_likesAndComments_2xxCu9moUsR16ZuV8LE8tM {\n  float: left;\n  width: 100%;\n  font-size: 12px;\n  line-height: 20px;\n  margin-top: auto;\n  padding-bottom: 10px;\n  background-color: #f6f6f6;\n  margin-bottom: 20px;\n  box-sizing: border-box;\n}\n\n.IA_commandBar_2zTJux-qEp9ZstnSYbyofa {\n  width: 100%;\n  height: 30px;\n  float: left;\n  box-sizing: border-box;\n  line-height: 30px;\n}\n\n.IA_commandBarButton_3SyuBW-Le3fyP1mK6hotV- {\n  min-width: 40px;\n  padding-right: 10px;\n  float: left;\n  cursor: pointer;\n}\n\n.IA_commandBarButtonIcon_E8yjkyW-9bWdXtxbSNrft {\n  margin-right: 5px;\n  margin-top: 1px;\n  float: left;\n}\n\n.IA_commandBarButtonText_3m-pZVKxTsCJt_DpeVaDhT {\n  float: left;\n  width: auto;\n  font-size: 14px;\n}\n\n.IA_comments_Jy_QAxkDuCKhFNgTgACif {\n  width: 100%;\n  height: auto;\n  box-sizing: border-box;\n  clear: both;\n}\n\n.IA_likes_1n193gdhXTXXAw8lFMCDp6 {\n  width: 100%;\n  box-sizing: border-box;\n}\n\n.IA_spinner_1m6zM1xujI19crUEu51FcQ {\n  -webkit-animation: spin_1h44gUu-Zy89kwncb_jjbE 700ms linear infinite;\n  -moz-animation: spin_1h44gUu-Zy89kwncb_jjbE 700ms linear infinite;\n  animation: spin_1h44gUu-Zy89kwncb_jjbE 700ms linear infinite;\n  height: 15px;\n  width: 15px;\n  background-position: center;\n  background-size: 15px;\n  position: relative;\n  float: left;\n  clear: both;\n  opacity: 0.5;\n}\n\n.IA_personWhoLiked_qZIkcA-0Z13szyc37csWp {\n  padding-bottom: 5px;\n  padding-top: 5px;\n  border-bottom: 1px solid #eeeeee;\n  width: calc(100% - 15px);\n}\n\n@-moz-keyframes spin_1h44gUu-Zy89kwncb_jjbE {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_1h44gUu-Zy89kwncb_jjbE {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_1h44gUu-Zy89kwncb_jjbE {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"IA_likesAndComments": "IA_likesAndComments_2xxCu9moUsR16ZuV8LE8tM",
	"IA_commandBar": "IA_commandBar_2zTJux-qEp9ZstnSYbyofa",
	"IA_commandBarButton": "IA_commandBarButton_3SyuBW-Le3fyP1mK6hotV-",
	"IA_commandBarButtonIcon": "IA_commandBarButtonIcon_E8yjkyW-9bWdXtxbSNrft",
	"IA_commandBarButtonText": "IA_commandBarButtonText_3m-pZVKxTsCJt_DpeVaDhT",
	"IA_comments": "IA_comments_Jy_QAxkDuCKhFNgTgACif",
	"IA_likes": "IA_likes_1n193gdhXTXXAw8lFMCDp6",
	"IA_spinner": "IA_spinner_1m6zM1xujI19crUEu51FcQ",
	"spin": "spin_1h44gUu-Zy89kwncb_jjbE",
	"IA_personWhoLiked": "IA_personWhoLiked_qZIkcA-0Z13szyc37csWp"
};