import * as React from 'react';
import { Store } from '../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './PlaylistEditPanel.css';
import { IAPanel } from '../../../../panels/Panel';
import { IATextField } from '../../../../textField/TextField';
import { LocalizationService } from '../../../../../services/LocalizationService';
import PlayService from '../../../../../services/PlayService';
import { IPlayArticle, IPlaylistExtended, IPlayRssTickerWidget, IPlaySlide, IPlaySlideExtended, IPlayWidgetExtended } from '../../../../../interfaces/IPlay';
import { Helper } from '../../../../../Helper';
import { Slide } from '../../../../player/slide/Slide';
import { SlideEditPanel } from '../../slides/slideEditPanel/SlideEditPanel';
import { PlayHelper } from '../../../PlayHelper';
import { IASpinner } from '../../../../spinner/Spinner';
import { Group } from '../../../reusableComponents/group/Group';
import { IAPlayer } from '../../../../player/Index';
import { Popup } from '../../../reusableComponents/popup/Popup';
import { IAListView } from '../../../../listView/ListView';
import { IAToggle } from '../../../../toggle/Toggle';
import { IAIcon } from '../../../../icon/Icon';
import { Footer } from '../../../../player/footer/Footer';
import { RssTicker } from '../../../../player/rssTicker/RssTicker';
import { IAButton } from '../../../../button/Button';
import { IAOptionSelector } from '../../../../optionSelector/OptionSelector';
import { IAPivot } from '../../../../pivot/Pivot';

export interface IProps {
  playlistId?: string;
  store?: Store;
  onSave: () => void;
  onClose: () => void;
}

export interface IState {
  currentSlideIndex: number;
  isLoadingSlides: boolean;
  expiredSlides?: IPlaySlide[];
  expiredSlidesCount?: number;
  previewMode: "liveMode" | "editMode";
  showConfirmClosePopup: boolean;
  showRssTickerOptions: boolean;
}

@inject('store')
@observer
export class PlaylistEditPanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      currentSlideIndex: 0,
      isLoadingSlides: false,
      previewMode: "liveMode",
      showConfirmClosePopup: false,
      showRssTickerOptions: false
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.playlistId !== this.props.playlistId && props.playlistId) {
      if (props.playlistId != "new") {
        PlayService.getPlaylistById(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, props.playlistId).then((playlist: IPlaylistExtended) => {
          this.props.store.playlistToEdit = playlist;
          this.props.store.playlistToEditBeforeChanges = JSON.stringify(this.props.store.playlistToEdit);
          this.setState({ showRssTickerOptions: !!playlist.rssTickerId });
          this.getAvailableSlides();
          this.forceUpdate();
        }).catch(e => {
          this.props.store.workingOnIt = false;
          this.props.store.generalErrorPopup = {
            technicalDetails: e
          };
        });
      } else {
        this.props.store.playlistToEdit = {
          screenFormat: "landscape",
          transitionType: "slide",
          slides: []
        };
        this.getAvailableSlides();
      }
    }
  }

  private onPlaylistSave() {
    this.props.onSave();
  }

  private getAvailableSlides(): void {
    this.setState({ isLoadingSlides: true });
    const slidesToExclude = [];
    if (this.props.store.playlistToEdit?.slides?.length > 0) {
      this.props.store.playlistToEdit.slides.forEach((slide: IPlaySlideExtended) => {
        slidesToExclude.push(slide.id);
      });
    }
    PlayService.getSlides(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, { excludeIds: slidesToExclude, showOnly: ["active", "scheduled"] }).then(slides => {
      this.props.store.availableSlides = slides.value;
      this.setState({ isLoadingSlides: false });
      this.forceUpdate();
    }).catch(e => {
      this.setState({ isLoadingSlides: false });
      this.props.store.workingOnIt = false;
      this.props.store.generalErrorPopup = {
        technicalDetails: e
      };
    });
  }

  private getExpiredSlidesForPlaylist(): void {
    PlayService.getSlidesForPlaylist(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, this.props.playlistId, { showOnly: ["expired"] }).then((result: any) => {
      this.setState({ expiredSlides: result.value, expiredSlidesCount: result.count });
    }).catch(e => {
      this.props.store.workingOnIt = false;
      this.props.store.generalErrorPopup = {
        technicalDetails: e
      };
    });
  }

  private getActiveSlides(): IPlaySlideExtended[] {
    let slides = JSON.parse(JSON.stringify(this.props.store.playlistToEdit?.slides));
    const activeSlides = [];
    if (slides.length > 0) {
      slides.forEach((slide: IPlaySlideExtended) => {
        if (new Date(slide.startDate) < new Date()) {
          slide?.widgets?.forEach((widget: IPlayWidgetExtended) => {
            if (widget?.articleViewer) {
              const activeArticles = [];
              widget?.articleViewer?.articles?.forEach((article: IPlayArticle) => {
                if (new Date(article?.startDate) < new Date()) {
                  activeArticles.push(article);
                }
              });
              widget.articleViewer.articles = activeArticles;
            }
          })
          activeSlides.push(slide);
        }
      });
    }
    return activeSlides;
  }

  public render(): JSX.Element {
    let previewWidth = this.props.store.windowWidth - 480;
    let previewHeight = (previewWidth / 16) * 9;

    let footerAndRssSizeUnit = (previewHeight > previewWidth ? previewWidth : previewHeight) / 100;
    // resize if the window height is smaller than the preview height
    if (previewHeight > window.innerHeight - 84) {
      previewHeight = window.innerHeight - 84 - 80;
      previewWidth = (previewHeight / 9) * 16;
    }
    // Portrait mode
    if (this.props.store.playlistToEdit?.screenFormat === "portrait") {
      previewHeight = window.innerHeight - 84 - 80;
      previewWidth = (previewHeight / 16) * 9;
    }

    let profileRssTicker: IPlayRssTickerWidget;
    if (this.props.store.profile?.rssTickers && this.props.store.playlistToEdit?.rssTickerId) {
      profileRssTicker = PlayHelper.getTickerbyId(this.props.store.profile.rssTickers, this.props.store.playlistToEdit.rssTickerId);
    }
    let slideIndex = this.props.store.playlistToEdit?.slides[this.state.currentSlideIndex];
    const showFooter = slideIndex && slideIndex.showFooter;
    const rssActive = slideIndex && slideIndex.showRssTicker && !!profileRssTicker;

    const activeSlidesList = [];
    const scheduledSlidesList = [];
    if (this.props.store.playlistToEdit?.slides?.length > 0) {
      this.props.store.playlistToEdit.slides.forEach((slide: IPlaySlideExtended, index) => {
        if (new Date(slide.startDate) < new Date()) {
          activeSlidesList.push(
            {
              id: slide.id,
              object: slide,
              columns: [
                {
                  header: "",
                  type: "icon",
                  icon: PlayHelper.hasSlideActiveContent(slide) ? (new Date(slide.startDate) > new Date() ? "Clock" : "Show") : "Hide",
                  color: this.props.store.darkMode ? "#ffffff" : "#333333",
                  iconSize: 20,
                  marginTop: 16
                },
                {
                  header: "",
                  type: "text",
                  text: slide.title,
                  color: this.props.store.darkMode ? "#ffffff" : "#333333"
                }
              ],
              buttons: [
                {
                  action: "edit",
                  icon: "Edit",
                  color: this.props.store.highlightColor
                }, {
                  action: "delete",
                  icon: "Close",
                  color: this.props.store.highlightColor
                }, {
                  action: index === 0 ? undefined : "moveUp",
                  iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Chevron%20Up/SVG/ic_fluent_chevron_up_48_regular.svg",
                  color: index === 0 ? this.props.store.highlightColor + "80" : this.props.store.highlightColor,
                }, {
                  action: index === this.props.store.playlistToEdit.slides.length - 1 ? undefined : "moveDown",
                  iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Chevron%20Down/SVG/ic_fluent_chevron_down_48_regular.svg",
                  color: index === this.props.store.playlistToEdit.slides.length - 1 ? this.props.store.highlightColor + "80" : this.props.store.highlightColor,
                }
              ]
            }
          );
        } else {
          scheduledSlidesList.push(
            {
              id: slide.id,
              object: slide,
              columns: [
                {
                  header: "",
                  type: "icon",
                  icon: PlayHelper.hasSlideActiveContent(slide) ? (new Date(slide.startDate) > new Date() ? "Clock" : "Show") : "Hide",
                  color: this.props.store.darkMode ? "#ffffff" : "#333333",
                  iconSize: 20,
                  marginTop: 16
                },
                {
                  header: "",
                  type: "text",
                  text: slide.title,
                  color: this.props.store.darkMode ? "#ffffff" : "#333333"
                }
              ],
              buttons: [
                {
                  action: "edit",
                  icon: "Edit",
                  color: this.props.store.highlightColor
                }, {
                  action: "delete",
                  icon: "Close",
                  color: this.props.store.highlightColor
                }
              ]
            }
          );
        }
      });
    }

    const availableSlides = [];
    if (this.props.store.availableSlides?.length > 0) {
      this.props.store.availableSlides.forEach((slide: IPlaySlide) => {
        if (
          ((slide.layout === "portraitOneRow" || slide.layout === "portraitTwoRows") && this.props.store.playlistToEdit?.screenFormat === "portrait") ||
          ((slide.layout !== "portraitOneRow" && slide.layout !== "portraitTwoRows") && this.props.store.playlistToEdit?.screenFormat === "landscape")
        )
          availableSlides.push(
            {
              id: slide.id,
              object: slide,
              columns: [
                {
                  header: "",
                  type: "text",
                  text: slide.title,
                  color: this.props.store.darkMode ? "#ffffff" : "#333333"
                }
              ],
              buttons: [
                {
                  action: "edit",
                  icon: "Edit",
                  color: this.props.store.highlightColor
                },
                {
                  action: "add",
                  icon: "Add",
                  color: this.props.store.highlightColor
                }
              ]
            }
          );
      });
    }

    const expiredSlides = [];
    if (this.state.expiredSlides?.length > 0) {
      this.state.expiredSlides.forEach((slide: IPlaySlide) => {
        expiredSlides.push(
          {
            id: slide.id,
            object: slide,
            columns: [
              // {
              //   header: "",
              //   type: "icon",
              //   icon: "Attention",
              //   color: this.props.store.darkMode ? "#ffffff" : "#333333",
              //   iconSize: 20,
              //   marginTop: 16
              // },
              {
                header: "",
                type: "text",
                text: slide.title,
                color: this.props.store.darkMode ? "#ffffff" : "#333333"
              }
            ],
            buttons: [
              {
                action: "edit",
                icon: "Edit",
                color: this.props.store.highlightColor
              },
              {
                action: "delete",
                icon: "Close",
                color: this.props.store.highlightColor
              }
            ]
          }
        );
      });
    }

    const slidesPreview = [];
    if (this.props.store.playlistToEdit?.slides?.length > 0) {
      this.props.store.playlistToEdit.slides.forEach((slide: IPlaySlideExtended, index) => {
        slidesPreview.push(
          <Slide
            key={`slide_${index}`}
            index={index}
            slide={slide}
            isActive={true}
            height={previewHeight}
            width={previewWidth}
            left={0}
            footer={slide.showFooter && this.props.store.profile?.footer}
            editPreviewMode
            city={this.props.store.city}
            environment={this.props.store.environment}
            tenantId={this.props.store.tenantId}
            profileId={this.props.store.profile.id}
            playerId='admin'
            rssTicker={profileRssTicker}
            showActivityLabel
            forecast={this.props.store.weatherData}
            headlineFont={this.props.store.profile?.fonts?.headline ? JSON.parse(this.props.store.profile?.fonts?.headline).name : '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'}
            contentFont={this.props.store.profile?.fonts?.body ? JSON.parse(this.props.store.profile?.fonts?.body).name : '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'}
            isHardcodedWeatherData={this.props.store.isUsingHardCodedWeather}
            nextSlide={() => { }}
          />
        );
      });
    }

    const rssTickerOptions = [];
    PlayHelper.getPredefinedRssTickers().forEach((ticker, i) => {
      rssTickerOptions.push(
        <div
          key={i}
          className={styles.IA_widgetType}
          style={{
            opacity: this.props.store.playlistToEdit?.rssTickerId === ticker.id || !this.props.store.playlistToEdit?.rssTickerId ? 1 : 0.5
          }}
          onClick={() => {
            this.props.store.playlistToEdit.rssTickerId = ticker.id;
            this.forceUpdate();
          }}
        >
          <div
            style={{
              height: 65,
              width: 65,
              backgroundColor: ticker.labelBackgroundColor,
              borderRadius: 10,
              overflow: "hidden",
              display: "flex"
            }}
          >
            <img
              style={{
                objectFit: "contain",
                width: "100%",
                height: "100%"
              }}
              src={ticker.labelLogo}
            />
          </div>
          <div
            className={styles.IA_widgetTypeLabel}
            style={{
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
          >
            {ticker.title}
          </div>
        </div>
      );
    });

    this.props.store.profile?.rssTickers?.forEach((ticker, i) => {
      rssTickerOptions.push(
        <div
          key={i}
          className={styles.IA_widgetType}
          style={{
            opacity: this.props.store.playlistToEdit?.rssTickerId === ticker.id || !this.props.store.playlistToEdit?.rssTickerId ? 1 : 0.5
          }}
          onClick={() => {
            this.props.store.playlistToEdit.rssTickerId = ticker.id;
            this.forceUpdate();
          }}
        >
          {ticker.labelLogo && ticker.labelLogo !== "none" ?
            <div
              style={{
                height: 65,
                width: 65,
                backgroundColor: ticker.labelBackgroundColor,
                borderRadius: 10,
                overflow: "hidden",
                display: "flex"
              }}
            >
              <img
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%"
                }}
                src={ticker.labelLogo}
              />
            </div>
            :
            <IAIcon
              url="https://intraactivestorage.blob.core.windows.net/cdn/icons/RSS/SVG/ic_fluent_rss_24_regular.svg"
              size={41}
              color={ticker.labelColor}
              style={{
                float: "left",
                backgroundColor: ticker.labelBackgroundColor,
                padding: "10px 12px",
                borderRadius: 10,
                overflow: "hidden"
              }}
            />
          }
          <div
            className={styles.IA_widgetTypeLabel}
            style={{
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
          >
            {ticker.title}
          </div>
        </div>
      );
    });

    return (
      <IAPanel
        open={(this.props.store.playlistToEdit != undefined || this.props.playlistId != undefined) && !this.props.store.slideToEdit}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        loading={false}
        width={this.props.store.windowWidth}
        darkMode={this.props.store.darkMode}
        dataAutomationIdPrefix="playlist-edit-panel"
        panelId="playlist-edit-panel"
        isInTeams={this.props.store.isTeams}
        navigationsBarContent={
          <IAButton
            label={this.props.store.playlistToEdit?.id ? this.localizationService.strings.PlayAdmin_Update?.toUpperCase() : this.localizationService.strings.PlayAdmin_Save?.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            disbaled={(!this.props.store.playlistToEdit?.title)}
            darkMode={this.props.store.darkMode}
            onClick={() => {
              this.onPlaylistSave()
            }}
            borderRadius={5}
            showSpinner={this.props.store.workingOnIt}
            style={{
              float: "right",
              position: "relative",
              marginTop: 7,
              marginRight: 10
            }}
          />
        }
        close={() => {
          if (this.props.store.playlistToEditBeforeChanges === JSON.stringify(this.props.store.playlistToEdit)) {
            this.props.store.playlistToEdit = undefined;
            this.props.onClose();
          } else {
            this.setState({ showConfirmClosePopup: true });
          }
        }}
      >
        <SlideEditPanel
          slideId={undefined}
          isOpen={!!this.props.store.slideToEdit}
          close={() => {
            this.props.store.slideToEdit = undefined;
          }}
          onSave={() => {
            this.props.store.workingOnIt = true;
            const slideModel: IPlaySlide = {
              ...this.props.store.slideToEdit,
              startDate: new Date(this.props.store.slideToEdit.startDate).toJSON(),
              endDate: this.props.store.slideToEdit.endDate ? new Date(this.props.store.slideToEdit.endDate).toJSON() : null,
              rssTicker: this.props.store.slideToEdit?.rssTicker?.id,
              widgets: this.props.store.slideToEdit?.widgets?.map(widget => widget.id)
            }
            if (this.props.store.slideToEdit.id) {
              PlayService.updateSlide(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, slideModel).then(() => {
                this.props.store.workingOnIt = false;
                // If you update an expired slide to be active
                if (this.props.store.playlistToEdit.omittedSlides.indexOf(this.props.store.slideToEdit.id) !== -1) {
                  this.props.store.playlistToEdit.omittedSlides = this.props.store.playlistToEdit.omittedSlides.filter(slideId => {
                    return slideId != this.props.store.slideToEdit.id;
                  });
                  const expiredSlides = this.state.expiredSlides.filter(slide => {
                    return slide.id != this.props.store.slideToEdit.id;
                  });
                  this.setState({ expiredSlides });
                  this.props.store.playlistToEdit.slides.push(this.props.store.slideToEdit)
                }
                // TODO: If you update an active slide to be expired
                this.props.store.slideToEdit = undefined;
                this.forceUpdate();
              }).catch(e => {
                this.props.store.workingOnIt = false;
                this.props.store.generalErrorPopup = {
                  technicalDetails: e
                };
              });
            } else {
              PlayService.createSlide(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, slideModel).then((id) => {
                this.props.store.workingOnIt = false;
                this.props.store.slideToEdit.id = id;
                this.props.store.playlistToEdit?.slides ? this.props.store.playlistToEdit.slides.push(this.props.store.slideToEdit) : this.props.store.playlistToEdit.slides = [this.props.store.slideToEdit];
                this.props.store.slideToEdit = undefined;
                this.forceUpdate();
              }).catch(e => {
                this.props.store.workingOnIt = false;
                this.props.store.generalErrorPopup = {
                  technicalDetails: e
                };
              });
            }
          }}
        />
        {this.state.showConfirmClosePopup &&
          <Popup
            headline={this.localizationService.strings.Messages_CancelPopup_Headline}
            close={() => this.setState({ showConfirmClosePopup: false })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_Yes?.toUpperCase()}
                  onClick={() => {
                    this.props.store.playlistToEdit = undefined;
                    this.props.onClose();
                    this.setState({ showConfirmClosePopup: false });
                  }}
                  borderRadius={5}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_No?.toUpperCase()}
                  onClick={() => this.setState({ showConfirmClosePopup: false })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
        {this.props.store.playlistToEdit ?
          <div
            className={styles.IA_playlistEditPanel}
            style={{
              height: window.innerHeight - 44,
              backgroundColor: this.props.store.darkMode ? "#414141" : "#777777",
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
          >
            <div className={styles.IA_content} style={{ backgroundColor: this.props.store.darkMode ? Helper.darkModeBackgroundColor : '#fff' }}>
              <h1>{this.localizationService.strings.PlayAdmin_MenuArea_PlaylistsSettings}</h1>
              <IATextField
                required
                label={this.localizationService.strings.PlayAdmin_Title?.toUpperCase()}
                placeholder={this.localizationService.strings.PlayAdmin_Title}
                text={this.props.store.playlistToEdit.title}
                darkMode={this.props.store.darkMode}
                highlightColor={this.props.store.highlightColor}
                borderRadius={5}
                labelStyle={{
                  textTransform: "uppercase",
                  fontSize: 12
                }}
                onChange={(title: string) => {
                  this.props.store.playlistToEdit.title = title;
                  this.forceUpdate();
                }}
              />
              <IAOptionSelector
                label={this.localizationService.strings.PlayAdmin_PlayerFormat}
                darkMode={this.props.store.darkMode}
                disabled={this.props.store.playlistToEdit.id != undefined}
                borderRadius={5}
                labelStyle={{
                  textTransform: "uppercase",
                  fontSize: 12
                }}
                options={[
                  {
                    icon: "Layout Main One Column",
                    key: "landscape",
                    text: this.localizationService.strings.PlayAdmin_PlayerFormatLandscape
                  },
                  {
                    icon: "Layout Portrait One Row",
                    key: "portrait",
                    text: this.localizationService.strings.PlayAdmin_PlayerFormatPortrait
                  },
                ]}
                selected={this.props.store.playlistToEdit.screenFormat ? this.props.store.playlistToEdit.screenFormat : "landscape"}
                highlightColor={this.props.store.highlightColor}
                compact
                iconSize={30}
                onChange={(screenFormat: any) => {
                  this.props.store.playlistToEdit.screenFormat = screenFormat;
                  this.forceUpdate();
                }}
                style={{ clear: "none", marginTop: 20 }}
              />
              <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
              <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : '#333333' }}>{this.localizationService.strings.PlayAdmin_AddSomeFun}</h3>
              <IAToggle
                labelStyle={{
                  fontSize: "12px"
                }}
                label={this.localizationService.strings.PlayAdmin_ShowHalloween?.toUpperCase()}
                checked={this.props.store.playlistToEdit.showHalloween}
                color={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                style={{
                  marginTop: 20
                }}
                onChange={() => {
                  this.props.store.playlistToEdit.showHalloween = this.props.store.playlistToEdit.showHalloween != undefined ? !this.props.store.playlistToEdit.showHalloween : true;
                  this.forceUpdate();
                }}
              />
              <IAToggle
                labelStyle={{
                  fontSize: "12px"
                }}
                label={this.localizationService.strings.PlayAdmin_ShowSnow?.toUpperCase()}
                checked={this.props.store.playlistToEdit.showSnow}
                color={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                style={{
                  marginTop: 20
                }}
                onChange={() => {
                  this.props.store.playlistToEdit.showSnow = this.props.store.playlistToEdit.showSnow != undefined ? !this.props.store.playlistToEdit.showSnow : true;
                  this.forceUpdate();
                }}
              />
              <IAToggle
                labelStyle={{
                  fontSize: "12px"
                }}
                label={this.localizationService.strings.PlayAdmin_ShowEaster?.toUpperCase()}
                checked={this.props.store.playlistToEdit.showEaster}
                color={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                style={{
                  marginTop: 20
                }}
                onChange={() => {
                  this.props.store.playlistToEdit.showEaster = this.props.store.playlistToEdit.showEaster != undefined ? !this.props.store.playlistToEdit.showEaster : true;
                  this.forceUpdate();
                }}
              />
              <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
              <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : '#333333' }}>{this.localizationService.strings.PlayAdmin_SetupoRssTicker}</h3>
              <IAToggle
                labelStyle={{
                  fontSize: "12px"
                }}
                label={this.localizationService.strings.PlayAdmin_PlaylistEditPanelAddTicker?.toUpperCase()}
                checked={this.state.showRssTickerOptions}
                color={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                onChange={() => {
                  this.setState({ showRssTickerOptions: !this.state.showRssTickerOptions }, () => {
                    if (!this.state.showRssTickerOptions) {
                      this.props.store.playlistToEdit.rssTickerId = null;
                    }
                    this.forceUpdate();
                  });
                }}
              />
              {this.state.showRssTickerOptions &&
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    clear: "both",
                    float: "left",
                    marginTop: 20
                  }}
                >
                  {rssTickerOptions}
                </div>
              }
              <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
              <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : '#333333', clear: "both" }}>{this.localizationService.strings.PlayAdmin_MenuArea_Slides}</h3>
              <Group
                key={"activeSlides"}
                count={activeSlidesList?.length}
                label={this.localizationService.strings.PlayAdmin_ActiveSlides}
                darkMode={this.props.store.darkMode}
                textAlign={"left"}
                labelColor={this.props.store.darkMode ? "#a8a8a8" : "gray"}
              >
                {activeSlidesList?.length > 0 &&
                  <IAListView
                    id="ListViewOfPlaylists2"
                    listElements={activeSlidesList}
                    backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
                    hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
                    buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
                    darkMode={this.props.store.darkMode}
                    defaultAction={undefined}
                    selectedAction={(action: string, id: string, slide: any) => {
                      const index = this.props.store.playlistToEdit.slides.findIndex(slide => slide.id === id);
                      switch (action) {
                        case "moveUp":
                          this.props.store.playlistToEdit.slides.splice(index - 1, 0, this.props.store.playlistToEdit.slides.splice(index, 1)[0]);
                          break;
                        case "moveDown":
                          this.props.store.playlistToEdit.slides.splice(index + 1, 0, this.props.store.playlistToEdit.slides.splice(index, 1)[0]);
                          break;
                        case "delete":
                          this.props.store.playlistToEdit.slides = this.props.store.playlistToEdit.slides.filter(slide => {
                            return slide.id != id;
                          });
                          this.getAvailableSlides();
                          this.setState({ previewMode: this.state.previewMode === "liveMode" ? "editMode" : "liveMode" }, () => {
                            setTimeout(() => {
                              this.setState({ previewMode: this.state.previewMode === "liveMode" ? "editMode" : "liveMode" });
                            }, 10);
                          });
                          break;
                        case "edit":
                          this.props.store.slideToEdit = slide;
                          break;
                        default:
                          break;
                      }
                      setTimeout(() => {
                        this.forceUpdate();
                      }, 10);
                    }}
                  />
                }
              </Group>
              <Group
                key={"scheduledSlides"}
                count={scheduledSlidesList?.length}
                label={this.localizationService.strings.PlayAdmin_ScheduledSlides}
                darkMode={this.props.store.darkMode}
                textAlign={"left"}
                labelColor={this.props.store.darkMode ? "#a8a8a8" : "gray"}
              >
                {scheduledSlidesList?.length > 0 &&
                  <IAListView
                    id="ListViewOfPlaylists2"
                    listElements={scheduledSlidesList}
                    backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
                    hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
                    buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
                    darkMode={this.props.store.darkMode}
                    defaultAction={undefined}
                    selectedAction={(action: string, id: string, slide: any) => {
                      switch (action) {
                        case "delete":
                          this.props.store.playlistToEdit.slides = this.props.store.playlistToEdit.slides.filter(slide => {
                            return slide.id != id;
                          });
                          this.getAvailableSlides();
                          this.setState({ previewMode: this.state.previewMode === "liveMode" ? "editMode" : "liveMode" }, () => {
                            setTimeout(() => {
                              this.setState({ previewMode: this.state.previewMode === "liveMode" ? "editMode" : "liveMode" });
                            }, 10);
                          });
                          break;
                        case "edit":
                          this.props.store.slideToEdit = slide;
                          break;
                        default:
                          break;
                      }
                      setTimeout(() => {
                        this.forceUpdate();
                      }, 10);
                    }}
                  />
                }
              </Group>
              {this.props.store.playlistToEdit?.omittedSlides?.length > 0 &&
                <Group
                  key={"expiredSlides"}
                  label={this.localizationService.strings.PlayAdmin_ExpiredSlides}
                  count={this.props.store.playlistToEdit?.omittedSlides?.length != undefined ? this.props.store.playlistToEdit?.omittedSlides?.length : 0}
                  darkMode={this.props.store.darkMode}
                  textAlign={"left"}
                  labelColor={this.props.store.darkMode ? "#a8a8a8" : "gray"}
                  collapsed
                  onExpand={() => {
                    this.getExpiredSlidesForPlaylist();
                  }}
                >
                  {expiredSlides?.length > 0 &&
                    <IAListView
                      id="ListViewOfPlaylists3"
                      listElements={expiredSlides}
                      backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
                      hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
                      buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
                      darkMode={this.props.store.darkMode}
                      defaultAction={undefined}
                      selectedAction={(action: string, id: string) => {
                        switch (action) {
                          case "delete":
                            this.props.store.playlistToEdit.omittedSlides = this.props.store.playlistToEdit.omittedSlides.filter(slideId => {
                              return slideId != id;
                            });
                            const expiredSlides = this.state.expiredSlides.filter(slide => {
                              return slide.id != id;
                            });
                            this.setState({ expiredSlides });
                            break;
                          case "edit":
                            PlayService.getSlide(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, id).then(slideExtended => {
                              this.props.store.slideToEdit = slideExtended;
                            }).catch(e => {
                              this.props.store.workingOnIt = false;
                              this.props.store.generalErrorPopup = {
                                technicalDetails: e
                              };
                            });
                            break;
                          default:
                            break;
                        }
                        setTimeout(() => {
                          this.forceUpdate();
                        }, 10);
                      }}
                    />
                  }
                  <IAButton
                    label={this.localizationService.strings.PlayAdmin_RemoveAllExpiredSlides?.toUpperCase()}
                    buttonColor={this.props.store.highlightColor}
                    darkMode={this.props.store.darkMode}
                    onClick={() => {
                      this.props.store.playlistToEdit.omittedSlides = [];
                      this.setState({ expiredSlides: [] });
                      this.forceUpdate();
                    }}
                    showSpinner={this.props.store.workingOnIt}
                    borderRadius={5}
                    style={{
                      float: "left",
                      position: "relative",
                      clear: "both",
                      marginBottom: 10,
                      marginTop: 20
                    }}
                  />
                </Group>
              }
              <Group
                key={"availableSlides"}
                label={this.localizationService.strings.PlayAdmin_AvailableSlides}
                count={availableSlides?.length}
                darkMode={this.props.store.darkMode}
                labelColor={this.props.store.darkMode ? "#a8a8a8" : "gray"}
                textAlign={"left"}
              >
                {availableSlides?.length > 0 &&
                  <IAListView
                    id="ListViewOfPlaylists4"
                    listElements={availableSlides}
                    backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
                    hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
                    buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
                    darkMode={this.props.store.darkMode}
                    defaultAction={"add"}
                    selectedAction={(action: string, id: string) => {
                      switch (action) {
                        case "add":
                          PlayService.getSlide(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, id).then(slideExtended => {
                            this.props.store.playlistToEdit.slides.push(slideExtended)
                            this.getAvailableSlides();
                            this.forceUpdate();
                            this.setState({ previewMode: this.state.previewMode === "liveMode" ? "editMode" : "liveMode" }, () => {
                              setTimeout(() => {
                                this.setState({ previewMode: this.state.previewMode === "liveMode" ? "editMode" : "liveMode" });
                              }, 10);
                            });
                          }).catch(e => {
                            this.props.store.workingOnIt = false;
                            this.props.store.generalErrorPopup = {
                              technicalDetails: e
                            };
                          });
                          break;
                        case "edit":
                          PlayService.getSlide(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, id).then(slideExtended => {
                            this.props.store.slideToEdit = slideExtended;
                          }).catch(e => {
                            this.props.store.workingOnIt = false;
                            this.props.store.generalErrorPopup = {
                              technicalDetails: e
                            };
                          });
                          break;
                        default:
                          break;
                      }
                      setTimeout(() => {
                        this.forceUpdate();
                      }, 10);
                    }}
                  />
                }
              </Group>
              <IAButton
                label={this.localizationService.strings.PlayAdmin_CreateNewSlide?.toUpperCase()}
                buttonColor={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                onClick={() => {
                  this.props.store.slideToEdit = PlayHelper.getEmptySlide();
                  this.forceUpdate();
                }}
                showSpinner={this.props.store.workingOnIt}
                borderRadius={5}
                style={{
                  float: "left",
                  position: "relative",
                  clear: "both",
                  marginBottom: 20,
                  marginTop: 0
                }}
              />
            </div>
            <div className={styles.IA_previewArea}>
              <div className={styles.IA_previewSettings}>
                <div
                  className={styles.IA_previewSetting}
                  style={{ backgroundColor: this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : '#ffffff' }}
                >
                  <IAPivot
                    highlightColor={"#ffffff"}
                    selectedTabBackgrondColor={this.props.store.highlightColor}
                    textColor={this.props.store.darkMode ? "#eeeeee" : "#999999"}
                    asTabs
                    items={[
                      {
                        key: "liveMode",
                        text: this.localizationService.strings.PlayAdmin_LiveMode?.toUpperCase()
                      },
                      {
                        key: "editMode",
                        text: this.localizationService.strings.PlayAdmin_EditMode?.toUpperCase(),
                      }
                    ]}
                    style={{
                      fontSize: "14px",
                      color: this.props.store.darkMode ? "#ffffff" : "#333333",
                      textTransform: "uppercase",
                    }}
                    autoTabWidth
                    height={30}
                    selectedKey={this.state.previewMode}
                    select={(previewMode: "liveMode" | "editMode") => {
                      this.setState({ previewMode });
                      setTimeout(() => {
                        this.forceUpdate();
                      }, 100);
                    }}
                  />
                </div>
                {this.state.previewMode === "editMode" && slidesPreview?.length > 1 &&
                  <div
                    className={styles.IA_previewSetting}
                    style={{
                      backgroundColor: this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : '#ffffff',
                      marginLeft: 20,
                      float: "right"
                    }}
                  >
                    <IAButton
                      iconColor={this.props.store.highlightColor}
                      iconUrl='https://intraactivestorage.blob.core.windows.net/cdn/icons/Chevron%20Left/SVG/ic_fluent_chevron_left_48_regular.svg'
                      style={{
                        marginTop: 2
                      }}
                      onClick={() => this.props.store.playlistToEdit.slides?.length && this.setState({ currentSlideIndex: this.state.currentSlideIndex - 1 >= 0 ? this.state.currentSlideIndex - 1 : this.props.store.playlistToEdit.slides.length - 1 }, this.forceUpdate)}
                    />
                    <IAButton
                      iconColor={this.props.store.highlightColor}
                      iconUrl="https://intraactivestorage.blob.core.windows.net/cdn/icons/Chevron%20Right/SVG/ic_fluent_chevron_right_48_regular.svg"
                      darkMode={this.props.store.darkMode}
                      style={{
                        marginTop: 2
                      }}
                      onClick={() => this.props.store.playlistToEdit.slides?.length && this.setState({ currentSlideIndex: this.state.currentSlideIndex + 1 <= this.props.store.playlistToEdit.slides.length - 1 ? this.state.currentSlideIndex + 1 : 0 }, this.forceUpdate)}
                    />
                  </div>
                }
                {this.state.previewMode === "editMode" && this.state.currentSlideIndex != undefined && this.props.store.playlistToEdit?.slides?.length > 0 && this.props.store.playlistToEdit.slides[this.state.currentSlideIndex]?.title && this.props.store.playlistToEdit.slides[this.state.currentSlideIndex]?.startDate &&
                  <div
                    style={{
                      position: "relative",
                      float: "right",
                      textAlign: "right",
                      marginTop: 6,
                      color: "#ffffff"
                    }}
                  >
                    {new Date(this.props.store.playlistToEdit.slides[this.state.currentSlideIndex].startDate) > new Date() ? `${this.props.store.playlistToEdit.slides[this.state.currentSlideIndex]?.title?.toUpperCase()} (${this.localizationService.strings.PlayAdmin_Scheduled?.toUpperCase()})` : this.props.store.playlistToEdit.slides[this.state.currentSlideIndex]?.title?.toUpperCase()}
                  </div>
                }
              </div>
              {!this.props.store.isMobile &&
                <div
                  className={styles.IA_preview}
                  style={{
                    height: previewHeight,
                    width: previewWidth,
                    paddingLeft: this.props.store.playlistToEdit?.screenFormat === "portrait" ? `calc(50% - ${previewWidth / 2}px)` : 40
                  }}
                >
                  <div
                    className={styles.IA_previewScreen}
                    style={{
                      height: previewHeight,
                      width: previewWidth,
                    }}
                  >
                    {this.state.previewMode === "liveMode" ?
                      <IAPlayer
                        environment={this.props.store.environment}
                        preview
                        previewWeather={this.props.store.weatherData}
                        previewIsHardcodedWeatherData={this.props.store.isUsingHardCodedWeather}
                        player={{
                          width: previewWidth,
                          height: previewHeight,
                          city: this.props.store.city,
                          playlist: {
                            showHalloween: this.props.store.playlistToEdit.showHalloween,
                            showSnow: this.props.store.playlistToEdit.showSnow,
                            showEaster: this.props.store.playlistToEdit.showEaster,
                            screenFormat: this.props.store.playlistToEdit?.screenFormat ? this.props.store.playlistToEdit.screenFormat : "landscape",
                            slides: this.getActiveSlides(), //this.props.store.playlistToEdit.slides,
                            transitionType: this.props.store.playlistToEdit.transitionType,
                            rssTickerId: this.props.store.playlistToEdit.rssTickerId
                          },
                          playProfile: this.props.store.profile,
                          tenantId: this.props.store.tenantId
                        }}
                      />
                      :
                      <>
                        <div
                          style={{
                            position: "relative",
                            float: "left",
                            clear: "both",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          {slidesPreview[this.state.currentSlideIndex]}
                          {profileRssTicker &&
                            <RssTicker
                              rssTicker={{ rssTicker: profileRssTicker }}
                              sizeUnit={footerAndRssSizeUnit}
                              playerWidth={previewWidth}
                              bottom={this.props.store.profile.footer ? footerAndRssSizeUnit * 8 : 0}
                              isActive={rssActive}
                              headlineFont={this.props.store.profile?.fonts?.headline ? JSON.parse(this.props.store.profile?.fonts?.headline).name : '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'}
                              contentFont={this.props.store.profile?.fonts?.body ? JSON.parse(this.props.store.profile?.fonts?.body).name : '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'}
                            />
                          }
                          {this.props.store.profile?.footer && showFooter &&
                            <Footer
                              footer={this.props.store.profile?.footer}
                              sizeUnit={footerAndRssSizeUnit}
                              city={this.props.store.city}
                              editMode={true}
                              currentWeather={this.props.store.weatherData?.current}
                            />
                          }
                        </div>
                      </>
                    }
                  </div>
                </div>
              }
            </div>
          </div>
          :
          <div
            className={styles.IA_loading}
            style={{
              backgroundColor: this.props.store.darkMode ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.5)"
            }}
          >
            <IASpinner
              color={this.props.store.highlightColor}
            />
            <div className={styles.IA_weAreWorkingOnIt}>{this.localizationService.strings.Composer_WeAreWorkingOnIt}</div>
          </div>
        }
      </IAPanel>
    );
  }


}