exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".likesAndComments_12_V_VdmYWfC-KVDeC9tam {\n  position: relative;\n  float: right;\n  height: 20px;\n  font-size: 14px;\n  line-height: 20px;\n  margin-top: 8px;\n}\n\n.likesAndComments_12_V_VdmYWfC-KVDeC9tam .likes_24kwgCY2e-xFcbM2stKeko {\n  height: 20px;\n  float: right;\n  position: relative;\n  margin-right: 5px;\n  cursor: pointer;\n}\n\n.likesAndComments_12_V_VdmYWfC-KVDeC9tam .spinner_fIUTQhFKMzQeUmGN7DMUo {\n  height: 18px;\n  width: 18px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_30yGTCOz_LUkiEUAxECWb3 700ms linear infinite;\n  -moz-animation: spin_30yGTCOz_LUkiEUAxECWb3 700ms linear infinite;\n  animation: spin_30yGTCOz_LUkiEUAxECWb3 700ms linear infinite;\n}\n\n.likesAndComments_12_V_VdmYWfC-KVDeC9tam .comments_1aS2z6Z2LBhpcMsL32GiPo {\n  height: 20px;\n  float: right;\n  position: relative;\n  margin-right: 3px;\n}\n\n.likesAndComments_12_V_VdmYWfC-KVDeC9tam .likesText_1rOUvmO5Uiu6KZNaNoel84 {\n  float: left;\n  margin-left: 5px;\n  margin-top: -1px;\n}\n\n.likesAndComments_12_V_VdmYWfC-KVDeC9tam .likesTextVertical_11QFINjxxAHv2PBby6nmEV {\n  float: left;\n  clear: both;\n  margin-top: -7px;\n  text-align: center;\n  width: 100%;\n  box-sizing: border-box;\n  padding-left: 1px;\n}\n\n.likesAndComments_12_V_VdmYWfC-KVDeC9tam .commentText_1KDouJAfLDAc4tsV4Z9tL9 {\n  float: left;\n  margin-left: 4px;\n  margin-top: -2px;\n}\n\n@-moz-keyframes spin_30yGTCOz_LUkiEUAxECWb3 {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_30yGTCOz_LUkiEUAxECWb3 {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_30yGTCOz_LUkiEUAxECWb3 {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"likesAndComments": "likesAndComments_12_V_VdmYWfC-KVDeC9tam",
	"likes": "likes_24kwgCY2e-xFcbM2stKeko",
	"spinner": "spinner_fIUTQhFKMzQeUmGN7DMUo",
	"spin": "spin_30yGTCOz_LUkiEUAxECWb3",
	"comments": "comments_1aS2z6Z2LBhpcMsL32GiPo",
	"likesText": "likesText_1rOUvmO5Uiu6KZNaNoel84",
	"likesTextVertical": "likesTextVertical_11QFINjxxAHv2PBby6nmEV",
	"commentText": "commentText_1KDouJAfLDAc4tsV4Z9tL9"
};