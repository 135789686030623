import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as styles from './Users.css';
import { Store } from '../../stores';
import { IListElement, IPlayProfile, IPlayUser } from '../../../../interfaces/IPlay';
import { LocalizationService } from '../../../../services/LocalizationService';
import { Helper } from '../../../../Helper';
import PlayService from '../../../../services/PlayService';
import { Group } from '../../reusableComponents/group/Group';
import { Popup } from '../../reusableComponents/popup/Popup';
import { IAButton } from '../../../button/Button';
import { IASpinner } from '../../../spinner/Spinner';
import { PermissionsEditPanel } from '../../dashboard/settingsPanel/permissions/permissionsEditPanel/PermissionsEditPanel';
import { IAHeader } from '../../../header/Header';
import { IAListView } from '../../../listView/ListView';

export interface IProps {
  display: boolean;
  store?: Store;
}

export interface IState {
  profileList: JSX.Element[];
  adminUsers?: IListElement[];
  userToDelete?: string;
  adminPlayerToDelete?: string;
  selectedProfileId?: string;
  loading: boolean;
  isAddingAdminUser: boolean;
  isAddingUser: boolean;
  profiles?: { key: string, value: any }[];
}

@inject('store')
@observer
export class Users extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      profileList: undefined,
      loading: false,
      isAddingAdminUser: false,
      isAddingUser: false
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.display && !this.props.display) {
      this.getProfiles();
    }
  }

  private getListElement(user: IPlayUser, profile: IPlayProfile): IListElement {
    return (
      {
        object: profile,
        id: user.userId,
        columns: [
          {
            header: this.localizationService.strings.PlayAdmin_DisplayName?.toUpperCase(),
            type: "text",
            text: user.displayName,
            backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
            color: this.props.store.darkMode ? "#ffffff" : "#333333",
          },
          {
            header: this.localizationService.strings.PlayAdmin_UserPrincipalName?.toUpperCase(),
            type: "text",
            text: user.userPrincipalName,
            backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
            color: this.props.store.darkMode ? "#ffffff" : "#333333",
          }
        ],
        buttons: [
          {
            action: "delete",
            icon: "Trash",
            color: this.props.store.highlightColor
          }
        ]
      }
    );
  }

  private getProfiles(): void {
    const profileList = [];
    this.setState({ loading: true });
    const adminUsers = [];
    PlayService.getAdminUsers(this.props.store.environment, this.props.store.token, this.props.store.tenantId).then((users: IPlayUser[]) => {
      if (users) {
        users.forEach((user: IPlayUser) => {
          adminUsers.push({
            id: user.userId,
            columns: [
              {
                header: this.localizationService.strings.PlayAdmin_DisplayName?.toUpperCase(),
                type: "text",
                text: user.displayName,
                backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
              },
              {
                header: this.localizationService.strings.PlayAdmin_UserPrincipalName?.toUpperCase(),
                type: "text",
                text: user.userPrincipalName,
                backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
              }
            ],
            buttons: [
              {
                action: "delete",
                icon: "Trash",
                color: this.props.store.highlightColor
              }
            ]
          });
        });
        this.setState({ adminUsers });
      }
      PlayService.getPlayProfiles(this.props.store.environment, this.props.store.token, this.props.store.tenantId).then(async (profiles: IPlayProfile[] | 401) => {
        if (profiles === 401) {
          this.props.store.showAuthenticationErrorPopup = true;
        } else {
          profiles = Helper.sortAlphabeticalByTitle(profiles);
          const availableProfiles = [];
          if (profiles.length > 1) {
            profiles.forEach(async (profile: IPlayProfile) => {
              availableProfiles.push({
                key: profile.id,
                value: profile.title
              });
              const profileUsers: IListElement[] = [];
              if (profile.title) {
                await PlayService.getProfileUsers(this.props.store.environment, this.props.store.token, this.props.store.tenantId, profile.id).then((users: IPlayUser[]) => {
                  users.forEach((user: IPlayUser) => {
                    profileUsers.push(this.getListElement(user, profile));
                  });
                });
                profileList.push(
                  <Group
                    key={profile.id}
                    label={profile.title}
                    count={profileUsers?.length}
                    darkMode={this.props.store.darkMode}
                  >
                    <IAListView
                      id="ListViewOfProfilesUsers"
                      listElements={profileUsers}
                      backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
                      hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
                      buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
                      defaultAction={undefined}
                      darkMode={this.props.store.darkMode}
                      selectedAction={(action: string, id: string, profile: any) => {
                        switch (action) {
                          case "delete":
                            this.setState({ userToDelete: id, selectedProfileId: profile?.id });
                            break;
                          default:
                            break;
                        }
                      }}
                    />
                  </Group>
                );
                this.setState({ profileList, loading: false, profiles: availableProfiles }, () => this.forceUpdate());
              }
            });
          } else {
            if (profiles?.length === 1) {
              const profileUsers: IListElement[] = [];
              const profile = {
                key: profiles[0].id,
                value: profiles[0].title
              };
              if (profiles[0].title) {
                await PlayService.getProfileUsers(this.props.store.environment, this.props.store.token, this.props.store.tenantId, profiles[0].id).then((users: IPlayUser[]) => {
                  users.forEach((user: IPlayUser) => {
                    profileUsers.push(this.getListElement(user, profiles[0]));
                  });
                });
                profileList.push(
                  <IAListView
                    id="ListViewOfProfilesUsers2"
                    listElements={profileUsers}
                    backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
                    hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
                    buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
                    defaultAction={undefined}
                    darkMode={this.props.store.darkMode}
                    selectedAction={(action: string, id: string, profile: any) => {
                      switch (action) {
                        case "delete":
                          this.setState({ userToDelete: id, selectedProfileId: profile?.id });
                          break;
                        default:
                          break;
                      }
                    }}
                  />
                );
                this.setState({ profiles: [profile], profileList, loading: false }, () => this.forceUpdate());
              }
            } else {
              this.setState({ profiles: [], profileList: [], loading: false }, () => this.forceUpdate());
            }
          }
        }
      });
    });
  }

  private onDelete(userId: string) {
    this.props.store.workingOnIt = true;
    if (this.state.userToDelete) {
      PlayService.removeUserFromProfile(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.state.selectedProfileId, userId).then(() => {
        this.props.store.workingOnIt = false;
        this.setState({ userToDelete: undefined, selectedProfileId: undefined });
        this.getProfiles();
      });
    } else if (this.state.adminPlayerToDelete) {
      PlayService.removeAdminUser(this.props.store.environment, this.props.store.token, this.props.store.tenantId, userId).then(() => {
        this.props.store.workingOnIt = false;
        this.setState({ adminPlayerToDelete: undefined });
        this.getProfiles();
      });
    }
  }

  private onAddUser(userId: string, profileId?: string) {
    PlayService.addUserToProfile(this.props.store.environment, this.props.store.token, this.props.store.tenantId, profileId ? profileId : this.state.profiles[0].key, userId).then(() => {
      this.getProfiles();
      this.setState({ isAddingAdminUser: false, isAddingUser: false });
    })
  }

  private onAddAdminUser(userId: string) {
    PlayService.addAdminUser(this.props.store.environment, this.props.store.token, this.props.store.tenantId, userId).then(() => {
      this.getProfiles();
      this.setState({ isAddingAdminUser: false, isAddingUser: false });
    })
  }

  public render(): JSX.Element {
    return this.props.display ? (
      <div>
        <PermissionsEditPanel
          isAddingUser={this.state.isAddingAdminUser || this.state.isAddingUser}
          profiles={this.state.profiles?.length > 1 && this.state.isAddingUser ? this.state.profiles : undefined}
          onAddUser={(userId, profileId) => {
            console.log(profileId);
            this.state.isAddingAdminUser ? this.onAddAdminUser(userId) : this.onAddUser(userId, profileId);
            this.setState({ isAddingAdminUser: false, isAddingUser: false });
          }}
          onClose={() => {
            this.setState({ isAddingAdminUser: false, isAddingUser: false });
            this.getProfiles();
          }}
        />
        {(this.state.userToDelete || this.state.adminPlayerToDelete) &&
          <Popup
            headline={this.state.adminPlayerToDelete ? this.localizationService.strings.PlayAdmin_RemoveAdminUser : this.localizationService.strings.PlayAdmin_RemoveUserFromProfile}
            close={() => this.setState({ userToDelete: undefined, adminPlayerToDelete: undefined, selectedProfileId: undefined })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  label={this.localizationService.strings.DeletePopup_Yes}
                  darkMode={this.props.store.darkMode}
                  disbaled={this.state.profiles == undefined || this.state.profiles?.length === 0}
                  onClick={() => {
                    if (this.state.userToDelete) {
                      this.onDelete(this.state.userToDelete);
                    }
                    if (this.state.adminPlayerToDelete) {
                      this.onDelete(this.state.adminPlayerToDelete);
                    }
                  }}
                  borderRadius={5}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.DeletePopup_No}
                  onClick={() => this.setState({ userToDelete: undefined, selectedProfileId: undefined })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
        <div className={styles.IA_users}>
          <IAHeader
            id="play-users-header"
            title={this.localizationService.strings.PlayAdmin_Users}
            description={this.localizationService.strings.PlayAdmin_LicenseDescription}
            expandButtonColor={this.props.store.highlightColor}
            navigationButtonColor="#666666"
            navigationButtonIconColor="#ffffff"
            isMobile={this.props.store.isMobile}
            darkMode={this.props.store.darkMode}
            slides={[
              {
                backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-bg-black.png",
                headline: this.localizationService.strings.PlayAdmin_Users,
                text: this.localizationService.strings.PlayAdmin_UsersDescription,
                image: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-illustration-users.png"
              }
            ]}
          />
          <h1 style={{ width: "calc(100% - 200px)" }}>{this.localizationService.strings.PlayAdmin_AdminUsers}</h1>
          <IAButton
            label={this.localizationService.strings.PlayAdmin_Add?.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onClick={() => this.setState({ isAddingAdminUser: true })}
            borderRadius={5}
            showSpinner={this.props.store.workingOnIt}
            style={{
              float: "right",
              position: "relative",
              marginTop: 20
            }}
          />
          {this.state.loading ?
            <IASpinner
              color={this.props.store.highlightColor}
              style={{
                float: "left",
                marginLeft: "calc(50% - 20px)",
                clear: "both"
              }}
            />
            :
            <div className={styles.IA_users}>
              {this.state.adminUsers &&
                <IAListView
                  id="ListViewOfAdminUsers"
                  listElements={this.state.adminUsers}
                  backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
                  hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
                  buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
                  defaultAction={undefined}
                  darkMode={this.props.store.darkMode}
                  selectedAction={(action: string, id: string) => {
                    switch (action) {
                      case "delete":
                        this.setState({ adminPlayerToDelete: id });
                        break;
                      default:
                        break;
                    }
                  }}
                />
              }
            </div>
          }
        </div>
        <div className={styles.IA_users}
          style={{
            marginTop: 40
          }}
        >
          <h1 style={{ width: "calc(100% - 200px)" }}>{this.localizationService.strings.PlayAdmin_ProfilesAndUsers}</h1>
          <IAButton
            label={this.localizationService.strings.PlayAdmin_Add?.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onClick={() => this.setState({ isAddingUser: true })}
            borderRadius={5}
            showSpinner={this.props.store.workingOnIt}
            style={{
              float: "right",
              position: "relative",
              marginTop: 20
            }}
          />
          <div className={styles.IA_users}>
            {this.state.loading ?
              <IASpinner
                color={this.props.store.highlightColor}
                style={{
                  float: "left",
                  marginLeft: "calc(50% - 20px)",
                  clear: "both"
                }}
              />
              :
              this.state.profileList
            }
          </div>
        </div>
      </div>
    )
      :
      (<></>);
  }


}