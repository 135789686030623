exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_footer_1ebqG3JJZn5kpMAonXYmUD {\n  position: absolute;\n  width: 100%;\n  left: 0\n}\n\n.IA_footerWeatherAndClockContainer_1k0MIJgOKTRKZ_Jfz2zM5f {\n  height: 100%;\n  display: flex;\n  flex-direction: row-reverse;\n}\n\n.IA_logo_3HHRZpkYc9WQyHjmn1RlkG {\n  height: 100%;\n  width: 50%;\n  background-repeat: no-repeat;\n  background-size: contain;\n  position: relative;\n  float: left;\n}\n\n.IA_clock_1hVGLF92jGQYs78GJd2GyA {\n  height: 100%;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-end;\n}\n\n.IA_time_2N9OWBFr3y8E-AGIjXON1E {\n  position: relative;\n  float: right;\n  text-align: left;\n  margin-left: 19%;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_timeDivider_1Jn1UIf0NvQ0hIpP2HRJhZ {\n  margin: 0 2px 0 2px;\n}\n\n.IA_date_2hYgalPfuLbH5HQbfzFFss {\n  position: relative;\n  float: right;\n  text-align: right;\n  font-weight: lighter;\n}\n\n\n.IA_weather_2LsUJAaQ-BeqlZ9FSztcc1 {\n  height: 100%;\n  position: relative;\n  float: right;\n  box-sizing: border-box;\n}\n\n.IA_weatherIcon_1ouICpvabAhFgavZERg1bO {\n  position: relative;\n  text-align: left;\n}\n\n.IA_weatherTemperature_umKkfbDgcxE1qkr_VQrfH {\n  position: relative;\n  float: right;\n  text-align: left;\n  width: 50%;\n\n}\n\n.IA_weatherArea_3jbSsqIsvffzA7E9iY-6jq {\n  position: relative;\n  float: right;\n  text-align: left;\n  font-weight: lighter;\n  width: 50%;\n  clear: right;\n\n}", ""]);

// Exports
exports.locals = {
	"IA_footer": "IA_footer_1ebqG3JJZn5kpMAonXYmUD",
	"IA_footerWeatherAndClockContainer": "IA_footerWeatherAndClockContainer_1k0MIJgOKTRKZ_Jfz2zM5f",
	"IA_logo": "IA_logo_3HHRZpkYc9WQyHjmn1RlkG",
	"IA_clock": "IA_clock_1hVGLF92jGQYs78GJd2GyA",
	"IA_time": "IA_time_2N9OWBFr3y8E-AGIjXON1E",
	"IA_timeDivider": "IA_timeDivider_1Jn1UIf0NvQ0hIpP2HRJhZ",
	"IA_date": "IA_date_2hYgalPfuLbH5HQbfzFFss",
	"IA_weather": "IA_weather_2LsUJAaQ-BeqlZ9FSztcc1",
	"IA_weatherIcon": "IA_weatherIcon_1ouICpvabAhFgavZERg1bO",
	"IA_weatherTemperature": "IA_weatherTemperature_umKkfbDgcxE1qkr_VQrfH",
	"IA_weatherArea": "IA_weatherArea_3jbSsqIsvffzA7E9iY-6jq"
};