import * as React from 'react';
import { IListElement } from '../../interfaces/IPlay';
import { ListElement } from './listElement/ListElement';
import { ListElementHeader } from './listElementHeader/ListElementHeader';
import * as styles from './ListView.css';

export interface IListViewProps {
  id: string;
  listElements: IListElement[];
  backgroundColor: string;
  hoverBackgroundColor: string;
  buttonAreaDividerColor: string;
  defaultAction: string;
  darkMode: boolean;
  selectedAction: (action: string, id: string, object?: any) => void;
  hideHeaders?: boolean;
  rowHeight?: string;
  userLocation?: string;
}

export class IAListView extends React.Component<IListViewProps> {

  constructor(props: IListViewProps) {
    super(props);
  }

  public render(): JSX.Element {
    let header;
    const rows = [];
    this.props.listElements.forEach((listElement: IListElement) => {
      if (!header) {
        header = (
          <ListElementHeader
            listViewId={this.props.id}
            key={`listElementHeader_${listElement.id}`}
            listElement={listElement}
            darkMode={this.props.darkMode}
          />
        );
      }
      rows.push(
        <ListElement
          listViewId={this.props.id}
          key={`listElement_${listElement.id}`}
          listElement={listElement}
          backgroundColor={this.props.backgroundColor}
          hoverBackgroundColor={this.props.hoverBackgroundColor}
          buttonAreaDividerColor={this.props.buttonAreaDividerColor}
          defaultAction={this.props.defaultAction}
          darkMode={this.props.darkMode}
          selectedAction={(action: string, id: string, object?: any) => this.props.selectedAction(action, id, object)}
          rowHeight={this.props.rowHeight}
          userLocation={this.props.userLocation}
        />
      );
    });
    return (
      <div className={styles.IA_listView}>
        {!this.props.hideHeaders && header}
        {rows}
      </div>
    );
  }
}