import * as React from 'react';
import { IEnvironment } from '../../../../interfaces/IEnvironment';
import { IPlayGalleryWidget } from '../../../../interfaces/IPlay';
import { Environment } from '../../../../services/Environment';
import { LocalizationService } from '../../../../services/LocalizationService';
import QRCodeService from '../../../../services/QRCodeService';
import { PlayHelper } from '../../../playAdmin/PlayHelper';
import { Image } from '../image/Image';
import * as styles from './Gallery.css';
import { Helper } from '../../../../Helper';

export interface IProps {
  title: string;
  gallery: IPlayGalleryWidget;
  playerHeight: number;
  playerWidth?: number;
  areaWidth?: number;
  transition: string;
  duration: number;
  environment: IEnvironment;
  tenantId: string;
  profileId: string;
  keepOriginal?: boolean;
  backgroundColor: string;
  widgetId?: string;
  language?: string;
  willRestart: () => void;
}

export interface IState {
  visibleGalleryIndex: number;
  marginLeftIndex: number;
  qrCode?: string;
}

export class Gallery extends React.Component<IProps, IState> {

  private localizationService: LocalizationService;
  private galleryInterval: ReturnType<typeof setInterval> | undefined;

  constructor(props: IProps) {
    super(props);
    this.state = {
      visibleGalleryIndex: 0,
      marginLeftIndex: 0
    };
  }

  componentDidMount(): void {
    try {
      this.localizationService = new LocalizationService(PlayHelper.getLocalizerLanguageFromPlayerLanguage(this.props.language));
      const size = this.props.playerHeight / 10;
      this.setThumbnailMargin(size);
      this.galleryInterval = setInterval(() => {
        if (this.props.gallery && this.props.gallery.images && this.state.visibleGalleryIndex < this.props.gallery.images.length - 1) {
          this.setState({ visibleGalleryIndex: this.state.visibleGalleryIndex + 1 });
          this.setThumbnailMargin(size);
        } else {
          this.props.willRestart();
          this.setState({ visibleGalleryIndex: 0, marginLeftIndex: 0 });
        }
        if (this.props.widgetId) {
          Helper.setLocalStorage(`galleryIndex_${this.props.widgetId}`, `${this.state.visibleGalleryIndex}`);
        }
      }, this.props.duration * 1000);
      let socialGalleryUrl = undefined;
      if (this.props.tenantId && this.props.profileId) {
        if (!this.props.widgetId) {
          // when there is no widgetId, that means the user is creating a new widget. Display a QR code to https://intraactive.net/products/replay/
          socialGalleryUrl = "https://intraactive.net/products/replay/";
        } else {
          const title = this.props.title ?? "";
          const orientation = this.props.playerHeight > this.props.playerWidth ? "vertical" : "horizontal";
          const objectFit = this.props.keepOriginal ? "contain" : "cover";
          const imageSize = PlayHelper.getStandardImageFormat(this.props.playerWidth, this.props.playerHeight);
          socialGalleryUrl = `${Environment.getEnvironmentForPlayFrontend(this.props.environment)}?tenant=${this.props.tenantId}&profile=${this.props.profileId}&widget=${this.props.widgetId}&name=${title}&orientation=${orientation}&objectFit=${objectFit}&imageSize=${imageSize}`;
        }
        QRCodeService.generateQRCode(socialGalleryUrl).then((qrCode: string) => {
          if (qrCode) {
            this.setState({ qrCode });
          }
        })
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private setThumbnailMargin(size: number): void {
    try {
      const galleryWidth = document.getElementById(`IA_Gallery_${this.props.widgetId}`)?.clientWidth ?? 0;
      if (galleryWidth && (this.state.visibleGalleryIndex + 1) * size > galleryWidth + (size * this.state.marginLeftIndex)) {
        this.setState({ marginLeftIndex: this.state.visibleGalleryIndex });
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  public render(): JSX.Element {
    let sizeUnit = (this.props.playerHeight < this.props.playerWidth ? this.props.playerHeight : this.props.playerWidth) / 100;
    const showThumbnails = this.props.gallery && this.props.gallery.showThumbnails && this.props.gallery.images && this.props.gallery.images.length > 1;
    const thumbnailSize = this.props.playerHeight / 10;
    const thumbnailsOnScreen = this.props.areaWidth / thumbnailSize;
    const images = [];
    const thumbnails = [];
    try {
      if (this.props.gallery && this.props.gallery.images) {
        const imageUrls = [...this.props.gallery.images];
        if (this.props.gallery.showSocialGallery) {
          // reverse the list if using social gallery
          imageUrls.reverse();
        }
        imageUrls.forEach((image: string, index) => {
          images.push(
            <div
              key={`image_${index}`}
              className={this.props.transition === "slide" ? styles.IA_imageWithSlidingTransition : styles.IA_image}
              style={{
                opacity: this.props.transition === "slide" || index === this.state.visibleGalleryIndex ? 1 : 0,
                transition: this.props.transition === "crossfade" ? "opacity 500ms ease-in-out" : "none",
                width: this.props.transition === "slide" ? `${100 / this.props.gallery.images.length}%` : "100%",
                height: this.props.gallery.showThumbnails && this.props.gallery.images.length > 1 ? `calc(100% - ${thumbnailSize}px)` : "100%",
                willChange: this.props.transition === "crossfade" ? "opacity" : ""
              }}
            >
              <Image
                imageUrl={image}
                environment={this.props.environment}
                tenantId={this.props.tenantId}
                profileId={this.props.profileId}
                keepOriginal={this.props.keepOriginal}
                backgroundColor={this.props.backgroundColor}
                isActive={index === this.state.visibleGalleryIndex}
              />
            </div>
          );
          if (this.props.gallery.images?.length > 1) {
            thumbnails.push(
              <div
                key={`thumbnail_${index}`}
                className={styles.IA_thumbnail}
                style={{
                  height: thumbnailSize,
                  width: thumbnailSize,
                  opacity: index === this.state.visibleGalleryIndex ? 1 : 0.4,
                  willChange: "opacity"
                }}
              >
                <Image
                  height={thumbnailSize}
                  width={thumbnailSize}
                  imageUrl={image}
                  environment={this.props.environment}
                  tenantId={this.props.tenantId}
                  profileId={this.props.profileId}
                  backgroundColor={this.props.backgroundColor}
                  isActive={this.state.marginLeftIndex <= index && index <= this.state.marginLeftIndex + thumbnailsOnScreen}
                />
              </div>
            );
          }
        });
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
    return (
      <div
        className={styles.IA_gallery}
        id={`IA_Gallery_${this.props.widgetId}`}
      >
        <div
          className={styles.IA_gallery}
          style={{
            position: "absolute",
            transition: "left 500ms ease-in-out",
            width: this.props.transition === "slide" ? `${this.props.gallery.images.length * 100}%` : "100%",
            left: this.props.transition === "slide" ? `${-100 * this.state.visibleGalleryIndex}%` : 0,
            willChange: this.props.transition === "slide" ? "left" : ""
          }}
        >
          {images}
        </div>
        {showThumbnails &&
          <div
            className={styles.IA_thumbnails}
            style={{
              height: thumbnailSize
            }}
          >
            <div
              className={styles.IA_thumbnailsContainer}
              style={{
                marginLeft: -(thumbnailSize * this.state.marginLeftIndex)
              }}
            >
              {thumbnails}
            </div>
          </div>
        }
        {this.state.qrCode && this.props.gallery?.showSocialGallery &&
          <div
            style={{
              position: "absolute",
              right: sizeUnit * 3,
              bottom: showThumbnails ? thumbnailSize + (sizeUnit * 2) : sizeUnit * 2,
              zIndex: 1000,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: sizeUnit * 13,
              borderRadius: sizeUnit * 1.5,
              backgroundColor: "#ffffff",
            }}
          >
            <div
              style={{
                backgroundColor: "#ffffff",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "#333333",
                borderRadius: `${sizeUnit * 1.5}px ${sizeUnit * 1.5}px 0px 0px`,
              }}
            >
              <div
                style={{
                  marginTop: 0,
                  fontWeight: "bold",
                  fontSize: sizeUnit * 1.3,
                  marginBottom: sizeUnit,
                  textAlign: "center",
                  paddingTop: sizeUnit,
                }}
              >
                {this.props.gallery.linkText ?? this.localizationService.strings.PlayAdmin_AddImage?.toUpperCase()}
              </div>
              <img
                src={this.state.qrCode}
                style={{
                  width: sizeUnit * 11,
                  height: sizeUnit * 11,
                  paddingLeft: sizeUnit,
                  paddingRight: sizeUnit,
                  paddingBottom: sizeUnit,
                  backgroundColor: "#ffffff"
                }}
              />
            </div>
            <div
              style={{
                paddingTop: sizeUnit,
                paddingBottom: sizeUnit,
                backgroundColor: "#333333",
                color: "#ffffff",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: `0px 0px ${sizeUnit * 1.5}px ${sizeUnit * 1.5}px`,
                width: sizeUnit * 13,
              }}
            >
              <div
                style={{
                  marginBottom: 0,
                  fontSize: sizeUnit * 1.4,
                  marginTop: 0,
                  fontWeight: "lighter"
                }}
              >
                <b>REPLAY</b> SOCIAL
              </div>
            </div>
          </div>
        }
      </div>
    );
  }

  public componentWillUnmount(): void {
    clearInterval(this.galleryInterval);
  }


}