import * as React from 'react';
import DateTimeService from '../../../../../services/DateTimeService';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IAIcon } from '../../../../icon/Icon';
import * as styles from './ListItem.css';
import { PlayHelper } from '../../../../playAdmin/PlayHelper';

export interface IProps {
  sizeUnit: number;
  borderColor?: string;
  title?: string;
  teaser?: string;
  startDate?: string;
  endDate?: string;
  isAllDayEvent?: boolean;
  location?: string;
  showTime?: boolean;
  showTimeBox?: boolean;
  textColor?: string;
  boxColor?: string;
  timeBatchBackgroundColor?: string;
  timeBatchTextColor?: string;
  timeBatchBorderColor?: string;
  textLines?: number;
  hidden: boolean;
  language?: string;
  headlineFont: string;
  contentFont: string;
}

export interface IState {
}

export class ListItem extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService;
  private readonly dateTimeService: DateTimeService = new DateTimeService();

  constructor(props: IProps) {
    super(props);
    this.state = {
    };
    this.localizationService = new LocalizationService(PlayHelper.getLocalizerLanguageFromPlayerLanguage(this.props.language));
  }

  public render(): JSX.Element {
    let dateLabel;
    let dateIcon = "Calendar";
    try {
      dateLabel = this.props.isAllDayEvent ? this.dateTimeService.ConvertToDDMMYYYY(this.props.startDate) : `${this.dateTimeService.ConvertToDDMMYYYYHHMM(this.props.startDate)} - ${this.dateTimeService.ConvertToDDMMYYYYHHMM(this.props.endDate)}`;
      if (this.props.showTimeBox) {
        dateLabel = `${this.dateTimeService.ConvertToHHMM(this.props.startDate)} - ${this.dateTimeService.ConvertToHHMM(this.props.endDate)}`;
        dateIcon = "Clock";
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
    return (
      <div
        className={styles.IA_listItem}
        style={{
          opacity: this.props.hidden ? 0 : 1,
          marginBottom: this.props.sizeUnit * 2,
          width: "100%",
          padding: this.props.boxColor && this.props.boxColor !== "transparent" ? `${this.props.sizeUnit * 1.5}px ${this.props.sizeUnit * 2}px` : 0,
          backgroundColor: this.props.boxColor,
          color: this.props.textColor,
          border: `1px solid ${this.props.borderColor ? this.props.borderColor : "transparent"}`,
          minHeight: this.props.showTimeBox ? this.props.sizeUnit * 14 : undefined,
          fontFamily: this.props.contentFont
        }}
      >
        {this.props.showTimeBox &&
          <div
            style={{
              position: "absolute",
              float: "left",
              width: this.props.sizeUnit * 10,
              height: this.props.sizeUnit * 10,
              boxSizing: "border-box",
              marginTop: this.props.sizeUnit * 0.5,
              border: `1px solid ${this.props.timeBatchBorderColor}`,
              backgroundColor: this.props.timeBatchBackgroundColor,
              color: this.props.timeBatchTextColor
            }}
          >
            {/* One day event */}
            {this.dateTimeService.isStartAndEndDateTheSameDay(new Date(this.props.startDate), new Date(this.props.endDate)) ?
              /* All day event */
              this.props.isAllDayEvent ?
                (this.dateTimeService.ConvertToDDMMYYYY(this.props.startDate) === this.dateTimeService.ConvertToDDMMYYYY(new Date()) ?
                  <div
                    className={styles.IA_now}
                    style={{
                      fontSize: `${this.props.sizeUnit * 2.2}px`,
                      color: this.props.timeBatchTextColor,
                      lineHeight: `${this.props.sizeUnit * 9}px`
                    }}
                  >
                    {this.localizationService.strings.Player_Now?.toUpperCase()}
                  </div>
                  :
                  <div
                    className={styles.IA_now}
                    style={{
                      fontSize: `${this.props.sizeUnit * 2.2}px`,
                      color: this.props.timeBatchTextColor,
                      lineHeight: `${this.props.sizeUnit * 9}px`
                    }}
                  >
                    {`${this.dateTimeService.getDayInMonth(this.props.startDate)}. ${this.dateTimeService.getNameOfMonth(this.props.startDate, this.props.isAllDayEvent)?.substring(0, 3)?.toUpperCase()}`}
                  </div>
                )
                :
                (this.dateTimeService.isStartAndEndDateTheSameDay(new Date(this.props.startDate), new Date(this.props.endDate)) ?
                  <div
                    className={styles.IA_now}
                    style={{
                      fontSize: `${this.props.sizeUnit * 2.2}px`,
                      color: this.props.timeBatchTextColor,
                      lineHeight: `${this.props.sizeUnit * 9}px`
                    }}
                  >
                    {`${this.dateTimeService.getDayInMonth(this.props.startDate)}. ${this.dateTimeService.getNameOfMonth(this.props.startDate, this.props.isAllDayEvent)?.substring(0, 3)?.toUpperCase()}`}
                  </div>
                  :
                  <>
                    <div
                      className={styles.IA_startDate}
                      style={{
                        fontSize: `${this.props.sizeUnit * 2.2}px`,
                        marginTop: this.props.sizeUnit * 1,
                        color: this.props.timeBatchTextColor
                      }}
                    >
                      {this.dateTimeService.ConvertToHHMM(this.props.startDate)}
                    </div>
                    <div
                      className={styles.IA_startDateendDateDivider}
                      style={{
                        marginTop: this.props.sizeUnit * 0.7,
                        backgroundColor: this.props.timeBatchTextColor
                      }}
                    />
                    <div
                      className={styles.IA_endDate}
                      style={{
                        marginTop: this.props.sizeUnit * 0.5,
                        fontSize: `${this.props.sizeUnit * 2.2}px`,
                        color: this.props.timeBatchTextColor
                      }}
                    >
                      {this.dateTimeService.ConvertToHHMM(this.props.endDate)}
                    </div>
                  </>
                )
              :
              /* More than one day event */
              <>
                <div
                  className={styles.IA_startDate}
                  style={{
                    fontSize: `${this.props.sizeUnit * 2.2}px`,
                    marginTop: this.props.sizeUnit * 1,
                    color: this.props.timeBatchTextColor
                  }}
                >
                  {!this.props.isAllDayEvent ?
                    `${this.dateTimeService.getDayInMonth(this.props.startDate)}. ${this.dateTimeService
                      .getNameOfMonth(this.props.startDate, this.props.isAllDayEvent)?.substring(0, 3)?.toUpperCase()}`
                    :
                    `${this.dateTimeService.getDayInMonth(this.props.startDate)}. ${this.dateTimeService.getNameOfMonth(this.props.startDate, this.props.isAllDayEvent)?.substring(0, 3)?.toUpperCase()}`
                  }
                </div>
                <div
                  className={styles.IA_startDateendDateDivider}
                  style={{
                    marginTop: this.props.sizeUnit * 0.7,
                    backgroundColor: this.props.timeBatchTextColor
                  }}
                />
                <div
                  className={styles.IA_endDate}
                  style={{
                    marginTop: this.props.sizeUnit * 0.5,
                    fontSize: `${this.props.sizeUnit * 2.2}px`,
                    color: this.props.timeBatchTextColor
                  }}
                >
                  {`${this.dateTimeService.getDayInMonth(this.props.endDate)}. ${this.dateTimeService.getNameOfMonth(this.props.endDate, this.props.isAllDayEvent)?.substring(0, 3)?.toUpperCase()}`}

                </div>
              </>
            }
          </div>
        }
        {this.props.title &&
          <div
            className={styles.IA_title}
            style={{
              fontSize: this.props.sizeUnit * 3.5,
              marginLeft: this.props.showTimeBox ? this.props.sizeUnit * 13 : 0,
              width: this.props.showTimeBox ? `calc(100% - ${this.props.sizeUnit * 13}px)` : "100%",
              marginBottom: this.props.sizeUnit,
            }}
          >
            {this.props.title}
          </div>
        }
        {this.props.teaser &&
          <div
            className={styles.IA_teaser}
            style={{
              fontSize: this.props.sizeUnit * 2.5,
              marginLeft: this.props.showTimeBox ? this.props.sizeUnit * 13 : 0,
              width: this.props.showTimeBox ? `calc(100% - ${this.props.sizeUnit * 13}px)` : "100%",
              marginBottom: this.props.sizeUnit * 1.5,
              lineClamp: this.props.textLines ? this.props.textLines : 1,
              WebkitLineClamp: this.props.textLines ? this.props.textLines : 1
            }}
          >
            {this.props.teaser}
          </div>
        }
        {this.props.startDate && this.props.showTime && !(this.props.isAllDayEvent && this.props.showTimeBox) &&
          <div
            className={styles.IA_byline}
            style={{
              fontSize: this.props.sizeUnit * 2,
              marginLeft: this.props.showTimeBox ? this.props.sizeUnit * 13 : 0,
              marginBottom: this.props.sizeUnit * 0.5,
              opacity: 0.8
            }}
          >
            <IAIcon
              title={dateIcon}
              size={this.props.sizeUnit * 3}
              color={this.props.textColor}
              style={{
                float: "left",
                marginTop: -2
              }}
            />
            <div style={{
              float: "left",
              marginLeft: this.props.sizeUnit,
            }}>
              {dateLabel}
            </div>
          </div>
        }
        {this.props.location &&
          <div
            className={styles.IA_byline}
            style={{
              marginLeft: this.props.showTimeBox ? this.props.sizeUnit * 13 : 0,
              fontSize: this.props.sizeUnit * 2,
              opacity: 0.8
            }}
          >
            <IAIcon
              title="Address"
              size={this.props.sizeUnit * 3}
              color={this.props.textColor}
              style={{
                float: "left",
              }}
            />
            <div style={{
              float: "left",
              marginLeft: this.props.sizeUnit
            }}>
              {this.props.location}
            </div>
          </div>
        }
      </div>
    );
  }
}