exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".wordImagePasteErrorMessageContainer_25fQqkf62QhmvtOOTKSRcT {\n  display: flex;\n  justify-content: center;\n}\n\n.wordImagePasteErrorMessage_3l0Z45KWOQznbAo3NubKsX {\n  display: flex;\n  justify-content: center;\n  background-color: orange;\n  border-radius: 5px;\n  transition: all .5s, height .75s;\n  width: 100%;\n  position: relative;\n  text-align: center;\n  align-items: center;\n  padding-left: 15px;\n  padding-right: 30px;\n}\n\n.editorHasFocus_1NvaRCmNfWicJb-_UuAMKs [class*=\"fr-toolbar\"] {\nz-index: 3000000 !important;\n}\n\n.editorHasBlur_34n6Qta9Kw2PYe7GaUEMbr [class*=\"fr-toolbar\"] {\nz-index: 1 !important;\n}", ""]);

// Exports
exports.locals = {
	"wordImagePasteErrorMessageContainer": "wordImagePasteErrorMessageContainer_25fQqkf62QhmvtOOTKSRcT",
	"wordImagePasteErrorMessage": "wordImagePasteErrorMessage_3l0Z45KWOQznbAo3NubKsX",
	"editorHasFocus": "editorHasFocus_1NvaRCmNfWicJb-_UuAMKs",
	"editorHasBlur": "editorHasBlur_34n6Qta9Kw2PYe7GaUEMbr"
};