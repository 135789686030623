import * as React from "react";
import * as styles from "./Card.css";
import "../../styles/BasicIA.css";
import { ICardDesign } from "../../interfaces/ICardDesign";
import { ICardCover } from "../../interfaces/ICardCover";
import { IACardCover } from "./cover/CardCover";
import { IATooltip } from "../tooltip/Tooltip";
import { IEventSettings } from "../../interfaces/IEventSettings";
import DateTimeService from "../../services/DateTimeService";
import { IAIcon } from "../icon/Icon";
import { IACardFooter } from "./footer/CardFooter";
import { ILike } from "../../interfaces/ILike";
import { IInstance } from "../../interfaces/IInstance";
import { IUser } from "../../interfaces/IUser";
import { ILabel } from "../../interfaces/ILabel";
import { ENTER_KEY, SPACEBAR_KEY } from "../../global/Constants";
import { ICardFooterElement } from "../../interfaces/ICard";
import { IMessageCategory } from "../../interfaces/IMessage";
import { IAMessageCategories } from "../viewer/categories/MessageCategories";
import { IEnvironment } from "../../interfaces/IEnvironment";
import Moment from "react-moment";
import { IFontSlots } from "../../interfaces/IFontSlots";

export interface ICardProps {
  // SETTINGS
  id?: string;
  design?: ICardDesign;
  styles?: any;
  width?: number;
  isMobile?: boolean;
  type?: "Event" | "Message" | "ContentCreator" | "ContentOrganizer";
  instance?: IInstance;
  token?: string;
  tenant?: string;
  environment?: IEnvironment;
  user?: IUser;
  hasDarkBackground?: boolean;
  showSkeleton?: boolean;
  source?: any;
  alwaysShowFooter?: boolean;
  footerElements?: ICardFooterElement[];
  footerLeftText?: string;
  darkMode?: boolean;
  isPanelOpen?: boolean;
  userLocation?: string;
  // CONTENT
  cover?: ICardCover;
  tags?: string[];
  tagsWithColor?: IMessageCategory[];
  title?: string;
  teaser?: string;
  eventSettings?: IEventSettings;
  date?: Date;
  location?: string;
  author?: string;
  likes?: ILike[];
  uniqueViews?: number;
  comments?: number;
  isUnread?: boolean;
  labels?: ILabel[];
  showPinIcon?: boolean;
  isSummaryDisabled?: boolean;
  showLikesDimmed?: boolean;
  showCommentsDimmed?: boolean;
  showUniqueViewsDimmed?: boolean;
  hideCommandBarButton?: boolean;
  fontSlots?: IFontSlots;
  // CALL BACKS
  onClickOpen?: () => void;
  onClickEdit?: () => void;
  onClickPreview?: () => void;
  onClickCopy?: () => void;
  onClickDelete?: () => void;
  likesUpdated?: (likes: ILike[]) => void;
  commentsUpdated?: (comments: number) => void;
  commentsClick?: () => void;
}

export interface ICardSate { }

export class IACard extends React.Component<ICardProps, ICardSate> {
  private readonly dateTimeService: DateTimeService = new DateTimeService();
  private cardRef: HTMLDivElement;

  constructor(props: ICardProps) {
    super(props);
    this.state = {
    };
  }

  componentDidMount(): void {
    // To check if the labels will overflow when they have rendered the first time
    setTimeout(() => {
      this.forceUpdate();
    }, 0);
  }

  public render(): JSX.Element {

    // TAGS
    const listOfTags = [];
    if (this.props.tags && this.props.tags.length > 0) {
      this.props.tags.forEach((tag: any) => {
        listOfTags.push(<div key={"tag" + tag}>{tag}</div>);
      });
    }
    let tags = undefined;
    if (this.props.tags && this.props.tags.length > 0) {
      let tagText = this.props.tags ? this.props.tags.slice(0, 1).reduce((x, y) => {
        return x + (x ? ", " : "") + y;
      }, "") + (this.props.tags.length > 1 ? ` +${this.props.tags.length - 1}` : "")
        : undefined;
      tags = (
        <div
          className={styles.IA_tag}
          key={`categories-${this.props.id}`}
          style={{
            color: this.props.design.textColor
          }}
        >
          {tagText}
        </div>
      );
    }
    // SUMMARY
    const showLongSummary = this.props.cover && (!this.props.cover.imageURL && !this.props.cover.videoURL) ? true : false;

    // FOOTER
    let showFooter = false;
    if (
      this.props.alwaysShowFooter ||
      this.props.likes != undefined ||
      this.props.comments != undefined ||
      this.props.uniqueViews != undefined ||
      this.props.onClickEdit != undefined ||
      this.props.onClickPreview != undefined ||
      this.props.onClickCopy != undefined ||
      this.props.onClickDelete != undefined
    ) {
      showFooter = true;
    }
    // LABELS
    const labelElements = document.getElementsByClassName(`IA_label_${this.props.id}`);
    let showMoreLabels = false;
    let numberOfLabelsToDisplay;
    if (labelElements) {
      let labelsWidth = 0;
      for (let i = 0; i < labelElements.length; i++) {
        labelsWidth += labelElements[i].clientWidth;
        if (labelsWidth > (this.props.width - 50) && numberOfLabelsToDisplay == undefined) {
          numberOfLabelsToDisplay = i;
        }
      }
      if (labelsWidth > (this.props.width - 50) || document.getElementById(`IA_moreLabel_${this.props.id}`)) {
        showMoreLabels = true;
      }
    }
    const visibleLabels = [];
    const hiddenLabels = [];
    if (this.props.labels && this.props.labels.length > 0) {
      this.props.labels.forEach((label: ILabel, index) => {
        if (numberOfLabelsToDisplay == undefined || numberOfLabelsToDisplay > index) {
          const labelElement = (
            <div
              key={`${index}visibleLabels`}
              className={`${styles.IA_label} IA_label_${this.props.id}`}
              style={{
                backgroundColor: label.backgroundColor ? label.backgroundColor : `${this.props.design.textColor}10`,
                padding: label.backgroundColor === "transparent" ? 0 : (label.text ? "0 10px 0 8px" : "0 6px 0 6px"),
                borderRadius: label.borderRadius
              }}
            >
              {label.image &&

                <img
                  style={{
                    borderRadius: 5,
                    margin: "4px 4px 4px 0",
                    width: 22,
                    height: 22,
                    float: "left",
                    position: "relative"
                  }}
                  src={label.image}
                />
              }
              {(label.icon || label.iconUrl) &&
                <IAIcon
                  title={label.icon}
                  url={label.iconUrl}
                  size={label.iconSize ? label.iconSize : 18}
                  color={label.iconColor ? label.iconColor : (label.textColor ? label.textColor : this.props.design.textColor)}
                  style={{
                    float: "left",
                    marginTop: label.iconSize ? (6 - (label.iconSize - 18) / 2) : 6
                  }}
                />
              }
              {label.text &&
                <div
                  className={styles.IA_labelText}
                  style={{
                    color: label.textColor ? label.textColor : this.props.design.textColor,
                    marginLeft: label.icon ? 6 : 2,
                    fontSize: label.textSize ? label.textSize : 14
                  }}
                >
                  {label.text}
                </div>
              }
            </div>
          );
          if (label.tooltip) {
            visibleLabels.push(
              <IATooltip
                content="Tooltip"
                type={"bubble"}
                darkMode={this.props.darkMode}
                styles={{
                  width: 90,
                  textAlign: "center",
                  marginBottom: "5px",
                  marginLeft: -3
                }}
                borderRadius={5}
                componentContent={
                  <div>
                    {label.tooltip}
                  </div>
                }>
                {labelElement}
              </IATooltip>

            )
          } else {
            visibleLabels.push(
              labelElement
            )
          }
        } else {
          hiddenLabels.push(
            <div
              key={`${index}hiddenLabels`}
              className={`${styles.IA_label} IA_label_${this.props.id}`}
              style={{
                backgroundColor: label.backgroundColor ? label.backgroundColor : `${this.props.design.textColor}10`,
                borderRadius: label.borderRadius,
                margin: 4
              }}
            >
              {(label.icon || label.iconUrl) &&
                <IAIcon
                  title={label.icon}
                  url={label.iconUrl}
                  size={18}
                  color={label.iconColor ? label.iconColor : (label.textColor ? label.textColor : this.props.design.textColor)}
                  style={{
                    float: "left",
                    marginTop: 6
                  }}
                />
              }
              {label.text &&
                <div
                  className={styles.IA_labelText}
                  style={{
                    color: label.textColor ? label.textColor : this.props.design.textColor,
                    marginLeft: label.icon ? 6 : 2
                  }}
                >
                  {label.text}
                </div>
              }
            </div>
          )
        }
      });
    }
    const labelsPosition = this.props.labels ? (showFooter ? 50 : 10) : undefined;
    // LOCATION
    const locationPosition = this.props.eventSettings && this.props.eventSettings.location != undefined ? (labelsPosition ? labelsPosition + 37 : (showFooter ? 50 : 10)) : undefined;
    // EVENT DATA
    const eventTime = this.props.eventSettings && this.props.eventSettings.showTimespand && this.props.eventSettings.startDate && this.props.eventSettings.endDate ? this.dateTimeService.getEventString(this.props.eventSettings.startDate, this.props.eventSettings.endDate, this.props.eventSettings.isAllDayEvent, true) : undefined;
    const eventTimePosition = eventTime ? (locationPosition ? locationPosition + 26 : (labelsPosition ? labelsPosition + 35 : (showFooter ? 50 : 10))) : undefined;
    // CARD HEIGHT
    let height = this.props.cover ? this.props.width / (16 / 9) : 0;
    height += this.props.tags || this.props.tagsWithColor ? 23 : 0;
    height += this.props.title != undefined ? (this.props.isSummaryDisabled ? 73 : 53) : 0;
    height += this.props.teaser != undefined ? 60 : 0;
    height += this.props.date || this.props.author ? 22 : 0;
    height += eventTime ? 38 : 0;
    height += this.props.eventSettings && this.props.eventSettings.location != undefined ? 26 : 0;
    height += this.props.labels ? 34 : 0;
    height += this.props.author ? 12 : 0;
    height += showFooter ? 52 : 10;
    let contentHeight = height;
    if (this.props.cover?.imageURL || this.props.cover?.videoURL || this.props.eventSettings) {
      contentHeight = contentHeight - this.props.width / (16 / 9);
    }
    if (showFooter) {
      contentHeight = contentHeight - 42;
    }
    const showCover = this.props.cover && (this.props.cover.imageURL || this.props.cover.videoURL || (this.props.type === "Event" && this.props.eventSettings?.displayDateOnCover) || this.props.cover.html) && !this.props.showSkeleton;
    return (
      <div
        key={`IACARD_${this.props.id}`}
        className={`${styles.IA_card} IA_OnFocus`}
        id={`card_${this.props.id}`}
        ref={(ref) => this.cardRef = ref}
        tabIndex={0}
        style={{
          backgroundColor: !this.props.showSkeleton && this.props.design.backgroundColor ? this.props.design.backgroundColor : "transparent",
          border: this.props.design.borderColor != undefined && !this.props.showSkeleton ? `1px solid ${this.props.design.borderColor}` : "none",
          boxShadow: this.props.design.showDropShadow && !this.props.showSkeleton ? "rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px" : "none",
          height: height,
          borderLeft: this.props.isUnread && !this.props.showSkeleton ? `4px solid ${this.props.design.unreadHighlightColor}` : this.props.design.borderColor != undefined && !this.props.showSkeleton ? `1px solid ${this.props.design.borderColor}` : "none",
          borderRadius: this.props.design?.borderRadius,
          ...this.props.styles,
        }}
        onKeyDown={(event) => {
          if (event.key === ENTER_KEY || event.key === SPACEBAR_KEY) {
            event.stopPropagation();
            if (this.props.onClickOpen) {
              this.props.onClickOpen();
            }
            event.currentTarget.blur();
          }
        }}
        onMouseOver={() => {
          const listElement = this.cardRef;
          if (listElement) {
            listElement.style.backgroundColor = this.props.design?.hoverBackgroundColor;
          }
        }}
        onMouseOut={() => {
          const listElement = this.cardRef;
          if (listElement) {
            listElement.style.backgroundColor = this.props.design?.backgroundColor;
          }
        }}
      >
        {this.props.showPinIcon &&
          <div
            className={styles.IA_pinCoverOverlay}
            style={{
              cursor: this.props.onClickOpen ? "pointer" : undefined
            }}
            onClick={this.props.onClickOpen ? () => this.props.onClickOpen() : undefined}
          >
            {showCover
              ?
              <img
                src="https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/pinned.png"
                style={{
                  width: 22,
                  height: 22
                }}
              />
              :
              <IAIcon
                url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Pin/SVG/ic_fluent_pin_48_filled.svg"
                size={22}
                color={this.props.design.highlightColor}
              />
            }
          </div>
        }
        {/* COVER */}
        {showCover &&
          <IACardCover
            id={this.props.id}
            imageURL={this.props.cover?.imageURL}
            iaMessageBgImagesFromBlob={this.props.cover?.iaMessageBgImagesFromBlob}
            videoURL={this.props.cover?.videoURL}
            html={this.props.cover.html}
            eventSettings={this.props.eventSettings}
            height={this.props.width / (16 / 9)}
            isMobile={this.props.isMobile}
            cropSettings={this.props.cover?.cropSettings}
            modified={this.props.cover?.modified}
            token={this.props.token}
            tenant={this.props.tenant}
            environment={this.props.environment}
            borderRadius={this.props.design?.borderRadius}
            onClickOpen={this.props.onClickOpen ? () => this.props.onClickOpen() : undefined}
            isPanelOpen={this.props.isPanelOpen}
          />
        }
        { }
        {/* CONTENT */}
        {this.props.showSkeleton ?
          <div
            className={styles.IA_skeleton}
            style={{
              opacity: this.props.hasDarkBackground ? 0.1 : 1
            }}
          >
            {this.props.cover &&
              <div
                className={styles.IA_skeletonImage}
                style={{
                  borderRadius: this.props.design.borderRadius ? `${this.props.design.borderRadius}px ${this.props.design.borderRadius}px 0 0` : "none"
                }}
              />}
            {this.props.title && <div className={styles.IA_skeletonHeadline} />}
            {this.props.title && <div className={styles.IA_skeletonHeadline2} />}
            {this.props.teaser && <div className={styles.IA_skeletonText} />}
            {this.props.teaser && <div className={styles.IA_skeletonText2} />}
            {this.props.teaser && <div className={styles.IA_skeletonText3} />}
            {(this.props.date || this.props.author) && <div className={styles.IA_skeletonPersonaText} />}
          </div>
          :
          <div
            style={{
              width: "100%",
              height: contentHeight,
              cursor: this.props.onClickOpen ? "pointer" : "default"
            }}
            onClick={this.props.onClickOpen ? () => this.props.onClickOpen() : undefined}
          >
            {/* TAGS - prefer colored over non colored */}
            {this.props.tagsWithColor?.length > 0
              ?
              <>
                <IAMessageCategories
                  categories={this.props.tagsWithColor}
                  paddingTop={5}
                  paddingLeft={10}
                  paddingRight={10}
                  showOverflowCounter
                  disablePopup={this.props.isMobile}
                  fontSlots={this.props.fontSlots}
                  textColorWhenTransparentBackground={this.props.design.textColor}
                />
              </>
              :
              <>
                {this.props.tags != undefined && this.props.tags.length > 1 &&
                  <IATooltip
                    content="Tooltip"
                    type={this.props.isMobile ? "popup" : "bubble"}
                    styles={{
                      width: 200,
                      textAlign: "left",
                      color: "#333333",
                      fontFamily: this.props.fontSlots?.body?.fontFamily,
                    }}
                    borderRadius={5}
                    componentContent={
                      <div>{listOfTags}</div>
                    }
                  >
                    <div
                      className={styles.IA_tags}
                      style={{
                        cursor: this.props.onClickOpen ? "pointer" : "default",
                        fontFamily: this.props.fontSlots?.body?.fontFamily,
                      }}
                    >
                      {tags}
                    </div>
                  </IATooltip>
                }
                {this.props.tags != undefined && this.props.tags.length == 1 &&
                  <div
                    className={styles.IA_tags}
                    style={{
                      fontFamily: this.props.fontSlots?.body?.fontFamily,
                    }}
                  >
                    {tags}
                  </div>
                }
              </>
            }

            {/* TITLE */}
            {this.props.title &&
              <div
                className={styles.IA_title}
                style={{
                  color: this.props.design.textColor,
                  cursor: this.props.onClickOpen ? "pointer" : "default",
                  padding: this.props.showPinIcon && !showCover ? "9px 35px 0 10px" : "9px 10px 0",
                  lineClamp: !this.props.teaser ? 3 : 2,
                  WebkitLineClamp: !this.props.teaser ? 3 : 2,
                  fontFamily: this.props.fontSlots?.heading?.fontFamily
                }}
              >
                {this.props.title}
              </div>
            }
            {/* TEASER */}
            {this.props.teaser && !showLongSummary &&
              <div
                className={styles.IA_teaserSmall}
                style={{
                  color: this.props.design.textColor,
                  cursor: this.props.onClickOpen ? "pointer" : "default",
                  fontFamily: this.props.fontSlots?.body?.fontFamily,
                }}
              >
                {this.props.teaser}
              </div>
            }
            {this.props.teaser && showLongSummary &&
              <div
                className={styles.IA_teaserLarge}
                style={{
                  color: this.props.design.textColor,
                  cursor: this.props.onClickOpen ? "pointer" : "default",
                  fontFamily: this.props.fontSlots?.body?.fontFamily,
                }}
              >
                {this.props.teaser}
              </div>
            }
            {/* AUTHOR AND DATE */}
            {(this.props.date || this.props.author) && (
              <div
                className={styles.IA_authorAndDate}
                style={eventTime || (this.props.eventSettings && this.props.eventSettings.location) || this.props.labels ? {} : {
                  position: "absolute",
                  bottom: showFooter ? 50 : 10,
                  cursor: this.props.onClickOpen ? "pointer" : "default",
                  fontFamily: this.props.fontSlots?.body?.fontFamily,
                }}
              >
                {this.props.author &&
                  <div
                    className={styles.IA_author}
                    style={{
                      maxWidth: this.props.date ? "calc(100% - 66px)" : "100%",
                      color: this.props.design.textColor,
                      cursor: this.props.onClickOpen ? "pointer" : "default",
                      fontFamily: this.props.fontSlots?.body?.fontFamily,
                    }}
                  >
                    {`${this.props.author} `}
                  </div>
                }
                {this.props.date &&
                  <div
                    style={{
                      float: "left",
                      color: this.props.design.textColor,
                      cursor: this.props.onClickOpen ? "pointer" : "default",
                      fontFamily: this.props.fontSlots?.body?.fontFamily,
                    }}
                  >
                    <Moment
                      format="L"
                      locale={this.props.userLocation ?? navigator.language}
                    >
                      {this.props.date}
                    </Moment>

                  </div>
                }
              </div>
            )}
            {/* EVENT TIME */}
            {(eventTime || this.props.eventSettings?.displayDateOnCover) &&
              <div
                className={styles.IA_eventTime}
                style={{
                  color: this.props.design.textColor,
                  bottom: eventTimePosition,
                  fontFamily: this.props.fontSlots?.body?.fontFamily,
                }}
              >
                {this.props.eventSettings?.displayDateOnCover ?
                  eventTime
                  :
                  this.dateTimeService.getEventStringDateTimeFormat(this.props.eventSettings.startDate, this.props.eventSettings.endDate, this.props.eventSettings.isAllDayEvent)
                }
              </div>
            }
            {/* LOCATION */}
            {this.props.eventSettings && this.props.eventSettings.location &&
              <div
                className={styles.IA_location}
                style={{
                  bottom: locationPosition,
                  fontFamily: this.props.fontSlots?.body?.fontFamily,
                }}
              >
                <div className={styles.IA_locationLabel}>
                  {this.props.eventSettings.location}
                </div>
              </div>
            }
            {this.props.labels &&
              <div
                className={styles.IA_labels}
                style={{
                  bottom: labelsPosition,
                  fontFamily: this.props.fontSlots?.body?.fontFamily,
                }}
              >
                {visibleLabels}
                {showMoreLabels && hiddenLabels.length > 0 &&

                  <IATooltip
                    content="Tooltip"
                    type={this.props.isMobile ? "popup" : "bubble"}
                    styles={{
                      textAlign: "left",
                      color: "#333333"
                    }}
                    borderRadius={5}
                    componentContent={
                      <div>{hiddenLabels}</div>
                    }
                  >
                    <div
                      id={`IA_moreLabel_${this.props.id}`}
                      className={styles.IA_moreLabels}
                      style={{
                        backgroundColor: `${this.props.design.textColor}10`
                      }}
                    >
                      <IAIcon
                        url="https://intraactivestorage.blob.core.windows.net/cdn/icons/More%20Horizontal/SVG/ic_fluent_more_horizontal_48_regular.svg"
                        size={14}
                        color={this.props.design.highlightColor}
                        style={{
                          marginTop: 3,
                        }}
                        onClick={() => { }}
                      />
                    </div>
                  </IATooltip>
                }
              </div>
            }
          </div>
        }
        {showFooter && !this.props.showSkeleton &&
          <IACardFooter
            isMobile={this.props.isMobile}
            user={this.props.user}
            likes={this.props.likes}
            uniqueViews={this.props.uniqueViews}
            comments={this.props.comments}
            id={this.props.id}
            tenant={this.props.tenant}
            type={this.props.type}
            environment={this.props.environment}
            instance={this.props.instance}
            token={this.props.token}
            source={this.props.source}
            color={this.props.design.highlightColor}
            borderColor={`${this.props.design.textColor}20`}
            backgroundColor={this.props.design.backgroundColor}
            footerElements={this.props.footerElements}
            footerLeftText={this.props.footerLeftText}
            darkMode={this.props.darkMode}
            likesUpdated={this.props.likesUpdated != undefined ? (likes: ILike[]) => { this.props.likesUpdated(likes) } : undefined}
            commentsUpdated={this.props.commentsUpdated != undefined ? (comments: number) => { this.props.commentsUpdated(comments) } : undefined}
            commentsClick={this.props.commentsClick != undefined ? () => { this.props.commentsClick() } : undefined}
            onClickEdit={this.props.onClickEdit != undefined ? () => { this.props.onClickEdit() } : undefined}
            onClickOpen={this.props.onClickOpen != undefined ? () => { this.props.onClickOpen() } : undefined}
            onClickPreview={this.props.onClickPreview != undefined ? () => { this.props.onClickPreview() } : undefined}
            onClickCopy={this.props.onClickCopy != undefined ? () => { this.props.onClickCopy() } : undefined}
            onClickDelete={this.props.onClickDelete != undefined ? () => { this.props.onClickDelete() } : undefined}
            showLikesDimmed={this.props.showLikesDimmed}
            showCommentsDimmed={this.props.showCommentsDimmed}
            showUniqueViewsDimmed={this.props.showUniqueViewsDimmed}
            hideCommandBarButton={this.props.hideCommandBarButton}
          />
        }
      </div>
    );
  }
}
