import * as React from 'react';
import * as styles from './DateAndTimePicker.css';
import { IAIcon } from '../icon/Icon';
import DateTimeService from '../../services/DateTimeService';
import { Helper } from '../../Helper';

export interface IDateAndTimePickerProps {
  label?: string;
  selectedDate: Date;
  showTimeSelector: boolean;
  minDate?: Date;
  maxDate?: Date;
  highlightColor?: string;
  isClearable?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  required?: boolean;
  inputWidth?: number;
  darkMode?: boolean;
  borderRadius?: number;
  inputStyle?: React.CSSProperties;
  onChange: (date: Date) => void;
  onBlur?: () => void;
}

export interface IDateAndTimePickerState {
}

export class IADateAndTimePicker extends React.Component<IDateAndTimePickerProps, IDateAndTimePickerState> {

  private readonly dateTimeService: DateTimeService = new DateTimeService();

  constructor(props: IDateAndTimePickerProps) {
    super(props);
    this.state = {
    };
  }

  public render(): JSX.Element {
    let label = <div style={{ float: "left" }}>{this.props.label}</div>;
    if (this.props.required) {
      label = <div style={{ float: "left" }}>{this.props.label} *</div>;
    }
    const style = this.props.style ?? {};
    if (!style["--highlight-color"]) {
      style["--highlight-color"] = this.props.highlightColor ?? "black";
    }
    return (
      <div
        className={styles.IA_dateAndTimePicker}
        style={style}
      >
        {this.props.label != undefined &&
          <span style={{ color: this.props.darkMode ? Helper.darkModeLabelColor : undefined }}>
            {label}
          </span>
        }
        <div className={`${styles.picker} ${this.props.darkMode ? styles.IA_darkPicker : ''}`}>
          <input
            type={this.props.showTimeSelector ? "datetime-local" : "date"}
            id="start"
            name="trip-start"
            value={this.props.selectedDate !== undefined ? (this.props.showTimeSelector ? this.dateTimeService.ConvertToLocalIOSString(this.props.selectedDate) : this.dateTimeService.ConvertToYYYYMMDD(this.props.selectedDate)) : ""}
            min={this.props.minDate !== undefined ? (this.props.showTimeSelector ? this.dateTimeService.ConvertToLocalIOSString(this.props.minDate) : this.dateTimeService.ConvertToYYYYMMDD(this.props.minDate)) : ""}
            max={this.props.maxDate !== undefined ? (this.props.showTimeSelector ? this.dateTimeService.ConvertToLocalIOSString(this.props.maxDate) : this.dateTimeService.ConvertToYYYYMMDD(this.props.maxDate)) : ""}
            onChange={(date: any) => {
              if (date.target.value && date.target.value !== "") {
                this.props.onChange(new Date(date.target.value));
              }
            }}
            onBlur={this.props.onBlur ? () => this.props.onBlur() : undefined}
            placeholder=""
            style={{
              color: this.props.selectedDate !== undefined ? this.props.darkMode ? '#fff' : "#333333" : "#b3b3b3",
              width: this.props.inputWidth ? this.props.inputWidth : 200,
              backgroundColor: this.props.darkMode ? Helper.darkModeInputFieldBackgroundColor : '#fff',
              borderColor: this.props.highlightColor,
              borderRadius: this.props.borderRadius,
              ...this.props.inputStyle
            }}
          />
        </div>
        {this.props.isClearable && this.props.selectedDate &&
          <div
            className={styles.IA_dateAndTimePickerClearButton}
          >
            <IAIcon
              title="Close"
              size={18}
              color={this.props.disabled ? "gray" : "red"}
              onClick={this.props.disabled ? undefined : () => this.props.onChange(undefined)}
            />
          </div>
        }
      </div>
    );
  }
}