exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_companyInformation_1vsQwuiBwUtpvo2LSomxbO {\n  width: 100%;\n  margin-top: 20px;\n  margin-bottom: 20px;\n  float: left;\n}\n\n.IA_divider_2_kAe1pkJJPwqe_sjKYJO6, .IA_dividerDarkMode_3IBpnNpQnM8kEVjzJJkqAH {\n  height: 1px;\n  width: 100%;\n  background-color: #eeeeee;\n  margin-top: 40px;\n  margin-bottom: 20px;\n  float: left;\n}\n\n.IA_billingInfo_yrBgr6RnR70v2pbkdSn1D {\n  font-size: 14px;\n}", ""]);

// Exports
exports.locals = {
	"IA_companyInformation": "IA_companyInformation_1vsQwuiBwUtpvo2LSomxbO",
	"IA_divider": "IA_divider_2_kAe1pkJJPwqe_sjKYJO6",
	"IA_dividerDarkMode": "IA_dividerDarkMode_3IBpnNpQnM8kEVjzJJkqAH",
	"IA_billingInfo": "IA_billingInfo_yrBgr6RnR70v2pbkdSn1D"
};