import { action, observable } from 'mobx';
import { IEnvironment } from '../../../interfaces/IEnvironment';
import { IPlayArticle, IPlayer, IPlaylist, IPlaylistExtended, IPlayProfile, IPlaySlide, IPlaySlideExtended, IPlayWidget, IPlayWidgetExtended, IMediaFile, IMediaFileTag, IPlayerGroup, IPlayUser, IPlayCompanyInformation, IPlayIntegration, IPlayWeatherData } from '../../../interfaces/IPlay';
import PlayService from '../../../services/PlayService';
export class Store {

  // Public properties
  @observable public environment: IEnvironment;
  @observable public tenantId: string;
  @observable public sharepointRootSite: string;
  @observable public token: string;
  @observable public isTeams: boolean;
  @observable public isLoading: boolean;
  @observable public displayConsentButton: boolean;
  @observable public consentIsGiven: boolean;
  @observable public darkMode: boolean;

  @observable public isMobile: boolean = false;
  @observable public highlightColor: string = "#fc8a00";

  @observable public workingOnIt: boolean = false;

  @observable public city: string;
  @observable public weatherData: IPlayWeatherData;
  @observable public isUsingHardCodedWeather: boolean;


  @action public setHardcodedWeather(): void {
    this.weatherData = this.defaultWeatherData;
    this.city = this.defaultCity;
    this.isUsingHardCodedWeather = true;
  }

  @observable public showAuthenticationErrorPopup: boolean;
  @observable public generalErrorPopup: {
    technicalDetails: string;
  };

  // Menu
  @observable public selectedMenuItem: string = localStorage.getItem("intraactive-play-menu-item") ? localStorage.getItem("intraactive-play-menu-item") : "players";
  @observable public menuCollapsed: boolean = localStorage.getItem("intraactive-play-menu-collapsed") ? true : false;
  @observable public showMobileMenu: boolean;

  // Dashboard
  @observable public contentWidth: number;
  @observable public windowWidth: number;
  @observable public loadingPreviewContent: boolean;

  // Profiles
  @observable public profiles: IPlayProfile[];
  @observable public profile: IPlayProfile;
  @observable public creatingNewProfile: number;
  @observable public profileHasChanged: boolean;

  // Settings
  @observable public showSettingsPanel: boolean;

  @action public resetProfile(): void {
    window.location.reload();
  }

  @action public getDefaultProfile(): IPlayProfile {
    return {
      title: "Replay",
      logoUrl: "https://intraactivestorage.blob.core.windows.net/cdn/play/content-pack/IA-play.png",
      footer: {
        backgroundColor: "#ffffff",
        color: "#fc8a00",
        showLogo: true,
        logoUrl: "https://intraactivestorage.blob.core.windows.net/cdn/play/content-pack/IA-play-Side-Txt.png",
        showWeather: true,
        showClock: true,
      },
      hasAccessPermissions: true
    };
  }

  @action public async loadFont(fontFace: FontFace, type: "headline" | "body"): Promise<void> {
    try {
      await fontFace.load();
      const fonts: any = document.fonts;
      fonts.add(fontFace);
    } catch (e) {
      console.log(e);
      switch (type) {
        case "headline":
          this.profile.fonts.headline = JSON.stringify({
            name: '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
            url: undefined
          });
          break;
        case "body":
          this.profile.fonts.body = JSON.stringify({
            name: '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
            url: undefined
          });
          break;
        default:
          break;
      }
    }
  }

  @action public async addDemoContent(): Promise<void> {

    // ARTICLES
    const article1: IPlayArticle = {
      margin: 4,
      contentWidth: 52,
      titleSize: "large",
      textSize: "small",
      startDate: "2022-12-19T09:42:47.000Z",
      endDate: null,
      hideTitle: false,
      widgetsToAddTo: [],
      widgetsToRemoveFrom: [],
      boxStyle: "box",
      contentPosition: "right",
      contentVerticalPosition: "bottom",
      colorTheme: {
        backgroundColor: "#ffffff",
        textColor: "#000000"
      },
      galleryImages: [],
      showDate: false,
      showTitle: true,
      imageUrl: "https://img.freepik.com/free-vector/hand-drawn-flea-market-concept_23-2148823308.jpg?t=st=1651733877~exp=1651734477~hmac=1ce3291538d03847c57f146ff092c136e95c5bcf7bd3c57dc5dac1421aab0e39&w=1380",
      title: "Demo: Reuse content across widgets, slides and players",
      textContent: [
        "<p>It sounds unreal, but Replay can distribute content to different playlists that appear on different players.</p><p><br></p>"
      ],
      boxStyleRoundCorner: true
    };
    const article2: IPlayArticle = {
      margin: 4,
      contentWidth: 35,
      titleSize: "large",
      textSize: "small",
      startDate: "2022-12-19T09:50:06.000Z",
      endDate: null,
      hideTitle: false,
      widgetsToAddTo: [],
      widgetsToRemoveFrom: [],
      boxStyle: "box",
      contentPosition: "left",
      contentVerticalPosition: "top",
      colorTheme: {
        backgroundColor: "#ff6914",
        textColor: "#ffffff"
      },
      galleryImages: [],
      showDate: false,
      showTitle: true,
      imageUrl: "https://intraactivestorage.blob.core.windows.net/cdn/play/content-pack/computer-screen-connect.jpg",
      title: "Demo: Connect your player in a few seconds",
      textContent: [
        "<p>With Replay, you can connect to a monitor using a 4-digit code. Enter the code on your player and you can start Replay.</p><p><br></p>"
      ],
      boxStyleRoundCorner: true
    };
    const article3: IPlayArticle = {
      margin: 4,
      contentWidth: 50,
      titleSize: "large",
      textSize: "small",
      startDate: "2022-12-19T09:53:24.000Z",
      endDate: null,
      hideTitle: false,
      widgetsToAddTo: [],
      widgetsToRemoveFrom: [],
      boxStyle: "box",
      contentPosition: "right",
      contentVerticalPosition: "bottom",
      colorTheme: {
        backgroundColor: "#f97e5c",
        textColor: "#ffffff"
      },
      galleryImages: [],
      showDate: false,
      showTitle: true,
      imageUrl: "https://intraactivestorage.blob.core.windows.net/cdn/play/content-pack/widget-illustration-001.png",
      title: "Demo: Find the widget that suit your needs",
      textContent: [
        "<p>Setup your own widget based on our widget types and re-use it as many times you like, accross all your players.</p><p><br></p>"
      ],
      boxStyleRoundCorner: true
    };
    const article4: IPlayArticle = {
      margin: 4,
      contentWidth: 50,
      titleSize: "large",
      textSize: "small",
      startDate: "2022-12-19T10:01:54.000Z",
      endDate: null,
      hideTitle: false,
      widgetsToAddTo: [],
      widgetsToRemoveFrom: [],
      boxStyle: "box",
      contentPosition: "left",
      contentVerticalPosition: "top",
      colorTheme: {
        backgroundColor: "#e8f3ff",
        textColor: "#000000"
      },
      galleryImages: [],
      showDate: false,
      showTitle: true,
      imageUrl: "https://img.freepik.com/free-vector/recruit-agent-analyzing-candidates_74855-4565.jpg?t=st=1651733924~exp=1651734524~hmac=1c52a69bd670d07d8eda64ad53af037d2f3ba2cf5cfa7e651dfdf4460fe2f36f&w=1380",
      title: "Demo: Maintain the overview with profiles",
      textContent: [
        "<p>Use profiles if you are many different people from different areas or locations working with Replay in your organization.</p><p><br></p>"
      ],
      boxStyleRoundCorner: true
    };
    const articleId1 = await PlayService.createArticle(this.environment, this.token, this.tenantId, this.profile?.id, article1);
    const articleId2 = await PlayService.createArticle(this.environment, this.token, this.tenantId, this.profile?.id, article2);
    const articleId3 = await PlayService.createArticle(this.environment, this.token, this.tenantId, this.profile?.id, article3);
    const articleId4 = await PlayService.createArticle(this.environment, this.token, this.tenantId, this.profile?.id, article4);
    this.creatingNewProfile = 30;

    // WIDGETS
    const widget1: IPlayWidget = {
      title: "Demo: Article Viewer",
      type: "articleViewer",
      articleViewer: {
        articles: [articleId1, articleId2, articleId3, articleId4],
        transition: "crossfade",
        duration: 10
      }
    };
    const widget2: IPlayWidget = {
      title: "Demo: Gallery",
      type: "gallery",
      gallery: {
        showTitle: false,
        images: [
          "https://intraactivestorage.blob.core.windows.net/cdn/play/content-pack/produkt-ia2.png",
          "https://intraactivestorage.blob.core.windows.net/cdn/play/content-pack/produkt-intranet.png",
          "https://intraactivestorage.blob.core.windows.net/cdn/play/content-pack/produkt-connect.png",
          "https://intraactivestorage.blob.core.windows.net/cdn/play/content-pack/produkt-pling.png",
          "https://intraactivestorage.blob.core.windows.net/cdn/play/content-pack/produkt-governance.png",
          ""
        ],
        duration: 5,
        transition: "slide",
        showThumbnails: false
      }
    }
    const widget4: IPlayWidget = {
      title: "Demo: Video",
      type: "video",
      video: {
        duration: 61,
        fitToView: false,
        videoUrl: "https://intraactivestorage.blob.core.windows.net/cdn/play/content-pack/play-connect.mp4",
        showTitle: false
      }
    }
    const widgetId1 = await PlayService.createWidget(this.environment, this.token, this.tenantId, this.profile?.id, widget1);
    const widgetId2 = await PlayService.createWidget(this.environment, this.token, this.tenantId, this.profile?.id, widget2);
    const widgetId4 = await PlayService.createWidget(this.environment, this.token, this.tenantId, this.profile?.id, widget4);
    this.creatingNewProfile = 60;

    // SLIDES
    const slide1: IPlaySlide = {
      duration: 60,
      layout: "rightSideTwoRows",
      showFooter: true,
      startDate: new Date().toJSON(),
      title: "Demo: Slide 1",
      widgets: [widgetId1, widgetId2, widgetId4]
    }
    const slide2: IPlaySlide = {
      duration: 61,
      layout: "oneColumn",
      showFooter: false,
      startDate: new Date().toJSON(),
      title: "Demo: Slide 2",
      widgets: [widgetId4]
    }
    const slideId1 = await PlayService.createSlide(this.environment, this.token, this.tenantId, this.profile?.id, slide1);
    const slideId2 = await PlayService.createSlide(this.environment, this.token, this.tenantId, this.profile?.id, slide2);
    this.creatingNewProfile = 80;

    // PLAYLIST
    const playlist: IPlaylist = {
      title: "Demo: Playlist",
      screenFormat: "landscape",
      slides: [slideId1, slideId2]
    }
    PlayService.createPlaylist(this.environment, this.token, this.tenantId, this.profile?.id, playlist).then(() => {
      this.creatingNewProfile = 100;
      setTimeout(() => {
        this.creatingNewProfile = undefined;
        this.resetProfile();
      }, 500);
    }).catch(e => {
      this.workingOnIt = false;
      this.generalErrorPopup = {
        technicalDetails: e
      };
    });;
  }

  // Players
  @observable public players: IPlayer[];
  @observable public playersCount: number;
  @observable public playerToEdit: IPlayer;
  @observable public isShowingConnectNewPlayerPopUp: boolean;

  // Groups
  @observable public groups: IPlayerGroup[];
  @observable public groupsCount: number;
  @observable public groupToEdit: IPlayerGroup;

  // Playlists
  @observable public playlists: IPlaylist[];
  @observable public playlistsCount: number;
  @observable public playlistToEdit: IPlaylistExtended;
  @observable public playlistToEditBeforeChanges: string;

  // Media files
  @observable public mediaFiles: IMediaFile[];
  @observable public mediaFileTags: IMediaFileTag[];
  @observable public mediaFilesCount: number;
  @observable public mediaFileToEdit: IMediaFile;

  @action public getPlaylist(id: string): IPlaylist {
    let playlistForPlayer: IPlaylist;
    if (this.playlists && this.playlists.length > 0) {
      this.playlists.forEach((playlist: IPlaylist) => {
        if (playlist.id === id) {
          playlistForPlayer = playlist;
        }
      });
      return playlistForPlayer;
    } else {
      return undefined;
    }
  }

  // Slides
  @observable public slides: IPlaySlide[];
  @observable public availableSlides: IPlaySlide[];
  @observable public slidesCount: number;
  @observable public slideToEdit: IPlaySlideExtended;
  @observable public slideToEditBeforeChanges: string;

  @action public getSlide(slideId: string): IPlaySlide {
    let slideToReturn;
    if (this.slides && this.slides.length > 0) {
      this.slides.forEach((slide: IPlaySlide) => {
        if (slide.id === slideId) {
          slideToReturn = slide;
        }
      })
    }
    return slideToReturn;
  }

  // Widgets
  @observable public widgets: IPlayWidget[];
  @observable public widgetsCount: number;
  @observable public widgetToEdit: IPlayWidgetExtended;
  @observable public widgetToEditBeforeChanges: string;

  @action public getWidget(widgetId: string): IPlaySlide {
    let widgetToReturn;
    if (this.widgets && this.widgets.length > 0) {
      this.widgets.forEach((widget: IPlayWidget) => {
        if (widget.id === widgetId) {
          widgetToReturn = widget;
        }
      });
    }
    return widgetToReturn;
  }

  // Articles
  @observable public articles: IPlayArticle[];
  @observable public articlesCount: number;
  @observable public articleToEdit: IPlayArticle;
  @observable public articleToEditBeforeChanged: string;

  // Users
  @observable public currentUser: IPlayUser;
  @observable public profileUsers: IPlayUser[];
  @observable public adminUsers: IPlayUser[];
  @observable public isAdmin: boolean;

  @action public getArticle(articleId: string): IPlayArticle {
    let articleToReturn;
    if (this.articles && this.articles.length > 0) {
      this.articles.forEach((article: IPlayArticle) => {
        if (article.id === articleId) {
          articleToReturn = article;
        }
      });
    }
    return articleToReturn;
  }

  // ADMINISTRATION
  @observable public isShowingAccountSettings: boolean;
  @observable public companyInformation: IPlayCompanyInformation;
  @observable public integrationToEdit: IPlayIntegration;

  @action public isConnectedToEvents(): boolean {
    return this.profile?.integrations?.indexOf('IntraActive-Events') > -1;
  };
  @action public isConnectedToMessages(): boolean {
    return this.profile?.integrations?.indexOf('IntraActive-Messages') > -1;
  };
  @action public isConnectedToSharepointNews(): boolean {
    return this.profile?.integrations?.indexOf('SharePointNews') > -1;
  };
  @action public isConnectedFacebook(): boolean {
    return this.profile?.integrations?.indexOf('facebookPageViewer') > -1;
  };
  @action public isConnectedToPowerBi(): boolean {
    return this.profile?.integrations?.indexOf('powerbi') > -1;
  };
  @action public isConnectedToRelesys(): boolean {
    return this.profile?.integrations?.indexOf('relesys') > -1;
  };
  @action public isConnectedToTimeEdit(): boolean {
    return this.profile?.integrations?.indexOf('timeEdit') > -1;
  };

  // Workplace

  @observable public showWorkplaceAPIKeyIsMisssingPopup: boolean;

  // License

  @observable public subscription: any;
  @observable public licensedNumberOfPlayers: number = 1;
  @observable public numberOfPlayersOnTenant?: number;
  @observable public hasDiscount: boolean;

  // Swatches

  @action public addSwatch(color: string): void {
    if (this.profile.swatches == undefined) {
      this.profile.swatches = [];
    }
    this.profile.swatches.push(color);
    this.profile.swatches = this.profile.swatches.filter((element, index) => {
      return this.profile.swatches.indexOf(element) === index;
    });
    PlayService.updatePlayProfile(this.environment, this.token, this.tenantId, this.profile).catch(e => {
      this.workingOnIt = false;
      this.generalErrorPopup = {
        technicalDetails: e
      };
    });
  }

  // Hardcoded weather example for admin when no location is available
  private defaultWeatherData: IPlayWeatherData = {
    current: {
      time: "2023-07-31T14:58:13.6740364+02:00",
      airTemperature: 20.1,
      iconUrl: "https://api.met.no/images/weathericons/svg/cloudy.svg",
      precipitationAmount: 0.0
    },
    dayForecast: [
      {
        time: "2023-07-31T12:00:00Z",
        airTemperature: 20.1,
        iconUrl: "https://api.met.no/images/weathericons/svg/lightrainshowers_day.svg",
        precipitationAmount: 0.0
      },
      {
        time: "2023-07-31T13:00:00Z",
        airTemperature: 20.1,
        iconUrl: "https://api.met.no/images/weathericons/svg/partlycloudy_day.svg",
        precipitationAmount: 0.0
      },
      {
        time: "2023-07-31T14:00:00Z",
        airTemperature: 19.9,
        iconUrl: "https://api.met.no/images/weathericons/svg/partlycloudy_day.svg",
        precipitationAmount: 0.1
      },
      {
        time: "2023-07-31T15:00:00Z",
        airTemperature: 19.8,
        iconUrl: "https://api.met.no/images/weathericons/svg/partlycloudy_day.svg",
        precipitationAmount: 0.0
      },
      {
        time: "2023-07-31T16:00:00Z",
        airTemperature: 19.3,
        iconUrl: "https://api.met.no/images/weathericons/svg/partlycloudy_day.svg",
        precipitationAmount: 0.0
      },
      {
        time: "2023-07-31T17:00:00Z",
        airTemperature: 19.4,
        iconUrl: "https://api.met.no/images/weathericons/svg/partlycloudy_night.svg",
        precipitationAmount: 0.0
      },
      {
        time: "2023-07-31T18:00:00Z",
        airTemperature: 18.8,
        iconUrl: "https://api.met.no/images/weathericons/svg/partlycloudy_night.svg",
        precipitationAmount: 0.0
      },
      {
        time: "2023-07-31T19:00:00Z",
        airTemperature: 18.2,
        iconUrl: "https://api.met.no/images/weathericons/svg/rain.svg",
        precipitationAmount: 0.0
      },
      {
        time: "2023-07-31T20:00:00Z",
        airTemperature: 17.3,
        iconUrl: "https://api.met.no/images/weathericons/svg/rain.svg",
        precipitationAmount: 0.0
      },
      {
        time: "2023-07-31T21:00:00Z",
        airTemperature: 17.0,
        iconUrl: "https://api.met.no/images/weathericons/svg/rain.svg",
        precipitationAmount: 0.0
      },
      {
        time: "2023-07-31T22:00:00Z",
        airTemperature: 16.6,
        iconUrl: "https://api.met.no/images/weathericons/svg/rain.svg",
        precipitationAmount: 0.0
      },
      {
        time: "2023-07-31T23:00:00Z",
        airTemperature: 16.5,
        iconUrl: "https://api.met.no/images/weathericons/svg/rain.svg",
        precipitationAmount: 0.2
      },
      {
        time: "2023-08-01T00:00:00Z",
        airTemperature: 16.1,
        iconUrl: "https://api.met.no/images/weathericons/svg/rain.svg",
        precipitationAmount: 2.2
      },
      {
        time: "2023-08-01T01:00:00Z",
        airTemperature: 15.3,
        iconUrl: "https://api.met.no/images/weathericons/svg/rain.svg",
        precipitationAmount: 1.1
      },
      {
        time: "2023-08-01T02:00:00Z",
        airTemperature: 15.6,
        iconUrl: "https://api.met.no/images/weathericons/svg/rain.svg",
        precipitationAmount: 0.3
      },
      {
        time: "2023-08-01T03:00:00Z",
        airTemperature: 15.9,
        iconUrl: "https://api.met.no/images/weathericons/svg/rain.svg",
        precipitationAmount: 0.4
      },
      {
        time: "2023-08-01T04:00:00Z",
        airTemperature: 15.7,
        iconUrl: "https://api.met.no/images/weathericons/svg/rain.svg",
        precipitationAmount: 0.0
      },
      {
        time: "2023-08-01T05:00:00Z",
        airTemperature: 15.8,
        iconUrl: "https://api.met.no/images/weathericons/svg/rain.svg",
        precipitationAmount: 0.0
      },
      {
        time: "2023-08-01T06:00:00Z",
        airTemperature: 16.6,
        iconUrl: "https://api.met.no/images/weathericons/svg/rain.svg",
        precipitationAmount: 0.8
      },
      {
        time: "2023-08-01T07:00:00Z",
        airTemperature: 16.4,
        iconUrl: "https://api.met.no/images/weathericons/svg/rain.svg",
        precipitationAmount: 1.9
      },
      {
        time: "2023-08-01T08:00:00Z",
        airTemperature: 16.0,
        iconUrl: "https://api.met.no/images/weathericons/svg/rain.svg",
        precipitationAmount: 0.4
      },
      {
        time: "2023-08-01T09:00:00Z",
        airTemperature: 16.2,
        iconUrl: "https://api.met.no/images/weathericons/svg/rain.svg",
        precipitationAmount: 0.2
      },
      {
        time: "2023-08-01T10:00:00Z",
        airTemperature: 18.0,
        iconUrl: "https://api.met.no/images/weathericons/svg/rain.svg",
        precipitationAmount: 0.2
      },
      {
        time: "2023-08-01T11:00:00Z",
        airTemperature: 17.8,
        iconUrl: "https://api.met.no/images/weathericons/svg/rain.svg",
        precipitationAmount: 0.1
      },
      {
        time: "2023-08-01T12:00:00Z",
        airTemperature: 18.0,
        iconUrl: "https://api.met.no/images/weathericons/svg/rain.svg",
        precipitationAmount: 0.0
      }
    ],
    forecast: [
      {
        time: "2023-07-31T14:58:13.6729484+02:00",
        airTemperatureHigh: 20.1,
        airTemperatureLow: 16.5,
        precipitationAmount: 0.30000000000000004,
        iconUrl: "https://api.met.no/images/weathericons/svg/cloudy.svg"
      },
      {
        time: "2023-08-01T00:00:00+02:00",
        airTemperatureHigh: 19.2,
        airTemperatureLow: 15.3,
        precipitationAmount: 9.000000000000002,
        iconUrl: "https://api.met.no/images/weathericons/svg/rain.svg"
      },
      {
        time: "2023-08-02T00:00:00+02:00",
        airTemperatureHigh: 20.1,
        airTemperatureLow: 14.3,
        precipitationAmount: 5.5,
        iconUrl: "https://api.met.no/images/weathericons/svg/partlycloudy_day.svg"
      },
      {
        time: "2023-08-03T00:00:00+02:00",
        airTemperatureHigh: 20.8,
        airTemperatureLow: 17.9,
        precipitationAmount: 6.8,
        iconUrl: "https://api.met.no/images/weathericons/svg/rain.svg"
      },
      {
        time: "2023-08-04T00:00:00+02:00",
        airTemperatureHigh: 22.0,
        airTemperatureLow: 17.1,
        precipitationAmount: 0.0,
        iconUrl: "https://api.met.no/images/weathericons/svg/partlycloudy_day.svg"
      },
      {
        time: "2023-08-05T00:00:00+02:00",
        airTemperatureHigh: 19.9,
        airTemperatureLow: 15.7,
        precipitationAmount: 0.0,
        iconUrl: "https://api.met.no/images/weathericons/svg/partlycloudy_day.svg"
      }
    ]
  }
  private defaultCity = "Copenhagen";
}
