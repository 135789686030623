import * as React from 'react';
import * as styles from "./PersonaSmall.css";
import { Helper } from './../../Helper';
import { MSPersonCard } from '../msPersonCard/MSPersonCard';

const INTRAACTIVE_CDN_ICON_PREFIX: string = "https://intraactivestorage.blob.core.windows.net/cdn/icons/";

export interface IPersonaSmallProps {
  name: string;
  id?: string;
  title?: string;
  metadata?: any;
  profileImage?: string;
  backgroundColor?: string;
  styles?: React.CSSProperties;
  numMetaData?: number;
  listViewStyle?: boolean;
  styleMetadata?: any;
  userLoginName?: string;
  highlightColor?: string;
}

export class IAPersonaSmall extends React.Component<IPersonaSmallProps> {

  public render(): JSX.Element {
    const isImageProfileIconFromCdn = this.props.profileImage.indexOf(INTRAACTIVE_CDN_ICON_PREFIX) > -1;
    const {firstNameChar, lastNameChar} = Helper.getFirstNameAndLastNameChars(this.props.name);
    return (
      <div
        className={styles.IA_personaSmall}
        style={this.props.styles}
        title={this.props.id}
      >
        <MSPersonCard
          userLoginName={this.props.userLoginName}
          userProfileImage={this.props.profileImage}
          highlightColor={this.props.highlightColor}
        >
          <div
            className={styles.IA_personaSmallProfileImageWrapper}
            style={{
              backgroundColor: isImageProfileIconFromCdn ? "" : this.props.backgroundColor != undefined ? this.props.backgroundColor : "#333333",
              color: this.props.backgroundColor != undefined ? Helper.getTextColorBasedOnBackgroundColorDarkness(this.props.backgroundColor, "#ffffff", "#333333") : "#ffffff",
              marginTop: this.props.numMetaData ? (5 * this.props.numMetaData).toString() + 'px' : '1px',
              marginRight: !this.props.listViewStyle || !this.props.listViewStyle ? "7px" : "10px"
            }}
          >
            {!isImageProfileIconFromCdn && <div className={styles.IA_personaSmallProfileImageText}>
              {firstNameChar + lastNameChar}
            </div>}
            {!isImageProfileIconFromCdn && <div
              className={styles.IA_personaSmallProfileImage}
              style={{
                backgroundImage: `url('${this.props.profileImage}')`
              }}
            />}
            {isImageProfileIconFromCdn && <img
              src={this.props.profileImage}
              style={{
                border: "1px solid #DCD9D6",
                borderRadius: 17,
                color: "#212121",
                backgroundColor: "#F4F4F4",
                width: "21px",
                padding: "4px",
              }}
            />}
          </div>
        </MSPersonCard>
        <div className={styles.IA_personaSmallName} style={!this.props.metadata ? { width: "auto", marginTop: "7px" } : {}}  title={this.props.name}>{this.props.name}{this.props.title ? ` (${this.props.title})`: ''}</div>
        {this.props.metadata &&
          <div className={styles.IA_personaSmallMetadata} style={this.props.styleMetadata ? this.props.styleMetadata : {}}>{this.props.metadata}</div>
        }
      </div>
    );
  }
}