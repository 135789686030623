
import { IAPlayRequestFilters, IAzureStorageUploadInfo, IFacebookFeedItem, IFacebookPageIntegration, IMediaFile, IMediaFileTag, IPlayAppRegistrationConsentResponse, IPlayArticle, IPlayCompanyInformation, IPlayer, IPlayerActivity, IPlayerExtended, IPlayerGroup, IPlaylist, IPlaylistExtended, IPlayMessage, IPlayProfile, IPlayRelesysContent, IPlayRelesysContentModule, IPlaySharepointNewsItem, IPlaySlide, IPlaySlideExtended, IPlayTimeEditIntegration, IPlayTimeEditReservation, IPlayTimeEditSearchObject, IPlayTimeEditType, IPlayUser, IPlayWeatherData, IPlayWidget, IPlayWidgetExtended, IPlayWidgetType, IPlayWorkplaceFeedItem, IPowerBIGenerateToken, IPowerBIPage, IPowerBIReport, IPowerBIWorkspace, IWidgetSwitchStatus } from '../interfaces/IPlay';
import { Environment } from './Environment';
import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import { IStockImagesSearchResult } from '../interfaces/IStockImagesSearchResult';
import { PlayHelper } from '../components/playAdmin/PlayHelper';
import { IFeed, IFeedSharePointSiteMeta } from '../interfaces/IFeed';
import { IEnvironment } from '../interfaces/IEnvironment';
import DateTimeService from './DateTimeService';
import { Helper } from '../Helper';

export default class PlayService {

  private static getRejectMessage(method: string, url: string, payload?: any, status?: number, statusText?: string){
    return `${status ? status : ""} ${statusText ?? ""} | ${method} ${url} | ${payload ? JSON.stringify(payload) : ""}`;
  }

  /////////////////////////////////////////////////////////// Pending player //////////////////////////////////////////////////////////

  public static getPendingPlayer(environment: IEnvironment, displayCode: number): Promise<IPlayer> {
    return new Promise<IPlayer>((resolve) => {
      const apiUrl = Environment.getEnvironmentForPlayApi(environment);
      fetch(`${apiUrl}/players/pending/${displayCode}`, {
        method: "GET"
      })
        .then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return response.json();
        })
        .then((player) => {
          resolve(player);
        })
        .catch(() => {
          resolve(undefined);
        });
    });
  }

  public static addPendingPlayer(environment: IEnvironment): Promise<IPlayerExtended> {
    return new Promise<IPlayerExtended>((resolve, reject) => {
      const apiUrl = Environment.getEnvironmentForPlayApi(environment);
      fetch(`${apiUrl}/players/pending`, {
        method: "POST"
      }).then((response) => {
        if (!response.ok) {
          reject(response);
          return;
        }
        return response.json();
      })
        .then((player) => {
          resolve(player);
        })
        .catch(error => {
          console.log(error);
          reject(null);
        });
    });
  }

  public static updatePendingPlayer(environment: IEnvironment, token: string, tenantId: string, profileId: string, displayCode: number): Promise<IPlayer> {
    return new Promise<IPlayer>((resolve) => {
      const apiUrl = Environment.getEnvironmentForPlayApi(environment);
      fetch(`${apiUrl}/players/pending/${displayCode}`, {
        method: "PUT",
        body: JSON.stringify({
          tenantId: tenantId,
          profileId: profileId
        }),
        headers: {
          "Authorization": `Bearer ${token}`
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
          return;
        }
        return response.json();
      })
        .then((player) => {
          resolve(player);
        })
        .catch(error => {
          console.log(error);
          resolve(undefined);
        });
    });
  }

  ////////////////////////////////////////////////////////////// Players //////////////////////////////////////////////////////////////

  public static getPlayerCountOnTenant(environment: IEnvironment, token: string, tenantId: string): Promise<number> {
    return new Promise<number>((resolve) => {
      if (environment && tenantId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/players/count`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(0);
            return;
          }
          return response.json();
        })
          .then((count: number) => {
            resolve(count);
          })
          .catch(error => {
            console.log(error);
            resolve(0);
          });
      } else {
        return resolve(0);
      }
    });
  }

  public static getPlayers(environment: IEnvironment, token: string, tenantId: string, profileId: string): Promise<{ value: IPlayer[], count: number }> {
    return new Promise<{ value: IPlayer[], count: number }>((resolve) => {
      if (environment && tenantId && profileId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/players`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            resolve({ value: [], count: 0 });
            return;
          }
          return response.json();
        })
          .then((players: { value: IPlayer[], count: number }) => {
            resolve(players);
          })
          .catch(error => {
            console.log(error);
            resolve({ value: [], count: 0 });
          });
      } else {
        return resolve({ value: [], count: 0 });
      }
    });
  }


  public static getPlayer(environment: IEnvironment, tenantId: string, profileId: string, playerId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (environment && tenantId && profileId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/players/${playerId}`, {
          method: "GET"
        }).then((response) => {
          if (response.status === 404) {
            localStorage.removeItem("IA_player");
            location.reload();
            console.log(response);
            resolve(undefined);
          }
          return response.json();
        })
          .then((player: any) => {
            resolve(player);
          })
          .catch(error => {
            if (error.status === 404) {
              localStorage.removeItem("IA_player");
              location.reload();
            }
            console.log(error);
            resolve(undefined);
          });
      } else {
        return reject(null);
      }
    });
  }


  public static createPlayer(environment: IEnvironment, tenantId: string, profileId: string, player: IPlayer): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (environment && tenantId && profileId && player) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/players`, {
          method: "POST",
          body: JSON.stringify(player)
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          return response.json();
        })
          .then((player: { id: string }) => {
            resolve(player.id);
          })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  public static updatePlayer(environment: IEnvironment, token: string, tenantId: string, profileId: string, player: IPlayer): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId && player) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/players/${player.id}`;
        const method = "PUT";
        fetch(apiUrl, {
          method: method,
          body: JSON.stringify(player),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, player, response.status, response.statusText));
            return;
          }
          resolve();
        })
        .catch(error => {
          reject(this.getRejectMessage(method, apiUrl, player, undefined, error?.message));
        });
      } else {
        return reject(null);
      }
    });
  }

  public static deletePlayer(environment: IEnvironment, token: string, tenantId: string, profileId: string, playerId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId && playerId) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/players/${playerId}`;
        const method = "DELETE";
        fetch(apiUrl, {
          method: method,
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
          .then((response) => {
            if (!response.ok) {
              reject(this.getRejectMessage(method, apiUrl, undefined, response.status, response.statusText));
              return;
            }
            resolve();
          })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, undefined, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static restartPlayer(environment: IEnvironment, token: string, tenantId: string, profileId: string, id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId && id) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/players/${id}/restart`, {
          method: "POST",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
          .then((response) => {
            if (!response.ok) {
              reject(response);
              return;
            } else {
              resolve();
            }
          })
          .catch(error => {
            console.log(error);
            reject();
          });
      } else {
        return reject();
      }
    });
  }

  public static restartPlayers(environment: IEnvironment, token: string, tenantId: string, profileId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/players/restart`;
        const method = "POST";
        fetch(apiUrl, {
          method: method,
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
          .then((response) => {
            if (!response.ok) {
              reject(this.getRejectMessage(method, apiUrl, undefined, response.status, response.statusText));
              return;
            } else {
              resolve();
            }
          })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, undefined, undefined, error?.message));
          });
      } else {
        return reject();
      }
    });
  }

  public static successfulRequest(environment: IEnvironment, tenantId: string, profileId: string, playerId: string, activity: IPlayerActivity): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId && playerId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/players/${playerId}/activity`, {
          method: "PATCH",
          body: JSON.stringify(activity)
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          resolve();
        })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  ////////////////////////////////////////////////////////////// Profile //////////////////////////////////////////////////////////////

  public static getPlayProfiles(environment: IEnvironment, token: string, tenantId: string): Promise<IPlayProfile[] | 401> {
    return new Promise<IPlayProfile[] | 401>((resolve, reject) => {
      if (environment && tenantId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
          .then((response) => {
            if (!response.ok) {
              reject(response);
              return;
            }
            return response.json();
          })
          .then((profiles: IPlayProfile[]) => {
            resolve(profiles);
          })
          .catch(error => {
            if (error.status === 401) {
              resolve(401);
            }
            console.log(error);
          });
      } else {
        return reject(null);
      }
    });
  }

  public static getPlayProfile(environment: IEnvironment, token: string, tenantId: string, profileId: string): Promise<IPlayProfile | 401 | 404> {
    return new Promise<IPlayProfile | 401 | 404>((resolve) => {
      if (environment && tenantId && profileId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
          .then((response) => {
            if (!response.ok) {
              resolve(undefined);
              return;
            }
            return response.json();
          })
          .then((profile: IPlayProfile) => {
            resolve(profile);
          })
          .catch(error => {
            console.log(error);
            if (error.status === 401) {
              resolve(401);
            }
            if (error.status === 404) {
              resolve(404);
            }
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static createPlayProfile(environment: IEnvironment, token: string, tenantId: string, profile: IPlayProfile, sharePointRootSite: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (environment && tenantId && profile) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles`;
        const method = "POST";
        const body = { ...profile, sharePointRootSite: sharePointRootSite };
        fetch(apiUrl, {
          method: method,
          body: JSON.stringify(body),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
          .then((response) => {
            if (!response.ok) {
              reject(this.getRejectMessage(method, apiUrl, body, response.status, response.statusText));
              return;
            }
            return response.json();
          })
          .then((playProfile: { id: string }) => {
            resolve(playProfile.id);
          })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, body, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static updatePlayProfile(environment: IEnvironment, token: string, tenantId: string, profile: IPlayProfile): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profile) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profile.id}`;
        const method = "PUT";
        fetch(apiUrl, {
          method: method,
          body: JSON.stringify(profile),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
          .then((response) => {
            if (!response.ok) {
              reject(this.getRejectMessage(method, apiUrl, profile, response.status, response.statusText));
              return;
            }
            resolve();
          })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, profile, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static deletePlayProfile(environment: IEnvironment, token: string, tenantId: string, profileId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}`;
        const method = "DELETE";
        fetch(apiUrl, {
          method: method,
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
          .then((response) => {
            if (!response.ok) {
              reject(this.getRejectMessage(method, apiUrl, undefined, response.status, response.statusText));
              return;
            }
            resolve();
          })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, undefined, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  ////////////////////////////////////////////////////////////// Playlist /////////////////////////////////////////////////////////////

  public static getPlaylists(environment: IEnvironment, token: string, tenantId: string, profileId: string, filters?: IAPlayRequestFilters): Promise<{ value: IPlaylist[], count: number }> {
    return new Promise<{ value: IPlaylist[], count: number }>((resolve, reject) => {
      if (environment && tenantId && profileId) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/playlists?${PlayHelper.convertFiltersToURLParams(filters)}`;
        const method = "GET";
        fetch(apiUrl, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
          .then((response) => {
            if (!response.ok) {
              reject(this.getRejectMessage(method, apiUrl, undefined, response.status, response.statusText));
              return;
            }
            return response.json();
          })
          .then((playlists: { value: IPlaylist[], count: number }) => {
            resolve(playlists);
          })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, undefined, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static getPlaylistById(environment: IEnvironment, token: string, tenantId: string, profileId: string, id: string): Promise<IPlaylistExtended> {
    return new Promise<IPlaylistExtended>((resolve, reject) => {
      if (environment && tenantId && profileId && id) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/playlists/${id}`;
        const method = "GET";
        fetch(apiUrl, {
          method: method,
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
          .then((response) => {
            if (!response.ok) {
              reject(this.getRejectMessage(method, apiUrl, undefined, response.status, response.statusText));
              return;
            }
            return response.json();
          })
          .then((playlist: IPlaylistExtended) => {
            resolve(playlist);
          })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, undefined, undefined, error?.message));
          });
      } else {
        reject(null);
      }
    });
  }

  public static createPlaylist(environment: IEnvironment, token: string, tenantId: string, profileId: string, playlist: IPlaylist): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (environment && tenantId && profileId && playlist) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/playlists`;
        const method = "POST";
        fetch(apiUrl, {
          method: method,
          body: JSON.stringify(playlist),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
          .then((response) => {
            if (!response.ok) {
              reject(this.getRejectMessage(method, apiUrl, playlist, response.status, response.statusText));
              return;
            }
            return response.json();
          })
          .then((playlist: { id: string }) => {
            resolve(playlist.id);
          })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, playlist, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static updatePlaylist(environment: IEnvironment, token: string, tenantId: string, profileId: string, playlist: IPlaylist): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId && playlist) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/playlists/${playlist.id}`;
        const method = "PUT";
        fetch(apiUrl, {
          method: method,
          body: JSON.stringify(playlist),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
          .then((response) => {
            if (!response.ok) {
              reject(this.getRejectMessage(method, apiUrl, playlist, response.status, response.statusText));
              return;
            }
            resolve();
          })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, playlist, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static deletePlaylist(environment: IEnvironment, token: string, tenantId: string, profileId: string, id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId && id) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/playlists/${id}`;
        const method = "DELETE";
        fetch(apiUrl, {
          method: method,
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, undefined, response.status, response.statusText));
            return;
          }
          resolve();
        })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, undefined, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  /////////////////////////////////////////////////////////////// Slide ///////////////////////////////////////////////////////////////

  public static getSlides(environment: IEnvironment, token: string, tenantId: string, profileId: string, filters?: IAPlayRequestFilters): Promise<{ value: IPlaySlide[], count: number }> {
    return new Promise<{ value: IPlaySlide[], count: number }>((resolve, reject) => {
      if (environment && tenantId && profileId) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/slides?${PlayHelper.convertFiltersToURLParams(filters)}`;
        const method = "GET";
        fetch(apiUrl, {
          method: method,
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, undefined, response.status, response.statusText));
            return;
          }
          return response.json();
        })
          .then((slides: { value: IPlaySlide[], count: number }) => {
            resolve(slides);
          })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, undefined, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static getSlidesForPlaylist(environment: IEnvironment, token: string, tenantId: string, profileId: string, playlistId: string, filters?: IAPlayRequestFilters): Promise<IPlaySlide[]> {
    return new Promise<IPlaySlide[]>((resolve, reject) => {
      if (environment && tenantId && profileId && playlistId) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/slides/?playlistId=${playlistId}&${PlayHelper.convertFiltersToURLParams(filters)}`;
        const method = "GET";
        fetch(apiUrl, {
          method: method,
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, undefined, response.status, response.statusText));
            return;
          }
          return response.json();
        })
          .then((slides: IPlaySlide[]) => {
            resolve(slides);
          })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, undefined, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static getSlide(environment: IEnvironment, token: string, tenantId: string, profileId: string, slideId: string): Promise<IPlaySlideExtended> {
    return new Promise<IPlaySlideExtended>((resolve, reject) => {
      if (environment && tenantId && profileId && slideId) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/slides/${slideId}`;
        const method = "GET";
        fetch(apiUrl, {
          method: method,
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, undefined, response.status, response.statusText));
            return;
          }
          return response.json();
        })
          .then((slide: IPlaySlideExtended) => {
            resolve(slide);
          })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, undefined, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static createSlide(environment: IEnvironment, token: string, tenantId: string, profileId: string, slide: IPlaySlide): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (environment && tenantId && profileId && slide) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/slides`;
        const method = "POST";
        fetch(apiUrl, {
          method: method,
          body: JSON.stringify(slide),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, slide, response.status, response.statusText));
            return;
          }
          return response.json();
        })
          .then((slide: { id: string }) => {
            resolve(slide.id);
          })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, slide, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static updateSlide(environment: IEnvironment, token: string, tenantId: string, profileId: string, slide: IPlaySlide): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId && slide) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/slides/${slide.id}`;
        const method = "PUT";
        fetch(apiUrl, {
          method: method,
          body: JSON.stringify(slide),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, slide, response.status, response.statusText));
            return;
          }
          resolve();
        })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, slide, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static deleteSlide(environment: IEnvironment, token: string, tenantId: string, profileId: string, slideId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId && slideId) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/slides/${slideId}`;
        const method = "DELETE";
        fetch(apiUrl, {
          method: method,
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, undefined, response.status, response.statusText));
            return;
          }
          resolve();
        })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, undefined, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  ////////////////////////////////////////////////////////////// Widgets //////////////////////////////////////////////////////////////

  public static getWidgets(environment: IEnvironment, token: string, tenantId: string, profileId: string, type?: IPlayWidgetType, filters?: IAPlayRequestFilters): Promise<{ value: IPlayWidget[], count: number }> {
    return new Promise<{ value: IPlayWidget[], count: number }>((resolve, reject) => {
      if (environment && tenantId && profileId) {
        const query = PlayHelper.convertFiltersToURLParams(filters);
        query.set("type", type ?? "");
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/widgets?${query}`;
        const method = "GET";
        fetch(apiUrl, {
          method: method,
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, undefined, response.status, response.statusText));
            return;
          }
          return response.json();
        })
          .then((widgets: { value: IPlayWidget[], count: number }) => {
            resolve(widgets);
          })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, undefined, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static getWidgetById(environment: IEnvironment, token: string, tenantId: string, profileId: string, id: string): Promise<IPlayWidgetExtended> {
    return new Promise<IPlayWidgetExtended>((resolve, reject) => {
      if (environment && tenantId && profileId && id) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/widgets/${id}`;
        const method = "GET";
        fetch(apiUrl, {
          method: method,
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, undefined, response.status, response.statusText));
            return;
          }
          return response.json();
        })
          .then((widget: IPlayWidgetExtended) => {
            resolve(widget);
          })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, undefined, undefined, error?.message));
          });
      } else {
        reject(null);
      }
    });
  }


  public static createWidget(environment: IEnvironment, token: string, tenantId: string, profileId: string, widget: IPlayWidget): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (environment && tenantId && profileId && widget) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/widgets`;
        const method = "POST";
        fetch(apiUrl, {
          method: method,
          body: JSON.stringify(widget),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, widget, response.status, response.statusText));
            return;
          }
          return response.json();
        })
          .then((widget: { id: string }) => {
            resolve(widget.id);
          })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, widget, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static updateWidget(environment: IEnvironment, token: string, tenantId: string, profileId: string, widget: IPlayWidget): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId && widget) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/widgets/${widget.id}`;
        const method = "PUT";
        fetch(apiUrl, {
          method: method,
          body: JSON.stringify(widget),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, widget, response.status, response.statusText));
            return;
          }
          resolve();
        })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, widget, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static deleteWidget(environment: IEnvironment, token: string, tenantId: string, profileId: string, id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId && id) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/widgets/${id}`;
        const method = "DELETE";
        fetch(apiUrl, {
          method: method,
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, undefined, response.status, response.statusText));
            return;
          }
          resolve();
        })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, undefined, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static getWeatherForecast(environment: IEnvironment, lat: string, lon: string): Promise<IPlayWeatherData> {
    return new Promise((resolve, reject) => {
      // Get from cache if data is less than an hour old
      const cacheKey = "IA_weatherData";
      const cachedWeather = localStorage.getItem(cacheKey);
      const parsed = cachedWeather ? JSON.parse(cachedWeather) : undefined;
      if (parsed && parsed.lat === lat && parsed.lon === lon && new DateTimeService().getDatePlusOneHour(new Date(parsed.date)) > new Date()) {
        console.log("Using cached weather data");
        resolve(JSON.parse(cachedWeather).value);
      } else {
        console.log("Fetching weather data");
        const playApi = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${playApi}/weather/forecast?lat=${lat}&lon=${lon}`, {
          method: "GET",
          headers: {
            'Content-Type': 'application/json'
          },
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          return response.json();
        }).then((weatherData: IPlayWeatherData) => {
          Helper.setLocalStorage(cacheKey, JSON.stringify({
            date: new Date(),
            lat: lat,
            lon: lon,
            value: weatherData
          }));
          resolve(weatherData);
        })
          .catch(error => {
            console.error(error);
            reject(error);
          });
      }
    });
  }

  public static getCityName(environment: IEnvironment, lat: string, lon: string,): Promise<string> {
    return new Promise((resolve, reject) => {
      const playApi = Environment.getEnvironmentForPlayApi(environment);
      fetch(`${playApi}/geolocation/city?lat=${lat}&lon=${lon}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json'
        },
      }).then((response) => {
        if (!response.ok) {
          reject(response);
          return;
        }
        return response.text();
      }).then((cityName) => {
        resolve(cityName);
      })
        .catch(error => {
          console.error(error);
          reject(error);
        });
    });
  }

  public static addImageToSocialGallery(environment: IEnvironment, tenantId: string, profileId: string, galleryId: string, imageUrl: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId && galleryId && imageUrl) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/widgets/${galleryId}/gallery-image?url=${imageUrl}`, {
          method: "POST"
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          resolve();
        })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  public static generateSocialGalleryZip(environment: IEnvironment, token: string, tenantId: string, profileId: string, galleryId: string): Promise<{ statusUri: string }> {
    return new Promise<{ statusUri: string }>((resolve, reject) => {
      if (environment && tenantId && profileId && galleryId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/widgets/${galleryId}/social-gallery`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
          .then((response) => {
            if (!response.ok) {
              reject(response);
              return;
            }
            return response.json();
          })
          .then(generateResponse => {
            resolve({
              statusUri: generateResponse?.statusQueryGetUri
            });
          })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  public static getSocialGalleryZipStatus(tenantId: string, statusUri: string): Promise<{ runtimeStatus: string, output: string }> {
    console.log(tenantId);
    return new Promise<{ runtimeStatus: string, output: string }>((resolve, reject) => {
      if (statusUri) {
        fetch(statusUri, {
          method: "GET"
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          return response.json();
        })
          .then(status => {
            resolve({
              runtimeStatus: status.runtimeStatus,
              output: status.output
            });
          })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  ////////////////////////////////////////////////////////////// Articles /////////////////////////////////////////////////////////////

  public static getArticles(environment: IEnvironment, token: string, tenantId: string, profileId: string, filters?: IAPlayRequestFilters): Promise<{ value: IPlayArticle[], count: number }> {
    return new Promise<{ value: IPlayArticle[], count: number }>((resolve, reject) => {
      if (environment && tenantId && profileId) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/articles?${PlayHelper.convertFiltersToURLParams(filters)}`;
        const method = "GET";
        fetch(apiUrl, {
          method: method,
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, undefined, response.status, response.statusText));
            return;
          }
          return response.json();
        })
          .then((articles: { value: IPlayArticle[], count: number }) => {
            resolve(articles);
          })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, undefined, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static getArticlesForWidget(environment: IEnvironment, token: string, tenantId: string, profileId: string, widgetId: string, filters?: string): Promise<IPlayArticle[]> {
    return new Promise<IPlayArticle[]>((resolve, reject) => {
      if (environment && tenantId && profileId && widgetId) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/articles/?widgetId=${widgetId}${filters ? filters : ""}`;
        const method = "GET";
        fetch(apiUrl, {
          method: method,
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, undefined, response.status, response.statusText));
            return;
          }
          return response.json();
        })
          .then((articles: IPlayArticle[]) => {
            resolve(articles);
          })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, undefined, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static getArticle(environment: IEnvironment, token: string, tenantId: string, profileId: string, articleId: string): Promise<IPlayArticle> {
    return new Promise<IPlayArticle>((resolve, reject) => {
      if (environment && tenantId && profileId && articleId) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/articles/${articleId}`;
        const method = "GET";
        fetch(apiUrl, {
          method: method,
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, undefined, response.status, response.statusText));
            return;
          }
          return response.json();
        })
          .then((article: IPlayArticle) => {
            resolve(article);
          })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, undefined, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static createArticle(environment: IEnvironment, token: string, tenantId: string, profileId: string, article: IPlayArticle): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (environment && tenantId && profileId && article) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/articles`;
        const method = "POST";
        fetch(apiUrl, {
          method: method,
          body: JSON.stringify(article),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, article, response.status, response.statusText));
            return;
          }
          return response.json();
        })
          .then((article: { id: string }) => {
            resolve(article.id);
          })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, article, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static updateArticle(environment: IEnvironment, token: string, tenantId: string, profileId: string, article: IPlayArticle): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId && article) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/articles/${article.id}`;
        const method = "PUT";
        fetch(apiUrl, {
          method: method,
          body: JSON.stringify(article),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, article, response.status, response.statusText));
            return;
          }
          resolve();
        })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, article, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static deleteArticle(environment: IEnvironment, token: string, tenantId: string, profileId: string, articleId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId && articleId) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/articles/${articleId}`;
        const method = "DELETE";
        fetch(apiUrl, {
          method: method,
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, undefined, response.status, response.statusText));
            return;
          }
          resolve();
        })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, undefined, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  /////////////////////////////////////////////////////////////// Groups //////////////////////////////////////////////////////////////

  public static getGroups(environment: IEnvironment, token: string, tenantId: string, profileId: string): Promise<{ value: IPlayerGroup[], count: number }> {
    return new Promise<{ value: IPlayerGroup[], count: number }>((resolve, reject) => {
      if (environment && tenantId && profileId && token) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/player-groups`;
        const method = "GET";
        fetch(apiUrl, {
          method: method,
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, undefined, response.status, response.statusText));
            return;
          }
          return response.json();
        })
          .then((groups: { value: IPlayerGroup[], count: number }) => {
            resolve(groups);
          })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, undefined, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static createGroup(environment: IEnvironment, token: string, tenantId: string, profileId: string, group: IPlayerGroup): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (environment && tenantId && profileId && token && group) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/player-groups`;
        const method = "POST";
        fetch(apiUrl, {
          method: method,
          body: JSON.stringify(group),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, group, response.status, response.statusText));
            return;
          }
          return response.json();
        })
          .then((group) => {
            resolve(group?.id);
          })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, group, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static updateGroup(environment: IEnvironment, token: string, tenantId: string, profileId: string, group: IPlayerGroup): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId && token && group) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/player-groups/${group.id}`;
        const method = "PUT";
        fetch(apiUrl, {
          method: method,
          body: JSON.stringify(group),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, group, response.status, response.statusText));
            return;
          }
          resolve();
        })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, group, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  public static deleteGroup(environment: IEnvironment, token: string, tenantId: string, profileId: string, groupId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId && token && groupId) {
        const apiUrl = `${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/profiles/${profileId}/player-groups/${groupId}`;
        const method = "DELETE";
        fetch(apiUrl, {
          method: method,
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(this.getRejectMessage(method, apiUrl, undefined, response.status, response.statusText));
            return;
          }
          resolve();
        })
          .catch(error => {
            reject(this.getRejectMessage(method, apiUrl, undefined, undefined, error?.message));
          });
      } else {
        return reject(null);
      }
    });
  }

  //////////////////////////////////////////////////////////// Media files ////////////////////////////////////////////////////////////

  public static getMediaFileLinkToUpload = (environment: IEnvironment, token: string, tenantId: string, profileId: string, name: string) => {
    return new Promise<IAzureStorageUploadInfo>((resolve, reject) => {
      if (environment && tenantId && profileId && name) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/media-files/info/${name}`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          return response.json();
        })
          .then((response: IAzureStorageUploadInfo) => {
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  public static getSocialGalleryMediaFileLinkToUpload = (environment: IEnvironment, tenantId: string, profileId: string, galleryId: string, name: string) => {
    return new Promise<IAzureStorageUploadInfo>((resolve, reject) => {
      if (environment && tenantId && profileId && galleryId && name) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/widgets/${galleryId}/media-files/info/${name}`, {
          method: "GET"
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          return response.json();
        })
          .then((response: IAzureStorageUploadInfo) => {
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  private static createBlobInContainer = async (containerClient: ContainerClient, tenantId: string, profileId: string, file: File, fileName: string) => {
    const blobClient = containerClient.getBlockBlobClient(`${tenantId}/${profileId}/${fileName}`);
    const options = { blobHTTPHeaders: { blobContentType: file.type } };
    await blobClient.uploadData(file, options);
  }

  public static uploadFileToAzureStorage = async (tenantId: string, profileId: string, storageUri: string, containerName: string, sharedAccessSignature: string, file: File, fileName: string) => {
    const blobServiceClient = new BlobServiceClient(`${storageUri}${sharedAccessSignature}`);
    const containerClient = blobServiceClient.getContainerClient(containerName);
    await this.createBlobInContainer(containerClient, tenantId, profileId, file, fileName);
  }

  public static getMediaFiles = (environment: IEnvironment, token: string, tenantId: string, profileId: string, type?: string, filters?: IAPlayRequestFilters): Promise<{ value: IMediaFile[], count: number }> => {
    return new Promise<{ value: IMediaFile[], count: number }>((resolve, reject) => {
      if (environment && tenantId && profileId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        const query = PlayHelper.convertFiltersToURLParams(filters);
        query.set("type", type ?? "");
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/media-files?${query}`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          return response.json();
        })
          .then((mediaFiles: { value: IMediaFile[], count: number }) => {
            resolve(mediaFiles);
          })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  public static saveMediaFile = (environment: IEnvironment, token: string, tenantId: string, profileId: string, mediaFile: IMediaFile) => {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId && mediaFile) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/media-files`, {
          method: "POST",
          body: JSON.stringify(mediaFile),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          resolve();
        })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  public static deleteMediaFile = (environment: IEnvironment, token: string, tenantId: string, profileId: string, id: string) => {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId && id) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/media-files/${id}`, {
          method: "DELETE",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          resolve();
        })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  public static getMediaFileTags = (environment: IEnvironment, token: string, tenantId: string, profileId: string, filters?: IAPlayRequestFilters): Promise<IMediaFileTag[]> => {
    return new Promise<IMediaFileTag[]>((resolve, reject) => {
      if (environment && tenantId && profileId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        const query = PlayHelper.convertFiltersToURLParams(filters);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/media-file-tags?${query}`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          return response.json();
        })
          .then((mediaFileTags: IMediaFileTag[]) => {
            resolve(mediaFileTags);
          })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  public static createMediaFileTag = (environment: IEnvironment, token: string, tenantId: string, profileId: string, tagName: string): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      if (environment && tenantId && profileId && tagName) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/media-file-tags`, {
          method: "POST",
          body: JSON.stringify({
            id: tagName
          }),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          resolve(tagName.toLocaleLowerCase());
        })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  public static deleteMediaFileTag = (environment: IEnvironment, token: string, tenantId: string, profileId: string, tagName: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId && tagName) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/media-file-tags/${tagName}`, {
          method: "DELETE",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          resolve();
        })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  //////////////////////////////////////////////////////////// Admin users ////////////////////////////////////////////////////////////

  public static getAdminUsers = (environment: IEnvironment, token: string, tenantId: string) => {
    return new Promise<IPlayUser[]>((resolve) => {
      if (environment && tenantId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/admins/`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            resolve([]);
            return;
          }
          return response.json();
        })
          .then((profileUsers: IPlayUser[]) => {
            resolve(profileUsers);
          })
          .catch(error => {
            console.log(error);
            resolve([]);
          });
      } else {
        return resolve([]);
      }
    });
  }

  public static addAdminUser = (environment: IEnvironment, token: string, tenantId: string, userId: string) => {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && userId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/admins`, {
          method: "POST",
          body: JSON.stringify({ userId }),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          resolve();
        })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  public static removeAdminUser = (environment: IEnvironment, token: string, tenantId: string, userId: string) => {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/admins/${userId}`, {
          method: "DELETE",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          resolve();
        })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }


  //////////////////////////////////////////////////////////// Profile users ////////////////////////////////////////////////////////////

  public static getProfileUsers = (environment: IEnvironment, token: string, tenantId: string, profileId: string) => {
    return new Promise<IPlayUser[]>((resolve, reject) => {
      if (environment && tenantId && profileId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/users`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          return response.json();
        })
          .then((profileUsers: IPlayUser[]) => {
            resolve(profileUsers);
          })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  public static addUserToProfile = (environment: IEnvironment, token: string, tenantId: string, profileId: string, userId: string) => {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/users`, {
          method: "POST",
          body: JSON.stringify({ userId }),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          resolve();
        })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  public static removeUserFromProfile = (environment: IEnvironment, token: string, tenantId: string, profileId: string, userId: string) => {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/users/${userId}`, {
          method: "DELETE",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          resolve();
        })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  public static getCurrentUser(environment: IEnvironment, token: string, tenantId: string,): Promise<IPlayUser> {
    return new Promise<IPlayUser>((resolve, reject) => {
      fetch(`${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/graph/users/me`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`
        }
      }).then((response) => {
        if (!response.ok) {
          reject(response);
          return;
        }
        return response.json();
      })
        .then(user => {
          if (user) {
            resolve(user);
          }
        });
    });
  }

  //////////////////////////////////////////////////////////// Graph users ////////////////////////////////////////////////////////////

  public static getGraphUsers = (environment: IEnvironment, token: string, tenantId: string, searchTerm: string) => {
    return new Promise<any[]>((resolve, reject) => {
      if (environment && tenantId && token) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/graph/users${searchTerm ? `?search=${searchTerm}` : ''}`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          return response.json();
        })
          .then((profileUsers: any[]) => {
            resolve(profileUsers);
          })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  //////////////////////////////////////////////////////////// Stock Photos ////////////////////////////////////////////////////////////

  public static getStockImages(environment: IEnvironment, tenantId: string, token: string, search: string, top: number, skip: number, language?: string): Promise<IStockImagesSearchResult> {
    return new Promise<IStockImagesSearchResult>((resolve, reject) => {
      fetch(`${Environment.getEnvironmentForPlayApi(environment)}/${tenantId}/stock/?filter=pivotNames%2Fany(p%3A%20p%20eq%20%27Stock%20Images%27)&search=${search}&top=${top}&skip=${skip}&language=${language ?? "en-US"}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`
        }
      }).then((response) => {
        if (!response.ok) {
          reject(response);
          return;
        }
        return response.json();
      })
        .then(response => {
          if (response) {
            resolve({
              count: response.Count,
              images: response.MicrosoftContents,
              searchQuery: search
            });
          }
        });
    });
  }

  //////////////////////////////////////////////////////////// Integrations ////////////////////////////////////////////////////////////

  public static getIntegrations(environment: IEnvironment, token: string, tenantId: string, application: 'messages' | 'events'): Promise<IFeed[]> {
    return new Promise<IFeed[]>((resolve) => {
      if (environment && tenantId && token && application) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/intranet/${application}/feeds`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return response.json();
        })
          .then((integrations: IFeed[]) => {
            resolve(integrations);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static getProfileIntegrations(environment: IEnvironment, token: string, tenantId: string, profileId: string, application: 'messages' | 'events'): Promise<IFeed[]> {
    return new Promise<IFeed[]>((resolve) => {
      if (environment && tenantId && token && profileId && application) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/intranet/${application}/feeds`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return response.json();
        })
          .then((integrations: IFeed[]) => {
            resolve(integrations);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static verifyIntranetConnection(environment: IEnvironment, token: string, tenantId: string, application: 'messages' | 'events'): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (environment && tenantId && token && application) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/intranet/${application}/connect`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return response.json();
        })
          .then((isConnected) => {
            resolve(isConnected);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    })
  }

  public static connectIntranetApplicationFunction(environment: IEnvironment, token: string, tenantId: string, profileId: string, application: 'messages' | 'events', feedIds: string[]): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (environment && tenantId && token && application) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/intranet/${application}/connect`, {
          method: "POST",
          body: profileId ? JSON.stringify({ profileId, feedIds }) : null,
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return response.json();
        })
          .then((isConnected) => {
            resolve(isConnected);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    })
  }

  public static connectWorkplaceFeedFunction(environment: IEnvironment, token: string, tenantId: string, profileId: string, accessToken: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (environment && tenantId && profileId && accessToken) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/workplaceintegration`, {
          method: "POST",
          body: JSON.stringify({ "accessToken": accessToken }),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return response.json();
        })
          .then((isConnected) => {
            resolve(isConnected);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    })
  }

  public static registerFacebookPagesForProfile(environment: IEnvironment, token: string, tenantId: string, profileId: string, pages: IFacebookPageIntegration[]): Promise<IFacebookPageIntegration[]> {
    return new Promise<IFacebookPageIntegration[]>((resolve) => {
      if (environment && tenantId && profileId && pages) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/facebookpages`, {
          method: "POST",
          body: JSON.stringify(pages),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return response.json();
        })
          .then((isConnected) => {
            resolve(isConnected);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    })
  }

  public static connectFacebookPages(environment: IEnvironment, tenantId: string, userId: string, userToken: string): Promise<IFacebookPageIntegration[]> {
    return new Promise<IFacebookPageIntegration[]>((resolve) => {
      if (environment && tenantId && userToken && userId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/Facebookintegration`, {
          method: "POST",
          body: JSON.stringify({ "accessToken": userToken, "userId": userId }),
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return response.json();
        })
          .then((isConnected) => {
            resolve(isConnected);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    })
  }

  public static getWorkplaceAPIKey(environment: IEnvironment, token: string, tenantId: string, profileId: string): Promise<string> {
    return new Promise<string>((resolve) => {
      if (environment && tenantId && token && profileId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/workplaceintegration`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (response?.status !== 200) {
            resolve(undefined);
            return;
          }
          return response?.json();
        })
          .then((result) => {
            resolve(result?.accessToken);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static getFacebookPageIntegrations(environment: IEnvironment, token: string, tenantId: string, profileId: string): Promise<IFacebookPageIntegration[]> {
    return new Promise<IFacebookPageIntegration[]>((resolve) => {
      if (environment && tenantId && token && profileId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/facebookintegration`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (response?.status !== 200) {
            resolve(undefined);
            return;
          }
          return response?.json();
        })
          .then((result) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static getFacebookPages(environment: IEnvironment, token: string, tenantId: string): Promise<IFacebookPageIntegration[]> {
    return new Promise<IFacebookPageIntegration[]>((resolve) => {
      if (environment && tenantId && token) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/facebookintegration`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (response?.status !== 200) {
            resolve(undefined);
            return;
          }
          return response?.json();
        })
          .then((result) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static getFacebookPageFeed(environment: IEnvironment, token: string, tenantId: string, profileId: string, pageId: string): Promise<IFacebookFeedItem[]> {
    return new Promise<IFacebookFeedItem[]>((resolve, reject) => {
      if (environment && tenantId && token && profileId && pageId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/${profileId}/facebookpage/feed/${pageId}`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then(async (response) => {
          if (response?.status !== 200) {
            reject(await response?.text())
            return;
          }
          return response?.json();
        })
          .then((result) => {
            resolve(result);
          })
          .catch(error => {
            reject(error);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static getWorkplaceFeed(environment: IEnvironment, token: string, tenantId: string, profileId: string, groups: string[]): Promise<IPlayWorkplaceFeedItem[]> {
    return new Promise<IPlayWorkplaceFeedItem[]>((resolve) => {
      if (environment && tenantId && token && profileId && groups) {
        let groupsString = "";
        groups?.forEach((group: string, index) => {
          groupsString += group;
          if (groups.length > index + 1) {
            groupsString += "-";
          }
        });
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        let url = `${apiUrl}/${tenantId}/${profileId}/workplace/feed/${groupsString}`;
        fetch(url, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return response?.json();
        })
          .then((messages) => {
            resolve(messages);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    })
  }

  public static getFeed(environment: IEnvironment, token: string, tenantId: string, profileId: string, application: 'messages' | 'events', feedId: string, from?: string, to?: string, count?: number): Promise<IPlayMessage[]> {
    return new Promise<IPlayMessage[]>((resolve) => {
      if (environment && tenantId && token && profileId && application && feedId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        let url = `${apiUrl}/${tenantId}/intranet/${application}/feeds/${feedId}`;
        if (from) {
          url += `?from=${from}`;
        }
        if (from && to) {
          url += `&to=${to}`;
        }
        if (count) {
          url += `&count=${count}`;
        }
        fetch(url, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return response.json();
        })
          .then((messages) => {
            resolve(messages);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    })
  }

  public static verifyAppRegistrationConsent(environment: IEnvironment, token: string, tenantId: string, appRegistration: string): Promise<IPlayAppRegistrationConsentResponse> {
    return new Promise<IPlayAppRegistrationConsentResponse>((resolve) => {
      if (environment && tenantId && token && appRegistration) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/apps/${appRegistration}/verify-consent`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return response.json();
        })
          .then((result) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    })
  }

  public static getSharePointSitesSelectedAsFeeds(environment: IEnvironment, token: string, tenantId: string): Promise<IFeed[]> {
    return new Promise<IFeed[]>((resolve) => {
      if (environment && tenantId && token) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/sharepoint/sites`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return response.json();
        })
          .then((result) => {
            resolve(result.map(item => ({
              id: item.name,
              title: item.displayName,
              application: "sharepoint-news",
              meta: {
                siteId: item.id
              } as IFeedSharePointSiteMeta
            } as IFeed)));
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static getSharePointProfileNewsSites(environment: IEnvironment, token: string, tenantId: string, profileId: string): Promise<IFeed[]> {
    return new Promise<IFeed[]>((resolve) => {
      if (environment && tenantId && profileId && token) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/sharepoint/sites`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return response.json();
        })
          .then((result) => {
            resolve(result.map(item => ({
              id: item.name,
              title: item.displayName,
              application: "sharepoint-news"
            } as IFeed)));
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static getSharepointNews(environment: IEnvironment, token: string, tenantId: string, profileId: string, siteName: string, take: number): Promise<IPlaySharepointNewsItem[]> {
    return new Promise<IPlaySharepointNewsItem[]>((resolve) => {
      if (environment && tenantId && profileId && token && siteName) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/sharepoint/news/${siteName}?take=${take}&skip=0`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return response.json();
        })
          .then((result) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static updateSharePointSitesForProfile(environment: IEnvironment, token: string, tenantId: string, profileId: string, siteNames: string[], sharepointRootSite: string): Promise<void> {
    return new Promise<void>((resolve) => {
      if (environment && tenantId && profileId && token) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/sharepoint/sites`, {
          method: "POST",
          body: JSON.stringify({ siteNames: siteNames, sharepointRootSite: sharepointRootSite }),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return {};
        })
          .then(() => {
            resolve();
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static searchSharePointSites(environment: IEnvironment, token: string, tenantId: string, searchTerm: string): Promise<IFeed[]> {
    return new Promise<IFeed[]>((resolve) => {
      if (environment && token && tenantId && searchTerm) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/sharepoint/sites/search?text=${searchTerm}`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return response.json();
        })
          .then((result) => {
            resolve(result.searchResults.map(item => ({
              id: item.id,
              title: item.name,
              application: "sharepoint-news"
            } as IFeed)));
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static createSharePointSitePermission(environment: IEnvironment, token: string, tenantId: string, id: string): Promise<IFeed[]> {
    return new Promise<IFeed[]>((resolve) => {
      if (environment && token && tenantId && id) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/sharepoint/sites`, {
          method: "POST",
          body: JSON.stringify({ siteId: id }),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return response.json();
        })
          .then((result) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static deleteSharePointSitePermission(environment: IEnvironment, token: string, tenantId: string, id: string): Promise<IFeed[]> {
    return new Promise<IFeed[]>((resolve) => {
      if (environment && token && tenantId && id) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/sharepoint/sites/${id}`, {
          method: "DELETE",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return response.json();
        })
          .then((result) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }


  //////////////////////////////////////////////////////////// Reepay ////////////////////////////////////////////////////////////

  public static getCustomer(environment: IEnvironment, token: string, tenantId: string): Promise<IPlayCompanyInformation> {
    return new Promise<IPlayCompanyInformation>((resolve) => {
      if (environment && tenantId && token) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/reepay/customers/${tenantId}`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return response.json();
        })
          .then((customer) => {
            resolve(customer);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static createCustomer(environment: IEnvironment, token: string, tenantId: string, companyInformation: IPlayCompanyInformation): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && token && companyInformation) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/reepay/customers`, {
          method: "POST",
          body: JSON.stringify(companyInformation),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          resolve();
        })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  public static updateCustomer(environment: IEnvironment, token: string, tenantId: string, companyInformation: IPlayCompanyInformation): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && token && companyInformation) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/reepay/customers/${tenantId}`, {
          method: "PUT",
          body: JSON.stringify(companyInformation),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          resolve();
        })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  public static getSubscriptions(environment: IEnvironment, token: string, tenantId: string): Promise<any[]> {
    return new Promise<any[]>((resolve) => {
      if (environment && tenantId && token) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/reepay/customers/${tenantId}/subscriptions`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return response.json();
        })
          .then((result) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static getSubscription(environment: IEnvironment, token: string, tenantId: string, subscriptionId: string): Promise<any> {
    return new Promise<any>((resolve) => {
      if (environment && tenantId && token && subscriptionId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/reepay/customers/${tenantId}/subscriptions/${subscriptionId}`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return response.json();
        })
          .then((result) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static createSubscription(environment: IEnvironment, token: string, tenantId: string, numberOfPlayers: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && token) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/reepay/customers/${tenantId}/subscriptions`, {
          method: "POST",
          body: JSON.stringify(
            {
              plan: "plan-a71c3",
              signupMethod: "link",
              addOns: [{
                addOn: "add-on-521e1",
                name: "Players",
                quantity: numberOfPlayers,
                amount: 9900
              }]
            }
          ),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          resolve();
        })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  public static updateSubscription(environment: IEnvironment, token: string, tenantId: string, subscriptionId: string, numberOfPlayers: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && token) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/reepay/customers/${tenantId}/subscriptions/${subscriptionId}`, {
          method: "PUT",
          body: JSON.stringify(
            {
              plan: "plan-a71c3",
              signupMethod: "link",
              addOns: [{
                addOn: "add-on-521e1",
                name: "Players",
                quantity: numberOfPlayers,
                amount: 9900
              }]
            }
          ),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          resolve();
        })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  /////////////////////////////////////////////////////////////// Poll ///////////////////////////////////////////////////////////////

  public static addReplyToPoll(environment: IEnvironment, tenantId: string, profileId: string, pollId: string, replyId: string, deviceId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && profileId && pollId && replyId && deviceId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/pollreply`, {
          method: "POST",
          body: JSON.stringify(
            {
              replyId: replyId,
              pollId: pollId,
              deviceId: deviceId
            }
          )
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          resolve();
        })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  public static getPollResult(environment: IEnvironment, tenantId: string, profileId: string, pollId: string): Promise<any> {
    return new Promise<any>((resolve) => {
      if (environment && tenantId && profileId && pollId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/${pollId}/pollresults`, {
          method: "GET"
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return response.json();
        })
          .then((result) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }


  /////////////////////////////////////////////////////////////// Switch ///////////////////////////////////////////////////////////////

  public static widgetSwitch(environment: IEnvironment, widgetId: string, playerId: string, switchStatus: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && widgetId && playerId && switchStatus) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${widgetId}/${playerId}/switch-status/${switchStatus}`, {
          method: "PUT"
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          resolve();
        })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  public static isWidgetSwitched(environment: IEnvironment, widgetId: string, playerId: string): Promise<IWidgetSwitchStatus> {
    return new Promise<IWidgetSwitchStatus>((resolve) => {
      if (environment && widgetId && playerId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${widgetId}/${playerId}/switch-status`, {
          method: "GET"
        }).then((response) => {
          if (!response.ok) {
            resolve(undefined);
            return;
          }
          return response.json();
        })
          .then((result) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }
  ///////////////////////////////// Power BI //////////////////////////////////////////////////////////////////////////////////////
  public static getPowerBIWorkspaces(environment: IEnvironment, token: string, tenantId: string): Promise<IPowerBIWorkspace[]> {
    return new Promise<IPowerBIWorkspace[]>((resolve) => {
      if (environment && tenantId && token) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/powerbi/groups`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (response?.status !== 200) {
            resolve(undefined);
            return;
          }
          return response?.json();
        })
          .then((result) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static getPowerBIReports(environment: IEnvironment, token: string, tenantId: string, workspaceId: string): Promise<IPowerBIReport[]> {
    return new Promise<IPowerBIReport[]>((resolve) => {
      if (environment && tenantId && token && workspaceId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/powerbi/groups/${workspaceId}/reports`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (response?.status !== 200) {
            resolve(undefined);
            return;
          }
          return response?.json();
        })
          .then((result) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static getPowerBIPages(environment: IEnvironment, token: string, tenantId: string, workspaceId: string, reportId: string): Promise<IPowerBIPage[]> {
    return new Promise<IPowerBIPage[]>((resolve) => {
      if (environment && tenantId && token && workspaceId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/powerbi/groups/${workspaceId}/reports/${reportId}/pages`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (response?.status !== 200) {
            resolve(undefined);
            return;
          }
          return response?.json();
        })
          .then((result) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static getPowerBIAcessToken(environment: IEnvironment, token: string, tenantId: string, datasetId: string, reportId: string): Promise<IPowerBIGenerateToken> {
    return new Promise<IPowerBIGenerateToken>((resolve) => {
      if (environment && tenantId && token && datasetId && reportId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/powerbi/datasets/${datasetId}/reports/${reportId}/generatetoken`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (response?.status !== 200) {
            resolve(undefined);
            return;
          }
          return response?.json();
        })
          .then((result) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static getPowerBiIntegrations(environment: IEnvironment, token: string, tenantId: string, profileId: string): Promise<IPowerBIWorkspace[]> {
    return new Promise<IPowerBIReport[]>((resolve) => {
      if (environment && tenantId && token && profileId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/powerbi/workspaces`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (response?.status !== 200) {
            resolve(undefined);
            return;
          }
          return response?.json();
        })
          .then((result) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static updatePowerBiIntegrations(environment: IEnvironment, token: string, tenantId: string, profileId: string, workspaces: IPowerBIWorkspace[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && token && profileId && workspaces) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/powerbi/workspaces`, {
          method: "POST",
          body: JSON.stringify(workspaces),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          resolve();
        })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  public static getRelesysIntegration(environment: IEnvironment, token: string, tenantId: string, profileId: string): Promise<{clientId: string, clientSecret: string}> {
    return new Promise((resolve) => {
      if (environment && tenantId && token && profileId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/relesys-integration`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (response?.status !== 200) {
            resolve(undefined);
            return;
          }
          return response?.json();
        })
          .then((result) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static registerRelesysIntegrations(environment: IEnvironment, token: string, tenantId: string, profileId: string, clientId: string, clientSecret: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && token && profileId && clientId && clientSecret) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/relesys-integration`, {
          method: "POST",
          body: JSON.stringify({
            clientId,
            clientSecret
          }),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          resolve();
        })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }

  public static getRelesysContentModules(environment: IEnvironment, token: string, tenantId: string, profileId: string): Promise<IPlayRelesysContentModule[]> {
    return new Promise((resolve) => {
      if (environment && tenantId && token && profileId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/relesys/content-modules`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (response?.status !== 200) {
            resolve(undefined);
            return;
          }
          return response?.json();
        })
          .then((result) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static getRelesysContent(environment: IEnvironment, token: string, tenantId: string, profileId: string, contentModuleId: string, amount: number): Promise<IPlayRelesysContent[]> {
    return new Promise((resolve) => {
      if (environment && tenantId && token && profileId && contentModuleId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/relesys/content/${contentModuleId}?amount=${amount}`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (response?.status !== 200) {
            resolve(undefined);
            return;
          }
          return response?.json();
        })
          .then((result) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static validateRelesysIntegration(environment: IEnvironment, token: string, tenantId: string, profileId: string, clientId: string, clientSecret): Promise<{validated: boolean}> {
    return new Promise((resolve) => {
      if (environment && tenantId && token && profileId && clientId && clientSecret) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/validate-relesys`, {
          method: "POST",
          body: JSON.stringify({
            clientId,
            clientSecret
          }),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (response?.status !== 200) {
            resolve(undefined);
            return;
          }
          return response?.json();
        })
          .then((result) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve({validated: false});
      }
    });
  }

  public static getTimeEditTypes(environment: IEnvironment, token: string, tenantId: string, profileId: string): Promise<{types: IPlayTimeEditType[]}> {
    return new Promise((resolve, reject) => {
      if (environment && tenantId && token && profileId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/time-edit-types`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (response?.status !== 200) {
            reject();
            return;
          }
          return response?.json();
        })
          .then((result: {types: IPlayTimeEditType[]}) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            reject();
          });
      } else {
        return reject();
      }
    });
  }

  public static getTimeEditTypeFields(environment: IEnvironment, token: string, tenantId: string, profileId: string, type: string): Promise<{fields: string[]}> {
    return new Promise((resolve, reject) => {
      if (environment && tenantId && token && profileId && type) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/time-edit-type-fields?type=${type}`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (response?.status !== 200) {
            reject();
            return;
          }
          return response?.json();
        })
          .then((result: {fields: string[]}) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            reject();
          });
      } else {
        return reject();
      }
    });
  }

  public static getTimeEditFilterObjects(environment: IEnvironment, token: string, tenantId: string, profileId: string, type: string, searchQuery: string): Promise<{objects: IPlayTimeEditSearchObject[]}> {
    return new Promise((resolve, reject) => {
      if (environment && tenantId && token && profileId && type && searchQuery) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/time-edit-filter-objects?type=${type}&searchQuery=${searchQuery}`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (response?.status !== 200) {
            reject();
            return;
          }
          return response?.json();
        })
          .then((result: {objects: IPlayTimeEditSearchObject[]}) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            reject();
          });
      } else {
        return reject();
      }
    });
  }

  public static getTimeEditSchedule(environment: IEnvironment, token: string, tenantId: string, profileId: string, searchObjects: IPlayTimeEditSearchObject[]): Promise<{reservations: IPlayTimeEditReservation[]}> {
    return new Promise((resolve, reject) => {
      if (environment && tenantId && token && profileId && searchObjects) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/time-edit-schedule`, {
          method: "POST",
          body: JSON.stringify({ objects: searchObjects }),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (response?.status !== 200) {
            reject();
            return;
          }
          return response?.json();
        })
          .then((result: {reservations: IPlayTimeEditReservation[]}) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            reject();
          });
      } else {
        return reject();
      }
    });
  }

  public static getTimeEditIntegration(environment: IEnvironment, token: string, tenantId: string, profileId: string): Promise<IPlayTimeEditIntegration> {
    return new Promise((resolve) => {
      if (environment && tenantId && token && profileId) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/time-edit-integration`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (response?.status !== 200) {
            resolve(undefined);
            return;
          }
          return response?.json();
        })
          .then((result) => {
            resolve(result);
          })
          .catch(error => {
            console.log(error);
            resolve(undefined);
          });
      } else {
        return resolve(undefined);
      }
    });
  }

  public static validateTimeEditIntegration(environment: IEnvironment, token: string, tenantId: string, profileId: string, timeEdit: IPlayTimeEditIntegration): Promise<{validated: boolean}> {
    return new Promise((resolve) => {
      if (environment && tenantId && token && profileId && timeEdit) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/verify-time-edit`, {
          method: "POST",
          body: JSON.stringify(timeEdit),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (response?.status !== 200) {
            resolve({validated: false});
            return;
          }
          resolve({validated: true});
        })
          .catch(() => {
            resolve({validated: false});
          });
      } else {
        return resolve({validated: false});
      }
    });
  }

  public static registerTimeEditIntegrations(environment: IEnvironment, token: string, tenantId: string, profileId: string, timeEdit: IPlayTimeEditIntegration): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (environment && tenantId && token && profileId && timeEdit) {
        const apiUrl = Environment.getEnvironmentForPlayApi(environment);
        fetch(`${apiUrl}/${tenantId}/profiles/${profileId}/time-edit-integration`, {
          method: "POST",
          body: JSON.stringify(timeEdit),
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          resolve();
        })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        return reject(null);
      }
    });
  }
}