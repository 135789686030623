exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_articles_3USE5sbjNhh2kO8X4rU3Mh {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_32xAI0FlTBg4sp73WIqYB_ {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_2a4kPmjJpNOp_ZiDyaM5PC {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_1DSHB4sEbVoKm16G8lA-uk {\n  text-align: center;\n}", ""]);

// Exports
exports.locals = {
	"IA_articles": "IA_articles_3USE5sbjNhh2kO8X4rU3Mh",
	"IA_filters": "IA_filters_32xAI0FlTBg4sp73WIqYB_",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_2a4kPmjJpNOp_ZiDyaM5PC",
	"IA_paginationPageCount": "IA_paginationPageCount_1DSHB4sEbVoKm16G8lA-uk"
};