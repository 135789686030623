import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IAToggle } from '../../../../../toggle/Toggle';
import { IATextField } from '../../../../../textField/TextField';
import { IAGroup } from '../../../../../group/Group';
import { IASpinner } from '../../../../../spinner/Spinner';
import { IANumberField } from '../../../../../numberField/NumberField';
import { MediaFileSelector } from '../../../mediaFiles/mediaFileSelector/MediaFileSelector';
import { IAColorTheme } from '../../../../../colorTheme/ColorTheme';
import { IAButton } from '../../../../../button/Button';
import { Helper } from '../../../../../../Helper';
import PlayService from '../../../../../../services/PlayService';
import { TimeEditFilterSelectorPanel } from './timeEditFilterSelectorPopup/TimeEditFilterSelectorPanel';
import { TimeEditFilter } from './timeEditFilterSelectorPopup/TimeEditFilter';
import { IPlayTimeEditType } from '../../../../../../interfaces/IPlay';
import { RotatorViewTemplate } from '../../../rotatorViewSelector/rotatorViewTemplate/RotatorViewTemplate';

export interface IProps {
  store?: Store;
  forceUpdate: () => void;
  reloadPreview: () => void;
  loadingContent: (loading: boolean) => void
}

export interface IState {
  isLoadingTypes: boolean;
  types: IPlayTimeEditType[];
  showAddFilterPopup: boolean;
}

@inject('store')
@observer
export class TimeEditWidgetSettings extends React.Component<IProps, IState> {
  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoadingTypes: true,
      types: [],
      showAddFilterPopup: false
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentDidMount(): void {
    this.getFilterTypes();
  }

  private getFilterTypes(){
    this.setState({isLoadingTypes: true}, () => {
      PlayService.getTimeEditTypes(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id)
        .then(response => {
          this.setState({types: response.types});
        })
        .finally(() => {
          this.setState({isLoadingTypes: false});
        });
    });
  }

  render(): React.ReactNode {
    const colorThemes = [];
    if (this.props.store.widgetToEdit?.timeEdit?.colorThemes?.length > 0) {
      this.props.store.widgetToEdit?.timeEdit.colorThemes.forEach((colorTheme, index) => {
        colorThemes.push(
          <IAColorTheme
            key={"colorTheme_" + index}
            backgroundColor={colorTheme.backgroundColor}
            textColor={colorTheme.textColor}
            highlightColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            swatches={this.props.store.profile?.swatches}
            inTeams
            remove={undefined}
            allowAddingToSwatches={false}
            setBackgroundColor={(backgroundColor) => {
              colorTheme.backgroundColor = backgroundColor;
              this.props.forceUpdate();
            }}
            setTextColor={(textColor) => {
              colorTheme.textColor = textColor;
              this.props.forceUpdate();
            }}
          />
        )
      });
    }
    return (
      <>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_TimeEditSetup?.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <div style={{
            display: "flex",
            flexDirection: "column"
          }}>
            <label
              style={{
                color: this.props.store.darkMode ? Helper.darkModeLabelColor : '#333333'
              }}
            >
              {this.localizationService.strings.PlayAdmin_TimeEditFilters}
            </label>
            {this.state.isLoadingTypes
            ?
              <IASpinner 
                color={this.props.store.highlightColor}
              />
            :
              <>
                {this.props.store.widgetToEdit.timeEdit?.searchObjects?.length === 0
                ?
                  <p>{this.localizationService.strings.PlayAdmin_TimeEditNoFiltersAdded}</p>
                :
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "flex-start",
                      rowGap: 5,
                      columnGap: 5,
                      marginTop: 10
                    }}
                  >
                    {this.props.store.widgetToEdit.timeEdit?.searchObjects.map(filterObject => <TimeEditFilter
                      highlightColor={this.props.store.highlightColor}
                      widgetFilterObject={filterObject}
                    />)}
                  </div>
                }
                <IAButton
                  iconUrl="https://intraactivestorage.blob.core.windows.net/cdn/icons/Edit/SVG/ic_fluent_edit_48_regular.svg"
                  label={this.localizationService.strings.PlayAdmin_TimeEditEditFilters?.toUpperCase()}
                  buttonColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  onClick={() => this.setState({showAddFilterPopup: true})}
                  showSpinner={this.props.store.workingOnIt}
                  borderRadius={5}
                  style={{
                    marginTop: 10,
                    width: "fit-content"
                  }}
                />
              </>
            }
          </div>
        </IAGroup>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_WidgetSettings?.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <div style={{
            display: "flex",
            flexDirection: "column"
          }}>
            <IATextField
              label={this.localizationService.strings.PlayAdmin_Title?.toUpperCase()}
              required
              placeholder={this.localizationService.strings.PlayAdmin_Title}
              text={this.props.store.widgetToEdit.title}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              borderRadius={5}
              labelStyle={{
                fontSize: "12px"
              }}
              onChange={(title: string) => {
                this.props.store.widgetToEdit.title = title;
                this.props.forceUpdate();
              }}
            />
            <IAToggle
              style={{
                marginTop: 20,
                fontSize: 12
              }}
              darkMode={this.props.store.darkMode}
              label={this.localizationService.strings.PlayAdmin_ShowWidgetTitle?.toUpperCase()}
              checked={this.props.store.widgetToEdit.showTitle}
              color={this.props.store.highlightColor}
              onChange={() => {
                this.props.store.widgetToEdit.showTitle = !!!this.props.store.widgetToEdit.showTitle;
                this.props.forceUpdate();
              }}
            />
            <IANumberField
              label={this.localizationService.strings.PlayAdmin_MinimumDuration?.toUpperCase()}
              number={this.props.store.widgetToEdit?.timeEdit?.duration}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              borderRadius={5}
              labelStyle={{
                fontSize: "12px"
              }}
              onChange={(duration: number) => {
                this.props.store.widgetToEdit.timeEdit.duration = duration;
                this.props.forceUpdate();
              }}
              styles={{
                width: "100%",
                clear: "both"
              }}
            />
            <MediaFileSelector
              label={this.localizationService.strings.PlayAdmin_NoContentImageUrl?.toUpperCase()}
              url={this.props.store.widgetToEdit?.timeEdit?.noContentImageUrl ? this.props.store.widgetToEdit?.timeEdit.noContentImageUrl : this.localizationService.strings.Player_NoContentUrl}
              type={"image"}
              onChange={(noContentImageUrl: string) => {
                this.props.store.widgetToEdit.timeEdit.noContentImageUrl = noContentImageUrl;
                this.props.forceUpdate();
              }}
            />
          </div>
        </IAGroup>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_ArticleLayoutType.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <IAToggle
            style={{
              fontSize: 12
            }}
            darkMode={this.props.store.darkMode}
            label={this.localizationService.strings.PlayAdmin_TimeEditDisplayTodaysDate?.toLocaleUpperCase()}
            checked={this.props.store.widgetToEdit.timeEdit?.showTodaysDate}
            color={this.props.store.highlightColor}
            onChange={() => {
              this.props.store.widgetToEdit.timeEdit.showTodaysDate = !!!this.props.store.widgetToEdit.timeEdit.showTodaysDate;
              this.props.forceUpdate();
            }}
          />
          <MediaFileSelector
            label={this.localizationService.strings.PlayAdmin_BackgroundImage?.toUpperCase()}
            url={this.props.store.widgetToEdit?.timeEdit?.backgroundImage ?? ""}
            type={"image"}
            onChange={(backgroundImage: string) => {
              this.props.store.widgetToEdit.timeEdit.backgroundImage = backgroundImage;
              this.props.forceUpdate();
            }}
          />
          <label
            style={{
              color: this.props.store.darkMode ? Helper.darkModeLabelColor : '#333333',
              marginTop: 20
            }}
          >
            {this.localizationService.strings.PlayAdmin_ColorThemes?.toUpperCase()}
          </label>
          {colorThemes}
          <div style={{display: "flex", clear: "both"}}>
            <RotatorViewTemplate
              template={"full"}
              transparency={"solid"}
              selected={this.props.store.widgetToEdit.timeEdit.boxStyle === "fullSolid" || this.props.store.widgetToEdit.timeEdit.boxStyle === undefined}
              backgroundColor={this.props.store.widgetToEdit.timeEdit.colorThemes[0].backgroundColor}
              textColor={this.props.store.widgetToEdit.timeEdit.colorThemes[0].textColor}
              image={this.props.store.widgetToEdit.timeEdit.backgroundImage}
              onSelect={() => {
                this.props.store.widgetToEdit.timeEdit.boxStyle = "fullSolid";
                this.forceUpdate();
                this.props.reloadPreview();
              }}
            />
            <RotatorViewTemplate
              template={"full"}
              transparency={"transparent"}
              selected={this.props.store.widgetToEdit.timeEdit.boxStyle === "fullTransparent"}
              backgroundColor={this.props.store.widgetToEdit.timeEdit.colorThemes[0].backgroundColor}
              textColor={this.props.store.widgetToEdit.timeEdit.colorThemes[0].textColor}
              image={this.props.store.widgetToEdit.timeEdit.backgroundImage}
              onSelect={() => {
                this.props.store.widgetToEdit.timeEdit.boxStyle = "fullTransparent";
                this.forceUpdate();
                this.props.reloadPreview();
              }}
            />
          </div>
        </IAGroup>
        <TimeEditFilterSelectorPanel
          isOpen={this.state.showAddFilterPopup}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          environment={this.props.store.environment}
          tenantId={this.props.store.tenantId}
          profileId={this.props.store.profile.id}
          token={this.props.store.token}
          types={this.state.types}
          widgetFilterObjects={this.props.store.widgetToEdit.timeEdit?.searchObjects}
          onClosePopup={() => this.setState({ showAddFilterPopup: false })}
          onApplyFilters={(objects) => {
            this.props.loadingContent(true);
            this.setState({ showAddFilterPopup: false }, () => {
              PlayService.getTimeEditSchedule(
                this.props.store.environment,
                this.props.store.token,
                this.props.store.tenantId,
                this.props.store.profile.id,
                objects)
                .then(response => {
                  this.props.store.widgetToEdit.timeEdit.searchObjects = objects;
                  this.props.store.widgetToEdit.timeEdit.reservations = response.reservations;
                })
                .catch(() => {
                  this.props.store.widgetToEdit.timeEdit.searchObjects = objects;
                  this.props.store.widgetToEdit.timeEdit.reservations = [];
                })
                .finally(() => this.props.loadingContent(false));
            });
          }}
        />
      </>
    );
  }
}
