exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_weatherWidgetContainer_3ZCL4f16IGhhs27RQOIIAH {\n  height:100%;\n  width:100%;\n  display:flex;\n  flex-direction:row;\n}\n\n.IA_weatherDay_3WlxxrKItFkaWCJZtYRBKT {\n  flex:1;\n  display:flex;\n  flex-direction:column;\n  align-items:center;\n}\n\n.IA_iconAndPrecipationContainer_2-_YmErV6HS0TY6qC659CK {\n  width:100%;\n  display:flex;\n  flex-direction:column;\n  align-items:center;\n}\n\n.IA_weatherIcon_lC39XwCczDZTp0uTyN2ul {\n  width: 45%;\n}\n\n.IA_precipation_2-BRj6yNAKnaEWibRQ84WD {\n  color:#0062bf;\n  font-weight:bold;\n}\n\n.IA_graphContainer_3wEet6cbjnn1kMUEaeFkeS {\n  width:100%;\n  height:30%;\n  position:absolute;\n  bottom:0;\n  left:0;\n}\n\n.IA_weatherWidgetContainerVertical_3eWpUX7TZgGdB0tW8yGXnV {\n  height:100%;\n  width:100%;\n  background-color:#f0f2f4;\n  display:flex;\n  flex-direction: column;\n}\n\n.IA_weatherDayVertical_27JmD2nITEA04zudL0ARCH {\n  flex:1;\n  display:flex;\n  flex-direction:row;\n  align-items:center;\n  justify-content: space-around;\n}\n\n.IA_exampleWeatherLabel_2uvbSU6ePlrGLxyFO9pDKy {\n  background-color: #ffffff;\n  color: #333333;\n  position: absolute;\n  text-align: center;\n  transform: rotate(-45deg);\n  box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px 0;\n}", ""]);

// Exports
exports.locals = {
	"IA_weatherWidgetContainer": "IA_weatherWidgetContainer_3ZCL4f16IGhhs27RQOIIAH",
	"IA_weatherDay": "IA_weatherDay_3WlxxrKItFkaWCJZtYRBKT",
	"IA_iconAndPrecipationContainer": "IA_iconAndPrecipationContainer_2-_YmErV6HS0TY6qC659CK",
	"IA_weatherIcon": "IA_weatherIcon_lC39XwCczDZTp0uTyN2ul",
	"IA_precipation": "IA_precipation_2-BRj6yNAKnaEWibRQ84WD",
	"IA_graphContainer": "IA_graphContainer_3wEet6cbjnn1kMUEaeFkeS",
	"IA_weatherWidgetContainerVertical": "IA_weatherWidgetContainerVertical_3eWpUX7TZgGdB0tW8yGXnV",
	"IA_weatherDayVertical": "IA_weatherDayVertical_27JmD2nITEA04zudL0ARCH",
	"IA_exampleWeatherLabel": "IA_exampleWeatherLabel_2uvbSU6ePlrGLxyFO9pDKy"
};