import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from '../WidgetEditPanel.css';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IANumberField } from '../../../../../numberField/NumberField';
import { IAToggle } from '../../../../../toggle/Toggle';
import PlayService from '../../../../../../services/PlayService';
import { IAButton } from '../../../../../button/Button';
import { Helper } from '../../../../../../Helper';
import { MediaFileSelector } from '../../../mediaFiles/mediaFileSelector/MediaFileSelector';
import { IAColorTheme } from '../../../../../colorTheme/ColorTheme';
import { IATextField } from '../../../../../textField/TextField';
import { IAGroup } from '../../../../../group/Group';
import { RotatorViewSelector } from '../../../rotatorViewSelector/RotatorViewSelector';
import { IPlayBoxStyle } from '../../../../../../interfaces/IPlay';
import { IADropDown } from '../../../../../dropDown/DropDown';
import { IOption } from '../../../../../../interfaces/IOption';

export interface IProps {
  store?: Store;
  forceUpdate: () => void;
  reloadPreview: () => void;
  loadingContent: (loading: boolean) => void;
}

export interface IState {
  pages?: IOption[];
  colorThemeForPreview?: any;
  groupToAdd?: string;
  integrationError: string;
}

@inject('store')
@observer
export class FacebookWidgetSettings extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      pages: [{key: "", value: " "}],
      integrationError: undefined
    }
    this.updateFacebookPageId = this.updateFacebookPageId.bind(this);
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentDidMount(): void {
    this.getFacebookPages();
    this.fetchContent();
  }

  private getFacebookPages(): void{
    PlayService.getFacebookPageIntegrations(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id).then((pages) => {
      var options = this.state.pages;
      pages.forEach((page) => options.push({key: page.pageId, value: page.pageName}));
      this.setState({pages: options});
      this.props.forceUpdate();
      setTimeout(() => {
        this.props.forceUpdate();
      }, 500);
    });
  }
  private fetchContent(): void {
    this.props.loadingContent(true);
    PlayService.getFacebookPageFeed(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id, this.props.store.widgetToEdit.facebookPageViewer.pageId).then((feed) => {
      this.props.store.widgetToEdit.facebookPageViewer.feed = feed;
    }).catch(e => {
      this.props.store.widgetToEdit.facebookPageViewer.feed = [];
      this.setState({integrationError: JSON.stringify(e)})
    }).finally(() => {
      this.props.loadingContent(false);
      this.props.forceUpdate();
      setTimeout(() => {
        this.props.forceUpdate();
      }, 500);
    });
  }

  private updateFacebookPageId(pageId: string): void{
    this.props.store.widgetToEdit.facebookPageViewer.pageId = pageId;
              this.props.forceUpdate();
              this.fetchContent();
  }

  render(): React.ReactNode {

    const colorThemes = [];
    if (this.props.store.widgetToEdit?.facebookPageViewer?.colorThemes?.length > 0) {
      this.props.store.widgetToEdit?.facebookPageViewer.colorThemes.forEach((colorTheme, index) => {
        colorThemes.push(
          <IAColorTheme
            key={"colorTheme_" + index}
            backgroundColor={colorTheme.backgroundColor}
            textColor={colorTheme.textColor}
            highlightColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            swatches={this.props.store.profile?.swatches}
            inTeams
            remove={() => {
              this.props.store.widgetToEdit?.facebookPageViewer.colorThemes.splice(index, 1);
              this.props.forceUpdate();
            }}
            allowAddingToSwatches={false}
            setBackgroundColor={(backgroundColor) => {
              colorTheme.backgroundColor = backgroundColor;
              this.props.forceUpdate();
            }}
            setTextColor={(textColor) => {
              colorTheme.textColor = textColor;
              this.props.forceUpdate();
            }}
          />
        )
      });
    }

    return (
      <>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_WidgetSettings?.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <IATextField
            label={this.localizationService.strings.PlayAdmin_Title?.toUpperCase()}
            required
            placeholder={this.localizationService.strings.PlayAdmin_Title}
            text={this.props.store.widgetToEdit.title}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(title: string) => {
              this.props.store.widgetToEdit.title = title;
              this.props.forceUpdate();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20,
              fontSize: 12
            }}
            darkMode={this.props.store.darkMode}
            label={this.localizationService.strings.PlayAdmin_ShowWidgetTitle?.toUpperCase()}
            checked={this.props.store.widgetToEdit.showTitle}
            color={this.props.store.highlightColor}
            onChange={() => {
              this.props.store.widgetToEdit.showTitle = this.props.store.widgetToEdit.showTitle != undefined ? !this.props.store.widgetToEdit.showTitle : true;
              this.props.forceUpdate();
            }}
          />
          <IANumberField
            label={this.localizationService.strings.PlayAdmin_MinimumDuration?.toUpperCase()}
            number={this.props.store.widgetToEdit?.facebookPageViewer.duration}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(duration: number) => {
              this.props.store.widgetToEdit.facebookPageViewer.duration = duration;
              this.fetchContent();
              this.props.forceUpdate();
            }}
            styles={{
              width: "100%",
              clear: "both"
            }}
          />

          <IADropDown
            label={this.localizationService.strings.PlayAdmin_Facebook_Page}
            selectedOption={this.props.store.widgetToEdit.facebookPageViewer.pageId}
            options={this.state.pages}
            onChange={(selectedPage: string) => {
              this.updateFacebookPageId(selectedPage);
            }}
            style={{
              float: "left",
              width: "100%"
            }}
            borderRadius={5}
            highlightColor={this.props.store.highlightColor}
          />
          {this.state.integrationError &&
            <>
              <p style={{ color: "red", marginBottom: 5, float: "left"}}>{this.localizationService.strings.PlayAdmin_ErrorPopupHeader}</p>
              <p style={{ color: "red", marginTop: 0, float: "left"}}><i>{this.state.integrationError}</i></p>
            </>
          }
          <IANumberField
            label={this.localizationService.strings.PlayAdmin_AmountOfWorkplaceFeedItemsToShow?.toUpperCase()}
            number={this.props.store.widgetToEdit?.facebookPageViewer.amountToShow}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            min={1}
            max={50}
            onChange={(amountToShow: number) => {
              this.props.store.widgetToEdit.facebookPageViewer.amountToShow = amountToShow;
              this.fetchContent();
              this.props.forceUpdate();
            }}
            styles={{
              width: "100%",
              clear: "both"
            }}
          />
          <MediaFileSelector
            label={this.localizationService.strings.PlayAdmin_NoContentImageUrl?.toUpperCase()}
            url={this.props.store.widgetToEdit?.facebookPageViewer.noContentImageUrl ? this.props.store.widgetToEdit?.facebookPageViewer.noContentImageUrl : this.localizationService.strings.Player_NoContentUrl}
            type={"image"}
            onChange={(noContentImageUrl: string) => {
              this.props.store.widgetToEdit.facebookPageViewer.noContentImageUrl = noContentImageUrl;
              this.props.forceUpdate();
              this.props.forceUpdate();
            }}
          />
          <MediaFileSelector
            label={this.localizationService.strings.PlayAdmin_HeaderImage?.toUpperCase()}
            url={this.props.store.widgetToEdit.headerImage ? this.props.store.widgetToEdit.headerImage : undefined}
            type={"image"}
            onChange={(headerImage: string) => {
              this.props.store.widgetToEdit.headerImage = headerImage;
              this.props.reloadPreview();
            }}
          />
         
        </IAGroup>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_ArticleLayoutType.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <label
            style={{
              color: this.props.store.darkMode ? Helper.darkModeLabelColor : '#333333',
              marginTop: 20
            }}
          >
            {this.localizationService.strings.PlayAdmin_ColorThemes?.toUpperCase()}
          </label>
          {colorThemes}
          <IAButton
            label={this.localizationService.strings.PlayAdmin_AddColorTheme?.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onClick={() => {
              if (!this.props.store.widgetToEdit?.facebookPageViewer?.colorThemes) {
                this.props.store.widgetToEdit.facebookPageViewer.colorThemes = [];
              }
              this.props.store.widgetToEdit?.facebookPageViewer.colorThemes.push(
                {
                  textColor: "#333333",
                  backgroundColor: "#ffffff"
                }
              );
              this.props.forceUpdate();
            }}
            showSpinner={this.props.store.workingOnIt}
            borderRadius={5}
            style={{
              float: "left",
              position: "relative",
              clear: "both",
              marginBottom: 20,
              marginTop: 10
            }}
          />
          <RotatorViewSelector
            layoutType={"slide"}
            boxStyle={this.props.store.widgetToEdit?.facebookPageViewer?.boxStyle}
            backgroundColor={this.props.store.widgetToEdit?.facebookPageViewer?.colorThemes[0].backgroundColor}
            textColor={this.props.store.widgetToEdit?.facebookPageViewer?.colorThemes[0].textColor}
            image={`https://intraactivestorage.blob.core.windows.net/cdn/play/no-image.webp`}
            contentPosition={this.props.store.widgetToEdit?.facebookPageViewer.contentPosition}
            keepOriginalImage={this.props.store.widgetToEdit?.facebookPageViewer.keepOriginalImage}
            boxDisabled
            updateLayout={() => { }}
            updateBoxStyle={(boxStyle: IPlayBoxStyle) => {
              this.props.store.widgetToEdit.facebookPageViewer.boxStyle = boxStyle;
              this.props.reloadPreview();
            }}
            updateContentPosition={(contentPosition: "left" | "right" | "random") => {
              this.props.store.widgetToEdit.facebookPageViewer.contentPosition = contentPosition;
              this.props.reloadPreview();
            }}
            updateKeepOriginalImage={(keepOriginalImage: boolean) => {
              this.props.store.widgetToEdit.facebookPageViewer.keepOriginalImage = keepOriginalImage;
              this.props.reloadPreview();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            label={this.localizationService.strings.PlayAdmin_MessagesViewerShowQR?.toUpperCase()}
            checked={this.props.store.widgetToEdit?.facebookPageViewer && this.props.store.widgetToEdit?.facebookPageViewer.showQRLink != undefined ? this.props.store.widgetToEdit?.facebookPageViewer.showQRLink : true}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onChange={() => {
              this.props.store.widgetToEdit.facebookPageViewer.showQRLink = this.props.store.widgetToEdit?.facebookPageViewer.showQRLink != undefined ? !this.props.store.widgetToEdit?.facebookPageViewer.showQRLink : false;
              this.props.reloadPreview();
            }}
          />
          {this.props.store.widgetToEdit?.facebookPageViewer.showQRLink &&
            <>
              <div className={styles.IA_info}>{this.localizationService.strings.PlayAdmin_QRcodeOnMessagesTooltipPart1} <a href="https://docs.intraactive.net/?doc=/intraactive-intranet/messages-manage-messages/#section-header-two-a2dhj" target="_blank">{this.localizationService.strings.PlayAdmin_QRcodeOnMessagesTooltipPart2}</a> {this.localizationService.strings.PlayAdmin_QRcodeOnMessagesTooltipPart3}</div>
              <IATextField
                placeholder={this.localizationService.strings.PlayAdmin_LinkText}
                text={this.props.store.widgetToEdit.facebookPageViewer.linkText}
                darkMode={this.props.store.darkMode}
                highlightColor={this.props.store.highlightColor}
                borderRadius={5}
                label={this.localizationService.strings.PlayAdmin_QRText?.toUpperCase()}
                style={{
                  marginTop: 10
                }}
                labelStyle={{
                  fontSize: "12px"
                }}
                inputFieldStyle={{
                  marginBottom: 10
                }}
                onChange={(text: string) => {
                  this.props.store.widgetToEdit.facebookPageViewer.linkText = text;
                  this.props.forceUpdate();
                }}
              />
            </>
          }
          <IAToggle
            style={{
              marginTop: 20
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            label={this.localizationService.strings.PlayAdmin_MessagesViewerShowTitle?.toUpperCase()}
            checked={this.props.store.widgetToEdit?.facebookPageViewer && this.props.store.widgetToEdit?.facebookPageViewer.showTitle != undefined ? this.props.store.widgetToEdit?.facebookPageViewer.showTitle : true}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onChange={() => {
              this.props.store.widgetToEdit.facebookPageViewer.showTitle = this.props.store.widgetToEdit?.facebookPageViewer.showTitle != undefined ? !this.props.store.widgetToEdit?.facebookPageViewer.showTitle : false;
              this.props.reloadPreview();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            label={this.localizationService.strings.PlayAdmin_MessagesViewerShowText?.toUpperCase()}
            checked={this.props.store.widgetToEdit?.facebookPageViewer && this.props.store.widgetToEdit?.facebookPageViewer.showText != undefined ? this.props.store.widgetToEdit?.facebookPageViewer.showText : true}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onChange={() => {
              this.props.store.widgetToEdit.facebookPageViewer.showText = this.props.store.widgetToEdit?.facebookPageViewer.showText != undefined ? !this.props.store.widgetToEdit?.facebookPageViewer.showText : false;
              this.props.reloadPreview();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            label={this.localizationService.strings.PlayAdmin_MessagesViewerEnableScroll?.toUpperCase()}
            checked={this.props.store.widgetToEdit?.facebookPageViewer && this.props.store.widgetToEdit?.facebookPageViewer.enableScroll != undefined ? this.props.store.widgetToEdit?.facebookPageViewer.enableScroll : false}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onChange={() => {
              this.props.store.widgetToEdit.facebookPageViewer.enableScroll = this.props.store.widgetToEdit?.facebookPageViewer.enableScroll != undefined ? !this.props.store.widgetToEdit?.facebookPageViewer.enableScroll : true;
              this.props.reloadPreview();
            }}
          />
          {this.props.store.widgetToEdit?.facebookPageViewer?.boxStyle !== "postcard" &&
            <IAToggle
              style={{
                marginTop: 20
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_ShowProgressBar?.toUpperCase()}
              checked={this.props.store.widgetToEdit.showProgressBar != undefined ? this.props.store.widgetToEdit.showProgressBar : true}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.props.store.widgetToEdit.showProgressBar = this.props.store.widgetToEdit.showProgressBar != undefined ? !this.props.store.widgetToEdit.showProgressBar : false;
                this.props.reloadPreview();
              }}
            />
          }
          <IAToggle
            style={{
              marginTop: 20
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            label={this.localizationService.strings.PlayAdmin_MessagesViewerShowAuthor?.toUpperCase()}
            checked={this.props.store.widgetToEdit?.facebookPageViewer && this.props.store.widgetToEdit?.facebookPageViewer.showAuthor != undefined ? this.props.store.widgetToEdit?.facebookPageViewer.showAuthor : true}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onChange={() => {
              this.props.store.widgetToEdit.facebookPageViewer.showAuthor = this.props.store.widgetToEdit?.facebookPageViewer.showAuthor != undefined ? !this.props.store.widgetToEdit?.facebookPageViewer.showAuthor : false;
              this.props.reloadPreview();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            label={this.localizationService.strings.PlayAdmin_MessagesViewerShowDate?.toUpperCase()}
            checked={this.props.store.widgetToEdit?.facebookPageViewer && this.props.store.widgetToEdit?.facebookPageViewer.showTime != undefined ? this.props.store.widgetToEdit?.facebookPageViewer.showTime : true}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onChange={() => {
              this.props.store.widgetToEdit.facebookPageViewer.showTime = this.props.store.widgetToEdit?.facebookPageViewer.showTime != undefined ? !this.props.store.widgetToEdit?.facebookPageViewer.showTime : false;
              this.props.reloadPreview();
            }}
          />
        </IAGroup>
      </>
    );
  }


}
