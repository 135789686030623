import * as React from 'react';
import Moment from 'react-moment';
import { Helper } from '../../../Helper';
import { IListElement, IListElementButton, IListElementColumn } from '../../../interfaces/IPlay';
import { IAButton } from '../../button/Button';
import { IAIcon } from '../../icon/Icon';
import { IATooltip } from '../../tooltip/Tooltip';
import * as styles from './ListElement.css';

export interface IProps {
  listViewId: string;
  listElement: IListElement;
  backgroundColor: string;
  hoverBackgroundColor: string;
  buttonAreaDividerColor: string;
  defaultAction: string;
  darkMode: boolean;
  selectedAction: (action: string, id: string, object?: any) => void;
  rowHeight?: string;
  userLocation?: string;
}

export interface IState {
}

export class ListElement extends React.Component<IProps, IState> {

  private readonly randomId = Helper.getRandomStringKey();

  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }

  public render(): JSX.Element {
    const columns: JSX.Element[] = [];
    const buttons: JSX.Element[] = [];
    if (this.props.listElement && this.props.listElement.buttons && this.props.listElement.buttons.length > 0) {
      this.props.listElement.buttons.forEach((button: IListElementButton, index: number) => {
        if (button.text) {
          buttons.push(
            <IAButton
              label={button.text}
              buttonColor={button.backgroundColor}
              textColor={button.color}
              icon={button.icon}
              iconUrl={button.iconUrl}
              height={30}
              iconColor={button.color}
              fontSize={12}
              borderRadius={5}
              onClick={() => this.props.selectedAction(button.action, this.props.listElement.id, this.props.listElement.object)}
              style={{
                float: "left",
                marginLeft: 10,
                marginTop: 10
              }}
            />
          );
        } else {
          buttons.push(
            <IAIcon
              key={`listButton_${index}`}
              title={button.icon}
              url={button.iconUrl}
              size={20}
              color={button.color}
              style={{
                float: "left",
                marginTop: 16,
                marginLeft: 10,
                opacity: button.opacity
              }}
              svgStyle={button.svgStyles}
              onClick={() => this.props.selectedAction(button.action, this.props.listElement.id, this.props.listElement.object)}
            />
          );
        }
      });
    }

    if (this.props.listElement && this.props.listElement.columns && this.props.listElement.columns.length > 0) {
      this.props.listElement.columns.forEach((column: IListElementColumn, index: number) => {
        switch (column.type) {
          case "tag":
            columns.push(
              <div
                className={styles.IA_column}
                key={`tag_${index}`}
                onClick={() => this.props.selectedAction(this.props.defaultAction, this.props.listElement.id, this.props.listElement.object)}
              >
                <div
                  className={styles.IA_label}
                  style={{
                    color: column.color
                  }}
                >
                  {column.icon &&
                    <div className={styles.IA_labelIcon}>
                      <IAIcon
                        title={column.icon}
                        url={column.iconUrl}
                        size={18}
                        color={column.color}
                        style={{
                          float: "left",
                          marginTop: 6
                        }}
                      />
                    </div>
                  }
                  {column.src &&
                    <img
                      style={{
                        borderRadius: 5,
                        margin: "4px 4px 4px 4px",
                        width: 22,
                        height: 22,
                        float: "left",
                        position: "relative"
                      }}
                      src={column.src}
                    />
                  }
                  <div
                    className={styles.IA_labelText}
                    style={{
                      backgroundColor: column.backgroundColor,
                      marginLeft: column.icon || column.src ? -30 : 5,
                      paddingLeft: column.icon || column.src ? 40 : 10,
                      paddingRight: 10
                    }}
                  >
                    {column.text}
                  </div>
                </div>
              </div>
            )
            break;
          case "icon":
            columns.push(
              <div
                className={styles.IA_iconColumn}
                key={`icon_${index}`}
                onClick={() => this.props.selectedAction(this.props.defaultAction, this.props.listElement.id, this.props.listElement.object)}
              >
                {column.tooltip ?
                  <IATooltip
                    content="Tooltip"
                    type={"bubble"}
                    darkMode={this.props.darkMode}
                    styles={{
                      width: 90,
                      textAlign: "center",
                      marginBottom: "5px"
                    }}
                    componentContent={
                      <div>
                        {column.tooltip}
                      </div>
                    }>
                    <IAIcon
                      title={column.icon}
                      url={column.iconUrl}
                      size={column.iconSize ? column.iconSize : 28}
                      color={column.color}
                      style={{
                        float: "left",
                        marginTop: column.marginTop ? column.marginTop : 10
                      }}
                    />
                  </IATooltip>
                  :
                  <IAIcon
                    title={column.icon}
                    url={column.iconUrl}
                    size={column.iconSize ? column.iconSize : 28}
                    color={column.color}
                    style={{
                      float: "left",
                      marginTop: column.marginTop ? column.marginTop : 10
                    }}
                  />
                }
              </div>
            )
            break;
          case "text":
            columns.push(
              <div
                className={styles.IA_column}
                key={`text_${index}`}
                style={column.style}
                onClick={() => this.props.selectedAction(this.props.defaultAction, this.props.listElement.id, this.props.listElement.object)}
              >
                <div
                  className={styles.IA_text}
                  style={{
                    color: column.color
                  }}
                >
                  {column.text}
                </div>
              </div>
            )
            break;
          case "date":
            columns.push(
              <div
                className={styles.IA_dateColumn} key={`date_${index}`}
                onClick={() => this.props.selectedAction(this.props.defaultAction, this.props.listElement.id, this.props.listElement.object)}
              >
                <div
                  className={styles.IA_date}
                  style={{
                    color: column.color
                  }}
                >
                  <Moment
                    format="L"
                    locale={this.props.userLocation ?? navigator.language}
                  >
                    {column.date}
                  </Moment>
                </div>
              </div>
            )
            break;
          case "preview":
            columns.push(
              <div
                className={styles.IA_previewColumn}
                key={`date_${index}`}
                onClick={() => this.props.selectedAction(this.props.defaultAction, this.props.listElement.id, this.props.listElement.object)}
              >
                <div
                  className={styles.IA_filePreview}
                  style={{
                    color: column.color,
                    backgroundColor: this.props.darkMode ? Helper.darkModeInputFieldBackgroundColor : '#fafafa',
                  }}
                >
                  {
                    (column.fileType === 'image/jpeg' || column.fileType === 'image/gif' || column.fileType === 'image/png' || column.fileType === 'image/webp') &&
                    <img src={column.src} />
                  }
                  {(column.fileType === 'video/mp4') &&
                    <video src={column.src} />
                  }
                  {column.fileType === 'application/pdf' &&
                    <img src={"https://img.icons8.com/color/96/000000/pdf.png"} />
                  }
                </div>
              </div>
            )
            break;
          case "person":
            columns.push(
              <div
                className={styles.IA_column}
                key={`person${index}`}
                style={column.style}
              >
                Profile image
              </div>
            )
          case "customElement":
            columns.push(
              <div
                className={styles.IA_column}
                key={`customElement_${index}`}
                style={column.style}
                onClick={() => this.props.selectedAction(this.props.defaultAction, this.props.listElement.id, this.props.listElement.object)}
              >
                {column.element}
              </div>
            )
            break;
          default:
            break;
        }
      });
    }
    return (
      <div
        key={`row_${this.props.listElement.id}${this.randomId}`}
        className={styles.IA_listElement}
        style={{
          backgroundColor: this.props.backgroundColor,
          color: this.props.darkMode ? "#ffffff" : "#333333",
          height: this.props.rowHeight ? this.props.rowHeight : ''
        }}
        id={`listItem_${this.props.listElement.id}${this.randomId}`}
        onMouseOver={() => {
          const listElement = document.getElementById(`listItem_${this.props.listElement.id}${this.randomId}`);
          if (listElement) {
            listElement.style.backgroundColor = this.props.hoverBackgroundColor;
          }
        }}
        onMouseOut={() => {
          const listElement = document.getElementById(`listItem_${this.props.listElement.id}${this.randomId}`);
          if (listElement) {
            listElement.style.backgroundColor = this.props.backgroundColor;
          }
        }}
      >
        {columns}
        {buttons?.length > 0 &&
          <div
            className={styles.IA_buttons}
            id={`IA_ListElementButtons_${this.props.listViewId}`}
          >
            <div
              className={styles.IA_buttonsdDivider}
              style={{
                background: this.props.buttonAreaDividerColor
              }}
            />
            {buttons}
          </div>
        }
      </div>
    );
  }

  
}