import * as React from 'react';
import * as styles from "./IAAuthors.css";
import { IUser } from '../../../interfaces/IUser';
import { MSPersonCard } from '../../msPersonCard/MSPersonCard';
import { Helper } from '../../../Helper';

export interface IAuthorsProps {
  authors: IUser[];
  highlightColor: string;
  style?: React.CSSProperties;
}
export default class IAAuthors extends React.Component<IAuthorsProps> {

  constructor(props: IAuthorsProps) {
    super(props);
  }

  render() {
    return (
      <div
        className={styles.IA_authors}
        style={
          this.props.style
        }
      >
        {this.props.authors && this.props.authors.length > 0 && this.props.authors.map(author => {
          const {firstNameChar, lastNameChar} = Helper.getFirstNameAndLastNameChars(author.displayName);
          return <div className={styles.IA_authorWrapper} key={author.userPrincipalName}>
            <MSPersonCard
              userLoginName={author.userPrincipalName}
              userProfileImage={author.image}
              highlightColor={this.props.highlightColor}
            >
              <div className={styles.IA_authorBackground}>
                <div 
                  className={styles.IA_authorsImageBackground}
                >
                  <div
                    className={styles.IA_authorsImageText}
                    style={{
                      backgroundColor: this.props.highlightColor ?? "#333333",
                      color: Helper.getTextColorBasedOnBackgroundColorDarkness(this.props.highlightColor ?? "#333333", "#ffffff", "#333333"),
                    }}
                  >
                    {firstNameChar + lastNameChar}
                  </div>
                  {author.image && 
                    <div
                      className={styles.IA_authorsImage}
                      style={{
                        backgroundImage: `url('${author.image}')`
                      }}
                    />
                  }
                </div>
                <div className={styles.IA_authorsDisplayName}>
                  {author.displayName}
                </div>
              </div>
            </MSPersonCard>
          </div>;
        })}
      </div>
    );
  }
}