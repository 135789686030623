import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from '../WidgetEditPanel.css';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IANumberField } from '../../../../../numberField/NumberField';
import { IAToggle } from '../../../../../toggle/Toggle';
import PlayService from '../../../../../../services/PlayService';
import { IFeed } from '../../../../../../interfaces/IFeed';
import { IAButton } from '../../../../../button/Button';
import { Helper } from '../../../../../../Helper';
import { MediaFileSelector } from '../../../mediaFiles/mediaFileSelector/MediaFileSelector';
import { IAColorTheme } from '../../../../../colorTheme/ColorTheme';
import { IATextField } from '../../../../../textField/TextField';
import { IAIcon } from '../../../../../icon/Icon';
import { IAGroup } from '../../../../../group/Group';
import { RotatorViewSelector } from '../../../rotatorViewSelector/RotatorViewSelector';
import { IPlayBoxStyle } from '../../../../../../interfaces/IPlay';

export interface IProps {
  store?: Store;
  forceUpdate: () => void;
  reloadPreview: () => void;
  loadingContent: (loading: boolean) => void;
}

export interface IState {
  feeds?: IFeed[];
  colorThemeForPreview?: any;
  groupToAdd?: string;
}

@inject('store')
@observer
export class WorkplaceWidgetSettings extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      feeds: [],
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentDidMount(): void {
    this.fetchContent();
  }

  private fetchContent(): void {
    this.props.loadingContent(true);
    PlayService.getWorkplaceFeed(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id, this.props.store.widgetToEdit.workplaceFeedViewer.groups).then((feed) => {
      this.props.store.widgetToEdit.workplaceFeedViewer.feed = feed;
      this.props.loadingContent(false);
      this.props.forceUpdate();
      setTimeout(() => {
        this.props.forceUpdate();
      }, 500);
    });
  }

  render(): React.ReactNode {
    const colorThemes = [];
    if (this.props.store.widgetToEdit?.workplaceFeedViewer?.colorThemes?.length > 0) {
      this.props.store.widgetToEdit?.workplaceFeedViewer.colorThemes.forEach((colorTheme, index) => {
        colorThemes.push(
          <IAColorTheme
            key={"colorTheme_" + index}
            backgroundColor={colorTheme.backgroundColor}
            textColor={colorTheme.textColor}
            // disableTextColor={this.props.store.widgetToEdit?.workplaceFeedViewer != undefined}
            highlightColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            swatches={this.props.store.profile?.swatches}
            inTeams
            remove={() => {
              this.props.store.widgetToEdit?.workplaceFeedViewer.colorThemes.splice(index, 1);
              this.props.forceUpdate();
            }}
            allowAddingToSwatches={false}
            setBackgroundColor={(backgroundColor) => {
              colorTheme.backgroundColor = backgroundColor;
              this.props.forceUpdate();
            }}
            setTextColor={(textColor) => {
              colorTheme.textColor = textColor;
              this.props.forceUpdate();
            }}
          />
        )
      });
    }
    const groups: JSX.Element[] = [];
    if (this.props.store.widgetToEdit.workplaceFeedViewer?.groups?.length > 0) {
      this.props.store.widgetToEdit.workplaceFeedViewer.groups.forEach((group: string, index) => {
        groups.push(
          <div className={styles.IA_group}>
            <div className={styles.IA_groupId}>
              {group.toUpperCase()}
            </div>
            <IAIcon
              url='https://intraactivestorage.blob.core.windows.net/cdn/icons/Delete/SVG/ic_fluent_delete_48_regular.svg'
              size={22}
              color={"#333333"}
              style={{
                float: "left",
                marginLeft: 10,
                marginTop: 2
              }}
              onClick={() => {
                this.props.store.widgetToEdit.workplaceFeedViewer.groups.splice(index, 1);
                this.forceUpdate()
                this.fetchContent();
              }}
            />
          </div>
        );
      });
    }
    return (
      <>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_WidgetSettings?.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <IATextField
            label={this.localizationService.strings.PlayAdmin_Title?.toUpperCase()}
            required
            placeholder={this.localizationService.strings.PlayAdmin_Title}
            text={this.props.store.widgetToEdit.title}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(title: string) => {
              this.props.store.widgetToEdit.title = title;
              this.props.forceUpdate();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20,
              fontSize: 12
            }}
            darkMode={this.props.store.darkMode}
            label={this.localizationService.strings.PlayAdmin_ShowWidgetTitle?.toUpperCase()}
            checked={this.props.store.widgetToEdit.showTitle}
            color={this.props.store.highlightColor}
            onChange={() => {
              this.props.store.widgetToEdit.showTitle = this.props.store.widgetToEdit.showTitle != undefined ? !this.props.store.widgetToEdit.showTitle : true;
              this.props.forceUpdate();
            }}
          />
          <IANumberField
            label={this.localizationService.strings.PlayAdmin_MinimumDuration?.toUpperCase()}
            number={this.props.store.widgetToEdit?.workplaceFeedViewer.duration}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(duration: number) => {
              this.props.store.widgetToEdit.workplaceFeedViewer.duration = duration;
              this.fetchContent();
              this.props.forceUpdate();
            }}
            styles={{
              width: "100%",
              clear: "both"
            }}
          />
          <IATextField
            label={"GROUPS"}
            required
            placeholder={"Add group..."}
            text={this.state.groupToAdd}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px",
              width: 320
            }}
            style={{
              width: "calc(100% - 80px)",
              float: "left"
            }}
            onChange={(group: string) => {
              this.setState({ groupToAdd: group });
            }}
          />
          <IAIcon
            url='https://intraactivestorage.blob.core.windows.net/cdn/icons/Add/SVG/ic_fluent_add_28_regular.svg'
            size={25}
            color={this.props.store.highlightColor}
            style={{
              float: "left",
              marginTop: 43,
              marginLeft: 10,
              opacity: this.state.groupToAdd?.length > 0 ? 1 : 0.5
            }}
            onClick={() => {
              if (this.state.groupToAdd?.length > 0) {
                if (this.props.store.widgetToEdit.workplaceFeedViewer.groups == undefined) {
                  this.props.store.widgetToEdit.workplaceFeedViewer.groups = [];
                }
                this.props.store.widgetToEdit.workplaceFeedViewer.groups.push(this.state.groupToAdd);
                this.forceUpdate()
                this.fetchContent();
                this.setState({ groupToAdd: undefined });
              }
            }}
          />
          <div style={{ clear: "both", marginBottom: 10, float: "left" }}>
            {groups}
          </div>

          <IANumberField
            label={this.localizationService.strings.PlayAdmin_AmountOfWorkplaceFeedItemsToShow?.toUpperCase()}
            number={this.props.store.widgetToEdit?.workplaceFeedViewer.amountToShow}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            min={1}
            max={50}
            onChange={(amountToShow: number) => {
              this.props.store.widgetToEdit.workplaceFeedViewer.amountToShow = amountToShow;
              this.fetchContent();
              this.props.forceUpdate();
            }}
            styles={{
              width: "100%",
              clear: "both"
            }}
          />
          <MediaFileSelector
            label={this.localizationService.strings.PlayAdmin_NoContentImageUrl?.toUpperCase()}
            url={this.props.store.widgetToEdit?.workplaceFeedViewer.noContentImageUrl ? this.props.store.widgetToEdit?.workplaceFeedViewer.noContentImageUrl : this.localizationService.strings.Player_NoContentUrl}
            type={"image"}
            onChange={(noContentImageUrl: string) => {
              this.props.store.widgetToEdit.workplaceFeedViewer.noContentImageUrl = noContentImageUrl;
              this.props.forceUpdate();
              this.props.forceUpdate();
            }}
          />
          <MediaFileSelector
            label={this.localizationService.strings.PlayAdmin_HeaderImage?.toUpperCase()}
            url={this.props.store.widgetToEdit.headerImage ? this.props.store.widgetToEdit.headerImage : undefined}
            type={"image"}
            onChange={(headerImage: string) => {
              this.props.store.widgetToEdit.headerImage = headerImage;
              this.props.reloadPreview();
            }}
          />
          {/* <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
          <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : '#333333' }}>{"Header"}</h3>
          
          <IATextField
            text={this.props.store.widgetToEdit.headerTitle}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            label={"Header title"}
            style={{
              marginTop: 10
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            inputFieldStyle={{
              marginBottom: 10
            }}
            onChange={(headerTitle: string) => {
              this.props.store.widgetToEdit.headerTitle = headerTitle;
              this.props.forceUpdate();
            }}
          />
          <IATextField
            text={this.props.store.widgetToEdit.headerSubtitle}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            label={"Header subtitle"}
            style={{
              marginTop: 10
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            inputFieldStyle={{
              marginBottom: 10
            }}
            onChange={(headerSubtitle: string) => {
              this.props.store.widgetToEdit.headerSubtitle = headerSubtitle;
              this.props.forceUpdate();
            }}
          />

          <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} /> */}

        </IAGroup>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_ArticleLayoutType.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <label
            style={{
              color: this.props.store.darkMode ? Helper.darkModeLabelColor : '#333333',
              marginTop: 20
            }}
          >
            {this.localizationService.strings.PlayAdmin_ColorThemes?.toUpperCase()}
          </label>
          {colorThemes}
          <IAButton
            label={this.localizationService.strings.PlayAdmin_AddColorTheme?.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onClick={() => {
              if (!this.props.store.widgetToEdit?.workplaceFeedViewer?.colorThemes) {
                this.props.store.widgetToEdit.workplaceFeedViewer.colorThemes = [];
              }
              this.props.store.widgetToEdit?.workplaceFeedViewer.colorThemes.push(
                {
                  textColor: "#333333",
                  backgroundColor: "#ffffff"
                }
              );
              this.props.forceUpdate();
            }}
            showSpinner={this.props.store.workingOnIt}
            borderRadius={5}
            style={{
              float: "left",
              position: "relative",
              clear: "both",
              marginBottom: 20,
              marginTop: 10
            }}
          />
          <RotatorViewSelector
            layoutType={"slide"}
            boxStyle={this.props.store.widgetToEdit?.workplaceFeedViewer?.boxStyle}
            backgroundColor={this.props.store.widgetToEdit?.workplaceFeedViewer?.colorThemes[0].backgroundColor}
            textColor={this.props.store.widgetToEdit?.workplaceFeedViewer?.colorThemes[0].textColor}
            image={`https://intraactivestorage.blob.core.windows.net/cdn/play/no-image.webp`}
            contentPosition={this.props.store.widgetToEdit?.workplaceFeedViewer.contentPosition}
            keepOriginalImage={this.props.store.widgetToEdit?.workplaceFeedViewer.keepOriginalImage}
            boxDisabled
            updateLayout={() => { }}
            updateBoxStyle={(boxStyle: IPlayBoxStyle) => {
              this.props.store.widgetToEdit.workplaceFeedViewer.boxStyle = boxStyle;
              this.props.reloadPreview();
            }}
            updateContentPosition={(contentPosition: "left" | "right" | "random") => {
              this.props.store.widgetToEdit.workplaceFeedViewer.contentPosition = contentPosition;
              this.props.reloadPreview();
            }}
            updateKeepOriginalImage={(keepOriginalImage: boolean) => {
              this.props.store.widgetToEdit.workplaceFeedViewer.keepOriginalImage = keepOriginalImage;
              this.props.reloadPreview();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            label={this.localizationService.strings.PlayAdmin_MessagesViewerShowQR?.toUpperCase()}
            checked={this.props.store.widgetToEdit?.workplaceFeedViewer && this.props.store.widgetToEdit?.workplaceFeedViewer.showQRLink != undefined ? this.props.store.widgetToEdit?.workplaceFeedViewer.showQRLink : true}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onChange={() => {
              this.props.store.widgetToEdit.workplaceFeedViewer.showQRLink = this.props.store.widgetToEdit?.workplaceFeedViewer.showQRLink != undefined ? !this.props.store.widgetToEdit?.workplaceFeedViewer.showQRLink : false;
              this.props.reloadPreview();
            }}
          />
          {this.props.store.widgetToEdit?.workplaceFeedViewer.showQRLink &&
            <>
              <div className={styles.IA_info}>{this.localizationService.strings.PlayAdmin_QRcodeOnMessagesTooltipPart1} <a href="https://docs.intraactive.net/?doc=/intraactive-intranet/messages-manage-messages/#section-header-two-a2dhj" target="_blank">{this.localizationService.strings.PlayAdmin_QRcodeOnMessagesTooltipPart2}</a> {this.localizationService.strings.PlayAdmin_QRcodeOnMessagesTooltipPart3}</div>
              <IATextField
                placeholder={this.localizationService.strings.PlayAdmin_LinkText}
                text={this.props.store.widgetToEdit.workplaceFeedViewer.linkText}
                darkMode={this.props.store.darkMode}
                highlightColor={this.props.store.highlightColor}
                borderRadius={5}
                label={this.localizationService.strings.PlayAdmin_QRText?.toUpperCase()}
                style={{
                  marginTop: 10
                }}
                labelStyle={{
                  fontSize: "12px"
                }}
                inputFieldStyle={{
                  marginBottom: 10
                }}
                onChange={(text: string) => {
                  this.props.store.widgetToEdit.workplaceFeedViewer.linkText = text;
                  this.props.forceUpdate();
                }}
              />
            </>
          }
          <IAToggle
            style={{
              marginTop: 20
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            label={this.localizationService.strings.PlayAdmin_MessagesViewerShowTitle?.toUpperCase()}
            checked={this.props.store.widgetToEdit?.workplaceFeedViewer && this.props.store.widgetToEdit?.workplaceFeedViewer.showTitle != undefined ? this.props.store.widgetToEdit?.workplaceFeedViewer.showTitle : true}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onChange={() => {
              this.props.store.widgetToEdit.workplaceFeedViewer.showTitle = this.props.store.widgetToEdit?.workplaceFeedViewer.showTitle != undefined ? !this.props.store.widgetToEdit?.workplaceFeedViewer.showTitle : false;
              this.props.reloadPreview();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            label={this.localizationService.strings.PlayAdmin_MessagesViewerShowText?.toUpperCase()}
            checked={this.props.store.widgetToEdit?.workplaceFeedViewer && this.props.store.widgetToEdit?.workplaceFeedViewer.showText != undefined ? this.props.store.widgetToEdit?.workplaceFeedViewer.showText : true}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onChange={() => {
              this.props.store.widgetToEdit.workplaceFeedViewer.showText = this.props.store.widgetToEdit?.workplaceFeedViewer.showText != undefined ? !this.props.store.widgetToEdit?.workplaceFeedViewer.showText : false;
              this.props.reloadPreview();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            label={this.localizationService.strings.PlayAdmin_MessagesViewerEnableScroll?.toUpperCase()}
            checked={this.props.store.widgetToEdit?.workplaceFeedViewer && this.props.store.widgetToEdit?.workplaceFeedViewer.enableScroll != undefined ? this.props.store.widgetToEdit?.workplaceFeedViewer.enableScroll : false}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onChange={() => {
              this.props.store.widgetToEdit.workplaceFeedViewer.enableScroll = this.props.store.widgetToEdit?.workplaceFeedViewer.enableScroll != undefined ? !this.props.store.widgetToEdit?.workplaceFeedViewer.enableScroll : true;
              this.props.reloadPreview();
            }}
          />
          {this.props.store.widgetToEdit?.workplaceFeedViewer?.boxStyle !== "postcard" &&
            <IAToggle
              style={{
                marginTop: 20
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_ShowProgressBar?.toUpperCase()}
              checked={this.props.store.widgetToEdit.showProgressBar != undefined ? this.props.store.widgetToEdit.showProgressBar : true}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.props.store.widgetToEdit.showProgressBar = this.props.store.widgetToEdit.showProgressBar != undefined ? !this.props.store.widgetToEdit.showProgressBar : false;
                this.props.reloadPreview();
              }}
            />
          }
          <IAToggle
            style={{
              marginTop: 20
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            label={this.localizationService.strings.PlayAdmin_MessagesViewerShowAuthor?.toUpperCase()}
            checked={this.props.store.widgetToEdit?.workplaceFeedViewer && this.props.store.widgetToEdit?.workplaceFeedViewer.showAuthor != undefined ? this.props.store.widgetToEdit?.workplaceFeedViewer.showAuthor : true}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onChange={() => {
              this.props.store.widgetToEdit.workplaceFeedViewer.showAuthor = this.props.store.widgetToEdit?.workplaceFeedViewer.showAuthor != undefined ? !this.props.store.widgetToEdit?.workplaceFeedViewer.showAuthor : false;
              this.props.reloadPreview();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            label={this.localizationService.strings.PlayAdmin_MessagesViewerShowDate?.toUpperCase()}
            checked={this.props.store.widgetToEdit?.workplaceFeedViewer && this.props.store.widgetToEdit?.workplaceFeedViewer.showTime != undefined ? this.props.store.widgetToEdit?.workplaceFeedViewer.showTime : true}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onChange={() => {
              this.props.store.widgetToEdit.workplaceFeedViewer.showTime = this.props.store.widgetToEdit?.workplaceFeedViewer.showTime != undefined ? !this.props.store.widgetToEdit?.workplaceFeedViewer.showTime : false;
              this.props.reloadPreview();
            }}
          />
        </IAGroup>
      </>
    );
  }


}
