import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import { IADropDown } from '../../../../../dropDown/DropDown';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IPlayProfile } from '../../../../../../interfaces/IPlay';
import PlayService from '../../../../../../services/PlayService';
import { IFeed } from '../../../../../../interfaces/IFeed';
import { Helper } from '../../../../../../Helper';
import { IntegrationFeedCheckbox } from './IntegrationFeedCheckbox';
import { IASpinner } from '../../../../../spinner/Spinner';
import { IAButton } from '../../../../../button/Button';
import * as styles from '../IntegrationsEditPanel.css';

export interface IProps {
  store?: Store;
  profiles: IPlayProfile[];
  isEditingExistingIntegration: boolean;
  isDeletingSharepointNewsItem: boolean;
  selectedProfileId: string;
  isConnected: boolean;
  onProfileChange: (profile) => void;
  onAddNewFeed: () => void;
  onConnect: () => void;
}

export interface IState {
  availableFeeds: IFeed[];
  isLoading: boolean;
}

@inject('store')
@observer
export class EventIntegrationComponent extends React.Component<IProps, IState> {
  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      availableFeeds: [],
      isLoading: true
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentDidMount(): void {
    PlayService.getIntegrations(this.props.store.environment, this.props.store.token, this.props.store.tenantId, "events")
      .then(availableFeeds => this.setState({ availableFeeds}))
      .finally(() => this.setState({ isLoading: false }));
  }

  public render(): JSX.Element {
    const hasFeeds = this.state.availableFeeds && this.state.availableFeeds.length > 0;
    return (
      <>
        {this.state.isLoading ?
          <IASpinner color={this.props.store.highlightColor}/>
        :
          <>
            {this.props.isConnected
            ? 
              <>
                <IADropDown
                  label={this.localizationService.strings.PlayAdmin_SelectAProfileToAccess?.toUpperCase()}
                  selectedOption={this.props.store.integrationToEdit?.profileId}
                  options={[{
                    key: undefined,
                    value: this.localizationService.strings.PlayAdmin_SelectAProfileToAccess,
                    disabled: true
                  },
                  ...this.props.profiles.map(profile => { return { key: profile.id, value: profile.title } })
                  ]}
                  disabled={this.props.isEditingExistingIntegration || this.state.isLoading}
                  darkMode={this.props.store.darkMode}
                  highlightColor={this.props.store.highlightColor}
                  onChange={(profile) => {
                    this.props.onProfileChange(profile)
                  }}
                  style={{
                    float: "left",
                    width: 340
                  }}
                  borderRadius={5}
                />
                {this.props.selectedProfileId && 
                  <div style={{
                    marginTop: 20,
                    float: "left",
                    width: "100%"
                  }}>
                    {hasFeeds ?
                      <>
                        <label>{this.localizationService.strings.PlayAdmin_SelectAFeed?.toUpperCase()}</label>
                        {this.state.availableFeeds.map(feed => {
                          const id = Helper.getRandomStringKey();
                          return <IntegrationFeedCheckbox
                            id={id}
                            feed={feed}
                            isDeletingSharepointNewsItem={this.props.isDeletingSharepointNewsItem}
                          />
                        })}
                      </>
                    :
                      <>{this.localizationService.strings.PlayAdmin_NoFeeds}</>
                    }
                  </div>
                }
                <IAButton
                  label={this.localizationService.strings.PlayAdmin_AddNewFeed?.toUpperCase()}
                  buttonColor={this.props.store.highlightColor}
                  disbaled={false}
                  darkMode={this.props.store.darkMode}
                  onClick={() => this.props.onAddNewFeed()}
                  borderRadius={5}
                  showSpinner={this.props.store.workingOnIt}
                  style={{ clear: "both", marginTop: 15 }}
                />
              </>
            :
              <div className={styles.IA_feeds}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <IAButton
                    label={this.localizationService.strings.PlayAdmin_Connect?.toUpperCase()}
                    buttonColor={this.props.store.highlightColor}
                    disbaled={false}
                    darkMode={this.props.store.darkMode}
                    onClick={() => this.props.onConnect()}
                    borderRadius={5}
                    showSpinner={this.props.store.workingOnIt}
                  />
                </div>
              </div>
            }
          </>
        }
      </>
    );
  }
}