import * as React from 'react';
import * as styles from './TableSettings.css';
import { inject, observer } from 'mobx-react';
import { Store } from '../stores';
import { LocalizationService } from '../../../services/LocalizationService';
import { IAOptionSelector } from '../../optionSelector/OptionSelector';

export interface IProps {
  store?: Store;
  onTableSettingsChange?: (showTableBorder: boolean) => void;
}

@inject('store')
@observer
export class IATableSettings extends React.Component<IProps> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public render(): JSX.Element {
    let toolTipContentElement = document.getElementById("IA_Box");
    let cursorPositionTop = this.props.store.cursorPositionTop;
    let cursorPositionLeft = this.props.store.cursorPositionLeft;
    if (toolTipContentElement) {
      if (this.props.store.cursorPositionTop + toolTipContentElement.clientHeight > window.innerHeight) {
        cursorPositionTop -= toolTipContentElement.clientHeight + 20;
      }
      if (this.props.store.cursorPositionLeft + toolTipContentElement.clientWidth > window.innerWidth) {
        cursorPositionLeft = window.innerWidth - (toolTipContentElement.clientWidth + 20);
      }
    } else {
      this.forceUpdate();
    }
    return (
      <div
        style={{
          position: "fixed",
          zIndex: 3000001,
          height: "100%",
          width: "100%",
          top: 0,
          left: 0
        }}
        onClick={() => this.props.store.showTableSettings = false}
      >
        <div
          id="IA_Box"
          className={styles.IA_box}
          style={{
            top: this.props.store.isMobile ? undefined : cursorPositionTop,
            bottom: this.props.store.isMobile ? 80 : undefined,
            left: this.props.store.isMobile ? "20px" : cursorPositionLeft,
            height: "auto",
            width: this.props.store.isMobile ? "calc(100% - 40px)" : 370,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <IAOptionSelector
            label={this.localizationService.strings.Editor_BorderStyle}
            compact
            borderRadius={5}
            labelStyle={{ fontSize: 14 }}
            options={[
              {
                image: " ",
                key: "none"
              },
              {
                image: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/border-style-solid.png",
                key: "solid"
              }
            ]}
            selected={this.props.store.showTableBorder == undefined || this.props.store.showTableBorder ? "solid" : "none"}
            highlightColor={this.props.store.highlightColor}
            onChange={(showTableBorder: string) => {
              if (showTableBorder === "none") {
                this.props.onTableSettingsChange(false);
              } else {
                this.props.onTableSettingsChange(true);
              }
            }}
            style={{ clear: "none", marginTop: 20 }}
          />
        </div>
      </div>
    );
  }
}