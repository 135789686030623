import * as React from 'react';
import * as styles from './Pivot.css';
import "../../styles/BasicIA.css";
import { IAIcon } from '../icon/Icon';
import { IATooltip } from '../tooltip/Tooltip';

export interface IIAPivotProps {
  items: { key: string, text: string, disabled?: boolean, icon?: string, error?: boolean, unselectedBackgroundColor?: string, tooltip?: string }[];
  highlightColor: string;
  textColor?: string;
  selectedKey: string;
  style?: React.CSSProperties;
  select: (key: string) => void;
  asTabs?: boolean;
  tabBackgrondColor?: string;
  selectedTabBackgrondColor?: string;
  showNumber?: boolean;
  showIcon?: boolean;
  isMobile?: boolean;
  autoTabWidth?: boolean;
  height?: number;
  darkmode?: boolean;
}

export class IAPivot extends React.Component<IIAPivotProps, {}> {

  constructor(props: IIAPivotProps) {
    super(props);
  }

  public render(): JSX.Element {
    let items = [];
    this.props.items.forEach((pivotItem: { key: string, text: string, disabled?: boolean, icon?: string, error?: boolean, unselectedBackgroundColor?: string, tooltip?: string }, index) => {
      if (this.props.asTabs) {
        const item = (
          <div
            tabIndex={0}
            key={pivotItem.key}
            className={[styles.IA_tabItem, "IA_OnFocus"].join(" ")}
            onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => {
              if (event.key === "Enter") {
                this.props.select(pivotItem.key);
              }
            }}
            onClick={() => {
              if (!pivotItem.disabled) {
                this.props.select(pivotItem.key);
              }
            }}
            style={{
              width: this.props.autoTabWidth ? "auto" : `${100 / this.props.items.length}%`,
              backgroundColor: this.props.selectedKey === pivotItem.key ? this.props.selectedTabBackgrondColor : (pivotItem.unselectedBackgroundColor ?? this.props.tabBackgrondColor),
              color: pivotItem.disabled ? "#999999" : "#000000",
              borderColor: this.props.selectedTabBackgrondColor,
              borderRight: this.props.autoTabWidth ? "none" : "1px solid #ffffff",
              height: this.props.height ? this.props.height : 40,
              lineHeight: this.props.height ? `${this.props.height}px` : "40px",
              padding: this.props.asTabs ? "0px 20px" : "0px 10px"
            }}
          >
            {this.props.showNumber &&
              <div
                className={styles.IA_tabItemNumber}
                style={{
                  backgroundColor: this.props.selectedKey === pivotItem.key ? this.props.highlightColor : "#ffffff",
                  color: this.props.selectedKey === pivotItem.key ? "#ffffff" : "#000000",
                }}
              >
                {index + 1}
              </div>
            }
            {this.props.showIcon &&
              <div
                className={styles.IA_tabItemIcon}
                style={{
                  marginLeft: this.props.autoTabWidth ? -5 : 0,
                  marginTop: this.props.autoTabWidth ? 6 : 12,
                  width: this.props.autoTabWidth ? 25 : 20
                }}
              >
                <IAIcon
                  title={pivotItem.icon}
                  size={18}
                  color={pivotItem.error ? "red" : this.props.selectedKey === pivotItem.key ? this.props.highlightColor : (this.props.textColor ? this.props.textColor : "#000000")}
                  style={{
                    float: "left"
                  }}
                />
              </div>
            }
            <div
              className={styles.IA_tabItemText}
              style={{
                color: pivotItem.error ? "red" : this.props.selectedKey === pivotItem.key ? this.props.highlightColor : (this.props.textColor ? this.props.textColor : "#000000"),
                marginLeft: this.props.autoTabWidth ? 0 : 10
              }}
            >
              {pivotItem.text}
            </div>
          </div>
        );
        if (pivotItem.tooltip) {
          items.push(
            <IATooltip
              content="Tooltip"
              type={"bubble"}
              styles={{
                width: 300,
                color: "#999999",
                textAlign: "center",
                marginBottom: 5,
                borderRadius: 5
              }}
              componentContent={
                <div>
                  {pivotItem.tooltip}
                </div>
              }>
              {item}
            </IATooltip>
          );
        } else {
          items.push(
            item
          );
        }
      } else {
        items.push(
          <div
            tabIndex={0}
            key={pivotItem.key}
            className={[styles.IA_pivotItem, "IA_OnFocus"].join(" ")}
            onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => {
              if (event.key === "Enter") {
                this.props.select(pivotItem.key);
              }
            }}
            onClick={() => {
              if (!pivotItem.disabled) {
                this.props.select(pivotItem.key);
              }
            }}
            style={{
              borderBottom: `2px solid ${this.props.selectedKey === pivotItem.key ? this.props.highlightColor : "transparent"}`,
              opacity: pivotItem.disabled ? 0.5 : 1,
              color: this.props.darkmode ? "#ffffff" : "#333333"
            }}
          >
            {pivotItem.text}
          </div>
        );
      }
    });
    return (
      <div
        className={styles.IA_pivot}
        style={this.props.style}
      >
        {items}
      </div>
    );
  }


}