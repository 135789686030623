import * as React from 'react';
import { LocalizationService } from '../../../../services/LocalizationService';
import * as styles from './PreviewSettings.css';
import { inject, observer } from 'mobx-react';
import { IPlaySlideLayout } from '../../../../interfaces/IPlay';
import { IAButton } from '../../../button/Button';
import { Helper } from '../../../../Helper';
import { IColorTheme } from '../../../../interfaces/IColorTheme';
import { Store } from '../../stores/Store';

export interface IProps {
  store?: Store;
  displayLayout: "oneColumn" | "twoColumns" | "rightSideTwoRows" | "leftAndRightSideTwoRows" | "portraitOneRow" | "portraitTwoRows";
  previewColorTheme?: IColorTheme;
  showFooter: boolean;
  showRssTicker: boolean;
  onLayoutChange: (layout: IPlaySlideLayout) => void;
  onColorThemeChange?: (colorTheme: IColorTheme) => void;
  onShowFooterChange: () => void;
  onShowRSSChange: () => void;
}

export interface IState {
}

@inject('store')
@observer
export class PreviewSettings extends React.Component<IProps, IState> {

  private localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = { };
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  componentDidMount(): void {
  }

  public render(): JSX.Element {
    const layoutOptions = ["oneColumn", "twoColumns", "rightSideTwoRows", "leftAndRightSideTwoRows", "portraitOneRow", "portraitTwoRows"] as IPlaySlideLayout[];
    const layoutOptionIcons = ["Layout Main One Column", "Layout Main Split Vertical", "Layout Main Three Column", "Layout Main Four Column", "Layout Portrait One Row", "Layout Portrait Two Rows"];
    return (
      <div className={styles.IA_previewSettings}>
        <div
          className={styles.IA_previewSetting}
          style={{ backgroundColor: this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : 'lightgrey' }}
        >
          {layoutOptions.map((layout, i) => {
            return <IAButton
              key={i}
              darkMode={this.props.store.darkMode}
              buttonColor={this.props.displayLayout === layout ? this.props.store.highlightColor : this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : 'white'}
              iconColor={this.props.displayLayout === layout ? "white" : this.props.store.darkMode ? 'white' : 'black'}
              icon={layoutOptionIcons[i]}
              onClick={() => this.props.onLayoutChange(layout)}
              style={{ borderRadius: 0 }}
            />;
          })}
        </div>
        {this.props.store.widgetToEdit?.type === "messageViewer" &&
          <div className={styles.IA_previewSetting}>
            {this.props.store.widgetToEdit.messageViewer.colorThemes.map((colorTheme: IColorTheme) => {
              return <div
                className={styles.IA_colorThemePreview}
                style={{
                  color: colorTheme.textColor,
                  backgroundColor: colorTheme.backgroundColor,
                  border: this.props.previewColorTheme === colorTheme ? (this.props.store.darkMode ? "1px solid #ffffff" : `1px solid ${this.props.store.highlightColor}`) : (this.props.store.darkMode ? `1px solid #323232` : `1px solid gray`)
                }}
                onClick={() => this.props.onColorThemeChange(colorTheme)}
              >
                Abc
              </div>;
            })}
          </div>
        }
        <IAButton
          darkMode={this.props.store.darkMode}
          buttonColor={this.props.showFooter ? this.props.store.highlightColor : this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : 'white'}
          textColor={this.props.showFooter ? "white" : this.props.store.darkMode ? 'white' : 'black'}
          label={this.localizationService.strings.PlayAdmin_ShowFooterInEditor}
          onClick={() => this.props.onShowFooterChange()}
          style={{ borderRadius: 5 }}
        />
        <IAButton
          darkMode={this.props.store.darkMode}
          buttonColor={this.props.showRssTicker ? this.props.store.highlightColor : this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : 'white'}
          textColor={this.props.showRssTicker ? "white" : this.props.store.darkMode ? 'white' : 'black'}
          label={this.localizationService.strings.PlayAdmin_ShowRssTickerInEditor}
          onClick={() => this.props.onShowRSSChange()}
          style={{ borderRadius: 5, marginLeft: 20 }}
        />
      </div>
    );
  }
}