
import * as React from 'react';
import * as styles from './LikesAndComments.css';
import { IComment } from '../../interfaces/IComment';
import { IAComment } from './comment/Comment';
import { IUser } from '../../interfaces/IUser';
import { IReply } from '../../interfaces/IReply';
import { IANewComment } from './newComment/NewComment';
import { ILike } from '../../interfaces/ILike';
import { IAItem } from '../../interfaces/IAItem';
import SocialService from '../../services/SocialService';
import Utils from './Utils';
import { IAIcon } from '../icon/Icon';
import { LocalizationService } from '../../services/LocalizationService';
import { Helper } from '../../Helper';
import { ENTER_KEY_CODE, SPACEBAR_KEY_CODE } from '../../global/Constants';
import { LoggingService } from '../../services/LoggingService';
import { IEnvironment } from '../../interfaces/IEnvironment';
import { IInstance } from '../../interfaces/IInstance';
const IC_SPINNER = require('../../images/spinner.png');

declare global {
  interface Window {
    social: any;
  }
}

export interface ILikesAndCommentsProps {
  user: IUser;
  userRole?: "Administrator" | "Editor" | "Reader";
  top?: number;
  itemId: string;
  item?: IAItem;
  webpartId?: string;
  tenant: string;
  component: string;
  instance: IInstance;
  token: string;
  environment?: IEnvironment;
  source: 'Home' | 'Mobile' | 'SharePoint';
  imageUploadUrl: string;
  likesEnabled: boolean;
  commentsEnabled: boolean;
  likesOnCommentsEnabled?: boolean;
  color?: string;
  style?: React.CSSProperties;
  padding: number;
  likes?: ILike[];
  commentCount?: number;
  isMobile: boolean;
  dataAutomationIdPrefix?: string;
  loadCommentsOnMount?: boolean;
  commentOpenedFromLink?: string;
  newCommentPopupIsShowing?: (isShowing: boolean) => void;
  likesUpdated: (likes: ILike[]) => void;
  commentsUpdated: (comments: number) => void;
  commentClicked?: () => void;
  commentCanceled?: () => void;
  onOpenIAMessage?: (instance: string, messageId: string) => void;
  onContentImageClicked?: (imageUrl) => void;
}

export interface ILikesAndCommentsState {
  showSpinner: boolean;
  showAllComments: boolean;
  likes: ILike[];
  comments: IComment[];
  showLikeButtonLoader: boolean;
  allCommentsCount: number;
  reply: IReply;
  isMobile: boolean;
  lastCommentRerender: Date;
  isAddingComment: boolean;
}

const DEFAULT_COLOR: string = '#025159';

export class IALikesAndComments extends React.Component<ILikesAndCommentsProps, ILikesAndCommentsState> {
  private readonly constructorName = "IALikesAndComments";
  private readonly localizationService = new LocalizationService();

  constructor(props: ILikesAndCommentsProps) {
    super(props);
    this.state = {
      showSpinner: false,
      showAllComments: false,
      likes: props.likes != undefined ? props.likes : undefined,
      comments: undefined,
      showLikeButtonLoader: false,
      allCommentsCount: props.commentCount != undefined ? props.commentCount : undefined,
      reply: undefined,
      isMobile: window.innerWidth < 667,
      lastCommentRerender: new Date(),
      isAddingComment: false
    };
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
    LoggingService.init(this.props.environment);
  }

  public componentDidMount(): void {
    if (this.props.commentOpenedFromLink) {
      this.setState({ showAllComments: true });
    }
    if (this.props.likesEnabled && this.props.likes == undefined) {
      this.setState({ showSpinner: true });
      if (this.props.item != undefined && this.props.item.type == 'News') {
        SocialService.getPublishingLikes(this.props.item, this.props.environment, this.props.tenant, this.props.token).then((response: any) => {
          if (response != 400 || response != 500 || response != 204) {
            let likes = [];
            response.likedBy.forEach((user: any) => {
              let userWhoLiked = Helper.syncUserProperties(user);
              likes.push({
                user: {
                  userPrincipalName: userWhoLiked.email.toLowerCase(),
                  id: userWhoLiked.id,
                  image: userWhoLiked.image,
                  displayName: userWhoLiked.name,
                },
              });
            });
            this.setState({ likes, showSpinner: false });
          } else {
            console.log('Error getting publiching likes');
          }
        });
      } else {
        SocialService.getLikes(this.props.environment, this.props.tenant, this.props.component, this.props.instance.instanceName, this.props.token, this.props.itemId).then((likes: ILike[]) => {
          this.setState({ likes, showSpinner: false });
        });
      }
    }
    if (this.props.commentsEnabled && this.props.loadCommentsOnMount) {
      SocialService.getCommentsIncludingDeleted(this.props.environment, this.props.tenant, this.props.component, this.props.instance.instanceName, this.props.token, this.props.itemId).then((result: { latestComments: IComment[], commentsCount: number }) => {
        if (result) {
          this.setState({ comments: result.latestComments, allCommentsCount: result.commentsCount, showSpinner: false });
        }
      });
    }
  }

  public componentWillReceiveProps(props: ILikesAndCommentsProps): void {
    if (props.itemId != undefined) {
      if (props.likesEnabled) {
        if (props.likes == undefined && this.state.likes == undefined) {
          if (props.item != undefined && props.item.type == 'News') {
            this.setState({ showSpinner: true });
            SocialService.getPublishingLikes(props.item, this.props.environment, this.props.tenant, this.props.token).then((response: any) => {
              if (response != 400 || response != 500 || response != 204) {
                let likes = [];
                response.likedBy.forEach((user: any) => {
                  console.log(user);
                  let userWhoLiked = Helper.syncUserProperties(user);
                  likes.push({
                    user: {
                      userPrincipalName: userWhoLiked.email.toLowerCase(),
                      id: userWhoLiked.id,
                      image: userWhoLiked.image,
                      displayName: userWhoLiked.name,
                    },
                  });
                });
                this.setState({ likes, showSpinner: false });
              } else {
                console.log('Error getting publiching likes');
              }
            });
          } else {
            this.setState({ showSpinner: true });
            SocialService.getLikes(this.props.environment, this.props.tenant, this.props.component, this.props.instance.instanceName, this.props.token, props.itemId).then((likes: ILike[]) => {
              this.setState({ likes, showSpinner: false });
            });
          }
        } else {
          if (props.likes != undefined) {
            this.setState({ likes: props.likes, showSpinner: false });
          }
        }
      }
      if (props.commentsEnabled) {
        if (props.item != undefined && props.item.type == 'News') {
          this.setState({ showSpinner: true });
          SocialService.getPublishingComments(props.item, this.props.environment, this.props.tenant, this.props.token).then((response: any) => {
            if (response != 400 || response != 500 || response != 204) {
              console.log(response);
              this.setState({ allCommentsCount: response.length, comments: response, showSpinner: false });
            } else {
              console.log('Error getting publiching comments');
            }
          });
        } else {
          if (!this.state.showSpinner) {
            this.setState({ showSpinner: true });
            SocialService.getCommentsIncludingDeleted(this.props.environment, this.props.tenant, this.props.component, this.props.instance.instanceName, this.props.token, props.itemId).then((result: { latestComments: IComment[], commentsCount: number }) => {
              if (result) {
                this.setState({ comments: result.latestComments, allCommentsCount: result.commentsCount, showSpinner: false });
              }
            });
            if (props.commentCount == undefined) {
              SocialService.getCommentsCount(this.props.environment, this.props.tenant, this.props.component, this.props.instance.instanceName, this.props.token, props.itemId).then((allCommentsCount: number) => {
                this.setState({ allCommentsCount });
              });
            } else {
              this.setState({ allCommentsCount: props.commentCount });
            }
          }
        }
      }
      if (props.itemId !== this.props.itemId) {
        this.setState({ showAllComments: false });
      }
    }
  }

  private likeButtonOnClick(hasLiked: boolean, isPublishingNews: boolean): void {
    if (!this.state.showLikeButtonLoader && this.props.user != undefined && !this.state.showSpinner) {
      if (hasLiked) {
        LoggingService.trackEvent(this.constructorName, {
          ActionType: 'UnlikeFromLikesAndComments',
          Component: this.props.component,
          ContentType: this.props.item != undefined ? this.props.item.type : '-',
          ContentId: this.props.item != undefined ? this.props.item.id : '-',
          Tenant: this.props.tenant,
          Instance: this.props.instance.instanceName,
          UserId: this.props.token,
          Source: this.props.source,
          Environment: this.props.environment,
        });
      } else {
        LoggingService.trackEvent(this.constructorName, {
          ActionType: 'LikeFromLikesAndComments',
          Component: this.props.component,
          ContentType: this.props.item != undefined ? this.props.item.type : '-',
          ContentId: this.props.item != undefined ? this.props.item.id : '-',
          Tenant: this.props.tenant,
          Instance: this.props.instance.instanceName,
          UserId: this.props.token,
          Source: this.props.source,
          Environment: this.props.environment,
        });
      }
      this.setState({ showLikeButtonLoader: true });
      if (isPublishingNews) {
        SocialService.publishingLike(this.props.item, this.props.environment, this.props.tenant, this.props.token, !hasLiked).then((response: any) => {
          if (response != 400 || response != 500 || response != 204) {
            let likes = [];
            response.likedBy.forEach((user: any) => {
              let userWhoLiked = Helper.syncUserProperties(user);
              likes.push({
                user: {
                  userPrincipalName: userWhoLiked.email.toLowerCase(),
                  id: userWhoLiked.id,
                  image: userWhoLiked.image,
                  displayName: userWhoLiked.name,
                },
              });
            });
            this.setState({ likes, showLikeButtonLoader: false });
            this.props.likesUpdated(likes);
          } else {
            console.log('Error getting publiching likes');
          }
        });
      } else {
        if (hasLiked) {
          let likeId: string;
          this.state.likes.forEach((like: ILike) => {
            if (this.props.user && like.user.userPrincipalName.toLowerCase() == this.props.user?.userPrincipalName.toLowerCase()) {
              likeId = like.id;
            }
          });
          SocialService.unlike(this.props.environment, this.props.tenant, this.props.component, this.props.instance.instanceName, this.props.token, this.props.itemId, likeId).then((likes: ILike[]) => {
            this.setState({ likes, showLikeButtonLoader: false });
            this.props.likesUpdated(likes);
          });
        } else {
          SocialService.like(
            this.props.environment,
            this.props.tenant,
            this.props.component,
            this.props.instance.instanceName,
            this.props.token,
            this.props.user,
            this.props.itemId,
            this.props.source
          ).then((likes: ILike[]) => {
            this.setState({ likes, showLikeButtonLoader: false });
            this.props.likesUpdated(likes);
          });
        }
      }
    }
  }

  private prevCommentsOnClick(): void {
    this.setState({ showAllComments: !this.state.showAllComments, lastCommentRerender: new Date() });
  }

  private addComment(content: string, mentions: IUser[], reply: IReply, isPublishingNews: boolean): Promise<void> {
    return new Promise((resolve) => {
      this.setState({ isAddingComment: true });
      let commentId = reply != undefined ? reply.commentId : undefined;
      LoggingService.trackEvent(this.constructorName, {
        ActionType: 'NewCommentFromLikesAndComments',
        Component: this.props.component,
        ReplyToId: commentId != undefined ? commentId : '-',
        ContentType: this.props.item != undefined ? this.props.item.type : '-',
        ContentId: this.props.item != undefined ? this.props.item.id : '-',
        Tenant: this.props.tenant,
        Instance: this.props.instance.instanceName,
        UserId: this.props.token,
        Source: this.props.source,
        Environment: this.props.environment,
      });
      if (isPublishingNews) {
        SocialService.addPublishingComment(this.props.item, this.props.environment, this.props.tenant, this.props.token, content).then(() => {
          SocialService.getPublishingComments(this.props.item, this.props.environment, this.props.tenant, this.props.token).then((response: any) => {
            if (response != 400 || response != 500 || response != 204) {
              this.setState({ allCommentsCount: response.length, comments: response, showSpinner: false });
              this.props.commentsUpdated(response);
            } else {
              console.log('Error getting publiching comments');
            }
            this.setState({ isAddingComment: false });
            resolve();
          });
        });
      } else {
        SocialService.addComment(
          this.props.environment,
          this.props.tenant,
          this.props.component,
          this.props.instance.instanceName,
          this.props.token,
          this.props.user,
          content,
          mentions,
          this.props.itemId,
          this.props.source,
          commentId
        ).then(() => {
          SocialService.getCommentsIncludingDeleted(this.props.environment, this.props.tenant, this.props.component, this.props.instance.instanceName, this.props.token, this.props.itemId).then((result: { latestComments: IComment[], commentsCount: number }) => {
            if (result) {
              this.setState({ comments: result.latestComments, allCommentsCount: result.commentsCount, showSpinner: false, reply: undefined });
            }
            this.setState({ isAddingComment: false });
          });
          SocialService.getCommentsCount(this.props.environment, this.props.tenant, this.props.component, this.props.instance.instanceName, this.props.token, this.props.itemId).then(
            (allCommentsCount: number) => {
              this.setState({ allCommentsCount, reply: undefined });
              this.props.commentsUpdated(allCommentsCount);
            }
          );
          resolve();
        });
      }
    });
  }

  private updateComment(content: string, commentId: string): Promise<void> {
    return new Promise((resolve) => {
      this.setState({ isAddingComment: true });
      SocialService.updateComment(
        this.props.environment,
        this.props.tenant,
        this.props.component,
        this.props.instance.instanceName,
        this.props.token,
        this.props.user,
        content,
        commentId
      ).then(() => {
        SocialService.getCommentsIncludingDeleted(this.props.environment, this.props.tenant, this.props.component, this.props.instance.instanceName, this.props.token, this.props.itemId).then((result: { latestComments: IComment[], commentsCount: number }) => {
          if (result) {
            this.setState({ comments: result.latestComments, allCommentsCount: result.commentsCount, showSpinner: false, reply: undefined });
          }
          this.setState({ isAddingComment: false });
        });
        SocialService.getCommentsCount(this.props.environment, this.props.tenant, this.props.component, this.props.instance.instanceName, this.props.token, this.props.itemId).then(
          (allCommentsCount: number) => {
            this.setState({ allCommentsCount, reply: undefined });
            this.props.commentsUpdated(allCommentsCount);
          }
        );
        resolve();
      });
    });
  }

  public render(): JSX.Element {
    let isPublishingNews = this.props.item?.type == 'News' ? true : false;
    // likes
    let hasLiked = false;
    let likes: JSX.Element[];
    if (this.props.likesEnabled && this.state.likes != undefined) {
      this.state.likes.forEach((like: any) => {
        if (isPublishingNews) {
          if (this.props.user && like.user.userPrincipalName.toLowerCase() == this.props.user?.userPrincipalName.toLowerCase()) {
            hasLiked = true;
          }
        } else {
          if (this.props.user && like.user.userPrincipalName.toLowerCase() == this.props.user?.userPrincipalName.toLowerCase()) {
            hasLiked = true;
          }
        }
      });
      likes = Utils.getLikesWithExamples(this.state.likes, this.props.user, this.props.color, this.props.isMobile, this.props.environment, this.props.tenant);
    }
    //
    let comments: JSX.Element[] = [];
    if (this.props.commentsEnabled && this.state.comments != undefined) {
      this.state.comments.forEach((comment: IComment) => {
        if (comment.reply) {
          // do not render replies on the first level
          return;
        }
        let myComment: boolean;
        if (isPublishingNews) {
          myComment = this.props.user && comment.user?.displayName == this.props.user?.displayName;
        } else {
          myComment = this.props.user && comment.user?.userPrincipalName?.toLowerCase() == this.props.user?.userPrincipalName?.toLowerCase();
        }
        comments.push(
          <IAComment
            key={comment.id}
            itemId={this.props.itemId}
            item={this.props.item}
            id={comment.id}
            tenant={this.props.tenant}
            component={this.props.component}
            instance={this.props.instance.instanceName}
            token={this.props.token}
            environment={this.props.environment}
            source={this.props.source}
            user={this.props.user}
            userRole={this.props.userRole}
            author={comment.user}
            content={comment.content}
            likes={this.props.likesEnabled || this.props.likesOnCommentsEnabled ? comment.likes : undefined}
            created={comment.created}
            myComment={myComment}
            reply={comment.reply}
            highlightColor={this.props.color != undefined ? this.props.color : DEFAULT_COLOR}
            likesEnabled={this.props.likesEnabled || this.props.likesOnCommentsEnabled}
            padding={this.props.padding}
            isMobile={this.props.isMobile}
            isDeleted={comment.isDeleted}
            isEdited={comment.isEdited}
            imageUploadUrl={this.props.imageUploadUrl}
            showAllComments={this.state.showAllComments}
            commentOpenedFromLink={this.props.commentOpenedFromLink}
            delete={async (commentId: string) => {
              return new Promise(async (resolve, reject) => {
                try {
                  if (isPublishingNews) {
                    await SocialService.deletePublishingComment(this.props.item, this.props.environment, this.props.tenant, this.props.token, commentId);
                  } else {
                    await SocialService.deleteComment(this.props.environment, this.props.tenant, this.props.component, this.props.instance.instanceName, this.props.token, this.props.itemId, commentId, this.props.instance.id);
                  }
                  let comments = this.state.comments;
                  const commentsAmount = Math.sign(this.state.allCommentsCount - 1) === -1 ? 0 : this.state.allCommentsCount - 1;
                  const index = comments.findIndex(c => c.id === commentId);
                  comments[index].isDeleted = true;
                  this.setState({ comments });
                  this.props.commentsUpdated(commentsAmount);
                  resolve();
                } catch (error) {
                  console.log("Error deleting comment");
                  reject();
                }
              });
            }}
            replyComment={(content: string, mentions: IUser[], reply: IReply) => this.addComment(content, mentions, reply, isPublishingNews)}
            likesUpdated={(likes, commentId) => {
              const comments: IComment[] = JSON.parse(JSON.stringify(this.state.comments));
              comments.find(comment => comment.id === commentId).likes = likes;
              this.setState({ comments });
            }}
            updateComment={(content: string, commentId: string) => this.updateComment(content, commentId)}
            allComments={this.state.comments}
            lastRerender={this.state.lastCommentRerender}
            triggerRerenderOnAllComments={() => this.setState({ lastCommentRerender: new Date() })}
            onOpenIAMessage={(instance, messageId) => this.props.onOpenIAMessage && this.props.onOpenIAMessage(instance, messageId)}
            onContentImageClicked={(imageUrl) => this.props.onContentImageClicked && this.props.onContentImageClicked(imageUrl)}
          />
        );
      });

      comments.reverse();
      if (!this.state.showAllComments && comments.length > 2) {
        comments = comments.slice(comments.length - 2);
      }
    }
    let disabled: boolean = !this.props.likesEnabled && !this.props.commentsEnabled;
    let paddingBottom = disabled ? 0 : 10;
    if (!this.props.commentsEnabled && this.props.likesEnabled) {
      if (this.props.isMobile) {
        paddingBottom = 70;
      } else {
        paddingBottom = 40;
      }
    }
    return (
      <div
        ref={(social: any) => {
          window.social = social;
        }}
        className={styles.IA_likesAndComments}
        id={`IALikesAndComments_${this.props.instance?.instanceName}_${this.props.itemId}_${this.props.webpartId}`}
        style={{
          paddingLeft: this.props.padding,
          paddingRight: this.props.padding,
          paddingBottom: paddingBottom,
          paddingTop: disabled ? 0 : 10,
          ...this.props.style,
        }}
      >
        {this.props.likesEnabled &&
          <div
            className={styles.IA_commandBar}
            style={{
              height: disabled ? 0 : 36,
              paddingTop: disabled ? 0 : 3,
              paddingBottom: disabled ? 0 : 3,
            }}
          >
            {/* Like button */}
            <div
              className={[styles.IA_commandBarButton, "IA_OnFocus"].join(" ")}
              tabIndex={0}
              role="button"
              style={{
                color: this.props.color != undefined ? this.props.color : DEFAULT_COLOR,
                opacity: this.props.user != undefined ? 1 : 0.5,
              }}
              data-automation-id={`${this.props.dataAutomationIdPrefix}-like`}
              onClick={(e) => {
                e.stopPropagation();
                this.likeButtonOnClick(hasLiked, isPublishingNews);
              }}
              onKeyPress={(event) => {
                if (event.which === ENTER_KEY_CODE || event.which === SPACEBAR_KEY_CODE) {
                  this.likeButtonOnClick(hasLiked, isPublishingNews);
                }
              }}
            >
              <div className={styles.IA_commandBarButtonIcon}>
                <IAIcon
                  url={hasLiked ? "https://intraactivestorage.blob.core.windows.net/cdn/icons/Thumb%20Like/SVG/ic_fluent_thumb_like_48_filled.svg" : "https://intraactivestorage.blob.core.windows.net/cdn/icons/Thumb%20Like/SVG/ic_fluent_thumb_like_48_regular.svg"}
                  size={18}
                  color={this.props.color != undefined ? this.props.color : DEFAULT_COLOR}
                  style={{
                    marginTop: 3,
                    opacity: this.state.showSpinner ? 0.5 : 1,
                  }}
                />
              </div>
              <div
                className={styles.IA_commandBarButtonText}
                style={{
                  opacity: this.state.showSpinner ? 0.5 : 1,
                }}
              >
                {hasLiked ? this.localizationService.strings.LikesAndComments_Unlike : this.localizationService.strings.LikesAndComments_Like}
              </div>
            </div>
          </div>
        }
        {/* Show likes */}
        <div className={styles.IA_likes}>{likes}</div>
        {/* Show previous comments */}
        {this.props.commentsEnabled && this.state.allCommentsCount > 2 && !isPublishingNews && (
          <div className={styles.IA_commandBar}>
            {/* Load previous comments button */}
            <div
              className={[styles.IA_commandBarButton, "IA_OnFocus"].join(" ")}
              tabIndex={0}
              role="button"
              style={{
                color: this.props.color != undefined ? this.props.color : DEFAULT_COLOR,
              }}
              onClick={() => this.prevCommentsOnClick()}
              onKeyPress={(event) => {
                if (event.which === ENTER_KEY_CODE || event.which === SPACEBAR_KEY_CODE) {
                  this.prevCommentsOnClick();
                }
              }}
            >
              <div className={styles.IA_commandBarButtonText}>{this.state.showAllComments ? this.localizationService.strings.LikesAndComments_OnlyShowLatestComments : this.localizationService.strings.LikesAndComments_ShowAllComments}</div>
            </div>
          </div>
        )}
        {this.props.commentsEnabled && (
          <div className={styles.IA_comments}>
            {this.state.showSpinner && (
              <div
                className={styles.IA_spinner}
                style={{
                  backgroundImage: `url('${IC_SPINNER}')`,
                }}
              ></div>
            )}
            {comments}
            <IANewComment
              token={this.props.token}
              tenant={this.props.tenant}
              environment={this.props.environment}
              reply={this.state.reply}
              addComment={true}
              isMobile={this.state.isMobile}
              user={this.props.user}
              dataAutomationIdPrefix={this.props.dataAutomationIdPrefix + '-comment'}
              highlightColor={this.props.color ?? DEFAULT_COLOR}
              isAddingComment={this.state.isAddingComment}
              comment={(content: string, mentions: IUser[], reply?: IReply) => this.addComment(content, mentions, reply, isPublishingNews)}
              cancelReply={() => {
                this.setState({ reply: undefined });
                if (this.props.commentCanceled) {
                  this.props.commentCanceled();
                }
              }}
              newCommentPopupIsShowing={this.props.newCommentPopupIsShowing}
            />
          </div>
        )}
      </div>
    );
  }
}
