import * as React from 'react';
import { ILike } from '../../interfaces/ILike';
import { IUser } from '../../interfaces/IUser';
import { IATooltip } from '../tooltip/Tooltip';
import { LocalizationService } from '../../services/LocalizationService';
import { IAPersonaSmall } from '../personaSmall/PersonaSmall';
import { Helper } from '../../Helper';
import { Environment } from '../../services/Environment';
import { IEnvironment } from '../../interfaces/IEnvironment';

export default class Utils {

  private static readonly localizationService: LocalizationService = new LocalizationService();

  public static getLikesWithExamples(likes: ILike[], you: IUser, color: string, isMobile: boolean, environment: IEnvironment, tenant): JSX.Element[] {
    let peopleWhoLiked = [];
    likes.forEach(like => {
      peopleWhoLiked.push(
        <IAPersonaSmall
          name={like.user.displayName}
          profileImage={Helper.getProfileImageUrlFromImageAPI(Environment.getEnvironmentForImageAPI(environment), tenant, "000", like.user.userPrincipalName)}
          styles={{
            marginBottom: 5,
            paddingLeft: 5,
            boxSizing: "border-box"
          }}
        />
      );
    });
    let likesElement: JSX.Element[] = [];
    if (likes != undefined) {
      if (likes.length == 1) {
        let user: IUser = likes[likes.length - 1].user;
        if (you != undefined && user.userPrincipalName == you.userPrincipalName) {
          likesElement.push(
            <div style={{ float: "left" }}><span style={{ fontWeight: "bold" }}>{this.localizationService.strings.LikesAndComments_Like_You}</span> {this.localizationService.strings.LikesAndComments_Comment_LikeThis}</div>
          );
        } else {
          likesElement.push(
            <div style={{ float: "left"}}><span style={{ fontWeight: "bold" }}>{user.displayName}</span> {this.localizationService.strings.LikesAndComments_Comment_LikesThis}</div>
          );
        }
      }
      if (likes.length > 1) {
        let user: IUser = likes[likes.length - 1].user;
        likesElement.push(
          <div style={{ float: "left" }}><span style={{ fontWeight: "bold" }}>{you != undefined && user.userPrincipalName == you.userPrincipalName ? this.localizationService.strings.LikesAndComments_Like_You : user.displayName}</span></div>
        );
      }
      if (likes.length == 2) {
        let user: IUser = likes[likes.length - 2].user;
        likesElement.push(
          <div style={{ float: "left" }}>&nbsp;{this.localizationService.strings.LikesAndComments_Comment_And}&nbsp;<span style={{ fontWeight: "bold" }}>{you != undefined && user.userPrincipalName == you.userPrincipalName ? this.localizationService.strings.LikesAndComments_Like_You : user.displayName}</span> {this.localizationService.strings.LikesAndComments_Comment_LikeThis}</div>
        );
      }
      if (likes.length > 2) {
        let user: IUser = likes[likes.length - 2].user;
        likesElement.push(
          <div style={{ float: "left" }}>, <span style={{ fontWeight: "bold" }}>{you != undefined && user.userPrincipalName == you.userPrincipalName ? this.localizationService.strings.LikesAndComments_Like_You : user.displayName}</span></div>
        );
      }
      if (likes.length == 3) {
        let user: IUser = likes[likes.length - 3].user;
        likesElement.push(
          <div style={{ float: "left" }}>&nbsp;{this.localizationService.strings.LikesAndComments_Comment_And}&nbsp;<span style={{ fontWeight: "bold" }}>{you != undefined && user.userPrincipalName == you.userPrincipalName ? this.localizationService.strings.LikesAndComments_Like_You : user.displayName}</span> {this.localizationService.strings.LikesAndComments_Comment_LikeThis}</div>
        );
      }
      if (likes.length > 3) {
        let user: IUser = likes[likes.length - 3].user;
        likesElement.push(
          <div style={{ float: "left" }}>, <span style={{ fontWeight: "bold" }}>{you != undefined && user.userPrincipalName == you.userPrincipalName ? this.localizationService.strings.LikesAndComments_Like_You: user.displayName}</span></div>
        );
      }
      if (likes.length >= 4) {
        const string = likes.length === 4 ? this.localizationService.strings.LikesAndComments_Comment_OneOther : `${likes.length - 3} ${this.localizationService.strings.LikesAndComments_Comment_Others}`;
        likesElement.push(
          <div style={{ float: "left" }}>
            <div style={{ float: "left" }}>&nbsp;{this.localizationService.strings.LikesAndComments_Comment_And}&nbsp;</div>
            <IATooltip
              content="Tooltip"
              type={isMobile ? "popup" : "bubble"}
              // type={"popup"}
              styles={{
                width: 200,
                textAlign: "left",
                color: "#333333",
              }}
              borderRadius={5}
              componentContent={
                <div>
                  <ul>
                    {peopleWhoLiked}
                  </ul>
                </div>
              }
            >
              <div style={{ float: "left", color: color, fontWeight: "bold", cursor: "pointer" }}>{string}&nbsp;</div>
            </IATooltip>
            {this.localizationService.strings.LikesAndComments_Comment_LikeThis}</div >
        );
      }
    }
    return likesElement;
  }
}