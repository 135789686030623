exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_widgets_1UUOmoqs5djqqEPiQfAi_S {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_EpirJUBLj6UV_tQXu-fJ9 {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_3sHQe1CHfHtLvaS3B6846C {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_3X28aLq2v-DunShSfainwu {\n  text-align: center;\n}", ""]);

// Exports
exports.locals = {
	"IA_widgets": "IA_widgets_1UUOmoqs5djqqEPiQfAi_S",
	"IA_filters": "IA_filters_EpirJUBLj6UV_tQXu-fJ9",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_3sHQe1CHfHtLvaS3B6846C",
	"IA_paginationPageCount": "IA_paginationPageCount_3X28aLq2v-DunShSfainwu"
};