exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_listItem_1WVWcbMwEpmQA7euE8zsBY {\n  position: relative;\n  width: 100%;\n  float: left;\n  box-sizing: border-box;\n}\n\n.IA_title_2znD0PMfb78KAzVH4reI09 {\n  float: left;\n}\n\n.IA_teaser_3wknV3I41eY4f5AqO2-yMs {\n  float: left;\n  display: -webkit-box;\n  box-orient: vertical;\n  -webkit-box-orient: vertical;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.IA_startDate_Ami5hqKUk3wTtamrI7X2E {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 10px; \n}\n\n.IA_now_121Ub6n9WLNzgNaUQDGTUF {\n  float: left;\n  width: 100%;\n  text-align: center;\n  font-weight: bold;\n}\n\n.IA_startDateendDateDivider_1bwQ3jb9zgH5oDVOFqWDkD {\n  float: left;\n  width: 60%;\n  height: 1px;\n  background-color: #999999;\n  margin-left: 20%;\n}\n\n.IA_endDate_NgBUhKaGhkZ1dxzs1Mbq- {\n  float: left;\n  width: 100%;\n  text-align: center;\n}\n\n.IA_coverOverlayToday_1Q0USp54M8GRCloWy97ylT {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 27px; \n  font-weight: bold;\n}\n\n.IA_coverOverlayMonth_2ruLL33jItc9iYPGxF2l5F {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 15px; \n  font-weight: normal;\n}\n\n.IA_coverOverlayDay_3Kec4FpRKfrL3rarOlYVdd {\n  float: left;\n  width: 100%;\n  text-align: center;\n}\n\n.IA_byline_1cg2XbHTGG8BfNflAXxS6M {\n  float: left;\n  clear: both;\n  position: relative;\n}\n", ""]);

// Exports
exports.locals = {
	"IA_listItem": "IA_listItem_1WVWcbMwEpmQA7euE8zsBY",
	"IA_title": "IA_title_2znD0PMfb78KAzVH4reI09",
	"IA_teaser": "IA_teaser_3wknV3I41eY4f5AqO2-yMs",
	"IA_startDate": "IA_startDate_Ami5hqKUk3wTtamrI7X2E",
	"IA_now": "IA_now_121Ub6n9WLNzgNaUQDGTUF",
	"IA_startDateendDateDivider": "IA_startDateendDateDivider_1bwQ3jb9zgH5oDVOFqWDkD",
	"IA_endDate": "IA_endDate_NgBUhKaGhkZ1dxzs1Mbq-",
	"IA_coverOverlayToday": "IA_coverOverlayToday_1Q0USp54M8GRCloWy97ylT",
	"IA_coverOverlayMonth": "IA_coverOverlayMonth_2ruLL33jItc9iYPGxF2l5F",
	"IA_coverOverlayDay": "IA_coverOverlayDay_3Kec4FpRKfrL3rarOlYVdd",
	"IA_byline": "IA_byline_1cg2XbHTGG8BfNflAXxS6M"
};