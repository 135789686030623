import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import { IAColorTheme } from '../../../../../colorTheme/ColorTheme';
import { Helper } from '../../../../../../Helper';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import PlayService from '../../../../../../services/PlayService';
import { IAOptionSelector } from '../../../../../optionSelector/OptionSelector';
import { IATextField } from '../../../../../textField/TextField';
import { IAToggle } from '../../../../../toggle/Toggle';
import { IANumberField } from '../../../../../numberField/NumberField';
import { IAGroup } from '../../../../../group/Group';

export interface IProps {
  store?: Store;
  onPlayersMissingLocation: (names: string[]) => void;
  forceUpdate: () => void;
}

@inject("store")
@observer
export class WeatherWidgetSettings extends React.Component<IProps> {
  private readonly localizationService: LocalizationService = new LocalizationService();

  componentDidMount(): void {
    PlayService.getPlayers(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id).then(players => {
      if (players.value?.length > 0) {
        const playersMissingLocationWarning: string[] = [];
        players.value.forEach(player => {
          if (!player.longitude || !player.latitude) {
            playersMissingLocationWarning.push(player.title);
          }
        });
        if (playersMissingLocationWarning.length > 0) {
          this.props.onPlayersMissingLocation(playersMissingLocationWarning);
        }
      }
    });
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  render(): React.ReactNode {
    return (
      <div>
        <IAGroup
          darkMode={this.props.store.darkMode}
          label={this.localizationService.strings.PlayAdmin_WidgetSettings?.toUpperCase()}
        >
          <IATextField
            label={this.localizationService.strings.PlayAdmin_Title?.toUpperCase()}
            required
            placeholder={this.localizationService.strings.PlayAdmin_Title}
            text={this.props.store.widgetToEdit.title}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(title: string) => {
              this.props.store.widgetToEdit.title = title;
              this.props.forceUpdate();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20,
              fontSize: 12
            }}
            darkMode={this.props.store.darkMode}
            label={this.localizationService.strings.PlayAdmin_ShowWidgetTitle?.toUpperCase()}
            checked={this.props.store.widgetToEdit.showTitle}
            color={this.props.store.highlightColor}
            onChange={() => {
              this.props.store.widgetToEdit.showTitle = this.props.store.widgetToEdit.showTitle != undefined ? !this.props.store.widgetToEdit.showTitle : true;
              this.props.forceUpdate();
            }}
          />
          <IANumberField
            label={this.localizationService.strings.PlayAdmin_Duration?.toUpperCase()}
            number={this.props.store.widgetToEdit?.duration && this.props.store.widgetToEdit?.duration !== 0 ? this.props.store.widgetToEdit?.duration : 30}
            onChange={(duration: number) => {
              this.props.store.widgetToEdit.duration = duration;
              this.props.forceUpdate();
            }}
            min={0}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            styles={{
              width: "100%",
              clear: "both"
            }}
            labelStyle={{
              fontSize: "12px"
            }}
          />
        </IAGroup>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_ArticleLayoutType.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <label
            style={{
              color: this.props.store.darkMode ? Helper.darkModeLabelColor : "#333333",
              marginTop: 20
            }}
          >
            {this.localizationService.strings.PlayAdmin_ColorThemes?.toUpperCase()}
          </label>
          <IAColorTheme
            backgroundColor={this.props.store.widgetToEdit?.weather?.colorTheme?.backgroundColor}
            textColor={this.props.store.widgetToEdit?.weather?.colorTheme?.textColor}
            highlightColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            swatches={this.props.store.profile?.swatches}
            inTeams
            remove={undefined}
            allowAddingToSwatches={false}
            setBackgroundColor={(backgroundColor) => {
              this.props.store.widgetToEdit.weather.colorTheme.backgroundColor = backgroundColor;
              this.props.forceUpdate();
            }}
            setTextColor={(textColor) => {
              this.props.store.widgetToEdit.weather.colorTheme.textColor = textColor;
              this.props.forceUpdate();
            }}
          />
          <IAOptionSelector
            label={this.localizationService.strings.PlayAdmin_WeatherType?.toUpperCase()}
            options={[
              {
                iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Shifts Day/SVG/ic_fluent_shifts_day_24_regular.svg",
                key: "day",
                text: this.localizationService.strings.PlayAdmin_Day
              },
              {
                iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Calendar LTR/SVG/ic_fluent_calendar_ltr_48_regular.svg",
                key: "week",
                text: this.localizationService.strings.PlayAdmin_Week
              },
            ]}
            selected={this.props.store.widgetToEdit.weather.weatherType ?? "week"}
            highlightColor={this.props.store.highlightColor}
            compact
            iconSize={30}
            darkMode={this.props.store.darkMode}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(weatherType: any) => {
              this.props.store.widgetToEdit.weather.weatherType = weatherType;
              this.props.forceUpdate();
            }}
            style={{ clear: "none", marginTop: 20 }}
          />
        </IAGroup>
      </div>
    );
  }

  componentWillUnmount(): void {
    this.props.onPlayersMissingLocation([]);
  }
}