import * as React from 'react';
import { IAIcon } from '../../../icon/Icon';
import * as styles from './Popup.css';

export interface IIAPopupProps {
  headline?: string;
  content: JSX.Element;
  style?: React.CSSProperties;
  darkMode?: boolean;
  close?: () => void;
}

export class Popup extends React.Component<IIAPopupProps, {}> {

  constructor(props: IIAPopupProps) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <div className={this.props.darkMode ? styles.IA_popupDarkMode : styles.IA_popup}>
        <div
          className={styles.IA_popupBackground}
          onClick={() => {
            if (this.props.close) {
              this.props.close();
            }
          }}
        />
        <div
          className={styles.IA_popupContentBox}
          style={this.props.style}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            {this.props.headline &&
              <h1 style={{fontWeight: 600}}>{this.props.headline}</h1>
            }
            {this.props.close != undefined &&
              <IAIcon
                title={"Close"}
                size={18}
                color={this.props.darkMode ? "#A8A8A8" : "#000"}
                style={{
                  marginTop: 8,
                  marginLeft: 15
                }}
                onClick={() => this.props.close()}
              />
            }
          </div>
          {this.props.content}
        </div>
      </div>
    );
  }
}