exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_playlists_3N099hasOelu8LKO9KZs5M {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_1D_ekAy71bMurLLj13X560 {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_3R4qNPH-JT-pUTEdFqvTGI {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_2FgMEH-l9zA-0VpawDPML9 {\n  text-align: center;\n}", ""]);

// Exports
exports.locals = {
	"IA_playlists": "IA_playlists_3N099hasOelu8LKO9KZs5M",
	"IA_filters": "IA_filters_1D_ekAy71bMurLLj13X560",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_3R4qNPH-JT-pUTEdFqvTGI",
	"IA_paginationPageCount": "IA_paginationPageCount_2FgMEH-l9zA-0VpawDPML9"
};