import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IPlayTimeEditSearchObject } from '../../../../../../../interfaces/IPlay';

export interface IProps {
  highlightColor: string;
  widgetFilterObject: IPlayTimeEditSearchObject;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export interface IState {
}

@inject('store')
@observer
export class TimeEditFilter extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
  }

  render(): React.ReactNode {
    const propsStyle = this.props.style ?? {};
    const labelStyle: React.CSSProperties = {
      padding: "2px 5px",
      borderRadius: 5,
      backgroundColor: this.props.highlightColor,
      color: "white",
      cursor: this.props.onClick ? "pointer" : undefined,
      userSelect: "none",
      ...propsStyle
    };
    return (
      <div
        style={labelStyle}
        onClick={this.props.onClick}
      >
        {this.props.widgetFilterObject.field[0].value}
      </div>
    );
  }
}