import * as React from 'react';
import * as styles from './Mentions.css';
import { inject, observer } from 'mobx-react';
import { Store } from '../stores';
import { IUser } from '../../../interfaces/IUser';
import UserService from '../../../services/UserService';
import { IASpinner } from '../../spinner/Spinner';
import { IAPersonaImage } from '../../personaImage/PersonaImage';
import { Helper } from '../../../Helper';
import { Environment } from '../../../services/Environment';
import { LocalizationService } from '../../../services/LocalizationService';

export interface IProps {
  store?: Store;
  mentionsQuery: string;
  onMentionPicked: (displayName: string, userPrincipalName: string) => void;
  closePopupAndFocusText: () => void;
  saveMentionRef: (ref: HTMLDivElement) => void;
}

export interface IState {
  isLoading: boolean;
  searchResults: IUser[];
}

@inject('store')
@observer
export class IAMentions extends React.Component<IProps, IState> {
  private localizationService = new LocalizationService();
  private searchResultRefs: HTMLDivElement[] = [];

  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: false,
      searchResults: [],
    }
    this.onClickHandler = this.onClickHandler.bind(this);
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  componentDidMount(): void {
    this.queryMentions();
    window.addEventListener("mousedown", this.onClickHandler);
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.mentionsQuery !== this.props.mentionsQuery) {
      this.queryMentions();
    }
  }

  private queryMentions(){
    const query = this.props.mentionsQuery?.replace("@","");
    if (query) {
      this.setState({isLoading: true}, () => {
        UserService.getUsers(this.props.store.environment, this.props.store.token, `${this.props.store.tenant}.sharepoint.com`, query, false).then(result => {
          this.setState({
            searchResults: result.users,
            isLoading: false
          });
        });
      });
    } else {
      this.setState({
        searchResults: [],
        isLoading: false
      });
    }
  }

  private onClickHandler(event: MouseEvent) {
    let element = event.target as HTMLElement;
    if (!element.className?.includes("IA_Mentions")) {
      window.removeEventListener("mousedown", this.onClickHandler);
      this.props.store.showMentionsTool = false;
    }
  }

  private onKeyDownHandler(event: React.KeyboardEvent<HTMLDivElement>, index: number) {
    if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      event.preventDefault();
      let nextIndex: number;
      if (event.key === "ArrowDown") {
        nextIndex = index + 1 >= this.state.searchResults.length ? 0 : index + 1;
      }
      if (event.key === "ArrowUp") {
        nextIndex = index - 1 < 0 ? this.state.searchResults.length - 1 : index - 1;
      }

      this.searchResultRefs[nextIndex].focus();
      return;
    }

    if (event.key === "Enter") {
      event.preventDefault();
      this.props.onMentionPicked(this.state.searchResults[index].displayName, this.state.searchResults[index].userPrincipalName);
      return;
    }

    if (event.key === "Escape") {
      event.nativeEvent.stopImmediatePropagation();
      this.props.closePopupAndFocusText();
      return;
    }
  }

  public render(): JSX.Element {
    const hasResults = this.state.searchResults?.length > 0;
    const width = 350;
    let left = this.props.store.mentionsPopupLeft + width > window.innerWidth ? window.innerWidth - width + 5 : this.props.store.mentionsPopupLeft;
    if (this.props.store.isMobile) {
      left = 20;
    }
    return (
      <div
        id="IA_Mentions"
        className={styles.IA_MentionsBox}
        style={{
          left: left,
          top: this.props.store.mentionsPosition === "below" ? this.props.store.mentionsPopupTop : undefined,
          bottom: this.props.store.mentionsPosition === "above" ? window.innerHeight - this.props.store.mentionsPopupBottom : undefined,
          width: this.props.store.isMobile ? "calc(100% - 40px)" : width,
          maxHeight: this.props.store.mentionsPopupMaxHeight
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {this.state.isLoading 
        ?
          <IASpinner color={this.props.store.highlightColor}/> 
        : 
          <>
            {hasResults
            ?
              <>
                {this.state.searchResults.map((user, i) => {
                  // ugly workaround for the email stored in "mail" prop
                  const email = user.email ?? user["mail"] ?? user.userPrincipalName;
                  return <div
                    key={user.userPrincipalName}
                    ref={(ref) => {
                      this.searchResultRefs[i] = ref;
                      if (i === 0) {
                        this.props.saveMentionRef(ref);
                      }
                    }}
                    className={styles.IA_MentionsSearchResultWrapper}
                    tabIndex={1}
                    onClick={() => this.props.onMentionPicked(user.displayName, user.userPrincipalName)}
                    onKeyDown={(e) => this.onKeyDownHandler(e, i)}
                  >
                    <IAPersonaImage
                      backgroundColor={this.props.store.highlightColor}
                      profileImage={Helper.getProfileImageUrlFromImageAPI(
                        Environment.getEnvironmentForImageAPI(this.props.store.environment),
                        this.props.store.tenant,
                        this.props.store.token,
                        email
                      )}
                      name={user.displayName}
                      size={30}
                      fontSize={16}
                      marginRight={10}
                    />
                    <div className={styles.IA_MentionsSearchResultInfo}>
                      <div className={styles.IA_MentionsSearchResultName}>
                        {user.displayName}
                      </div>
                      <div className={styles.IA_MentionsSearchResultUPN}>
                        {email}
                      </div>
                    </div>
                  </div>
                })}
              </>
            :
              <div className={styles.IA_MentionsNoResult}>{this.localizationService.strings.Mentions_NoUsersFound}</div>
            }
          </>
        }
      </div>
    );
  }
}