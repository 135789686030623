import * as React from 'react';
import * as styles from './ArticleEditPanel.css';
import "../../../../../styles/BasicIA.css";
import { inject, observer } from 'mobx-react';
import { Store } from '../../../stores';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IAEditor } from '../../../../editor/Index';
import { IAMessageBar } from '../../../../messageBar/MessageBar';

export interface IProps {
  store?: Store;
  id: string;
  text: string;
  hideToolbox: boolean;
  onChange: (text: string) => void;
  onTableSettingsChange?: (showTableBorder: boolean) => void;
}

export interface IState {
  editorHtml: any;
  showController: boolean;
  didPressCtrlV?: boolean;
  contentHeightIsOk: boolean;
}

@inject('store')
@observer
export class ArticleText extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      editorHtml: undefined,
      showController: false,
      didPressCtrlV: false,
      contentHeightIsOk: true
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentDidMount(): void {
    this.setState({
      editorHtml: this.props.text ? this.props.text : ``
    });
    this.hideToolbox();
  }

  private showToolbox(): void {
    this.setState({ showController: true });
  }

  private hideToolbox(): void {
    this.setState({ showController: false });
  }

  public render(): JSX.Element {
    return (
      <>
        <div
          id={"IA_text"}
          className={styles.IA_text}
        >
          <IAEditor
            isMobile={this.props.store.isMobile}
            highlightColor={this.props.store.highlightColor}
            content={this.state.editorHtml}
            placeholder={this.localizationService.strings.Composer_TypeHere}
            darkMode={this.props.store.darkMode}
            style={{
              width: "100%",
              minHeight: 116,
              overflow: "hidden",
              border: this.state.contentHeightIsOk ? (this.state.showController ? `1px solid ${this.props.store.highlightColor}` : "1px solid #cbcbcb") : "1px solid #ffda78",
              padding: 5,
              boxSizing: "border-box",
              backgroundColor: this.props.store.darkMode ? "#414141" : "white",
              color: this.props.store.darkMode ? "white" : "black",
              borderRadius: 5
            }}
            hideToolbox={!this.state.showController}
            fontStyleSupport
            listsSupport
            clearStylingSupport
            emojiSupport
            tableSupport
            tableSettingsSupport
            htmlSupport
            pastePlain
            showTableBorder={this.props.store.articleToEdit?.showTableBorder}
            onChange={(editorHtml) => {
              this.setState({ editorHtml });
              let contentElement = document.getElementById("IA_text");
              if (contentElement.clientHeight < 415) {
                this.setState({ contentHeightIsOk: true }, () => this.props.onChange(editorHtml));
              } else {
                this.setState({ contentHeightIsOk: false }, () => this.props.onChange(editorHtml));
              }
            }}
            onFocus={() => {
              setTimeout(() => {
                this.showToolbox();
              }, 0);
            }}
            onBlur={() => {
              if (this.state.didPressCtrlV) {
                // fix for pasting stuff not working when textfield is empty
                this.setState({ didPressCtrlV: false });
              } else {
                setTimeout(() => {
                  this.hideToolbox();
                }, 10);
              }
            }}
            onStyleChange={() => { }}
            onTableSettingsChange={(showTableBorder: boolean) => this.props.onTableSettingsChange(showTableBorder)}
          />
        </div>
        {!this.state.contentHeightIsOk &&
          <IAMessageBar
            type="warning"
            onDismiss={() => { }}
            icon="Attention"
            content={<span>{this.localizationService.strings.PlayAdmin_ContentWillScroll}</span>}
            showIcon={true}
            showCloseButton={false}
            style={{
              clear: "both",
              float: "left",
              borderRadius: 5,
              padding: "0 20px",
              marginTop: 20
            }}
          />
        }
      </>
    );
  }
}