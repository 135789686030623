import * as React from 'react';
import { Store } from '../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './IntegrationsEditPanel.css';
import { IAPanel } from '../../../../panels/Panel';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IASpinner } from '../../../../spinner/Spinner';
import { Helper } from '../../../../../Helper';
import { PlayHelper } from '../../../PlayHelper';
import { IIconDropDown } from '../../../../../interfaces/IIconDropDown';
import PlayService from '../../../../../services/PlayService';
import { IFeed, IIntegrationsPerProfile } from '../../../../../interfaces/IFeed';
import { PlayIntegrationType, IPlayProfile, IFacebookPageIntegration } from '../../../../../interfaces/IPlay';
import { IPlayAppRegistration } from '../appRegistrationConsentHelper/AppRegistrationConsentHelper';
import { Popup } from '../../../reusableComponents/popup/Popup';
import { IAButton } from '../../../../button/Button';
import { IAMessageBar } from '../../../../messageBar/MessageBar';
import { IntegrationsIcon } from './IntegrationsIcon';
import { EventIntegrationComponent } from './integrationsEditComponents/EventIntegrationComponent';
import { MessagesIntegrationComponent } from './integrationsEditComponents/MessagesIntegrationComponent';
import { SharepointNewsIntegrationComponent } from './integrationsEditComponents/SharepointNewsIntegrationComponent';
import { WorkPlaceIntegrationComponent } from './integrationsEditComponents/WorkplaceIntegrationComponent';
import { PowerBIIntegrationComponent } from './integrationsEditComponents/PowerBIIntegrationComponent';
import { FacebookFeedIntegrationComponent } from './integrationsEditComponents/FacebookFeedIntegrationComponent';
import { RelesysIntegrationComponent } from './integrationsEditComponents/RelesysIntegrationComponent';
import { TimeEditIntegrationComponent } from './integrationsEditComponents/TimeEditIntegrationComponent';

export interface IProps {
  show: boolean;
  profiles: IPlayProfile[];
  store?: Store;
  onSave: () => void;
  onClose: () => void;
  onConnecting: () => Promise<void>;
  integrationsPerProfile: IIntegrationsPerProfile;
  isEditingExistingIntegration: boolean;
  areMessagesConnected: boolean;
  areEventsConnected: boolean;
  isConsentGrantedForSharePointNewsApp: boolean;
  isConsentGrantedForSharePointNewsSiteManagementApp: boolean;
  getIntegrationsForProfile: (profile: IPlayProfile) => Promise<void>;
  loadingProfileIntegrations: {[profileId: string]: boolean};
}

export interface IState {
  loading: boolean;
  isCreatingNewFeed: boolean;
  availableFeeds: IFeed[];
  requiredAppRegistrations: IPlayAppRegistration[];
  isConnected: boolean;
  isConsentGrantedForSharePointNewsApp: boolean;
  isConsentGrantedForSharePointNewsSiteManagementApp: boolean;
  isDeletingSharepointNewsItem: boolean;
  feedToDelete: IFeed;
  showConfirmDeleteSharepointNewsItem: boolean;
  shouldReloadOnFocus: boolean;
  profileIdSelected: string;
}

@inject('store')
@observer
export class IntegrationsEditPanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      isCreatingNewFeed: false,
      availableFeeds: [],
      requiredAppRegistrations: [],
      isConnected: undefined,
      isConsentGrantedForSharePointNewsApp: props.isConsentGrantedForSharePointNewsApp,
      isConsentGrantedForSharePointNewsSiteManagementApp: props.isConsentGrantedForSharePointNewsSiteManagementApp,
      isDeletingSharepointNewsItem: false,
      feedToDelete: undefined,
      showConfirmDeleteSharepointNewsItem: false,
      shouldReloadOnFocus: false,
      profileIdSelected: undefined
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  componentWillMount(): void {
    window.addEventListener("focus", this.handleFocusEvent)
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.handleFocusEvent)
  }

  handleFocusEvent = async () => {
    if (this.state.shouldReloadOnFocus) {
      this.setState({ shouldReloadOnFocus: false }, async () => {
        await this.onFocus(false);
      });
    }
  }

  onFocus = async (force: boolean = false, feedToAddSiteId?: string) => {
    if ((this.props.show &&
      this.props.store.integrationToEdit?.type &&
      (
        this.props.store.integrationToEdit.type === 'messages' ||
        this.props.store.integrationToEdit.type === 'events'
      ) &&
      this.state.isConnected
    ) || force) {
      this.setState({ loading: true })
      await this.props.onConnecting();
      this.onIntegrationTypeChange(this.props.store.integrationToEdit.type);
      this.onProfileChange(this.props.store.integrationToEdit.profileId, feedToAddSiteId);
      this.setState({ loading: false });
      this.state.isCreatingNewFeed && this.setState({ isCreatingNewFeed: false });
    }
  }

  componentWillReceiveProps(nextProps: Readonly<IProps>): void {
    console.log("areMessagesConnected:" + nextProps.areMessagesConnected);
    if (nextProps.show && !this.props.show && nextProps.isEditingExistingIntegration) {
      this.onIntegrationTypeChange(this.props.store.integrationToEdit.type);
      this.onProfileChange(this.props.store.integrationToEdit.profileId);
    }

    if (nextProps.isConsentGrantedForSharePointNewsApp !== undefined) {
      this.setState({ isConsentGrantedForSharePointNewsApp: nextProps.isConsentGrantedForSharePointNewsApp });
    }

    if (nextProps.isConsentGrantedForSharePointNewsSiteManagementApp !== undefined) {
      this.setState({ isConsentGrantedForSharePointNewsSiteManagementApp: nextProps.isConsentGrantedForSharePointNewsSiteManagementApp });
    }
  }


  private async onProfileChange(id: string, feedToAddSiteId?: string) {
    if (!id) return;

    this.props.store.integrationToEdit.profileId = id;
    if (!this.props.integrationsPerProfile[id]) {
      await this.props.getIntegrationsForProfile({ id: id });
    }

    let currentFeeds: IFeed[];

    switch (this.props.store.integrationToEdit.type) {
      case 'messages':
        currentFeeds = this.props.integrationsPerProfile[id].messagesFeeds;
        break;
      case 'events':
        currentFeeds = this.props.integrationsPerProfile[id].eventsFeeds;
        break;
      case 'sharepoint-news':
        currentFeeds = this.props.integrationsPerProfile[id].sharePointSites;
        break;
      default:
        currentFeeds = [];
        break;
    }

    // newly added sites should be added to integration
    if (feedToAddSiteId) {
      this.state.availableFeeds.forEach(feed => {
        if (feed.meta?.siteId === feedToAddSiteId) {
          this.props.store.integrationToEdit?.feeds.push(feed.id);
          currentFeeds.push(feed);
          return;
        }
      })
    };

    this.props.store.integrationToEdit.feeds = currentFeeds.map(feed => feed.id);
    this.props.store.integrationToEdit.powerBiWorkspaces = this.props.integrationsPerProfile[id]?.powerBiIntegrations ?? [];
    this.props.store.integrationToEdit.relesysClientId = this.props.integrationsPerProfile[id]?.relesysIntegration?.clientId;
    this.props.store.integrationToEdit.relesysClientSecret = this.props.integrationsPerProfile[id]?.relesysIntegration?.clientSecret;
    this.props.store.integrationToEdit.facebookIntegrations = this.props.integrationsPerProfile[id]?.facebookPageIntegrations ?? [];
    this.props.store.integrationToEdit.timeEditIntegration = this.props.integrationsPerProfile[id]?.timeEditIntegration ?? {
      customerSpecificUrl: "",
      password: "",
      username: ""
    };
    this.setState({ profileIdSelected: id });
  }

  private onIntegrationTypeChange(type: string) {
    let availableFeeds: IFeed[];
    let requiredAppRegistrations: IPlayAppRegistration[];
    let isConnected: boolean;

    this.props.store.integrationToEdit.type = type as PlayIntegrationType;

    switch (type) {
      case 'messages':
        isConnected = this.props.areMessagesConnected;
        break;
      case 'events':
        isConnected = this.props.areEventsConnected;
        break;
      case 'sharepoint-news':
        isConnected = this.state.isConsentGrantedForSharePointNewsApp; // **SharePointNewsSiteManagement** app is **optional**
        requiredAppRegistrations = !this.state.isConsentGrantedForSharePointNewsApp ? [
          {
            name: "SharePointNews",
            heading: this.localizationService.strings.PlayAdmin_AppRegistrationSharePointNewsTitle,
            description: this.localizationService.strings.PlayAdmin_AppRegistrationSharePointNewsDescription
          },
          {
            name: "SharePointNewsSiteManagement",
            heading: this.localizationService.strings.PlayAdmin_AppRegistrationSharePointNewsSiteManagementTitle,
            description: this.localizationService.strings.PlayAdmin_AppRegistrationSharePointNewsSiteManagementDescription
          }] : [];
        break;
      default:
        break;
    }

    this.onProfileChange(this.props.store.integrationToEdit.profileId);
    this.setState({ isConnected, availableFeeds, requiredAppRegistrations });
  }

  private onConnectEventOrMessage(integrationTypeToEdit: "messages" | "events") {
    PlayService.connectIntranetApplicationFunction(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.integrationToEdit.profileId, integrationTypeToEdit, null).then(async (_) => {
      this.setState({ loading: true, isCreatingNewFeed: true, shouldReloadOnFocus: true });
      window.open(`https://${this.props.store.sharepointRootSite}/sites/IntraActive-Admin?showFeedWidgetForComponent=${integrationTypeToEdit === 'messages' ? 'IntraActive-Messages' : 'IntraActive-Events'}`, '_blank')
      await setTimeout(() => { }, 10000);
      const interval = setInterval(() => {
        PlayService.connectIntranetApplicationFunction(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.integrationToEdit.profileId, integrationTypeToEdit, null)
          .then(async (isConnected) => {
            if (isConnected) {
              await this.props.onConnecting();
              this.onIntegrationTypeChange(this.props.store.integrationToEdit.type);
              this.onProfileChange(this.props.store.integrationToEdit.profileId);
              this.setState({ loading: false, isCreatingNewFeed: false });
              clearInterval(interval);
            }
          })
      }, 5000)
    });
  }

  private disableSaveButton(): boolean {
    if (!this.state.isConnected) {
      return true;
    }

    switch (this.props.store.integrationToEdit?.type) {
      case "powerbi":
        return !!!this.props.store.integrationToEdit?.powerBiWorkspaces?.length;
      case "messages":
      case "events":
      case "sharepoint-news":
        return !!!this.props.store.integrationToEdit?.feeds?.length;
      case "workplace-feed":
        return !!!this.props.store.integrationToEdit?.accessToken;
      case "facebook-feed":
        return !!!(this.props.store.integrationToEdit?.facebookIntegrations?.length);
      case "relesys":
        return !!!(this.props.store.integrationToEdit?.relesysClientId && this.props.store.integrationToEdit?.relesysClientSecret);
      case "timeEdit":
        return !!!(this.props.store.integrationToEdit?.timeEditIntegration?.customerSpecificUrl && this.props.store.integrationToEdit?.timeEditIntegration?.password && this.props.store.integrationToEdit?.timeEditIntegration?.username);
      default:
        return true;
    }
  }

  public render(): JSX.Element {
    const integrationTypes = [];
    const availabelWidgetTypes = PlayHelper.getIntegrationTypes(this.localizationService);
    availabelWidgetTypes?.forEach((integrationType: IIconDropDown, i: number) => {
      integrationTypes.push(
        <IntegrationsIcon
          icon={integrationType.icon}
          integrationKey={integrationType.value}
          label={integrationType.label}
          onIntegrationTypeChange={(value) => this.onIntegrationTypeChange(value)}
          key={i}
          opacity={this.props.isEditingExistingIntegration && this.props.store.integrationToEdit?.type === integrationType.value ? 0.8 : (!this.props.store.integrationToEdit?.type || this.props.store.integrationToEdit?.type === integrationType.value) ? 1 : 0.5}
          disabled={this.props.isEditingExistingIntegration}
        />
      );
    });
    return (
      <IAPanel
        open={this.props.show}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        loading={false}
        width={440}
        darkMode={this.props.store.darkMode}
        dataAutomationIdPrefix="integrations-edit-panel"
        panelId="integrations-edit-panel"
        isInTeams={this.props.store.isTeams}
        navigationsBarContent={
          <IAButton
            label={this.props.isEditingExistingIntegration ? this.localizationService.strings.PlayAdmin_Update?.toUpperCase() : this.localizationService.strings.PlayAdmin_Save?.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            disbaled={this.disableSaveButton()}
            darkMode={this.props.store.darkMode}
            onClick={async () => {
              this.setState({ loading: true })
              await this.props.onSave();
              this.setState({ loading: false })
            }}
            borderRadius={5}
            showSpinner={this.props.store.workingOnIt}
            style={{
              float: "right",
              position: "relative",
              marginTop: 7,
              marginRight: 10
            }}
          />
        }
        close={() => {
          this.setState({ availableFeeds: undefined, isConnected: undefined, profileIdSelected: undefined })
          this.props.onClose();
        }}
      >
        {this.state.showConfirmDeleteSharepointNewsItem &&
          <Popup
            headline={this.localizationService.strings.PlayAdmin_IntegrationConfirmDeleteSite}
            close={() => this.setState({ showConfirmDeleteSharepointNewsItem: false })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_Yes?.toUpperCase()}
                  onClick={() => {
                    // Signal that we are currently deleting something
                    this.setState({ isDeletingSharepointNewsItem: true, showConfirmDeleteSharepointNewsItem: false })
                    // Delete the permission for the selected sharepoint site
                    PlayService.deleteSharePointSitePermission(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.state.feedToDelete.meta.siteId).then(() => {
                      this.setState({ isDeletingSharepointNewsItem: false });
                      this.onFocus(true);
                    });
                  }}
                  borderRadius={5}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_No?.toUpperCase()}
                  onClick={() => this.setState({ showConfirmDeleteSharepointNewsItem: false })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
        {this.props.store.integrationToEdit ?
          <div
            className={styles.IA_integrationsEditPanel}
            style={{
              height: window.innerHeight - 44,
              backgroundColor: this.props.store.darkMode ? "#414141" : "#ffffff",
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
          >
            <div className={styles.IA_content} style={{ backgroundColor: this.props.store.darkMode ? Helper.darkModeBackgroundColor : '#fff' }}>
              <h1 style={{ color: this.props.store.darkMode ? "#ffffff" : '#333333' }}>{this.props.store.integrationToEdit?.feeds?.length > 0 || this.props.store.integrationToEdit.accessToken != undefined ? this.localizationService.strings.PlayAdmin_EditIntegration : this.localizationService.strings.PlayAdmin_AddNewIntegration}</h1>
              <div className={styles.IA_widgetWrapper}>
                {integrationTypes}
                {(this.props.store.integrationToEdit.type && this.props.store.integrationToEdit.type == "messages" || this.props.store.integrationToEdit.type == "events" || this.props.store.integrationToEdit.type == "sharepoint-news") &&
                  <IAMessageBar
                    type={"warning"}
                    style={{
                      marginTop: 20,
                      borderRadius: 5,
                      background: "#eeeeee"
                    }}
                    content={
                      <div style={{marginLeft: 5, marginRight: 5}}>
                        {this.localizationService.strings.PlayAdmin_SharepointIntegrationDisclaimer}
                      </div>
                    }
                    onDismiss={() => { }}
                  />
                }
              </div>
              {(this.props.loadingProfileIntegrations && this.props.loadingProfileIntegrations[this.props.store.integrationToEdit.profileId]) ?
                  <IASpinner
                    color={this.props.store.highlightColor}
                  />
                :
                  <>
                  {this.props.store.integrationToEdit.type === "events" &&
                    <EventIntegrationComponent
                      profiles={this.props.profiles}
                      onProfileChange={profile => this.onProfileChange(profile)}
                      isDeletingSharepointNewsItem={this.state.isDeletingSharepointNewsItem}
                      isEditingExistingIntegration={this.props.isEditingExistingIntegration}
                      selectedProfileId={this.state.profileIdSelected}
                      isConnected={this.state.isConnected}
                      onAddNewFeed={() => {
                        this.setState({ isCreatingNewFeed: true, shouldReloadOnFocus: true })
                        window.open(`https://${this.props.store.sharepointRootSite}/sites/IntraActive-Admin?showFeedWidgetForComponent=IntraActive-Events`, '_blank')    
                      }}
                      onConnect={() => this.onConnectEventOrMessage("events")}
                    />
                  }
                  {this.props.store.integrationToEdit.type === "messages" &&
                    <MessagesIntegrationComponent
                      profiles={this.props.profiles}
                      isDeletingSharepointNewsItem={this.state.isDeletingSharepointNewsItem}
                      isEditingExistingIntegration={this.props.isEditingExistingIntegration}
                      selectedProfileId={this.state.profileIdSelected}
                      onProfileChange={profile => this.onProfileChange(profile)}
                      isConnected={this.state.isConnected}
                      onAddNewFeed={() => {
                        this.setState({ isCreatingNewFeed: true, shouldReloadOnFocus: true })
                        window.open(`https://${this.props.store.sharepointRootSite}/sites/IntraActive-Admin?showFeedWidgetForComponent=IntraActive-Messages`, '_blank')    
                      }}
                      onConnect={() => this.onConnectEventOrMessage("messages")}
                    />
                  }
                  {this.props.store.integrationToEdit.type === "sharepoint-news" &&
                    <SharepointNewsIntegrationComponent
                      profiles={this.props.profiles}
                      onProfileChange={profile => this.onProfileChange(profile)}
                      isDeletingSharepointNewsItem={this.state.isDeletingSharepointNewsItem}
                      isEditingExistingIntegration={this.props.isEditingExistingIntegration}
                      selectedProfileId={this.state.profileIdSelected}
                      isConnected={this.state.isConnected}
                      requiredAppRegistrations={this.state.requiredAppRegistrations}
                      sharePointSiteManagerOnComplete={(id) => this.onFocus(true, id)}
                      onConstent={(appRegistration) => {
                        switch (appRegistration) {
                          case 'SharePointNews':
                            this.setState({ isConsentGrantedForSharePointNewsApp: true });
                            break;
                          case 'SharePointNewsManagement':
                            this.setState({ isConsentGrantedForSharePointNewsSiteManagementApp: true });
                            break;
                          default:
                            break;
                        }
                      }}
                      onConnectSucces={() => this.setState({ isConnected: true })}
                    />
                  }
                  {this.props.store.integrationToEdit.type === "workplace-feed" &&
                    <WorkPlaceIntegrationComponent
                      profiles={this.props.profiles}
                      onProfileChange={profile => this.onProfileChange(profile)}
                      isEditingExistingIntegration={this.props.isEditingExistingIntegration}
                      onAccessTokenChange={accessToken => {
                        this.props.store.integrationToEdit.accessToken = accessToken;
                        this.setState({ isConnected: true }, () => this.forceUpdate());
                      }}
                    />
                  }
                  {this.props.store.integrationToEdit.type === "powerbi" &&
                    <PowerBIIntegrationComponent
                      profiles={this.props.profiles}
                      onProfileChange={profile => this.onProfileChange(profile)}
                      isEditingExistingIntegration={this.props.isEditingExistingIntegration}
                      onPowerBiWorkspaceChosen={workspace => {
                        const index = this.props.store.integrationToEdit?.powerBiWorkspaces.findIndex(w => w.id == workspace.id);
                        if (index === -1) {
                          this.props.store.integrationToEdit?.powerBiWorkspaces.push(workspace);
                        } else {
                          this.props.store.integrationToEdit?.powerBiWorkspaces.splice(index, 1);
                        }
                        this.setState({ isConnected: true }, () => this.forceUpdate());    
                      }}
                    />
                  }
                  {this.props.store.integrationToEdit.type === "facebook-feed" &&
                    <FacebookFeedIntegrationComponent
                      profiles={this.props.profiles}
                      onProfileChange={profile => this.onProfileChange(profile)}
                      isEditingExistingIntegration={this.props.isEditingExistingIntegration}
                      onFacebookFeedChange={(page: IFacebookPageIntegration) => {
                        const index = this.props.store.integrationToEdit?.facebookIntegrations.findIndex(w => w.pageId == page.pageId);
                        if (index === -1) {
                          this.props.store.integrationToEdit?.facebookIntegrations.push(page);
                        } else {
                          this.props.store.integrationToEdit?.facebookIntegrations.splice(index, 1);
                        }
                        this.setState({ isConnected: true }, () => this.forceUpdate());
                      }}
                    />
                  }
                  {this.props.store.integrationToEdit.type === "relesys" &&
                    <RelesysIntegrationComponent
                      profiles={this.props.profiles}
                      onProfileChange={profile => this.onProfileChange(profile)}
                      isEditingExistingIntegration={this.props.isEditingExistingIntegration}
                      onChange={(isConnected) => {
                        this.setState({ isConnected }, () => {
                          console.log(!this.state.isConnected || !(this.props.store.integrationToEdit?.powerBiWorkspaces?.length || this.props.store.integrationToEdit?.feeds?.length || this.props.store.integrationToEdit?.accessToken || (this.props.store.integrationToEdit?.facebookIntegrations)));
                          console.log(this.state.isConnected);
                          this.forceUpdate();
                        });
                      }}
                    />
                  }
                  {this.props.store.integrationToEdit.type === "timeEdit" &&
                    <TimeEditIntegrationComponent
                      profiles={this.props.profiles}
                      onProfileChange={profile => this.onProfileChange(profile)}
                      isEditingExistingIntegration={this.props.isEditingExistingIntegration}
                      onChange={(isConnected) => {
                        this.setState({ isConnected }, () => {
                          this.forceUpdate();
                        });
                      }}
                    />
                  }
                </>
              }
              

              {this.props.store.integrationToEdit?.type &&
                <>
                  <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
                  {this.state.loading &&
                    <>
                      <div
                        style={{ clear: 'both', paddingTop: 10 }}>
                        <IASpinner
                          color={this.props.store.highlightColor}
                        />
                      </div>
                      {this.state.isCreatingNewFeed &&
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          {this.localizationService.strings.PlayAdmin_CreatingNewFeed}
                        </div>
                      }
                    </>
                  }
                </>
              }
            </div>
          </div>
          :
          <div
            className={styles.IA_loading}
            style={{
              backgroundColor: this.props.store.darkMode ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.5)"
            }}
          >
            <IASpinner
              color={this.props.store.highlightColor}
            />
            <div className={styles.IA_weAreWorkingOnIt}>{this.localizationService.strings.Composer_WeAreWorkingOnIt}</div>
          </div>
        }
      </IAPanel >
    );
  }


}