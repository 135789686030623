import * as React from 'react';
import QRCodeService from '../../../../../../services/QRCodeService';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import * as styles from './QRCode.css';
import { PlayHelper } from '../../../../../playAdmin/PlayHelper';

export interface IProps {
  link: string;
  linkText?: string;
  sizeUnit: number;
  style?: React.CSSProperties;
  language?: string;
  backgroundColor?: string;
  textColor?: string;
  id?: string;
}

export interface IState {
  qrCode?: string;
}

export class QRCode extends React.Component<IProps, IState> {

  private localizationService: LocalizationService;

  constructor(props: IProps) {
    super(props);
    this.state = {};
    this.localizationService = new LocalizationService(PlayHelper.getLocalizerLanguageFromPlayerLanguage(this.props.language));
  }

  componentDidMount(): void {
    if (this.props.link) {
      try {
        QRCodeService.generateQRCode(this.props.link, this.props.backgroundColor, this.props.textColor).then((qrCode: string) => {
          if (qrCode) {
            this.setState({ qrCode });
          }
        });
      } catch (error) {
        console.log(`%c${error}`, 'background: red; color: #ffffff')
      }
    }
  }

  public render(): JSX.Element {
    return (
      <div
        id={this.props.id}
        className={styles.IA_QRCode}
        style={{
          position: "absolute",
          borderRadius: this.props.sizeUnit,
          width: this.props.sizeUnit * 12,
          ...this.props.style
        }}
      >
        <div
          style={{
            marginTop: 0,
            fontSize: this.props.sizeUnit * 1.7,
            marginBottom: this.props.sizeUnit * 0.3,
            textAlign: "center",
            paddingTop: this.props.sizeUnit,
            paddingLeft: this.props.sizeUnit,
            paddingRight: this.props.sizeUnit,
            color: this.props.textColor ? this.props.textColor : "#333333",
            overflowWrap: "anywhere",
            hyphens: "auto"
          }}
        >
          {this.props.linkText ?? this.localizationService.strings.Player_More}
        </div>
        <img
          src={this.state.qrCode}
          className={styles.IA_QRCodeImage}
          style={{
            width: this.props.sizeUnit * 12,
            height: this.props.sizeUnit * 12,
            backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : "#ffffff",
          }}
        />
      </div >
    );
  }
}