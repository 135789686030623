import * as React from 'react';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { PlayHelper } from '../../../../PlayHelper';
import * as styles from './MediaFileUploadPanel.css';
import { IAButton } from '../../../../../button/Button';
import { IATextField } from '../../../../../textField/TextField';
import { IAzureStorageUploadInfo, IMediaFile } from '../../../../../../interfaces/IPlay';
import PlayService from '../../../../../../services/PlayService';
import { Store } from '../../../../stores';
import { Helper } from '../../../../../../Helper';
import { IAPanel } from '../../../../../panels';
import { inject, observer } from "mobx-react";
import { Popup } from '../../../../reusableComponents/popup/Popup';
import { MediaFilePreview } from './MediaFilePreview';

export interface IProps {
  open: boolean;
  fileType?: "image" | "video" | "pdf" | "font";
  store?: Store;
  enableMultiple?: boolean;
  close: () => void;
  reloadData?: () => void;
  selectedUrl?: (url: string) => void;
}

export interface IState {
  isSaving: boolean;
  file: File;
  azureStorageInfo: IAzureStorageUploadInfo;
  openDialog: boolean;
  isOverriding: boolean;
  isDragging: boolean;
  isLoadingPreview: boolean;
  lastLoaded: IMediaFile;
  previewUrl: string;
  isUploadingMultiple: boolean;
  isLoadingMultiplePreview: boolean;
  tagToAdd: string;
  isAddingTag: boolean;
  showConfirmClosePopup: boolean;
  filesToUpload: {
    previewUrl: string,
    azureStorageInfo: IAzureStorageUploadInfo,
    file: File,
    blurHash: string
  }[];
}

@inject('store')
@observer
export class MediaFileUploadPanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();
  private inputRef: React.RefObject<HTMLInputElement>

  constructor(props: IProps) {
    super(props);
    this.state = {
      file: undefined,
      azureStorageInfo: {} as IAzureStorageUploadInfo,
      isSaving: false,
      openDialog: false,
      isOverriding: false,
      isDragging: false,
      isLoadingPreview: true,
      lastLoaded: undefined,
      previewUrl: undefined,
      isUploadingMultiple: false,
      isLoadingMultiplePreview: true,
      filesToUpload: [],
      tagToAdd: undefined,
      isAddingTag: false,
      showConfirmClosePopup: false
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
    this.inputRef = React.createRef();
  }

  componentDidUpdate(): void {
    if (this.props.store.mediaFileToEdit !== this.state.lastLoaded) {
      let previewUrl = this.state.file && this.props.store.mediaFileToEdit?.isNew ? URL.createObjectURL(this.state.file) : this.props.store.mediaFileToEdit?.url ?? "";
      this.setState({
        isLoadingPreview: this.props.store.mediaFileToEdit?.mimeType?.includes("font") ? false : true,
        lastLoaded: this.props.store.mediaFileToEdit,
        previewUrl
      });
    }
  }

  // private convertBytesToKB = (bytes) => Math.round(bytes / PlayHelper.BYTES_PER_KILOBYTES);

  private handleUploadBtnClick = () => {
    this.inputRef.current.click();
  };

  private validateTitle(title: string): string {
    let validatedTitle = decodeURIComponent(title);
    const validatedTitleArray = validatedTitle.split(".");
    validatedTitle = validatedTitleArray.length > 1 ? validatedTitleArray[0] : validatedTitle;
    return validatedTitle;
  }

  private handleMediaFileChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const files = e.target.files;
    if (files.length > 1 || this.state.isUploadingMultiple) {
      this.setState({ isUploadingMultiple: true }, () => {
        this.prepareMultipleFilesForUpload(files);
      });
    } else {
      this.prepareFileForUpload(files[0]);
    }
  };

  private prepareFileForUpload(file: File) {
    this.setState({ file });
    let fileName = file.name;
    if (this.props.store.isMobile && file.name && file.name.indexOf(".")) {
      const splittedFileName = file.name.split(".");
      fileName = `${splittedFileName[0]}-${Helper.getRandomStringKey()}.${splittedFileName[1]}`;
    }
    PlayService.getMediaFileLinkToUpload(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, fileName).then((azureStorageInfo: IAzureStorageUploadInfo) => {
      azureStorageInfo.fileName = fileName;
      this.props.store.mediaFileToEdit = {
        ...this.props.store.mediaFileToEdit,
        id: azureStorageInfo.id,
        name: encodeURIComponent(this.validateTitle(azureStorageInfo.id)),
        url: azureStorageInfo.uploadLink,
        mimeType: file.type,
      }
      this.setState({ azureStorageInfo, isLoadingPreview: file.type?.includes("font") ? false : true });
    });
  }

  private prepareMultipleFilesForUpload(files: FileList) {
    const prevLength = this.state.filesToUpload.length;
    const list = this.state.filesToUpload;
    for (let i = 0; i < files.length; i++) {
      list.push({
        azureStorageInfo: undefined,
        previewUrl: undefined,
        file: undefined,
        blurHash: undefined
      });
    }
    this.setState({ filesToUpload: list, isLoadingMultiplePreview: true }, () => {
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        let fileName = file.name;
        if (this.props.store.isMobile && file.name && file.name.indexOf(".")) {
          const splittedFileName = file.name.split(".");
          fileName = `${splittedFileName[0]}-${Helper.getRandomStringKey()}.${splittedFileName[1]}`;
        }
        PlayService.getMediaFileLinkToUpload(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, fileName).then((azureStorageInfo: IAzureStorageUploadInfo) => {
          azureStorageInfo.fileName = fileName;
          list[i + prevLength] = {
            azureStorageInfo: azureStorageInfo,
            previewUrl: URL.createObjectURL(file),
            file: file,
            blurHash: undefined
          }
          this.setState({ filesToUpload: list });
          if (i + 1 === files.length) {
            this.setState({ isLoadingMultiplePreview: false });
          }
        });
      }
    });
  }

  private removeFileFromState() {
    this.setState({ file: undefined, azureStorageInfo: { fileExists: false, containerName: '', storageUri: '', sharedAccessSignature: '', id: '', uploadLink: '' }, isOverriding: false })
    this.props.store.mediaFileToEdit = undefined;
  }

  private getFileType(): string {
    if (this.props.fileType) {
      switch (this.props.fileType) {
        case 'image':
          return 'image/*';
        case 'video':
          return 'video/*';
        case 'font':
          return 'font/*';
        case 'pdf':
          return 'application/pdf';
        default:
          return 'application/pdf, image/*, video/*, font/*'
      }
    }
  }

  private async onSave(override?: boolean) {
    if (this.state.azureStorageInfo.fileExists) {
      this.setState({ isOverriding: true });
    }

    if (!this.state.azureStorageInfo.fileExists || override) {
      this.setState({ isSaving: true });
      if (this.state.file) {
        await PlayService.uploadFileToAzureStorage(this.props.store.tenantId, this.props.store.profile?.id, this.state.azureStorageInfo.storageUri, this.state.azureStorageInfo.containerName, this.state.azureStorageInfo.sharedAccessSignature, this.state.file, this.state.azureStorageInfo.fileName);
      }
      if (this.props.store.mediaFileToEdit && this.props.store.mediaFileToEdit.url) {
        this.props.store.mediaFileToEdit.url = PlayHelper.useCDN(this.props.store.mediaFileToEdit.url);
      }
      if (this.props.store.mediaFileToEdit.blurHash) this.props.store.mediaFileToEdit.url = `${this.props.store.mediaFileToEdit.url}?${PlayHelper.BLURHASH_QUERY_PARAM}=${this.props.store.mediaFileToEdit.blurHash}`;
      await PlayService.saveMediaFile(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, this.props.store.mediaFileToEdit);
      if (this.props.reloadData) {
        this.props.reloadData();
      }
      if (this.props.selectedUrl) {
        this.props.selectedUrl(this.props.store.mediaFileToEdit.url);
      }
      this.removeFileFromState();
      this.setState({ isSaving: false, openDialog: false, isOverriding: false, file: undefined });
    }
  }

  private onSaveMultiple() {
    this.state.filesToUpload.forEach((file, i) => {
      this.setState({ isSaving: true });
      PlayService.uploadFileToAzureStorage(this.props.store.tenantId, this.props.store.profile?.id, file.azureStorageInfo.storageUri, file.azureStorageInfo.containerName, file.azureStorageInfo.sharedAccessSignature, file.file, file.azureStorageInfo.fileName).then(() => {
        if (file.blurHash) file.azureStorageInfo.uploadLink = `${file.azureStorageInfo.uploadLink}?${PlayHelper.BLURHASH_QUERY_PARAM}=${file.blurHash}`;
        PlayService.saveMediaFile(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, {
          mimeType: file.file.type,
          id: file.azureStorageInfo.id,
          name: encodeURIComponent(this.validateTitle(file.azureStorageInfo.id)),
          url: file.azureStorageInfo.uploadLink
        }).then(() => {
          if (this.props.selectedUrl) {
            this.props.selectedUrl(file.azureStorageInfo.uploadLink);
          }
          if (i + 1 === this.state.filesToUpload.length) {
            this.setState({
              isSaving: false,
              file: undefined,
              isUploadingMultiple: false,
              filesToUpload: []
            });
            this.props.store.mediaFileToEdit = undefined;
            this.props.reloadData();
          }
        });
      });
    });
  }

  private addTagToMediaFile(tag) {
    if (!this.props.store.mediaFileToEdit?.tags) {
      this.props.store.mediaFileToEdit.tags = [];
    }
    const tagIndex = this.props.store.mediaFileToEdit.tags.indexOf(tag);
    if (tagIndex === -1) {
      this.props.store.mediaFileToEdit.tags.push(tag);
      this.forceUpdate();
    } else {
      this.props.store.mediaFileToEdit.tags.splice(tagIndex, 1);
      this.forceUpdate();
    }
    this.setState({ isAddingTag: false, tagToAdd: undefined });
  }

  public render(): JSX.Element {
    return (
      <IAPanel
        open={this.props.open}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        loading={this.state.isSaving}
        spinnerColor={this.props.store.highlightColor}
        width={600}
        dataAutomationIdPrefix="media-file-edit-panel"
        panelId="media-file-edit-panel"
        isInTeams={this.props.store.isTeams}
        navigationsBarContent={
          <IAButton
            label={this.localizationService.strings.PlayAdmin_Save?.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            disbaled={this.state.isLoadingPreview && this.state.isLoadingMultiplePreview}
            onClick={() => {
              if (this.state.isUploadingMultiple) {
                this.onSaveMultiple();
              } else {
                this.onSave();
              }
            }}
            darkMode={this.props.store.darkMode}
            borderRadius={5}
            style={{
              float: "right",
              position: "relative",
              marginTop: 7,
              marginRight: 10
            }}
          />
        }
        darkMode={this.props.store.darkMode}
        close={() => this.setState({ showConfirmClosePopup: true })}
      >
        {this.state.showConfirmClosePopup &&
          <Popup
            headline={this.localizationService.strings.Messages_CancelPopup_Headline}
            close={() => this.setState({ showConfirmClosePopup: false })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_Yes?.toUpperCase()}
                  onClick={() => {
                    this.setState({
                      file: undefined,
                      isUploadingMultiple: false,
                      filesToUpload: [],
                      showConfirmClosePopup: false
                    });
                    this.props.close();
                  }}
                  borderRadius={5}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_No?.toUpperCase()}
                  onClick={() => this.setState({ showConfirmClosePopup: false })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
        <div className={styles.IA_mediaFileUploadPanel}>
          <>
            {this.props.store.mediaFileToEdit?.url && !this.props.store.mediaFileToEdit?.isNew &&
              <div className={styles.IA_label} style={{ color: this.props.store.darkMode ? Helper.darkModeLabelColor : '#000' }}>{this.localizationService.strings.PlayAdmin_Preview.toLocaleUpperCase()}</div>
            }
            <div
              className={styles.IA_uploadContainer}
              style={{
                backgroundColor: this.props.store.darkMode ? Helper.darkModeInputFieldBackgroundColor : '#fafafa',
                border: this.props.store.mediaFileToEdit?.url || this.state.file ? `1px solid ${this.props.store.darkMode ? Helper.darkModeBackgroundColor : "#ffffff"}` : "2px dotted #dddddd",
                opacity: this.state.isDragging ? 0.5 : 1
              }}
            >
              {!(this.props.store.mediaFileToEdit?.url || this.state.file) &&
                <>
                  <p className={styles.IA_dragDropText} style={{ color: this.props.store.darkMode ? Helper.darkModeLabelColor : '#333333' }}>{this.localizationService.strings.PlayAdmin_DragAndDropYourFilesOrClickOnAddButton}</p>
                  <input
                    ref={this.inputRef}
                    className={styles.IA_uploadInput}
                    type="file"
                    accept={this.getFileType()}
                    onLoad={() => this.setState({ isLoadingPreview: false })}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleMediaFileChange(e)}
                    onDragOver={e => {
                      if (e.dataTransfer?.items && e.dataTransfer?.items?.length > 0 && !this.state.isDragging) {
                        this.setState({ isDragging: true });
                      }
                    }}
                    onDragLeave={() => {
                      if (this.state.isDragging) {
                        this.setState({ isDragging: false });
                      }
                    }}
                    onDrop={() => {
                      this.setState({ isDragging: false });
                    }}
                    multiple={!!this.props.enableMultiple}
                  />
                  <IAButton
                    icon="Add"
                    buttonColor={this.props.store.highlightColor}
                    darkMode={this.props.store.darkMode}
                    borderRadius={5}
                    label={this.localizationService.strings.PlayAdmin_Add?.toUpperCase()}
                    onClick={this.handleUploadBtnClick}
                    style={{
                      marginTop: 20,
                      zIndex: 100
                    }}
                  />
                </>
              }
              {this.state.previewUrl && !this.state.isUploadingMultiple &&
                <>
                  <MediaFilePreview
                    mimeType={this.props.store.mediaFileToEdit?.mimeType?.includes("font") ? "image/png" : this.props.store.mediaFileToEdit?.mimeType}
                    previewUrl={this.props.store.mediaFileToEdit?.mimeType?.includes("font") ? "https://intraactivestorage.blob.core.windows.net/cdn/play/font-type-preview.png" : this.state.previewUrl}

                    onLoad={(imgRef) => {
                      this.setState({ isLoadingPreview: false }, () => {
                        if (imgRef && !this.props.store.mediaFileToEdit?.blurHash && this.props.store.mediaFileToEdit?.isNew) {
                          this.props.store.mediaFileToEdit.blurHash = Helper.generateBlurHash(imgRef);
                        }
                        URL.revokeObjectURL(this.state.previewUrl);
                      });
                    }}
                    style={{
                      height: "100%",
                      width: "100%"
                    }}
                  />
                  {this.state.isLoadingPreview &&
                    <div className={[styles.IA_uploadContainerSkeleton, this.props.store.darkMode ? styles.IA_uploadContainerSkeletonDark : styles.IA_uploadContainerSkeletonLight].join(" ")}></div>
                  }
                </>
              }
            </div>
          </>
          <>
            {this.state.previewUrl &&
              <>
                <IATextField
                  label={this.localizationService.strings.PlayAdmin_Title?.toUpperCase()}
                  placeholder={this.localizationService.strings.PlayAdmin_Title}
                  text={this.props.store.mediaFileToEdit?.name}
                  darkMode={this.props.store.darkMode}
                  highlightColor={this.props.store.highlightColor}
                  borderRadius={5}
                  disabled={!this.props.store.mediaFileToEdit?.url}
                  labelStyle={{
                    fontSize: "12px"
                  }}
                  onChange={(name: string) => {
                    this.props.store.mediaFileToEdit.name = name;
                  }}
                />
                <IATextField
                  label={"URL"}
                  placeholder={"Url"}
                  disabled
                  text={this.props.store.mediaFileToEdit?.url}
                  borderRadius={5}
                  darkMode={this.props.store.darkMode}
                  highlightColor={this.props.store.highlightColor}
                  labelStyle={{
                    fontSize: "12px"
                  }}
                  onChange={(url: string) => {
                    this.props.store.mediaFileToEdit.url = url;
                  }}
                />
                <IATextField
                  label={this.localizationService.strings.PlayAdmin_Type?.toUpperCase()}
                  placeholder={this.localizationService.strings.PlayAdmin_Type}
                  disabled
                  text={this.props.store.mediaFileToEdit?.mimeType}
                  borderRadius={5}
                  darkMode={this.props.store.darkMode}
                  highlightColor={this.props.store.highlightColor}
                  labelStyle={{
                    fontSize: "12px"
                  }}
                  onChange={(type: string) => {
                    this.props.store.mediaFileToEdit.mimeType = type;
                  }}
                />
                {/* <IATextField
                label={this.localizationService.strings.PlayAdmin_Size?.toUpperCase()}
                placeholder={this.localizationService.strings.PlayAdmin_Size}
                disabled
                borderRadius={5}
                darkMode={this.props.store.darkMode}
                highlightColor={this.props.store.highlightColor}
                labelStyle={{
                  fontSize: "12px"
                }}
                text={`${this.state.file?.size ? this.convertBytesToKB(this.state.file.size) : 0} kb`}
                onChange={() => { }}
              /> */}
                <>
                  <IATextField
                    placeholder={`${this.localizationService.strings.PlayAdmin_AddNewTag}...`}
                    text={this.state.tagToAdd}
                    borderRadius={5}
                    labelStyle={{
                      textTransform: "uppercase",
                      fontSize: 12
                    }}
                    style={{
                      marginTop: 15,
                      marginBottom: 15,
                      width: "calc(100% - 110px)"
                    }}
                    label={this.localizationService.strings.PlayAdmin_Tag?.toUpperCase()}
                    darkMode={this.props.store.darkMode}
                    onChange={(tagToAdd: string) => {
                      this.setState({ tagToAdd: tagToAdd });
                    }}
                  />
                  <IAButton
                    disbaled={this.state.isAddingTag || !this.state.tagToAdd}
                    label={this.localizationService.strings.PlayAdmin_Add?.toUpperCase()}
                    buttonColor={this.props.store.highlightColor}
                    darkMode={this.props.store.darkMode}
                    onClick={() => {
                      if (this.state.tagToAdd) {
                        this.setState({ isAddingTag: true }, () => {
                          PlayService.createMediaFileTag(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id, this.state.tagToAdd).then(tag => {
                            PlayService.getMediaFileTags(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id).then(tags => {
                              this.props.store.mediaFileTags = tags;
                              this.addTagToMediaFile(tag);
                            });
                          });
                        });
                      }
                    }}
                    borderRadius={5}
                    showSpinner={this.props.store.workingOnIt}
                    style={{
                      float: "left",
                      position: "relative",
                      marginLeft: 10,
                      width: 100,
                      marginTop: 42
                    }}
                  />
                  <div
                    style={{
                      color: this.props.store.darkMode ? "#6e6e6e" : "#333333",
                      clear: "both"
                    }}
                  >
                    {this.props.store.mediaFileTags?.map((tag, index) => {
                      let backgroundColor = this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#eeeeee";
                      let color = this.props.store.darkMode ? "#ffffff" : "#333333";
                      if (this.props.store.mediaFileToEdit?.tags && this.props.store.mediaFileToEdit?.tags?.indexOf(tag.id) !== -1) {
                        backgroundColor = this.props.store.highlightColor;
                        color = "white";
                      }
                      return <div
                        key={tag.id + index}
                        className={styles.IA_tags}
                        style={{
                          backgroundColor: backgroundColor,
                          color: color
                        }}
                        onClick={() => { this.addTagToMediaFile(tag.id) }}
                      >
                        {tag.id.toLocaleUpperCase()}
                      </div>
                    })}
                  </div>
                </>
              </>
            }
            {this.state.filesToUpload && this.state.filesToUpload.length > 0 &&
              <div style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap"
              }}>
                {this.state.filesToUpload.map((file, i) => {
                  return <MediaFilePreview
                    key={`imagePreview_${file.previewUrl}_${i}`}
                    mimeType={file.file?.type?.includes("font") ? "image/png" : file.file?.type}
                    previewUrl={file.file?.type?.includes("font") ? "https://intraactivestorage.blob.core.windows.net/cdn/play/font-type-preview.png" : file.previewUrl}
                    onLoad={(imgRef) => {
                      if (imgRef) {
                        this.state.filesToUpload[i].blurHash = Helper.generateBlurHash(imgRef);
                        this.setState({ filesToUpload: this.state.filesToUpload });
                      }
                      URL.revokeObjectURL(file.previewUrl);
                    }}
                    style={{
                      width: "calc(50% - 10px)",
                      height: 200,
                      margin: 5,
                      backgroundColor: this.props.store.darkMode ? Helper.darkModeInputFieldBackgroundColor : '#fafafa',
                      borderRadius: 10,
                      overflow: "hidden"
                    }}
                  />
                })}
              </div>
            }
            {this.state.isOverriding && this.state.azureStorageInfo.fileExists &&
              <Popup
                headline={this.localizationService.strings.PlayAdmin_FileAlreadyExists}
                close={() => this.removeFileFromState()}
                darkMode={this.props.store.darkMode}
                content={
                  <>
                    <h4 style={{
                      marginBottom: 15,
                      color: this.props.store.darkMode ? Helper.darkModeLabelColor : '#000'
                    }}>
                      {this.localizationService.strings.PlayAdmin_OverrideFileQuestion}
                    </h4>
                    <IAButton
                      buttonColor={this.props.store.highlightColor}
                      darkMode={this.props.store.darkMode}
                      label={this.localizationService.strings.PlayAdmin_DeletePopup_Yes.toLocaleUpperCase()}
                      onClick={() => {
                        this.onSave(true);
                      }}
                      borderRadius={5}
                      style={{
                        float: "left"
                      }}
                    />
                    <IAButton
                      textColor={this.props.store.highlightColor}
                      darkMode={this.props.store.darkMode}
                      label={this.localizationService.strings.PlayAdmin_DeletePopup_No.toLocaleUpperCase()}
                      onClick={() => this.removeFileFromState()}
                      style={{
                        float: "left",
                        marginLeft: 30
                      }}
                    />
                  </>
                }
              />
            }
          </>
        </div>
      </IAPanel>
    );
  }


}
