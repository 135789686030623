import * as React from 'react';
import { LocalizationService } from '../../services/LocalizationService';
import { IAColorPicker } from '../colorPicker/ColorPicker';
import { IAIcon } from '../icon/Icon';
import * as styles from './ColorTheme.css';

export interface IColorThemeProps {
  key?: string;
  backgroundColor: string;
  textColor: string;
  swatches?: string[];
  allowAddingToSwatches: boolean;
  highlightColor: string;
  darkMode?: boolean;
  inTeams?: boolean;
  toolTip?: JSX.Element;
  disableTextColor?: boolean;
  setBackgroundColor: (backgroundColor: string) => void;
  setTextColor: (textColor: string) => void;
  remove: () => void;
  addSwatch?: (color: string) => void;
}

export class IAColorTheme extends React.Component<IColorThemeProps> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IColorThemeProps) {
    super(props);
    this.state = {
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public render(): JSX.Element {
    return (
      <div
        key={this.props.key}
        className={styles.IA_colorTheme}
        style={{
          backgroundColor: this.props.darkMode ? "#414141" : "#f4f4f4",
          border: this.props.darkMode ? "none" : "1px solid #dddddd"
        }}
      >
        <div
          className={styles.IA_colorThemePreview}
          style={{
            color: this.props.textColor,
            backgroundColor: this.props.backgroundColor
          }}
        >
          Abc
        </div>
        <IAColorPicker
          key="IA_themeBackgroundColor"
          label={this.localizationService.strings.PlayAdmin_BackgroundColor}
          color={this.props.backgroundColor}
          swatches={this.props.swatches}
          allowAddingToSwatches={this.props.allowAddingToSwatches}
          toolTip={this.props.toolTip}
          inTeams={this.props.inTeams}
          addSwatch={(color: string) => this.props.addSwatch(color)}
          onChange={(backgroundColor: string) => {
            this.props.setBackgroundColor(backgroundColor);
          }}
          darkMode={this.props.darkMode}
          showClearButton={false}
          styles={{
            marginTop: 0,
            float: "left",
            width: "auto",
            marginLeft: 20
          }}
        />
        <IAColorPicker
          key="IA_themeTextColor"
          label={this.localizationService.strings.PlayAdmin_TextColor}
          color={this.props.textColor}
          swatches={this.props.swatches}
          inTeams={this.props.inTeams}
          toolTip={this.props.toolTip}
          disabled={this.props.disableTextColor}
          addSwatch={(color: string) => {
            this.props.setTextColor(color);
            this.props.addSwatch(color);
          }}
          onChange={(textColor: string) => {
            this.props.setTextColor(textColor);
          }}
          darkMode={this.props.darkMode}
          showClearButton={false}
          styles={{
            marginTop: 0,
            float: "left",
            width: "auto",
            marginLeft: 20
          }}
        />
        {this.props.remove &&
          <IAIcon
            title="Close"
            size={18}
            color={this.props.highlightColor}
            style={{
              cursor: "pointer",
              display: "inline",
              marginLeft: 7,
              float: "right"
            }}
            onClick={() => this.props.remove()}
          />
        }
      </div>
    );
  }


}