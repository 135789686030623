exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_personaCard_35rEDEoFwmxsdrOy-cEpFH{\n  display:flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.IA_personaWrapper_3laXYdbiV2ACT94lLlImCF {\n  font-size: 14px;\n  float: left;\n  display: block;\n  width: 100%;\n  padding: 8px 0;\n}\n\n.IA_personaWrapper_3laXYdbiV2ACT94lLlImCF .IA_personaProfileImageWrapper_3_RJVelu1NLiKDwFDu2eA6 {\n  height: 30px;\n  width: 30px;\n  float: left;\n  border-radius: 30px;\n  margin-right: 7px;\n  margin-top: 1px;\n  font-size: 15px;\n  text-align: center;\n  line-height: 29px;\n  text-transform: uppercase;\n  font-weight: 200;\n}\n\n\n.IA_personaSmall_1sV0SK0jv_ojNfrg3tC4GF .IA_personaProfileImageText_DsnT_0M3PXjG4XrdWA44L {\n  height: 30px;\n  width: 30px;\n  border-radius: 30px;\n  left: 0px;\n  top: 0px;\n  position: relative;\n  float: left;\n}\n\n\n.IA_personaUserPrincipalName_1Rv2j8B8xWrm4Y6mipwtcN {\n  font-size: 9px;\n  opacity: 0.8\n}", ""]);

// Exports
exports.locals = {
	"IA_personaCard": "IA_personaCard_35rEDEoFwmxsdrOy-cEpFH",
	"IA_personaWrapper": "IA_personaWrapper_3laXYdbiV2ACT94lLlImCF",
	"IA_personaProfileImageWrapper": "IA_personaProfileImageWrapper_3_RJVelu1NLiKDwFDu2eA6",
	"IA_personaSmall": "IA_personaSmall_1sV0SK0jv_ojNfrg3tC4GF",
	"IA_personaProfileImageText": "IA_personaProfileImageText_DsnT_0M3PXjG4XrdWA44L",
	"IA_personaUserPrincipalName": "IA_personaUserPrincipalName_1Rv2j8B8xWrm4Y6mipwtcN"
};