import * as React from 'react';
import * as styles from './Attachments.css';
import { Helper } from '../../Helper';
import { IAFileUploaderFile } from '../fileUploader/FileUploader';
import { IAIcon } from '../icon/Icon';

export interface IAAttachmentsProps {
  attachments: IAFileUploaderFile[];
  linkColor?: string;
  target?: "_blank" | "_self";
  style?: React.CSSProperties;
  onClick?: () => void;
}

export interface IAAttachmentsState {
}

export class IAAttachments extends React.Component<IAAttachmentsProps, IAAttachmentsState> {
  constructor(props: IAAttachmentsProps) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <div
        className={styles.IA_AttachmentsContainer}
        style={this.props.style}
      >
        {this.props.attachments && this.props.attachments.map((file, i) => {
          const attachment = <div key={i} className={styles.IA_AttachmentsAttachment}>
              <IAIcon
                url={Helper.getFileIconFromExtension(file.extension).icon}
                size={22}
                color={Helper.getFileIconFromExtension(file.extension).color}
                style={{ marginRight: 10, marginTop: 5 }}
              />
              <div
                onClick={() => this.props.onClick()}
                className={styles.IA_AttachmentsLink}
                style={{ color: this.props.linkColor ? this.props.linkColor : "black" }}
              >
                {file.altText && file.altText?.trim() !== "" ? file.altText : file.name}
              </div>
            </div>

          return (this.props.onClick ?
            <>
              {attachment}
            </>
            :
            <a
              href={file.url}
              target={this.props.target ?? "_self"}
              data-interception="off"
              style={{ textDecoration: "none" }}
              download
            >
              {attachment}
            </a>
          )
        })}
      </div>
    );
  }
}