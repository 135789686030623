import * as React from 'react';
import * as styles from './OptionSelector.css';
import { IAIcon } from '../icon/Icon'
import { Helper } from '../../Helper';
import { IOptionSelectorItem } from '../../interfaces/IOptionSelectorIcon';

export interface IOptionSelectorProps {
  label?: string;
  options: IOptionSelectorItem[];
  selected: string;
  highlightColor: string;
  className?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  compact?: boolean;
  list?: boolean;
  iconSize?: number;
  darkMode?: boolean;
  borderRadius?: number;
  labelStyle?: React.CSSProperties;
  onChange: (option: string) => void;
}

export interface IOptionSelectorState {
  selected: string;
}

export class IAOptionSelector extends React.Component<IOptionSelectorProps, IOptionSelectorState> {

  constructor(props: IOptionSelectorProps) {
    super(props);
    this.state = {
      selected: this.props.selected
    };
  }

  public componentWillReceiveProps(props: IOptionSelectorProps): void {
    this.setState({ selected: props.selected });
  }

  private onChange(selected): void {
    this.setState({ selected });
    this.props.onChange(selected);
  }

  public render(): JSX.Element {
    let options: JSX.Element[] = [];
    this.props.options.forEach((option: IOptionSelectorItem) => {
      if (this.props.list && (option.icon || option.iconUrl) && option.text) {
        options.push(
          <div
            key={`item_${option.key}`}
            onClick={() => this.props.disabled || option.disabled ? {} : this.onChange(option.key)}
            className={styles.IA_listItem}
            style={{
              border: this.state.selected === option.key ? `2px solid ${this.props.highlightColor}` : (this.props.darkMode ? "2px solid #1f1f1f" : "2px solid transparent"),
              backgroundColor: this.props.darkMode ? Helper.darkModeInputFieldBackgroundColor : "#f4f4f4",
              borderRadius: this.props.borderRadius,
              opacity: option.disabled ? 0.5 : 1
            }}
          >
            <div
              className={styles.IA_listItemIcon}
              style={{
                backgroundColor: this.props.darkMode ? "#6b6b6b" : "#e9e9e9"
              }}
            >
              <IAIcon
                title={option.icon}
                url={option.iconUrl}
                size={this.props.iconSize ? this.props.iconSize : 43}
                color={this.state.selected === option.key ? this.props.highlightColor : (this.props.darkMode ? "#ffffff" : "#666666")}
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: 15,
                  marginBottom: 10,
                  textAlign: "center"
                }}
              />
            </div>
            <div
              className={styles.IA_listItemText}
              style={{
                color: this.state.selected === option.key ? this.props.highlightColor : (this.props.darkMode ? "#ffffff" : "#666666")
              }}>
              {option.text}
            </div>
          </div>
        );
      } else {
        options.push(
          <div
            key={`imageSelectorItem_${option.key}`}
            onClick={() => this.props.disabled || option.disabled ? {} : this.onChange(option.key)}
            className={this.props.compact ? styles.IA_imageSelectorItemCompact : styles.IA_imageSelectorItem}
            style={{
              opacity: option.disabled ? 0.5 : 1,
              borderRadius: this.props.borderRadius,
              width: option.icon || option.iconUrl ? "calc(50% - 10px)" : 60,
              minHeight: option.icon || option.iconUrl ? 85 : "unset"
            }}
          >
            {option.image && <div
              className={this.props.compact ? styles.IA_imageSelectorItemImageCompact : styles.IA_imageSelectorItemImage}
              style={{
                backgroundImage: `url('${option.image}')`,
                border: this.state.selected === option.key ? `2px solid ${this.props.highlightColor}` : (this.props.darkMode ? "2px solid #414141" : "2px solid #f4f4f4"),
                borderRadius: this.props.borderRadius,
              }}
            />}
            {(option.icon || option.iconUrl) && <div
              className={this.props.compact ? styles.IA_imageSelectorItemIconCompact : styles.IA_imageSelectorItemIcon}
              style={{
                border: this.state.selected === option.key ? `2px solid ${this.props.highlightColor}` : (this.props.darkMode ? "2px solid #414141" : "2px solid #f4f4f4"),
                backgroundColor: this.props.darkMode ? Helper.darkModeInputFieldBackgroundColor : "#f4f4f4",
                borderRadius: this.props.borderRadius
              }}>
              <IAIcon
                title={option.icon}
                url={option.iconUrl}
                size={this.props.iconSize ? this.props.iconSize : 43}
                color={this.state.selected === option.key ? this.props.highlightColor : (this.props.darkMode ? "#ffffff" : "#666666")}
                style={{
                  marginBottom: this.props.compact ? 5: 10,
                  display: "block",
                  textAlign: "center"
                }}
              />
              {option.text != undefined && <div
                className={this.props.compact ? styles.IA_imageSelectorItemLabelIconCompact : styles.IA_imageSelectorItemLabelIcon}
                style={{
                  color: this.state.selected === option.key ? this.props.highlightColor : (this.props.darkMode ? "#ffffff" : "#666666")
                }}>
                {option.text}
              </div>
              }
            </div>}
            {!(option.icon || option.iconUrl) && option.text != undefined &&
              <div
                className={this.props.compact ? styles.IA_imageSelectorItemLabelCompact : styles.IA_imageSelectorItemLabel}
                style={{ color: this.state.selected === option.key ? this.props.highlightColor : (this.props.darkMode ? Helper.darkModeLabelColor : "#666666") }}
              >
                {option.text}
              </div>
            }
          </div>
        );
      }
    })
    return (
      <div
        className={(this.props.darkMode ? styles.IA_optionSelectorDarkMode : styles.IA_optionSelector) + " " + (!this.props.className ? this.props.className : "")}
        style={this.props.style}
      >
        {this.props.label &&
          <label style={this.props.labelStyle}>{this.props.label}</label>
        }
        <div
          className={styles.IA_selections}
          style={{
            opacity: this.props.disabled ? 0.5 : 1
          }}
        >
          {options}
        </div>
      </div>
    );
  }


}