import * as React from 'react';
import { Store } from '../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './ArticleEditPanel.css';
import { IAPanel } from '../../../../panels/Panel';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IPlayArticle, IPlayBoxStyle, IPlaySlideExtended, IPlaySlideLayout, IPlayWidget, IPlayWidgetExtended } from '../../../../../interfaces/IPlay';
import PlayService from '../../../../../services/PlayService';
import { IASpinner } from '../../../../spinner/Spinner';
import { Slide } from '../../../../player/slide/Slide';
import { IAButton } from '../../../../button/Button';
import { Helper } from '../../../../../Helper';
import { IACheckbox } from '../../../../checkbox/Checkbox';
import { PlayHelper } from '../../../PlayHelper';
import { Popup } from '../../../reusableComponents/popup/Popup';
import { RssTicker } from '../../../../player/rssTicker/RssTicker';
import { Footer } from '../../../../player/footer/Footer';
import { PreviewSettings } from '../../../reusableComponents/previewSettings/PreviewSettings';
import { IAGroup } from '../../../../group/Group';
import { IATextField } from '../../../../textField/TextField';
import { IAToggle } from '../../../../toggle/Toggle';
import { MediaFileSelector } from '../../mediaFiles/mediaFileSelector/MediaFileSelector';
import { IACard } from '../../../../card/Card';
import { ArticleText } from './ArticleText';
import { IADateAndTimePicker } from '../../../../dateAndTimePicker/DateAndTimePicker';
import { IAColorTheme } from '../../../../colorTheme/ColorTheme';
import { RotatorViewSelector } from '../../rotatorViewSelector/RotatorViewSelector';
import { IADropDown } from '../../../../dropDown/DropDown';

export interface IProps {
  id?: string;
  articleId: string;
  store?: Store;
  showWidgetsSelector?: boolean;
  isOpen: boolean;
  close: () => void;
  onSave: () => void;
}

export interface IState {
  isSaving: boolean;
  displayLayout: IPlaySlideLayout;
  isLoadingMore: boolean;
  widgets: IPlayWidget[];
  widgetsCount: number;
  showConfirmClosePopup: boolean;
  showFooter?: boolean;
  showRssTicker?: boolean;


  newImageUrl: string;
  isTyping: any;
  text: any;
}

@inject('store')
@observer
export class ArticleEditPanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();
  private readonly perPage: number = 100;

  constructor(props: IProps) {
    super(props);
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());

    const ArticlePreviewSettings = localStorage.getItem(`IA_articlePreviewSettings_${props.articleId}`)
    this.state = {
      isSaving: false,
      displayLayout: ArticlePreviewSettings ? JSON.parse(ArticlePreviewSettings).displayLayout : "oneColumn",
      showFooter: ArticlePreviewSettings ? JSON.parse(ArticlePreviewSettings).showFooter : false,
      showRssTicker: ArticlePreviewSettings ? JSON.parse(ArticlePreviewSettings).showRssTicker : false,
      isLoadingMore: false,
      widgets: [],
      widgetsCount: undefined,
      showConfirmClosePopup: false,
      newImageUrl: undefined,
      isTyping: undefined,
      text: undefined,
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    this.props.showWidgetsSelector && this.state.widgets?.length < this.perPage && props.isOpen && this.loadArticleViewers();
    if (this.props.articleId !== props.articleId && props.articleId != undefined && props.articleId !== "new") {
      const ArticlePreviewSettings = localStorage.getItem(`IA_articlePreviewSettings_${props.articleId}`)
      this.setState({
        displayLayout: ArticlePreviewSettings ? JSON.parse(ArticlePreviewSettings).displayLayout : "oneColumn",
        showFooter: ArticlePreviewSettings ? JSON.parse(ArticlePreviewSettings).showFooter : false,
        showRssTicker: ArticlePreviewSettings ? JSON.parse(ArticlePreviewSettings).showRssTicker : false,
      });
      PlayService.getArticle(props.store.environment, this.props.store.token, props.store.tenantId, this.props.store.profile?.id, props.articleId).then((article: IPlayArticle) => {
        this.props.store.articleToEdit = { ...article, widgetsToAddTo: [], widgetsToRemoveFrom: [] };
        this.props.store.articleToEditBeforeChanged = JSON.stringify(this.props.store.articleToEdit);
        this.forceUpdate();
      }).catch(e => {
        this.props.store.workingOnIt = false;
        this.props.store.generalErrorPopup = {
          technicalDetails: e
        };
      });
    }
  }

  public componentWillUnmount(): void {
    this.props.store.widgetsCount = undefined;
    this.props.store.widgets = undefined;
  }

  private loadArticleViewers() {
    this.setState({ isLoadingMore: true })
    const currentPage = Math.ceil(this.state.widgets?.length / this.perPage) || 0;
    PlayService.getWidgets(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, "articleViewer",
      {
        skip: currentPage * this.perPage,
        take: this.perPage,
        sortBy: "title"
      })
      .then(result => {
        if (!this.state.widgetsCount) {
          this.setState({ widgetsCount: result.count })
        }
        this.setState({ widgets: [...this.state.widgets, ...result.value], isLoadingMore: false })
      }).catch(e => {
        this.props.store.workingOnIt = false;
        this.props.store.generalErrorPopup = {
          technicalDetails: e
        };
      });
  }

  public render(): JSX.Element {
    let previewWidth = this.props.store.windowWidth - 480;
    let previewHeight = (previewWidth / 16) * 9;

    // resize if the window height is smaller than the preview height
    if (previewHeight > window.innerHeight - 84) {
      previewHeight = window.innerHeight - 84 - 80;
      previewWidth = (previewHeight / 9) * 16;
    }
    // Portrait mode
    if (this.state.displayLayout === "portraitOneRow" || this.state.displayLayout === "portraitTwoRows") {
      previewHeight = window.innerHeight - 84 - 80;
      previewWidth = (previewHeight / 16) * 9;

    }

    const articleViewer: IPlayWidgetExtended = {
      id: this.props.store.articleToEdit?.id,
      type: "articleViewer",
      articleViewer: {
        articles: [this.props.store.articleToEdit],
        duration: 30,
        transition: "none",
        articleEditMode: true
      }
    }

    const previewSlide: IPlaySlideExtended = {
      id: "articleEditPanelPreviewSlide",
      showFooter: this.state.showFooter,
      showRssTicker: this.state.showRssTicker,
      startDate: new Date().toJSON(),
      widgets: [
        articleViewer,
        articleViewer,
        articleViewer,
        articleViewer
      ],
      layout: this.state.displayLayout
    }

    return (
      <IAPanel
        open={this.props.isOpen}
        darkMode={this.props.store.darkMode}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        loading={this.state.isSaving}
        width={this.props.store.windowWidth}
        dataAutomationIdPrefix="article-edit-panel"
        panelId={`article-edit-panel-from-${this.props.id}`}
        isInTeams={this.props.store.isTeams}
        navigationsBarContent={
          <IAButton
            darkMode={this.props.store.darkMode}
            label={this.props.store.articleToEdit?.id && this.props.store.articleToEdit?.id !== "new" ? this.localizationService.strings.PlayAdmin_Update?.toUpperCase() : this.localizationService.strings.PlayAdmin_Save?.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            disbaled={(!this.props.store.articleToEdit?.title)}
            onClick={() => {
              this.setState({ widgets: [], widgetsCount: undefined })
              if (!this.props.articleId) {
                const articlePreviewSettingsObject = {
                  displayLayout: this.state.displayLayout
                }
                localStorage.setItem(`IA_articlePreviewSettings_undefined`, JSON.stringify(articlePreviewSettingsObject));
              }
              this.props.onSave();
            }}
            borderRadius={5}
            showSpinner={this.props.store.workingOnIt}
            style={{
              float: "right",
              position: "relative",
              marginTop: 7,
              marginRight: 10
            }}
          />
        }
        close={() => {
          if (this.props.store.articleToEditBeforeChanged === JSON.stringify(this.props.store.articleToEdit)) {
            this.setState({ widgets: [], widgetsCount: undefined, showConfirmClosePopup: false });
            this.props.close();
          } else {
            this.setState({ showConfirmClosePopup: true });
          }
        }}
      >
        {this.state.showConfirmClosePopup &&
          <Popup
            headline={this.localizationService.strings.Messages_CancelPopup_Headline}
            close={() => this.setState({ showConfirmClosePopup: false })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_Yes?.toUpperCase()}
                  onClick={() => {
                    this.setState({ widgets: [], widgetsCount: undefined, showConfirmClosePopup: false });
                    this.props.close();
                  }}
                  borderRadius={5}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_No?.toUpperCase()}
                  onClick={() => this.setState({ showConfirmClosePopup: false })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
        {this.props.store.articleToEdit ?
          <div
            className={styles.IA_articleEditPanel}
            style={{
              height: window.innerHeight - 45,
              backgroundColor: this.props.store.darkMode ? "#414141" : "#777777",
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
          >
            <div className={styles.IA_content} style={{ backgroundColor: this.props.store.darkMode ? Helper.darkModeBackgroundColor : '#fff' }}>
              {this.props.store.articleToEdit.boxStyle &&
                <>
                  <h1 style={{ marginLeft: 40, color: this.props.store.darkMode ? "#ffffff" : '#333333' }}>{this.localizationService.strings.PlayAdmin_ArticleSettings}</h1>
                  <IAGroup
                    label={this.localizationService.strings.PlayAdmin_Content?.toUpperCase()}
                    darkMode={this.props.store.darkMode}
                  >
                    {/* TITLE */}
                    <IATextField
                      label={this.localizationService.strings.PlayAdmin_Title?.toUpperCase()}
                      placeholder={this.localizationService.strings.PlayAdmin_MissionTitle}
                      text={this.props.store.articleToEdit.title}
                      darkMode={this.props.store.darkMode}
                      highlightColor={this.props.store.highlightColor}
                      required
                      borderRadius={5}
                      labelStyle={{
                        fontSize: "12px"
                      }}
                      onChange={(title: string) => {
                        this.props.store.articleToEdit.title = title;
                        this.forceUpdate();
                      }}
                    />
                    {this.props.store.articleToEdit.boxStyle !== "onlyMedia" &&
                      <IAToggle
                        style={{
                          marginTop: 20,
                          width: "100%",
                          clear: 'both',
                          float: "left"
                        }}
                        label={this.localizationService.strings.PlayAdmin_MessagesViewerShowTitle?.toUpperCase()}
                        checked={!!this.props.store.articleToEdit.showTitle}
                        color={this.props.store.highlightColor}
                        darkMode={this.props.store.darkMode}
                        labelStyle={{
                          fontSize: 12
                        }}
                        onChange={() => {
                          this.props.store.articleToEdit.showTitle = !!!this.props.store.articleToEdit.showTitle;
                          this.forceUpdate();
                        }}
                      />
                    }
                    {/* IMAGE */}
                    <MediaFileSelector
                      url={this.props.store.articleToEdit.imageUrl}
                      label={this.localizationService.strings.PlayAdmin_Image?.toUpperCase()}
                      placeholder={this.localizationService.strings.PlayAdmin_ImageUrl}
                      type={"image"}
                      disabled={!((this.props.store.articleToEdit.video == undefined || this.props.store.articleToEdit.video == "") && this.props.store.articleToEdit.galleryImages?.length === 0)}
                      onChange={(imageUrl: string) => {
                        this.props.store.articleToEdit.imageUrl = imageUrl;
                        this.forceUpdate();
                        setTimeout(() => {
                          this.forceUpdate();
                        }, 0);
                      }}
                    />
                    {/* GALLERY */}
                    <div style={{
                      float: "left",
                      width: "100%",
                      alignItems: "end",
                    }}>
                      <MediaFileSelector
                        url={this.state.newImageUrl}
                        label={this.localizationService.strings.PlayAdmin_GalleryImages?.toUpperCase()}
                        placeholder={this.localizationService.strings.PlayAdmin_ImageUrl}
                        type={"image"}
                        disabled={!(this.props.store.articleToEdit.video == undefined || this.props.store.articleToEdit.video == "")}
                        enableMultiple
                        onChange={(newImageUrl: string) => {
                          try {
                            // only add valid urls
                            const url = new URL(newImageUrl);
                            if (url.protocol === "http:" || url.protocol === "https:") {
                              if (this.props.store.articleToEdit.galleryImages) {
                                this.props.store.articleToEdit.galleryImages.push(newImageUrl);
                              } else {
                                this.props.store.articleToEdit.galleryImages = [newImageUrl];
                              }
                              this.setState({ newImageUrl: undefined });
                              this.forceUpdate();
                            }
                          } catch (_) {
                            return;
                          }
                        }}
                      />
                    </div>
                    {this.props.store.articleToEdit.galleryImages &&
                      <div style={{ paddingTop: this.props.store.articleToEdit.galleryImages?.length > 0 ? 20 : 0, display: "flex", flexWrap: "wrap", justifyContent: "space-between", width: "100%" }}>
                        {this.props.store.articleToEdit.galleryImages.map((imageUrl, index) => {
                          const isFirstElement = index === 0;
                          const isLastElement = index === this.props.store.articleToEdit.galleryImages.length - 1;
                          return <IACard
                            key={`image${imageUrl}${index}`}
                            id={imageUrl}
                            darkMode={this.props.store.darkMode}
                            cover={{
                              imageURL: imageUrl
                            }}
                            isMobile={this.props.store.isMobile}
                            alwaysShowFooter
                            footerElements={[
                              {
                                color: isLastElement ? this.props.store.highlightColor + "80" : this.props.store.highlightColor,
                                iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Chevron%20Down/SVG/ic_fluent_chevron_down_48_regular.svg",
                                onClick: () => {
                                  if (!isLastElement) {
                                    this.props.store.articleToEdit.galleryImages.splice(index + 1, 0, this.props.store.articleToEdit.galleryImages.splice(index, 1)[0]);
                                    this.forceUpdate();
                                  }
                                }
                              },
                              {
                                color: isFirstElement ? this.props.store.highlightColor + "80" : this.props.store.highlightColor,
                                iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Chevron%20Up/SVG/ic_fluent_chevron_up_48_regular.svg",
                                onClick: () => {
                                  if (!isFirstElement) {
                                    this.props.store.articleToEdit.galleryImages.splice(index - 1, 0, this.props.store.articleToEdit.galleryImages.splice(index, 1)[0]);
                                    this.forceUpdate();
                                  }
                                }
                              },
                              {
                                color: this.props.store.highlightColor,
                                icon: "Trash",
                                onClick: () => {
                                  this.props.store.articleToEdit.galleryImages.splice(index, 1);
                                  this.forceUpdate();
                                }
                              }
                            ]}
                            width={300}
                            styles={{
                              marginBottom: 20,
                              color: this.props.store.darkMode ? "#ffffff" : "#333333",
                              width: 300
                            }}
                            design={{
                              highlightColor: this.props.store.highlightColor,
                              showDropShadow: true,
                              borderRadius: 5,
                              backgroundColor: this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"
                            }}
                          />;
                        })}
                      </div>
                    }
                    {/* VIDEO */}
                    <MediaFileSelector
                      label={this.localizationService.strings.PlayAdmin_VideoUrl?.toUpperCase()}
                      url={this.props.store.articleToEdit.video}
                      placeholder={this.localizationService.strings.PlayAdmin_VideoUrl}
                      type={"video"}
                      onChange={(videoUrl: string) => {
                        this.props.store.articleToEdit.video = Helper.convertUrlToEmbedUrl(videoUrl);
                        this.forceUpdate();
                      }}
                    />
                    {/* <IAToggle
                  style={{
                    marginTop: 20,
                    width: "100%",
                    clear: 'both',
                    float: "left"
                  }}
                  label={this.localizationService.strings.PlayAdmin_ShowOriginalImage?.toUpperCase()}
                  checked={!!this.props.store.articleToEdit.keepOriginalImage}
                  color={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  labelStyle={{
                    fontSize: 12
                  }}
                  onChange={() => {
                    this.props.store.articleToEdit.keepOriginalImage = !!!this.props.store.articleToEdit.keepOriginalImage;
                    this.forceUpdate();
                  }}
                /> */}
                    {/* Content */}
                    <div style={{ fontSize: "12px", float: "left", marginTop: 20, marginBottom: 10, color: this.props.store.darkMode ? Helper.darkModeLabelColor : 'rgb(51, 51, 51)' }}>{this.localizationService.strings.PlayAdmin_MenuArea_Content?.toUpperCase()}</div>
                    {this.props.store.articleToEdit.textContent.map((text, i) => {
                      return (
                        <ArticleText
                          key={`articletext_${i}`}
                          id={`articletext_${i}`}
                          text={text}
                          hideToolbox={false}
                          onChange={(text: string) => {
                            this.props.store.articleToEdit.textContent[i] = text;
                            if (this.state.isTyping !== undefined) {
                              clearTimeout(this.state.isTyping);
                            }
                            this.setState({
                              isTyping: setTimeout(() => {
                                this.forceUpdate();
                                this.setState({ isTyping: undefined });
                              }, 500)
                            });
                          }}
                          onTableSettingsChange={(showTableBorder: boolean) => {
                            this.props.store.articleToEdit.showTableBorder = showTableBorder;
                            this.forceUpdate();
                          }}
                        />
                      );
                    })}
                    {/* Start and end times */}
                    <IADateAndTimePicker
                      label={this.localizationService.strings.Messages_EditPanel_StartTime?.toUpperCase()}
                      selectedDate={new Date(this.props.store.articleToEdit.startDate)}
                      showTimeSelector={true}
                      highlightColor={this.props.store.highlightColor}
                      isClearable={false}
                      required={true}
                      darkMode={this.props.store.darkMode}
                      borderRadius={5}
                      onChange={(startDate: Date) => {
                        this.props.store.articleToEdit.startDate = startDate.toJSON();
                      }}
                      style={{
                        marginTop: 20,
                        width: "100%",
                        fontSize: 12
                      }}
                      inputStyle={{
                        color: this.props.store.articleToEdit?.startDate && new Date(this.props.store.articleToEdit?.startDate) > new Date() ? this.props.store.highlightColor : (this.props.store.darkMode ? "#ffffff" : "#000000")
                      }}
                    />
                    <IADateAndTimePicker
                      label={this.localizationService.strings.Messages_EditPanel_EndTime?.toUpperCase()}
                      selectedDate={this.props.store.articleToEdit.endDate ? new Date(this.props.store.articleToEdit.endDate) : undefined}
                      showTimeSelector={true}
                      highlightColor={this.props.store.highlightColor}
                      isClearable={true}
                      darkMode={this.props.store.darkMode}
                      borderRadius={5}
                      onChange={(endDate: Date | undefined) => {
                        this.props.store.articleToEdit.endDate = endDate?.toJSON() ?? null;
                      }}
                      style={{
                        marginTop: 20,
                        width: "100%",
                        fontSize: 12
                      }}
                      inputStyle={{
                        color: this.props.store.articleToEdit?.endDate && new Date(this.props.store.articleToEdit?.endDate) < new Date() ? "#ee4545" : (this.props.store.darkMode ? "#ffffff" : "#000000")
                      }}
                    />
                    {this.props.store.articleToEdit.boxStyle !== "onlyMedia" &&
                      <IAToggle
                        style={{
                          marginTop: 20,
                          width: "100%",
                          clear: 'both',
                          float: "left"
                        }}
                        label={this.localizationService.strings.PlayAdmin_MessagesViewerShowDate?.toUpperCase()}
                        checked={!!this.props.store.articleToEdit.showDate}
                        color={this.props.store.highlightColor}
                        darkMode={this.props.store.darkMode}
                        labelStyle={{
                          fontSize: 12
                        }}
                        onChange={() => {
                          this.props.store.articleToEdit.showDate = !!!this.props.store.articleToEdit.showDate;
                          this.forceUpdate();
                        }}
                      />
                    }
                    {/* QR link */}
                    <IATextField
                      label={this.localizationService.strings.PlayAdmin_QRCode?.toUpperCase()}
                      placeholder={this.localizationService.strings.PlayAdmin_Link}
                      text={this.props.store.articleToEdit.link}
                      darkMode={this.props.store.darkMode}
                      highlightColor={this.props.store.highlightColor}
                      borderRadius={5}
                      labelStyle={{
                        fontSize: "12px"
                      }}
                      onChange={(link: string) => {
                        this.props.store.articleToEdit.link = link;
                        this.forceUpdate();
                      }}
                    />
                    <IATextField
                      placeholder={this.localizationService.strings.PlayAdmin_LinkText}
                      text={this.props.store.articleToEdit.linkText}
                      darkMode={this.props.store.darkMode}
                      highlightColor={this.props.store.highlightColor}
                      borderRadius={5}
                      labelStyle={{
                        fontSize: "12px"
                      }}
                      inputFieldStyle={{
                        marginTop: 5
                      }}
                      onChange={(text: string) => {
                        this.props.store.articleToEdit.linkText = text;
                        this.forceUpdate();
                      }}
                    />
                  </IAGroup>
                  <IAGroup
                    label={this.localizationService.strings.PlayAdmin_ArticleLayoutType.toUpperCase()}
                    darkMode={this.props.store.darkMode}
                  >
                    {/* Font size */}
                    <IADropDown
                      label={this.localizationService.strings.PlayAdmin_FontSize.toUpperCase()}
                      selectedOption={this.props.store.articleToEdit.fontSize ?? "small"}
                      options={[
                        {
                          key: "small",
                          value: this.localizationService.strings.PlayAdmin_FontSizeSmall
                        },
                        {
                          key: "medium",
                          value: this.localizationService.strings.PlayAdmin_FontSizeStandard
                        },
                        {
                          key: "large",
                          value: this.localizationService.strings.PlayAdmin_FontSizeLarge
                        }
                      ]}
                      onChange={(fontSize) => {
                        this.props.store.articleToEdit.fontSize = fontSize;
                        this.forceUpdate();
                      }}
                      style={{
                        clear: "none",
                        float: "left",
                        marginRight: 20,
                        width: "100%"
                      }}
                    />
                    {/* Colors */}
                    <label
                      style={{
                        color: this.props.store.darkMode ? Helper.darkModeLabelColor : '#333333',
                        marginTop: 15,
                        float: "left"
                      }}
                    >
                      {this.localizationService.strings.PlayAdmin_ColorTheme?.toUpperCase()}
                    </label>
                    <IAColorTheme
                      backgroundColor={this.props.store.articleToEdit.colorTheme.backgroundColor}
                      textColor={this.props.store.articleToEdit.colorTheme.textColor}
                      highlightColor={this.props.store.highlightColor}
                      darkMode={this.props.store.darkMode}
                      swatches={this.props.store.profile?.swatches}
                      inTeams
                      remove={undefined}
                      allowAddingToSwatches={false}
                      setBackgroundColor={(backgroundColor) => {
                        this.props.store.articleToEdit.colorTheme.backgroundColor = backgroundColor;
                        this.forceUpdate();
                      }}
                      setTextColor={(textColor) => {
                        this.props.store.articleToEdit.colorTheme.textColor = textColor;
                        this.forceUpdate();
                      }}
                    />
                    <RotatorViewSelector
                      layoutType='slide'
                      boxStyle={this.props.store.articleToEdit?.boxStyle}
                      backgroundColor={this.props.store.articleToEdit?.colorTheme?.backgroundColor}
                      textColor={this.props.store.articleToEdit?.colorTheme?.textColor}
                      image={this.props.store.articleToEdit?.imageUrl}
                      video={this.props.store.articleToEdit?.video}
                      galleryImages={this.props.store.articleToEdit?.galleryImages}
                      contentPosition={this.props.store.articleToEdit.contentPosition}
                      contentVerticalPosition={this.props.store.articleToEdit.contentVerticalPosition}
                      keepOriginalImage={this.props.store.articleToEdit.keepOriginalImage}
                      boxStyleRoundCorner={this.props.store.articleToEdit.boxStyleRoundCorner}
                      contentWidth={this.props.store.articleToEdit.contentWidth}
                      updateLayout={() => { }}
                      updateBoxStyle={(boxStyle: IPlayBoxStyle) => {
                        this.props.store.articleToEdit.boxStyle = boxStyle;
                        this.forceUpdate();
                      }}
                      updateContentPosition={(contentPosition: "left" | "right" | "random") => {
                        this.props.store.articleToEdit.contentPosition = contentPosition;
                        this.forceUpdate();
                      }}
                      updateContentVerticalPosition={(contentVerticalPosition: "top" | "bottom") => {
                        this.props.store.articleToEdit.contentVerticalPosition = contentVerticalPosition;
                        this.forceUpdate();
                      }}
                      updateKeepOriginalImage={(keepOriginalImage: boolean) => {
                        this.props.store.articleToEdit.keepOriginalImage = keepOriginalImage;
                        this.forceUpdate();
                      }}
                      updateBoxStyleRoundCorner={(boxStyleRoundCorner: boolean) => {
                        this.props.store.articleToEdit.boxStyleRoundCorner = boxStyleRoundCorner;
                        this.forceUpdate();
                      }}
                      updateContentWidth={(contentWidth: number) => {
                        this.props.store.articleToEdit.contentWidth = contentWidth;
                        this.forceUpdate();

                      }}
                    />
                  </IAGroup>
                </>
              }
              <IAGroup
                label={this.localizationService.strings.PlayAdmin_PublishToArticleViewer?.toUpperCase()}
                darkMode={this.props.store.darkMode}
              >
                {this.props.showWidgetsSelector && this.props.store.articleToEdit &&
                  <div className={`${styles.IA_widgetsSelector} ${this.props.store.darkMode ? styles.dark : ''}`}>
                    {
                      this.state.widgets?.map((widget) => {
                        // disable the checkbox if we are editing an article with the new layout,
                        // and the article viewer is from before the launch of the new article layout (because of no backwards compatibility)
                        const disableForOld = !this.props.store.articleToEdit.boxStyle && widget.created && new Date(widget.created) > PlayHelper.NEW_ARTICLES_LAUNCH_DATE;
                        if (!disableForOld) {
                          return <div
                            key={widget.id}
                            className={styles.IA_widgetsSelectorRow}
                          >
                            <div>
                              {widget.title}
                            </div>
                            <div
                              style={{ display: "flex" }}
                            >
                              <IACheckbox
                                highlightColor={this.props.store.highlightColor}
                                isChecked={(widget.articleViewer?.articles?.some(articleId => articleId === this.props.store.articleToEdit.id) || this.props.store.articleToEdit.widgetsToAddTo.some(widgetId => widget.id === widgetId)) && this.props.store.articleToEdit.widgetsToRemoveFrom.every(widgetId => widgetId !== widget.id)}
                                onChange={(isChecked) => {
                                  if (isChecked) {
                                    widget.articleViewer.articles.every(widgetId => widgetId != this.props.store.articleToEdit.id) && this.props.store.articleToEdit.widgetsToAddTo.push(widget.id);
                                    this.props.store.articleToEdit.widgetsToRemoveFrom = [...this.props.store.articleToEdit.widgetsToRemoveFrom.filter(widgetId => widgetId !== widget.id)];
                                  }
                                  else {
                                    widget.articleViewer.articles.some(articleId => articleId === this.props.store.articleToEdit.id) && this.props.store.articleToEdit.widgetsToRemoveFrom.push(widget.id);
                                    this.props.store.articleToEdit.widgetsToAddTo = [...this.props.store.articleToEdit.widgetsToAddTo.filter(widgetId => widgetId !== widget.id)];
                                  }
                                }}
                              />
                            </div>
                          </div>
                        }
                      })
                    }
                    {
                      this.state.isLoadingMore && <div>
                        <IASpinner color={this.props.store.highlightColor} />
                      </div>
                    }
                    {
                      this.state.widgets?.length < this.state.widgetsCount && <div className={styles.IA_widgetsSelectorLoadMore}>
                        <IAButton
                          label='Load more'
                          darkMode={this.props.store.darkMode}
                          buttonColor={this.props.store.highlightColor}
                          onClick={() => this.loadArticleViewers()}
                        />
                      </div>
                    }

                  </div>
                }
              </IAGroup>
            </div>
            <div className={styles.IA_previewArea}>
              <PreviewSettings
                displayLayout={this.state.displayLayout}
                showFooter={this.state.showFooter}
                showRssTicker={this.state.showRssTicker}
                onLayoutChange={(layout: IPlaySlideLayout) => {
                  this.setState({ displayLayout: layout }, () => {
                    const ArticlePreviewSettings = localStorage.getItem(`IA_articlePreviewSettings_${this.props.articleId}`);
                    let articlePreviewSettingsObject;
                    if (ArticlePreviewSettings) {
                      articlePreviewSettingsObject = JSON.parse(ArticlePreviewSettings);
                      articlePreviewSettingsObject.displayLayout = this.state.displayLayout;
                    } else {
                      articlePreviewSettingsObject = {
                        displayLayout: this.state.displayLayout
                      }
                    }
                    localStorage.setItem(`IA_articlePreviewSettings_${this.props.articleId}`, JSON.stringify(articlePreviewSettingsObject));
                    this.forceUpdate();
                  });
                }}
                onShowFooterChange={() => {
                  this.setState({ showFooter: !this.state.showFooter }, () => {
                    const ArticlePreviewSettings = localStorage.getItem(`IA_articlePreviewSettings_${this.props.articleId}`);
                    let articlePreviewSettingsObject;
                    if (ArticlePreviewSettings) {
                      articlePreviewSettingsObject = JSON.parse(ArticlePreviewSettings);
                      articlePreviewSettingsObject.showFooter = this.state.showFooter;
                    } else {
                      articlePreviewSettingsObject = {
                        showFooter: this.state.showFooter
                      }
                    }
                    localStorage.setItem(`IA_articlePreviewSettings_${this.props.articleId}`, JSON.stringify(articlePreviewSettingsObject));
                    this.forceUpdate();
                  });
                }}
                onShowRSSChange={() => {
                  this.setState({ showRssTicker: !this.state.showRssTicker }, () => {
                    const ArticlePreviewSettings = localStorage.getItem(`IA_articlePreviewSettings_${this.props.articleId}`);
                    let articlePreviewSettingsObject;
                    if (ArticlePreviewSettings) {
                      articlePreviewSettingsObject = JSON.parse(ArticlePreviewSettings);
                      articlePreviewSettingsObject.showRssTicker = this.state.showFooter;
                    } else {
                      articlePreviewSettingsObject = {
                        showRssTicker: this.state.showRssTicker
                      }
                    }
                    localStorage.setItem(`IA_articlePreviewSettings_${this.props.articleId}`, JSON.stringify(articlePreviewSettingsObject));
                    this.forceUpdate();
                  });
                }}
              />
              <div
                className={styles.IA_preview}
                style={{
                  width: previewWidth,
                  height: previewHeight,
                  paddingLeft: this.state.displayLayout === "portraitOneRow" || this.state.displayLayout === "portraitTwoRows" ? `calc(50% - ${previewWidth / 2}px)` : 40
                }}
              >
                <div
                  className={styles.IA_previewScreen}
                  style={{
                    height: previewHeight,
                    width: previewWidth
                  }}
                >
                  {this.state.showRssTicker &&
                    <RssTicker
                      rssTicker={{}}
                      editMode={true}
                      isActive={true}
                      sizeUnit={(previewHeight > previewWidth ? previewWidth : previewHeight) / 100}
                      playerWidth={previewWidth}
                      headlineFont={this.props.store.profile?.fonts?.headline ? JSON.parse(this.props.store.profile?.fonts?.headline).name : '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'}
                      contentFont={this.props.store.profile?.fonts?.body ? JSON.parse(this.props.store.profile?.fonts?.body).name : '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'}
                      bottom={this.state.showFooter ? ((previewHeight > previewWidth ? previewWidth : previewHeight) / 100) * 8 : 0}

                    />
                  }
                  {this.state.showFooter &&
                    <Footer
                      footer={this.props.store.profile?.footer}
                      sizeUnit={(previewHeight > previewWidth ? previewWidth : previewHeight) / 100}
                      city={this.props.store.city}
                      currentWeather={this.props.store.weatherData?.current}
                      editMode={true}
                    />
                  }
                  <Slide
                    index={0}
                    slide={previewSlide}
                    isActive={true}
                    height={previewHeight}
                    width={previewWidth}
                    left={0}
                    footer={undefined}
                    city={this.props.store.city}
                    environment={this.props.store.environment}
                    tenantId={this.props.store.tenantId}
                    profileId={this.props.store.profile.id}
                    headlineFont={this.props.store.profile?.fonts?.headline ? JSON.parse(this.props.store.profile?.fonts?.headline).name : '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'}
                    contentFont={this.props.store.profile?.fonts?.body ? JSON.parse(this.props.store.profile?.fonts?.body).name : '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'}
                    playerId='admin'
                    editPreviewMode
                    showActivityLabel
                    standalone
                    forecast={this.props.store.weatherData}
                    nextSlide={() => { }}
                    rssTicker={this.state.showRssTicker ? {} : undefined}
                    isHardcodedWeatherData={this.props.store.isUsingHardCodedWeather}
                  />
                </div>
              </div>
            </div>
          </div>
          :
          <div
            className={styles.IA_loading}
            style={{
              backgroundColor: this.props.store.darkMode ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.5)"
            }}
          >
            <IASpinner
              color={this.props.store.highlightColor}
            />
            <div className={styles.IA_weAreWorkingOnIt}>{this.localizationService.strings.Composer_WeAreWorkingOnIt}</div>
          </div>
        }
      </IAPanel>
    );
  }


}