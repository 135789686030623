import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import { IADropDown } from '../../../../../dropDown/DropDown';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IFacebookPageIntegration, IPlayProfile } from '../../../../../../interfaces/IPlay';
import { IAButton } from '../../../../../button/Button';
import { Environment } from '../../../../../../services/Environment';
import PlayService from '../../../../../../services/PlayService';
import * as styles from '../IntegrationsEditPanel.css';
import { IACheckbox } from '../../../../../checkbox/Checkbox';
import { Helper } from 'intraactive-sdk-helper';

export interface IProps {
  store?: Store;
  profiles: IPlayProfile[];
  isEditingExistingIntegration: boolean;
  onProfileChange: (profile) => void;
  onFacebookFeedChange: (page: IFacebookPageIntegration) => void;
}
export interface FacebookFeedIntegrationComponentState {
  pages: IFacebookPageIntegration[]
}

@inject('store')
@observer
export class FacebookFeedIntegrationComponent extends React.Component<IProps, FacebookFeedIntegrationComponentState> {
  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentDidMount(): void {
    this.getFacebookIntegrations();
  }

  private redirectToFacebookPermissions() {
    window.open(`${Environment.getEnvironmentForPlayFrontend(this.props.store.environment)}?tenantid=${this.props.store.tenantId}&profileid=${this.props.store.integrationToEdit?.profileId}&environment=${this.props.store.environment}&isfacebookpermissions=true`, '_blank')

  }

  private getFacebookIntegrations() {
    if (this.props.store.integrationToEdit?.profileId) {
      PlayService.getFacebookPages(this.props.store.environment, this.props.store.token, this.props.store.tenantId).then((pages) => {
        this.setState({ pages: pages });
      });
    }
  }
  public render(): JSX.Element {
    return (
      <div className={styles.IA_feeds}>
        <IADropDown
          label={this.localizationService.strings.PlayAdmin_SelectAProfileToAccess?.toUpperCase()}
          selectedOption={this.props.store.integrationToEdit?.profileId}
          options={[{
            key: undefined,
            value: this.localizationService.strings.PlayAdmin_SelectAProfileToAccess,
            disabled: true
          },
          ...this.props.profiles.map(profile => { return { key: profile.id, value: profile.title } })
          ]}
          disabled={this.props.isEditingExistingIntegration}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          onChange={(profile) => {
            this.props.onProfileChange(profile);
            this.getFacebookIntegrations();
          }}
          style={{
            float: "left",
            width: 340,
            marginBottom: 15
          }}
          borderRadius={5}
        />
          <IAButton
              borderRadius={5}
              label=""
              onClick={ () => {
               this.getFacebookIntegrations();
              }}
              style={{
                  position: "absolute",
                  right: 30,
                  backgroundColor: "transparent"
              }}
              height={40}
              iconMarginTop={8}
              iconUrl='https://intraactivestorage.blob.core.windows.net/cdn/icons/Arrow Clockwise/SVG/ic_fluent_arrow_clockwise_48_regular.svg'
              buttonColor="#f4f4f4"
              disbaled={!this.props.store.integrationToEdit?.profileId}
            />   
        {this.props.store.integrationToEdit?.profileId && this.state?.pages?.length > 0 &&
          <div>
            <span>{this.localizationService.strings.PlayAdmin_Facebook_SelectPages}:</span>
            {this.state.pages?.map((page) => {
             const id = Helper.getRandomStringKey();
             return (
               <div
                 id={id}
                 className={styles.IA_feed_element}
                 onMouseOver={() => {
                   document.getElementById(id).style.backgroundColor = this.props.store.darkMode ? "#393939" : "#e6e6e6";
                 }}
                 onMouseOut={() => {
                   document.getElementById(id).style.backgroundColor = "";
                 }}
               >
                 <IACheckbox
                key={page.pageId}
                label={page.pageName}
                highlightColor={this.props.store.highlightColor}
                isChecked={this.props.store.integrationToEdit?.facebookIntegrations?.findIndex(w => w.pageId == page.pageId) !== -1}
                onChange={() => this.props.onFacebookFeedChange(page)}
                styles={{
                  padding: 10
                }}
              />
               </div>
             );
            }
            )}
          </div>}

        <IAButton
          disbaled={!this.props.store.integrationToEdit?.profileId}
          darkMode={this.props.store.darkMode}
          buttonColor={this.props.store.highlightColor}
          label={this.localizationService.strings.PlayAdmin_Facebook_Page_Permissions}
          onClick={() => this.redirectToFacebookPermissions()}
          borderRadius={5}
          style={{ clear: "both", marginTop: 15 }}
        />

      </div>
    );
  }
}