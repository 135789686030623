import * as React from 'react';
import { IAButton } from '../../../button/Button';
import { LocalizationService } from '../../../../services/LocalizationService';
import { Popup } from '../../reusableComponents/popup/Popup';

export interface IIAErrorPopupProps {
  technicalDetails: string;
  highlightColor: string;
  darkMode: boolean;
  onClose: () => void;
}

export interface IIAErrorPopupState {
  showTechnicalDetails: boolean;
  hasCopiedDetails: boolean;
}

export class ErrorPopup extends React.Component<IIAErrorPopupProps, IIAErrorPopupState> {
  private readonly localizationService: LocalizationService = new LocalizationService();
  
  constructor(props: IIAErrorPopupProps) {
    super(props);
    this.state = {
      showTechnicalDetails: false,
      hasCopiedDetails: false
    }
  }

  public render(): JSX.Element {
    return (
      <Popup
        darkMode={this.props.darkMode}
        headline={this.localizationService.strings.PlayAdmin_ErrorPopupHeader}
        close={() => this.props.onClose()}
        content={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start"
            }}
          >
            {this.localizationService.strings.PlayAdmin_ErrorPopupText}
            {this.props.technicalDetails && 
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    marginTop: 10
                  }}
                >
                  <IAButton
                    label={this.localizationService.strings.PlayAdmin_ErrorPopupTechnicalDetails}
                    onClick={() => this.setState({ showTechnicalDetails: !this.state.showTechnicalDetails }, () => !this.state.showTechnicalDetails && this.setState({hasCopiedDetails: false}))}
                    iconMarginTop={8}
                    iconSize={16}
                    textColor={this.props.darkMode ? "#A8A8A8" : undefined}
                    iconColor={this.props.darkMode ? "#A8A8A8" : undefined}
                    iconUrl={this.state.showTechnicalDetails
                      ? "https://intraactivestorage.blob.core.windows.net/cdn/icons/Chevron%20Down/SVG/ic_fluent_chevron_down_48_regular.svg" 
                      : "https://intraactivestorage.blob.core.windows.net/cdn/icons/Chevron%20Right/SVG/ic_fluent_chevron_right_48_regular.svg"
                    }
                  />
                  {this.state.showTechnicalDetails &&
                    <IAButton
                      onClick={() => {
                        navigator.clipboard.writeText(this.props.technicalDetails);
                        this.setState({hasCopiedDetails: true});
                      }}
                      iconMarginTop={8}
                      iconSize={16}
                      textColor={this.props.darkMode ? "#A8A8A8" : undefined}
                      iconColor={this.props.darkMode ? "#A8A8A8" : undefined}
                      iconUrl={this.state.hasCopiedDetails
                        ? "https://intraactivestorage.blob.core.windows.net/cdn/icons/Clipboard Task/SVG/ic_fluent_clipboard_task_24_regular.svg" 
                        : "https://intraactivestorage.blob.core.windows.net/cdn/icons/Copy/SVG/ic_fluent_copy_24_regular.svg"
                      }
                    />
                  }
                </div>
                {this.state.showTechnicalDetails &&
                  <p
                    style={{
                      marginTop: 0,
                      fontSize: 12,
                      marginLeft: 26,
                      wordBreak: "break-all",
                      height: 200,
                      overflow: "auto"
                    }}
                  >
                    {this.props.technicalDetails}
                  </p>
                }
              </>
            }
          </div>
        }
      />
    );
  }
}