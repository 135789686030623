exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_spider_3FrSvOXBJHinZIe73O7ezy {\n  position: absolute;\n  display: inline-block;\n  top: 0;\n  right: 10%;\n\n  /* 4. Animatie */\n  animation: swing_rfrclPU4anKtNprXWRIRA 3s infinite;\n  transform-origin: top;\n\n  /* Bonus */\n  transition: 2s ease-in-out;\n}\n\n.IA_spiderWeb_Oi9RsSmJ_aLLy1e5vZ4fo {\n  background: rgba(255, 255, 255, .7);\n}\n\n.IA_spiderBody_a9pdFROkZanVKaM1ObhNh {\n  position: relative;\n  background-image: url(\"https://intraactivestorage.blob.core.windows.net/cdn/play/spider.png\");\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n\n.IA_spiderLeftEye_3f2HH0gb6lU76p0OB1vIyr {\n  position: absolute;\n  background: #fff;\n  border-radius: 50%;\n}\n\n.IA_spiderLeftEye_3f2HH0gb6lU76p0OB1vIyr:after {\n  background: red;\n  width: 32%;\n  height: 32%;\n  content: '';\n  display: block;\n  margin: 55%;\n  border-radius: 50%;\n\n  /* 3. Animatie */\n  animation: look_3-C6-5IR4jsotV5yvA4Ixp 8s infinite;\n}\n\n.IA_spiderRightEye_FoZOn35XstRU3mWgq4rIz {\n  position: absolute;\n  background: #fff;\n  border-radius: 50%;\n}\n\n.IA_spiderRightEye_FoZOn35XstRU3mWgq4rIz:after {\n  background: red;\n  width: 32%;\n  height: 32%;\n  content: '';\n  display: block;\n  margin: 55%;\n  border-radius: 50%;\n\n  /* 3. Animatie */\n  animation: look_3-C6-5IR4jsotV5yvA4Ixp 8s infinite;\n}\n\n/* 1. Animatie */\n@keyframes look_3-C6-5IR4jsotV5yvA4Ixp {\n  0%, 40%, 100% {\n    transform: translateX(0);\n  }\n\n  45%, 95% {\n    transform: translateX(-110%);\n  }\n}\n\n\n/* 3. Animatie */\n@keyframes swing_rfrclPU4anKtNprXWRIRA {\n  0%, 100% {\n    transform: translateY(0);\n  }\n\n  50% {\n    transform: translateY(-20px);\n  }\n}", ""]);

// Exports
exports.locals = {
	"IA_spider": "IA_spider_3FrSvOXBJHinZIe73O7ezy",
	"swing": "swing_rfrclPU4anKtNprXWRIRA",
	"IA_spiderWeb": "IA_spiderWeb_Oi9RsSmJ_aLLy1e5vZ4fo",
	"IA_spiderBody": "IA_spiderBody_a9pdFROkZanVKaM1ObhNh",
	"IA_spiderLeftEye": "IA_spiderLeftEye_3f2HH0gb6lU76p0OB1vIyr",
	"look": "look_3-C6-5IR4jsotV5yvA4Ixp",
	"IA_spiderRightEye": "IA_spiderRightEye_FoZOn35XstRU3mWgq4rIz"
};