import * as React from 'react';
import { Ghost } from './ghost/Ghost';
import * as styles from './Halloween.css';
import { Spider } from './spider/Spider';

export interface IProps {
  sizeUnit: number;
}

export interface IState {
}

export class Halloween extends React.Component<IProps, IState> {

  public render(): JSX.Element {

    return (
      <div className={styles.IA_halloween}>
        <Spider
          sizeUnit={this.props.sizeUnit}
        />
        <Ghost
          animation='horizontalGhostAnimation'
          delay='-30s'
        />
        <Ghost
          animation='verticalGhostAnimation'
          delay='-90s'
        />
        <div className={styles.fog} />
        <div className={styles.fog2} />
      </div>
    );
  }

}