import * as React from 'react';
import { Helper } from '../../../../../../Helper';
import { IEnvironment } from '../../../../../../interfaces/IEnvironment';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import PlayService from '../../../../../../services/PlayService';
import { IAButton } from '../../../../../button/Button';
import { IAIcon } from '../../../../../icon/Icon';
import { IAPanel } from '../../../../../panels';
import { IASearch } from '../../../../../search/Search';
import * as styles from './StockPhotosPanel.css';

export interface IProps {
  open: boolean;
  close: () => void;
  onSelect: (image: string, lastModified?: string) => void;
  highlightColor: string;
  isMobile: boolean;
  environment: IEnvironment;
  tenant: string;
  token: string;
  marginTop: number;
  darkMode: boolean;
}

export interface IState {
  searchQuery: string;
  thumbnails: JSX.Element[];
  images: any[];
  totalCount: number;
  loading: boolean;
}

export class StockPhotosPanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      searchQuery: "",
      thumbnails: [],
      images: [],
      totalCount: undefined,
      loading: false
    };
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.open && props.open !== this.props.open) {
      this.search("", 0);
    }
  }

  private search(searchQuery: string, skip: number): void {
    this.setState({ loading: true });
    setTimeout(() => {
      if (searchQuery === this.state.searchQuery) {
        PlayService.getStockImages(this.props.environment, this.props.tenant, this.props.token, searchQuery, 50, skip, this.localizationService.getLanguageCode()).then((result: any) => {
          const thumbnails = [];
          if (result && result.images && this.state.searchQuery === result.searchQuery) {
            let row = [];
            if (skip === 0) {
              this.setState({ images: result.images, totalCount: result.count });
            } else {
              this.setState({ images: this.state.images.concat(result.images), totalCount: result.count });
            }
            console.log(result.images);
            let imagesDisplayed = 0;
            let imagesInRow = [];
            let imagesCombinedWidthInRow = 0;
            this.state.images.forEach((image: any) => {
              imagesDisplayed++;
              if (imagesInRow.length === (this.props.isMobile ? 2 : 4)) {
                const factor = (this.props.isMobile ? window.innerWidth - 65 : 900) / imagesCombinedWidthInRow;
                const height = 130 * factor;
                imagesInRow.forEach((imageInRow: any) => {
                  row.push(
                    <img
                      crossOrigin="anonymous"
                      key={imageInRow.Id}
                      id={`stockPhoto_${imageInRow.Id}`}
                      className={styles.IA_thumbnail}
                      width={imageInRow.ThumbnailInfo.Medium.Width * factor}
                      height={height}
                      style={{
                        width: imageInRow.ThumbnailInfo.Medium.Width * factor
                      }}
                      src={`https://cdn.hubblecontent.osi.office.net/m365content/publish/${imageInRow.Id}/thumbnails/large.jpg`}
                      onClick={() => this.props.onSelect(`https://cdn.hubblecontent.osi.office.net/${imageInRow.Path}?blurHash=${Helper.generateBlurHash(document.getElementById(`stockPhoto_${imageInRow.Id}`) as HTMLImageElement)}`)}
                    />
                  );
                });
                thumbnails.push(
                  <div
                    key={`IA_row_${image.Id}`}
                    className={styles.IA_imageRow}
                    style={{
                      height: `${height}px`
                    }}>
                    {row}
                  </div>
                );
                row = [];
                imagesInRow = [];
                imagesInRow.push(image);
                imagesCombinedWidthInRow = image.ThumbnailInfo.Medium.Width;
              } else {
                imagesInRow.push(image);
                imagesCombinedWidthInRow = imagesCombinedWidthInRow + image.ThumbnailInfo.Medium.Width;
                if (imagesDisplayed === result.count) {
                  imagesInRow.forEach((imageInRow: any) => {
                    row.push(
                      <img
                        crossOrigin="anonymous"
                        key={imageInRow.Id}
                        id={`stockPhoto_${imageInRow.Id}`}
                        className={styles.IA_thumbnail}
                        height={130}
                        src={`https://cdn.hubblecontent.osi.office.net/m365content/publish/${imageInRow.Id}/thumbnails/large.jpg`}
                        onClick={() => this.props.onSelect(`https://cdn.hubblecontent.osi.office.net/${imageInRow.Path}?blurHash=${Helper.generateBlurHash(document.getElementById(`stockPhoto_${imageInRow.Id}`) as HTMLImageElement)}`)}
                      />
                    );
                  });
                  thumbnails.push(
                    <div
                      key={`IA_row_${image.Id}`}
                      className={styles.IA_imageRow}
                      style={{
                        height: `${130}px`
                      }}>
                      {row}
                    </div>
                  );
                }
              }
            });
            this.setState({ thumbnails, loading: false });
          } else {
            this.setState({ thumbnails, images: [], loading: false });
          }
        });
      }
    }, 500);
  }

  public render(): JSX.Element {
    return (
      <IAPanel
        open={this.props.open}
        transition={"slide"}
        showNavigationBar={true}
        darkMode={this.props.darkMode}
        marginTop={this.props.marginTop}
        width={this.props.isMobile ? window.innerWidth : 1000}
        isMobile={this.props.isMobile}
        close={() => this.props.close()}
      >
        <div
          id="IA_StockPhotos"
          style={{
            padding: 20,
            width: "100%",
            boxSizing: "border-box",
            float: "left",
            position: "relative"
          }}>
          <IASearch
            searchQuery={this.state.searchQuery}
            placeholder={this.localizationService.strings.StockPhotos_SearchPlaceholder}
            arialable="Search"
            darkMode={this.props.darkMode}
            borderRadius={5}
            // dataAutomationId=""
            onChange={(searchQuery: string) => {
              this.search(searchQuery, 0);
              this.setState({ searchQuery });
            }}
          />
          <div className={styles.IA_images}>
            {this.state.thumbnails}
          </div>
          {this.state.images.length === 0 && this.state.searchQuery !== "" && !this.state.loading &&
            <div className={styles.IA_imageResultInfo}>{`${this.localizationService.strings.StockPhotos_NoImagesForTheResult} "${this.state.searchQuery}"`}</div>
          }
          {this.state.loading &&
            <div className={styles.IA_spinner}>
              <IAIcon
                title="Spinner"
                size={20}
                color={"#999999"}
              />
            </div>
          }
          {this.state.images.length > 0 && !this.state.loading &&
            <div className={styles.IA_imageResultInfo}>{this.state.searchQuery === "" ? `${this.localizationService.strings.StockPhotos_Showing} ${this.state.images.length} ${this.localizationService.strings.StockPhotos_OutOf} ${this.state.totalCount} ${this.localizationService.strings.StockPhotos_Photos}` : `${this.localizationService.strings.StockPhotos_Showing} ${this.state.images.length} ${this.localizationService.strings.StockPhotos_OutOf} ${this.state.totalCount} ${this.localizationService.strings.StockPhotos_ResultsForTheSearch} "${this.state.searchQuery}"`}</div>
          }
          {this.state.images.length < this.state.totalCount &&
            <IAButton
              label={this.localizationService.strings.StockPhotos_LoadMore}
              buttonColor={this.props.highlightColor}
              darkMode={this.props.darkMode}
              showSpinner={false}
              onClick={() => {
                this.search(this.state.searchQuery, this.state.images.length);
              }}
              style={{
                marginTop: 20,
                width: 100,
                float: "left",
                marginLeft: "calc(50% - 50px)"
              }}
            />
          }
        </div>
      </IAPanel>
    );
  }

  
}
