import * as React from "react";
import { IALikes } from "../../../components/likes/Likes";
import { IAComments } from "../../../components/comments/Comments";
import { IAIcon } from "../../icon/Icon";
import { IInstance } from "../../../interfaces/IInstance";
import { ILike } from "../../../interfaces/ILike";
import { LocalizationService } from "../../../services/LocalizationService";
import { IAUniqueViews } from "../../uniqueViews/UniqueViews";
import * as styles from "./CardFooter.css";
import { ICardFooterElement } from "../../../interfaces/ICard";
import { IEnvironment } from "../../../interfaces/IEnvironment";


export interface IProps {
  isMobile: boolean;
  user: any;
  likes?: any;
  uniqueViews?: number;
  comments?: number;
  id: string;
  tenant: string;
  type: "Event" | "Message" | "ContentCreator" | "ContentOrganizer";
  environment: IEnvironment;
  instance: IInstance;
  source: string;
  token: string;
  color: string;
  borderColor?: string;
  backgroundColor: string;
  footerElements?: ICardFooterElement[];
  footerLeftText?: string;
  darkMode?: boolean;
  showLikesDimmed?: boolean;
  showCommentsDimmed?: boolean;
  showUniqueViewsDimmed?: boolean;
  hideCommandBarButton?: boolean;
  likesUpdated?: (likes: ILike[]) => void;
  commentsUpdated?: (comments: number) => void;
  commentsClick?: () => void;
  onClickEdit?: () => void;
  onClickOpen?: () => void;
  onClickPreview?: () => void;
  onClickCopy?: () => void;
  onClickDelete?: () => void;
}

export interface IState {
  commandaBarOpen: boolean;
}

export class IACardFooter extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();
  private menuRef: React.RefObject<HTMLDivElement>

  constructor(props: IProps) {
    super(props);
    this.state = {
      commandaBarOpen: false
    };
    this.menuRef = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  private handleClick(event: Event) {
    if (this.menuRef && this.menuRef.current && !this.menuRef.current.contains(event.target as Node)) {
      if (this.state.commandaBarOpen) {
        this.setState({ commandaBarOpen: false })
      }
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  public render(): JSX.Element {
    let commandBarItems: Array<any> = [];
    let showOpenCommandBarButton = false;
    if (this.props.onClickEdit && this.props.id) {
      commandBarItems.push(
        <div className={styles.IA_commandBarItem} key={`commandbar-edit-${this.props.id}`} onClick={() => this.props.onClickEdit()}>
          <IAIcon
            url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Edit/SVG/ic_fluent_edit_48_regular.svg"
            size={14}
            color={this.props.color}
            style={{
              float: "left",
            }}
          />
          <div
            style={{
              color: "#333333",
              float: "left",
              marginLeft: 7,
            }}
          >
            {this.localizationService.strings.Card_CommandBar_Edit}
          </div>
        </div>
      );
    }
    if (this.props.onClickOpen && this.props.footerElements == undefined) {
      showOpenCommandBarButton = true;
      commandBarItems.push(
        <div className={styles.IA_commandBarItem} key={`commandbar-open-${this.props.id}`} onClick={() => this.props.onClickOpen()}>
          <IAIcon
            url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Open/SVG/ic_fluent_open_48_regular.svg"
            size={14}
            color={this.props.color}
            style={{
              float: "left",
            }}
          />
          <div
            style={{
              color: "#333333",
              float: "left",
              marginLeft: 7,
            }}
          >
            {this.localizationService.strings.Card_CommandBar_Open}
          </div>
        </div>
      );
    }
    if (this.props.onClickPreview) {
      commandBarItems.push(
        <div
          className={styles.IA_commandBarItem}
          key={`commandbar-preview-${this.props.id}`}
          onClick={() => this.props.onClickPreview()}
        >
          <IAIcon
            url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Eye/SVG/ic_fluent_eye_24_regular.svg"
            size={14}
            color={this.props.color}
            style={{
              float: "left",
            }}
          />
          <div
            style={{
              color: "#333333",
              float: "left",
              marginLeft: 7,
            }}
          >
            {this.localizationService.strings.Card_CommandBar_Preview}
          </div>
        </div>
      );
    }
    if (this.props.onClickCopy) {
      commandBarItems.push(
        <div className={styles.IA_commandBarItem} key={`commandbar-copy-${this.props.id}`} onClick={() => this.props.onClickCopy()}>
          <IAIcon
            url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Copy/SVG/ic_fluent_copy_24_regular.svg"
            size={14}
            color={this.props.color}
            style={{
              float: "left",
            }}
          />
          <div
            style={{
              color: "#333333",
              float: "left",
              marginLeft: 7,
            }}
          >
            {this.localizationService.strings.Card_CommandBar_Copy}
          </div>
        </div>
      );
    }

    if (this.props.onClickDelete && !this.props.isMobile) {
      commandBarItems.push(
        <div
          className={styles.IA_commandBarItem}
          key={`commandbar-delete-${this.props.id}`}
          onClick={() => this.props.onClickDelete()}
        >
          <IAIcon
            url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Delete/SVG/ic_fluent_delete_48_regular.svg"
            size={14}
            color={this.props.color}
            style={{
              float: "left",
            }}
          />
          <div
            style={{
              color: "#333333",
              float: "left",
              marginLeft: 7,
            }}
          >
            {this.localizationService.strings.Card_CommandBar_Delete}
          </div>
        </div>
      );
    }
    if (commandBarItems.length === 1 && showOpenCommandBarButton) {
      // hide command bar if the only item is the "open" button
      commandBarItems = [];
    }
    const buttons = [];
    if (this.props.footerElements?.length) {
      this.props.footerElements.forEach((button: ICardFooterElement, index: number) => {
        buttons.push(
          <IAIcon
            key={`button_${index}`}
            title={button.icon}
            url={button.iconUrl}
            size={20}
            color={button.color}
            style={{
              float: "right",
              marginTop: 10,
              marginRight: 10,
            }}
            svgStyle={button.svgStyles}
            onClick={() => button.onClick()}
          />
        );
      });
    }

    const showLikes = this.props.likes && this.props.id != undefined && this.props.instance;
    const showComments = this.props.comments != undefined && this.props.id != undefined && this.props.instance;
    const showUniqueViews = this.props.id != undefined && this.props.uniqueViews && this.props.instance;    
    return (
      <div
        className={styles.IA_cardFooter}
        style={{
          borderColor: this.props.darkMode ? "#727272" : this.props.borderColor
        }}>
        <div className={styles.IA_cardContainer}>
          {(showLikes || this.props.showLikesDimmed) &&
            <IALikes
              color={this.props.color}
              itemId={this.props.id}
              user={this.props.user}
              tenant={this.props.tenant}
              component={this.props.type}
              instance={this.props.instance ? this.props.instance.instanceName : undefined}
              token={this.props.token}
              environment={this.props.environment}
              source={this.props.source}
              likes={this.props.likes}
              likesUpdated={(likes: ILike[]) => this.props.likesUpdated(likes)}
              style={{
                float: "left",
                marginRight: 6,
                marginTop: 10,
              }}
              showDimmed={this.props.showLikesDimmed}
            />
          }
          {(showComments || this.props.showCommentsDimmed) &&
            <IAComments
              color={this.props.color}
              itemId={this.props.id}
              user={this.props.user}
              tenant={this.props.tenant}
              component={this.props.type}
              instance={this.props.instance.instanceName}
              token={this.props.token}
              environment={this.props.environment}
              commentCount={this.props.comments}
              commentsUpdated={(comments: number) => this.props.commentsUpdated(comments)}
              style={{
                float: "left",
                marginTop: 10,
                opacity: this.props.showCommentsDimmed ? 0.5 : undefined
              }}
              commentsClick={() => !this.props.showCommentsDimmed && this.props.commentsClick()}
            />
          }
          {(showUniqueViews || this.props.showUniqueViewsDimmed) &&
            <IAUniqueViews
              color={this.props.color}
              itemId={this.props.id}
              user={this.props.user}
              tenant={this.props.tenant}
              component={this.props.type}
              instance={this.props.instance.instanceName}
              token={this.props.token}
              environment={this.props.environment}
              source={this.props.source}
              uniqueViews={this.props.uniqueViews}
              style={{
                float: "left",
                marginLeft: this.props.comments !== undefined ? 4 : 0,
                marginTop: 10
              }}
              showDimmed={this.props.showUniqueViewsDimmed}
            />
          }
          {commandBarItems.length > 0 && !this.props.hideCommandBarButton &&
            <div
              ref={this.menuRef}
              onClick={() => this.setState({ commandaBarOpen: !this.state.commandaBarOpen })}
            >
              <div className={styles.IA_cardBarBtn}>
                <IAIcon
                  url="https://intraactivestorage.blob.core.windows.net/cdn/icons/More%20Vertical/SVG/ic_fluent_more_vertical_48_regular.svg"
                  size={18}
                  color={this.props.color}
                />
              </div>
              {this.state.commandaBarOpen &&
                <ul className={styles.IA_cardBarItems}
                >
                  {commandBarItems}
                </ul>
              }
            </div>
          }
        </div>
        {this.props.footerLeftText &&
          <div className={styles.IA_cardFooterLeftText}>
            {this.props.footerLeftText?.toUpperCase()}
          </div>
        }
        {buttons}
      </div>
    );
  }
}
