import * as React from 'react';
import * as styles from './Easter.css';
import { EasterEgg } from './easterEgg/EasterEgg';

export interface IProps {
  sizeUnit: number;
}

export interface IState {
  eggIndex: number;
  confettiWrapper: JSX.Element[];
}

export class Easter extends React.Component<IProps, IState> {

  public constructor(props: IProps) {
    super(props);
    this.state = {
      eggIndex: 0,
      confettiWrapper: [],
    };
    this.startEggs();
  }

  private startEggs(): void {
    this.addConfettiElement();
    setTimeout(() => {
      this.startEggs();
    }, Math.floor(Math.random() * 50000));

  }

  private addConfettiElement(): void {
    this.setState({ eggIndex: this.state.eggIndex + 1 }, () => {
      const confettiWrapper = this.state.confettiWrapper ? this.state.confettiWrapper : [];
      const duration = Math.floor(Math.floor(Math.random() * 10) + 5);
      confettiWrapper.push(
        <EasterEgg
          key={this.state.eggIndex}
          id={`Egg_${this.state.eggIndex}`}
          duration={duration}
          sizeUnit={this.props.sizeUnit}
        />
      );
      const id = this.state.eggIndex;
      setTimeout(() => {
        this.removeConfettiElement(`Egg_${id}`);
      }, (duration + 3) * 1000);
      this.setState({ confettiWrapper });
    });
  }

  private removeConfettiElement(id: string): void {
    const confettiElement = document.getElementById(id);
    if (confettiElement) {
      confettiElement.outerHTML = "";
    }
  }

  public render(): JSX.Element {

    return (
      <div className={styles.IA_easter}>
        <div
          className={styles.IA_easterEggContainer}
          style={{
            width: this.props.sizeUnit * 10,
            height: this.props.sizeUnit * 14,
          }}
        >
          {this.state.confettiWrapper}
        </div>
      </div >
    );
  }

}