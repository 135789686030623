import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import { IADropDown } from '../../../../../dropDown/DropDown';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IPlayProfile } from '../../../../../../interfaces/IPlay';
import PlayService from '../../../../../../services/PlayService';
import { IFeed } from '../../../../../../interfaces/IFeed';
import { Helper } from '../../../../../../Helper';
import { IntegrationFeedCheckbox } from './IntegrationFeedCheckbox';
import { IASpinner } from '../../../../../spinner/Spinner';
import { SharePointSiteManager } from '../../sharePointSiteManager/SharePointSiteManager';
import { IAButton } from '../../../../../button/Button';
import { AppRegistrationConsentHelper, IPlayAppRegistration } from '../../appRegistrationConsentHelper/AppRegistrationConsentHelper';

export interface IProps {
  store?: Store;
  profiles: IPlayProfile[];
  isEditingExistingIntegration: boolean;
  isDeletingSharepointNewsItem: boolean;
  selectedProfileId: string;
  isConnected: boolean;
  requiredAppRegistrations: IPlayAppRegistration[];
  onProfileChange: (profile) => void;
  sharePointSiteManagerOnComplete: (id: string) => void;
  onConstent: (constent: string) => void;
  onConnectSucces: () => void;
}

export interface IState {
  availableFeeds: IFeed[];
  isLoading: boolean;
  manageMode: boolean;
}

@inject('store')
@observer
export class SharepointNewsIntegrationComponent extends React.Component<IProps, IState> {
  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      availableFeeds: [],
      isLoading: true,
      manageMode: false
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentDidMount(): void {
    PlayService.getSharePointSitesSelectedAsFeeds(this.props.store.environment, this.props.store.token, this.props.store.tenantId)
      .then(availableFeeds => this.setState({ availableFeeds}))
      .finally(() => this.setState({ isLoading: false }));
  }

  private getFeedCheckboxes(): JSX.Element {
    return <>
      {this.state.availableFeeds.map(feed => {
        const id = Helper.getRandomStringKey();
        return <IntegrationFeedCheckbox
          id={id}
          feed={feed}
          isDeletingSharepointNewsItem={this.props.isDeletingSharepointNewsItem}
        />
      })}
    </>;
  }

  public render(): JSX.Element {
    const hasFeeds = this.state.availableFeeds && this.state.availableFeeds.length > 0;
    return (
      <>
        {this.state.isLoading ?
          <IASpinner color={this.props.store.highlightColor}/>
        :
          <>
            {this.props.isConnected
            ? 
              <>
                <IADropDown
                  label={this.localizationService.strings.PlayAdmin_SelectAProfileToAccess?.toUpperCase()}
                  selectedOption={this.props.store.integrationToEdit?.profileId}
                  options={[{
                    key: undefined,
                    value: this.localizationService.strings.PlayAdmin_SelectAProfileToAccess,
                    disabled: true
                  },
                  ...this.props.profiles.map(profile => { return { key: profile.id, value: profile.title } })
                  ]}
                  disabled={this.props.isEditingExistingIntegration || this.state.isLoading}
                  darkMode={this.props.store.darkMode}
                  highlightColor={this.props.store.highlightColor}
                  onChange={(profile) => {
                    this.props.onProfileChange(profile)
                  }}
                  style={{
                    float: "left",
                    width: 340
                  }}
                  borderRadius={5}
                />
                <>
                  {this.state.manageMode ?
                    <>
                      <SharePointSiteManager
                        onComplete={(id) => this.props.sharePointSiteManagerOnComplete(id)}
                      />
                      <IAButton
                        label={this.localizationService.strings.PlayAdmin_SharePointNewsSiteManagementBack}
                        buttonColor={this.props.store.highlightColor}
                        disbaled={false}
                        darkMode={this.props.store.darkMode}
                        onClick={() => this.setState({ manageMode: false })}
                        borderRadius={5}
                        showSpinner={this.props.store.workingOnIt}
                        style={{ float: "none" }}
                      />
                    </>
                    :
                    <>
                      {this.props.selectedProfileId && 
                        <div style={{
                          marginTop: 20,
                          float: "left",
                          width: "100%"
                        }}>
                          {hasFeeds ?
                            <>
                              <label>{this.localizationService.strings.PlayAdmin_SharePointNewsSelectSites?.toUpperCase()}</label>
                              {this.getFeedCheckboxes()}
                            </>
                          :
                            <>{this.localizationService.strings.PlayAdmin_SharePointNewsNoFeedsMessage}</>
                          }
                        </div>
                      }
                      <IAButton
                        label={"Add site"}
                        buttonColor={this.props.store.highlightColor}
                        disbaled={false}
                        darkMode={this.props.store.darkMode}
                        onClick={() => {
                          this.setState({ manageMode: true });
                        }}
                        borderRadius={5}
                        showSpinner={this.props.store.workingOnIt}
                        style={{ clear: "both", marginTop: 15 }}
                      />
                    </>
                  }
                </>
              </>
            :
              <AppRegistrationConsentHelper
                appRegistrations={this.props.requiredAppRegistrations}
                onConsent={(appRegistration) => this.props.onConstent(appRegistration)}
                onSuccess={() => this.props.onConnectSucces()}
              />
            }
          </>
        }
      </>
    );
  }
}