import * as React from 'react';
import { Store } from '../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './IntegrationsEditPanel.css';

export interface IProps {
  store?: Store;
  integrationKey: string;
  icon: string;
  label: string;
  opacity: number;
  onIntegrationTypeChange: (string) => void;
  disabled?: boolean;
}

@inject('store')
@observer
export class IntegrationsIcon extends React.Component<IProps> {

  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }


  public render(): JSX.Element {
    return (
      <div
        className={styles.IA_widgetType}
        style={{
          opacity: this.props.opacity,
          cursor: this.props.disabled ? 'default' : 'cursor'
        }}
        onClick={() => {
          !this.props.disabled && this.props.onIntegrationTypeChange(this.props.integrationKey)
        }}
      >
        <img
          src={`https://intraactivestorage.blob.core.windows.net/cdn/play/widgets/${this.props.icon}.png`}
          width="100%"
          height="100%"
          style={{
            float: "left",
            width: 65,
            height: 65,
            borderRadius: 10,
            backgroundColor: "#eeeeee",
            border: this.props.icon === "widget-sharepoint-news" ? "1px solid #e5e5e5" : "1px solid transparent"
          }}
        />
        <div
          className={styles.IA_widgetTypeLabel}
          style={{
            color: this.props.store.darkMode ? "#ffffff" : "#333333"
          }}
        >
          {this.props.label}
        </div>
      </div>
    );
  }
}