exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_weather_2XVXlKzoTzfk0qRsRx5CmH {\n  height: 100%;\n  box-sizing: border-box;\n  display: flex;\n  justify-content: end;\n  align-items: center;\n}\n\n.IA_weatherIcon_PNnme6kveTLSsk40dWlx6 {\n  height: 65%;\n  width: fit-content;\n}\n\n.IA_weatherTemperature_3Thl2W96xC5NqTiNNxAm1V {\n  position: relative;\n  text-align: left;\n\n}\n\n.IA_weatherArea_35iESr1nHnO-rcm_vd-o5k {\n  position: relative;\n  text-align: left;\n  font-weight: lighter;\n  clear: right;\n}", ""]);

// Exports
exports.locals = {
	"IA_weather": "IA_weather_2XVXlKzoTzfk0qRsRx5CmH",
	"IA_weatherIcon": "IA_weatherIcon_PNnme6kveTLSsk40dWlx6",
	"IA_weatherTemperature": "IA_weatherTemperature_3Thl2W96xC5NqTiNNxAm1V",
	"IA_weatherArea": "IA_weatherArea_35iESr1nHnO-rcm_vd-o5k"
};