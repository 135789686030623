import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from '../WidgetEditPanel.css';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IAOptionSelector } from '../../../../../optionSelector/OptionSelector';
import { IANumberField } from '../../../../../numberField/NumberField';
import { IAToggle } from '../../../../../toggle/Toggle';
import PlayService from '../../../../../../services/PlayService';
import { IFeed } from '../../../../../../interfaces/IFeed';
import { IADropDown } from '../../../../../dropDown/DropDown';
import { IAButton } from '../../../../../button/Button';
import { Helper } from '../../../../../../Helper';
import { IASpinner } from '../../../../../spinner/Spinner';
import { IAColorPicker } from '../../../../../colorPicker/ColorPicker';
import { MediaFileSelector } from '../../../mediaFiles/mediaFileSelector/MediaFileSelector';
import { IAColorTheme } from '../../../../../colorTheme/ColorTheme';
import { IATextField } from '../../../../../textField/TextField';
import { IAIcon } from '../../../../../icon/Icon';
import { IAGroup } from '../../../../../group/Group';
import { RotatorViewSelector } from '../../../rotatorViewSelector/RotatorViewSelector';
import { IPlayBoxStyle } from '../../../../../../interfaces/IPlay';

export interface IProps {
  store?: Store;
  forceUpdate: () => void;
  reloadPreview: () => void;
  loadingContent: (loading: boolean) => void;
}

export interface IState {
  feeds?: IFeed[];
  loadingFeeds?: boolean;
  colorThemeForPreview?: any;
  feedInstances?: string;
  groupToAdd?: string;
}

@inject('store')
@observer
export class EventsWidgetSettings extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      feeds: [],
      loadingFeeds: true
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  componentDidMount() {
    this.getAvailableInstances();
  }

  private async getAvailableInstances() {
    this.setState({ loadingFeeds: true });
    let feeds;
    if (this.props.store.widgetToEdit.eventViewer || this.props.store.widgetToEdit.eventViewer) {
      feeds = await PlayService.getProfileIntegrations(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id, this.props.store.widgetToEdit.eventViewer ? 'events' : 'messages')
      this.setState({ feeds, loadingFeeds: false });
    } else {
      feeds = await PlayService.getSharePointProfileNewsSites(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id)
      this.setState({ feeds, loadingFeeds: false });
    }
    if (!this.props.store.widgetToEdit.eventViewer?.feedId) {
      this.onFeedChange(feeds[0].id);
    }
  }

  private async onFeedChange(feedId: string) {
    this.props.loadingContent(true);
    this.props.store.widgetToEdit.eventViewer.feedId = feedId;
    let from: Date = this.props.store.widgetToEdit.eventViewer.excludeToday ? new Date(new Date().setDate(new Date().getDate() + 1)) : new Date();
    let to: Date;
    if (this.props.store.widgetToEdit.eventViewer.layoutType === "week") {
      const currentDayInWeek: number = new Date().getDay();
      switch (currentDayInWeek) {
        case 0: // Sunday
          from = new Date(new Date().setDate(new Date().getDate() + 1));
          to = new Date(new Date().setDate(new Date().getDate() + 6));
          break;
        case 1: // Monday
          from = new Date();
          to = new Date(new Date().setDate(new Date().getDate() + 5));
          break;
        case 2: // Tuesday
          from = new Date(new Date().setDate(new Date().getDate() - 1));
          to = new Date(new Date().setDate(new Date().getDate() + 3));
          break;
        case 3: // Wednesday
          from = new Date(new Date().setDate(new Date().getDate() - 2));
          to = new Date(new Date().setDate(new Date().getDate() + 2));
          break;
        case 4: // Thursday
          from = new Date(new Date().setDate(new Date().getDate() - 3));
          to = new Date(new Date().setDate(new Date().getDate() + 1));
          break;
        case 5: // Friday
          from = new Date(new Date().setDate(new Date().getDate() - 4));
          to = new Date();
          break;
        case 6: // Saturday
          from = new Date(new Date().setDate(new Date().getDate() + 2));
          to = new Date(new Date().setDate(new Date().getDate() + 7));
          break;
        default:
          break;
      }
    } else {
      switch (this.props.store.widgetToEdit.eventViewer.timespand) {
        case "today":
          to = new Date();
          break;
        case "week":
          to = new Date(new Date().setDate(new Date().getDate() + 7));
          break;
        case "month":
          to = new Date(new Date().setDate(new Date().getDate() + 30));
          break;
        case "upcoming":
          to = new Date(new Date().setDate(new Date().getDate() + 10000));
          break;
        default:
          break;
      }
    }
    this.props.store.widgetToEdit.eventViewer.events = await PlayService.getFeed(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id, 'events', this.props.store.widgetToEdit?.eventViewer.feedId, from?.toISOString(), to?.toISOString(), this.props.store.widgetToEdit?.eventViewer?.amountToShow);
    this.props.forceUpdate();
    setTimeout(() => {
      this.props.forceUpdate();
      this.props.loadingContent(false);
    }, 500);
    // Instances on feed
    this.state.feeds?.forEach((feed: IFeed) => {
      if (feed.id === feedId && feed.instances?.length > 0) {
        let feeds = "";
        feed.instances?.forEach((feedInstance: string, index) => {
          feeds += feedInstance;
          if (index < feed.instances.length - 1) {
            feeds += ", ";
          }
        });
        this.setState({ feedInstances: feeds });
      }
    })
  }

  render(): React.ReactNode {
    let availableFeeds = this.state.feeds?.map(feed => {
      return {
        key: feed.id,
        value: feed.title
      }
    });
    const colorThemes = [];
    if (this.props.store.widgetToEdit?.eventViewer?.colorThemes?.length > 0) {
      this.props.store.widgetToEdit?.eventViewer.colorThemes.forEach((colorTheme, index) => {
        colorThemes.push(
          <IAColorTheme
            key={"colorTheme_" + index}
            backgroundColor={colorTheme.backgroundColor}
            textColor={colorTheme.textColor}
            disableTextColor={this.props.store.widgetToEdit?.workplaceFeedViewer != undefined}
            highlightColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            swatches={this.props.store.profile?.swatches}
            inTeams
            remove={() => {
              this.props.store.widgetToEdit?.eventViewer.colorThemes.splice(index, 1);
              this.props.forceUpdate();
            }}
            allowAddingToSwatches={false}
            setBackgroundColor={(backgroundColor) => {
              colorTheme.backgroundColor = backgroundColor;
              this.props.forceUpdate();
            }}
            setTextColor={(textColor) => {
              colorTheme.textColor = textColor;
              this.props.forceUpdate();
            }}
          />
        )
      });
    }
    const groups: JSX.Element[] = [];
    if (this.props.store.widgetToEdit.workplaceFeedViewer?.groups?.length > 0) {
      this.props.store.widgetToEdit.workplaceFeedViewer.groups.forEach((group: string, index) => {
        groups.push(
          <div className={styles.IA_group}>
            <div className={styles.IA_groupId}>
              {group.toUpperCase()}
            </div>
            <IAIcon
              url='https://intraactivestorage.blob.core.windows.net/cdn/icons/Delete/SVG/ic_fluent_delete_48_regular.svg'
              size={22}
              color={"#333333"}
              style={{
                float: "left",
                marginLeft: 10,
                marginTop: 2
              }}
              onClick={() => {
                this.props.store.widgetToEdit.workplaceFeedViewer.groups.splice(index, 1);
                this.forceUpdate()
                this.setState({ loadingFeeds: true });
                PlayService.getWorkplaceFeed(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id, this.props.store.widgetToEdit.workplaceFeedViewer.groups).then((feed) => {
                  this.props.store.widgetToEdit.workplaceFeedViewer.feed = feed;
                  this.setState({ loadingFeeds: false });
                  this.props.forceUpdate();
                  setTimeout(() => {
                    this.props.forceUpdate();
                  }, 500);
                });
              }}
            />
          </div>
        );
      });
    }
    const amountToShowLabel = this.props.store.widgetToEdit.eventViewer
      ? this.localizationService.strings.PlayAdmin_AmountOfMessagesToShow
      : this.props.store.widgetToEdit.eventViewer
        ? this.localizationService.strings.PlayAdmin_AmountOfEventsToShow
        : this.props.store.widgetToEdit.sharepointNewsViewer
          ? this.localizationService.strings.PlayAdmin_AmountOfSharepointNewsToShow
          : this.localizationService.strings.PlayAdmin_AmountOfWorkplaceFeedItemsToShow;
    return (
      <>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_WidgetSettings?.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <IATextField
            label={this.localizationService.strings.PlayAdmin_Title?.toUpperCase()}
            required
            placeholder={this.localizationService.strings.PlayAdmin_Title}
            text={this.props.store.widgetToEdit.title}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(title: string) => {
              this.props.store.widgetToEdit.title = title;
              this.props.forceUpdate();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20,
              fontSize: 12
            }}
            darkMode={this.props.store.darkMode}
            label={this.localizationService.strings.PlayAdmin_ShowWidgetTitle?.toUpperCase()}
            checked={this.props.store.widgetToEdit.showTitle}
            color={this.props.store.highlightColor}
            onChange={() => {
              this.props.store.widgetToEdit.showTitle = this.props.store.widgetToEdit.showTitle != undefined ? !this.props.store.widgetToEdit.showTitle : true;
              this.props.reloadPreview();
            }}
          />
          <div style={{
            float: "left",
            width: "100%",
            marginTop: 20,
            color: this.props.store.darkMode ? "#a8a8a8" : "#333333"
          }}>
            <label>{this.localizationService.strings.PlayAdmin_SelectFeed?.toUpperCase()}</label>
            {this.state.loadingFeeds ?
              <IASpinner
                color={this.props.store.highlightColor}
                style={{
                  margin: "18px auto"
                }}
              />
              :
              <IADropDown
                selectedOption={this.props.store.widgetToEdit.eventViewer?.feedId}
                options={availableFeeds}
                darkMode={this.props.store.darkMode}
                highlightColor={this.props.store.highlightColor}
                borderRadius={5}
                onChange={(id: string) => {
                  this.onFeedChange(id)
                }}
                labelStyle={{
                  fontSize: "12px"
                }}
                style={{
                  marginTop: 20,
                  marginBottom: 10,
                  width: "100%"
                }}
              />
            }
            {this.props.store.widgetToEdit.eventViewer?.feedId && this.state.feedInstances &&
              <div
                style={{
                  fontSize: 12,
                  color: "gray",
                  marginBottom: 5
                }}
              >
                {`${this.localizationService.strings.PlayAdmin_InstancesInFeed}: ${this.state.feedInstances}`}
              </div>
            }
          </div>
          {this.props.store.widgetToEdit.eventViewer?.layoutType !== "week" &&
            <IANumberField
              label={amountToShowLabel?.toUpperCase()}
              number={this.props.store.widgetToEdit?.eventViewer.amountToShow}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              borderRadius={5}
              labelStyle={{
                fontSize: "12px"
              }}
              min={1}
              max={50}
              onChange={(amountToShow: number) => {
                this.props.store.widgetToEdit.eventViewer.amountToShow = amountToShow;
                this.onFeedChange(this.props.store.widgetToEdit?.eventViewer.feedId);
                this.props.forceUpdate();
              }}
              styles={{
                width: "100%",
                clear: "both"
              }}
            />
          }
          {this.props.store.widgetToEdit.eventViewer?.layoutType !== "list" && this.props.store.widgetToEdit.eventViewer?.layoutType !== "week" &&
            <IANumberField
              label={this.localizationService.strings.PlayAdmin_MinimumDuration?.toUpperCase()}
              number={this.props.store.widgetToEdit?.eventViewer.duration}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              borderRadius={5}
              labelStyle={{
                fontSize: "12px"
              }}
              onChange={(duration: number) => {
                this.props.store.widgetToEdit.eventViewer.duration = duration;
                this.props.reloadPreview();
              }}
              styles={{
                width: "100%",
                clear: "both"
              }}
            />
          }
          {/* Timespan for events */}
          {this.props.store.widgetToEdit.eventViewer.layoutType !== "week" &&
            <div style={{
              float: "left",
              width: "100%",
            }}>
              <IADropDown
                label={this.localizationService.strings.PlayAdmin_Timespand?.toUpperCase()}
                selectedOption={this.props.store.widgetToEdit.eventViewer.timespand}
                options={[
                  {
                    key: "today",
                    value: this.localizationService.strings.PlayAdmin_TimespandToday
                  },
                  {
                    key: "week",
                    value: this.localizationService.strings.PlayAdmin_TimespandWeek
                  },
                  {
                    key: "month",
                    value: this.localizationService.strings.PlayAdmin_TimespandMonth
                  },
                  {
                    key: "upcoming",
                    value: this.localizationService.strings.PlayAdmin_TimespandUpcoming
                  }
                ]}
                borderRadius={5}
                darkMode={this.props.store.darkMode}
                highlightColor={this.props.store.highlightColor}
                onChange={(timespand) => {
                  this.props.store.widgetToEdit.eventViewer.timespand = timespand;
                  this.onFeedChange(this.props.store.widgetToEdit?.eventViewer.feedId);
                }}
                labelStyle={{
                  fontSize: "12px"
                }}
                style={{
                  clear: "none",
                  float: "left",
                  width: "100%"
                }}
              />
              <IAToggle
                style={{
                  marginTop: 20
                }}
                labelStyle={{
                  fontSize: "12px"
                }}
                label={this.localizationService.strings.PlayAdmin_ExcludeToday?.toUpperCase()}
                checked={this.props.store.widgetToEdit.eventViewer.excludeToday}
                color={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                onChange={() => {
                  this.props.store.widgetToEdit.eventViewer.excludeToday = !this.props.store.widgetToEdit.eventViewer.excludeToday;
                  this.onFeedChange(this.props.store.widgetToEdit?.eventViewer.feedId);
                }}
              />
            </div>
          }
          <MediaFileSelector
            label={this.localizationService.strings.PlayAdmin_NoContentImageUrl?.toUpperCase()}
            url={this.props.store.widgetToEdit?.eventViewer.noContentImageUrl ? this.props.store.widgetToEdit?.eventViewer.noContentImageUrl : this.localizationService.strings.Player_NoContentUrl}
            type={"image"}
            onChange={(noContentImageUrl: string) => {
              this.props.store.widgetToEdit.eventViewer.noContentImageUrl = noContentImageUrl;
              this.props.forceUpdate();
              this.props.forceUpdate();
            }}
          />

        </IAGroup>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_ArticleLayoutType.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          {(this.props.store.widgetToEdit.eventViewer?.layoutType === "list" || this.props.store.widgetToEdit.eventViewer?.layoutType === "week") ?
            <div style={{ marginBottom: 20, float: "left" }}>
              <label
                style={{
                  color: this.props.store.darkMode ? Helper.darkModeLabelColor : '#333333',
                  width: "100%"
                }}
              >
                {this.localizationService.strings.PlayAdmin_Colors?.toUpperCase()}
              </label>
              <IAColorPicker
                label={this.localizationService.strings.PlayAdmin_BackgroundColor?.toUpperCase()}
                color={this.props.store.widgetToEdit.eventViewer.backgroundColor}
                inTeams={this.props.store.isTeams}
                swatches={this.props.store.profile?.swatches}
                darkMode={this.props.store.darkMode}
                toolTip={<div>{this.localizationService.strings.PlayAdmin_EditSwatches}</div>}
                allowAddingToSwatches
                onChange={(backgroundColor: string) => {
                  this.props.store.widgetToEdit.eventViewer.backgroundColor = backgroundColor;
                  this.props.forceUpdate();
                }}
                showClearButton={false}
                addSwatch={(color: string) => this.props.store.addSwatch(color)}
              />
              <IAColorPicker
                label={this.localizationService.strings.PlayAdmin_TextColor?.toUpperCase()}
                color={this.props.store.widgetToEdit.eventViewer.textColor}
                inTeams={this.props.store.isTeams}
                swatches={this.props.store.profile?.swatches}
                toolTip={<div>{this.localizationService.strings.PlayAdmin_EditSwatches}</div>}
                allowAddingToSwatches
                darkMode={this.props.store.darkMode}
                onChange={(textColor: string) => {
                  this.props.store.widgetToEdit.eventViewer.textColor = textColor;
                  this.props.forceUpdate();
                }}
                showClearButton={false}
                addSwatch={(color: string) => this.props.store.addSwatch(color)}
              />
              <IAColorPicker
                label={this.props.store.widgetToEdit.eventViewer?.layoutType === "week" ? this.localizationService.strings.PlayAdmin_TodayColor?.toUpperCase() : this.localizationService.strings.PlayAdmin_BoxColor?.toUpperCase()}
                color={this.props.store.widgetToEdit.eventViewer.boxColor}
                inTeams={this.props.store.isTeams}
                swatches={this.props.store.profile?.swatches}
                toolTip={<div>{this.localizationService.strings.PlayAdmin_EditSwatches}</div>}
                allowAddingToSwatches
                darkMode={this.props.store.darkMode}
                onChange={(boxColor: string) => {
                  this.props.store.widgetToEdit.eventViewer.boxColor = boxColor;
                  this.props.forceUpdate();
                }}
                defaultColor="transparent"
                showClearButton={true}
                addSwatch={(color: string) => this.props.store.addSwatch(color)}
              />
            </div>
            :
            <>
              <label
                style={{
                  color: this.props.store.darkMode ? Helper.darkModeLabelColor : '#333333',
                }}
              >
                {this.localizationService.strings.PlayAdmin_ColorThemes?.toUpperCase()}
              </label>
              {colorThemes}
              <IAButton
                label={this.localizationService.strings.PlayAdmin_AddColorTheme?.toUpperCase()}
                buttonColor={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                onClick={() => {
                  if (!this.props.store.widgetToEdit?.eventViewer?.colorThemes) {
                    this.props.store.widgetToEdit.eventViewer.colorThemes = [];
                  }
                  this.props.store.widgetToEdit?.eventViewer.colorThemes.push(
                    {
                      textColor: "#333333",
                      backgroundColor: "#ffffff"
                    }
                  );
                  this.props.forceUpdate();
                }}
                showSpinner={this.props.store.workingOnIt}
                borderRadius={5}
                style={{
                  float: "left",
                  position: "relative",
                  clear: "both",
                  marginBottom: 20,
                  marginTop: 10
                }}
              />
            </>
          }
          <RotatorViewSelector
            layoutType={this.props.store.widgetToEdit?.eventViewer?.layoutType}
            boxStyle={this.props.store.widgetToEdit?.eventViewer?.boxStyle}
            backgroundColor={this.props.store.widgetToEdit?.eventViewer?.colorThemes[0].backgroundColor}
            textColor={this.props.store.widgetToEdit?.eventViewer?.colorThemes[0].textColor}
            listBackgroundColor={this.props.store.widgetToEdit?.eventViewer?.backgroundColor}
            listTextColor={this.props.store.widgetToEdit?.eventViewer?.textColor}
            listBoxColor={this.props.store.widgetToEdit?.eventViewer?.boxColor}
            timeBatchBackgroundColor={this.props.store.widgetToEdit?.eventViewer.timeBatchBackgroundColor}
            timeBatchTextColor={this.props.store.widgetToEdit?.eventViewer.timeBatchTextColor}
            timeBatchBorderColor={this.props.store.widgetToEdit?.eventViewer.timeBatchBorderColor}
            image={`https://intraactivestorage.blob.core.windows.net/cdn/play/no-image.webp`}
            contentPosition={this.props.store.widgetToEdit?.eventViewer.contentPosition}
            keepOriginalImage={this.props.store.widgetToEdit?.eventViewer.keepOriginalImage}
            boxDisabled
            showListOption
            showWeekOption
            updateLayout={(layout: "list") => {
              this.props.store.widgetToEdit.eventViewer.layoutType = layout;
              this.props.forceUpdate();
            }}
            updateBoxStyle={(boxStyle: IPlayBoxStyle) => {
              this.props.store.widgetToEdit.eventViewer.boxStyle = boxStyle;
              if (boxStyle === "fullSolid" || boxStyle === "fullTransparent") {
                this.props.store.widgetToEdit.eventViewer.showTimeBox = false;
              }
              this.props.reloadPreview();
            }}
            updateContentPosition={(contentPosition: "left" | "right" | "random") => {
              this.props.store.widgetToEdit.eventViewer.contentPosition = contentPosition;
              this.props.reloadPreview();
            }}
            updateKeepOriginalImage={(keepOriginalImage: boolean) => {
              this.props.store.widgetToEdit.eventViewer.keepOriginalImage = keepOriginalImage;
              this.props.reloadPreview();
            }}
          />
          {this.props.store.widgetToEdit.eventViewer?.layoutType === "week" &&
            <div className={styles.IA_disclaimer}>
              {this.localizationService.strings.PlayAdmin_CanteenMenuDisclaimer}
            </div>
          }
          {(this.props.store.widgetToEdit?.eventViewer.layoutType == undefined || this.props.store.widgetToEdit?.eventViewer.layoutType === "slide") &&
            <IAOptionSelector
              label={this.localizationService.strings.PlayAdmin_TransitionType?.toUpperCase()}
              options={[
                {
                  icon: "Transition Crossfade",
                  key: "crossfade",
                  text: this.localizationService.strings.PlayAdmin_Crossfade
                },
                {
                  icon: "Transition Slide",
                  key: "slide",
                  text: this.localizationService.strings.PlayAdmin_Slide
                },
              ]}
              selected={this.props.store.widgetToEdit?.eventViewer.transition}
              highlightColor={this.props.store.highlightColor}
              compact
              labelStyle={{
                fontSize: "12px"
              }}
              iconSize={30}
              darkMode={this.props.store.darkMode}
              borderRadius={5}
              onChange={(transitionType: any) => {
                this.props.store.widgetToEdit.eventViewer.transition = transitionType;
                this.props.reloadPreview();
              }}
              style={{ clear: "none", marginTop: 20 }}
            />
          }

          <>
            {(this.props.store.widgetToEdit.eventViewer?.layoutType === "list" || this.props.store.widgetToEdit.eventViewer?.layoutType === "week") &&
              <>
                {this.props.store.widgetToEdit.eventViewer.showHeadline && this.props.store.widgetToEdit.eventViewer.layoutType !== "list" &&
                  <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
                }
                <IAToggle
                  labelStyle={{
                    fontSize: "12px",
                    marginTop: 10
                  }}
                  label={this.localizationService.strings.PlayAdmin_ShowWidgetTitle?.toUpperCase()}
                  checked={this.props.store.widgetToEdit.eventViewer.showHeadline != undefined ? this.props.store.widgetToEdit.eventViewer.showHeadline : true}
                  color={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  onChange={() => {
                    this.props.store.widgetToEdit.eventViewer.showHeadline = this.props.store.widgetToEdit.eventViewer.showHeadline != undefined ? !this.props.store.widgetToEdit.eventViewer.showHeadline : false;
                    this.props.forceUpdate();
                    setTimeout(() => {
                      this.props.forceUpdate();
                    }, 1000);
                  }}
                />
                {this.props.store.widgetToEdit.eventViewer && this.props.store.widgetToEdit.eventViewer.showHeadline &&
                  <IAColorPicker
                    label={this.localizationService.strings.PlayAdmin_HeadlineColor?.toUpperCase()}
                    color={this.props.store.widgetToEdit.eventViewer.headlineColor}
                    swatches={this.props.store.profile?.swatches}
                    toolTip={<div>{this.localizationService.strings.PlayAdmin_EditSwatches}</div>}
                    inTeams={this.props.store.isTeams}
                    allowAddingToSwatches
                    darkMode={this.props.store.darkMode}
                    onChange={(headlineColor: string) => {
                      this.props.store.widgetToEdit.eventViewer.headlineColor = headlineColor;
                      this.props.forceUpdate();
                    }}
                    showClearButton={false}
                    addSwatch={(color: string) => this.props.store.addSwatch(color)}
                  />
                }
              </>
            }
            <IAToggle
              style={{
                marginTop: 20
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_MessagesViewerShowTitle?.toUpperCase()}
              checked={this.props.store.widgetToEdit?.eventViewer && this.props.store.widgetToEdit?.eventViewer.showTitle != undefined ? this.props.store.widgetToEdit?.eventViewer.showTitle : true}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.props.store.widgetToEdit.eventViewer.showTitle = this.props.store.widgetToEdit?.eventViewer.showTitle != undefined ? !this.props.store.widgetToEdit?.eventViewer.showTitle : false;
                this.props.reloadPreview();
              }}
            />
            <IAToggle
              style={{
                marginTop: 20
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_MessagesViewerShowText?.toUpperCase()}
              checked={this.props.store.widgetToEdit?.eventViewer && this.props.store.widgetToEdit?.eventViewer.showText != undefined ? this.props.store.widgetToEdit?.eventViewer.showText : true}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.props.store.widgetToEdit.eventViewer.showText = this.props.store.widgetToEdit?.eventViewer.showText != undefined ? !this.props.store.widgetToEdit?.eventViewer.showText : false;
                this.props.reloadPreview();
              }}
            />
            {this.props.store.widgetToEdit?.eventViewer.layoutType === "slide" &&
              <IAToggle
                style={{
                  marginTop: 20
                }}
                labelStyle={{
                  fontSize: "12px"
                }}
                label={this.localizationService.strings.PlayAdmin_MessagesViewerEnableScroll?.toUpperCase()}
                checked={this.props.store.widgetToEdit?.eventViewer && this.props.store.widgetToEdit?.eventViewer.enableScroll != undefined ? this.props.store.widgetToEdit?.eventViewer.enableScroll : false}
                color={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                onChange={() => {
                  this.props.store.widgetToEdit.eventViewer.enableScroll = this.props.store.widgetToEdit?.eventViewer.enableScroll != undefined ? !this.props.store.widgetToEdit?.eventViewer.enableScroll : true;
                  this.props.reloadPreview();
                }}
              />
            }
            {this.props.store.widgetToEdit?.eventViewer.boxStyle !== "postcard" && this.props.store.widgetToEdit?.eventViewer.layoutType === "slide" &&
              <IAToggle
                style={{
                  marginTop: 20
                }}
                labelStyle={{
                  fontSize: "12px"
                }}
                label={this.localizationService.strings.PlayAdmin_ShowProgressBar?.toUpperCase()}
                checked={this.props.store.widgetToEdit.showProgressBar != undefined ? this.props.store.widgetToEdit.showProgressBar : true}
                color={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                onChange={() => {
                  this.props.store.widgetToEdit.showProgressBar = this.props.store.widgetToEdit.showProgressBar != undefined ? !this.props.store.widgetToEdit.showProgressBar : false;
                  this.props.reloadPreview();
                }}
              />
            }
            {this.props.store.widgetToEdit.eventViewer?.layoutType === "list" && (this.props.store.widgetToEdit?.eventViewer.showText || this.props.store.widgetToEdit?.eventViewer.showText == undefined) &&
              <IANumberField
                label={this.localizationService.strings.PlayAdmin_NumberOfTeaserLines?.toUpperCase()}
                number={this.props.store.widgetToEdit.eventViewer?.textLines}
                darkMode={this.props.store.darkMode}
                highlightColor={this.props.store.highlightColor}
                borderRadius={5}
                labelStyle={{
                  fontSize: "12px"
                }}
                onChange={(textLines: number) => {
                  this.props.store.widgetToEdit.eventViewer.textLines = textLines;
                  this.props.reloadPreview();
                }}
                styles={{
                  width: "100%",
                  clear: "both",
                  marginBottom: 10
                }}
              />
            }
            {(!this.props.store.widgetToEdit.eventViewer && !this.props.store.widgetToEdit.sharepointNewsViewer) &&
              <IAToggle
                style={{
                  marginTop: 20
                }}
                labelStyle={{
                  fontSize: "12px"
                }}
                label={this.localizationService.strings.PlayAdmin_MessagesViewerShowAuthor?.toUpperCase()}
                checked={this.props.store.widgetToEdit?.eventViewer && this.props.store.widgetToEdit?.eventViewer.showAuthor != undefined ? this.props.store.widgetToEdit?.eventViewer.showAuthor : true}
                color={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                onChange={() => {
                  this.props.store.widgetToEdit.eventViewer.showAuthor = this.props.store.widgetToEdit?.eventViewer.showAuthor != undefined ? !this.props.store.widgetToEdit?.eventViewer.showAuthor : false;
                  this.props.reloadPreview();
                }}
              />
            }
            {!this.props.store.widgetToEdit.eventViewer &&
              <IAToggle
                style={{
                  marginTop: 20
                }}
                labelStyle={{
                  fontSize: "12px"
                }}
                label={this.localizationService.strings.PlayAdmin_MessagesViewerShowDate?.toUpperCase()}
                checked={this.props.store.widgetToEdit?.eventViewer && this.props.store.widgetToEdit?.eventViewer.showTime != undefined ? this.props.store.widgetToEdit?.eventViewer.showTime : true}
                color={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                onChange={() => {
                  this.props.store.widgetToEdit.eventViewer.showTime = this.props.store.widgetToEdit?.eventViewer.showTime != undefined ? !this.props.store.widgetToEdit?.eventViewer.showTime : false;
                  this.props.reloadPreview();
                }}
              />
            }
            {this.props.store.widgetToEdit.eventViewer &&
              <IAToggle
                style={{
                  marginTop: 20
                }}
                labelStyle={{
                  fontSize: "12px"
                }}
                label={this.localizationService.strings.PlayAdmin_MessagesViewerShowTime?.toUpperCase()}
                checked={this.props.store.widgetToEdit?.eventViewer && this.props.store.widgetToEdit?.eventViewer.showTime != undefined ? this.props.store.widgetToEdit?.eventViewer.showTime : true}
                color={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                onChange={() => {
                  this.props.store.widgetToEdit.eventViewer.showTime = this.props.store.widgetToEdit?.eventViewer.showTime != undefined ? !this.props.store.widgetToEdit?.eventViewer.showTime : false;
                  this.props.reloadPreview();
                }}
              />
            }
            {this.props.store.widgetToEdit.eventViewer &&
              <IAToggle
                style={{
                  marginTop: 20
                }}
                labelStyle={{
                  fontSize: "12px"
                }}
                label={this.localizationService.strings.PlayAdmin_MessagesViewerShowLocation?.toUpperCase()}
                checked={this.props.store.widgetToEdit?.eventViewer && this.props.store.widgetToEdit?.eventViewer.showLocation != undefined ? this.props.store.widgetToEdit?.eventViewer.showLocation : true}
                color={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                onChange={() => {
                  this.props.store.widgetToEdit.eventViewer.showLocation = this.props.store.widgetToEdit?.eventViewer.showLocation != undefined ? !this.props.store.widgetToEdit?.eventViewer.showLocation : false;
                  this.props.reloadPreview();
                }}
              />
            }
            {this.props.store.widgetToEdit.eventViewer && this.props.store.widgetToEdit.eventViewer.boxStyle !== "fullSolid" && this.props.store.widgetToEdit.eventViewer.boxStyle !== "fullTransparent" && this.props.store.widgetToEdit.eventViewer.layoutType !== "week" &&
              <>
                {this.props.store.widgetToEdit?.eventViewer.showTimeBox && this.props.store.widgetToEdit.eventViewer?.layoutType !== "slide" &&
                  <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
                }
                <IAToggle
                  labelStyle={{
                    fontSize: "12px",
                    marginTop: 20
                  }}
                  label={this.localizationService.strings.PlayAdmin_MessagesViewerShowTimeBox?.toUpperCase()}
                  checked={this.props.store.widgetToEdit?.eventViewer && this.props.store.widgetToEdit?.eventViewer.showTimeBox != undefined ? this.props.store.widgetToEdit?.eventViewer.showTimeBox : true}
                  color={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  onChange={() => {
                    this.props.store.widgetToEdit.eventViewer.showTimeBox = this.props.store.widgetToEdit?.eventViewer.showTimeBox != undefined ? !this.props.store.widgetToEdit?.eventViewer.showTimeBox : false;
                    this.props.reloadPreview();
                  }}
                />
                {this.props.store.widgetToEdit?.eventViewer.showTimeBox && this.props.store.widgetToEdit.eventViewer?.layoutType !== "slide" &&
                  <>
                    <IAColorPicker
                      label={this.localizationService.strings.PlayAdmin_TimeBatchBackgroundColor?.toUpperCase()}
                      color={this.props.store.widgetToEdit.eventViewer.timeBatchBackgroundColor}
                      swatches={this.props.store.profile?.swatches}
                      toolTip={<div>{this.localizationService.strings.PlayAdmin_EditSwatches}</div>}
                      inTeams={this.props.store.isTeams}
                      allowAddingToSwatches
                      darkMode={this.props.store.darkMode}
                      onChange={(timeBatchBackgroundColor: string) => {
                        this.props.store.widgetToEdit.eventViewer.timeBatchBackgroundColor = timeBatchBackgroundColor;
                        this.props.forceUpdate();
                      }}
                      defaultColor="transparent"
                      showClearButton={true}
                      addSwatch={(color: string) => this.props.store.addSwatch(color)}
                    />
                    <IAColorPicker
                      label={this.localizationService.strings.PlayAdmin_TimeBatchTextColor?.toUpperCase()}
                      color={this.props.store.widgetToEdit.eventViewer.timeBatchTextColor}
                      swatches={this.props.store.profile?.swatches}
                      toolTip={<div>{this.localizationService.strings.PlayAdmin_EditSwatches}</div>}
                      inTeams={this.props.store.isTeams}
                      allowAddingToSwatches
                      onChange={(timeBatchTextColor: string) => {
                        this.props.store.widgetToEdit.eventViewer.timeBatchTextColor = timeBatchTextColor;
                        this.props.forceUpdate();
                      }}
                      showClearButton={false}
                      addSwatch={(color: string) => this.props.store.addSwatch(color)}
                    />
                    <IAColorPicker
                      label={this.localizationService.strings.PlayAdmin_TimeBatchBorderColor?.toUpperCase()}
                      color={this.props.store.widgetToEdit.eventViewer.timeBatchBorderColor}
                      swatches={this.props.store.profile?.swatches}
                      inTeams={this.props.store.isTeams}
                      darkMode={this.props.store.darkMode}
                      toolTip={<div>{this.localizationService.strings.PlayAdmin_EditSwatches}</div>}
                      allowAddingToSwatches
                      onChange={(timeBatchBorderColor: string) => {
                        this.props.store.widgetToEdit.eventViewer.timeBatchBorderColor = timeBatchBorderColor;
                        this.props.forceUpdate();
                      }}
                      styles={{
                        clear: "both"
                      }}
                      defaultColor="transparent"
                      showClearButton={true}
                      addSwatch={(color: string) => this.props.store.addSwatch(color)}
                    />
                  </>
                }
              </>
            }
          </>


          {this.props.store.widgetToEdit.eventViewer?.layoutType === "week" &&
            <>
              <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
              <MediaFileSelector
                label={this.localizationService.strings.PlayAdmin_BackgroundImage?.toUpperCase()}
                url={this.props.store.widgetToEdit.eventViewer?.backgroundImage}
                type={"image"}
                onChange={(backgroundImage: string) => {
                  this.props.store.widgetToEdit.eventViewer.backgroundImage = backgroundImage;
                  this.props.forceUpdate();
                  this.props.forceUpdate();
                }}
              />
            </>
          }
        </IAGroup>
      </>
    );
  }


}
