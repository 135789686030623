import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { Helper } from '../../../../../../Helper';
import { MediaFileSelector } from '../../../mediaFiles/mediaFileSelector/MediaFileSelector';
import { IAToggle } from '../../../../../toggle/Toggle';
import { IATextField } from '../../../../../textField/TextField';
import { IAGroup } from '../../../../../group/Group';

export interface IProps {
  store?: Store;
  forceUpdate: () => void;
}

@inject('store')
@observer
export class VideoWidgetSettings extends React.Component<IProps> {

  private readonly localizationService = new LocalizationService()

  render(): React.ReactNode {
    return (
      <>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_WidgetSettings?.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <IATextField
            label={this.localizationService.strings.PlayAdmin_Title?.toUpperCase()}
            required
            placeholder={this.localizationService.strings.PlayAdmin_Title}
            text={this.props.store.widgetToEdit.title}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(title: string) => {
              this.props.store.widgetToEdit.title = title;
              this.props.forceUpdate();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20,
              fontSize: 12
            }}
            darkMode={this.props.store.darkMode}
            label={this.localizationService.strings.PlayAdmin_ShowWidgetTitle?.toUpperCase()}
            checked={this.props.store.widgetToEdit.showTitle}
            color={this.props.store.highlightColor}
            onChange={() => {
              this.props.store.widgetToEdit.showTitle = this.props.store.widgetToEdit.showTitle != undefined ? !this.props.store.widgetToEdit.showTitle : true;
              this.props.forceUpdate();
            }}
          />
          {this.props.store.widgetToEdit.type === "video" ?
            <MediaFileSelector
              label={this.localizationService.strings.PlayAdmin_VideoUrl?.toUpperCase()}
              url={this.props.store.widgetToEdit.video.videoUrl}
              placeholder={this.localizationService.strings.PlayAdmin_VideoUrl}
              type={"video"}
              onChange={(videoUrl: string) => {
                this.props.store.widgetToEdit.video.videoUrl = Helper.convertUrlToEmbedUrl(videoUrl);
                this.props.forceUpdate();
              }}
            />
            :
            <IATextField
              label={this.localizationService.strings.PlayAdmin_VideoUrl?.toUpperCase()}
              text={this.props.store.widgetToEdit.video.videoUrl}
              placeholder={this.localizationService.strings.PlayAdmin_VideoUrl}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              borderRadius={5}
              labelStyle={{
                fontSize: "12px"
              }}
              onChange={(videoUrl: string) => {
                if (this.props.store.widgetToEdit.type === "youtube" && videoUrl.includes("youtu")) {
                  this.props.store.widgetToEdit.video.videoUrl = Helper.convertUrlToEmbedUrl(videoUrl);
                } else if (this.props.store.widgetToEdit.type === "vimeo" && videoUrl.includes("vimeo")) {
                  this.props.store.widgetToEdit.video.videoUrl = Helper.convertUrlToEmbedUrl(videoUrl);
                } else if (this.props.store.widgetToEdit.type === "video23") {
                  if (videoUrl?.includes("secret")) {
                    const splittedUrl = videoUrl.split("/secret/");
                    let baseUrl;
                    let id;
                    let token;
                    if (splittedUrl?.length > 1) {
                      baseUrl = splittedUrl[0];
                      const videoParameters = splittedUrl[1].split("/");
                      if (videoParameters?.length > 1) {
                        id = videoParameters[0];
                        token = videoParameters[1];
                        this.props.store.widgetToEdit.video.videoUrl = `${baseUrl}/v.ihtml/player.html?token=${token}&photo%5fid=${id}&source=embed&autoPlay=1&autoMute=1`
                      }
                    }
                  }
                } else {
                  this.props.store.widgetToEdit.video.videoUrl = videoUrl;
                }
                this.props.forceUpdate();
              }}
            />
          }
        </IAGroup>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_ArticleLayoutType.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <IAToggle
            style={{
              fontSize: 12
            }}
            darkMode={this.props.store.darkMode}
            label={this.localizationService.strings.PlayAdmin_FitToView?.toUpperCase()}
            checked={this.props.store.widgetToEdit.video.fitToView}
            color={this.props.store.highlightColor}
            onChange={() => {
              this.props.store.widgetToEdit.video.fitToView = !this.props.store.widgetToEdit.video.fitToView;
              this.props.forceUpdate();
            }}
          />
        </IAGroup>
      </>
    );
  }


}