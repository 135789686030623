import * as React from 'react';
import * as styles from './Box.css';
import { inject, observer } from 'mobx-react';
import { Store } from '../stores';
import { IAButton } from '../../button/Button';
import { LocalizationService } from '../../../services/LocalizationService';
import { IAColorPicker } from '../../colorPicker/ColorPicker';
import { Helper } from '../../../Helper';
import { IAOptionSelector } from '../../optionSelector/OptionSelector';

export interface IProps {
  store?: Store;
  onStyleChange: (style: any) => void;
}

@inject('store')
@observer
export class IABox extends React.Component<IProps> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  private updateStyle(): void {
    const defaultBoxStyle = Helper.defaultBoxStyle;
    if ((this.props.store.boxStyle.backgroundColor === defaultBoxStyle.backgroundColor || this.props.store.boxStyle.backgroundColor == undefined) && (this.props.store.boxStyle.borderColor === defaultBoxStyle.borderColor || this.props.store.boxStyle.borderColor == undefined) && this.props.store.boxStyle.borderStyle === defaultBoxStyle.borderStyle) {
      this.props.store.boxStyle.padding = "0px";
    } else {
      this.props.store.boxStyle.padding = "20px";
    }
    this.props.onStyleChange(this.props.store.boxStyle);
  }

  public render(): JSX.Element {
    let toolTipContentElement = document.getElementById("IA_Box");
    let cursorPositionTop = this.props.store.cursorPositionTop;
    let cursorPositionLeft = this.props.store.cursorPositionLeft;
    if (toolTipContentElement) {
      if (this.props.store.cursorPositionTop + toolTipContentElement.clientHeight > window.innerHeight) {
        cursorPositionTop -= toolTipContentElement.clientHeight + 20;
      }
      if (this.props.store.cursorPositionLeft + toolTipContentElement.clientWidth > window.innerWidth) {
        cursorPositionLeft = window.innerWidth - (toolTipContentElement.clientWidth + 20);
      }
    } else {
      this.forceUpdate();
    }
    return (
      <div
        style={{
          position: "fixed",
          zIndex: 3000001,
          height: "100%",
          width: "100%",
          top: 0,
          left: 0
        }}
        onClick={() => this.props.store.showBoxTool = false}
      >
        <div
          id="IA_Box"
          className={styles.IA_box}
          style={{
            top: this.props.store.isMobile ? undefined : cursorPositionTop,
            bottom: this.props.store.isMobile ? 80 : undefined,
            left: this.props.store.isMobile ? "20px" : cursorPositionLeft,
            height: "auto",
            width: this.props.store.isMobile ? "calc(100% - 40px)" : 370,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <IAColorPicker
            label={this.localizationService.strings.AppAdmin_BackgroundColor}
            color={this.props.store.boxStyle?.backgroundColor}
            onChange={(backgroundColor: string) => {
              this.props.store.boxStyle.backgroundColor = backgroundColor;
              this.updateStyle();
            }}
            showClearButton={false}
          />
          <IAColorPicker
            label={this.localizationService.strings.AppAdmin_BorderColor}
            color={this.props.store.boxStyle?.borderColor}
            onChange={(borderColor: string) => {
              if (borderColor != undefined && this.props.store.boxStyle.borderStyle === "none") {
                this.props.store.boxStyle.borderStyle = "solid";
              }
              if (borderColor == undefined) {
                this.props.store.boxStyle.borderStyle = "none";
              }
              this.props.store.boxStyle.borderColor = borderColor;
              this.updateStyle();
            }}
            showClearButton={false}
          />
          <IAOptionSelector
            label={this.localizationService.strings.Messages_Settings_BorderRadius}
            compact
            borderRadius={5}
            labelStyle={{ fontSize: 14 }}
            options={[
              {
                image: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/borderRadius0.png",
                key: "0"
              },
              {
                image: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/borderRadius5.png",
                key: "5"
              },
              {
                image: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/borderRadius10.png",
                key: "10"
              },
              {
                image: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/borderRadius20.png",
                key: "20"
              },
            ]}
            selected={`${this.props.store.boxStyle?.borderRadius}`}
            highlightColor={this.props.store.highlightColor}
            onChange={(borderRadius: string) => {
              if (borderRadius !== "0" && this.props.store.boxStyle.borderStyle === "none" && this.props.store.boxStyle.backgroundColor == undefined) {
                this.props.store.boxStyle.borderStyle = "solid";
              }
              if (borderRadius !== "0" && this.props.store.boxStyle.borderColor == undefined && this.props.store.boxStyle.backgroundColor == undefined) {
                this.props.store.boxStyle.borderColor = "#333333";
              }
              this.props.store.boxStyle.borderRadius = parseInt(borderRadius);
              this.updateStyle();
            }}
            style={{ clear: "none", marginTop: 20 }}
          />
          <IAOptionSelector
            label={this.localizationService.strings.Editor_BorderStyle}
            compact
            borderRadius={5}
            labelStyle={{ fontSize: 14 }}
            options={[
              {
                image: " ",
                key: "none"
              },
              {
                image: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/border-style-solid.png",
                key: "solid"
              },
              {
                image: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/border-style-dotted.png",
                key: "dotted"
              },
              {
                image: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/border-style-dashed.png",
                key: "dashed"
              },
            ]}
            selected={this.props.store.boxStyle?.borderStyle}
            highlightColor={this.props.store.highlightColor}
            onChange={(borderStyle: string) => {
              if (borderStyle !== "none" && this.props.store.boxStyle.borderColor == undefined) {
                this.props.store.boxStyle.borderColor = "#333333";
              }
              this.props.store.boxStyle.borderStyle = borderStyle;
              this.updateStyle();
            }}
            style={{ clear: "none", marginTop: 20 }}
          />
          <IAButton
            label={this.localizationService.strings.Editor_Update}
            buttonColor={this.props.store.highlightColor}
            borderRadius={5}
            onClick={() => {
              this.props.store.showBoxTool = false;
            }}
            style={{
              marginTop: 20,
              clear: "both",
              float: "left",
            }}
          />
          <IAButton
            label={this.localizationService.strings.Editor_RemoveBox}
            buttonColor={"gray"}
            borderRadius={5}
            onClick={() => {
              this.props.store.boxStyle = Helper.defaultBoxStyle;
              this.updateStyle();
              this.props.store.showBoxTool = false;
            }}
            style={{
              marginTop: 20,
              float: "left",
              marginLeft: 10,
            }}
          />
        </div>
      </div>
    );
  }
}