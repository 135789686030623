import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from '../WidgetEditPanel.css';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IAToggle } from '../../../../../toggle/Toggle';
import PlayService from '../../../../../../services/PlayService';
import { IFeed } from '../../../../../../interfaces/IFeed';
import { IAButton } from '../../../../../button/Button';
import { Helper } from '../../../../../../Helper';
import { MediaFileSelector } from '../../../mediaFiles/mediaFileSelector/MediaFileSelector';
import { IAColorTheme } from '../../../../../colorTheme/ColorTheme';
import { IATextField } from '../../../../../textField/TextField';
import { IAIcon } from '../../../../../icon/Icon';
import { IASpinner } from '../../../../../spinner/Spinner';
import { IADropDown } from '../../../../../dropDown/DropDown';
import { IANumberField } from '../../../../../numberField/NumberField';
import { IAGroup } from '../../../../../group/Group';
import { RotatorViewSelector } from '../../../rotatorViewSelector/RotatorViewSelector';
import { IPlayBoxStyle } from '../../../../../../interfaces/IPlay';
import { IAOptionSelector } from '../../../../../optionSelector/OptionSelector';

export interface IProps {
  store?: Store;
  forceUpdate: () => void;
  reloadPreview: () => void;
  loadingContent: (loading: boolean) => void;
}

export interface IState {
  feeds?: IFeed[];
  loadingFeeds?: boolean;
  colorThemeForPreview?: any;
  feedInstances?: string;
}

@inject('store')
@observer
export class MessagesWidgetSettings extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      feeds: [],
      loadingFeeds: true,
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  componentDidMount() {
    this.getAvailableInstances();
  }

  private async getAvailableInstances() {
    this.setState({ loadingFeeds: true });
    let feeds;
    if (this.props.store.widgetToEdit.messageViewer || this.props.store.widgetToEdit.messageViewer) {
      feeds = await PlayService.getProfileIntegrations(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id, this.props.store.widgetToEdit.messageViewer ? 'messages' : 'events')
      this.setState({ feeds, loadingFeeds: false });
    } else {
      feeds = await PlayService.getSharePointProfileNewsSites(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id)
      this.setState({ feeds, loadingFeeds: false });
    }
    if (!this.props.store.widgetToEdit.messageViewer?.feedId) {
      this.onFeedChange(feeds[0].id);
    }
  }

  private async onFeedChange(feedId: string) {
    this.props.loadingContent(true);
    this.props.store.widgetToEdit.messageViewer.feedId = feedId;
    this.props.store.widgetToEdit.messageViewer.messages = await PlayService.getFeed(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id, 'messages', this.props.store.widgetToEdit?.messageViewer.feedId)
    this.props.forceUpdate();
    setTimeout(() => {
      this.props.forceUpdate();
      this.props.loadingContent(false);
    }, 500);
    // Instances on feed
    this.state.feeds?.forEach((feed: IFeed) => {
      if (feed.id === feedId && feed.instances?.length > 0) {
        let feeds = "";
        feed.instances?.forEach((feedInstance: string, index) => {
          feeds += feedInstance;
          if (index < feed.instances.length - 1) {
            feeds += ", ";
          }
        });
        this.setState({ feedInstances: feeds });
      }
    })
  }

  render(): React.ReactNode {
    let availableFeeds = this.state.feeds?.map(feed => {
      return {
        key: feed.id,
        value: feed.title
      }
    });
    const colorThemes = [];
    if (this.props.store.widgetToEdit?.messageViewer?.colorThemes?.length > 0) {
      this.props.store.widgetToEdit?.messageViewer.colorThemes.forEach((colorTheme, index) => {
        colorThemes.push(
          <IAColorTheme
            key={"colorTheme_" + index}
            backgroundColor={colorTheme.backgroundColor}
            textColor={colorTheme.textColor}
            disableTextColor={this.props.store.widgetToEdit?.workplaceFeedViewer != undefined}
            highlightColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            swatches={this.props.store.profile?.swatches}
            inTeams
            remove={() => {
              this.props.store.widgetToEdit?.messageViewer.colorThemes.splice(index, 1);
              this.props.forceUpdate();
            }}
            allowAddingToSwatches={false}
            setBackgroundColor={(backgroundColor) => {
              colorTheme.backgroundColor = backgroundColor;
              this.props.forceUpdate();
            }}
            setTextColor={(textColor) => {
              colorTheme.textColor = textColor;
              this.props.forceUpdate();
            }}
          />
        )
      });
    }
    const groups: JSX.Element[] = [];
    if (this.props.store.widgetToEdit.workplaceFeedViewer?.groups?.length > 0) {
      this.props.store.widgetToEdit.workplaceFeedViewer.groups.forEach((group: string, index) => {
        groups.push(
          <div className={styles.IA_group}>
            <div className={styles.IA_groupId}>
              {group.toUpperCase()}
            </div>
            <IAIcon
              url='https://intraactivestorage.blob.core.windows.net/cdn/icons/Delete/SVG/ic_fluent_delete_48_regular.svg'
              size={22}
              color={"#333333"}
              style={{
                float: "left",
                marginLeft: 10,
                marginTop: 2
              }}
              onClick={() => {
                this.props.store.widgetToEdit.workplaceFeedViewer.groups.splice(index, 1);
                this.forceUpdate()
                this.setState({ loadingFeeds: true });
                PlayService.getWorkplaceFeed(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id, this.props.store.widgetToEdit.workplaceFeedViewer.groups).then((feed) => {
                  this.props.store.widgetToEdit.workplaceFeedViewer.feed = feed;
                  this.setState({ loadingFeeds: false });
                  this.props.forceUpdate();
                  setTimeout(() => {
                    this.props.forceUpdate();
                  }, 500);
                });
              }}
            />
          </div>
        );
      });
    }
    return (
      <>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_WidgetSettings?.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <IATextField
            label={this.localizationService.strings.PlayAdmin_Title?.toUpperCase()}
            required
            placeholder={this.localizationService.strings.PlayAdmin_Title}
            text={this.props.store.widgetToEdit.title}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(title: string) => {
              this.props.store.widgetToEdit.title = title;
              this.props.forceUpdate();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20,
              fontSize: 12
            }}
            darkMode={this.props.store.darkMode}
            label={this.localizationService.strings.PlayAdmin_ShowWidgetTitle?.toUpperCase()}
            checked={this.props.store.widgetToEdit.showTitle}
            color={this.props.store.highlightColor}
            onChange={() => {
              this.props.store.widgetToEdit.showTitle = this.props.store.widgetToEdit.showTitle != undefined ? !this.props.store.widgetToEdit.showTitle : true;
              this.props.forceUpdate();
            }}
          />
          <div style={{
            float: "left",
            width: "100%",
            marginTop: 20,
            color: this.props.store.darkMode ? "#a8a8a8" : "#333333"
          }}>
            <label>{this.localizationService.strings.PlayAdmin_SelectFeed?.toUpperCase()}</label>
            {this.state.loadingFeeds ?
              <IASpinner
                color={this.props.store.highlightColor}
                style={{
                  margin: "18px auto"
                }}
              />
              :
              <IADropDown
                selectedOption={this.props.store.widgetToEdit.messageViewer?.feedId}
                options={availableFeeds}
                darkMode={this.props.store.darkMode}
                highlightColor={this.props.store.highlightColor}
                borderRadius={5}
                onChange={(id: string) => {
                  this.onFeedChange(id)
                }}
                labelStyle={{
                  fontSize: "12px"
                }}
                style={{
                  marginTop: 20,
                  marginBottom: 10,
                  width: "100%"
                }}
              />
            }
            {this.props.store.widgetToEdit.messageViewer?.feedId && this.state.feedInstances &&
              <div
                style={{
                  fontSize: 12,
                  color: "gray",
                  marginBottom: 5
                }}
              >
                {`${this.localizationService.strings.PlayAdmin_InstancesInFeed}: ${this.state.feedInstances}`}
              </div>
            }
          </div>
          <IANumberField
            label={this.localizationService.strings.PlayAdmin_MinimumDuration?.toUpperCase()}
            number={this.props.store.widgetToEdit.messageViewer.duration}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(duration: number) => {
              this.props.store.widgetToEdit.messageViewer.duration = duration;
              this.props.reloadPreview()
            }}
            styles={{
              width: "100%",
              clear: "both"
            }}
          />
          <IANumberField
            label={this.localizationService.strings.PlayAdmin_AmountOfMessagesToShow?.toUpperCase()}
            number={this.props.store.widgetToEdit.messageViewer?.amountToShow}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            min={1}
            max={50}
            onChange={(amountToShow: number) => {
              this.props.store.widgetToEdit.messageViewer.amountToShow = amountToShow;
              this.onFeedChange(this.props.store.widgetToEdit.messageViewer.feedId)
            }}
            styles={{
              width: "100%",
              clear: "both"
            }}
          />
          <MediaFileSelector
            label={this.localizationService.strings.PlayAdmin_NoContentImageUrl?.toUpperCase()}
            url={this.props.store.widgetToEdit?.messageViewer.noContentImageUrl ? this.props.store.widgetToEdit?.messageViewer.noContentImageUrl : this.localizationService.strings.Player_NoContentUrl}
            type={"image"}
            onChange={(noContentImageUrl: string) => {
              this.props.store.widgetToEdit.messageViewer.noContentImageUrl = noContentImageUrl;
              this.props.forceUpdate();
              this.props.forceUpdate();
            }}
          />
        </IAGroup>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_ArticleLayoutType.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <label
            style={{
              color: this.props.store.darkMode ? Helper.darkModeLabelColor : '#333333',
              marginTop: 20
            }}
          >
            {this.localizationService.strings.PlayAdmin_ColorThemes?.toUpperCase()}
          </label>
          {colorThemes}
          <IAButton
            label={this.localizationService.strings.PlayAdmin_AddColorTheme?.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onClick={() => {
              if (!this.props.store.widgetToEdit?.messageViewer?.colorThemes) {
                this.props.store.widgetToEdit.messageViewer.colorThemes = [];
              }
              this.props.store.widgetToEdit?.messageViewer.colorThemes.push(
                {
                  textColor: "#333333",
                  backgroundColor: "#ffffff"
                }
              );
              this.props.forceUpdate();
            }}
            showSpinner={this.props.store.workingOnIt}
            borderRadius={5}
            style={{
              float: "left",
              position: "relative",
              clear: "both",
              marginBottom: 20,
              marginTop: 10
            }}
          />
          <RotatorViewSelector
            layoutType={"slide"}
            boxStyle={this.props.store.widgetToEdit?.messageViewer?.boxStyle}
            backgroundColor={this.props.store.widgetToEdit?.messageViewer?.colorThemes[0].backgroundColor}
            textColor={this.props.store.widgetToEdit?.messageViewer?.colorThemes[0].textColor}
            image={`https://intraactivestorage.blob.core.windows.net/cdn/play/no-image.webp`}
            contentPosition={this.props.store.widgetToEdit?.messageViewer.contentPosition}
            keepOriginalImage={this.props.store.widgetToEdit?.messageViewer.keepOriginalImage}
            boxDisabled
            updateLayout={() => { }}
            updateBoxStyle={(boxStyle: IPlayBoxStyle) => {
              this.props.store.widgetToEdit.messageViewer.boxStyle = boxStyle;
              this.props.reloadPreview();
            }}
            updateContentPosition={(contentPosition: "left" | "right" | "random") => {
              this.props.store.widgetToEdit.messageViewer.contentPosition = contentPosition;
              this.props.reloadPreview();
            }}
            updateKeepOriginalImage={(keepOriginalImage: boolean) => {
              this.props.store.widgetToEdit.messageViewer.keepOriginalImage = keepOriginalImage;
              this.props.reloadPreview();
            }}
          />
          <IAOptionSelector
            label={this.localizationService.strings.PlayAdmin_TransitionType?.toUpperCase()}
            options={[
              {
                icon: "Transition Crossfade",
                key: "crossfade",
                text: this.localizationService.strings.PlayAdmin_Crossfade
              },
              {
                icon: "Transition Slide",
                key: "slide",
                text: this.localizationService.strings.PlayAdmin_Slide
              },
            ]}
            selected={this.props.store.widgetToEdit?.messageViewer.transition}
            highlightColor={this.props.store.highlightColor}
            compact
            labelStyle={{
              fontSize: "12px"
            }}
            iconSize={30}
            darkMode={this.props.store.darkMode}
            borderRadius={5}
            onChange={(transitionType: any) => {
              this.props.store.widgetToEdit.messageViewer.transition = transitionType;
              this.props.reloadPreview();
            }}
            style={{ clear: "none", marginTop: 20 }}
          />
          <IAToggle
            style={{
              marginTop: 20
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            label={this.localizationService.strings.PlayAdmin_MessagesViewerShowQR?.toUpperCase()}
            checked={this.props.store.widgetToEdit?.messageViewer && this.props.store.widgetToEdit?.messageViewer.showQRLink != undefined ? this.props.store.widgetToEdit?.messageViewer.showQRLink : true}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onChange={() => {
              this.props.store.widgetToEdit.messageViewer.showQRLink = this.props.store.widgetToEdit?.messageViewer.showQRLink != undefined ? !this.props.store.widgetToEdit?.messageViewer.showQRLink : false;
              this.props.reloadPreview();
            }}
          />
          {this.props.store.widgetToEdit?.messageViewer.showQRLink &&
            <>
              <div className={styles.IA_info}>{this.localizationService.strings.PlayAdmin_QRcodeOnMessagesTooltipPart1} <a href="https://docs.intraactive.net/?doc=/intraactive-intranet/messages-manage-messages/#section-header-two-a2dhj" target="_blank">{this.localizationService.strings.PlayAdmin_QRcodeOnMessagesTooltipPart2}</a> {this.localizationService.strings.PlayAdmin_QRcodeOnMessagesTooltipPart3}</div>
              <IATextField
                placeholder={this.localizationService.strings.PlayAdmin_LinkText}
                text={this.props.store.widgetToEdit.messageViewer.linkText}
                darkMode={this.props.store.darkMode}
                highlightColor={this.props.store.highlightColor}
                borderRadius={5}
                label={this.localizationService.strings.PlayAdmin_QRText?.toUpperCase()}
                style={{
                  marginTop: 20
                }}
                labelStyle={{
                  fontSize: "12px"
                }}
                inputFieldStyle={{
                  marginBottom: 10
                }}
                onChange={(text: string) => {
                  this.props.store.widgetToEdit.messageViewer.linkText = text;
                  this.props.reloadPreview();
                }}
              />
            </>
          }
          {this.props.store.widgetToEdit?.messageViewer?.boxStyle !== "onlyMedia" &&
            <IAToggle
              style={{
                marginTop: 20
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_MessagesViewerShowTitle?.toUpperCase()}
              checked={this.props.store.widgetToEdit?.messageViewer && this.props.store.widgetToEdit?.messageViewer.showTitle != undefined ? this.props.store.widgetToEdit?.messageViewer.showTitle : true}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.props.store.widgetToEdit.messageViewer.showTitle = this.props.store.widgetToEdit?.messageViewer.showTitle != undefined ? !this.props.store.widgetToEdit?.messageViewer.showTitle : false;
                this.props.reloadPreview();
              }}
            />
          }
          {this.props.store.widgetToEdit?.messageViewer?.boxStyle !== "onlyMedia" &&
            <IAToggle
              style={{
                marginTop: 20
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_MessagesViewerShowText?.toUpperCase()}
              checked={this.props.store.widgetToEdit?.messageViewer && this.props.store.widgetToEdit?.messageViewer.showText != undefined ? this.props.store.widgetToEdit?.messageViewer.showText : true}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.props.store.widgetToEdit.messageViewer.showText = this.props.store.widgetToEdit?.messageViewer.showText != undefined ? !this.props.store.widgetToEdit?.messageViewer.showText : false;
                this.props.reloadPreview();
              }}
            />
          }
          {this.props.store.widgetToEdit?.messageViewer?.boxStyle !== "onlyMedia" &&
            <IAToggle
              style={{
                marginTop: 20
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_MessagesViewerEnableScroll?.toUpperCase()}
              checked={this.props.store.widgetToEdit?.messageViewer && this.props.store.widgetToEdit?.messageViewer.enableScroll != undefined ? this.props.store.widgetToEdit?.messageViewer.enableScroll : false}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.props.store.widgetToEdit.messageViewer.enableScroll = this.props.store.widgetToEdit?.messageViewer.enableScroll != undefined ? !this.props.store.widgetToEdit?.messageViewer.enableScroll : true;
                this.onFeedChange(this.props.store.widgetToEdit?.messageViewer.feedId);
                this.props.reloadPreview();
              }}
            />
          }
          {this.props.store.widgetToEdit?.messageViewer?.boxStyle !== "onlyMedia" &&
            <IAToggle
              style={{
                marginTop: 20
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_ShowProgressBar?.toUpperCase()}
              checked={this.props.store.widgetToEdit.showProgressBar != undefined ? this.props.store.widgetToEdit.showProgressBar : true}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.props.store.widgetToEdit.showProgressBar = this.props.store.widgetToEdit.showProgressBar != undefined ? !this.props.store.widgetToEdit.showProgressBar : false;
                this.props.reloadPreview();
              }}
            />
          }
          {this.props.store.widgetToEdit?.messageViewer?.boxStyle !== "onlyMedia" &&
            <IAToggle
              style={{
                marginTop: 20
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_MessagesViewerShowAuthor?.toUpperCase()}
              checked={this.props.store.widgetToEdit?.messageViewer && this.props.store.widgetToEdit?.messageViewer.showAuthor != undefined ? this.props.store.widgetToEdit?.messageViewer.showAuthor : true}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.props.store.widgetToEdit.messageViewer.showAuthor = this.props.store.widgetToEdit?.messageViewer.showAuthor != undefined ? !this.props.store.widgetToEdit?.messageViewer.showAuthor : false;
                this.props.reloadPreview();
              }}
            />
          }
          {this.props.store.widgetToEdit?.messageViewer?.boxStyle !== "onlyMedia" &&
            <IAToggle
              style={{
                marginTop: 20
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_MessagesViewerShowDate?.toUpperCase()}
              checked={this.props.store.widgetToEdit?.messageViewer && this.props.store.widgetToEdit?.messageViewer.showTime != undefined ? this.props.store.widgetToEdit?.messageViewer.showTime : true}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.props.store.widgetToEdit.messageViewer.showTime = this.props.store.widgetToEdit?.messageViewer.showTime != undefined ? !this.props.store.widgetToEdit?.messageViewer.showTime : false;
                this.props.reloadPreview();
              }}
            />
          }
          {this.props.store.widgetToEdit?.messageViewer?.boxStyle !== "onlyMedia" &&
            <IAToggle
              style={{
                marginTop: 20
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_MessagesViewerShowGallery?.toUpperCase()}
              checked={this.props.store.widgetToEdit?.messageViewer?.showGallery}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.props.store.widgetToEdit.messageViewer.showGallery = !!!this.props.store.widgetToEdit.messageViewer.showGallery;
                this.props.reloadPreview();
              }}
            />
          }
          {this.props.store.widgetToEdit?.messageViewer?.boxStyle !== "onlyMedia" &&
            <IAToggle
              style={{
                marginTop: 20
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_MessagesViewerShowVideo?.toUpperCase()}
              checked={this.props.store.widgetToEdit?.messageViewer?.showVideo}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.props.store.widgetToEdit.messageViewer.showVideo = !!!this.props.store.widgetToEdit.messageViewer.showVideo;
                this.props.reloadPreview();
              }}
            />
          }
        </IAGroup>
      </>
    );
  }


}
