import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import * as React from 'react';
import * as styles from './PowerBI.css';

export interface IProps {
  reportId: string;
  embedUrl: string;
  accessToken: string;
  pageName?: string;
}

export interface IState {
}
export class PowerBI extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
    };
    
  }

  public render(): JSX.Element {

    return <>
      <PowerBIEmbed
        embedConfig = {{
          type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
          id: this.props.reportId,
          embedUrl: this.props.embedUrl,
          accessToken: this.props.accessToken,
          tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
          pageName: this.props.pageName,
          settings: {           
            panes: {
              filters: {
                expanded: false,
                visible: false
              },
              pageNavigation: {
                visible: false
              }
            },
            background: models.BackgroundType.Transparent
          }
        }}

        eventHandlers = {
          new Map([
            ['loaded', function () {console.log('Report loaded');}],
            ['rendered', function () {console.log('Report rendered');}],
            ['error', function (event) {console.log(event.detail);}],
            ['visualClicked', () => console.log('visual clicked')],
            ['pageChanged', (event) => console.log(event)],
          ])
        }

        cssClassName = { styles.IA_PowerBI_Report }

        // getEmbeddedComponent = { (embeddedReport) => {
        // 	var report = embeddedReport as Report;
        // }}
      />
    </>
  }
}
