exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_canteenMenu_1R6S5-lTURnaO958mn_zgn {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  box-sizing: border-box;\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n}\n\n.IA_canteenMenuOverlay_1TN1LaRawr6WgIZKzBgvIa {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n}\n\n.IA_headline_3C0CkZmmYWYwNSj5roHEq {\n  width: 100%;\n  text-align: center;\n  position: relative;\n}\n\n.IA_column_12_JbSZWgoV7roJlXPqI9O {\n  float: left;\n  box-sizing: border-box;\n  height: 100%;\n  position: relative;\n}", ""]);

// Exports
exports.locals = {
	"IA_canteenMenu": "IA_canteenMenu_1R6S5-lTURnaO958mn_zgn",
	"IA_canteenMenuOverlay": "IA_canteenMenuOverlay_1TN1LaRawr6WgIZKzBgvIa",
	"IA_headline": "IA_headline_3C0CkZmmYWYwNSj5roHEq",
	"IA_column": "IA_column_12_JbSZWgoV7roJlXPqI9O"
};