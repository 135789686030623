import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IAToggle } from '../../../../../toggle/Toggle';
import { IATextField } from '../../../../../textField/TextField';
import { IAGroup } from '../../../../../group/Group';
import { IPlayBoxStyle, IPlayRelesysContentModule } from '../../../../../../interfaces/IPlay';
import PlayService from '../../../../../../services/PlayService';
import { IADropDown } from '../../../../../dropDown/DropDown';
import { IASpinner } from '../../../../../spinner/Spinner';
import { IANumberField } from '../../../../../numberField/NumberField';
import { MediaFileSelector } from '../../../mediaFiles/mediaFileSelector/MediaFileSelector';
import { IAColorTheme } from '../../../../../colorTheme/ColorTheme';
import { IAButton } from '../../../../../button/Button';
import { Helper } from '../../../../../../Helper';
import { RotatorViewSelector } from '../../../rotatorViewSelector/RotatorViewSelector';

export interface IProps {
  store?: Store;
  forceUpdate: () => void;
  reloadPreview: () => void;
  loadingContent: (loading: boolean) => void
}

export interface IState {
  isLoadingContentModules: boolean;
  contentModules: IPlayRelesysContentModule[];
}

@inject('store')
@observer
export class RelesysWidgetSettings extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoadingContentModules: true,
      contentModules: []
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentDidMount(): void {
    this.getRelesysContentModules();
  }

  private getRelesysContentModules(): void{
    PlayService.getRelesysContentModules(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id).then(contentModules => {
      this.setState({ contentModules });
    }).finally(() => {
      this.setState({ isLoadingContentModules: false })
    });
  }

  private getRelesysContentModulesDropdownOptions(): { key: string, value: string, disabled?: boolean }[] {
    const modules = [{
      key: "undefined",
      value: " "
    }];

    if (!this.state.contentModules) {
      return modules;
    }
    
    return modules.concat(this.state.contentModules?.map(contentModule => {
      return {
        key: contentModule.id,
        value: contentModule.name,
        disabled: false
      }
    }));
  }

  private async onContentModuleChange() {
    this.props.loadingContent(true);
    PlayService.getRelesysContent(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id, this.props.store.widgetToEdit.relesys.contentModuleId, this.props.store.widgetToEdit.relesys?.amountToShow ?? 5)
      .then(content => {
        this.props.store.widgetToEdit.relesys.relesysContent = content;
        console.log(content);
      })
      .finally(() => this.props.loadingContent(false));
  }

  render(): React.ReactNode {
    const colorThemes = [];
    if (this.props.store.widgetToEdit?.relesys?.colorThemes?.length > 0) {
      this.props.store.widgetToEdit?.relesys.colorThemes.forEach((colorTheme, index) => {
        colorThemes.push(
          <IAColorTheme
            key={"colorTheme_" + index}
            backgroundColor={colorTheme.backgroundColor}
            textColor={colorTheme.textColor}
            highlightColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            swatches={this.props.store.profile?.swatches}
            inTeams
            remove={() => {
              this.props.store.widgetToEdit?.relesys.colorThemes.splice(index, 1);
              this.props.forceUpdate();
            }}
            allowAddingToSwatches={false}
            setBackgroundColor={(backgroundColor) => {
              colorTheme.backgroundColor = backgroundColor;
              this.props.forceUpdate();
            }}
            setTextColor={(textColor) => {
              colorTheme.textColor = textColor;
              this.props.forceUpdate();
            }}
          />
        )
      });
    }
    return (
      <>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_WidgetSettings?.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <div style={{
            display: "flex",
            flexDirection: "column"
          }}>
            <IATextField
              label={this.localizationService.strings.PlayAdmin_Title?.toUpperCase()}
              required
              placeholder={this.localizationService.strings.PlayAdmin_Title}
              text={this.props.store.widgetToEdit.title}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              borderRadius={5}
              labelStyle={{
                fontSize: "12px"
              }}
              onChange={(title: string) => {
                this.props.store.widgetToEdit.title = title;
                this.props.forceUpdate();
              }}
            />
            <IAToggle
              style={{
                marginTop: 20,
                fontSize: 12
              }}
              darkMode={this.props.store.darkMode}
              label={this.localizationService.strings.PlayAdmin_ShowWidgetTitle?.toUpperCase()}
              checked={this.props.store.widgetToEdit.showTitle}
              color={this.props.store.highlightColor}
              onChange={() => {
                this.props.store.widgetToEdit.showTitle = this.props.store.widgetToEdit.showTitle != undefined ? !this.props.store.widgetToEdit.showTitle : true;
                this.props.forceUpdate();
              }}
            />
            {this.state.isLoadingContentModules ?
              <IASpinner
                color={this.props.store.highlightColor}
              />
            :
              <>
                <IADropDown
                  label={"RELESYS CONTENT MODULE"}
                  selectedOption={this.props.store.widgetToEdit?.relesys?.contentModuleId}
                  options={this.getRelesysContentModulesDropdownOptions()}
                  onChange={(contentModuleId: string) => {
                    console.log(contentModuleId);
                    this.props.store.widgetToEdit.relesys.contentModuleId = contentModuleId;
                    this.onContentModuleChange();
                  }}
                  style={{
                    float: "left",
                    width: "100%"
                  }}
                  borderRadius={5}
                  highlightColor={this.props.store.highlightColor}
                />
                <IANumberField
                  label={this.localizationService.strings.PlayAdmin_AmountOfMessagesToShow?.toUpperCase()}
                  number={this.props.store.widgetToEdit.relesys?.amountToShow}
                  darkMode={this.props.store.darkMode}
                  highlightColor={this.props.store.highlightColor}
                  borderRadius={5}
                  labelStyle={{
                    fontSize: "12px"
                  }}
                  min={1}
                  max={5}
                  onChange={(amountToShow: number) => {
                    this.props.store.widgetToEdit.relesys.amountToShow = amountToShow;
                    this.onContentModuleChange();
                  }}
                  styles={{
                    width: "100%",
                    clear: "both"
                  }}
                />
                <IANumberField
                  label={this.localizationService.strings.PlayAdmin_MinimumDuration?.toUpperCase()}
                  number={this.props.store.widgetToEdit?.relesys.duration}
                  darkMode={this.props.store.darkMode}
                  highlightColor={this.props.store.highlightColor}
                  borderRadius={5}
                  labelStyle={{
                    fontSize: "12px"
                  }}
                  onChange={(duration: number) => {
                    this.props.store.widgetToEdit.relesys.duration = duration;
                    this.props.forceUpdate();
                  }}
                  styles={{
                    width: "100%",
                    clear: "both"
                  }}
                />
                <MediaFileSelector
                  label={this.localizationService.strings.PlayAdmin_NoContentImageUrl?.toUpperCase()}
                  url={this.props.store.widgetToEdit?.relesys.noContentImageUrl ? this.props.store.widgetToEdit?.relesys.noContentImageUrl : this.localizationService.strings.Player_NoContentUrl}
                  type={"image"}
                  onChange={(noContentImageUrl: string) => {
                    this.props.store.widgetToEdit.relesys.noContentImageUrl = noContentImageUrl;
                    this.props.forceUpdate();
                    this.props.forceUpdate();
                  }}
                />
              </>
            }
          </div>
        </IAGroup>
        {!this.state.isLoadingContentModules &&
          <IAGroup
            label={this.localizationService.strings.PlayAdmin_ArticleLayoutType.toUpperCase()}
            darkMode={this.props.store.darkMode}
          >
            <label
              style={{
                color: this.props.store.darkMode ? Helper.darkModeLabelColor : '#333333',
                marginTop: 20
              }}
            >
              {this.localizationService.strings.PlayAdmin_ColorThemes?.toUpperCase()}
            </label>
            {colorThemes}
            <IAButton
              label={this.localizationService.strings.PlayAdmin_AddColorTheme?.toUpperCase()}
              buttonColor={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onClick={() => {
                if (!this.props.store.widgetToEdit?.relesys?.colorThemes) {
                  this.props.store.widgetToEdit.relesys.colorThemes = [];
                }
                this.props.store.widgetToEdit?.relesys.colorThemes.push(
                  {
                    textColor: "#333333",
                    backgroundColor: "#ffffff"
                  }
                );
                this.props.forceUpdate();
              }}
              showSpinner={this.props.store.workingOnIt}
              borderRadius={5}
              style={{
                float: "left",
                position: "relative",
                clear: "both",
                marginBottom: 20,
                marginTop: 10
              }}
            />
            <RotatorViewSelector
              layoutType={"slide"}
              boxStyle={this.props.store.widgetToEdit?.relesys?.boxStyle}
              backgroundColor={this.props.store.widgetToEdit?.relesys?.colorThemes[0].backgroundColor}
              textColor={this.props.store.widgetToEdit?.relesys?.colorThemes[0].textColor}
              image={`https://intraactivestorage.blob.core.windows.net/cdn/play/no-image.webp`}
              contentPosition={this.props.store.widgetToEdit?.relesys.contentPosition}
              contentVerticalPosition={this.props.store.widgetToEdit?.relesys?.contentVerticalPosition}
              keepOriginalImage={this.props.store.widgetToEdit?.relesys.keepOriginalImage}
              updateLayout={() => { }}
              boxStyleRoundCorner={this.props.store.widgetToEdit?.relesys?.boxStyleRoundCorner}
              contentWidth={this.props.store.widgetToEdit?.relesys?.contentWidth}
              updateContentVerticalPosition={(contentVerticalPosition: "top" | "bottom") => {
                this.props.store.widgetToEdit.relesys.contentVerticalPosition = contentVerticalPosition;
                this.props.reloadPreview();
              }}
              updateBoxStyle={(boxStyle: IPlayBoxStyle) => {
                this.props.store.widgetToEdit.relesys.boxStyle = boxStyle;
                if (boxStyle === "box" || boxStyle === "boxHidden" || boxStyle === "boxTransparent") {
                  // scroll is not working in box layout, so we disable text
                  this.props.store.widgetToEdit.relesys.showText = false;
                }
                this.props.reloadPreview();
              }}
              updateContentPosition={(contentPosition: "left" | "right" | "random") => {
                this.props.store.widgetToEdit.relesys.contentPosition = contentPosition;
                this.props.reloadPreview();
              }}
              updateKeepOriginalImage={(keepOriginalImage: boolean) => {
                this.props.store.widgetToEdit.relesys.keepOriginalImage = keepOriginalImage;
                this.props.reloadPreview();
              }}
              updateContentWidth={(width) => {
                this.props.store.widgetToEdit.relesys.contentWidth = width;
                this.props.reloadPreview();
              }}
              updateBoxStyleRoundCorner={(isEnabled) => {
                this.props.store.widgetToEdit.relesys.boxStyleRoundCorner = isEnabled;
                this.props.reloadPreview();
              }}
            />
            <IAToggle
              style={{
                marginTop: 20
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_MessagesViewerShowTitle?.toUpperCase()}
              checked={this.props.store.widgetToEdit?.relesys && this.props.store.widgetToEdit?.relesys.showTitle != undefined ? this.props.store.widgetToEdit?.relesys.showTitle : true}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.props.store.widgetToEdit.relesys.showTitle = this.props.store.widgetToEdit?.relesys.showTitle != undefined ? !this.props.store.widgetToEdit?.relesys.showTitle : false;
                this.props.reloadPreview();
              }}
            />
            {!(this.props.store.widgetToEdit.relesys.boxStyle === "box" || this.props.store.widgetToEdit.relesys.boxStyle === "boxHidden" || this.props.store.widgetToEdit.relesys.boxStyle === "boxTransparent") && 
              <>
                <IAToggle
                  style={{
                    marginTop: 20
                  }}
                  labelStyle={{
                    fontSize: "12px"
                  }}
                  label={this.localizationService.strings.PlayAdmin_MessagesViewerShowText?.toUpperCase()}
                  checked={this.props.store.widgetToEdit?.relesys && this.props.store.widgetToEdit?.relesys.showText != undefined ? this.props.store.widgetToEdit?.relesys.showText : true}
                  color={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  onChange={() => {
                    this.props.store.widgetToEdit.relesys.showText = this.props.store.widgetToEdit?.relesys.showText != undefined ? !this.props.store.widgetToEdit?.relesys.showText : false;
                    this.props.reloadPreview();
                  }}
                />
                <IAToggle
                  style={{
                    marginTop: 20
                  }}
                  labelStyle={{
                    fontSize: "12px"
                  }}
                  label={this.localizationService.strings.PlayAdmin_MessagesViewerEnableScroll?.toUpperCase()}
                  checked={this.props.store.widgetToEdit?.relesys && this.props.store.widgetToEdit?.relesys.enableScroll != undefined ? this.props.store.widgetToEdit?.relesys.enableScroll : false}
                  color={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  onChange={() => {
                    this.props.store.widgetToEdit.relesys.enableScroll = this.props.store.widgetToEdit?.relesys.enableScroll != undefined ? !this.props.store.widgetToEdit?.relesys.enableScroll : true;
                    this.props.reloadPreview();
                  }}
                />
              </>
            }
            <IAToggle
              style={{
                marginTop: 20
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_MessagesViewerShowDate?.toUpperCase()}
              checked={this.props.store.widgetToEdit?.relesys && this.props.store.widgetToEdit?.relesys.showTime != undefined ? this.props.store.widgetToEdit?.relesys.showTime : true}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.props.store.widgetToEdit.relesys.showTime = this.props.store.widgetToEdit?.relesys.showTime != undefined ? !this.props.store.widgetToEdit?.relesys.showTime : false;
                this.props.reloadPreview();
              }}
            />
            <IAToggle
              style={{
                marginTop: 20
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_MessagesViewerShowGallery?.toUpperCase()}
              checked={this.props.store.widgetToEdit?.relesys?.showGallery}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.props.store.widgetToEdit.relesys.showGallery = this.props.store.widgetToEdit?.relesys.showGallery != undefined ? !this.props.store.widgetToEdit?.relesys.showGallery : false;
                this.props.reloadPreview();
              }}
            />
          </IAGroup>
        }
      </>
    );
  }
}
