import * as React from 'react';
import * as styles from './Index.css';
import { IAzureStorageUploadInfo } from '../../../interfaces/IPlay';
import { Helper } from '../../../Helper';
import PlayService from '../../../services/PlayService';
import { PlayHelper } from '../../../components/playAdmin/PlayHelper';
import { IASpinner } from '../../spinner/Spinner';
import { IAButton } from '../../button/Button';
import { Environment } from '../../../services/Environment';
import { IEnvironment } from '../../../interfaces/IEnvironment';
import { LocalizationService } from '../../../services/LocalizationService';

export interface IProps {
  environment: IEnvironment;
  tenantId: string;
  profileId: string;
  widgetId: string;
  playerId?: string;
  switchStatus?: string;
  galleryName: string;
  orientation: "horizontal" | "vertical";
  objectFit: "cover" | "contain";
  imageSize: string;
  type: string;
  pollOptionId?: string;
}

export interface IState {
  file: File;
  azureStorageInfo: IAzureStorageUploadInfo;
  uploadStatus: "success" | "failed" | "waiting" | "uploading" | "waitingForConfirmation";
  imagePreview: string;
  isLoadingImage: boolean;
  isSaving?: boolean;
}

export class IAReplayInteract extends React.Component<IProps, IState> {
  private inputRef: React.RefObject<HTMLInputElement>

  constructor(props: IProps) {
    super(props);
    this.state = {
      file: undefined,
      azureStorageInfo: undefined,
      uploadStatus: "waiting",
      imagePreview: undefined,
      isLoadingImage: false,
      isSaving: true
    };
    this.inputRef = React.createRef();
    if (this.props.type === "poll") {
      fetch("https://api.ipify.org?format=json", {
        method: "GET",
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        })
      })
        .then((response) => {
          if (!response.ok) {
            return;
          }
          return response.json();
        }).then((result) => {
          console.log(result.ip);
          if (result?.ip) {
            PlayService.addReplyToPoll(this.props.environment, this.props.tenantId, this.props.profileId, this.props.widgetId, this.props.pollOptionId, result.ip).then(() => {
              this.setState({ isSaving: false });
            });
          }
        })
    }
    if (this.props.type === "christmascountdown") {
      PlayService.widgetSwitch(this.props.environment, this.props.widgetId, this.props.playerId, this.props.switchStatus).then(() => {

      });
    }
  }

  private handleMediaFileChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const files = e.target.files;
    this.uploadFile(files[0]);
  };

  private uploadFile(file: File) {
    this.setState({ file, uploadStatus: "uploading" });
    let fileName = file.name;
    if (file.name && file.name.indexOf(".")) {
      const splittedFileName = file.name.split(".");
      fileName = `${splittedFileName[0]}-${Helper.getRandomStringKey()}.${splittedFileName[1]}`;
    }
    PlayService.getSocialGalleryMediaFileLinkToUpload(this.props.environment, this.props.tenantId, this.props.profileId, this.props.widgetId, fileName).then((azureStorageInfo: IAzureStorageUploadInfo) => {
      azureStorageInfo.fileName = fileName;
      this.setState({ file, azureStorageInfo }, () => {
        PlayService.uploadFileToAzureStorage(this.props.tenantId, `${this.props.profileId}/social-gallery/${this.props.widgetId}`, this.state.azureStorageInfo.storageUri, this.state.azureStorageInfo.containerName, this.state.azureStorageInfo.sharedAccessSignature, this.state.file, this.state.azureStorageInfo.fileName).then(() => {
          const imageUrl = `${Environment.getEnvironmentForPlayApi(this.props.environment)}/${this.props.tenantId}/profiles/${this.props.profileId}/image/${this.props.imageSize}?path=${PlayHelper.useCDN(this.state.azureStorageInfo.uploadLink)}`;
          this.setState({
            uploadStatus: "waitingForConfirmation",
            imagePreview: imageUrl,
            isLoadingImage: true
          });
        }).catch(() => {
          this.setState({ uploadStatus: "failed" });
        });
      });
    }).catch(() => {
      this.setState({ uploadStatus: "failed" });
    });
  }

  public render(): JSX.Element {
    let content;
    switch (this.props.type) {
      case "poll":
        content = (
          <>
            <div
              className={styles.IA_pollThankYou}
            >
              {this.state.isSaving ?
                <IASpinner
                  color="#fff"
                />
                :
                new LocalizationService().strings.ReplayInteract_pollThankYou
              }
            </div>
          </>
        );
        break;
      case "christmascountdown":
        content = (
          <>
            <div
              className={styles.IA_christmasCandle}
            >
              {this.props.switchStatus === "true" ? "You have lit the candle 🔥" : "You have blown out the candle 💨" } 
            </div>
          </>
        );
        break;

      default:
        // Social Gallery
        content = (
          <>
            <div
              className={styles.IA_description}
            >
              Contribute to the Replay gallery:
            </div>
            <div
              className={styles.IA_widgetTitle}
            >
              {this.props.galleryName}
            </div>
            {this.state.uploadStatus === "uploading"
              ?
              <IASpinner
                style={{
                  top: "calc(50% - 250px)"
                }}
                color="#fff"
              />
              :
              ((this.state.uploadStatus === "waiting" || this.state.uploadStatus === "success") &&
                <>
                  <div
                    className={styles.IA_addImageButton}
                    onClick={() => {
                      this.setState({ uploadStatus: "waiting" }, () => {
                        this.inputRef.current.click();
                      });
                    }}
                  />
                  <input
                    ref={this.inputRef}
                    type="file"
                    accept="image/*"
                    style={{
                      opacity: 0
                    }}
                    onLoad={() => { }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleMediaFileChange(e)}
                  />
                </>
              )
            }

            {this.state.uploadStatus === "success" &&
              <p className={styles.IA_errorMessage}>
                IMAGE SUCCESFULLY UPLOADED
                <br />
                TAP ABOVE TO ADD ANOTHER
              </p>
            }

            {this.state.uploadStatus === "failed" &&
              <p className={styles.IA_errorMessage}>
                AN ERROR OCCURED WHILE UPLOADING
                <br />
                PLEASE TRY AGAIN.
              </p>
            }

            {this.state.uploadStatus === "waitingForConfirmation" && this.state.imagePreview &&
              <div
                className={styles.IA_successPopup}
              >
                {this.state.isLoadingImage &&
                  <IASpinner
                    style={{
                      position: "absolute",
                      top: "20%"
                    }}
                    color="#fff"
                  />
                }
                <img
                  src={this.state.imagePreview}
                  onLoad={() => this.setState({ isLoadingImage: false })}
                />
                <IAButton
                  buttonColor="#696969"
                  textColor="#ffffff"
                  borderColor='#ffffff'
                  label="ADD IMAGE TO GALLERY"
                  fontSize={14}
                  height={50}
                  style={{
                    boxShadow: "0px 5px 10px 0 rgba(0, 0, 0, 0.24)"
                  }}
                  onClick={() => {
                    this.setState({ uploadStatus: "uploading" }, () => {
                      PlayService.addImageToSocialGallery(this.props.environment, this.props.tenantId, this.props.profileId, this.props.widgetId, PlayHelper.useCDN(this.state.azureStorageInfo.uploadLink)).then(() => {
                        this.setState({ uploadStatus: "success" });
                      }).catch(() => {
                        this.setState({ uploadStatus: "failed" });
                      });
                    });
                  }}
                />
              </div>
            }
          </>
        );
        break;
    }
    return (
      <div
        className={styles.IA_app}
        style={{
          height: "100%",
          backgroundColor: "black"
        }}
      >
        {content}
        <div
          className={styles.IA_logo}
        />
        <div
          className={styles.IA_logoType}
        >
          <span style={{ fontWeight: "bold" }}>REPLAY</span> <span style={{ fontWeight: "lighter" }}>INTERACT</span>
        </div>
      </div>
    );
  }

}