import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import { IATextField } from '../../../../../textField/TextField';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IANumberField } from '../../../../../numberField/NumberField';
import { IAToggle } from '../../../../../toggle/Toggle';
import { Helper } from '../../../../../../Helper';
import { IAButton } from '../../../../../button/Button';
import { IAColorTheme } from '../../../../../colorTheme/ColorTheme';
import { IAGroup } from '../../../../../group/Group';
import { IAOptionSelector } from '../../../../../optionSelector/OptionSelector';
import { IPlayBoxStyle } from '../../../../../../interfaces/IPlay';
import { RotatorViewSelector } from '../../../rotatorViewSelector/RotatorViewSelector';

export interface IProps {
  store?: Store;
  forceUpdate: () => void;
  reloadPreview: () => void;
  loadingContent: (loading: boolean) => void;
}

export interface IState {
}

@inject('store')
@observer
export class RssWidgetSettings extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();


  constructor(props: IProps) {
    super(props);
    this.state = {
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }


  render(): React.ReactNode {
    const colorThemes = [];
    if (this.props.store.widgetToEdit?.rss?.colorThemes?.length > 0) {
      this.props.store.widgetToEdit.rss.colorThemes.forEach((colorTheme, index) => {
        colorThemes.push(
          <IAColorTheme
            key={"colorTheme_" + index}
            backgroundColor={colorTheme.backgroundColor}
            textColor={colorTheme.textColor}
            highlightColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            swatches={this.props.store.profile?.swatches}
            inTeams
            remove={() => {
              this.props.store.widgetToEdit.rss.colorThemes.splice(index, 1);
              this.props.forceUpdate();
            }}
            allowAddingToSwatches={false}
            setBackgroundColor={(backgroundColor) => {
              colorTheme.backgroundColor = backgroundColor;
              this.props.forceUpdate();
            }}
            setTextColor={(textColor) => {
              colorTheme.textColor = textColor;
              this.props.forceUpdate();
            }}
          />
        );
      });
    }
    return (
      <>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_WidgetSettings?.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <IATextField
            label={this.localizationService.strings.PlayAdmin_Title?.toUpperCase()}
            required
            placeholder={this.localizationService.strings.PlayAdmin_Title}
            text={this.props.store.widgetToEdit.title}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(title: string) => {
              this.props.store.widgetToEdit.title = title;
              this.props.forceUpdate();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20,
              fontSize: 12
            }}
            darkMode={this.props.store.darkMode}
            label={this.localizationService.strings.PlayAdmin_ShowWidgetTitle?.toUpperCase()}
            checked={this.props.store.widgetToEdit.showTitle}
            color={this.props.store.highlightColor}
            onChange={() => {
              this.props.store.widgetToEdit.showTitle = this.props.store.widgetToEdit.showTitle != undefined ? !this.props.store.widgetToEdit.showTitle : true;
              this.props.forceUpdate();
            }}
          />
          <IATextField
            label={this.localizationService.strings.PlayAdmin_RssUrl?.toUpperCase()}
            placeholder={this.localizationService.strings.PlayAdmin_RssUrl}
            text={this.props.store.widgetToEdit.rss ? this.props.store.widgetToEdit.rss.url : undefined}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(url: string) => {
              this.props.store.widgetToEdit.rss.url = url;
              this.props.forceUpdate();
            }}
          />
          <IANumberField
            label={this.localizationService.strings.PlayAdmin_RssMaxItems?.toUpperCase()}
            number={this.props.store.widgetToEdit.rss?.maxItems ?? undefined}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(maxItems: number) => {
              this.props.store.widgetToEdit.rss.maxItems = maxItems;
              this.props.reloadPreview();
            }}
            styles={{
              width: "100%",
              clear: "both"
            }}
          />
          <IANumberField
            label={this.localizationService.strings.PlayAdmin_MinimumDuration?.toUpperCase()}
            number={this.props.store.widgetToEdit.rss.duration}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(duration: number) => {
              this.props.store.widgetToEdit.rss.duration = duration;
              this.props.reloadPreview();
            }}
            styles={{
              width: "100%",
              clear: "both"
            }}
          />
        </IAGroup>
        <IAGroup
          label={this.localizationService.strings.PlayAdmin_ArticleLayoutType.toUpperCase()}
          darkMode={this.props.store.darkMode}
        >
          <label
            style={{
              color: this.props.store.darkMode ? Helper.darkModeLabelColor : '#333333',
              marginTop: 20
            }}
          >
            {this.localizationService.strings.PlayAdmin_ColorThemes?.toUpperCase()}
          </label>
          {colorThemes}
          <IAButton
            label={this.localizationService.strings.PlayAdmin_AddColorTheme?.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onClick={() => {
              if (!this.props.store.widgetToEdit.rss?.colorThemes) {
                this.props.store.widgetToEdit.rss.colorThemes = [];
              }
              this.props.store.widgetToEdit.rss.colorThemes.push(
                {
                  textColor: "#333333",
                  backgroundColor: "#ffffff"
                }
              );
              this.props.forceUpdate();
            }}
            showSpinner={this.props.store.workingOnIt}
            borderRadius={5}
            style={{
              float: "left",
              position: "relative",
              clear: "both",
              marginBottom: 20,
              marginTop: 10
            }}
          />
          <RotatorViewSelector
            layoutType={"slide"}
            boxStyle={this.props.store.widgetToEdit?.rss?.boxStyle}
            backgroundColor={this.props.store.widgetToEdit?.rss?.colorThemes[0].backgroundColor}
            textColor={this.props.store.widgetToEdit?.rss?.colorThemes[0].textColor}
            image={`https://intraactivestorage.blob.core.windows.net/cdn/play/no-image.webp`}
            contentPosition={this.props.store.widgetToEdit?.rss.contentPosition}
            keepOriginalImage={this.props.store.widgetToEdit?.rss.keepOriginalImage}
            boxDisabled
            updateLayout={() => { }}
            updateBoxStyle={(boxStyle: IPlayBoxStyle) => {
              this.props.store.widgetToEdit.rss.boxStyle = boxStyle;
              this.props.reloadPreview();
            }}
            updateContentPosition={(contentPosition: "left" | "right" | "random") => {
              this.props.store.widgetToEdit.rss.contentPosition = contentPosition;
              this.props.reloadPreview();
            }}
            updateKeepOriginalImage={(keepOriginalImage: boolean) => {
              this.props.store.widgetToEdit.rss.keepOriginalImage = keepOriginalImage;
              this.props.reloadPreview();
            }}
          />
          <IAOptionSelector
            label={this.localizationService.strings.PlayAdmin_TransitionType?.toUpperCase()}
            options={[
              {
                icon: "Transition Crossfade",
                key: "crossfade",
                text: this.localizationService.strings.PlayAdmin_Crossfade
              },
              {
                icon: "Transition Slide",
                key: "slide",
                text: this.localizationService.strings.PlayAdmin_Slide
              },
            ]}
            selected={this.props.store.widgetToEdit?.rss.transition}
            highlightColor={this.props.store.highlightColor}
            compact
            labelStyle={{
              fontSize: "12px"
            }}
            iconSize={30}
            darkMode={this.props.store.darkMode}
            borderRadius={5}
            onChange={(transitionType: any) => {
              this.props.store.widgetToEdit.rss.transition = transitionType;
              this.props.reloadPreview();
            }}
            style={{ clear: "none", marginTop: 20 }}
          />
          <IAToggle
            style={{
              marginTop: 20
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            label={this.localizationService.strings.PlayAdmin_MessagesViewerShowTitle?.toUpperCase()}
            checked={this.props.store.widgetToEdit.rss.showTitle != undefined ? this.props.store.widgetToEdit.rss.showTitle : true}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onChange={() => {
              this.props.store.widgetToEdit.rss.showTitle = this.props.store.widgetToEdit.rss.showTitle != undefined ? !this.props.store.widgetToEdit.rss.showTitle : false;
              this.props.reloadPreview();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            label={this.localizationService.strings.PlayAdmin_MessagesViewerShowDate?.toUpperCase()}
            checked={this.props.store.widgetToEdit.rss.showDate != undefined ? this.props.store.widgetToEdit.rss.showDate : true}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onChange={() => {
              this.props.store.widgetToEdit.rss.showDate = this.props.store.widgetToEdit.rss.showDate != undefined ? !this.props.store.widgetToEdit.rss.showDate : false;
              this.props.reloadPreview();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            label={this.localizationService.strings.PlayAdmin_ShowProgressBar?.toUpperCase()}
            checked={this.props.store.widgetToEdit.showProgressBar != undefined ? this.props.store.widgetToEdit.showProgressBar : true}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onChange={() => {
              this.props.store.widgetToEdit.showProgressBar = this.props.store.widgetToEdit.showProgressBar != undefined ? !this.props.store.widgetToEdit.showProgressBar : false;
              this.props.reloadPreview();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            label={this.localizationService.strings.PlayAdmin_MessagesViewerShowText?.toUpperCase()}
            checked={this.props.store.widgetToEdit.rss.showText != undefined ? this.props.store.widgetToEdit.rss.showText : true}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onChange={() => {
              this.props.store.widgetToEdit.rss.showText = this.props.store.widgetToEdit.rss.showText != undefined ? !this.props.store.widgetToEdit.rss.showText : false;
              this.props.reloadPreview();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            label={this.localizationService.strings.PlayAdmin_MessagesViewerEnableScroll?.toUpperCase()}
            checked={this.props.store.widgetToEdit?.rss && this.props.store.widgetToEdit?.rss.enableScroll != undefined ? this.props.store.widgetToEdit?.rss.enableScroll : false}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onChange={() => {
              this.props.store.widgetToEdit.rss.enableScroll = this.props.store.widgetToEdit?.rss.enableScroll != undefined ? !this.props.store.widgetToEdit?.rss.enableScroll : true;
              this.props.reloadPreview();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            label={this.localizationService.strings.PlayAdmin_MessagesViewerShowQR?.toUpperCase()}
            checked={this.props.store.widgetToEdit.rss.showQRLink != undefined ? this.props.store.widgetToEdit.rss.showQRLink : true}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onChange={() => {
              this.props.store.widgetToEdit.rss.showQRLink = this.props.store.widgetToEdit.rss.showQRLink != undefined ? !this.props.store.widgetToEdit.rss.showQRLink : false;
              this.props.reloadPreview();
            }}
          />
          <IATextField
            placeholder={this.localizationService.strings.PlayAdmin_LinkText}
            text={this.props.store.widgetToEdit.rss.linkText}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            label={this.localizationService.strings.PlayAdmin_QRText?.toUpperCase()}
            style={{
              marginTop: 20
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            inputFieldStyle={{
              marginBottom: 10
            }}
            onChange={(text: string) => {
              this.props.store.widgetToEdit.rss.linkText = text;
              this.props.forceUpdate();
            }}
          />
        </IAGroup>
      </>);
  }


}