import * as React from 'react';
import { IPlayWeatherForecast } from '../../../../../interfaces/IPlay';
import * as styles from '../Weather.css';
import { TemperatureGraph } from './TemperatureGraph';
import { IColorTheme } from '../../../../../interfaces/IColorTheme';
import { ColorTranslator } from 'colortranslator';

export interface IProps {
  widgetId: string;
  forecast: IPlayWeatherForecast[];
  width: number;
  height: number;
  sizeUnit: number;
  colorTheme: IColorTheme;
  isSmall?: boolean;
  getDayString?: (index: number, time: string) => string;
}

export interface IState {
}

export class WeekHorizontalWeather extends React.Component<IProps, IState> {
  private readonly MARGIN_MULTIPLIER = 5;

  constructor(props: IProps) {
    super(props);
    this.state = {
    };
  }

  public render(): JSX.Element {
    const colortranslator = new ColorTranslator(this.props.colorTheme.textColor.toLocaleLowerCase());
    const borderColor = `rgba(${colortranslator.R}, ${colortranslator.G}, ${colortranslator.B}, .2)`;

    return (
      <div
        className={styles.IA_weatherWidgetContainer}
        style={{
          backgroundColor: this.props.colorTheme.backgroundColor
        }}
      >
        {this.props.forecast && this.props.forecast.slice(0, -1).map((weather, i) => {
          return <div
            className={styles.IA_weatherDay}
            style={{
              marginTop: this.props.sizeUnit * this.MARGIN_MULTIPLIER,
              marginBottom: this.props.sizeUnit * this.MARGIN_MULTIPLIER,
              marginLeft: i === 0 ? this.props.sizeUnit * this.MARGIN_MULTIPLIER : undefined,
              marginRight: i === 4 ? this.props.sizeUnit * this.MARGIN_MULTIPLIER : undefined,
              borderLeft: i !== 0 ? `1px solid ${borderColor}` : undefined,
            }}
          >
            <div
              style={{
                fontSize: this.props.sizeUnit * 3,
                marginBottom: this.props.sizeUnit * 3,
                marginTop: this.props.sizeUnit * 3,
                color: this.props.colorTheme.textColor
              }}
            >
              {this.props.getDayString(i, weather.time)?.toUpperCase()}
            </div>
            <div
              className={styles.IA_iconAndPrecipationContainer}
            >
              <img
                src={weather.iconUrl}
                className={styles.IA_weatherIcon}
                style={{
                  width: "55%"
                }}
              />
              <div
                className={styles.IA_precipation}
                style={{
                  fontSize: this.props.sizeUnit * 2,
                  minHeight: this.props.sizeUnit * 1.5,
                  marginTop: this.props.sizeUnit,
                }}
              >
                {weather.precipitationAmount !== 0 ? `${Math.ceil(weather.precipitationAmount)} mm` : " "}
              </div>
            </div>
            <div
              style={{
                marginTop: this.props.sizeUnit * 3,
                fontSize: this.props.sizeUnit * 5,
                color: this.props.colorTheme.textColor
              }}
            >
              {`${weather.airTemperatureHigh.toFixed(0)}°`}
            </div>
          </div>
        })}
        <TemperatureGraph
          widgetId={this.props.widgetId}
          forecast={this.props.forecast}
          sizeUnit={this.props.sizeUnit}
          marginMultiplier={this.MARGIN_MULTIPLIER}
          width={this.props.width}
          color={this.props.colorTheme.textColor}
        />
      </div>
    );
  }
}