import * as React from 'react';
import { Store } from '../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './WidgetEditPanel.css';
import { IAPanel } from '../../../../panels/Panel';
import { LocalizationService } from '../../../../../services/LocalizationService';
import PlayService from '../../../../../services/PlayService';
import { IPlaySlideExtended, IPlaySlideLayout, IPlayWidgetExtended, IPlayWidgetType } from '../../../../../interfaces/IPlay';
import { IASpinner } from '../../../../spinner/Spinner';
import { Helper } from '../../../../../Helper';
import { IAButton } from '../../../../button/Button';
import { RssTicker } from '../../../../player/rssTicker/RssTicker';
import { Slide } from '../../../../player/slide/Slide';
import { ImageWidgetSettings } from './widgetSettings/ImageWidgetSettings';
import { RssWidgetSettings } from './widgetSettings/RssWidgetSettings';
import { GalleryWidgetSettings } from './widgetSettings/GalleryWidgetSettings';
import { IIconDropDown } from '../../../../../interfaces/IIconDropDown';
import { EmbedWidgetSettings } from './widgetSettings/EmbedWidgetSettings';
import { VideoWidgetSettings } from './widgetSettings/VideoWidgetSettings';
import { CountdownWidgetSettings } from './widgetSettings/CountdownWidgetSettings';
import { IColorTheme } from '../../../../../interfaces/IColorTheme';
import { Popup } from '../../../reusableComponents/popup/Popup';
import { PlayHelper } from '../../../PlayHelper';
import { Footer } from '../../../../player/footer/Footer';
import { PreviewSettings } from '../../../reusableComponents/previewSettings/PreviewSettings';
import { WeatherWidgetSettings } from './widgetSettings/WeatherWidgetSettings';
import { IAMessageBar } from '../../../../messageBar/MessageBar';
import { InstagramWidgetSettings } from './widgetSettings/InstagramWidgetSettings';
import { MessagesWidgetSettings } from './widgetSettings/MessagesWidgetSettings';
import { WorkplaceWidgetSettings } from './widgetSettings/WorkplaceWidgetSettings';
import { EventsWidgetSettings } from './widgetSettings/EventsWidgetSettings';
import { SharepointWidgetSettings } from './widgetSettings/SharepointWidgetSettings';
import { ArticleWidgetSettings } from './widgetSettings/ArticleWidgetSettings';
import { FacebookWidgetSettings } from './widgetSettings/FacebookWidgetSettings';
import { PowerBIWidgetSettings } from './widgetSettings/PowerBIWidgetSettings';
import { RelesysWidgetSettings } from './widgetSettings/RelesysWidgetSettings';
import { CounterWidgetSettings } from './widgetSettings/CounterWidgetSettings';
import { TimeEditWidgetSettings } from './widgetSettings/TimeEditWidgetSettings';

export interface IProps {
  widgetId: string;
  store?: Store;
  close: () => void;
  onSave: () => void;
  availableTypes?: IIconDropDown[];
  preSelectedType?: IPlayWidgetType;
}

export interface IState {
  isSaving: boolean;
  displayLayout: IPlaySlideLayout;
  previewColorTheme?: IColorTheme;
  showConfirmClosePopup: boolean;
  showFooter?: boolean;
  showRssTicker?: boolean;
  playersMissingLocationWarning?: string[];
  loadingContent: boolean;
}

@inject('store')
@observer
export class WidgetEditPanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    const WidgetPreviewSettings = localStorage.getItem(`IA_widgetPreviewSettings_${props.widgetId}`)
    this.state = {
      isSaving: false,
      showConfirmClosePopup: false,
      displayLayout: WidgetPreviewSettings ? JSON.parse(WidgetPreviewSettings).displayLayout : "oneColumn",
      showFooter: WidgetPreviewSettings ? JSON.parse(WidgetPreviewSettings).showFooter : false,
      showRssTicker: WidgetPreviewSettings ? JSON.parse(WidgetPreviewSettings).showRssTicker : false,
      playersMissingLocationWarning: [],
      loadingContent: false
    }
    this.localizationService?.checkLocalizedStrings().then(() => this.forceUpdate());
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.widgetId !== this.props.widgetId && props.widgetId) {
      const WidgetPreviewSettings = localStorage.getItem(`IA_widgetPreviewSettings_${props.widgetId}`)
      this.setState({
        displayLayout: WidgetPreviewSettings ? JSON.parse(WidgetPreviewSettings).displayLayout : "oneColumn",
        showFooter: WidgetPreviewSettings ? JSON.parse(WidgetPreviewSettings).showFooter : false,
        showRssTicker: WidgetPreviewSettings ? JSON.parse(WidgetPreviewSettings).showRssTicker : false,
      });
      PlayService.getWidgetById(props.store.environment, this.props.store.token, props.store.tenantId, this.props.store.profile?.id, props.widgetId).then((widget: IPlayWidgetExtended) => {
        if (widget) {
          this.props.store.widgetToEditBeforeChanges = JSON.stringify(widget);
          this.props.store.widgetToEdit = widget;
          if (this.props.store.widgetToEdit.type === "messageViewer") {
            this.setState({ previewColorTheme: this.props.store.widgetToEdit.messageViewer.colorThemes[0] });
          }
          if (this.props.store.widgetToEdit?.video?.showTitle) {
            this.props.store.widgetToEdit.showTitle = true;
            this.props.store.widgetToEdit.video.showTitle = undefined;
          }
          if (this.props.store.widgetToEdit?.gallery?.showTitle) {
            this.props.store.widgetToEdit.showTitle = true;
            this.props.store.widgetToEdit.gallery.showTitle = undefined;
          }
          this.setState({ loadingContent: false });
          this.forceUpdate();
          setTimeout(() => {
            this.forceUpdate();
          }, 2000);
        }
      }).catch(e => {
        this.props.store.workingOnIt = false;
        this.props.store.generalErrorPopup = {
          technicalDetails: e
        };
      });
    }
  }

  private reloadPreview(): void {
    this.setState({ loadingContent: true }, () => {
      setTimeout(() => {
        this.setState({ loadingContent: false });
      }, 1000);
    })
  }

  private getWidgetSettings(type: IPlayWidgetType): JSX.Element {
    switch (type) {
      case "image":
        return <ImageWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "rss":
        return <RssWidgetSettings
          forceUpdate={() => this.forceUpdate()}
          reloadPreview={() => this.reloadPreview()}
          loadingContent={(loading: boolean) => this.setState({ loadingContent: loading })}
        />;
      case "instagram":
        return <InstagramWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "gallery":
        return <GalleryWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "articleViewer":
        return <ArticleWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "embed":
        return <EmbedWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "video":
        return <VideoWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "youtube":
        return <VideoWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "vimeo":
        return <VideoWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "video23":
        return <VideoWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "messageViewer":
        return <MessagesWidgetSettings
          forceUpdate={() => this.forceUpdate()}
          reloadPreview={() => this.reloadPreview()}
          loadingContent={(loading: boolean) => this.setState({ loadingContent: loading })}
        />;
      case "eventViewer":
        return <EventsWidgetSettings
          forceUpdate={() => this.forceUpdate()}
          reloadPreview={() => this.reloadPreview()}
          loadingContent={(loading: boolean) => this.setState({ loadingContent: loading })}
        />;
      case "sharepointNewsViewer":
        return <SharepointWidgetSettings
          forceUpdate={() => this.forceUpdate()}
          reloadPreview={() => this.reloadPreview()}
          loadingContent={(loading: boolean) => this.setState({ loadingContent: loading })}
        />;
      case "workplaceFeedViewer":
        return <WorkplaceWidgetSettings
          forceUpdate={() => this.forceUpdate()}
          reloadPreview={() => this.reloadPreview()}
          loadingContent={(loading: boolean) => this.setState({ loadingContent: loading })}
        />;
      case "facebookPageViewer":
        return <FacebookWidgetSettings
          forceUpdate={() => this.forceUpdate()}
          reloadPreview={() => this.reloadPreview()}
          loadingContent={(loading: boolean) => this.setState({ loadingContent: loading })}
        />;
      case "weather":
        return <WeatherWidgetSettings
          onPlayersMissingLocation={playerNames => this.setState({ playersMissingLocationWarning: playerNames })}
          forceUpdate={() => this.forceUpdate()}
        />;
      case "countdown":
        return <CountdownWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "counter":
        return <CounterWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "powerbi":
        return <PowerBIWidgetSettings
          forceUpdate={() => this.forceUpdate()}
          reloadPreview={() => this.reloadPreview()}
          loadingContent={(loading: boolean) => this.setState({ loadingContent: loading })}
        />;
      case "relesys":
        return <RelesysWidgetSettings
          forceUpdate={() => this.forceUpdate()}
          reloadPreview={() => this.reloadPreview()}
          loadingContent={(loading: boolean) => this.setState({ loadingContent: loading })}
        />;
      case "timeEdit":
        return <TimeEditWidgetSettings
          forceUpdate={() => this.forceUpdate()}
          reloadPreview={() => this.reloadPreview()}
          loadingContent={(loading: boolean) => this.setState({ loadingContent: loading })}
        />;
      default:
        return <></>;
    }
  }

  private isSaveDisabled(): boolean {
    const widgetToEdit = this.props.store.widgetToEdit;
    if (!widgetToEdit?.title || !widgetToEdit.type) {
      return true;
    }

    if (widgetToEdit?.workplaceFeedViewer && (widgetToEdit?.workplaceFeedViewer?.groups === undefined || widgetToEdit?.workplaceFeedViewer?.groups?.length === 0)) {
      return true;
    }

    if (widgetToEdit.type === "messageViewer" && !widgetToEdit?.messageViewer?.feedId) {
      return true;
    }

    if (widgetToEdit.type === "eventViewer" && !widgetToEdit?.eventViewer?.feedId) {
      return true;
    }

    if (widgetToEdit.type === "sharepointNewsViewer" && !widgetToEdit?.sharepointNewsViewer?.feedId) {
      return true;
    }

    return false;
  }

  public render(): JSX.Element {
    // Landscape mode
    let previewWidth = this.props.store.windowWidth - 480;
    let previewHeight = (previewWidth / 16) * 9;
    // resize if the window height is smaller than the preview height
    if (previewHeight > window.innerHeight - 84) {
      previewHeight = window.innerHeight - 84 - 80;
      previewWidth = (previewHeight / 9) * 16;
    }
    // Portrait mode
    if (this.state.displayLayout === "portraitOneRow" || this.state.displayLayout === "portraitTwoRows") {
      previewHeight = window.innerHeight - 84 - 80;
      previewWidth = (previewHeight / 16) * 9;
    }
    const previewSlide: IPlaySlideExtended = {
      id: "widgetEditPanelPreviewSlide",
      showFooter: this.state.showFooter,
      showRssTicker: this.state.showRssTicker,
      startDate: new Date().toJSON(),
      widgets: [
        this.props.store.widgetToEdit,
        this.props.store.widgetToEdit,
        this.props.store.widgetToEdit,
        this.props.store.widgetToEdit
      ],
      layout: this.state.displayLayout
    }

    if (this.props.store.loadingPreviewContent) {
      setTimeout(() => {
        this.forceUpdate();
      }, 0);
    }
    return (
      <IAPanel
        open={this.props.store.widgetToEdit != undefined || this.props.widgetId != undefined}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        loading={this.state.isSaving}
        width={this.props.store.windowWidth}
        dataAutomationIdPrefix="widgets-edit-panel"
        panelId="widgets-edit-panel"
        darkMode={this.props.store.darkMode}
        isInTeams={this.props.store.isTeams}
        navigationsBarContent={
          <IAButton
            label={this.props.store.widgetToEdit?.id && this.props.store.widgetToEdit?.id !== "new" ? this.localizationService.strings.PlayAdmin_Update?.toUpperCase() : this.localizationService.strings.PlayAdmin_Save?.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            disbaled={this.isSaveDisabled()}
            onClick={() => {
              this.props.onSave();
            }}
            borderRadius={5}
            showSpinner={this.props.store.workingOnIt}
            style={{
              float: "right",
              position: "relative",
              marginTop: 7,
              marginRight: 10
            }}
          />
        }
        close={() => {
          if (this.props.store.widgetToEditBeforeChanges !== JSON.stringify(this.props.store.widgetToEdit)) {
            this.setState({ showConfirmClosePopup: true });
          } else {
            this.setState({ showConfirmClosePopup: false });
            this.props.close();
          }
        }}
      >
        <>
          {this.state.showConfirmClosePopup &&
            <Popup
              headline={this.localizationService.strings.Messages_CancelPopup_Headline}
              close={() => this.setState({ showConfirmClosePopup: false })}
              darkMode={this.props.store.darkMode}
              content={
                <>
                  <IAButton
                    buttonColor={this.props.store.highlightColor}
                    darkMode={this.props.store.darkMode}
                    label={this.localizationService.strings.PlayAdmin_DeletePopup_Yes?.toUpperCase()}
                    onClick={() => {
                      this.setState({ showConfirmClosePopup: false });
                      this.props.close();
                    }}
                    borderRadius={5}
                    style={{
                      float: "left"
                    }}
                  />
                  <IAButton
                    textColor={this.props.store.highlightColor}
                    darkMode={this.props.store.darkMode}
                    label={this.localizationService.strings.PlayAdmin_DeletePopup_No?.toUpperCase()}
                    onClick={() => this.setState({ showConfirmClosePopup: false })}
                    style={{
                      float: "left",
                      marginLeft: 30
                    }}
                  />
                </>
              }
            />
          }
          {this.props.store.widgetToEdit ?
            <div
              className={styles.IA_widgetsEditPanel}
              style={{
                height: window.innerHeight - 45,
                backgroundColor: this.props.store.darkMode ? "#414141" : "#777777",
                color: this.props.store.darkMode ? "#ffffff" : "#333333"
              }}
            >
              <div className={styles.IA_content} style={{ backgroundColor: this.props.store.darkMode ? Helper.darkModeBackgroundColor : '#fff' }}>
                <h1
                  style={{
                    color: this.props.store.darkMode ? "#ffffff" : '#333333',
                    marginLeft: 40,
                    width: "calc(100% - 80px)"
                  }}
                >
                  {PlayHelper.getTitleForWidgetType(this.props.store.widgetToEdit.type as IPlayWidgetType, this.localizationService)}
                </h1>
                <div
                  style={{
                    float: "left",
                    width: "calc(100% - 80px)",
                    marginLeft: 40,
                    marginBottom: 40,
                    height: 100,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    borderRadius: 5,
                    backgroundImage: `url("https://intraactivestorage.blob.core.windows.net/cdn/play/widgets/${PlayHelper.getIconForWidgetType(this.props.store.widgetToEdit.type as IPlayWidgetType)}-placeholder.png")`
                  }}
                />
                {this.props.store.widgetToEdit?.type === "weather" && this.state.playersMissingLocationWarning?.length > 0 &&
                  <IAMessageBar
                    type="warning"
                    onDismiss={undefined}
                    content={<div
                      style={{
                        display: "flex",
                        flexDirection: "column"
                      }}
                    >
                      <p style={{ marginBottom: 0 }}>{this.localizationService.strings.PlayAdmin_PlayersMissingLocation}</p>
                      <ul style={{ marginBottom: 0 }}>
                        {this.state.playersMissingLocationWarning.map(name => <li>{name}</li>)}
                      </ul>
                    </div>}
                    style={{
                      marginTop: 10,
                      borderRadius: 5
                    }}
                  />
                }
                {this.getWidgetSettings(this.props.store.widgetToEdit.type)}
              </div>
              <div className={styles.IA_previewArea}>
                {this.props.store.widgetToEdit.type !== "rssTicker" &&
                  <PreviewSettings
                    displayLayout={this.state.displayLayout}
                    previewColorTheme={this.state.previewColorTheme}
                    showFooter={this.state.showFooter}
                    showRssTicker={this.state.showRssTicker}
                    onColorThemeChange={(colorTheme: IColorTheme) => this.setState({ previewColorTheme: colorTheme }, () => this.forceUpdate())}
                    onLayoutChange={(layout: IPlaySlideLayout) => {
                      this.setState({ displayLayout: layout }, () => {
                        const widgetPreviewSettings = localStorage.getItem(`IA_widgetPreviewSettings_${this.props.widgetId}`);
                        let widgetPreviewSettingsObject;
                        if (widgetPreviewSettings) {
                          widgetPreviewSettingsObject = JSON.parse(widgetPreviewSettings);
                          widgetPreviewSettingsObject.displayLayout = this.state.displayLayout;
                        } else {
                          widgetPreviewSettingsObject = {
                            displayLayout: this.state.displayLayout
                          }
                        }
                        localStorage.setItem(`IA_widgetPreviewSettings_${this.props.widgetId}`, JSON.stringify(widgetPreviewSettingsObject));
                        this.forceUpdate();
                      });
                    }}
                    onShowFooterChange={() => {
                      this.setState({ showFooter: !this.state.showFooter }, () => {
                        const widgetPreviewSettings = localStorage.getItem(`IA_widgetPreviewSettings_${this.props.widgetId}`);
                        let widgetPreviewSettingsObject;
                        if (widgetPreviewSettings) {
                          widgetPreviewSettingsObject = JSON.parse(widgetPreviewSettings);
                          widgetPreviewSettingsObject.showFooter = this.state.showFooter;
                        } else {
                          widgetPreviewSettingsObject = {
                            showFooter: this.state.showFooter
                          }
                        }
                        localStorage.setItem(`IA_widgetPreviewSettings_${this.props.widgetId}`, JSON.stringify(widgetPreviewSettingsObject));
                        this.forceUpdate();
                      });
                    }}
                    onShowRSSChange={() => {
                      this.setState({ showRssTicker: !this.state.showRssTicker }, () => {
                        const widgetPreviewSettings = localStorage.getItem(`IA_widgetPreviewSettings_${this.props.widgetId}`);
                        let widgetPreviewSettingsObject;
                        if (widgetPreviewSettings) {
                          widgetPreviewSettingsObject = JSON.parse(widgetPreviewSettings);
                          widgetPreviewSettingsObject.showRssTicker = this.state.showFooter;
                        } else {
                          widgetPreviewSettingsObject = {
                            showRssTicker: this.state.showRssTicker
                          }
                        }
                        localStorage.setItem(`IA_widgetPreviewSettings_${this.props.widgetId}`, JSON.stringify(widgetPreviewSettingsObject));
                        this.forceUpdate();
                      });
                    }}
                  />
                }
                <div
                  className={styles.IA_preview}
                  style={{
                    width: previewWidth,
                    height: previewHeight,
                    paddingLeft: this.state.displayLayout === "portraitOneRow" || this.state.displayLayout === "portraitTwoRows" ? `calc(50% - ${previewWidth / 2}px)` : 40
                  }}
                >
                  {
                    this.props.store.widgetToEdit.type === "rssTicker" || this.props.preSelectedType === "rssTicker" ?
                      <div
                        className={styles.IA_previewRssTicker}
                        style={{
                          height: previewHeight,
                          width: previewWidth
                        }}
                      >
                        <RssTicker
                          rssTicker={this.props.store.widgetToEdit}
                          // slide={undefined}
                          sizeUnit={(previewHeight > previewWidth ? previewWidth : previewHeight) / 100}
                          playerWidth={previewWidth}
                          bottom={undefined}
                          headlineFont={this.props.store.profile?.fonts?.headline ? JSON.parse(this.props.store.profile?.fonts?.headline).name : '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'}
                          contentFont={this.props.store.profile?.fonts?.body ? JSON.parse(this.props.store.profile?.fonts?.body).name : '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'}
                          isActive={true}
                        />
                      </div>
                      :
                      <div
                        className={styles.IA_previewScreen}
                        style={{
                          height: previewHeight,
                          width: previewWidth
                        }}
                      >
                        <>
                          {this.state.loadingContent ?
                            <div
                              style={{
                                float: "left",
                                width: previewWidth,
                                height: previewHeight,
                                backgroundColor: this.props.store.darkMode ? "#333333" : "#ffffff"
                              }}
                            >
                              <IASpinner
                                key={Helper.getRandomStringKey()}
                                color={this.props.store.highlightColor}
                              />
                              <div
                                className={styles.IA_weAreWorkingOnIt}
                              >
                                {this.localizationService.strings.Composer_WeAreWorkingOnIt}
                              </div>
                            </div>
                            :
                            <>
                              {this.state.showRssTicker &&
                                <RssTicker
                                  rssTicker={{}}
                                  editMode={true}
                                  isActive={true}
                                  sizeUnit={(previewHeight > previewWidth ? previewWidth : previewHeight) / 100}
                                  playerWidth={previewWidth}
                                  headlineFont={this.props.store.profile?.fonts?.headline ? JSON.parse(this.props.store.profile?.fonts?.headline).name : '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'}
                                  contentFont={this.props.store.profile?.fonts?.body ? JSON.parse(this.props.store.profile?.fonts?.body).name : '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'}
                                  bottom={this.state.showFooter ? ((previewHeight > previewWidth ? previewWidth : previewHeight) / 100) * 8 : 0}

                                />
                              }
                              {this.state.showFooter &&
                                <Footer
                                  footer={this.props.store.profile?.footer}
                                  sizeUnit={(previewHeight > previewWidth ? previewWidth : previewHeight) / 100}
                                  city={this.props.store.city}
                                  currentWeather={this.props.store.weatherData?.current}
                                  editMode={true}
                                />
                              }
                              {!this.props.store.loadingPreviewContent &&
                                <Slide
                                  index={0}
                                  slide={previewSlide}
                                  isActive={true}
                                  height={previewHeight}
                                  width={previewWidth}
                                  left={0}
                                  footer={undefined}
                                  city={this.props.store.city}
                                  environment={this.props.store.environment}
                                  tenantId={this.props.store.tenantId}
                                  profileId={this.props.store.profile.id}
                                  playerId={"admin"}
                                  highlightColor={this.props.store.highlightColor}
                                  editPreviewMode
                                  previewColorTheme={this.state.previewColorTheme}
                                  standalone
                                  forecast={this.props.store.weatherData}
                                  nextSlide={() => { }}
                                  rssTicker={this.state.showRssTicker ? {} : undefined}
                                  headlineFont={this.props.store.profile?.fonts?.headline ? JSON.parse(this.props.store.profile?.fonts?.headline).name : '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'}
                                  contentFont={this.props.store.profile?.fonts?.body ? JSON.parse(this.props.store.profile?.fonts?.body).name : '"Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'}
                                  isHardcodedWeatherData={this.props.store.isUsingHardCodedWeather}
                                />
                              }
                            </>
                          }
                        </>
                      </div>
                  }
                </div>
              </div>
            </div>
            :
            <div
              className={styles.IA_loading}
              style={{
                backgroundColor: this.props.store.darkMode ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.5)"
              }}
            >
              <IASpinner
                key={Helper.getRandomStringKey()}
                color={this.props.store.highlightColor}
              />
              <div className={styles.IA_weAreWorkingOnIt}>{this.localizationService.strings.Composer_WeAreWorkingOnIt}</div>
            </div>
          }
        </>
      </IAPanel >
    );
  }


}