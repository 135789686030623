import { IStockImagesSearchResult } from '../interfaces/IStockImagesSearchResult';
import { FetchProxy } from 'intraactive-sdk-helper';
import { sdkContainerInstance } from '../global/Container';

export default class ImageService {
  public static uploadSelectedFile(file: File, uploadUrl: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        uploadUrl += `&name=${file.name}`;
        new FetchProxy(uploadUrl, {
          method: "PUT",
          credentials: "same-origin",
          mode: "cors",
          headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }),
          body: `"${event.target.result}"`
        }).withContainer(sdkContainerInstance)
        .fetch()
        .then((response) => {
          if (!response.ok) {
            reject();
          } else {
            return response.json();
          }
        }).then(response => {
          resolve(response);
        }).catch(() => reject());
      };
      fileReader.readAsDataURL(file);
    });
  }

  public static toDirectUrl(url: string): string {
    if (url && url.indexOf("intraactive-image-functions") !== -1) {
      return decodeURIComponent(url.split("path=")[1]);
    }
    // Url does not point to the image functions
    return url;
  }

  public static isUrlTenantInternal(url: string): boolean {
    return url && /https\:\/\/[a-z0-9A-Z-]+.sharepoint.com\/.+?/gi.test(url);
  }

  public static async getImageCropability(apiImageUrl: string, tenantName: string, token: string, imageUrl: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (imageUrl && apiImageUrl && tenantName && token) {
        tenantName = tenantName.replace(".onmicrosoft.com", "").replace(".sharepoint.com", "");

        const fullPath = `${apiImageUrl}/${tenantName}/verifyCropability/?token=${token}&path=${encodeURIComponent(imageUrl)}`;

        new FetchProxy(fullPath)
        .withContainer(sdkContainerInstance)
        .fetch()
        .then(ret => {
          if (ret.status === 200) {
            resolve(true);
          } else {
            resolve(false);
          }
        }).catch(_ => { resolve(false); });
      } else {
        resolve(false);
      }
    });
  }

  public static async getStockImages(apiImageUrl: string, tenantName: string, token: string, search: string, top: number, skip: number, language?: string): Promise<IStockImagesSearchResult> {
    return new Promise<IStockImagesSearchResult>((resolve, reject) => {
      const fullPath = `${apiImageUrl}/${tenantName}/stock/?filter=pivotNames%2Fany(p%3A%20p%20eq%20%27Stock%20Images%27)&search=${search}&top=${top}&skip=${skip}&language=${language ?? "en-US"}`;
      new FetchProxy(fullPath, {
        method: "GET",
        credentials: "same-origin",
        mode: "cors",
        headers: new Headers({
          "AuthToken": token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        })
      }).withContainer(sdkContainerInstance)
      .fetch()
      .then(response => {
        if (!response.ok) {
          reject();
        } else {
          return response.json();
        }
      }).then(response => {
        if (response) {
          resolve(
            {
              count: response.Count,
              images: response.MicrosoftContents,
              searchQuery: search
            }
          );
        }
      });
    });
  }

  public static async getBlobImageUrl(fullPath: string): Promise<string> {
    return new Promise<string>((resolve) => {
      new FetchProxy(fullPath, {
        method: "GET",
        credentials: "same-origin",
        mode: "cors",
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        })
      }).withContainer(sdkContainerInstance)
      .fetch()
      .then(response => {
        if (!response.ok) {
          throw (response.status);
        } else {
          return response.json();
        }
      }).then(response => {
        if (response) {
          resolve(
            response
          );
        }
      });
    });
  }

  public static async PrepareImageRenditions(apiImageUrl: string, tenantName: string, token: string, imageUrl: string, version?: string, cropSettings?: { x: number, y: number, width: number, height: number }, ignoreCache: boolean = false, retainType?: boolean) {
    if (imageUrl && apiImageUrl && tenantName && token && !imageUrl?.includes("intraactive-image-functions")) {

      const versionNumber = version !== undefined ? version : "";

      const pathComparison = `${apiImageUrl}/prepare/${tenantName}`;
      let crop = "";
      let ignore = "";

      if (ignoreCache) {
        ignore = `&ignoreCache=${ignoreCache}`;
      }

      if (cropSettings) {
        crop = `&cropX=${cropSettings.x}&cropY=${cropSettings.y}&cropH=${cropSettings.height}&cropW=${cropSettings.width}`;
      }

      const fullPath = `?token=${token}&version=${versionNumber}${ignore}${crop}&path=`;

      if (imageUrl.indexOf(pathComparison) === -1) {
        imageUrl = `${pathComparison}${fullPath}${encodeURIComponent(imageUrl)}`;
      } else {
        imageUrl = imageUrl.replace("&version=&", `&version=${versionNumber}&`) + crop;
      }
      if (retainType !== undefined) {
        imageUrl = `${imageUrl}&retainType=${retainType}`;
      }
      tenantName = tenantName.replace(".onmicrosoft.com", "").replace(".sharepoint.com", "");

      new FetchProxy(imageUrl, {
        method: "POST",
        credentials: "same-origin",
        mode: "cors",
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        })
      }).withContainer(sdkContainerInstance)
      .fetch();
      
    }
   
  }

}