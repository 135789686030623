import * as React from 'react';
import FacebookLogin from 'react-facebook-login';
import * as styles from './IaFacebookLogin.css';

export interface IAFacebookLoginProps {
  onChange: (userToken: string, userId: string) => void;
  disabled: boolean;
  buttonText: string;
}
export default class IaFacebookLogin extends React.Component<IAFacebookLoginProps> {

  constructor(props: IAFacebookLoginProps) {
    super(props);

  }
  private responseFacebook = (response) => {
    this.props.onChange(response["accessToken"], response["userID"]);
  };

  public componentWillMount() {
    window["fbAsyncInit"] = function () {
  
      window["FB"].init({
        appId: '163469590155019',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v10.0'
      });

      window["FB"].getLoginStatus(({ authResponse }) => {
        console.log("facebook", authResponse);
    });
    };

    (function (d, s, id) {
      let js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }
  public render(): JSX.Element {
    return (    
        <FacebookLogin
          appId="163469590155019"
          autoLoad={false}
          callback={this.responseFacebook}
          scope="pages_show_list,pages_read_engagement" 
          cssClass={styles.IA_facebookLogin}  
          isDisabled={this.props.disabled} 
          textButton={this.props.buttonText}   
        />
    );
  }


}
